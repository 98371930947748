import React, { Component } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { delay } from 'lodash/function'
import FilterSort from '../../utilities/modules/FilterSort'
import { ApiService } from '../../lib/api/HttpService'
import { encodeURI } from '../../utilities/String'
import { isArray } from 'lodash'

var scroll
var timeout

class ComboBox extends Component {
    constructor(props) {
        super(props)

        const modKey = `${this.props.type}Filter`
        this.fs = new FilterSort(modKey)
        this.api = new ApiService()

        this.state = {
            isOpen: false,
            data: [],
            checked: isArray(props?.selected) ? props?.selected : [],
            isLoaded: false,
            isEndData: false,
            searchLocally: false,
            setSuffix: true,

            searchProperty: '',
            page: 1,
            pageSize: 10,

            searchValue: '',
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick)
        document.addEventListener('keyup', this.handleSearch)

        let options = {
            setSuffix: this.props.setSuffix ?? true,
        }

        if (this.props.options) {
            options = {
                ...options,
                data: this.props.options ?? [],
                isLoaded: true,
                isEndData: true,
                searchLocally: true,
            }
        }

        this.setState(options)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({
                checked: this.props.selected ?? [],
            })
        }

        if (prevProps.onClear !== this.props.onClear) {
            this.setState({
                checked: this.props.onClear ? [] : this.state.checked,
            })
        }

        if (prevProps.options !== this.props.options) {
            this.setState({
                data: this.props.options,
            })
        }
    }

    fetchData = async (page, replace) => {
        const { fetchAll } = this.props
        let query = ''
        query += `?${this.props.filters ?? ''}`
        if (this.state.searchProperty !== '') {
            query +=
                this.props.filters == ''
                    ? `$filter=${this.state.searchProperty}`
                    : `and ${this.state.searchProperty}`
        }

        query += `&$orderby=${this.props.field.split('|')[0]} asc`
        const pageQry = fetchAll
            ? '&$top=2147483647'
            : `&$top=${this.state.pageSize}&$skip=${
                  ((page ?? this.state.page) - 1) * this.state.pageSize
              }`
        const data = await this.api[this.props.getDataFn](`${query}${pageQry}`)

        let results = []
        if (this.props.map) {
            results = await this.props.map(data)
        } else {
            results = data
        }

        let currentData = []
        if (!replace) {
            currentData = this.state.data.concat(results)
        } else {
            currentData = results
        }

        this.setState({
            data: currentData,
            isLoaded: true,
            isEndData: !results.length || fetchAll,
            isSearching: false,
        })
    }

    handleOutsideClick = (e) => {
        if (
            document.querySelector(`#ff-combobox-${this.props.id}`) &&
            !document
                .querySelector(`#ff-combobox-${this.props.id}`)
                .contains(e.target)
        ) {
            this.closeDropdown()
        }
    }

    buildQuery(value) {
        return this.props.field
            ?.split('|')
            ?.map((f) => `contains(${f}, '${encodeURI(value)}')`)
            ?.join(' or ')
    }

    handleSearch = (e) => {
        if (
            document.querySelector(`#ff-combobox-${this.props.id}`) &&
            document
                .querySelector(`#ff-combobox-${this.props.id}`)
                .contains(e.target)
        ) {
            // Scroll to top
            const listDiv = document.getElementById(
                `ff-combobox-list-${this.props.id}`
            )
            if (listDiv) listDiv.scrollTop = 0

            if (!this.state.searchLocally) {
                const search = this.buildQuery(e.target.value)
                this.setState({
                    page: 1,
                    searchProperty: search ? `(${search})` : '',
                    isLoaded: true,
                    isSearching: true,
                })

                clearTimeout(timeout)
                const self = this
                timeout = setTimeout(() => {
                    self.fetchData(1, true)
                }, 1000)
            } else {
                this.handleLocalSearch(e.target.value)
            }
        }
    }

    handleLocalSearch = (keyword) => {
        let newData = []
        _.forEach(this.props.options, (option) => {
            if (
                option.value !== undefined &&
                option.value.toLowerCase().includes(keyword.toLowerCase())
            ) {
                newData.push(option)
            }
        })

        this.setState({
            data: newData,
        })
    }

    handleOnChange = (e) => {
        const { type, isNumber, onSelect } = this.props
        let selected = []
        const val = isNumber
            ? parseInt(e.target.value)
            : encodeURI(e.target.value)
        if (e.target.checked) {
            selected = this.state.checked
            selected.push(val)
        } else {
            selected = this.state.checked.filter((item) => item !== val)
        }

        this.setState({
            checked: selected,
        })

        onSelect(selected, type, this.props)
    }

    handleScroll = (e) => {
        if (!this.state.isEndData && !this.state.isSearching) {
            if (
                e.target.scrollTop + e.target.clientHeight >
                e.target.scrollHeight - 2
            ) {
                const self = this

                clearTimeout(scroll)

                scroll = setTimeout(() => {
                    this.setState({
                        isSearching: true,
                    })
                    self.handleChangePage(self.state.page + 1)
                }, 200)
            }
        }
    }

    handleChangePage = async (page) => {
        this.fetchData(page)
        this.setState({
            page: page,
        })
    }

    toggleDropdown = (e) => {
        if (this.state.isOpen) {
            this.closeDropdown()
        } else {
            this.openDropdown()
        }
    }

    openDropdown = () => {
        this.setState({
            isOpen: true,
        })

        delay(() => {
            document.getElementById('ff-combobox-search-input')?.focus()
        }, 200)

        if (this.state.data.length < 1) {
            this.fetchData()
        }
    }

    closeDropdown = () => {
        this.setState({
            isOpen: false,
        })
    }

    getOptionValue(option) {
        const { customValue, overrideOptionValue } = this.props

        return customValue
            ? option[customValue]
            : overrideOptionValue
            ? overrideOptionValue(option)
            : option.value
    }

    getOptionKey(option) {
        const { customKey, overrideOptionKey } = this.props

        return customKey
            ? option[customKey]
            : overrideOptionKey
            ? overrideOptionKey(option)
            : option.key
    }

    getOptionLabel(option, optvalue, optkey) {
        const { overrideOptionLabel } = this.props

        return overrideOptionLabel
            ? overrideOptionLabel(option)
            : this.formatOptionLabel(optvalue, optkey)
    }

    formatOptionLabel(value, key) {
        const { formatLabel, alwaysShowLabelAndValue } = this.props
        const { setSuffix } = this.state
        if (formatLabel) {
            let valueStr = formatLabel.replace(/%VALUE%/gi, value)
            valueStr = valueStr.replace(/%KEY%/gi, key)

            return valueStr
        }

        if (alwaysShowLabelAndValue) {
            return `${value ?? ''} [${key ?? ''}]`.trim()
        }
        return value && setSuffix ? `${value} [${key}]` : value ?? `[${key}]`
    }

    renderData() {
        return (
            <>
                {this.state.data.length > 0 ? (
                    <ul
                        onScroll={this.handleScroll}
                        id={`ff-combobox-list-${this.props.id}`}
                    >
                        {this.state.data.map((option, k) => {
                            const optvalue = this.getOptionValue(option)
                            const optkey = this.getOptionKey(option)
                            const optlabel = this.getOptionLabel(
                                option,
                                optvalue,
                                optkey
                            )

                            return (
                                <li key={k}>
                                    <Form.Check
                                        label={optlabel}
                                        name="options"
                                        type="checkbox"
                                        id={`options-${k}`}
                                        value={optkey}
                                        checked={this.state.checked.includes(
                                            optkey
                                        )}
                                        onChange={this.handleOnChange}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                ) : (
                    <div className="text-center py-3">No option found</div>
                )}
            </>
        )
    }

    isFixed() {
        return this.props.isFixed === true || this.props.isFixed === undefined
    }

    render() {
        return (
            <div
                id={`ff-combobox-${this.props.id}`}
                className={`ff-combobox ${this.props.className ?? ''} ${
                    this.isFixed() ? 'mb-2' : 'ff-fixed-combo'
                }`}
                style={{
                    zIndex: 9999 + (this.props.zIndex || 0),
                }}
            >
                <div className="position-relative">
                    {this.props.baseVariant == 'button' ? (
                        <Button
                            href="#"
                            to="#"
                            variant="white"
                            size="sm"
                            className="fw-bold btn-icon btn-action"
                            onClick={this.toggleDropdown}
                        >
                            {this.props.label}
                        </Button>
                    ) : (
                        <div
                            className={`d-flex justify-content-between align-items-center rounded-1 bg-white position-relative ${
                                this.isFixed()
                                    ? 'px-2 border border-sand'
                                    : 'ps-2'
                            }`}
                        >
                            <span
                                className="py-2 w-100"
                                onClick={this.toggleDropdown}
                            >
                                {this.props.label}
                                {this.state.checked.length > 0 ? (
                                    <span className="total-selected">
                                        {this.state.checked.length}
                                    </span>
                                ) : null}
                            </span>
                            <div className="d-flex align-items-center">
                                <i
                                    className={`ri-arrow-drop-${
                                        this.state.isOpen ? 'up' : 'down'
                                    }-line ri-2x`}
                                    onClick={this.toggleDropdown}
                                ></i>
                            </div>
                        </div>
                    )}
                </div>

                {this.state.isOpen ? (
                    <div
                        className={`ff-combobox-dropdown ff-pos-${
                            this.props.position ?? 'left'
                        }`}
                    >
                        <div className="ff-combobox-dropdown-header">
                            <div className="ff-combobox-dropdown-header-title d-flex justify-content-between">
                                <span>
                                    {this.props.title ?? 'Select Options'}
                                </span>
                                {this.state.isSearching && (
                                    <Spinner size="sm"></Spinner>
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center ps-3">
                                <i className="ri-search-line fs-6"></i>
                                <Form.Control
                                    type="text"
                                    placeholder={
                                        this.props.searchPlaceholder ??
                                        `Search ${this.props.label}`
                                    }
                                    disabled={!this.state.isLoaded}
                                    className="search-input pe-3"
                                    id="ff-combobox-search-input"
                                    value={this.state.searchValue}
                                    onChange={(e) =>
                                        this.setState({
                                            searchValue: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="ff-combobox-dropdown-body">
                            {this.state.isLoaded ? (
                                this.renderData()
                            ) : (
                                <div className="py-3 d-flex align-items-center justify-content-center">
                                    <Spinner size="sm" />
                                    <span className={'ms-2'}>Loading...</span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default ComboBox
