import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Form,
    Row,
    Col,
    Table,
    Button,
    Breadcrumb,
    Container,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import { fakeData } from '../../../factory/fakeFactory'
import ListFilter from '../../../components/ListFilter'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { FooterFormAction } from '../../../components/Form'
import { ActionBarVertical } from '../../../components/ActionBar'

class JournalEntryRecurringAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: fakeData(3),
            dataIsLoaded: false,
        }
    }

    handleDateChange = (date) => {
        this.setState((prevState) => (prevState.startDate = date))
    }

    componentInit() {
        this.setTitle('Recurring Journal Entries').setFormAction(true)

        // Set API calls.
        this.setApiURL('/data/products.json')
            .setApiParams({ responseKey: 'response' })
            .enableSearchAndSortTable()
            .fetchData()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        this.setState((prev) => {
            prev.showTableSearch = !prev.showTableSearch
            return prev
        })
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                // eslint-disable-next-line
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                // eslint-enable-next-line
            }

            prevState.totalChecked = totalChecked

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[i] = true
                        document.getElementById(
                            'chk-projectview-items-' + i
                        ).checked = true
                        return null
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.generalLedger.journalEntry.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.generalLedger.journalEntry.base,
                            }}
                            linkAs={Link}
                        >
                            General Ledger
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.generalLedger.journalEntry
                                    .recurringJournalEntry,
                            }}
                            linkAs={Link}
                        >
                            Recurring Journal Entries
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Add Recurring Journal Entries
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Add Recurring Journal Entries
                    </HeaderLight.Title>

                    <div>
                        <Button
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            href={
                                URI.generalLedger.journalEntry
                                    .recurringJournalEntry
                            }
                        >
                            Cancel
                        </Button>
                        {/* Submit Button */}
                        <Button variant="primary">Save</Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderListFilter() {
        return (
            <>
                <ListFilter expandable={0}>
                    <ListFilter.Actions md={12} lg={12}>
                        <ListFilter.Action className="ms-auto">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderVerticalActionBar() {
        return (
            <ActionBarVertical>
                <ActionBarVertical.Links>
                    <li>
                        <NavLink
                            to="/"
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-add-line mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/"
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-edit-fill mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/" className="d-flex align-items-center">
                            <i className="ri-close-fill mx-0"></i>
                        </NavLink>
                    </li>
                </ActionBarVertical.Links>
            </ActionBarVertical>
        )
    }

    renderContent() {
        return (
            <Form.Group className="mb-5">
                <Row xs={1}>
                    <Col>
                        <Row className="mb-4">
                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-lg-0 mb-2"
                                    >
                                        <Form.Label className="mb-0">
                                            Entry Information
                                        </Form.Label>
                                    </Col>
                                    <Col lg={4} className="mb-3 mb-lg-0">
                                        <Form.Control
                                            type="text"
                                            placeholder="Entry No."
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Description"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={5}>
                                <Button
                                    variant="primary"
                                    style={{ minWidth: '120px' }}
                                >
                                    Create Reminder
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {this.renderListFilter()}

                <Row className="py-2">
                    <Col
                        sm={12}
                        className="mb-3 mb-lg-0 position-relative table-action-bar"
                    >
                        {this.renderVerticalActionBar()}

                        <div className="table-gradient">
                            <Table
                                striped
                                responsive
                                className="a-table table-check"
                            >
                                <thead>
                                    <tr key="0" className="a-table-heading">
                                        <th className="ps-4">
                                            <span className="sort active asc">
                                                Account No.
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Account Name
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Debit Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Credit Amount
                                            </span>
                                        </th>
                                    </tr>
                                    <tr
                                        className={`a-table-search-fields ${
                                            this.state.showTableSearch
                                                ? ''
                                                : 'd-none'
                                        }`}
                                    >
                                        <th className="ps-4">
                                            <Form.Control
                                                type="text"
                                                data-field="projectCodeNo"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => {
                                        if (i < 5) {
                                            return (
                                                <tr
                                                    key={i}
                                                    className={
                                                        this.state.checks[i]
                                                            ? `active`
                                                            : ''
                                                    }
                                                >
                                                    <td className="ps-4">
                                                        {item.projectCodeNo}
                                                    </td>
                                                    <td>{item.excerpt}</td>
                                                    <td>
                                                        {item.projectBudget}.00
                                                    </td>
                                                    <td>
                                                        {item.projectBudget}.00
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        return ''
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(JournalEntryRecurringAdd)
