import FormCurrencyInput from 'app/components/form/FormCurrencyInput'
import { Controller, useFormContext } from 'react-hook-form'
import Label from '../../../components/label/Label'
import React from 'react'
import { WhatChanged } from 'lib/api/types/WhatChangedItems'

const Budget = ({
    disabled,
    handleRecalculate,
}: {
    disabled: boolean
    handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void
}) => {
    const methods = useFormContext()
    return (
        <>
            <Label label="Unit Budget" className="tw-lg:col-span-1" />
            <Controller
                control={methods.control}
                name="unitbudget"
                render={({ field }) => (
                    <FormCurrencyInput
                        style={{
                            height: '2.563rem',
                        }}
                        id="Unit Budget"
                        value={field.value}
                        disabled={disabled}
                        maxLength={15}
                        onValueChange={field.onChange}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                field.onChange('0.00')
                            }

                            handleRecalculate(WhatChanged.UNIT_BUDGET)
                        }}
                    />
                )}
            />
            <div className="tw-flex tw-flex-col tw- xl:tw-flex-row tw-items-center !tw-gap-4 tw-w-full">
                <Label
                    label="Extended Budget"
                    className="tw-whitespace-nowrap"
                />
                <Controller
                    control={methods.control}
                    name="budget"
                    render={({ field }) => (
                        <FormCurrencyInput
                            style={{
                                height: '2.563rem',
                            }}
                            id="Extended Budget"
                            value={field.value}
                            disabled={disabled}
                            maxLength={15}
                            onValueChange={field.onChange}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    field.onChange('0.00')
                                }
                                handleRecalculate(WhatChanged.EXTENDED_BUDGET)
                            }}
                        />
                    )}
                />
            </div>
        </>
    )
}

Budget.displayName = 'Budget'

export default Budget
