import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import URI from 'defaults/RoutesDefault'
import { Component } from 'lib/api/types/Component'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

const PurchaseComponentsButton = ({
    isEdit,
    disableDelete,
    onDeleteClick,
    submitMethods,
    disableAdd,
    components,
}: {
    submitMethods: () => Promise<void>
    components: Component[]
    isEdit: boolean
    disableDelete: boolean
    disableAdd: boolean
    onDeleteClick: () => void
}) => {
    const { id: projectId, itemId } = useParams()

    const navigate = useNavigate()

    const firstCompId = components?.[0]?.id

    return (
        <div className="tw-flex tw-gap-4">
            {isEdit && components?.length > 0 && (
                <SecureBootstrapButton
                    attributeNo={14}
                    attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                    variant="ivory"
                    size="sm"
                    disabled={disableDelete}
                    onClick={onDeleteClick}
                >
                    <i className="ri-close-line" /> Delete
                </SecureBootstrapButton>
            )}
            <SecureBootstrapButton
                attributeNo={14}
                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                variant="primary"
                size="sm"
                onClick={
                    isEdit
                        ? () =>
                              navigate(
                                  generatePath(URI.project.newComponentEdit, {
                                      id: projectId,
                                      itemId: itemId,
                                      componentId: firstCompId,
                                  })
                              )
                        : submitMethods
                }
                disabled={disableAdd || (isEdit && !firstCompId)}
            >
                <i className="ri-add-line" /> Add
            </SecureBootstrapButton>
        </div>
    )
}
PurchaseComponentsButton.displayName = 'PurchaseComponentsButton'
export default PurchaseComponentsButton
