import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import ThreadComponent from '../../ThreadComponent'
import { MultiSearchComponentsProps } from '../../../../app/components/multi-search/MultiSearchComponents'
import { renderItemImage } from '../../../../utilities/Images'
import URI from '../../../../defaults/RoutesDefault'
import { ApiService } from '../../../../lib/api/HttpService'
import { encodeURI } from '../../../../utilities/String'
import { isEmpty } from 'lodash'
import {
    displayAlertError,
    displayAlertLoader,
    getErrorMessage,
    hideAlertLoader,
} from '../../../../utilities/Response'
import { getStatusColor } from '../../../../helpers/Util'

const api = new ApiService()

class ItemSearch extends ThreadComponent {
    constructor(props: any) {
        super(props)
    }

    render() {
        return <Outlet />
    }
}

const buildFilters = (type: string) => {
    switch (type) {
        case 'client':
        case 'vendor':
        case 'salesperson':
            return `$filter=inactive eq false `
        case 'project':
            return `$filter=closeddt eq null `
        case 'account':
            return ``
        case 'ship-to':
            return '$filter=addresstype eq 1 and inactive eq false '
        case 'locations':
            return '$filter=projectId eq null '
        default:
            return ``
    }
}

const ProjectIsOpen = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'closeddt eq null',
        label: 'Yes',
    },
    {
        value: 'closeddt ne null',
        label: 'No',
    },
]

const ItemIsCompleted = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'completed eq true',
        label: 'Yes',
    },
    {
        value: 'completed eq false',
        label: 'No',
    },
]

const ItemIsActive = [
    {
        value: '',
        label: 'All',
    },
    {
        value: '(completed eq false and inactive eq false)',
        label: 'Yes',
    },
    {
        value: '(completed eq true or inactive eq true)',
        label: 'No',
    },
]

const CFARequired = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'cfaRequired eq true',
        label: 'Yes',
    },
    {
        value: 'cfaRequired eq false',
        label: 'No',
    },
]

const Taxable = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'taxable eq true',
        label: 'Yes',
    },
    {
        value: 'taxable eq false',
        label: 'No',
    },
]

const ItemSearchFields: MultiSearchComponentsProps[] = [
    {
        type: 'combobox',
        id: 'project-dropdown',
        title: 'Select Project',
        label: 'Projects',
        apifield: 'proj',
        moduletype: 'project',
        field: 'projn|proj',
        getDataFn: 'getProjectsSummaries',
        queryFilter: buildFilters('project'),
        isFixed: true,
        style: {
            minWidth: '110px',
        },
    },
    {
        type: 'react-select',
        id: 'project-is-open',
        label: 'Project is open',
        apifield: 'closeddt',
        isFixed: true,
        options: ProjectIsOpen,
        placeholder: 'Project is open',
        style: {
            minWidth: '150px',
        },
    },
    {
        type: 'combobox',
        id: 'client-dropdown',
        title: 'Select Client',
        label: 'Client',
        apifield: 'client',
        moduletype: 'client',
        queryFilter: buildFilters('client'),
        field: 'ClientName|client',
        getDataFn: 'getClientsSummaries',
        isFixed: true,
        style: {
            minWidth: '96px',
        },
    },
    {
        type: 'combobox',
        id: 'salesperson-dropdown',
        title: 'Select Salesperson',
        label: 'Salesperson',
        apifield: 'manager',
        moduletype: 'salesperson',
        queryFilter: buildFilters('salesperson'),
        field: 'vendorn|vendor',
        getDataFn: 'getClientEmployeesSummaries',
        isFixed: true,
        style: {
            minWidth: '132px',
        },
    },
    {
        type: 'text',
        id: 'description',
        label: 'Description',
        apifield: 'shortdesc|shortCompDesc|componentDesc|itemDesc',
        isFixed: false,
        style: {
            minWidth: '140px',
        },
    },
    {
        type: 'combobox',
        id: 'statuscode-dropdown',
        title: 'Select Item Status',
        label: 'Item Status',
        apifield: 'statusNumber',
        moduletype: 'status-code',
        queryFilter: buildFilters('status-code'),
        field: 'statusName',
        getDataFn: 'getStatusCodes',
        isFixed: true,
        style: {
            minWidth: '128px',
        },
        customKey: 'statusNumber',
        customValue: 'statusName',
        formatLabel: '%VALUE%',
        fetchAll: true,
        isNumber: true,
    },
    {
        type: 'combobox',
        id: 'location-dropdown',
        title: 'Select Item Locations',
        label: 'Item Locations',
        apifield: 'loc',
        moduletype: 'item-location',
        queryFilter: buildFilters('locations'),
        field: 'locn|loc',
        getDataFn: 'getLocationsSummaries',
        isFixed: true,
        style: {
            minWidth: '148px',
        },
        customValue: 'key',
        overrideOptionKey: (option: any) => {
            return `${option.key}`
        },
        overrideOptionLabel: (item: any) => {
            return `${item.value} [${item.key}]`
        },
    },
    {
        type: 'combobox',
        id: 'salescategory-dropdown',
        title: 'Select Sales Category',
        label: 'Sales Category',
        apifield: 'scat',
        moduletype: 'sales-category',
        queryFilter: buildFilters('sales-category'),
        field: 'scatn|scat',
        getDataFn: 'getSalesCategoriesSummaries',
        map: (data) => {
            return data.map((option: any) => ({
                ...option,
                value: option.value === '' ? null : option.value,
            }))
        },
        isFixed: false,
        style: {
            minWidth: '168px',
        },
    },
    {
        type: 'combobox',
        id: 'spectemplate-dropdown',
        title: 'Select Spec Template',
        label: 'Spec Template',
        apifield: 'speccode',
        moduletype: 'spec-template',
        queryFilter: buildFilters('spec-template'),
        field: 'speccode|speccodename',
        getDataFn: 'getSpecificationTemplates',
        isFixed: false,
        style: {
            minWidth: '168px',
        },
        customKey: 'speccode',
        customValue: 'speccodename',
    },
    {
        type: 'text',
        id: 'plan-number',
        label: 'Item Plan No',
        apifield: 'planno',
        isFixed: false,
        min: 0,
        style: {
            maxWidth: '130px',
        },
    },
    {
        type: 'react-select',
        id: 'item-completed',
        label: 'Item Completed',
        isFixed: false,
        apifield: 'completed',
        options: ItemIsCompleted,
        placeholder: 'Item Completed',
        style: {
            minWidth: '150px',
        },
    },
    {
        type: 'react-select',
        id: 'item-active',
        label: 'Item Active',
        apifield: 'inactive',
        isFixed: false,
        options: ItemIsActive,
        placeholder: 'Item Active',
        style: {
            minWidth: '150px',
        },
    },
    {
        type: 'number',
        id: 'proposal-number',
        label: 'Proposal No',
        apifield: 'latestPoNum',
        isFixed: false,
        style: {
            maxWidth: '130px',
        },
    },
    {
        type: 'date-range',
        id: 'proposal-date',
        label: 'Proposal Date',
        apifield: 'latestPrpDt',
        isFixed: false,
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'number',
        id: 'invoice-number',
        label: 'Invoice No',
        apifield: 'latestInvNo',
        isFixed: false,
        style: {
            maxWidth: '130px',
        },
        isNumber: true,
    },
    {
        type: 'date-range',
        id: 'invoice-date',
        label: 'Invoice Date',
        apifield: 'latestInvDt',
        isFixed: false,
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'number',
        id: 'client-check-number',
        label: 'Client Check No',
        apifield: 'lastPaymentCkNo',
        isFixed: false,
        style: {
            maxWidth: '130px',
        },
    },
    {
        type: 'date-range',
        id: 'delivery-date',
        label: 'Delivery Date',
        apifield: 'lastDelDt',
        isFixed: false,
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'combobox',
        id: 'component-type-dropdown',
        title: 'Select Component Type',
        label: 'Component Type',
        apifield: 'compType',
        moduletype: 'comptype',
        isFixed: false,
        isNumber: true,
        style: {
            minWidth: '178px',
        },
        options: [
            {
                key: '0',
                value: 'Merchandise',
            },
        ],
        formatLabel: '%VALUE%',
    },
    {
        type: 'combobox',
        id: 'vendor-dropdown',
        title: 'Select Vendors',
        label: 'Vendor',
        apifield: 'supplier',
        moduletype: 'vendor',
        queryFilter: buildFilters('vendor'),
        field: 'vendorn|vendor',
        map: (data) => {
            return data.map((option: any) => ({
                ...option,
                value: option.value === '' ? null : option.value,
            }))
        },
        getDataFn: 'getVendorsSummaries',
        isFixed: false,
        style: {
            minWidth: '120px',
        },
    },
    {
        type: 'combobox',
        id: 'shipto-dropdown',
        title: 'Select Ship To',
        label: 'Ship To',
        apifield: 'shipTo',
        moduletype: 'ship-to',
        queryFilter: buildFilters('ship-to'),
        field: 'attention|code',
        getDataFn: 'getAddressesSummaries',
        isFixed: false,
        style: {
            minWidth: '122px',
        },
    },
    {
        type: 'text',
        id: 'catalog-number',
        label: 'Mfg Catalog No',
        apifield: 'catalog',
        isFixed: false,
        style: {
            maxWidth: '150px',
        },
    },
    {
        type: 'text',
        id: 'stock-number',
        label: 'Stock No',
        apifield: 'stockNo',
        isFixed: false,
        style: {
            maxWidth: '150px',
        },
    },
    {
        type: 'text',
        id: 'storage-location',
        label: 'Storage Location',
        apifield: 'statusnote',
        isFixed: false,
        style: {
            maxWidth: '150px',
        },
    },
    {
        type: 'date-range',
        id: 'ship-onbefore-date',
        label: 'Ship On or Before',
        apifield: 'latestshpdt',
        isFixed: false,
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'react-select',
        id: 'cfa-required',
        label: 'CFA Required',
        isFixed: false,
        apifield: 'cfaRequired',
        options: CFARequired,
        placeholder: 'CFA Required',
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'date-range',
        id: 'cfa-date',
        label: 'CFA Date',
        apifield: 'cfadt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'date-range',
        id: 'user-date-1',
        label: 'User Date 1',
        apifield: 'wc1dt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'date-range',
        id: 'user-date-2',
        label: 'User Date 2',
        apifield: 'wc2dt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'react-select',
        id: 'taxable',
        label: 'Taxable',
        apifield: 'taxable',
        isFixed: false,
        options: Taxable,
        placeholder: 'Taxable',
        style: {
            minWidth: '130px',
        },
    },
    {
        type: 'text',
        id: 'purchase-order-number',
        label: 'Purchase Order No',
        apifield: 'latestponum',
        isFixed: false,
        style: {
            maxWidth: '170px',
            minWidth: '170px',
        },
        operator: 'eq',
    },
    {
        type: 'date-range',
        id: 'ordered-date',
        label: 'Ordered Date',
        apifield: 'latestOrdDt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'text',
        id: 'acknowledgement-no',
        label: 'Acknowledgement No',
        apifield: 'latestAckNo',
        isFixed: false,
        style: {
            maxWidth: '190px',
            minWidth: '190px',
        },
        operator: 'eq',
    },
    {
        type: 'date-range',
        id: 'acknowledgement-date',
        label: 'Acknowledgement Date',
        apifield: 'latestAckDt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'date-range',
        id: 'exship-date',
        label: 'Ex Ship Date',
        apifield: 'latestexpdt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'text',
        id: 'vendor-invoice-no',
        label: 'Vendor Invoice No',
        apifield: 'latestsinno',
        isFixed: false,
        style: {
            maxWidth: '170px',
            minWidth: '170px',
        },
        operator: 'eq',
    },
    {
        type: 'date-range',
        id: 'vendor-invoice-date',
        label: 'Vendor Invoice Date',
        apifield: 'latestsindt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'date-range',
        id: 'received-date',
        label: 'Received Date',
        apifield: 'latestRecDt',
        isFixed: false,
        style: {
            minWidth: '100px',
        },
    },
    {
        type: 'text',
        id: 'vendor-check-no',
        label: 'Vendor Check No',
        apifield: 'lastcheckno|lastsupdepcheckno',
        isFixed: false,
        style: {
            maxWidth: '170px',
            minWidth: '170px',
        },
        operator: 'eq',
    },
]

const handleOpenEditItem = async (e: any, item: any, classRef: any) => {
    e.preventDefault()

    try {
        displayAlertLoader(
            'Redirecting to edit item page, please wait<span class="dot-load"></span>'
        )
        const itemData: any = await api.getProjectItems(
            `?$filter=item eq '${item.item}' and proj eq '${encodeURI(
                item.proj
            )}'`
        )
        hideAlertLoader()
        if (!isEmpty(itemData)) {
            classRef.navigate(
                routeParam(URI.project.itemEdit, {
                    id: itemData[0].projectId,
                    itemId: itemData[0].id,
                })
            )
        } else {
            displayAlertError("Oops, can't find the item. Please try again.")
        }
    } catch (error) {
        displayAlertError(getErrorMessage(error))
    }
}

const handleDocument = async (e: any, id: any, type: string) => {
    e.preventDefault()

    if (type === 'Invoice') {
        const [invoice]: any = await api.getInvoices(`?$filter=invnum eq ${id}`)
        id = invoice?.id ?? id
    } else if (type === 'Proposal' && id.split('-').length === 2) {
        const ids = id.split('-')
        const proj = ids[0]
        const propNum = ids[1]
        const [proposal]: any = await api.getProjectProposals(
            `?$filter=proj eq '${proj}' AND propnum eq '${propNum}'`
        )
        id = proposal?.id ?? id
    }

    try {
        let document: any = {}
        if (type === 'VendorOrder' && id.split('-').length === 2) {
            const ids = id.split('-')
            const proj = ids[0]
            const poNum = ids[1]
            const vendorOrder: any = await api.getProjectPurchaseOrders(
                `?$filter=proj eq '${proj}' AND ponum eq '${poNum}'`
            )

            document = await api.getPublicMappings(
                vendorOrder[0].fileId,
                vendorOrder[0].purchaseOrderName
            )
        } else {
            document = await api.postJson('publicmappingbatches', {
                publicMappingObjects: [
                    {
                        objectId: id,
                        objectType: type,
                    },
                ],
            })
        }

        window.open(document.link, '_blank')!.focus()
    } catch (error: any) {
        displayAlertError(getErrorMessage(error))
    }
}

const getItemSearchTableHeaders = (classRef: any) => [
    {
        label: 'Image',
        field: 'primaryImageId',
        tdClass: 'td-1',
        override: (item: any, field: any) => {
            return renderItemImage({
                item,
                imagesLoaded: classRef.state.imagesLoaded,
                imageData: classRef.state.imageData,
                property: 'primaryImageId',
                altProperty: 'shortCompDesc',
            })
        },
    },
    {
        label: 'Ref. No',
        sortable: true,
        searchable: true,
        field: 'refnum',
        override: (item: any, field: any) => {
            return (
                <Link
                    className="text-charcoal hover-view-icon"
                    to={item.refUrl}
                >
                    {item.refnum}
                </Link>
            )
        },
    },
    {
        label: 'Project',
        sortable: true,
        searchable: true,
        field: 'proj',
    },
    {
        label: 'Location',
        sortable: true,
        searchable: true,
        field: 'loc',
    },
    {
        label: 'Proposal No',
        sortable: true,
        searchable: true,
        field: 'latestPrpNo',
        override: (item: any) => {
            return item.latestPrpNo ? (
                <a
                    href=""
                    onClick={(e) => {
                        handleDocument(
                            e,
                            `${item.proj}-${item.latestPrpNo}`,
                            'Proposal'
                        )
                    }}
                >
                    {item.latestPrpNo}
                </a>
            ) : (
                ''
            )
        },
    },
    {
        label: 'Invoice No',
        sortable: true,
        searchable: true,
        field: 'latestInvNo',
        override: (item: any) => {
            return item.latestInvNo ? (
                <a
                    href=""
                    onClick={(e) => {
                        handleDocument(e, item.latestInvNo, 'Invoice')
                    }}
                >
                    {item.latestInvNo}
                </a>
            ) : (
                ''
            )
        },
    },
    {
        label: 'PO No',
        sortable: true,
        searchable: true,
        field: 'latestPoNum',
        override: (item: any) => {
            return item.latestPoNum ? (
                <a
                    href=""
                    onClick={(e) => {
                        handleDocument(
                            e,
                            `${item.proj}-${item.latestPoNum}`,
                            'VendorOrder'
                        )
                    }}
                >
                    {item.latestPoNum}
                </a>
            ) : (
                ''
            )
        },
    },
    {
        label: 'Sales Cat.',
        sortable: true,
        searchable: true,
        field: 'scat',
    },
    {
        label: 'Item description',
        sortable: true,
        searchable: true,
        field: 'itemDesc',
        className: 'description',
    },
    {
        label: 'Status',
        sortable: false,
        searchable: true,
        field: 'statusNumber',
        override: (item: any) => {
            /* eslint-disable */
            const statusName = classRef.state.statusNames[item.statusNumber]
            return (
                <strong
                    className={`dot`}
                    style={
                        {
                            '--color':
                                (statusName &&
                                    getStatusColor(
                                        statusName,
                                        classRef.state.statuses
                                    )) ??
                                'white',
                        } as React.CSSProperties
                    }
                >
                    {statusName}
                </strong>
            )
            /* eslint-enable */
        },
        className: 'mw-140px',
    },
    {
        label: 'Comp. description',
        sortable: true,
        searchable: true,
        field: 'componentDesc',
    },
    {
        label: 'Vendor',
        sortable: true,
        searchable: true,
        field: 'supplier',
    },
    {
        label: 'Ship To',
        sortable: true,
        searchable: true,
        field: 'shipTo',
    },
]

export { ItemSearchFields, getItemSearchTableHeaders }
export default WithRouter(ItemSearch)
