import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiService } from 'lib/api/HttpService'
import { Summary } from 'lib/api/types/Summary'
import { uniqBy } from 'lodash'
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown'

const api = new ApiService()

const getAddresses = async () => {
    const addresses = (await api.getAddressesSummaries()) as Summary[]
    const uniqueAddresses = uniqBy(addresses, (obj) => obj.key)
    return uniqueAddresses
}

const AddressesDropdown = (props: TBaseDropdownProps) => {
    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'addressesSummaries'],
        queryFn: getAddresses,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const options =
        data?.map((option) => ({
            label: `${option.value ?? ''} ${
                option.key ? `[${option.key}]` : ''
            }`.trim(),
            value: option.key,
        })) || []

    return <BaseDropdown {...props} isLoading={isLoading} options={options} />
}

AddressesDropdown.displayName = 'AddressesDropdown'

export default AddressesDropdown
