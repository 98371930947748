import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'

const useOutsideClick = (callback) => {
    const ref = useRef()

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [ref])

    return ref
}

const SearchableComboboxPopup = forwardRef(
    ({ options, text, onTextChange, onCheck }, ref) => {
        return (
            <div
                ref={ref}
                className="ff-combobox-dropdown position-absolute"
                style={{ zIndex: 9999, maxHeight: '230px' }}
            >
                <div className="ff-combobox-dropdown-header">
                    <div className="ff-combobox-dropdown-header-title d-flex justify-content-between">
                        <span>Select Options</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                        <i className="ri-search-line fs-6"></i>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            className="search-input pe-3"
                            id="ff-combobox-search-input"
                            value={text}
                            onChange={onTextChange}
                        />
                    </div>
                </div>
                <div className="ff-combobox-dropdown-body">
                    <ul style={{ minHeight: 'unset' }}>
                        {options?.map((option, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    onCheck(option.value)
                                }}
                            >
                                <Form.Check
                                    label={option.label ?? `Option ${index}`}
                                    type="checkbox"
                                    checked={option.checked}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
)
SearchableComboboxPopup.displayName = 'SearchableComboboxPopup'

const Button = ({ label, isExpanded, handleExpand, counter }) => {
    return (
        <div
            className="d-flex justify-content-between align-items-center rounded-1 bg-white position-relative ps-2 border"
            style={{ border: '--bs-border-color' }}
            onClick={handleExpand}
        >
            <span className="d-flex align-items-center">
                {label}
                {counter > 0 ? (
                    <span className="total-selected">{counter}</span>
                ) : null}
            </span>
            <div className="d-flex align-items-center">
                <i
                    className={`ri-arrow-drop-${
                        isExpanded ? 'up' : 'down'
                    }-line ri-2x`}
                    onClick={handleExpand}
                ></i>
            </div>
        </div>
    )
}

const SearchableCombobox = ({ label, options, onCheck }) => {
    const [isExpanded, setExpanded] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [text, setText] = useState('')
    const handleExpand = () => {
        setExpanded(!isExpanded)
    }
    const handleText = (e) => {
        setText(e.target.value)

        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setFilteredOptions(filteredOptions)
    }
    const handleOutsideClick = (e) => {
        setExpanded(false)
    }
    const popupRef = useOutsideClick(handleOutsideClick)
    const countChecked = () => {
        return options.filter((option) => option.checked).length
    }

    useEffect(() => {
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(text.toLowerCase())
        )
        setFilteredOptions(filteredOptions)
    }, [options])

    return (
        <div className="ff-combobox">
            <Button
                label={label}
                isExpanded={isExpanded}
                handleExpand={handleExpand}
                counter={countChecked()}
            />
            {isExpanded && (
                <SearchableComboboxPopup
                    ref={popupRef}
                    text={text}
                    options={filteredOptions}
                    onTextChange={handleText}
                    onCheck={onCheck}
                />
            )}
        </div>
    )
}

export default SearchableCombobox
