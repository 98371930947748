import Header from './components/Header'
import React, { useEffect, useRef, useState } from 'react'
import SalesCategoriesSelect from './components/SalesCategoriesSelect'
import { FormControl } from 'react-bootstrap'
import UnitsOfMeasureDropdown from 'app/components/dropdowns/UnitsOfMeasuresDropdown'
import WysiwygEditor from 'templates/ui-kit/WysiwygEditor'
import LocationSelect from './components/LocationSelect'
import Label from '../../components/label/Label'
import Deposit from './components/Deposit'
import useGetProject from 'lib/api/hooks/useGetProject'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Spinner from 'app/components/help/Spinner'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    hideAlertLoader,
} from 'utilities/Response'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { TTypeOption } from 'app/components/dropdowns/types/TTypeOption'
import Budget from './components/Budget'
import { WhatChanged, WhatChangedPayload } from 'lib/api/types/WhatChangedItems'
import useRecalculate from 'lib/api/hooks/useRecalculate'
import QuantityInput from './components/QuantityInput'
import useEditItem from 'lib/api/hooks/useEditItem'
import URI from 'defaults/RoutesDefault'
import useUploadFile from 'lib/api/hooks/useUploadFile'
import getItemDescription from 'utilities/getItemDescription'
import isValueANumber from 'utilities/isValueANumber'
import { AxiosError } from 'axios'
import { UserError } from 'lib/api/types/UserError'
import MSG from 'defaults/Message'
import useCreateComponent from 'lib/api/hooks/useCreateComponent'
import useGetItem from 'lib/api/hooks/useGetItem'
import { ItemFormValues } from './types/FormValues'
import ImageUploader from './components/Image'
import useUpdateFile from 'lib/api/hooks/useUpdateFile'
import useDeleteFile from 'lib/api/hooks/useDeleteFile'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'
import { useFeature } from 'use-feature'
import getPreloadValues from './utils/getPreloadValues'
import PurchaseComponentsTable from './components/PurchaseComponentsTable'
import useGetComponents from 'lib/api/hooks/useGetComponents'

type ItemsPageProps = {
    isEdit?: boolean
}

const ItemsPage = ({ isEdit }: ItemsPageProps) => {
    const { id: projectId, itemId } = useParams()
    const [description, setDescription] = useState('')

    const {
        data: project,
        isLoading: isProjectLoading,
        error: projectFetchError,
    } = useGetProject(projectId as string)

    const { mutateAsync: updateFile } = useUpdateFile()

    const { mutateAsync: deleteFile } = useDeleteFile()

    const {
        data: itemToEdit,
        isFetching: isLoadingItem,
        error: itemLoadingError,
    } = useGetItem(itemId as string, {
        enabled: !!isEdit,
    })

    const showFeature = useFeature(FeatureFlags.ItemsRefactor)

    const navigate = useNavigate()

    const { data: components } = useGetComponents(
        itemId as string,
        projectId as string
    )

    const { mutateAsync: createComponents } = useCreateComponent(
        project?.id as number
    )

    const { mutateAsync: editItem, isLoading: isEditing } = useEditItem()

    const { mutateAsync: uploadImage } = useUploadFile()

    const [whatChanged, setWhatChanged] = useState<WhatChangedPayload | object>(
        {}
    )

    const redirectToComponents = useRef(false)

    const {
        refetch: recalculate,
        data: recalculatedData,
        isFetching: isRecalculating,
    } = useRecalculate(itemToEdit?.id as number, JSON.stringify(whatChanged), {
        enabled:
            itemToEdit?.id !== undefined &&
            Object.values(whatChanged).length > 0,
    })

    const methods = useForm<ItemFormValues>({
        defaultValues: {
            quantity: '0',
            cldeppct: '0',
            description: {
                html: '',
                value: '',
            },
        },
    })

    const { setValue } = methods

    useEffect(() => {
        const preloadValues = async () => {
            if (isEdit && itemToEdit) {
                const preloadValues = await getPreloadValues(itemToEdit)
                setDescription(preloadValues.description.html)
                Object.entries(preloadValues).forEach(([name, value]) =>
                    setValue(name as keyof ItemFormValues, value)
                )
            }
        }
        preloadValues()
    }, [setValue, isEdit, itemToEdit])

    useEffect(() => {
        if (recalculatedData) {
            const {
                budget,
                unitBudget,
                clDepPct,
                quantity,
                estDep,
                estTPrice,
                estTCost,
            } = recalculatedData

            const newValues = {
                budget: String(budget),
                unitbudget: String(unitBudget),
                esttcost: String(estTCost),
                esttprice: String(estTPrice),
                estdep: String(estDep),
                cldeppct: String(clDepPct),
                quantity: String(quantity),
            }

            Object.entries(newValues).forEach(([name, value]) =>
                setValue(name as keyof ItemFormValues, value)
            )
        }
    }, [recalculatedData, setValue, whatChanged])

    if (isProjectLoading || isLoadingItem) {
        return <Spinner />
    }

    if (projectFetchError || itemLoadingError) {
        return <></>
    }

    const handleRecalculate = (whatPropertyChanged: `${WhatChanged}`) => {
        const formValues = methods.getValues()
        const newWhatChanged = {
            whatChanged: whatPropertyChanged,
            quantity: formValues.quantity || 0,
            clDepPct: formValues.cldeppct || 0,
            budget: formValues.budget || 0,
            unitBudget: formValues.unitbudget || 0,
            loc: formValues.loc?.value || null,
            scat: formValues.scat?.value || null,
        }

        // The query will refetch whenever whatChanged changes. But if there hasn´t been any change, we trigger a manual refetch
        if (JSON.stringify(whatChanged) === JSON.stringify(newWhatChanged)) {
            recalculate()
        }
        setWhatChanged(newWhatChanged)
    }

    const handleImage = async () => {
        const image = methods.watch('image')

        if (isEdit) {
            if (itemToEdit?.primaryImageId && !image) {
                await deleteFile(itemToEdit?.primaryImageId)
                return ''
            } else if (itemToEdit?.primaryImageId && image) {
                await updateFile({
                    id: itemToEdit?.primaryImageId,
                    file: image,
                })
                return itemToEdit?.primaryImageId
            }
        }
        if (image) {
            const uploadedImage = await uploadImage({
                file: image,
                ObjectType: 'item',
                ObjectId: itemToEdit?.id as number,
                FileType: 1,
            })
            return uploadedImage.id
        }
    }

    const onSubmit = async (data: ItemFormValues) => {
        displayAlertLoader(MSG.loading.update.msg)
        try {
            const itemDescription = getItemDescription(
                data.itemName,
                data.description
            )

            const uploadedImage = await handleImage()

            await editItem({
                ...itemDescription,
                itemName: data.itemName,
                id: itemToEdit?.id as number,
                primaryImageId: uploadedImage || '',
                budget: isValueANumber(data.budget),
                unitmeasure: data.unitmeasure?.label as string,
                estdep: isValueANumber(data.estdep),
                esttcost: isValueANumber(data.esttcost),
                esttprice: isValueANumber(data.esttprice),
                quantity: isValueANumber(data.quantity),
                scat: data.scat?.value as string,
                loc: data.loc?.value as string,
                cldeppct: isValueANumber(data.cldeppct),
            })

            const createAutoComponents =
                project?.autom ||
                project?.autod ||
                project?.autof ||
                project?.autoi ||
                project?.autol ||
                project?.autoo ||
                false

            const createCompPayload = {
                itemId: itemToEdit?.id as number,
                componentType: 0,
                associatedComponentNumber: '001',
                createAutoComponents,
                setDefaultsFromItem: true,
            }

            let newComponent

            // Only create components if there are none. This
            // is the case after the first manual save by the user
            if (components?.length === 0) {
                newComponent = await createComponents({
                    createComponentPayload: createCompPayload,
                    updatePriceAndCost: true,
                    updatedPriceAndCost: {
                        price: isValueANumber(data.esttprice),
                        cost: isValueANumber(data.esttcost),
                    },
                    createAutoComponents,
                })
            }
            hideAlertLoader()
            displayAlertSuccess(
                `Item ${isEdit ? 'edited' : 'created'} successfully!`
            )

            if (redirectToComponents.current && newComponent) {
                return navigate(
                    generatePath(
                        `${URI.project.newComponentEdit}?isFromAdd=true`,
                        {
                            id: projectId,
                            itemId: itemToEdit?.id as number,
                            componentId: newComponent?.id,
                        }
                    )
                )
            }
            navigate(
                generatePath(URI.project.projectItems, {
                    id: projectId,
                })
            )
        } catch (error) {
            redirectToComponents.current = false
            hideAlertLoader()
            displayAlertError(
                (error as AxiosError<UserError>)?.response?.data?.userError ||
                    'There was an error saving the information, please try again'
            )
        }
    }

    const handleErrors = () =>
        displayAlertError('Please enter a Sales Category')

    const disableButtons = isRecalculating || isEditing

    const nameInput = methods.register('itemName')
    const values = methods.getValues()

    const submitMethods = methods.handleSubmit(onSubmit, handleErrors)

    const submitFormFromOutside = async () => {
        redirectToComponents.current = true
        await submitMethods()
    }

    return showFeature ? (
        <FormProvider
            resetField={methods.resetField}
            getFieldState={methods.getFieldState}
            handleSubmit={methods.handleSubmit}
            watch={methods.watch}
            setError={methods.setError}
            getValues={methods.getValues}
            reset={methods.reset}
            setValue={methods.setValue}
            clearErrors={methods.clearErrors}
            control={methods.control}
            register={methods.register}
            unregister={methods.unregister}
            setFocus={methods.setFocus}
            trigger={methods.trigger}
            formState={methods.formState}
        >
            <form onSubmit={submitMethods}>
                <Header
                    budgetCheck={recalculatedData?.budgetCheck}
                    isEdit={typeof values.itemName === 'string'}
                    itemId={itemToEdit?.id as number}
                    disableButtons={disableButtons}
                />
                <div className="xl:tw-flex tw-!gap-4 tw-p-10 tw-flex-wrap">
                    <ImageUploader isEdit={!!isEdit} itemToEdit={itemToEdit} />
                    <div className="tw-flex tw-flex-col xl:tw-grid tw-items-center lg:tw-flex-1 tw-gap-x-6 tw-gap-y-4 tw-grid-cols-3">
                        <Label
                            label="Name"
                            className="tw-mt-[1rem] xl:tw-mt-[0]"
                        />
                        <FormControl
                            type="text"
                            className="tw-h-8 tw-col-span-2"
                            placeholder="Name"
                            id="Name"
                            name={nameInput.name}
                            onChange={nameInput.onChange}
                            ref={nameInput.ref}
                        />
                        <Label
                            label="Description"
                            className="tw-pt-4 tw-self-baseline"
                        />
                        <Controller
                            control={methods.control}
                            name="description"
                            render={({ field }) => (
                                <div className="tw-col-span-2 tw-w-full">
                                    <WysiwygEditor
                                        key={description}
                                        keepEmptySpaces
                                        triggerChangeOnLoad
                                        onChange={(e: {
                                            target: {
                                                html: string
                                                value: string
                                            }
                                        }) => field.onChange(e.target)}
                                    >
                                        {/* We are using description as key and props to trigger a state change on the Editor because the
                                        Editor internals are all messed up
                                         */}
                                        {description}
                                    </WysiwygEditor>
                                </div>
                            )}
                        />
                        <LocationSelect
                            disabled={isRecalculating}
                            handleRecalculate={handleRecalculate}
                        />
                        <Label label="Quantity" />
                        <QuantityInput
                            disabled={isRecalculating}
                            handleRecalculate={handleRecalculate}
                        />
                        <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-center xl:tw-justify-start tw-w-full lg:tw-col-span-1 tw-items-center !tw-gap-4 ">
                            <Label label="Units of Measure" />
                            <Controller
                                control={methods.control}
                                name="unitmeasure"
                                render={({ field }) => (
                                    <div className="tw-flex-1 tw- lg:tw-min-w-28 tw-w-full">
                                        <UnitsOfMeasureDropdown
                                            inputId="Units of Measure"
                                            value={field.value as TTypeOption}
                                            onChange={(val) =>
                                                field.onChange(val)
                                            }
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <Budget
                            disabled={isRecalculating}
                            handleRecalculate={handleRecalculate}
                        />
                        <SalesCategoriesSelect
                            disabled={isRecalculating}
                            handleRecalculate={handleRecalculate}
                        />
                    </div>
                </div>
                <Deposit
                    isEdit={!!isEdit}
                    itemToEdit={itemToEdit}
                    disablePriceAndCostFields={!!isEdit}
                    disableDepositSelect={isRecalculating}
                    handleRecalculate={handleRecalculate}
                />
                <PurchaseComponentsTable
                    submitMethods={submitFormFromOutside}
                    isEdit={false}
                />
            </form>
        </FormProvider>
    ) : null
}

ItemsPage.displayName = 'ItemsPage'

export default ItemsPage
