import TreeView from '../../components/TreeView'
import { Button } from 'react-bootstrap'
import { getClassNames, removeDomClass } from '../../../helpers/DOM'
import { Link } from 'react-router-dom'
import React, { Fragment } from 'react'
import { toggleDMPageLoader } from '../../../utilities/DOM'

class ReportingTree extends TreeView {
    constructor(props) {
        super(props)
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this)
        this.handleClickOnEmpty = this.handleClickOnEmpty.bind(this)
    }

    handleClick = (e) => {
        // Toggle active node class.
        e.target.parentNode.classList.toggle('active-node')
    }

    handleClickOnEmpty = (e) => {
        e.preventDefault()

        toggleDMPageLoader(true, false)
        const activeBtn = document.querySelector('.text-primary')
        if (activeBtn) {
            activeBtn.classList.remove('text-primary')
        }

        // Toggle active node class.
        e.target.classList.toggle('text-primary')

        this.props.updatereport(e.target.dataset.id)
    }

    handleClickFavorite = (e) => {
        e.target.classList.toggle('ri-heart-fill')
        e.target.classList.toggle('ri-heart-line')
    }

    render() {
        const { data } = this.props
        const { isroot } = this.props
        const { variant } = this.props
        const { btnemptyclass } = this.props

        if (!data) return null
        const reports = data.flatMap(({ reports }) => reports)

        return (
            <ul className="report-tree-parent">
                {reports.length > 1 &&
                    data.map((item, i) => {
                        if (item.reports === null || item.reports.length > 0) {
                            return (
                                <Fragment key={i}>
                                    <li className="report-header">
                                        {item.name}
                                    </li>
                                    {(() => {
                                        if (item.reports !== null) {
                                            return (
                                                <ul>
                                                    {item.reports.map(
                                                        (report, i) => (
                                                            <li
                                                                key={'r' + i}
                                                                className={
                                                                    item.isActive
                                                                        ? 'active-node '
                                                                        : ''
                                                                }
                                                            >
                                                                {/* show v1
                                            <i
                                                className="ri-heart-line"
                                                onClick={this.handleClickFavorite}
                                            >
                                            </i>

                                            <Button
                                                key={i}
                                                variant="link"
                                                className={`empty-node wrap-after ${btnemptyclass ? btnemptyclass : ''}`}
                                                value={report.id}
                                                onClick={this.handleClickOnEmpty}
                                            >
                                            <span dangerouslySetInnerHTML={{ __html: report.name }}></span>
                                        </Button>
                                        */}
                                                                <Link
                                                                    data-id={
                                                                        report.id
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleClickOnEmpty
                                                                    }
                                                                    className={`text-black ${
                                                                        btnemptyclass
                                                                            ? btnemptyclass
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {
                                                                        report.name
                                                                    }
                                                                </Link>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )
                                        }
                                    })()}
                                </Fragment>
                            )
                        }
                    })}
                {reports.length <= 1 && (
                    <li className="report-header">No reports to display</li>
                )}
            </ul>
        )
    }
}

export default ReportingTree
