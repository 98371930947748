import { useForm, Controller } from 'react-hook-form'
import { Container, Col, Form, Button } from 'react-bootstrap'
import {
    displayAlertError,
    displayAlertSuccess,
} from '../../../utilities/Response'
import { FooterFormAction } from '../../../templates/components/Form'
import { useCompanySettings, useMutateCompanySettings } from './hooks'
import FormRow from './FormRow'
import FormHeading from './FormHeading'
import FormGroup from './FormGroup'
import FormLabel from './FormLabel'
import TimezoneSelect from './TimezoneSelect'
import LogoControl from './LogoControl'

const SettingsForm = () => {
    const companySettings = useCompanySettings()
    const companySettingsMutation = useMutateCompanySettings()

    const originalLogoImageId = companySettings.logoImageId

    const values = { ...companySettings }
    const {
        register,
        control,
        handleSubmit,
        formState: { isDirty, dirtyFields, errors, touchedFields },
    } = useForm({ values })

    const onSubmit = async (data) => {
        // if we have no changes do nothing
        if (!isDirty || Object.keys(dirtyFields) < 1) return

        try {
            await companySettingsMutation.mutateAsync({
                data,
                dirtyFields,
                originalLogoImageId,
            })

            displayAlertSuccess('Changes were successfully saved.')
        } catch (error) {
            console.error(error)
            displayAlertError('Error saving changes.')
        }
    }

    if (companySettings.isLoading) {
        return null
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <div>Errors: {JSON.stringify(errors)}</div> */}
            <div className="content-padding min-height has-action-bar company-settings">
                <Container fluid>
                    <FormRow>
                        <Col>
                            <FormGroup controlId="companyName">
                                <FormLabel>Name</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={50}
                                        placeholder="Enter Name"
                                        {...register('name', {
                                            required: true,
                                            maxLength: 50,
                                        })}
                                        isInvalid={!!errors.name}
                                        isValid={
                                            !errors.name && !!touchedFields.name
                                        }
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup controlId="logo" align="start">
                                <FormLabel>Logo</FormLabel>
                                <Col>
                                    <LogoControl
                                        name="logoImageId"
                                        control={control}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormRow>
                    {/* Address */}
                    <FormHeading>Address</FormHeading>
                    <FormRow>
                        <Col>
                            <FormGroup controlId="addressLine1">
                                <FormLabel>Line 1</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={30}
                                        placeholder="Address Line 1"
                                        {...register('addr1')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="addressLine2">
                                <FormLabel>Line 2</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={30}
                                        placeholder="Address Line 2"
                                        {...register('addr2')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="addressLine3">
                                <FormLabel>Line 3</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={30}
                                        placeholder="Address Line 3"
                                        {...register('addr3')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="city">
                                <FormLabel>City</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={30}
                                        placeholder="City"
                                        {...register('city')}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup controlId="state">
                                <FormLabel>State</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={2}
                                        placeholder="State"
                                        {...register('state')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="zip">
                                <FormLabel>Zip</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={10}
                                        placeholder="Zip"
                                        {...register('zip')}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormRow>
                    {/* Contact Information */}
                    <FormHeading>Contact Information</FormHeading>
                    <FormRow>
                        <Col>
                            <FormGroup controlId="phone">
                                <FormLabel>Phone</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={25}
                                        placeholder="Phone"
                                        {...register('phone')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="fax">
                                <FormLabel>Fax</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={25}
                                        placeholder="fax"
                                        {...register('fax')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="email">
                                <FormLabel>Email</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="email"
                                        maxLength={40}
                                        placeholder="Email"
                                        {...register('email')}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup controlId="website">
                                <FormLabel>Website</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={50}
                                        placeholder="Website"
                                        {...register('website')}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="taxid">
                                <FormLabel>Tax ID</FormLabel>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={11}
                                        placeholder="Tax ID"
                                        {...register('ein')}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormRow>
                    {/* Miscellaneous */}
                    <FormHeading>Miscellaneous</FormHeading>
                    <FormRow>
                        <Col>
                            <FormGroup>
                                <FormLabel>Timezone</FormLabel>
                                <Col>
                                    <Controller
                                        name="timeZoneCode"
                                        control={control}
                                        render={({ field }) => (
                                            <TimezoneSelect {...field} />
                                        )}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </FormRow>
                </Container>
            </div>

            <FooterFormAction>
                <Button variant="primary" name="save" size="lg" type="submit">
                    Save
                </Button>
            </FooterFormAction>
        </Form>
    )
}

export default SettingsForm
