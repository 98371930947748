import { ApiService } from 'lib/api/HttpService'
import { TWarehouseQty } from 'lib/api/types/WarehouseQty'
import { TStockItem } from 'lib/api/types/StockItem'
import { UnitOfMeasure } from 'lib/api/types/UnitsOfMeasure'
import { TVendor } from 'lib/api/types/Vendor'
import { TSalesCategory } from 'lib/api/types/SalesCategory'
import { Project } from 'lib/api/types/Project'
import { Company } from 'lib/api/types/Company'
import { TAddress } from 'lib/api/types/Address'
import { TInventoryTransaction } from 'lib/api/types/InventoryTransaction'
import { Item } from 'lib/api/types/Item'

const fetchStockItems = async (filter: string): Promise<TStockItem[]> => {
    try {
        const response = await new ApiService().getInventory(filter)
        return response as Promise<TStockItem[]>
    } catch {
        return []
    }
}

const fetchThumbnail = async (id: string) => {
    return await new ApiService().getFile(`${id}?maxWidth=200&maxHeight=200`)
}

const deleteStockItem = async (stockNo: string) => {
    return await new ApiService().deleteObjects({
        deleteType: 'dmriTestOnly',
        objectType: 'objInventoryItem',
        objectCodeOrId: stockNo,
    })
}

const duplicateStockItem = async (stockItem: TStockItem, stockNo: string) => {
    return await new ApiService().postJson('inventorystocks', {
        stockno: stockNo,
        desc: stockItem.desc,
        descrtf: stockItem.descrtf,
        shortdesc: stockItem.shortdesc,
        supplier: stockItem.supplier,
        mfgcat: stockItem.mfgcat,
        category1: stockItem.category1,
        category2: stockItem.category2,
        locbin: stockItem.locbin,
        reorderpt: stockItem.reorderpt,
        notes: stockItem.notes,
        notesrtf: stockItem.notesrtf,
        unitprice: stockItem.unitprice,
        unitestcost: stockItem.unitestcost,
        sidemark: stockItem.sidemark,
        unitofmeasure: stockItem.unitofmeasure,
        scat: stockItem.scat,
        markup: stockItem.markup,
        reorderqty: stockItem.reorderqty,
        posdiscperc: stockItem.posdiscperc,
        primaryImageId: stockItem.primaryImageId,
        descOnly: stockItem.descOnly,
        descOnlyRtf: stockItem.descOnlyRtf,
        itemName: stockItem.itemName,
    })
}

const createStockItem = async (data: any): Promise<TStockItem> => {
    return await new ApiService().postJson('inventorystocks', data)
}

const fetchUnitsOfMeasures = async () => {
    const response = await new ApiService().getUnitsOfMeasures()
    const units = response as UnitOfMeasure[]
    return units.map((unit: UnitOfMeasure) => {
        return {
            value: unit.value,
            label: unit.value,
        }
    })
}

const fetchSalesCategories = async () => {
    const response = await new ApiService().getSalesCategories()
    const categories = response as TSalesCategory[]
    return categories.map((category: TSalesCategory) => {
        return {
            value: category.scat,
            label: `${category.scatn ? `${category.scatn} ` : ''}[${
                category.scat
            }]`,
        }
    })
}

const fetchSuppliers = async () => {
    const response = await new ApiService().getVendors()
    const vendors = response as TVendor[]
    return vendors.map((vendor: TVendor) => {
        return {
            value: vendor.vendor,
            label: `${vendor.vendorn ? `${vendor.vendorn} ` : ''}[${
                vendor.vendor
            }]`,
        }
    })
}

const updateStockItem = async (id: number, data: any) => {
    return await new ApiService().patch('inventorystocks', id, data)
}

const deleteImage = async (id: string) => {
    return await new ApiService().deleteFile(id)
}

const uploadImage = async (file: File) => {
    return await new ApiService().saveFile({ file: file })
}

const fetchImage = async (id: string) => {
    return await new ApiService().getFile(id)
}

const fetchWarehouse: (stockNo: string) => Promise<TWarehouseQty[]> = async (
    stockNo: string
) => {
    const reponse = await new ApiService().get(
        'inventorytransactions/warehouseqty',
        `?$filter=stockno eq '${stockNo}'`
    )
    return reponse as Promise<TWarehouseQty[]>
}

const fetchTransactions = async (stockNo: string) => {
    return (await new ApiService().get(
        'inventorytransactions',
        `?$filter=stockno eq '${stockNo}'`
    )) as Promise<TInventoryTransaction[]>
}

const fetchProjects = async () => {
    const response = await new ApiService().getProjects(
        '?$filter=closeddt eq null'
    )
    const projects = response as Project[]
    return projects.map((project: Project) => {
        return {
            value: project.proj,
            label: `${project.projn ? `${project.projn} ` : ''}[${
                project.proj
            }]`,
        }
    })
}

const fetchProjectItems = async (proj: string) => {
    const response = await new ApiService().getProjectItems(
        `?$filter=proj eq '${proj}' and inactive eq false and completed eq false`
    )
    const items = response as Item[]
    return items.map((item: Item) => {
        return {
            value: item.id,
            label: `${item.item}${item.itemName ? ' - ' + item.itemName : ''}`,
        }
    })
}

const fetchWarehouseAddress = async () => {
    const response = await new ApiService().getAddresses(
        '?$filter=addresstype eq 1 and warehouse eq true and inactive eq false'
    )
    const addresses = response as TAddress[]
    return addresses.map((address: TAddress) => {
        return {
            value: address.code,
            label: `${address.sortname ? `${address.sortname} ` : ''}[${
                address.code
            }]`,
        }
    })
}

const createInventoryAdjustment = async (data: any) => {
    return await new ApiService().postJson('inventorytransactions', data)
}

const updateInventoryAdjustment = async (id: number, data: any) => {
    return await new ApiService().patch('inventorytransactions', id, data)
}

const deleteInventoryAdjustments = async (ids: number[]) => {
    const promises = []
    const failedItems = []

    for (const id of ids) {
        try {
            promises.push(
                await new ApiService().delete('inventorytransactions', id, {})
            )
        } catch {
            failedItems.push(id)
        }
    }

    await Promise.all(promises)
    return failedItems
}

const fetchCompanyWarehouseAddress = async () => {
    const response = await new ApiService().getCompany()
    const company = response as Company
    return company.whshipto
}

export {
    fetchStockItems,
    fetchThumbnail,
    deleteStockItem,
    duplicateStockItem,
    createStockItem,
    fetchUnitsOfMeasures,
    fetchSalesCategories,
    fetchSuppliers,
    updateStockItem,
    deleteImage,
    uploadImage,
    fetchImage,
    fetchWarehouse,
    fetchTransactions,
    fetchProjects,
    fetchProjectItems,
    fetchWarehouseAddress,
    createInventoryAdjustment,
    updateInventoryAdjustment,
    deleteInventoryAdjustments,
    fetchCompanyWarehouseAddress,
}
