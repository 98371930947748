import React from 'react'
import {
    Col,
    Row,
    Form,
    Container,
    Breadcrumb,
    Button,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { generatePath, Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import { ApiService } from '../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import {
    getBreadcrumbTitle,
    getSaveButtonName,
    removeSpaces,
} from '../../../utilities/String'
import { FormEvent } from '../../../utilities/FormEvent'
import {
    displayAlertError,
    handleResponseAlert,
} from '../../../utilities/Response'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../utilities/Rtf'
import { isEmpty } from 'lodash'
import { addDomClass, removeDomClass } from '../../../utilities/DOM'

class GlossaryAddSpecificationTemplates extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            fieldCount: 1,
            data: {},
            dataIsLoaded: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
            instructionHtml: '',
        }

        this.api = new ApiService()

        this.newSpecTemplate = {
            speccode: '',
            speccodename: '',
            specmaterial: true,
            specdesc: '',
            specdescrtf: '',
            specshortdesc: '',
            spect1: '',
            spect2: '',
            spect3: '',
            spect4: '',
            spect5: '',
            spect6: '',
            spect7: '',
            spect8: '',
            spect9: '',
            spect10: '',
            specd1: '',
            specd2: '',
            specd3: '',
            specd4: '',
            specd5: '',
            specd6: '',
            specd7: '',
            specd8: '',
            specd9: '',
            specd10: '',
            ssmaTimeStamp: '',
        }

        this.htmlToRtfCoverter = new HtmlToRtfBrowser()
        this.formEvent = new FormEvent('glossarySpecTemplate', true)
        this.isEditing = Boolean(this.props.params.id)
    }

    async componentDidMount() {
        this.setTitle('Add Specification Template | Glossary')
        this.setFormAction(true)

        this.dMloader(true)
        setLocalStorage('glossarySpecTemplate', this.newSpecTemplate, true)

        if (this.props.params.id) {
            const data = await this.api.get(
                'specificationtemplates',
                `?$filter=speccode eq '${this.props.params.id}'`
            )

            if (data) {
                const tmp = data[0]

                let fieldCount = 0

                for (let i = 1; i <= 10; i++) {
                    if (
                        removeSpaces(tmp['specd' + i]) ||
                        removeSpaces(tmp['spect' + i])
                    ) {
                        fieldCount++
                    }
                }

                const instructionHtml = await mapRtfToDraft(
                    tmp?.specdescrtf ?? ''
                )

                this.setState({
                    instructionHtml,
                    dataIsLoaded: true,
                    data: tmp,
                    fieldCount: fieldCount > 0 ? fieldCount : 1,
                })

                this.setTitle('Edit Specification Template | Glossary')
                setLocalStorage('glossarySpecTemplate', tmp, true)
            }
        }

        setTimeout(() => {
            this.setState({
                showButtons: true,
            })
            this.dMloader(false)
        }, 250)
    }

    getLocalData() {
        return getLocalStorage('glossarySpecTemplate', true)
    }

    handleFieldAdd = (e) => {
        this.setState((prev) => {
            prev.fieldCount++

            return prev
        })
    }

    handleFieldDelete = (i) => {
        let { fieldCount } = this.state
        const ldata = this.getLocalData()

        let ctr = 1
        for (let i2 = 1; i2 <= 10; i2++) {
            if (
                i !== i2 &&
                (removeSpaces(ldata['specd' + i2]) ||
                    removeSpaces(ldata['spect' + i2]))
            ) {
                ldata['specd' + ctr] = ldata['specd' + i2]
                ldata['spect' + ctr] = ldata['spect' + i2]
                this.handleChange({
                    target: {
                        name: `specd${ctr}`,
                        value: ldata['specd' + i2],
                    },
                })

                this.handleChange({
                    target: {
                        name: `spect${ctr}`,
                        value: ldata['spect' + i2],
                    },
                })
                ctr++
            }
        }

        for (; ctr <= 10; ctr++) {
            ldata['specd' + ctr] = ''
            ldata['spect' + ctr] = ''
            this.handleChange({
                target: {
                    name: `specd${ctr}`,
                    value: '',
                },
            })

            this.handleChange({
                target: {
                    name: `spect${ctr}`,
                    value: '',
                },
            })
        }

        this.setState({
            data: ldata,
            fieldCount: fieldCount - 1,
        })
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    handleChange = (e) => {
        this.setDraft()
        this.formEvent.handleTextChange(e)
    }

    handleCheckChange = (e) => {
        this.setDraft()
        this.formEvent.handleCheckChange(e)
    }

    handleWysiwygChange = (name) => (e) => {
        const rtf = JSON.parse(JSON.stringify(e))
        rtf.target.value = this.htmlToRtfCoverter.convertHtmlToRtf(
            e.target.html
        )

        this.setDraft()
        this.formEvent.handleWysiwygChange(name, e)
        this.formEvent.handleWysiwygChange('specdescrtf', rtf)
    }

    isValid() {
        let ldata = this.getLocalData()
        let isValid = true
        const attrRows = document.querySelectorAll('.attributes .attr-list')
        attrRows?.length > 1 &&
            attrRows?.forEach((row) => {
                const inputs = row.querySelectorAll('input')
                const input1 = inputs ? inputs[0] : null
                const input2 = inputs ? inputs[1] : null

                if (isEmpty(input1.value) && isEmpty(input2.value)) {
                    addDomClass('bg-beige', row)
                    isValid = false
                } else {
                    removeDomClass('bg-beige', row)
                }
            })

        if (!isValid) {
            displayAlertError(
                'Please enter value on attributes or remove the rows with empty values.'
            )
        }

        if (isEmpty(ldata.speccode)) {
            displayAlertError('Template no. is required.')
            isValid = false
        }

        return isValid
    }

    handleClickSave = (e) => {
        if (!this.isValid()) {
            return
        }

        this.setIsLoading(true)
        this.state.data.speccode ? this.handleUpdate(e) : this.handleCreate(e)
    }

    handleUpdate(e) {
        if (this.state.data && this.state.data?.speccode) {
            const data = getLocalStorage('glossarySpecTemplate', true) || `{}`

            const request = this.api.patch(
                'specificationtemplates',
                this.state.data?.speccode,
                data
            )
            handleResponseAlert(
                request,
                (res) => {
                    this.setIsLoading(false)
                    this.setIsDraft(false)

                    setTimeout(() => {
                        window.location.href = generatePath(URI.glossary.list, {
                            path: 'specification-templates',
                        })
                    }, 1000)
                },
                7000,
                'speccode'
            )
        }
    }

    handleCreate(e) {
        const data = getLocalStorage('glossarySpecTemplate', true) || `{}`

        const request = this.api.postJson('specificationtemplates', data)
        handleResponseAlert(
            request,
            (res) => {
                this.setIsLoading(false)
                this.setIsDraft(false)

                if (res && res.speccode) {
                    setTimeout(() => {
                        window.location.href =
                            e.target.name === 'saveNew'
                                ? URI.glossary.add.specificationTemplates
                                : generatePath(URI.glossary.list, {
                                      path: 'specification-templates',
                                  })
                    }, 1000)
                }
            },
            7000,
            'speccode'
        )
    }

    setIsDraft(state) {
        window.onbeforeunload = null
        setLocalStorage('isDraft', state.toString())
        this.setState({
            isDraft: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    handleClick = (e) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            setLocalStorage('isDraft', 'false')
            if (e.currentTarget.href !== undefined) {
                location.href = e.currentTarget.href
            } else if (e.target.href !== undefined) {
                location.href = e.target.href
            } else {
                location.href = e.currentTarget.firstChild.href
            }
        }
    }

    getValue(name) {
        const { data } = this.state.data
        const localData = this.getLocalData()

        if (!isEmpty(localData)) {
            return localData[name]
        }

        if (!isEmpty(data) && data[name]) {
            return data[name]
        }

        return ''
    }

    renderHeader() {
        const { data, isLoading, isDraft } = this.state
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        onClick={this.handleClick}
                        to={URI.timeEntry.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{
                                to: getRouteWithParam(URI.glossary.list, {
                                    path: 'specification-templates',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Specification Templates
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{
                                to: URI.glossary.add.specificationTemplates,
                            }}
                            linkAs={Link}
                        >
                            {getBreadcrumbTitle(
                                data,
                                'speccodename',
                                'Specification Template'
                            )}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>
                        {getBreadcrumbTitle(
                            data,
                            'speccodename',
                            'Specification Template'
                        )}
                    </HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <Button
                            className={
                                this.props.params.id
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={isLoading}
                        >
                            Save & New
                        </Button>

                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={isLoading}
                        >
                            {getSaveButtonName(isLoading, 'Save')}
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { data } = this.state
        return (
            <Container className="ms-0">
                <Row className="py-4">
                    <Col className="mb-3 mb-lg-3" xs={12} lg={10}>
                        <Row className="align-items-center mb-3">
                            <Col lg={5}>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Template No.
                                        </Form.Label>
                                    </Col>
                                    <Col className="ps-lg-0">
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            defaultValue={data?.speccode}
                                            readOnly={this.isEditing}
                                            disabled={this.isEditing}
                                            name="speccode"
                                            onChange={(e) => {
                                                e.target.value = e.target.value
                                                    .substring(0, 5)
                                                    .toUpperCase()
                                                e.target.dataType = 'string'
                                                this.handleChange(e)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={5}>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={5}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Template Description
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            defaultValue={data?.speccodename}
                                            name="speccodename"
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    ></Col>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Material"
                                            name="specmaterial"
                                            type="checkbox"
                                            id={`checkbox-group-1`}
                                            onClick={this.handleCheckChange.bind(
                                                this
                                            )}
                                            defaultChecked={data?.specmaterial}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="mb-3 mb-lg-3" xs={12} lg={10}>
                        <Row className="align-items-start mb-3">
                            <Col xs={12} className="mb-2">
                                <Form.Label className="mb-0 mt-2">
                                    Instructions
                                </Form.Label>
                            </Col>
                            <Col lg={10}>
                                <WysiwygEditor
                                    onChange={this.handleWysiwygChange(
                                        'specdesc',
                                        this
                                    )}
                                    name="specdesc"
                                >
                                    {this.state.instructionHtml}
                                </WysiwygEditor>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="bg-secondary-grey px-4 roundedx-4 mb-4">
                    <Row xs={1} lg={2} className="py-3 align-items-center">
                        <Col>
                            <h6 className="fw-bold mb-0">Attributes</h6>
                        </Col>
                        <Col className="text-end">
                            <Button
                                variant="primary"
                                size="md"
                                onClick={this.handleFieldAdd}
                                disabled={this.state.fieldCount >= 10}
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Row className="mb-4">
                    <Col lg={12}>
                        <div className="table-gradient">
                            <Table responsive borderless>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th className="mw-100px">Title</th>
                                        <th className="mw-100px">
                                            Description
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(() => {
                                        let fields = []
                                        for (
                                            let i = 0;
                                            i <
                                            Math.min(this.state.fieldCount, 10);
                                            i++
                                        ) {
                                            fields.push(
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Please enter"
                                                            value={this.getValue(
                                                                'spect' +
                                                                    (i + 1)
                                                            )}
                                                            name={
                                                                'spect' +
                                                                (i + 1)
                                                            }
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Please enter"
                                                            value={this.getValue(
                                                                'specd' +
                                                                    (i + 1)
                                                            )}
                                                            name={
                                                                'specd' +
                                                                (i + 1)
                                                            }
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="light"
                                                            className="btn-icon"
                                                            size="md"
                                                            onClick={() => {
                                                                this.handleFieldDelete(
                                                                    i + 1
                                                                )
                                                            }}
                                                        >
                                                            <i className="ri-subtract-line"></i>
                                                            Remove
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        return fields
                                    })()}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading } = this.state
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        name="save"
                        onClick={this.handleClickSave.bind(this)}
                        disabled={isLoading}
                    >
                        {getSaveButtonName(isLoading, 'Save')}
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(GlossaryAddSpecificationTemplates)
