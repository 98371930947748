import React from 'react'
import { Link } from 'react-router-dom'

import { useHasAccess } from '../../../context/security'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function SecureLink({
    attributeNo,
    attributeType,
    to,
    title,
    children,
    ...props
}) {
    const hasAccess = useHasAccess(attributeNo, attributeType)
    const titleToUse = !hasAccess
        ? 'The assigned user role restricts access to this area'
        : title

    if (!hasAccess) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">{titleToUse}</Tooltip>}
            >
                <span {...props} disabled={true}>
                    {children}
                </span>
            </OverlayTrigger>
        )
    }

    return (
        <Link to={to} {...props} title={titleToUse}>
            {children}
        </Link>
    )
}
