import React from 'react'
import ReactSelect from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'

export default class Select extends React.Component<StateManagerProps> {
    constructor(props: StateManagerProps) {
        super(props)
    }

    render() {
        return <ReactSelect {...this.props} />
    }
}
