import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { Company } from '../types/Company'

const api = new ApiService()

const getCompany = async (): Promise<Company> => {
    const company = await api.getCompany()
    return company
}

const useGetCompanyInfo = (
    options?: UseQueryOptions<Company, unknown, Company, string[]>
): UseQueryResult<Company> => {
    const companyQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: ['company'],
        queryFn: getCompany,
        ...(options || {}),
    })

    useEffect(() => {
        if (companyQuery.error) {
            displayAlertError(
                'An error occurred fetching the company information, please try again'
            )
        }
    }, [companyQuery.error])

    return companyQuery
}

export default useGetCompanyInfo
