import React from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import { WithRouter } from '../../../../helpers/Router'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import ThreadComponent from '../../ThreadComponent'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown'
import AsyncAddressesDropdown from '../../../../app/components/dropdowns/AsyncAddressesDropdown'

class ItemAddWorkroom extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = props.updatedData

        this.state = {
            data: {},
            dataIsLoaded: false,
            wrdescHtml: '',
            clearedSelect: [],
            urlQuery: '?$filter=addresstype eq 1',
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
    }

    async componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data

            this.setState(
                {
                    data: data,
                    dataIsLoaded: true,
                    wrdescHtml: await mapRtfToDraft(
                        this.updatedData?.wrdescrtf ?? data?.wrdescrtf ?? ''
                    ),
                },
                () => {
                    this.setDefaultWorkroomVendor()
                    this.setDefaultShipto()
                }
            )
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data

            this.setState(
                {
                    data: data,
                    dataIsLoaded: true,
                    wrdescHtml: await mapRtfToDraft(
                        this.updatedData?.wrdescrtf ?? data?.wrdescrtf ?? ''
                    ),
                },
                () => {
                    this.setDefaultWorkroomVendor()
                    this.setDefaultShipto()
                }
            )
        }
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (e === null) {
            key = meta.name
            value = null
            // Since this is a select
            this.setState((prev) => {
                if (!this.isClearedSelect(key)) {
                    prev.clearedSelect.push(key)
                }
                return prev
            })
        } else if (
            meta &&
            e.hasOwnProperty('value') &&
            e.hasOwnProperty('label')
        ) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.props.onDataChange({ key, value })

        if (key === 'workroom' || key === 'wrshipto') {
            if (key === 'workroom')
                this.props.onDataChange({
                    key: 'selectedWorkroomVendor',
                    value: e,
                })
            else this.props.onDataChange({ key: 'selectedShipto', value: e })
        }

        if (key === 'wrdesc') {
            const valueRtf = this.htmlToRtfConverter
                .convertHtmlToRtf(e.target.html)
                .replaceAll('\\~', ' ')

            this.props.onDataChange({ key: `${key}rtf`, value: valueRtf })
        }
    }

    async setDefaultWorkroomVendor() {
        const vendorCode = this.state.data?.workroom
        if (vendorCode && !this.updatedData?.selectedWorkroomVendor) {
            const vendor = await this.api.getVendorByCode(vendorCode, true)
            this.props.onDataChange({
                key: 'selectedWorkroomVendor',
                value: vendor,
            })
        }
    }

    async setDefaultShipto() {
        const shiptoCode =
            this.state.data?.wrshipto || this.props.project.shipto

        if (shiptoCode && !this.updatedData?.selectedWorkroomVendor) {
            const shipto = await this.api.getAddressByCode(
                shiptoCode,
                true,
                'addresstype eq 1'
            )
            this.props.onDataChange({ key: 'selectedShipto', value: shipto })
        }
    }

    isClearedSelect(name) {
        return this.state.clearedSelect?.includes(name)
    }

    render() {
        const { data } = this.state
        return (
            <>
                <Form.Group>
                    <Row className="py-4">
                        <Col lg={6} className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3 zi-200 position-relative">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Workroom Vendor
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <AsyncVendorsDropdown
                                        // options={data.vendorsList}
                                        defaultValue={
                                            this.updatedData
                                                ?.selectedWorkroomVendor
                                        }
                                        className="react-select"
                                        placeholder="Please select"
                                        name="workroom"
                                        onChange={this.handleChange}
                                        isClearable={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3 zi-100 position-relative">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Ship Finished Product To
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <AsyncAddressesDropdown
                                        // options={data.shipToList}
                                        defaultValue={
                                            this.updatedData?.selectedShipto
                                        }
                                        className="react-select"
                                        placeholder="Please select"
                                        name="wrshipto"
                                        onChange={this.handleChange}
                                        isClearable={true}
                                        urlQuery={this.state.urlQuery}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Workroom Instructions
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <WysiwygEditor
                                        id="wrdesc"
                                        onChange={this.handleChange}
                                    >
                                        {this.state.wrdescHtml}
                                    </WysiwygEditor>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form.Group>
            </>
        )
    }
}

export default WithRouter(ItemAddWorkroom)
