import * as yup from 'yup'

export const ProjectViewInfoValidation = yup.object().shape({
    email: yup
        .string()
        .nullable()
        .matches(
            `^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$`,
            'Please enter a valid email'
        ),
})
