import React from 'react'
import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    Row,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import { Link } from 'react-router-dom'
import { ApiService } from '../../../../lib/api/HttpService'
import { Pagination } from '../../../../app/components/pagination/Pagination'
import debounce from 'lodash/debounce'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import { formatDate, formatFilterDate } from '../../../../helpers/Date'
import CreateDepositSlipModal from '../../modal/CreateDepositSlipModal'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { _delay, isFiltered } from '../../../../helpers/Util'
import { getCookie } from '../../../../utilities/Auth'
import {
    displayAlert,
    displayAlertError,
    getErrorMessage,
    handleResponseAlert,
} from '../../../../utilities/Response'
import { DateRangePicker } from 'rsuite'
import {
    addDays,
    endOfDay,
    getMonth,
    getYear,
    lastDayOfMonth,
    startOfDay,
    subDays,
} from 'date-fns'
import FilterSort from '../../../../utilities/modules/FilterSort'
import { currencyFormat } from '../../../../helpers/Number'
import dayjs from 'dayjs'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'

class CashReceiptListBankDeposit extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('ar_cashreceipts_bankdeposit_list')
        this.fs.setDefaultSort('payee asc')
        this.state = {
            checks: [],
            checkedItems: {},
            showRetainerModal: false,
            activeMenu: 'new',
            dataIsLoaded: false,
            data: [],
            accounts: [],
            selectedAccount: '',
            selectedTotal: 0,
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'payee asc',
            showModal: false,
            checksTotal: currencyFormat(0),
            cashTotal: currencyFormat(0),
            totalDeposit: currencyFormat(0),
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.api = new ApiService()
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )

        this.sortProp = this.state.sortProperty
        this.totalTagItems = this.state.selectedTotal
    }

    componentInit() {
        this.setTitle('Cash Receipts | Make Bank Deposit')
    }

    async componentDidMount() {
        const accounts = await this.api.getGLAccounts(`?$filter=specacct eq 1`)
        this.enableSortTable()

        if (accounts.length > 0) {
            this.setState(
                {
                    accounts: accounts,
                    selectedAccount: accounts[0].account,
                },
                () => {
                    this.fetchItems()
                }
            )
        }
    }

    async fetchItems(page, isLoader) {
        if (isLoader) this.setState({ dataIsLoaded: false })
        try {
            await this.api.postCashReceiptsUndepositedPaymentsGrid({
                cashAccountNumber: this.state.selectedAccount,
            })

            const undepositedPayments =
                await this.api.getCashReceiptsUndepositedPaymentsGrid(
                    `?${this.buildFilters(page, this.state.selectedAccount)}`
                )

            // Avoid parsing to object to check if localstorage key exist
            const itemsFromLocalStorage = getLocalStorage('cr_bank_dep_check')
            const checkedItems = JSON.parse(itemsFromLocalStorage) ?? []

            this.setState(
                {
                    checks: undepositedPayments
                        .filter((item) => item.tag)
                        .map((item) => item.keysort),
                    checkedItems: checkedItems,
                    data: undepositedPayments,
                    dataIsLoaded: true,
                },
                () => {
                    this.calcSelectedTotal()
                }
            )
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    handleSelectAllChecks = () => {
        let checks = []
        if (this.state.checks.length !== this.state.data.length) {
            checks = this.state.data.map((item) => {
                return item.keysort
            })
        }

        const checkedItems = this.state.checkedItems
        checkedItems[this.state.selectedAccount] = checks

        this.state.data.forEach((item) => {
            this.api.tagCashReceiptsUndepositedPayment({
                usercode: getCookie('dmUsercode'),
                keysort: item.keysort,
                tag: checks.includes(item.keysort),
            })
        })

        setLocalStorage('cr_bank_dep_check', JSON.stringify(checkedItems))

        this.setState(
            {
                checks,
                checkedItems,
            },
            () => {
                this.calcSelectedTotal()
            }
        )
    }

    handleCheck = (id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === id)
        const shouldTag = index <= -1

        shouldTag ? checks.push(id) : checks.splice(index, 1)

        const checkedItems = this.state.checkedItems
        checkedItems[this.state.selectedAccount] = checks

        this.api.tagCashReceiptsUndepositedPayment({
            usercode: getCookie('dmUsercode'),
            keysort: id,
            tag: shouldTag,
        })

        setLocalStorage('cr_bank_dep_check', JSON.stringify(checkedItems))

        this.setState(
            {
                checks,
                checkedItems,
            },
            () => {
                this.calcSelectedTotal()
            }
        )
    }

    handleSelectAccount = async (e) => {
        this.setState(
            {
                dataIsLoaded: false,
                selectedAccount: e.target.value,
            },
            () => {
                this.fetchItems()
            }
        )
    }

    calcSelectedTotal() {
        let selectedPayments = this.state.data.filter((item) => {
            return this.state.checks.includes(item.keysort)
        })

        const checksTotal = this.calcChecksTotal(selectedPayments)
        const cashTotal = this.calcCashTotal(selectedPayments)
        const totalDeposit = checksTotal + cashTotal

        this.setState({
            selectedTotal: currencyFormat(
                this.getSelectedTotal(selectedPayments)
            ),
            checksTotal: currencyFormat(checksTotal),
            cashTotal: currencyFormat(cashTotal),
            totalDeposit: currencyFormat(totalDeposit),
        })
    }

    getSelectedTotal(payments) {
        return payments.reduce(function (acc, obj) {
            return acc + obj.amtdue
        }, 0)
    }

    calcChecksTotal(payments) {
        return payments
            .filter((payment) => payment.proj === '2')
            .reduce(function (acc, obj) {
                return acc + obj.amtdue
            }, 0)
    }

    calcCashTotal(payments) {
        return payments
            .filter((payment) => payment.proj !== '2')
            .reduce(function (acc, obj) {
                return acc + obj.amtdue
            }, 0)
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {}

    buildFilters(currentPage, selectedAccount) {
        let filtersQuery = `&$filter=usercode eq ${getCookie('dmUsercode')}`
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.max) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page, true)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handlePostDepositSlip = async (data) => {
        try {
            await this.api.postCashReceiptsUndepositedPayment(data)

            const range = dayjs(data.depositDate).format('YYYY-MM-DD')

            const params = {
                instanceId: getCookie('dmInstanceId'),
                userId: Number(getCookie('dmUsercode')),
                reportId: 1055,
                reportFormatId: 1137,
                reportObjectType: 'Report',
                runReportParameters: [
                    {
                        parameterId: 297,
                        value: `{"cash_account": "${this.state.selectedAccount}"}`,
                    },
                    {
                        parameterId: 298,
                        value: `{"text": "${data.slipNumber}"}`,
                    },
                    {
                        parameterId: 299,
                        value: `{"range1": "${range}", "range2":"${range}"}`,
                    },
                    {
                        parameterId: 300,
                        value: '{"text": ""}',
                    },
                ],

                objectId: null,
            }

            this.setState({
                showModal: false,
            })

            await this.handleRunReports(params, () => {
                displayAlert('success', 'Successfully created deposit slip')
                _delay(() => {
                    window.onbeforeunload = null
                    window.location.reload(false)
                }, 1500)
            })
        } catch (e) {
            const errorMessage =
                e.response?.data?.userError ||
                'Deposit slip could not be created'
            displayAlert('danger', errorMessage)
        }
    }

    async handleRunReports(data, callback) {
        try {
            const saveRequest = this.api.postJson('runreports', data)

            const windowReport = window.open('', '_blank')
            windowReport.document.write(this.loadingWindowHTML())

            handleResponseAlert(saveRequest, (res) => {
                displayAlert(
                    'success',
                    'Please wait for your report to be generated.'
                )
                const reportId = res.id

                const maxRequest = 5
                let requestCount = 0
                let interval = window.setInterval(async () => {
                    try {
                        const response = await this.api.get(
                            'runreports',
                            `?$filter=id eq ${reportId}`
                        )
                        const fileId = response[0].completedFileId
                        if (fileId) {
                            clearInterval(interval)
                            await this.api.getFile(fileId).then((blob) => {
                                windowReport.location.href =
                                    window.URL.createObjectURL(blob)
                                callback()
                            })
                        } else if (requestCount === maxRequest) {
                            clearInterval(interval)
                            displayAlertError(
                                'Report is on pending status. Try again later.'
                            )
                        }
                    } catch (error) {
                        displayAlertError(getErrorMessage(error))
                        windowReport.close()
                    }

                    requestCount++
                }, 5000)
            })
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
    }

    loadingWindowHTML() {
        return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <div>
                <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" class="d-inline-block align-top" alt="React Bootstrap logo">
                <br>
                <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" class="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
                </div>
            </div>
            </div>
        </div>`
    }

    renderFilters() {
        return (
            <>
                <ListFilter>
                    <ListFilter.Fields
                        md={12}
                        lg={9}
                        xl={9}
                        className="width-30"
                    >
                        <ListFilter.Field>
                            <Form.Label className="text-end">
                                <strong>Account</strong>
                            </Form.Label>
                            <Form.Select
                                size="sm"
                                onChange={this.handleSelectAccount}
                            >
                                {this.state.accounts.map((item, i) => (
                                    <option
                                        key={item.account}
                                        value={item.account}
                                    >
                                        {item.account}-{item.accountn}
                                    </option>
                                ))}
                            </Form.Select>
                        </ListFilter.Field>
                        <ListFilter.Field>
                            <Form.Label className="text-end">
                                <strong>
                                    Selected
                                    <br />
                                    Total
                                </strong>
                            </Form.Label>
                            <div className="form-group-extra">
                                <Form.Control
                                    type="text"
                                    placeholder="0.00"
                                    disabled={true}
                                    size="sm"
                                    value={this.state.selectedTotal}
                                    readOnly
                                />
                                <span className="text-secondary-ash">USD</span>
                            </div>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    <ListFilter.Actions
                        md={12}
                        lg={3}
                        xl={3}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-md-end"
                    >
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                        <ListFilter.Action className="ms-auto ms-md-3">
                            <Dropdown
                                className="d-flex justify-content-end ms-auto"
                                align="end"
                            >
                                <Dropdown.Toggle
                                    id="dropdown-autoclose-true"
                                    variant="ivory"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        href=""
                                        onClick={() =>
                                            this.setState(
                                                (prev) =>
                                                    (prev.showModal = true)
                                            )
                                        }
                                        disabled={
                                            this.state.checks.length === 0
                                        }
                                    >
                                        <i className="ri-file-add-line"></i>{' '}
                                        Create Deposit Slip
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    render() {
        return (
            <>
                <div className="d-block sticky-container">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* Filter */}
                                {this.renderFilters()}
                                <div>
                                    <div className="table-gradient">
                                        <Table
                                            striped
                                            responsive
                                            className="a-table"
                                        >
                                            <thead>
                                                <tr className="a-table-heading">
                                                    <th
                                                        align="middle"
                                                        className="w-80px"
                                                    >
                                                        <div className="d-flex justify-content-center">
                                                            <Form.Check
                                                                label=""
                                                                type="checkbox"
                                                                checked={
                                                                    this.state
                                                                        .checks
                                                                        .length >
                                                                        0 &&
                                                                    this.state
                                                                        .checks
                                                                        .length ===
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .length
                                                                }
                                                                className={
                                                                    this.state
                                                                        .checks
                                                                        .length ===
                                                                    this.state
                                                                        .data
                                                                        .length
                                                                        ? ''
                                                                        : 'line'
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectAllChecks
                                                                }
                                                            />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'sinvnum'
                                                            )}
                                                            data-field="sinvnum"
                                                        >
                                                            Payment Type
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'payee'
                                                            )}
                                                            data-field="payee"
                                                        >
                                                            Check No.
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'amtdue'
                                                            )}
                                                            data-field="amtdue"
                                                        >
                                                            Amount
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'sinvdt'
                                                            )}
                                                            data-field="sinvdt"
                                                        >
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'orgpayee'
                                                            )}
                                                            data-field="orgpayee"
                                                        >
                                                            Client
                                                        </span>
                                                    </th>
                                                </tr>
                                                <tr
                                                    className={`a-table-search-fields ${
                                                        this.state
                                                            .showTableSearch
                                                            ? ''
                                                            : 'd-none'
                                                    }`}
                                                >
                                                    <th></th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="sinvnum"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'sinvnum'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="payee"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'payee'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="amtdue"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'amtdue'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <DateRangePicker
                                                            style={{
                                                                minWidth:
                                                                    '200px',
                                                            }}
                                                            placement="auto"
                                                            placeholder="Select date"
                                                            format="MM/dd/yyyy"
                                                            defaultValue={this.fs.getValue(
                                                                'sinvdt'
                                                            )}
                                                            onChange={this.handleDateChange.bind(
                                                                this,
                                                                'sinvdt'
                                                            )}
                                                            onClean={this.handleDateChange.bind(
                                                                this,
                                                                'sinvdt'
                                                            )}
                                                            ranges={[
                                                                {
                                                                    label: 'today',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date()
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'yesterday',
                                                                    value: [
                                                                        startOfDay(
                                                                            addDays(
                                                                                new Date(),
                                                                                -1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            addDays(
                                                                                new Date(),
                                                                                -1
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'last7Days',
                                                                    value: [
                                                                        startOfDay(
                                                                            subDays(
                                                                                new Date(),
                                                                                6
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Last 30 Days',
                                                                    value: [
                                                                        startOfDay(
                                                                            subDays(
                                                                                new Date(),
                                                                                30
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'This month',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ),
                                                                                1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            lastDayOfMonth(
                                                                                new Date()
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Last month',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ) -
                                                                                    1,
                                                                                1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            lastDayOfMonth(
                                                                                new Date(
                                                                                    getYear(
                                                                                        new Date()
                                                                                    ),
                                                                                    getMonth(
                                                                                        new Date()
                                                                                    ) -
                                                                                        1,
                                                                                    1
                                                                                )
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                            ]}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="orgpayee"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'orgpayee'
                                                            )}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.state.dataIsLoaded
                                                    ? showLoading()
                                                    : this.state.data.length ===
                                                          0 &&
                                                      isFiltered(
                                                          this.state
                                                              .searchProperties
                                                      )
                                                    ? showEmpty()
                                                    : this.state.data
                                                          .slice(
                                                              0,
                                                              this.state
                                                                  .pageSize
                                                          )
                                                          .map((item, i) => (
                                                              <tr
                                                                  key={i}
                                                                  data-key={i}
                                                                  className={
                                                                      this.state
                                                                          .checks[
                                                                          i
                                                                      ]
                                                                          ? `active`
                                                                          : ''
                                                                  }
                                                              >
                                                                  <td>
                                                                      <div className="d-flex justify-content-center">
                                                                          <Form.Check
                                                                              label=""
                                                                              type="checkbox"
                                                                              checked={this.state.checks.includes(
                                                                                  item.keysort
                                                                              )}
                                                                              onChange={() => {
                                                                                  this.handleCheck(
                                                                                      item.keysort
                                                                                  )
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          item.sinvnum
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          item.payee
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {item.amtdue
                                                                          ? item.amtdue.toFixed(
                                                                                2
                                                                            )
                                                                          : ''}
                                                                  </td>
                                                                  <td>
                                                                      {formatDate(
                                                                          item.sinvdt
                                                                      )}
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          item.orgpayee
                                                                      }
                                                                  </td>
                                                              </tr>
                                                          ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {this.state.data.length > 0 && (
                                        <Pagination
                                            onPageSizeChanged={
                                                this.onPageSizeChanged
                                            }
                                            onPageChanged={this.onPageChanged}
                                            hasPreviousPage={
                                                this.state.page > 1
                                            }
                                            hasNextPage={
                                                this.state.data.length >
                                                this.state.pageSize
                                            }
                                            page={this.state.page}
                                            pageSize={this.state.pageSize}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <CreateDepositSlipModal
                            show={this.state.showModal}
                            hideModal={() =>
                                this.setState(
                                    (prev) => (prev.showModal = false)
                                )
                            }
                            onPostDepositSlip={this.handlePostDepositSlip}
                            checksTotal={this.state.checksTotal}
                            cashTotal={this.state.cashTotal}
                            totalDeposit={this.state.totalDeposit}
                        />
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(CashReceiptListBankDeposit)
