import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Breadcrumb,
    Button,
    Container,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../../lib/api/HttpService'
import Select from 'react-select'
import { getCookie } from '../../../../utilities/Auth'
import dayjs from 'dayjs'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
    hideAlertLoader,
} from '../../../../utilities/Response'
import { delay, find, isEmpty } from 'lodash'
import MSG from '../../../../defaults/Message'
import { toggleDMPageLoader } from '../../../../utilities/DOM'
import { currencyFormat } from '../../../../helpers/Number'
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting'
import { showLoading } from '../../../../helpers/Loading'
import DMStorage from '../../../../app/models/storage/DMStorage'
import AsyncProjectsDropdown from '../../../../app/components/dropdowns/AsyncProjectsDropdown'

class VendorInvoiceAddDeposit extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            vendorInvoices: [],
            payTypes: [],
            dataIsLoaded: false,
            defaultProject: null,
            allProjects: true,
            selectedPayWith: '',
        }

        this.api = new ApiService()
        this.updatedData = {
            date: new Date().toISOString(),
            dueDate: new Date().toISOString(),
        }
    }

    componentInit() {
        this.setTitle('Add Invoice | Accounts Payable').setFormAction(true)
    }

    async componentDidMount() {
        this.dMloader(true)
        try {
            const payTypesResponse = await this.api.getPayTypes()
            const accountsResponse = await this.api.getGLAccounts(
                '?$filter=specacct eq 2'
            )

            await this.fetchData()

            const payTypes = payTypesResponse.map((item) => {
                return { value: item.defAcct, label: item.payType, isCC: false }
            })
            const accounts = accountsResponse.map((item) => {
                return { value: item.account, label: item.accountn, isCC: true }
            })

            this.setState({
                payTypes: [...payTypes, ...accounts],
            })
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }

        this.dMloader(false)
    }

    async fetchData() {
        const { allProjects, defaultProject } = this.state

        const gridFilter = {
            allProjects,
        }

        if (!allProjects) {
            gridFilter.projectCode = defaultProject?.value
        }

        await this.api.postVendorDepositsGrid(gridFilter)
        const vendorInvoicesResponse =
            await this.api.getTemporaryVendorInvoicesDeposits(
                `?$filter=usercode eq ${getCookie('dmUsercode')}`
            )
        this.setState(
            {
                vendorInvoices: vendorInvoicesResponse,
                dataIsLoaded: true,
            },
            () => {
                tableSortingEnableSort({
                    data: vendorInvoicesResponse,
                    stateKey: 'vendorInvoices',
                    classRef: this,
                    targetTable: '.a-table-heading.deposit',
                    pattern: (key) => {
                        return [
                            (d) => {
                                if (key === 'sinvnum' && d[key]) {
                                    return d[key]
                                        .replace(/\(/gi, '')
                                        .replace(/\)/gi, '')
                                }

                                return d[key] ?? ''
                            },
                        ]
                    },
                })
            }
        )
    }

    handleCheck = async (keysort) => {
        await this.api.tagTemporaryVendorInvoicesDeposit(keysort)
    }

    handlePayWithChange = (e) => {
        this.updatedData.payWith = e.value
        this.updatedData.payWithIsCc = e.isCC

        this.setState({
            selectedPayWith: e,
        })
    }

    handleDateChange = (date) => {
        let selectedDate = ''
        if (date) {
            selectedDate = new Date(date)
        }

        this.updatedData.date = selectedDate ? selectedDate.toISOString() : ''
        this.setState({
            date: selectedDate,
        })
    }

    handleDueDateChange = (date) => {
        const selectedDate = date ? new Date(date) : ''
        this.updatedData.dueDate = selectedDate
            ? selectedDate.toISOString()
            : ''
        this.setState({
            dueDate: selectedDate,
        })
    }

    handleSave = async () => {
        if (!this.updatedData.date) {
            displayAlert('danger', `Please enter a valid date.`)
            return
        }

        displayAlertLoader(MSG.loading.update.msg)
        try {
            await this.api.postQuickAddVendorDeposit(this.updatedData)

            displayAlertSuccess(MSG.success.create.APVendorInvDep)
            delay(() => {
                this.props.navigate(URI.accountsPayable.vendorInvoice.listNew)
            }, 700)
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
    }

    handleProjOption = (allProjects) =>
        this.setState(
            {
                allProjects,
                defaultProject: null,
                dataIsLoaded:
                    allProjects && !isEmpty(this.state.defaultProject)
                        ? false
                        : true,
            },
            allProjects && !isEmpty(this.state.defaultProject)
                ? this.fetchData
                : null
        )

    handleProjectChange = (props) =>
        this.setState(
            {
                defaultProject: props,
                dataIsLoaded: false,
            },
            this.fetchData
        )

    renderData(data) {
        this.setState({
            data: data,
        })
    }

    renderHeaderTitle() {
        return 'Quick Add Deposit'
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsPayable.vendorInvoice.listNew}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsPayable.vendorInvoice.listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Payable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsPayable.vendorInvoice.listNew,
                            }}
                            linkAs={Link}
                        >
                            Vendor Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.renderHeaderTitle()}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>
                        {this.renderHeaderTitle()}
                    </HeaderLight.Title>

                    <div>
                        {/* Submit Button */}

                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderListFilter() {
        return (
            <ListFilter className="no-separator">
                <ListFilter.Fields
                    sm={12}
                    lg={11}
                    className="width-50 mb-3"
                    separator={true}
                >
                    <ListFilter.Field className="w-33">
                        <Form.Check
                            inline
                            label="All Projects"
                            name="opt-show-project"
                            type="radio"
                            id={`inline-chk-opt-allproject`}
                            className="me-4"
                            onChange={() => this.handleProjOption(true)}
                            defaultChecked={true}
                        />
                        <Form.Check
                            inline
                            label="For a Specific Project/Order"
                            name="opt-show-project"
                            type="radio"
                            id={`inline-chk-opt-oneproject`}
                            onChange={() => this.handleProjOption(false)}
                        />
                    </ListFilter.Field>
                    <ListFilter.Field>
                        <Form.Label className="col-2 col-md-4 text-start text-md-end pe-md-3">
                            Project / Order
                        </Form.Label>
                        <AsyncProjectsDropdown
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 99999,
                                }),
                            }}
                            className="react-select w-100"
                            placeholder="Please select"
                            defaultValue={this.state.defaultProject}
                            onChange={this.handleProjectChange}
                            isDisabled={this.state.allProjects}
                            isClearable={true}
                        />
                    </ListFilter.Field>
                </ListFilter.Fields>
                <ListFilter.Fields
                    sm={12}
                    lg={11}
                    className="width-25"
                    separator={false}
                >
                    <ListFilter.Field className="w-200px">
                        <Form.Label className="col-sm-3">Pay with</Form.Label>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 99999,
                                }),
                            }}
                            key={`${Math.floor(Math.random() * 1000)}-min`}
                            options={this.state.payTypes}
                            value={this.state.selectedPayWith}
                            className="react-select w-100"
                            placeholder="Please select"
                            onChange={this.handlePayWithChange}
                        />
                    </ListFilter.Field>
                    <ListFilter.Field>
                        <Form.Label className="mb-0 col-sm-3 text-sm-end">
                            Date
                        </Form.Label>
                        <div className="react-select-header">
                            <DatePicker
                                selected={
                                    this.state.date ?? this.updatedData.date
                                        ? new Date(
                                              this.state.date ??
                                                  this.updatedData.date
                                          )
                                        : ''
                                }
                                minDate={new Date()}
                                showMonthDropdown="true"
                                showYearDropdown="true"
                                onChange={this.handleDateChange}
                                className="form-control"
                                placeholderText="Select"
                            />
                        </div>
                    </ListFilter.Field>
                    <ListFilter.Field>
                        <Form.Label className="mb-0 col-sm-3 text-sm-end">
                            Due Date
                        </Form.Label>
                        <div className="react-select-header">
                            <DatePicker
                                selected={
                                    this.state.dueDate ??
                                    this.updatedData.dueDate
                                        ? new Date(
                                              this.state.dueDate ??
                                                  this.updatedData.dueDate
                                          )
                                        : ''
                                }
                                minDate={new Date()}
                                showMonthDropdown="true"
                                showYearDropdown="true"
                                onChange={this.handleDueDateChange}
                                className="form-control"
                                placeholderText="Select"
                            />
                        </div>
                    </ListFilter.Field>
                </ListFilter.Fields>
            </ListFilter>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* Filter */}
                                {this.renderListFilter()}

                                <div className="table-gradient">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                    >
                                        <thead>
                                            <tr
                                                key="0"
                                                className="a-table-heading deposit"
                                            >
                                                <th>Tag</th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="payee"
                                                    >
                                                        Vendor
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="keysort"
                                                    >
                                                        PO/WO No.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="sinvnum"
                                                    >
                                                        Verbal/Ref. No
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="sinvdt"
                                                    >
                                                        Order Date
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="orgpayee"
                                                    >
                                                        Ship To
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="invamt"
                                                    >
                                                        Total Cost
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="amtdue"
                                                    >
                                                        Deposit
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr
                                                className={`a-table-search-fields ${
                                                    this.state.showTableSearch
                                                        ? ''
                                                        : 'd-none'
                                                }`}
                                            >
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="projectCodeNo"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.dataIsLoaded
                                                ? this.state.vendorInvoices.map(
                                                      (item, i) => (
                                                          <tr key={i}>
                                                              <td>
                                                                  <Form.Check
                                                                      inline
                                                                      label=""
                                                                      name={
                                                                          `group-` +
                                                                          i
                                                                      }
                                                                      type="checkbox"
                                                                      data-id={
                                                                          i
                                                                      }
                                                                      id={
                                                                          `inline-radio-` +
                                                                          i
                                                                      }
                                                                      defaultChecked={
                                                                          item.tag
                                                                      }
                                                                      onChange={() => {
                                                                          this.handleCheck(
                                                                              item.keysort
                                                                          )
                                                                      }}
                                                                  />
                                                              </td>
                                                              <td>
                                                                  {item.payee}
                                                              </td>
                                                              <td>
                                                                  {item.keysort}
                                                              </td>
                                                              <td>
                                                                  {item.sinvnum}
                                                              </td>
                                                              <td>
                                                                  {dayjs(
                                                                      new Date(
                                                                          item.sinvdt
                                                                      )
                                                                  ).format(
                                                                      'MM/DD/YYYY'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.orgpayee
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.invamt
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.amtdue
                                                                  )}
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : showLoading()}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(VendorInvoiceAddDeposit)
