import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { Collapse, Image, Nav, Navbar } from 'react-bootstrap'
import URI from '../../defaults/RoutesDefault'
import { Resource } from '../../resources/Resources'
import { containStr } from '../../helpers/String'
import { getLocalStorage } from '../../utilities/LocalStorage'
import { generatePath } from 'react-router-dom'
import { clearUserData, getCookie, parseJwt } from '../../utilities/Auth'
import Intercom from '../components/Intercom'
import SessionProvider from '../context/session'
import SecurityProvider from '../context/security'
import Datadog from '../components/datadog'
import logo from '../../assets/images/logo.svg'
import { AppNavigationComponent, isActiveMenu } from '../../utilities/Router'
import { initClickNavLink } from '../../utilities/DOM'
import ConfirmModal from '../components/modal/ConfirmModal'
import { delay } from 'lodash'
import Heap from '../components/Heap'
import AccountSwitcherDropdown from '../components/navigation/AccountSwitcherDropdown'
import SecureNavLink from '../components/security/SecureNavLink'
import { SECURITY_ATTRIBUTE_TYPES } from '../context/security'
import Chameleon from '../components/Chameleon'
import ImpersonationBanner from '../components/banners/ImpersonationBanner'
import { Feature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'
import { HeaderContext, SuperHeader } from 'app/layouts/SuperHeader/SuperHeader'

interface State {
    isToggleRelationship: boolean
    isToggleInventory: boolean
    isToggleTimeManagement: boolean
    isToggleItem: boolean
    isToggleContact: boolean
    isToggleAccountReceivable: boolean
    isToggleAccountPayable: boolean
    isToggleGeneralLedger: boolean
    isToggleTopMenu: boolean
    showLogoutModal: boolean
    inImpersonationMode: boolean
    superAdminName: string
    userName: string
}
interface Props {}

class AppLayout extends React.Component<Props, State> {
    private isFixed: boolean
    private dmUserName?: string

    constructor(props: Props) {
        super(props)

        const { pathname } = window.location
        const token = getCookie('dmAuthToken')
        const tokenData = parseJwt(token)

        this.state = {
            isToggleRelationship: false,
            isToggleInventory: false,
            isToggleTimeManagement: false,
            isToggleItem: containStr(pathname, URI.item.base),
            isToggleContact: containStr(pathname, URI.contact.base),
            isToggleAccountReceivable: containStr(
                pathname,
                URI.accountsReceivable.base
            ),
            isToggleAccountPayable: containStr(
                pathname,
                URI.accountsPayable.base
            ),
            isToggleGeneralLedger: containStr(pathname, URI.generalLedger.base),
            isToggleTopMenu: false,
            showLogoutModal: false,
            inImpersonationMode: tokenData?.ImpersonationMode || false,
            superAdminName: tokenData?.SuperadminUsername as string,
            userName: tokenData?.name,
        }

        this.isFixed = true
        this.dmUserName = getCookie('dmUsername')

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this)
        this.handleMenuClick = this.handleMenuClick.bind(this)
    }

    componentDidMount(): void {
        this.bindNavLinkEvents()
    }

    bindNavLinkEvents() {
        // Bind click event on sidebar nav-links.
        // If draft is enabled, this will trigger browser confirm()
        // before leaving the current screen.
        initClickNavLink()
    }

    handleClick = (e: any) => {
        e.preventDefault()

        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState) => {
            const data = {}
            //@ts-ignore
            data[id] = !prevState[id]

            return data
        })

        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            if (!e.target.classList.contains('menu-toggle')) {
                if (confirm('Leaving this screen will discard your changes.')) {
                    window.localStorage.setItem('isDraft', 'false')
                    if (e.currentTarget.href !== undefined) {
                        location.href = e.currentTarget.href
                    } else if (e.target.href !== undefined) {
                        location.href = e.target.href
                    } else {
                        location.href = e.currentTarget.firstChild.href
                    }
                } else {
                    return false
                }
            }
        } else {
            if (!e.target.classList.contains('menu-toggle')) {
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    handleMenuClick = (e: any) => {
        e.preventDefault()
        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState: any) => {
            const data: any = {}
            data[id] = !prevState[id]
            return data
        })
    }

    handleLogoutClick = (e: any) => {
        this.toggleLogoutModal(false)
        e.preventDefault()

        delay(() => {
            clearUserData()
            window.location.href = generatePath(URI.login.base)
        }, 700)
    }

    toggleLogoutModal = (state: boolean) => {
        this.setState({
            showLogoutModal: state,
        })
    }

    changeStatus = () => {
        this.setState((prevState: State) => {
            prevState.isToggleTopMenu = false
            return prevState
        })
    }

    render(): JSX.Element {
        return (
            <SecurityProvider>
                <SessionProvider>
                    <Intercom />
                    <Heap />
                    <Datadog />
                    <Chameleon />

                    <AppNavigationComponent changeStatus={this.changeStatus}>
                        <div className="nav-container">
                            {this.state.inImpersonationMode && (
                                <ImpersonationBanner
                                    userName={this.state.userName}
                                />
                            )}
                            <Navbar bg="dark" className="topmenu-wrapper">
                                <Navbar.Brand as={Link} to={'/'}>
                                    <img
                                        src={logo}
                                        width="100%"
                                        height="auto"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle
                                    as={NavLink}
                                    to={URI.home}
                                    className={`menu-toggle ${
                                        this.state.isToggleTopMenu
                                            ? 'nav-open'
                                            : ''
                                    }`}
                                    data-state="isToggleTopMenu"
                                    onClick={this.handleClick}
                                >
                                    <span className="menu-toggle-bar menu-toggle-bar--top"></span>
                                    <span className="menu-toggle-bar menu-toggle-bar--middle"></span>
                                    <span className="menu-toggle-bar menu-toggle-bar--bottom"></span>
                                </Navbar.Toggle>
                            </Navbar>
                        </div>
                        <div
                            className={`sidemenu-wrapper ${
                                this.isFixed ? 'fixed-left' : ''
                            } ${this.state.isToggleTopMenu ? 'nav-open' : ''}`}
                        >
                            <Navbar
                                bg="dark"
                                expand="lg"
                                variant="dark"
                                className={`sidemenu ${
                                    this.state.inImpersonationMode
                                        ? 'sidemenu-impersonation'
                                        : ''
                                } `}
                            >
                                <Navbar.Brand
                                    onClick={this.handleClick}
                                    as={Link}
                                    to={URI.home}
                                    className="d-flex w-100"
                                >
                                    <Image
                                        src={Resource.Image.Logo.NavigationLogo}
                                        className="me-auto"
                                    ></Image>
                                </Navbar.Brand>
                                <Nav className="side-nav">
                                    {/* <NavLink to={URI.home} end className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                    <i className="ri-home-fill fsx-24"></i> Home
                                </NavLink> */}
                                    <NavLink
                                        to={URI.project.base}
                                        className={({
                                            isActive,
                                        }: {
                                            isActive: boolean
                                        }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-stack-fill fsx-24"></i>{' '}
                                        Projects
                                    </NavLink>

                                    {/* <<<<<<<<<<-- ITEM --------------  */}
                                    <SecureNavLink
                                        attributeNo=""
                                        attributeType=""
                                        to={URI.item.clippedItem.base}
                                        className={({
                                            isActive,
                                        }: {
                                            isActive: boolean
                                        }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-clipboard-fill fsx-24"></i>{' '}
                                        Clipped Items
                                    </SecureNavLink>
                                    {/* show v1
                                <NavLink to={URI.item.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold has-collapse', this.state.isToggleItem ? 'nav-link-show' : null].filter(Boolean).join(' ')}
                                    data-toggle="collapse"
                                    data-state="isToggleItem"
                                    data-target="#isToggleItem"
                                    aria-controls="isToggleItem"
                                    aria-expanded={this.state.isToggleItem}
                                    onClick={this.handleMenuClick}>
                                    <i className="ri-archive-fill fsx-24"></i> Items
                                </NavLink>
                                <Collapse in={this.state.isToggleItem}>
                                    <ul className="nav-second-level">
                                        <NavLink to={URI.item.clippedItem.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-clipboard-fill fsx-24"></i> Clipped Items
                                        </NavLink>
                                        <NavLink to={URI.item.catalog.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-book-2-fill fsx-24"></i> Catalog
                                        </NavLink>
                                        <NavLink to={URI.item.stockItem.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-store-fill fsx-24"></i> Stock Items
                                        </NavLink>
                                        <NavLink to={URI.item.search} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-search-line fsx-24"></i> Item Search
                                        </NavLink>
                                    </ul>
                                </Collapse>
                                */}
                                    {/* ---------------->>>>>>>>>> */}

                                    <NavLink
                                        to={URI.item.search}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-search-line fsx-24"></i>{' '}
                                        Item Search
                                    </NavLink>

                                    <SecureNavLink
                                        attributeNo={15}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        to={URI.timeEntry.base}
                                        className={({
                                            isActive,
                                        }: {
                                            isActive: boolean
                                        }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-timer-fill fsx-24"></i>{' '}
                                        Time Entry
                                    </SecureNavLink>

                                    {/* <<<<<<<<<<-- CONTACTS --------------  */}
                                    <NavLink
                                        to={URI.contact.base}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold has-collapse',
                                                this.state.isToggleContact
                                                    ? 'nav-link-show'
                                                    : null,
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                        data-toggle="collapse"
                                        data-state="isToggleContact"
                                        data-target="#isToggleContact"
                                        aria-controls="isToggleContact"
                                        aria-expanded={
                                            this.state.isToggleContact
                                        }
                                        onClick={this.handleMenuClick}
                                    >
                                        <i className="ri-team-fill fsx-24"></i>{' '}
                                        Contacts
                                    </NavLink>
                                    <Collapse in={this.state.isToggleContact}>
                                        <ul className="nav-second-level">
                                            <SecureNavLink
                                                attributeNo={39}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={URI.contact.client}
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.contact.client
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-group-fill fsx-24"></i>{' '}
                                                Clients
                                            </SecureNavLink>
                                            <SecureNavLink
                                                attributeNo={49}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={URI.contact.vendor}
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.contact.vendor
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-briefcase-4-fill fsx-24"></i>{' '}
                                                Vendors
                                            </SecureNavLink>
                                            <SecureNavLink
                                                attributeNo={40}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={URI.contact.employee}
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.contact.employee
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-account-box-fill fsx-24"></i>{' '}
                                                Employees
                                            </SecureNavLink>
                                        </ul>
                                    </Collapse>
                                    {/* ---------------->>>>>>>>>> */}

                                    {/* <<<<<<<<<<-- ACCOUNTS RECEIVABLE --------------  */}
                                    <NavLink
                                        to={URI.accountsReceivable.base}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold has-collapse',
                                                this.state
                                                    .isToggleAccountReceivable
                                                    ? 'nav-link-show'
                                                    : null,
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                        data-toggle="collapse"
                                        data-state="isToggleAccountReceivable"
                                        data-target="#isToggleAccountReceivable"
                                        aria-controls="isToggleAccountReceivable"
                                        aria-expanded={
                                            this.state.isToggleAccountReceivable
                                        }
                                        onClick={this.handleMenuClick}
                                    >
                                        <i className="ri-folder-shared-fill fsx-24"></i>{' '}
                                        Accounts Receivable
                                    </NavLink>
                                    <Collapse
                                        in={
                                            this.state.isToggleAccountReceivable
                                        }
                                    >
                                        <ul className="nav-second-level">
                                            <SecureNavLink
                                                attributeNo={26}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={
                                                    URI.accountsReceivable
                                                        .clientInvoices.listNew
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI
                                                                .accountsReceivable
                                                                .clientInvoices
                                                                .listNew
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-file-paper-2-fill fsx-24"></i>{' '}
                                                Client Invoices
                                            </SecureNavLink>
                                            <SecureNavLink
                                                attributeNo={28}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={
                                                    URI.accountsReceivable
                                                        .cashReceipt.listNew
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI
                                                                .accountsReceivable
                                                                .cashReceipt
                                                                .listNew
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-file-list-3-fill fsx-24"></i>{' '}
                                                Cash Receipts
                                            </SecureNavLink>
                                            <SecureNavLink
                                                attributeNo={194}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={
                                                    URI.accountsReceivable
                                                        .returnsAndCredit.base
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI
                                                                .accountsReceivable
                                                                .returnsAndCredit
                                                                .base
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-swap-box-fill fsx-24"></i>{' '}
                                                Returns & Credits
                                            </SecureNavLink>
                                            <Feature
                                                name={FeatureFlags.RAINFOREST}
                                            >
                                                <NavLink
                                                    to={
                                                        URI.accountsReceivable
                                                            .designPay.base
                                                    }
                                                    className={({
                                                        isActive,
                                                    }: {
                                                        isActive: boolean
                                                    }) =>
                                                        [
                                                            isActive ||
                                                            isActiveMenu(
                                                                URI
                                                                    .accountsReceivable
                                                                    .designPay
                                                                    .base
                                                            )
                                                                ? 'active'
                                                                : null,
                                                            'nav-link fw-bold',
                                                        ]
                                                            .filter(Boolean)
                                                            .join(' ')
                                                    }
                                                >
                                                    <i className="ri-safe-fill fsx-24"></i>{' '}
                                                    Design Pay
                                                </NavLink>
                                            </Feature>
                                        </ul>
                                    </Collapse>
                                    {/* ---------------->>>>>>>>>> */}

                                    {/* <<<<<<<<<<-- ACCOUNTS PAYABLE --------------  */}
                                    <NavLink
                                        to={URI.accountsPayable.base}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold has-collapse',
                                                this.state
                                                    .isToggleAccountPayable
                                                    ? 'nav-link-show'
                                                    : null,
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                        data-toggle="collapse"
                                        data-state="isToggleAccountPayable"
                                        data-target="#isToggleAccountPayable"
                                        aria-controls="isToggleAccountPayable"
                                        aria-expanded={
                                            this.state.isToggleAccountPayable
                                        }
                                        onClick={this.handleMenuClick}
                                    >
                                        <i className="ri-folder-received-fill fsx-24"></i>{' '}
                                        Accounts<div>Payable</div>
                                    </NavLink>
                                    <Collapse
                                        in={this.state.isToggleAccountPayable}
                                    >
                                        <ul className="nav-second-level">
                                            <NavLink
                                                to={
                                                    URI.accountsPayable
                                                        .vendorInvoice.listNew
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.accountsPayable
                                                                .vendorInvoice
                                                                .listNew
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-file-paper-2-fill fsx-24"></i>{' '}
                                                Vendor Deposits & Invoices
                                            </NavLink>
                                            <NavLink
                                                to={
                                                    URI.accountsPayable.payment
                                                        .base
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.accountsPayable
                                                                .payment.base
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-money-dollar-circle-fill fsx-24"></i>{' '}
                                                Payments
                                            </NavLink>
                                            <NavLink
                                                to={
                                                    URI.accountsPayable
                                                        .checkbook.base
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.accountsPayable
                                                                .checkbook.base
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-wallet-3-fill fsx-24"></i>{' '}
                                                Checkbook
                                            </NavLink>
                                            <NavLink
                                                to={
                                                    URI.accountsPayable
                                                        .creditCard.base
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.accountsPayable
                                                                .creditCard.base
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                                end={true}
                                            >
                                                <i className="ri-bank-card-2-fill fsx-24"></i>{' '}
                                                Credit Card
                                            </NavLink>
                                            <Feature name="CC_RECON">
                                                <NavLink
                                                    to={
                                                        URI.accountsPayable
                                                            .creditCard
                                                            .reconcile
                                                    }
                                                    className={({
                                                        isActive,
                                                    }: {
                                                        isActive: boolean
                                                    }) =>
                                                        [
                                                            isActive
                                                                ? 'active'
                                                                : null,
                                                            'nav-link fw-bold',
                                                        ]
                                                            .filter(Boolean)
                                                            .join(' ')
                                                    }
                                                    end={true}
                                                >
                                                    <i className="ri-refund-fill fsx-24"></i>{' '}
                                                    Reconcile Credit Card
                                                </NavLink>
                                                <NavLink
                                                    to={
                                                        URI.accountsPayable
                                                            .creditCard.history
                                                    }
                                                    className={({
                                                        isActive,
                                                    }: {
                                                        isActive: boolean
                                                    }) =>
                                                        [
                                                            isActive
                                                                ? 'active'
                                                                : null,
                                                            'nav-link fw-bold',
                                                        ]
                                                            .filter(Boolean)
                                                            .join(' ')
                                                    }
                                                >
                                                    <i className="ri-safe-2-fill fsx-24"></i>{' '}
                                                    Reconcile Credit Card
                                                    History
                                                </NavLink>
                                            </Feature>
                                            {/* <NavLink to={URI.accountsPayable.vendorCredit.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-vip-diamond-fill fsx-24"></i> Vendor Credits
                                        </NavLink> */}
                                        </ul>
                                    </Collapse>
                                    {/* ---------------->>>>>>>>>> */}

                                    {/* <<<<<<<<<<-- GENERAL LEDGER --------------  */}
                                    <NavLink
                                        to={URI.generalLedger.base}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold has-collapse',
                                                this.state.isToggleGeneralLedger
                                                    ? 'nav-link-show'
                                                    : null,
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                        data-toggle="collapse"
                                        data-state="isToggleGeneralLedger"
                                        data-target="#isToggleGeneralLedger"
                                        aria-controls="isToggleGeneralLedger"
                                        aria-expanded={
                                            this.state.isToggleGeneralLedger
                                        }
                                        onClick={this.handleMenuClick}
                                    >
                                        <i className="ri-book-fill fsx-24"></i>{' '}
                                        General Ledger
                                    </NavLink>
                                    <Collapse
                                        in={this.state.isToggleGeneralLedger}
                                    >
                                        <ul className="nav-second-level">
                                            <NavLink
                                                to={
                                                    URI.generalLedger
                                                        .transactionSearch.list
                                                }
                                                className={({ isActive }) =>
                                                    [
                                                        isActive
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-search-line fsx-24"></i>{' '}
                                                Transaction Search
                                            </NavLink>
                                            <SecureNavLink
                                                attributeNo={31}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={
                                                    URI.generalLedger.account
                                                        .list
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.generalLedger
                                                                .account.list
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-contacts-fill fsx-24"></i>{' '}
                                                Accounts
                                            </SecureNavLink>
                                            <SecureNavLink
                                                attributeNo={32}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                }
                                                to={
                                                    URI.generalLedger
                                                        .journalEntry.listNew
                                                }
                                                className={({
                                                    isActive,
                                                }: {
                                                    isActive: boolean
                                                }) =>
                                                    [
                                                        isActive ||
                                                        isActiveMenu(
                                                            URI.generalLedger
                                                                .journalEntry
                                                                .listNew
                                                        )
                                                            ? 'active'
                                                            : null,
                                                        'nav-link fw-bold',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')
                                                }
                                            >
                                                <i className="ri-book-3-fill fsx-24"></i>{' '}
                                                Journal Entries
                                            </SecureNavLink>
                                            {/* show v1
                                        <NavLink to={URI.generalLedger.transactionSearch.list} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                            <i className="ri-file-search-fill fsx-24"></i> Transaction Search
                                        </NavLink>
                                        */}
                                        </ul>
                                    </Collapse>
                                    {/* ---------------->>>>>>>>>> */}
                                    {/* show v1
                                <NavLink to={URI.timeManagement.appointment} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                    <i className="ri-calendar-event-fill fsx-24"></i> Task and Appointments
                                </NavLink>

                                <NavLink to={URI.reviewSend.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                    <i className="ri-send-plane-2-fill fsx-24"></i> Review & Send
                                </NavLink>
                                */}
                                    <Feature name="STOCK_ITEMS">
                                        <NavLink
                                            to={URI.stockItem.base}
                                            className={({ isActive }) =>
                                                [
                                                    isActive ? 'active' : null,
                                                    'nav-link fw-bold',
                                                ]
                                                    .filter(Boolean)
                                                    .join(' ')
                                            }
                                        >
                                            <i className="ri-archive-drawer-fill fsx-24"></i>{' '}
                                            Stock Items
                                        </NavLink>
                                    </Feature>
                                    <SecureNavLink
                                        attributeNo={67}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        to={URI.report.base}
                                        className={({
                                            isActive,
                                        }: {
                                            isActive: boolean
                                        }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-file-chart-fill fsx-24"></i>{' '}
                                        Reporting
                                    </SecureNavLink>

                                    <NavLink
                                        to={URI.settings.list}
                                        className={({ isActive }) =>
                                            [
                                                isActive ? 'active' : null,
                                                'nav-link fw-bold',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')
                                        }
                                    >
                                        <i className="ri-settings-4-fill fsx-24"></i>{' '}
                                        Settings
                                    </NavLink>

                                    {/* <FloatingHelp></FloatingHelp> */}

                                    {/* <NavLink to={URI.uikit.base} className={({ isActive }) => [isActive ? 'active' : null, 'nav-link fw-bold'].filter(Boolean).join(' ')} >
                                    <i className="ri-tools-fill fsx-24"></i> UI Kit
                                </NavLink> */}

                                    <a
                                        href="/help"
                                        onClick={(e) => e.preventDefault()}
                                        className="intercom-help-button nav-link fw-bold"
                                    >
                                        <i className="ri-customer-service-fill fsx-24"></i>{' '}
                                        Help
                                    </a>

                                    {/* <a
                                    href="#"
                                    onClick={this.toggleLogoutModal.bind(
                                        this,
                                        true
                                    )}
                                    className="nav-link fw-bold"
                                >
                                    <i className="ri-login-box-fill fsx-24"></i>{' '}
                                    Logout
                                </a> */}
                                </Nav>

                                <Nav className="side-nav at-bottom">
                                    <AccountSwitcherDropdown
                                        onLogout={() => {
                                            this.toggleLogoutModal(true)
                                        }}
                                    />
                                </Nav>

                                {/* <Nav className="side-nav at-bottom">
                                </Nav> */}
                            </Navbar>
                        </div>
                        <div
                            className={`main-content ${
                                this.state.inImpersonationMode
                                    ? 'impersonating'
                                    : ''
                            }`}
                        >
                            <SuperHeader />
                            <Outlet />
                        </div>
                        <div id="response-alert-div"></div>

                        <ConfirmModal
                            title="Logout Confirm"
                            message={`Are you sure you want to logout as ${this.dmUserName}?`}
                            labelOK="Logout"
                            labelCancel="Cancel"
                            show={this.state.showLogoutModal}
                            toggleModal={this.toggleLogoutModal.bind(
                                this,
                                false
                            )}
                            confirmAction={this.handleLogoutClick.bind(this)}
                        />
                    </AppNavigationComponent>
                </SessionProvider>
            </SecurityProvider>
        )
    }
}

export default AppLayout
