import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import { ApiService } from '../../../lib/api/HttpService'
import DatePicker from 'react-datepicker'
import {
    FormEvent,
    setLocalStorageKeyValueObject,
} from '../../../utilities/FormEvent'
import {
    setLocalStorage,
    getLocalStorage,
} from '../../../utilities/LocalStorage'
import dayjs from 'dayjs'
import { getSaveButtonName } from '../../../utilities/String'
import { FooterFormAction } from '../../components/Form'
import { Is } from '../../../helpers/Util'
import { displayAlert } from '../../../utilities/Response'
import Spinner from '../../../app/components/help/Spinner'

class AccountSettingsFiscalMonth extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: { autodeffmo: false },
            fiscalOffsetDate: new Date(),
            isFiscalOffsetDisabled: true,
            company: {},
            currentDate: dayjs(new Date()).format('MM/YYYY'),
            isLoading: false,
            isMounted: false,
        }

        this.api = new ApiService()
        this.formEvent = new FormEvent('company2_settings_edit', true)
    }

    componentInit() {
        this.setTitle('Fiscal Month | Account Settings')
    }

    async componentDidMount() {
        setLocalStorage(`company2_settings_edit`, {}, true)
        const company = await this.api.getCompany()
        const company2 = await this.api.getCompany2()

        if (company.fiscaloffset !== undefined) {
            let fiscalDate = new Date()
            fiscalDate = new Date(
                fiscalDate.setMonth(
                    fiscalDate.getMonth() + company.fiscaloffset
                )
            )

            this.setState({
                fiscalOffsetDate: fiscalDate,
                company,
            })
        }

        this.renderData(company2)

        this.setState({
            isMounted: true,
        })
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleRadioChange = (e) => {
        const deffmo = e.target.value === '1' ? true : false
        setLocalStorageKeyValueObject(
            `company2_settings_edit`,
            'autodeffmo',
            deffmo,
            true
        )
        this.setState((prevState) => (prevState.data.autodeffmo = deffmo))
    }

    handleDateChange = (name, date) => {
        let { data } = this.state
        data[name] = date.toString()

        this.formEvent.handleDateChange('YYYY-MM-DD', name, date)
        this.renderData(data)
    }

    handleFiscalOffsetDateChange = (date) => {
        const monthOffset = this.getMonthOffset(date)
        let { data } = this.state
        data.fiscaloffset = monthOffset

        setLocalStorageKeyValueObject(
            `company_settings_edit`,
            'fiscaloffset',
            monthOffset,
            true
        )
        this.setState({
            fiscalOffsetDate: date,
            data,
        })
    }

    handleAgreementClick = (e) => {
        this.setState({
            isFiscalOffsetDisabled: false,
        })
    }

    getMonthOffset(date) {
        const currDate = new Date()
        let months = (date.getFullYear() - currDate.getFullYear()) * 12
        months -= currDate.getMonth()
        months += date.getMonth()
        return months
    }

    getDateValue(key) {
        return this.state.data !== undefined &&
            this.state.data[key] !== null &&
            this.state.data[key] !== undefined
            ? new Date(this.state.data[key])
            : new Date()
    }

    handleClickSave = (e) => {
        const company2 = getLocalStorage('company2_settings_edit', true)
        this.setIsLoading(true)
        this.api.patchCompany2(company2).then((res) => {
            const company = getLocalStorage('company_settings_edit', true)
            if (!Is.empty(company)) {
                this.api.patchCompany(company).then((res) => {
                    displayAlert('success', 'Successfully saved changes.')
                    this.setIsLoading(false)
                })
            } else {
                displayAlert('success', 'Successfully saved changes.')
                this.setIsLoading(false)
            }
        })
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    render() {
        if (this.state.isMounted) {
            return (
                <>
                    <Form.Group>
                        <Row xs={1} lg={2} className="mb-5">
                            <Col>
                                <Row xs={1} lg={1}>
                                    <Col className="mb-2 mb-lg-0">
                                        <Row className="mb-lg-3">
                                            <Col lg={12} className="text-start">
                                                <Form.Label className="mb-0">
                                                    <strong>
                                                        Last Closed Fiscal Month
                                                    </strong>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={4}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Accounts Receivable (A/R)
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row lg={2}>
                                            <Col>
                                                <DatePicker
                                                    selected={this.getDateValue(
                                                        'closedarfmo'
                                                    )}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Select"
                                                    onChange={this.handleDateChange.bind(
                                                        this,
                                                        'closedarfmo'
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={4}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Accounts Payable (A/P)
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row lg={2}>
                                            <Col>
                                                <DatePicker
                                                    selected={this.getDateValue(
                                                        'closedapfmo'
                                                    )}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Select"
                                                    onChange={this.handleDateChange.bind(
                                                        this,
                                                        'closedapfmo'
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={4}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            General Ledger (G/L)
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row lg={2}>
                                            <Col>
                                                <DatePicker
                                                    selected={this.getDateValue(
                                                        'closedglfmo'
                                                    )}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Select"
                                                    onChange={this.handleDateChange.bind(
                                                        this,
                                                        'closedglfmo'
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row xs={1} lg={1}>
                                    <Col className="mb-2 mb-lg-0">
                                        <Row className="mb-lg-3">
                                            <Col lg={3} className="text-start">
                                                <Form.Label className="mb-0">
                                                    <strong>Options</strong>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col>
                                        <Row lg={1} className="mb-4">
                                            <Col className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Allow Posting Prior to the Calendar Date of Last Cash Reconciliation"
                                                    name="allowpriorcashrec"
                                                    type="checkbox"
                                                    defaultChecked={
                                                        this.state.data
                                                            .allowpriorcashrec
                                                    }
                                                    id={`inline-check-1`}
                                                    onClick={this.formEvent.handleCheckChange.bind(
                                                        this
                                                    )}
                                                />
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Allow Unclearing of Checkbook/Credit Card Entries"
                                                    name="clearcbentries"
                                                    type="checkbox"
                                                    defaultChecked={
                                                        this.state.data
                                                            .clearcbentries
                                                    }
                                                    id={`inline-check-2`}
                                                    onClick={this.formEvent.handleCheckChange.bind(
                                                        this
                                                    )}
                                                />
                                            </Col>
                                        </Row>

                                        <Row lg={1}>
                                            <Col className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Default Fiscal Month to 1 Plus the Closed Month"
                                                    name="group2"
                                                    type="radio"
                                                    checked={
                                                        !this.state.data
                                                            .autodeffmo
                                                    }
                                                    id={`inline-radio-1`}
                                                    onClick={
                                                        this.handleRadioChange
                                                    }
                                                    value={0}
                                                />
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Default Fiscal Month to Current Fiscal Month Below"
                                                    name="group2"
                                                    type="radio"
                                                    checked={
                                                        this.state.data
                                                            .autodeffmo
                                                    }
                                                    id={`inline-radio-2`}
                                                    onClick={
                                                        this.handleRadioChange
                                                    }
                                                    value={1}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row lg={1} className="mb-5">
                            <Col>
                                Your computer's current calendar month is{' '}
                                {this.state.currentDate}. The fiscal month that
                                corresponds to this calendar month should be
                                entered below. This fiscal month setting should
                                NOT be changed after any date has been entered
                                into Design Manager unless you are fully aware
                                of the consequences in doing so. You must click
                                on the button below before the Current Fiscal
                                Month can be changed.
                                <Row lg={2} className="mt-5">
                                    <Col>
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            onClick={this.handleAgreementClick}
                                        >
                                            I have read and understand the above
                                            statement
                                        </Button>
                                    </Col>

                                    <Col>
                                        <Row className="align-items-center mb-3">
                                            <Col
                                                lg={6}
                                                className="text-lg-end mb-2 mb-lg-0"
                                            >
                                                <Form.Label className="mb-0">
                                                    Current Fiscal Month
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Row lg={1}>
                                                    <Col>
                                                        <DatePicker
                                                            selected={
                                                                this.state
                                                                    .fiscalOffsetDate
                                                            }
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                            className="form-control"
                                                            placeholderText="Select"
                                                            disabled={
                                                                this.state
                                                                    .isFiscalOffsetDisabled
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleFiscalOffsetDateChange
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Group>

                    <FooterFormAction>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={this.handleClickSave}
                        >
                            {getSaveButtonName(this.state.isLoading, 'Save')}
                        </Button>
                    </FooterFormAction>
                </>
            )
        } else {
            return (
                <>
                    <Spinner />
                </>
            )
        }
    }
}

export default WithRouter(AccountSettingsFiscalMonth)
