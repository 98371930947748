export enum BudgetWarnings {
    None = 0,
    DisplayWarning = 1,
    BlockEntry = 2,
}

export enum BudgetTypes {
    SalesCategory = 0,
    Location = 1,
}
