export function isEmail(email: string): boolean {
    return (email || '').match(/^\S+@\S+\.\S+$/) ? true : false
}

export function isNullOrUndefined(data: any): boolean {
    return data === null || data === undefined || typeof data === 'undefined'
}

export function isValidUrl(url: string) {
    try {
        new URL(url)
        return true
    } catch (err) {
        return false
    }
}
