import React from 'react'
import { Outlet } from 'react-router-dom'

import UiKitFooter from '../ui-kit/UiKitFooter'
import UiKitNavigation from '../ui-kit/UiKitNavigation'

function UiKitLayout() {
    return (
        <>
            <UiKitNavigation />
            <div className="d-block mt-5 uikit-content">
                <Outlet />
            </div>
            <UiKitFooter />
        </>
    )
}

export default UiKitLayout
