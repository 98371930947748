import React from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'

import ThreadComponent from '../../../ThreadComponent'
import { VendorService } from '../api/VendorService'
import { currencyFormat } from '../../../../helpers/Number'

interface State {
    statusData: any
    isLoading: boolean
}

interface Props {
    vendorId: number
}

class VendorEditStatus extends ThreadComponent<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            statusData: {},
            isLoading: false,
        }
    }

    async componentDidMount() {
        await this.fetchVendorStatus()
    }

    async fetchVendorStatus() {
        this.setState({ isLoading: true })

        const vendorService = new VendorService()

        try {
            const data = await vendorService.getVendorStatus(
                this.props.vendorId
            )
            this.setState({ statusData: data, isLoading: false })
        } catch (e) {
            this.setState({ isLoading: false })
        }
    }

    render(): JSX.Element {
        const { statusData, isLoading } = this.state

        return isLoading ? (
            <></>
        ) : (
            <Container className="px-0 ms-0">
                <Form.Group>
                    <Row xs={1} lg={2}>
                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Open POs
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.openPOs
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <b>Current Accounts Payable</b>
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.currentAccountsPayable
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Open Vendor Deposits
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.openVendorDeposits
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Account Payables on Hold
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.accountsPayableOnHold
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <b>Future Due</b>
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.futureDue
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <b>Year-to-Date Purchases</b>
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.yearToDatePurchases
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Gross Vendor Invoices
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.grossVendorInvoices
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Prior YTD Purchases
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.priorYtdPurchases
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Deposit Applied
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.priorYtdPurchases
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col />

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <b>Net Invoices</b>
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.netInvoices
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col />

                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <b>Total Payable/Paid</b>
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={currencyFormat(
                                            statusData.totalPayable
                                        )}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form.Group>
            </Container>
        )
    }
}

export default VendorEditStatus
