import React, { ReactNode } from 'react'
import { useSession } from '../../../context/session'

interface IProps {
    children: ReactNode
}

const AdminOnly = ({ children }: IProps) => {
    const { isAdmin } = useSession()
    if (!isAdmin) return null
    return <>{children}</>
}

AdminOnly.displayName = 'AdminOnly'

export default AdminOnly
