import LocationDropdown from 'app/components/dropdowns/LocationsDropdown'
import React from 'react'
import Label from '../../../components/label/Label'
import { WhatChanged } from 'lib/api/types/WhatChangedItems'
import { Controller, useFormContext } from 'react-hook-form'

const LocationSelect = ({
    disabled,
    handleRecalculate,
}: {
    disabled: boolean
    handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void
}) => {
    const methods = useFormContext()
    return (
        <>
            <Label label="Location" />
            <Controller
                control={methods.control}
                name="loc"
                render={({ field }) => (
                    <div className="tw-col-span-2 tw-w-full">
                        <LocationDropdown
                            blurInputOnSelect
                            isDisabled={disabled}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => {
                                const selectedValue = methods.getValues('loc')
                                if (selectedValue) {
                                    handleRecalculate(WhatChanged.LOCATION)
                                }
                            }}
                        />
                    </div>
                )}
            />
        </>
    )
}

LocationSelect.displayName = 'LocationSelect'

export default LocationSelect
