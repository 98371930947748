import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'

export function SearchExpressionButton({ callback, operator }) {
    const exp = operator ?? '>='
    return (
        <Button
            variant="dark"
            size="sm"
            style={{
                position: 'absolute',
                padding: 3,
                border: 'none',
                width: '22px',
                minWidth: '22px',
                right: 21,
                top: 6,
                opacity: 0.85,
                zIndex: 1,
            }}
            onClick={() => {
                const newExp = exp === '=' ? '<=' : exp === '>=' ? '=' : '>='
                callback && callback(newExp)
            }}
        >
            {exp}
        </Button>
    )
}
