function getSubComponent(name, components) {
    let Component

    for (const [key, value] of Object.entries(components)) {
        if (key === name) {
            Component = value
        }
    }

    return Component
}

function getMatchedValue(name, components) {
    let val

    for (const [key, value] of Object.entries(components)) {
        if (key === name) {
            val = value
        }
    }

    return val
}

function getStatusClassName(status) {
    let className

    const statusList = [
        { name: ['Billed', 'Awaiting Client Approval'], class: 'primary' },
        { name: ['Pending'], class: 'primary-yellow' },
        { name: ['Unpaid', 'Needs to be invoiced'], class: 'primary-red' },
        { name: ['Processing'], class: 'primary-blue' },
    ]

    statusList.forEach((item) => {
        if (item.name.indexOf(status) > -1) {
            className = item.class
        }
    })

    return className
}

function getStatusColor(statusName, statusCodes) {
    // Get the status color number
    const status = statusCodes?.filter((stat) => stat.statusName === statusName)
    const color = status && status.length ? status[0].color : 16777215

    // Convert the integer to hexadecimal
    let hexColor = color.toString(16)
    // Make sure the hex string has at least 6 characters
    while (hexColor.length < 6) {
        hexColor = '0' + hexColor
    }
    // Reverse the last two and first two characters
    const part1 = hexColor.slice(4, 6)
    const part2 = hexColor.slice(2, 4)
    const part3 = hexColor.slice(0, 2)
    const flippedHexColor = `${part1}${part2}${part3}`
    let dotStatusColor = `#${flippedHexColor}`

    return dotStatusColor
}

function reload() {
    window.onbeforeunload = null
    setTimeout(() => {
        window.location.reload()
    }, 2000)
}

const Is = {
    empty: function (obj) {
        if (!obj) {
            return true
        }

        if (typeof obj === 'object' && !Object.keys(obj).length) {
            return true
        }

        return false
    },
}

const isNumeric = (num) =>
    (typeof num === 'number' ||
        (typeof num === 'string' && num.trim() !== '')) &&
    !isNaN(num)

const isFiltered = (obj) => Object.entries(obj).length > 0

const _delay = (callback, time) => {
    return setTimeout(callback, time)
}

function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export {
    getSubComponent,
    getStatusClassName,
    getMatchedValue,
    getStatusColor,
    Is,
    isNumeric,
    isFiltered,
    _delay,
    reload,
    bytesToSize,
}
