import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import DatePicker from 'react-datepicker'

class ClientInvoiceFinanceCharge extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
        }
    }

    componentInit() {
        this.setTitle('Finance Charges').setFormAction(true)
    }

    handleDateChange = (date) => {
        this.setState((prevState) => (prevState.startDate = date))
    }

    handleRadioChange = (e) => {
        this.setState(
            (prevState) =>
                (prevState.isDisabled = e.target.value === '1' ? true : false)
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsReceivable.clientInvoices.listNew}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Client Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Generate Finance Charges
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>Finance Charges</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Aging Date
                                </Form.Label>
                            </Col>
                            <Col>
                                <div className="react-select-header">
                                    <DatePicker
                                        selected={this.state.startDate}
                                        minDate={this.state.minDate}
                                        onChange={this.handleDateChange}
                                        showMonthDropdown="true"
                                        showYearDropdown="true"
                                        className="form-control"
                                        placeholderText="Select"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-1 mb-lg-4">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Client Code Range
                                    <br />
                                    (Leave blank for all)
                                </Form.Label>
                            </Col>
                            <Col lg={4} className="mb-3 mb-lg-0">
                                <Form.Control type="text" placeholder="" />
                            </Col>
                            <Col
                                lg={1}
                                className="text-lg-end pe-0  mb-2 mb-lg-0"
                            >
                                <Form.Label className="mb-0">To</Form.Label>
                            </Col>
                            <Col lg={4} className="ps-lg-2">
                                <Form.Control type="text" placeholder="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-4">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0"></Form.Label>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <Form.Check
                                        inline
                                        defaultChecked={true}
                                        label="General for clients with a positive overdue balance and a percentage/days filled in on the client glossary within the range of codes above"
                                        name="grouprd-1"
                                        type="radio"
                                        id={`inline-radio-rd-1`}
                                        className="vtop"
                                        onClick={this.handleRadioChange}
                                        value={1}
                                    />
                                </div>
                                <div>
                                    <Form.Check
                                        inline
                                        label="General for all clients with a positive overdue balance within the range of codes above. Use the following percentage and days overdue:"
                                        name="grouprd-1"
                                        type="radio"
                                        id={`inline-radio-rd-2`}
                                        className="vtop"
                                        onClick={this.handleRadioChange}
                                        value={0}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Finance Chg. %
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    disabled={this.state.isDisabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Finance Chg. Days
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    disabled={this.state.isDisabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceFinanceCharge)
