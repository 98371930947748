export function pF(_number: number): number {
    return typeof _number === 'string' ? parseFloat(_number) : _number
}

export function round(_number: number, decimal = 2): number {
    return Number(Number(_number || 0).toFixed(decimal))
}

export function roundStr(_number: number, decimal = 2): number | string {
    return 0 == _number ? '0.00' : Number(_number || 0).toFixed(decimal)
}

export function pI(_number: number | string) {
    return parseInt((_number || '0').toString())
}

export function isNumberValid(val: string, props: any = {}) {
    const re = /^-?\d*\.?\d{0,6}$/
    const strVal = val.toString()
    const { length } = strVal
    const { max, min } = props?.target ?? props

    if (min !== undefined && parseFloat(val) < min) return false
    if (max !== undefined && parseFloat(val) > max) return false
    if (val === '') return true
    if (length === 1 && strVal[0] === '-') return true
    if (isNaN(parseFloat(val))) return false

    if (props.decimal && val.split('.')[1]?.length > props.decimal) {
        return false
    }

    return val.match(re) !== null
}

export function fixNumberValue(val: string, props: any = {}) {
    return parseFloat(val).toFixed(props?.decimal ?? 2)
}
