import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Form, Alert } from 'react-bootstrap'
import WysiwygEditor from '../../../components/editor/WysiwygEditor'
import ThreadComponent from '../../../ThreadComponent'
import ClientAddEditViewModel from '../ClientAddEditViewModel'
import { ClientService } from '../api/ClientService'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
//@ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser'

interface Props {
    data: any
    onDraft?: any
}

interface State {
    data: any
    notesHtml: string
}

class ClientAddEditNotes extends ThreadComponent<Props, State> {
    readonly clientAddEditModel: ClientAddEditViewModel
    htmlToRtfConverter: any
    constructor(props: Props) {
        super(props)

        this.clientAddEditModel = new ClientAddEditViewModel(
            new ClientService()
        )
        this.state = {
            data: {},
            notesHtml: '',
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
    }

    async componentDidMount() {
        this.setTitle('Notes - Add Clients').hasFormAction(true)

        const { data } = this.props
        this.setState({
            data: data,
            notesHtml: await mapRtfToDraft(data?.notesrtf ?? ''),
        })
    }

    componentDidUpdate(previousProps: Props, previousState: State): void {
        if (previousProps.data !== this.props.data) {
            const { data } = this.props

            this.setState({
                data: data,
            })
        }
    }

    handleClientChange = (e: any) => {
        this.clientAddEditModel.setClientData('notes', e.target.value)
        const htmlValue = this.htmlToRtfConverter.convertHtmlToRtf(
            e.target.html
        )
        this.clientAddEditModel.setClientData('notesrtf', htmlValue)
        this.props.onDraft()
    }

    render(): JSX.Element {
        const { data } = this.state

        return (
            <>
                <Form.Group>
                    <Row>
                        <Col>
                            <Alert
                                key={'danger'}
                                variant={'danger'}
                                id="user-error-area"
                                style={{ display: 'none' }}
                            >
                                Oops, something went wrong.
                                <br />
                                Error:{' '}
                                <strong>
                                    <span id="userError"></span>
                                </strong>
                            </Alert>
                            <Alert
                                key={'form-errors'}
                                variant={'danger'}
                                id="validation-error-area"
                                style={{ display: 'none' }}
                            >
                                Please fix the following errors:
                                <br />
                                <li>
                                    <span id="validationError"></span>
                                </li>
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col lg={6} className="mb-3 mb-lg-0">
                            <WysiwygEditor onChange={this.handleClientChange}>
                                {this.state.notesHtml || ''}
                            </WysiwygEditor>
                        </Col>
                    </Row>
                </Form.Group>
            </>
        )
    }
}

export default observer(ClientAddEditNotes)
