import React, { ReactNode } from 'react'
import BaseButton from 'app/components/Buttons/BaseButton'

interface ISectionContent {
    title?: string
    subtitle?: string
    children?: ReactNode
}

const SectionContent = ({
    title,
    subtitle = '',
    children,
}: ISectionContent) => (
    <>
        <div className="tw-flex tw-justify-between tw-items-center">
            <div>
                {title && (
                    <h2 className="tw-text-xl tw-font-semibold">{title}</h2>
                )}
                {subtitle && <p className="tw-text-gray-400">{subtitle}</p>}
            </div>
            <BaseButton
                text="View All"
                buttonClass="tw-px-4 tw-py-2 tw-border tw-border-green-500 tw-rounded tw-text-black hover:tw-cursor-pointer tw-text-center"
                onClick={() =>
                    (window.location.href = 'https://help.designmanager.com/')
                }
            />
        </div>
        {children}
    </>
)

SectionContent.displayName = 'SectionContent'

export default SectionContent
