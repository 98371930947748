import React from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import { WithRouter } from '../../../helpers/Router'
import { ApiService } from '../../../lib/api/HttpService'
import ListFilter from '../../components/ListFilter'
import { pathParam } from '../../../utilities/Router'
import ThreadComponent from '../ThreadComponent'
import ConfirmModal from '../../../app/components/modal/ConfirmModal'
import { displayAlert } from '../../../utilities/Response'
import { showLoading } from '../../../helpers/Loading'
import { Pagination } from '../../../app/components/pagination/Pagination'
import { debounce } from 'lodash'
import { buildAPIQuery } from '../../../utilities/ModuleHelper'
import { enableTableSortData } from '../../../utilities/modules/TableSorting'
import FilterSort from '../../../utilities/modules/FilterSort'

class GlossaryListSpecificationTemplates extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('glossary_specification_templates_list')
        this.fs.setDefaultSort('speccode asc')
        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            dataIsLoaded: false,
            showModal: false,
            disabledDeleteButton: true,
            page: 1,
            pageSize: 20,
            searchProperties: {},
            sortProperty: this.fs.getSort() ?? 'speccode asc',
        }
        this.api = new ApiService()
        this.errIds = []

        this.apiPath = 'specificationtemplates'
        this.pageTitle = 'Specification Templates | Glossaries'

        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    componentInit() {
        this.setTitle(this.pageTitle).setActionBar(true)
    }

    async fetchItems(currentPage) {
        const { searchProperties, page, pageSize, sortProperty } = this.state

        const data = await this.api.get(
            this.apiPath,
            `?${buildAPIQuery({
                searchProperties,
                pageSize,
                page,
                currentPage,
                sortProperty,
            })}`
        )

        this.renderData(data)
    }
    async componentDidMount() {
        this.fetchItems()
        this.enableSearchAndSortTable()
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.dataIsLoaded !== this.state.dataIsLoaded) {
            this.fetchItems()
        }
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.setState(
            {
                dataIsLoaded: false,
                page: page,
            },
            () => {
                this.fetchItems(page)
            }
        )
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
            },
            this.changePageHandler
        )
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data.filter((template) => {
                return template.speccode !== ''
            }),
        })
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    toggleDeleteButton = (e) => {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    toggleModal = (e) => {
        this.toggleDeleteButton(e)
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async handleDetele(path, id) {
        await this.api
            .delete(path, id, null)
            .then((res) => {
                if (res.status !== 200) this.errIds.push(id)
            })
            .catch((error) => {
                this.errIds.push(id)
            })
    }

    async deleteData(e) {
        this.toggleDeleteButton(e)

        this.dMloader(true)

        for (const [index, value] of Object.entries(this.state.checks)) {
            if (value) {
                await this.handleDetele(this.apiPath, index)
            }
        }

        const message = this.errIds.length
            ? 'The data with the ids [' +
              this.errIds.join() +
              "] couldn't be deleted"
            : 'The data was succesfully deleted'
        const variant = this.errIds.length ? 'danger' : 'success'

        displayAlert(variant, message)

        this.dMloader(false)
        this.refreshDataAfterDelete(e)
    }

    // Refresh data
    refreshDataAfterDelete = (e) => {
        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: {},
            totalChecked: 0,
            showModal: false,
            deleteResponses: [],
        })
        headingCheck.checked = false

        this.changePageHandler()

        this.uncheckAll()
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked
            prevState.disabledDeleteButton = totalChecked > 0 ? false : true

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    uncheckAll() {
        const checks = document.querySelectorAll(
            '.chk-glossaryLocations-items-item input'
        )
        if (checks) {
            checks.forEach((e) => {
                e.checked = false
            })
        }
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[item.id] = true
                        document.getElementById(
                            'chk-glossaryLocations-items-' + i
                        ).checked = true
                        return null
                    })
                }
                prevState.totalChecked = this.state.data.length
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                this.uncheckAll()
            }
            prevState.disabledDeleteButton =
                prevState.totalChecked > 0 ? false : true

            return prevState
        })
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')

        enableTableSortData(this)
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderFilter() {
        return (
            <>
                <ListFilter expandable={0} className="no-separator">
                    <ListFilter.Actions lg={12} xl={12}>
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <Button
                                as={Link}
                                to={URI.glossary.add.specificationTemplates}
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                            <Button
                                as={Link}
                                onClick={this.toggleModal.bind(this)}
                                to={'#'}
                                variant="danger"
                                size="sm"
                                className={
                                    this.state.disabledDeleteButton |
                                    (this.state.totalChecked < 1)
                                        ? 'btn-icon ms-3 disabled'
                                        : 'btn-icon ms-3'
                                }
                            >
                                Delete
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderFilter()}

                <div className="table-gradient">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr className="a-table-heading">
                                <th align="middle" className="w-70px">
                                    <div className="d-flex justify-content-center">
                                        <Form.Check
                                            inline
                                            label=""
                                            name={`inline-check-th-0`}
                                            type="checkbox"
                                            data-id={`th-0`}
                                            id={`inline-check-th-0`}
                                            defaultChecked={
                                                this.state.totalChecked > 0
                                                    ? true
                                                    : false
                                            }
                                            className={`${
                                                this.state.totalChecked > 0 &&
                                                this.state.totalChecked <
                                                    this.state.data.length
                                                    ? 'line'
                                                    : ''
                                            }`}
                                            onClick={this.handleSelectAllChecks}
                                        />
                                    </div>
                                </th>
                                <th className="w-200px ps-3">
                                    <span
                                        className={this.sortClass('speccode')}
                                        data-field="speccode"
                                    >
                                        Template No
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className={this.sortClass(
                                            'speccodename'
                                        )}
                                        data-field="speccodename"
                                    >
                                        Description
                                    </span>
                                </th>
                            </tr>
                            <tr
                                className={`a-table-search-fields ${
                                    this.state.showTableSearch ? '' : 'd-none'
                                }`}
                            >
                                <th></th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="speccode"
                                        onChange={this.handleSearch}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="speccodename"
                                        onChange={this.handleSearch}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.dataIsLoaded
                                ? showLoading()
                                : this.state.data.map((item, i) => (
                                      <tr
                                          key={i}
                                          data-key={i}
                                          className={
                                              this.state.checks[i]
                                                  ? `active`
                                                  : ''
                                          }
                                      >
                                          <td>
                                              <div className="d-flex justify-content-center">
                                                  <Form.Check
                                                      label=""
                                                      name={`group-` + i}
                                                      type="checkbox"
                                                      data-id={item.speccode}
                                                      id={
                                                          `chk-glossaryLocations-items-` +
                                                          i
                                                      }
                                                      className="chk-glossaryLocations-items-item"
                                                      defaultValue={
                                                          this.state.checks[
                                                              item.speccode
                                                          ]
                                                              ? true
                                                              : false
                                                      }
                                                      onClick={
                                                          this.handleChecks
                                                      }
                                                  />
                                              </div>
                                          </td>
                                          <td className="ps-3">
                                              <Link
                                                  to={pathParam(
                                                      URI.glossary.edit
                                                          .specificationTemplates,
                                                      { id: item.speccode }
                                                  )}
                                                  className="text-charcoal hover-view-icon"
                                              >
                                                  {item.speccode}
                                              </Link>
                                          </td>
                                          <td>{item.speccodename}</td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>

                    {(() => {
                        if (this.state.data.length) {
                            return (
                                <Pagination
                                    onPageSizeChanged={this.onPageSizeChanged}
                                    onPageChanged={this.onPageChanged}
                                    hasPreviousPage={this.state.page > 1}
                                    hasNextPage={
                                        this.state.data.length >
                                        this.state.pageSize
                                    }
                                    page={this.state.page}
                                    pageSize={this.state.pageSize}
                                />
                            )
                        }
                    })()}
                </div>
                <ConfirmModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    toggleModal={this.toggleModal}
                    confirmAction={this.deleteData.bind(this)}
                />
            </>
        )
    }
}

export default WithRouter(GlossaryListSpecificationTemplates)
