import { makeAutoObservable } from 'mobx'
import {
    ITimeEntryResponse,
    IProjectsResponse,
    IItemsResponse,
    ILocationsResponse,
    IComponentsResponse,
    IEmployeesResponse,
    ISalesCategoriesResponse,
    TimeEntryService,
} from '../service/TimeEntryService'
import { ApiService } from '../../../../lib/api/HttpService'
import { getLocalStorage } from '../../../../utilities/LocalStorage'

interface SelectOption {
    label: string
    value: string
}

export default class TimeEntryAddEditViewModel {
    get timeEntry(): ITimeEntryResponse {
        return this._timeEntry
    }

    get timeEntryData(): ITimeEntryResponse {
        return this._timeEntryData
    }

    get projects(): IProjectsResponse[] {
        return this._projects
    }

    get items(): IItemsResponse[] {
        return this._items
    }

    get components(): IComponentsResponse[] {
        return this._components
    }

    get salesCategories(): ISalesCategoriesResponse[] {
        return this._salesCategories
    }

    get locations(): ILocationsResponse[] {
        return this._locations
    }

    get employees(): IEmployeesResponse[] {
        return this._employees
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    get activities(): SelectOption[] {
        return this._activities
    }

    set activities(value: SelectOption[]) {
        this._activities = value
    }

    private timeEntryService: TimeEntryService
    private _isLoaded = false
    private _timeEntry: any = {}
    private _timeEntryData: any = {}
    public _id = 0
    private _projects: IProjectsResponse[] = []
    private _items: IItemsResponse[] = []
    private _components: IComponentsResponse[] = []
    private _locations: ILocationsResponse[] = []
    private _salesCategories: ISalesCategoriesResponse[] = []
    private _employees: IEmployeesResponse[] = []
    private _activities: SelectOption[] = []
    private apiService = new ApiService()

    constructor(timeEntryService: TimeEntryService) {
        this.timeEntryService = timeEntryService

        makeAutoObservable(this)
    }

    setTimeEntry(timeEntry: any): TimeEntryAddEditViewModel {
        this._timeEntry = timeEntry
        this._timeEntryData = timeEntry
        window.localStorage.setItem('timeEntryData', JSON.stringify(timeEntry))
        return this
    }

    setTimeEntryData(key: any, value: any): TimeEntryAddEditViewModel {
        if (!this._timeEntryData) {
            this._timeEntryData = {}
        }

        this._timeEntryData[key] = value
        window.localStorage.setItem(
            'timeEntryData',
            JSON.stringify(this._timeEntryData)
        )

        this._timeEntry[key] = value

        if (key == 'proj') {
            this.timeEntryService
                .getItems(`?$filter=timebudget eq true and proj eq '${value}'`)
                .then((resp) => {
                    const items: any = []
                    const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ]
                    const today = new Date()
                    const todayDate =
                        today.getFullYear() +
                        '-' +
                        ('0' + (today.getMonth() + 1)).slice(-2)
                    const d = new Date(
                        today.getFullYear(),
                        today.getMonth() + 1,
                        0
                    )

                    if (resp.length > 0) {
                        const tmpItems = resp

                        for (const i of items) {
                            if (todayDate == i.timeedate.substring(0, 7))
                                items.push(i)
                        }
                    }

                    if (items.length < 1) {
                        this.timeEntryService
                            .getProjects(
                                `?$filter=proj eq '${value}' and closeddt eq null`
                            )
                            .then((resp) => {
                                if (resp) {
                                    const item = {
                                        proj: value,
                                        isForTime: true,
                                        projectId: resp[0].id,
                                        desc:
                                            'Time bucket item for ' +
                                            monthNames[today.getMonth()],
                                        descOnly:
                                            'Time bucket item for ' +
                                            monthNames[today.getMonth()],
                                        shortdesc:
                                            'Time bucket item for ' +
                                            monthNames[today.getMonth()],
                                        itemName:
                                            'Time bucket item for ' +
                                            monthNames[today.getMonth()],
                                        timebudget: true,
                                        timeedate: todayDate + '-01',
                                        timesdate:
                                            todayDate +
                                            '-' +
                                            new Date(
                                                today.getFullYear(),
                                                today.getMonth(),
                                                0
                                            ).getDate(),
                                    }
                                    this.timeEntryService
                                        .insertItem(item)
                                        .then((resp) => {
                                            items.push(resp)
                                            this.setItems(items)
                                        })
                                }
                            })
                    }
                })
        }

        return this
    }

    setProjects(projects: any): TimeEntryAddEditViewModel {
        this._projects = projects
        return this
    }

    setEmployees(employees: any): TimeEntryAddEditViewModel {
        this._employees = employees
        return this
    }

    setItems(items: any): TimeEntryAddEditViewModel {
        this._items = items
        return this
    }

    setComponents(components: any): TimeEntryAddEditViewModel {
        this._components = components
        return this
    }

    setLocations(locations: any): TimeEntryAddEditViewModel {
        this._locations = locations
        return this
    }

    setSalesCategories(salesCategories: any): TimeEntryAddEditViewModel {
        this._salesCategories = salesCategories
        return this
    }

    setIsLoaded(isLoaded: boolean): TimeEntryAddEditViewModel {
        this._isLoaded = isLoaded
        return this
    }

    setId(id: number): TimeEntryAddEditViewModel {
        this._id = id
        return this
    }

    async componentDidMount() {
        this.fetchTimeEntry()
        // this.fetchActivities()
        this.setIsLoaded(true)
    }

    async fetchFilters() {
        this.timeEntryService
            .getProjects('?$order by projn asc&$filter=closeddt eq null')
            .then((resp) => {
                if (resp) {
                    this.setProjects(resp)
                }
            })

        this.apiService
            .getClientEmployeesSummaries(
                '?$order by vendorn asc&$filter=inactive eq false'
            )
            .then((response) => {
                if (response) {
                    const employees = response.map(
                        (employee: {
                            id: string
                            key: string
                            value: string
                        }) => {
                            const { id, key: vendor, value: vendorn } = employee
                            return {
                                id: Number(id),
                                vendor,
                                vendorn,
                            }
                        }
                    )
                    this.setEmployees(employees)
                }
            })
    }

    async getItemComponents(item: any) {
        return await this.timeEntryService.getComponents(
            `?$filter=item eq '${item}' and timetxnum ne 0`
        )
    }

    async getEmployeeData(employee: any) {
        return await this.timeEntryService.getEmployees(
            `?$filter=vendor eq '${employee}' and inactive eq false`
        )
    }

    async fetchTimeEntry() {
        this.setIsLoaded(false)

        await this.timeEntryService
            .getProjects('?$order by projn asc&$filter=closeddt eq null')
            .then((resp) => {
                if (resp) {
                    this.setProjects(resp)
                }
            })

        await this.timeEntryService
            .getEmployees('?$order by vendorn asc&$filter=inactive eq false')
            .then((resp) => {
                if (resp) {
                    this.setEmployees(resp)
                }
            })

        const activities = await this.apiService.getTimeActivities(
            '?$filter=inactive eq false'
        )
        this.activities = activities.map((activity) => {
            return {
                value: activity.activity,
                label: `${activity.activityn} [${activity.activity}]`,
            }
        })
        /*
				this.timeEntryService.getLocations().then((resp) => {
					if (resp) {
						this.setLocations(resp)
					}
				})
		
				this.timeEntryService.getSalesCategories().then((resp) => {
					if (resp) {
						this.setSalesCategories(resp)
					}
				})
			*/

        if (!this._id) return

        this.timeEntryService
            .getTimeEntries(`?$filter=txnum eq ${this._id}`)
            .then((response) => {
                if (response && response[0]) {
                    this.setTimeEntry(response[0])

                    this.setIsLoaded(true)
                }
            })
            .catch((e) => {
                this.setIsLoaded(true)
                console.log(e)
            })
    }

    async getItemId(date: string, projId: number) {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        const entryDate = new Date(date)
        const entryMonth = monthNames[entryDate.getMonth()]
        const entryYear = entryDate.getFullYear()

        const filter = `?$filter=desc eq 'Time for the month of ${entryMonth} ${entryYear}' and projectId eq ${projId}`
        const item = await this.timeEntryService.getItems(filter)

        if (item.length > 0) {
            return item[0].id
        } else {
            const initialData = {
                projectId: projId,
                isForTime: true,
            }

            const resp = await this.timeEntryService.insertItem(initialData)
            const itemId = resp.id

            const firstDay = new Date(
                entryDate.getFullYear(),
                entryDate.getMonth(),
                1
            )
            const lastDay = new Date(
                entryDate.getFullYear(),
                entryDate.getMonth() + 1,
                0
            )

            const itemData = {
                desc: `Time for the month of ${entryMonth} ${entryYear}`,
                descOnly: `Time for the month of ${entryMonth} ${entryYear}`,
                shortdesc: `Time for the month of ${entryMonth} ${entryYear}`,
                itemName: `Time for the month of ${entryMonth} ${entryYear}`,
                timesdate: firstDay,
                timeedate: lastDay,
            }

            this.timeEntryService.updateItem(itemId, itemData)

            return itemId
        }
    }

    modifyTimeEntry(id?: number, params?: any, callback?: any) {
        if (!id) {
            this.insertTimeEntry(params, callback)
        } else {
            this.updateTimeEntry(id, params, callback)
        }
    }

    async insertTimeEntry(params?: any, callback?: any) {
        if (params.nonbillable) {
            params.rateperhour = 0
            params.rate = 0
        }

        const itemId = await this.getItemId(params.entrydate, params.projectId)

        const newCompParams = {
            itemId: itemId,
            componentType: 'ctTime',
            associatedComponentNumber: '',
            createAutoComponents: false,
            setDefaultsFromItem: false,
        }

        const newComp = await this.timeEntryService.insert(newCompParams)
        const compId = newComp.id

        const updateCompParams = this.getComponentParam(params)

        // delete updateCompParams.projectId
        //add catch for errors
        let compResp = { timetxnum: 0 }
        await this.timeEntryService
            .update(compId, updateCompParams)
            .then((resp: any) => {
                compResp = resp
            })
            .catch((e: any) => {
                callback(e)
            })

        const timeEntryParams = params
        delete timeEntryParams.projectId

        const txnum = compResp.timetxnum
        const timeEntryResp = await this.timeEntryService.updateTimeEntry(
            txnum,
            timeEntryParams
        )

        const recalculateResp =
            await this.timeEntryService.recalculateComponent(compId)

        /* old */
        // this.timeEntryService
        // 	.insert(params)
        // 	.then((response: any) => {
        // 		if (callback) {
        // 			callback(response)
        // 		}
        // 	}).catch((e) => {
        // 		if (callback) {
        // 			callback(e)
        // 		}
        // 	})
    }

    getComponentParam(param: any) {
        return {
            supplier: param.employee || '',
            activity: param.activity || '',
            taxable: param.taxable || false,
            nonbilltime: param.nonbillable || false,
            desc: param.desc || '',
            descrtf: param.descrtf || '',
            quantity: param.hours || 0,
            unitlist: param.rateperhour || 0,
            list: param.rate || 0,
            nonbillunitprice: param.nonbillable ? param.rateperhour : 0,
            estunitcost: param.costperhour || 0,
            estcost: param.cost || 0,
        }
    }

    async updateTimeEntry(id: number, params?: any, callback?: any) {
        // check for date changes before update

        const itemId = await this.getItemId(params.entrydate, params.projectId)
        params.item = itemId

        this.timeEntryService
            .update(id, params)
            .then((response: any) => {
                if (callback) {
                    callback(response)
                }
            })
            .catch((e: any) => {
                if (callback) {
                    callback(e)
                }
            })
    }

    async deleteTimeEntry(params?: any, callback?: any) {
        return this.timeEntryService
            .delete(params)
            .then((res) => Promise.resolve(res))
            .catch((e) => Promise.reject(e))
    }

    async fetchActivities() {
        const activities = await this.apiService.getTimeActivities(
            '?$filter=inactive eq false'
        )
        this.activities = activities.map((activity) => {
            return {
                value: activity.activity,
                label: `${activity.activityn} [${activity.activity}]`,
            }
        })
    }
}
