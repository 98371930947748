import { Component } from 'react'
import { getClassNames } from '../../helpers/DOM'

/**
 * Displays the action bar.
 */
class ActionBar extends Component {
    static Links(props) {
        return (
            <ul className="mb-0 px-0 d-flex align-items-center justify-content-start justify-content-sm-center list-unstyled">
                {props.children}
            </ul>
        )
    }

    render() {
        return (
            <div
                className={`${
                    this.props.isFloat === false ? '' : 'a-action-bar-float'
                }`}
            >
                <div className="a-action-bar position-absolute roundedx-6 bg-charcoal">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

class ActionBarVertical extends ActionBar {
    static Links(props) {
        return (
            <ul
                className={getClassNames(
                    props || {},
                    `mb-0 px-0 d-flex flex-column align-items-center list-unstyled`
                )}
            >
                {props.children}
            </ul>
        )
    }

    render() {
        return (
            <div
                className={getClassNames(
                    this.props || {},
                    `a-action-bar vertical position-absolute roundedx-6 bg-charcoal`
                )}
            >
                {this.props.children}
            </div>
        )
    }
}

export default ActionBar
export { ActionBarVertical }
