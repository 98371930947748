import { ApiService } from '../lib/api/HttpService'

export const getAllInstances = async (clientCompanyId, setAllInstances) => {
    if (!clientCompanyId) {
        return
    }

    try {
        const api = new ApiService()
        const { instances } = await api.getAllCompanyInstances(clientCompanyId)
        const activeInstances = instances?.filter(
            (instance) => instance?.status === 'Active'
        )
        if (instances) {
            setAllInstances(activeInstances)
        } else {
            setAllInstances([])
        }
    } catch (error) {
        console.error('Error fetching instances:', error)
    }
}

export const getClientId = async (setClientCompanyId) => {
    const api = new ApiService()
    const { clientId } = await api.getCurrentUser()
    clientId && setClientCompanyId(clientId)
}
