import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
} from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { Component } from '../types/Component'

const api = new ApiService()

const useEditComponent = (
    options?: UseMutationOptions<
        Component,
        Partial<Component> & { id: number },
        Partial<Component> & { id: number },
        unknown
    >
): UseMutationResult<
    Component,
    Partial<Component> & { id: number },
    Partial<Component> & { id: number },
    unknown
> => {
    return useMutation({
        mutationFn: (editedComponent: Partial<Component> & { id: number }) => {
            const newlyEditedComponent = api.editComponent(
                editedComponent.id,
                editedComponent
            ) as Promise<Component>
            return newlyEditedComponent
        },
        ...(options || {}),
    })
}
export default useEditComponent
