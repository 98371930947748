import { makeAutoObservable } from 'mobx'
import { IAccountResponse, AccountService } from './api/AccountService'

export default class AccountListViewModel {
    private accountService: AccountService
    private _accountList: IAccountResponse[] = []
    private _isLoaded = false

    get accountList(): IAccountResponse[] {
        return this._accountList
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    setAccountList(accounts: any): AccountListViewModel {
        this._accountList = accounts
        return this
    }

    setIsLoaded(isLoaded: boolean): AccountListViewModel {
        this._isLoaded = isLoaded
        return this
    }

    constructor(accountService: AccountService) {
        this.accountService = accountService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        this.fetchAccounts()
    }

    fetchAccounts(): void {
        // Set loading state
        this.setIsLoaded(false)

        this.accountService
            .getGLAccounts()
            .then((response: any) => {
                this.setIsLoaded(true)

                if (response) {
                    this.setAccountList(response.results)
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }
}
