import React from 'react'
import {
    Container,
    Col,
    Row,
    Form,
    Breadcrumb,
    Button,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    formatDate,
    formatDateTime,
    formatFiscalMonth,
} from '../../../../helpers/Date'
import { currencyFormat } from '../../../../helpers/Number'
import { showLoading } from '../../../../helpers/Loading'
import { pathParam } from '../../../../utilities/Router'
import {
    displayAlert,
    displayAlertLoader,
    hideAlertLoader,
} from '../../../../utilities/Response'
import ClientInvoiceModal from '../../modal/ClientInvoiceModal'
import MSG from '../../../../defaults/Message'
import { isArray } from 'lodash'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'

class ClientInvoiceExistingView extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            id: null,
            project: [],
            data: {},
            reportFormats: [],
            dataIsLoaded: false,
            showClientInvoiceModal: false,
            clientInvoiceData: {},
        }

        this.api = new ApiService()
    }

    componentInit() {
        this.title = 'Client Invoice'
        this.setFormAction(true)
    }

    async componentDidMount() {
        const result = await this.api.getInvoices(
            `?$filter=invnum eq ${this.props.params.invnum}`
        )

        const lastNotificationIds = result
            ?.map((i) => i.lastNotificationId)
            ?.filter((val) => val !== null && val !== undefined)

        if (lastNotificationIds?.length) {
            const notifInfo = await this.api.get(
                `notifications`,
                `?$filter=id in (${lastNotificationIds.join()})`
            )

            for (let item of result) {
                let notif = notifInfo.find(
                    (i) => i.id === item.lastNotificationId
                )

                const toInfo = this.getNotifPayload(
                    notif?.notificationPayload,
                    'To'
                )
                item.sentInfo =
                    toInfo && isArray(toInfo) ? toInfo.join('\n') : toInfo ?? ''
                let sentStatus = ''
                const sentInfo = notif?.sentInfo
                    ? `Sent to: ${notif.sentInfo}`
                    : ''
                if (item.notificationSent) {
                    item.sentDateTime = formatDateTime(
                        item.sentDateTimeInUserTimeZone
                    )

                    sentStatus = sentInfo
                }

                if (
                    item.lastNotificationId !== null &&
                    !item.notificationSent
                ) {
                    item.sentInfo = 'Pending...'
                }

                item.sentStatus = sentStatus
            }
        }

        const fetchReportFormats = await this.api.get(
            'reportformats/types/invoice'
        )

        this.setState(
            {
                reportFormats: fetchReportFormats.reportFormats,
                data: result[0],
                id: result[0].id,
                project: [
                    {
                        id: result[0].id,
                        proj: result[0].proj,
                        invnum: result[0].invnum,
                        due: currencyFormat(result[0].amountDue),
                        client: result[0].client,
                        txdesc: result[0].txdesc,
                    },
                ],
            },
            () => {
                this.setState({
                    dataIsLoaded: true,
                })
            }
        )
    }

    getNotifPayload(payload, key) {
        payload = payload?.replace(/[\n\r\t]/g, '')
        if (payload?.length) {
            try {
                payload = JSON.parse(payload)
                return payload[key]
            } catch (error) {
                // Ignore
                console.error(error)
            }
        }

        return ''
    }

    getDocName = (styleId) => {
        const getDocument = this.state.reportFormats.filter((row) => {
            return row.id === styleId
        })

        if (getDocument.length) {
            return getDocument[0].name
        }

        return ''
    }

    async handleDocument(e, id) {
        e.preventDefault()

        try {
            let document = await this.api.postJson('publicmappingbatches', {
                publicMappingObjects: [
                    {
                        objectId: id,
                        objectType: 'Invoice',
                    },
                ],
            })

            window.open(document.link, '_blank').focus()
        } catch (error) {
            displayAlert('danger', 'No document for this invoice.')
        }
    }

    async handleEmailSend() {
        try {
            displayAlertLoader(MSG.loading.info)

            const mappingBatches = [
                {
                    objectId: this.state.id,
                    objectType: 'Invoice',
                },
            ]

            const postData = await this.api.postJson('publicmappingbatches', {
                publicMappingObjects: mappingBatches,
            })

            this.setState((prevState) => ({
                showClientInvoiceModal: true,
                clientInvoiceData: {
                    link: postData.link,
                    token: postData.token,
                },
            }))
            hideAlertLoader()
        } catch (error) {
            console.log(error)
            displayAlert('danger', 'No document for this invoice.')
        }
    }

    header() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={routeParam(
                            URI.accountsReceivable.clientInvoices.list,
                            { page: 'existing' }
                        )}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(
                                    URI.accountsReceivable.clientInvoices.list,
                                    { page: 'existing' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Account Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(
                                    URI.accountsReceivable.clientInvoices.list,
                                    { page: 'existing' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Client Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Invoice {this.props.params.invnum}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Invoice {this.props.params.invnum}
                    </HeaderLight.Title>

                    <div className={'d-flex'}>
                        {/* Submit Button */}
                        <Button
                            variant="white btn-action me-2 btn-icon px-4 fw-bold fs-md"
                            onClick={(e) => {
                                this.handleDocument(e, this.state.id)
                            }}
                        >
                            <i className="ri-file-chart-fill"></i> View PDF
                        </Button>
                        <SecureBootstrapButton
                            attributeNo={52}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec4}
                            as={Link}
                            to={pathParam(
                                URI.accountsReceivable.clientInvoices
                                    .existingHighlightedInvoice,
                                { id: this.props.params.invnum }
                            )}
                            variant="white btn-action me-2 btn-icon fw-bold fs-md px-4"
                            name="save"
                        >
                            <i className="ri-edit-line"></i> Modify
                        </SecureBootstrapButton>
                        <SecureBootstrapButton
                            variant="primary me-2 btn-icon  px-4 fw-bold fs-md"
                            as={Link}
                            attributeNo={52}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec3}
                            to={pathParam(
                                URI.accountsReceivable.clientInvoices
                                    .existingreverseCreditInvoice,
                                {
                                    invnum: this.props.params.invnum,
                                }
                            )}
                        >
                            <i className="ri-folder-received-line"></i> Credit
                        </SecureBootstrapButton>
                        <Button
                            variant="primary me-2 btn-icon  px-4 fw-bold fs-md"
                            onClick={(e) => {
                                e.preventDefault()
                                this.handleEmailSend()
                            }}
                        >
                            <i className="ri-mail-send-line"></i> Send
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { data } = this.state

        return (
            <>
                {!this.state.dataIsLoaded ? (
                    showLoading()
                ) : (
                    <Container className="px-0 ms-0">
                        <Form.Group>
                            <Row
                                xs={1}
                                lg={2}
                                className="mt-4 align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Prop No.
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>{data.propnum}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Reverse
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {' '}
                                                {data.hasreversal
                                                    ? 'Yes'
                                                    : 'No'}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                xs={1}
                                lg={2}
                                className="align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Date
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {formatDate(data.invdt)}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Reverse of Inv. No.
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {data.reverseofinvnum}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                xs={1}
                                lg={2}
                                className="align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Project / Order
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {data.projn} / {data.proj}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Transaction Description
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>{data.txdesc}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                xs={1}
                                lg={2}
                                className="align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Client Name
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {data.clientName} /{' '}
                                                {data.client}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Style
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {this.getDocName(data.style)}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                xs={1}
                                lg={2}
                                className="align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Sent Status
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <div className="d-flex">
                                                <strong
                                                    className={
                                                        data.notificationSent
                                                            ? `dot-primary`
                                                            : `dot-primary-red`
                                                    }
                                                ></strong>
                                                <div>
                                                    {data.sentDateTime}
                                                    {data.sentStatus ? (
                                                        <React.Fragment>
                                                            <br />
                                                            {data.sentStatus}
                                                        </React.Fragment>
                                                    ) : (
                                                        <>
                                                            <br />
                                                            {data.sentInfo}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Keyed by User
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>{data.username}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row
                                xs={1}
                                lg={2}
                                className="align-items-center static-field-rows"
                            >
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Fiscal Month
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {formatFiscalMonth(
                                                    data.fiscalmonth
                                                )}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-center mb-0 mb-lg-4">
                                        <Col
                                            xs={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Keyed Date
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <strong>
                                                {formatDateTime(
                                                    data.generateddate
                                                )}
                                            </strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Group>

                        <div className="bg-secondary-grey px-4 mt-3">
                            <Row
                                xs={1}
                                lg={2}
                                className="py-4 align-items-center"
                            >
                                <Col>
                                    <h6 className="fw-bold mb-0">
                                        Invoice Balance
                                    </h6>
                                </Col>
                            </Row>
                        </div>

                        <div className="table-gradient mt-3">
                            <Table responsive borderless className="">
                                <thead>
                                    <tr key="0">
                                        <th className="fs-md w-100px fw-normal">
                                            Net Invoice
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md w-100px fw-normal">
                                            Total Sale
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md w-100px fw-normal">
                                            Sales Tax
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md fw-normal">
                                            Deposit Applied
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={0}>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(
                                                    data.invoicePrice
                                                )}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <span className="fsx-24">=</span>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(
                                                    data.totalPrice
                                                )}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <i className="ri-add-line ri-lg"></i>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(data.totalTax)}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <i className="ri-subtract-line ri-lg"></i>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(
                                                    data.totalDepositApplied
                                                )}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="table-gradient mt-2">
                            <Table responsive borderless className="">
                                <thead>
                                    <tr key="0">
                                        <th className="fs-md w-100px fw-normal">
                                            Balance Due
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md w-100px fw-normal">
                                            Net Invoice
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md w-100px fw-normal">
                                            Sales Tax
                                        </th>
                                        <th className="fs-md w-40px"></th>
                                        <th className="fs-md fw-normal">
                                            Adjustments
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={0}>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(data.amountDue)}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <span className="fsx-24">=</span>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(
                                                    data.invoicePrice
                                                )}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <i className="ri-add-line ri-lg"></i>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(data.totalTax)}
                                            </strong>
                                        </td>
                                        <td className="align-middle text-secondary-ash ps-5 py-0">
                                            <i className="ri-subtract-line ri-lg"></i>
                                        </td>
                                        <td className="align-middle py-0">
                                            <strong>
                                                {currencyFormat(
                                                    data.adjustmentsAmount
                                                )}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Container>
                )}
                {this.state.showClientInvoiceModal && (
                    <ClientInvoiceModal
                        selectedProjects={this.state.project}
                        clientInvoiceData={this.state.clientInvoiceData}
                        show={this.state.showClientInvoiceModal}
                        hideModal={(isReload) =>
                            this.setState({
                                showClientInvoiceModal: false,
                                clientInvoiceData: {},
                            })
                        }
                        clientName={this.state.clientName}
                    />
                )}
            </>
        )
    }

    render() {
        return (
            <>
                {this.header()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceExistingView)
