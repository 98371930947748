import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap'
import React from 'react'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import Select from 'react-select'
import ProjectAddMarkup from './ProjectAddMarkup'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../utilities/Rtf'
import { ApiService } from '../../../lib/api/HttpService'
import { TIME_BILLING_TIERS } from './constants'

class ProjectAddSettings extends ThreadComponent {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data || {},
            isMarkupShown: false,
            notesHtml: '',
        }
        this.updatedData = {
            ...props.updatedData,
            salesTaxCodes: this.props.data.taxCodes,
        }
        this.timeBillingTiers = TIME_BILLING_TIERS

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
        this.api = new ApiService()
    }

    async componentDidMount() {
        const company1 = await this.api.get('company', '')
        const company2 = await this.api.get('company2', '')
        const company = { ...company1, ...company2 }
        this.setState({
            notesHtml: await mapRtfToDraft(
                this.updatedData?.notesrtf ?? this.state.data?.notesrtf ?? ''
            ),
            company,
        })

        this.updatedData.timeloccode =
            this.updatedData.timeloccode ?? company?.timeloccode
        this.updatedData.timescatcode =
            this.updatedData.timescatcode ?? company?.timescatcode
    }

    defaultTimeBillingTier() {
        const tiers = this.timeBillingTiers
        const { data } = this.state

        if (!(tiers && tiers.length > 0)) {
            return { value: '', label: 'Please select' }
        }

        let existingTier
        const selectedTier = tiers.filter((tier) => {
            if (tier.value === this.updatedData.timebillingtier) {
                return true
            }

            if (data?.timebillingtier === tier.value) {
                existingTier = tier
            }
        })[0]

        return (
            selectedTier ??
            existingTier ?? { value: '', label: 'Please select' }
        )
    }

    handleMarkupContinue = (markup) => {
        this.updatedData = { ...this.updatedData, ...markup }
        this.setState({ isMarkupShown: false })

        Object.keys(markup).forEach((key) => {
            this.props.onDataChange({ key: key, value: markup[key] })
        })
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        this.props.onDataChange({ key: key, value: value })

        if (key === 'notes') {
            const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(
                e.target.html
            )
            this.props.onDataChange({ key: `${key}rtf`, value: valueHtml })
        }

        if ('clientinvterms' === key) {
            this.props.onDataChange({ key: 'selectedClientInvoice', value })
        }
    }

    renderDefaults() {
        const { data } = this.state
        return (
            <Row xs={1} lg={2}>
                <Col className="mb-3 mb-lg-0">
                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0 mt-2 pt-1">
                                PO Sidemark
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                maxLength={60}
                                placeholder="PO Sidemark"
                                id="posidemark"
                                onChange={this.handleChange}
                                defaultValue={
                                    this.updatedData?.posidemark ??
                                    data?.posidemark ??
                                    this.state.company?.posidemark
                                }
                            />
                            <Form.Check
                                inline
                                label="Append Loc. to Sidemark"
                                type="checkbox"
                                className="mt-3"
                                id="locinsidemark"
                                onClick={this.handleChange}
                                defaultChecked={
                                    this.updatedData?.locinsidemark ??
                                    data?.locinsidemark ??
                                    this.state.company?.locinsidemark
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0 mt-2 pt-1">
                                PO Designer/Ext.
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                maxLength={60}
                                placeholder="PO Designer/Ext."
                                id="designer"
                                onChange={this.handleChange}
                                defaultValue={
                                    this.updatedData?.designer ??
                                    data?.designer ??
                                    ''
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0 mt-2 pt-1">
                                Client Deposit
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="number"
                                className="no-spinner"
                                placeholder="Client Deposit"
                                id="clientdep"
                                onChange={this.handleChange}
                                defaultValue={
                                    this.updatedData?.clientdep ??
                                    data?.clientdep ??
                                    this.state.company?.clientdep
                                }
                            />
                            <Form.Check
                                inline
                                label="Include Sales Tax in Deposit"
                                type="checkbox"
                                className="mt-3"
                                id="cldpsalestax"
                                onClick={this.handleChange}
                                defaultChecked={
                                    this.updatedData?.cldpsalestax ??
                                    data?.cldpsalestax ??
                                    this.state.company?.cldpsalestax
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0 mt-2 pt-1">
                                Client Invoice Terms
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                maxLength={30}
                                placeholder="Client Invoice Terms"
                                id="clientinvterms"
                                onChange={this.handleChange}
                                defaultValue={
                                    this.updatedData?.selectedClientInvoice ??
                                    this.updatedData?.clientinvterms ??
                                    data?.clientinvterms ??
                                    this.state.company?.clientinvterms
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0 mt-2 pt-1">
                                Time Billing Tier
                            </Form.Label>
                        </Col>
                        <Col>
                            <Select
                                key={`${Math.floor(Math.random() * 1000)}-min`}
                                options={this.timeBillingTiers}
                                className="react-select"
                                placeholder=""
                                name="timebillingtier"
                                onChange={this.handleChange}
                                defaultValue={this.defaultTimeBillingTier()}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-start mb-3">
                        <Col
                            lg={3}
                            className="d-none d-lg-flex text-lg-end mb-2 mb-lg-0"
                        ></Col>
                        <Col>
                            <Button
                                variant="primary"
                                className=" w-100"
                                onClick={() => {
                                    this.setState({ isMarkupShown: true })
                                }}
                            >
                                Advanced / Mark-up
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    renderNotes() {
        const { data } = this.state

        return (
            <Row>
                <Col lg="7">
                    <WysiwygEditor id="notes" onChange={this.handleChange}>
                        {this.state.notesHtml}
                    </WysiwygEditor>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div className="mb-4" data-testid="project-add-settings-form">
                <Accordion alwaysOpen defaultActiveKey={['0', '1', '2']}>
                    <Accordion.Item className="mb-2" eventKey="0">
                        <Accordion.Header className="roundedx-4">
                            Defaults
                        </Accordion.Header>
                        <Accordion.Body>
                            <Container className="ms-0">
                                {this.renderDefaults()}
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="mb-2" eventKey="1">
                        <Accordion.Header>Notes</Accordion.Header>
                        <Accordion.Body>
                            <Container className="ms-0">
                                {this.renderNotes()}
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <ProjectAddMarkup
                    markupData={this.updatedData}
                    company={this.state.company}
                    isShowing={this.state.isMarkupShown}
                    onContinue={this.handleMarkupContinue}
                    onCancel={() => {
                        this.setState({ isMarkupShown: false })
                    }}
                />
            </div>
        )
    }
}

export default WithRouter(ProjectAddSettings)
