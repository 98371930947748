import {
    Col,
    Container,
    Row,
    Table,
    Form,
    Button,
    Modal,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import { ApiService } from '../../../../lib/api/HttpService'
import { Pagination } from '../../../../app/components/pagination/Pagination'
import debounce from 'lodash/debounce'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import {
    formatDate,
    formatFiscalMonth,
    formatFilterDate,
    formatFilterMonth,
} from '../../../../helpers/Date'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { _delay, isFiltered } from '../../../../helpers/Util'
import { DatePicker, DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import {
    displayAlert,
    displayAlertError,
    getErrorMessage,
    handleResponseAlert,
} from '../../../../utilities/Response'
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting'
import { currencyFormat } from '../../../../helpers/Number'
import FilterSort from '../../../../utilities/modules/FilterSort'
import CreateDepositSlipListExistingModal from '../../modal/CreateDepositSlipListExistingModal'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import dayjs from 'dayjs'
import { getCookie } from '../../../../utilities/Auth'
import URI from '../../../../defaults/RoutesDefault'
import { pathParam } from '../../../../utilities/Router'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureButton from '../../../../app/components/security/SecureButton'
import SecureLink from '../../../../app/components/security/SecureLink'

class CashReceiptListExisting extends ThreadComponent {
    constructor(props) {
        super(props)

        const savedState = getLocalStorage('ar_cashreceipts_existing_list')
        this.fs = new FilterSort('ar_cashreceipts_existing_list')
        this.fs.setDefaultSort('crgroupnum desc')
        this.state = {
            ...this.state, // spread the existing state
            ...savedState, // spread the saved state
            checks: {},
            totalChecked: 0,
            checks1: {},
            totalChecked1: 0,
            showRetainerModal: false,
            activeMenu: 'new',
            receipts: [],
            selectedReceiptRefNo: '',
            selectedReceiptTransactions: [],
            selectedAccount: '',
            transactionTypes: {
                2006: 'Pmt.',
                2004: 'Dep.',
                2002: 'Ret.',
                2000: 'Misc.',
            },
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'crgroupnum desc',
            showModal: false,
            dataIsLoaded: false,
            isTransactionLoaded: false,
            showEditCashReceiptModal: false,
            selectedCRIdx: null,
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.api = new ApiService()
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )

        this.selectedCashReceipt = null
        this.selectedTransItem = null
    }

    componentInit() {
        this.setTitle('Cash Receipts - Existing')
        setLocalStorage('ar_cashreceipts_existing_list', this.state)
    }

    async componentDidMount() {
        const accounts = await this.api.getGLAccounts(`?$filter=specacct eq 1`)
        this.enableSortTable()

        if (accounts.length > 0) {
            this.setState(
                {
                    accounts: accounts,
                    selectedAccount: accounts[0].account,
                },
                () => {
                    this.fetchItems()
                }
            )
        } else {
            this.fetchItems()
        }
    }

    async fetchItems(page, isLoader) {
        if (isLoader) this.setState({ dataIsLoaded: false })
        const receipts = await this.api.getCashReceipts(
            `?${this.buildFilters(page)}`
        )

        this.setState({
            receipts: receipts,
            dataIsLoaded: true,
        })
    }

    extractCashAccountsSelect = () => {
        const cashAccounts = []
        const uniqueAccounts = new Set()

        this.state.receipts.forEach((receipt) => {
            const accountKey = `${receipt.faccount}-${receipt.faccountn}`
            if (!uniqueAccounts.has(accountKey)) {
                uniqueAccounts.add(accountKey)
                cashAccounts.push({
                    faccount: receipt.faccount,
                    faccountn: receipt.faccountn,
                })
            }
        })

        return cashAccounts
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked
            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.receipts.length) {
                    this.state.receipts.map((item, i) => {
                        prevState.checks[i] = true
                        const checkElement = document.getElementById(
                            'chk-projectview-items-' + i
                        )
                        if (checkElement) {
                            checkElement.checked = true
                        }
                        return null
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    handleChecks1 = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked1 = 0
            prevState.checks1[indx] = e.target.checked

            if (prevState.checks1) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(
                    prevState.checks1
                )) {
                    if (value) {
                        totalChecked1++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked1 = totalChecked1

            if (totalChecked1 > 0) {
                document.getElementById('inline-check-th-1').checked = true
            } else {
                document.getElementById('inline-check-th-1').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks1 = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (
                    this.state.totalChecked1 <
                    this.state.selectedReceiptTransactions.length
                ) {
                    this.state.selectedReceiptTransactions.map((item, i) => {
                        prevState.checks1[i] = true
                        const checkElement = document.getElementById(
                            'chk-cashreceipt-items-' + i
                        )
                        if (checkElement) {
                            checkElement.checked = true
                        }

                        prevState.totalChecked1 += 1

                        return null
                    })
                }
            } else {
                prevState.checks1 = {}
                prevState.totalChecked1 = 0

                const checks = document.querySelectorAll(
                    '.chk-cashreceipt-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    async handleSelectReceipt(item) {
        this.selectedCashReceipt = item
        const filter = `?$filter=crgroupnum eq ${item.crgroupnum}`
        const transactions = await this.api.getCashReceiptDetail(filter)

        this.setState(
            {
                selectedReceiptRefNo: item.crgroupnum,
                selectedReceiptTransactions: transactions,
                isTransactionLoaded: true,
            },
            () => {
                tableSortingEnableSort({
                    classRef: this,
                    data: transactions,
                    stateKey: 'selectedReceiptTransactions',
                    targetTable: '.a-table-heading.distribution .sort',
                    pattern: (key) => {
                        return [
                            (d) => {
                                switch (key) {
                                    case '_type':
                                        return this.state.transactionTypes[
                                            d.type
                                        ]

                                    default:
                                        return d[key] ?? ''
                                }
                            },
                        ]
                    },
                })
            }
        )
    }

    handleVoid = async (e) => {
        let hasError = false
        const receiptTransactions = this.state.selectedReceiptTransactions
        for (const [index, value] of Object.entries(this.state.checks1)) {
            if (value) {
                await this.api
                    .postTemporaryCashReceipt({
                        TransactionNumber: receiptTransactions[index].txnum,
                        PostingProcessType: 'voidTransaction',
                    })
                    .catch((error) => {
                        hasError = true
                        displayAlert('danger', error.response.data.userError)
                    })
            }
        }

        if (!hasError) {
            this.setState({
                totalChecked1: 0,
                checks1: {},
                dataIsLoaded: false,
            })

            // Refetch transactions for selected ref no.
            this.handleSelectReceipt(this.selectedCashReceipt)

            displayAlert(
                'success',
                `Successfully void a cash receipt ${
                    this.state.total > 1 ? 'entries' : 'entry'
                }`
            )

            this.fetchItems(this.state.page)
        }
    }

    enableSortTable() {
        const sort = document.querySelectorAll(
            '.a-table-heading.receipts .sort'
        )
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {}

    buildFilters(currentPage) {
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.max) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else if (property.type === 'month') {
                    if (property.value)
                        filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        const filtersQuery =
            filters.length > 0 ? `&$filter=${filters.join(' and ')}` : ''
        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page, true)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleSearchMonthChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                value: formatFilterMonth(e),
                type: 'month',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleClickTransItem = async (item, idx) => {
        const response = await this.api.getCreditCardTransactions(
            '?$filter=txnum eq ' + item.txnum
        )

        if (response.length > 0) {
            displayAlertError(response?.message)
            return
        }

        this.selectedTransItem = item
        this.setState({
            showEditCashReceiptModal: !this.state.showEditCashReceiptModal,
            selectedCRIdx: idx,
        })
    }

    hideEditDistributionModal = () => {
        this.setState({
            showEditCashReceiptModal: false,
            selectedCRIdx: null,
        })
    }

    handleUpdateData = (data) => {
        const selectedTr = this.state.selectedReceiptTransactions

        if (Object.values(data).length) {
            selectedTr[this.state.selectedCRIdx] = {
                ...selectedTr[this.state.selectedCRIdx],
                propnum: data.propnum,
                totalAmount: data.amount,
                txdesc: data.txdesc,
                payingtxnum: data.invNum,
                paytype: data.paytype,
            }

            if (this.state.selectedReceiptTransactions.length) {
                this.selectedCashReceipt = {
                    ...this.selectedCashReceipt,
                    fdepositslip: data.depositslip,
                    paytype: data.paytype,
                    fuserdate: data.userdate,
                    ffiscalmonth: data.fiscalmonth,
                    fchecknum: data.checknum,
                }
            }

            this.setState({
                selectedReceiptTransactions: selectedTr,
            })
        }

        this.setState(
            {
                selectedCRIdx: null,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    allSelectedHaveSameCashAccount = () => {
        const selectedItems = this.state.receipts.filter(
            (_, i) => this.state.checks[i]
        )

        if (selectedItems.length === 0) {
            return false
        }

        const cashAccounts = selectedItems.map((item) => item.faccount)
        return new Set(cashAccounts).size === 1
    }

    handlePostDepositSlip = async (data) => {
        try {
            await this.api.postCashReceiptsUndepositedPayment(data)

            const range = dayjs(data.depositDate).format('YYYY-MM-DD')

            const params = {
                instanceId: getCookie('dmInstanceId'),
                userId: Number(getCookie('dmUsercode')),
                reportId: 1055,
                reportFormatId: 1137,
                reportObjectType: 'Report',
                runReportParameters: [
                    {
                        parameterId: 297,
                        value: `{"cash_account": "${this.state.selectedAccount}"}`,
                    },
                    {
                        parameterId: 298,
                        value: `{"text": "${data.slipNumber}"}`,
                    },
                    {
                        parameterId: 299,
                        value: `{"range1": "${range}", "range2":"${range}"}`,
                    },
                    {
                        parameterId: 300,
                        value: '{"text": ""}',
                    },
                ],

                objectId: null,
            }

            this.setState({
                showModal: false,
            })

            await this.handleRunReports(params, () => {
                displayAlert('success', 'Successfully created deposit slip')
                _delay(() => {
                    window.onbeforeunload = null
                    window.location.reload(false)
                }, 1500)
            })
        } catch (e) {
            const errorMessage =
                e.response?.data?.userError ||
                'Deposit slip could not be created'
            displayAlert('danger', errorMessage)
        }
    }

    async handleRunReports(data, callback) {
        try {
            const saveRequest = this.api.postJson('runreports', data)

            const windowReport = window.open('', '_blank')
            windowReport.document.write(this.loadingWindowHTML())

            handleResponseAlert(saveRequest, (res) => {
                displayAlert(
                    'success',
                    'Please wait for your report to be generated.'
                )
                const reportId = res.id

                const maxRequest = 5
                let requestCount = 0
                let interval = window.setInterval(async () => {
                    try {
                        const response = await this.api.get(
                            'runreports',
                            `?$filter=id eq ${reportId}`
                        )
                        const fileId = response[0].completedFileId
                        if (fileId) {
                            clearInterval(interval)
                            await this.api.getFile(fileId).then((blob) => {
                                windowReport.location.href =
                                    window.URL.createObjectURL(blob)
                                callback()
                            })
                        } else if (requestCount === maxRequest) {
                            clearInterval(interval)
                            displayAlertError(
                                'Report is on pending status. Try again later.'
                            )
                        }
                    } catch (error) {
                        displayAlertError(getErrorMessage(error))
                        windowReport.close()
                    }

                    requestCount++
                }, 5000)
            })
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
    }

    loadingWindowHTML() {
        return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <div>
                <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" class="d-inline-block align-top" alt="React Bootstrap logo">
                <br>
                <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" class="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
                </div>
            </div>
            </div>
        </div>`
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderFilters() {
        return (
            <ListFilter>
                <ListFilter.Actions
                    md={12}
                    lg={12}
                    xl={12}
                    replaceclassmatch="justify-content-xl-end"
                    replaceclassmatchwith="justify-content-md-end"
                >
                    <ListFilter.Action>
                        <Button
                            as={Link}
                            to="#"
                            variant="ivory"
                            size="sm"
                            className={`btn-icon btn-action ${
                                this.state.showTableSearch
                                    ? 'bg-primary-ash text-white'
                                    : ''
                            }`}
                            onClick={this.handleShowTableSearch}
                        >
                            <i className="ri-search-line"></i> Search
                        </Button>
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        )
    }

    render() {
        return (
            <>
                <div className="d-block sticky-container">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* Filter */}
                                {this.renderFilters()}
                                <div className="d-block mt-3 mt-md-4"></div>
                                <div className="table-gradient">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                    >
                                        <thead>
                                            <tr className="a-table-heading receipts">
                                                <th
                                                    align="middle"
                                                    className="mw-80px"
                                                >
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            inline
                                                            label=""
                                                            name={`inline-check-th-0`}
                                                            type="checkbox"
                                                            data-id={`th-0`}
                                                            id={`inline-check-th-0`}
                                                            defaultChecked={
                                                                this.state
                                                                    .totalChecked >
                                                                0
                                                            }
                                                            className={`${
                                                                this.state
                                                                    .totalChecked >
                                                                    0 &&
                                                                this.state
                                                                    .totalChecked <
                                                                    this.state
                                                                        .receipts
                                                                        .length
                                                                    ? 'line'
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                this
                                                                    .handleSelectAllChecks
                                                            }
                                                        />
                                                    </div>
                                                </th>
                                                <th className="mw-90px">
                                                    <span
                                                        className={this.sortClass(
                                                            'crgroupnum'
                                                        )}
                                                        data-field="crgroupnum"
                                                    >
                                                        Ref No.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fuserdate'
                                                        )}
                                                        data-field="fuserdate"
                                                    >
                                                        Date
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'ffiscalmonth'
                                                        )}
                                                        data-field="ffiscalmonth"
                                                    >
                                                        Fiscal Month
                                                    </span>
                                                </th>
                                                <th className="w-140px mw-140px">
                                                    <span
                                                        className={this.sortClass(
                                                            'fchecknum'
                                                        )}
                                                        data-field="fchecknum"
                                                    >
                                                        Check No. (Payment
                                                        Status)
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'amount'
                                                        )}
                                                        data-field="amount"
                                                    >
                                                        Amount
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fclient'
                                                        )}
                                                        data-field="fclient"
                                                    >
                                                        Client Code
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fclientn'
                                                        )}
                                                        data-field="fclientn"
                                                    >
                                                        Client Name
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fpaytype'
                                                        )}
                                                        data-field="fpaytype"
                                                    >
                                                        Payment Type
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fdepositslip'
                                                        )}
                                                        data-field="fdepositslip"
                                                    >
                                                        Deposit Slip
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'faccount'
                                                        )}
                                                        data-field="faccount"
                                                    >
                                                        Cash Account No.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'faccountn'
                                                        )}
                                                        data-field="faccountn"
                                                    >
                                                        Cash Account Name
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fcomputerdate'
                                                        )}
                                                        data-field="fcomputerdate"
                                                    >
                                                        Keyed Date
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fusername'
                                                        )}
                                                        data-field="fusername"
                                                    >
                                                        Keyed By User
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fproj'
                                                        )}
                                                        data-field="fproj"
                                                    >
                                                        Maximum Project Code
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr
                                                className={`a-table-search-fields ${
                                                    this.state.showTableSearch
                                                        ? ''
                                                        : 'd-none'
                                                }`}
                                            >
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="crgroupnum"
                                                        data-type="number"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'crgroupnum'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <DateRangePicker
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                        placement="auto"
                                                        placeholder="Select date"
                                                        format="MM/dd/yyyy"
                                                        defaultValue={this.fs.getValue(
                                                            'fuserdate'
                                                        )}
                                                        onChange={this.handleDateChange.bind(
                                                            this,
                                                            'fuserdate'
                                                        )}
                                                        onClean={this.handleDateChange.bind(
                                                            this,
                                                            'fuserdate'
                                                        )}
                                                        ranges={[
                                                            {
                                                                label: 'today',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date()
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'yesterday',
                                                                value: [
                                                                    startOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'last7Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            6
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last 30 Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            30
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'This month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ),
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date()
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ) -
                                                                                1,
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ) -
                                                                                    1,
                                                                                1
                                                                            )
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                </th>
                                                <th>
                                                    <DatePicker
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                        placeholder="Select month"
                                                        format="MM/yyyy"
                                                        defaultValue={this.fs.getValue(
                                                            'ffiscalmonth'
                                                        )}
                                                        onChange={this.handleSearchMonthChange.bind(
                                                            this,
                                                            'ffiscalmonth'
                                                        )}
                                                        onClean={this.handleSearchMonthChange.bind(
                                                            this,
                                                            'ffiscalmonth'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fchecknum"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fchecknum'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="amount"
                                                        data-type="number"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'amount'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fclient"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fclient'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fclientn"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fclientn'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fpaytype"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fpaytype'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fdepositslip"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fdepositslip'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="faccount"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'faccount'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="faccountn"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'faccountn'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <DateRangePicker
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                        placement="auto"
                                                        placeholder="Select date"
                                                        format="MM/dd/yyyy"
                                                        defaultValue={this.fs.getValue(
                                                            'fcomputerdate'
                                                        )}
                                                        onChange={this.handleDateChange.bind(
                                                            this,
                                                            'fcomputerdate'
                                                        )}
                                                        onClean={this.handleDateChange.bind(
                                                            this,
                                                            'fcomputerdate'
                                                        )}
                                                        ranges={[
                                                            {
                                                                label: 'today',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date()
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'yesterday',
                                                                value: [
                                                                    startOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'last7Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            6
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last 30 Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            30
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'This month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ),
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date()
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ) -
                                                                                1,
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ) -
                                                                                    1,
                                                                                1
                                                                            )
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fusername"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fusername'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fproj"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fproj'
                                                        )}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.state.dataIsLoaded
                                                ? showLoading()
                                                : this.state.receipts.length ===
                                                      0 &&
                                                  isFiltered(
                                                      this.state
                                                          .searchProperties
                                                  )
                                                ? showEmpty()
                                                : this.state.receipts
                                                      .slice(
                                                          0,
                                                          this.state.pageSize
                                                      )
                                                      .map((item, i) => (
                                                          <tr
                                                              key={i}
                                                              data-key={i}
                                                              className={
                                                                  this.state
                                                                      .selectedReceiptRefNo ===
                                                                  item.crgroupnum
                                                                      ? `active`
                                                                      : ''
                                                              }
                                                          >
                                                              <td>
                                                                  <div className="d-flex justify-content-center">
                                                                      <Form.Check
                                                                          label=""
                                                                          name={
                                                                              `group-` +
                                                                              i
                                                                          }
                                                                          type="checkbox"
                                                                          data-id={
                                                                              i
                                                                          }
                                                                          id={
                                                                              `chk-projectview-items-` +
                                                                              i
                                                                          }
                                                                          className="chk-projectview-items-item"
                                                                          defaultValue={
                                                                              i
                                                                          }
                                                                          onClick={
                                                                              this
                                                                                  .handleChecks
                                                                          }
                                                                      />
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  {' '}
                                                                  <SecureLink
                                                                      attributeNo={
                                                                          54
                                                                      }
                                                                      attributeType={
                                                                          SECURITY_ATTRIBUTE_TYPES.DenySpec1
                                                                      }
                                                                      to={pathParam(
                                                                          URI
                                                                              .accountsReceivable
                                                                              .cashReceipt
                                                                              .existingReceiptView,
                                                                          {
                                                                              refno: item.crgroupnum,
                                                                          }
                                                                      )}
                                                                      className="text-charcoal hover-view-icon"
                                                                  >
                                                                      {
                                                                          item.crgroupnum
                                                                      }
                                                                  </SecureLink>
                                                              </td>
                                                              <td>
                                                                  {formatDate(
                                                                      item.fuserdate
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {formatFiscalMonth(
                                                                      item.ffiscalmonth
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fchecknum
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {item.totalAmount
                                                                      ? item.totalAmount.toFixed(
                                                                            2
                                                                        )
                                                                      : ''}
                                                              </td>
                                                              <td>
                                                                  {item.fclient}
                                                              </td>
                                                              <td>
                                                                  {2000 ===
                                                                  item.ftype
                                                                      ? item.forgname
                                                                      : item.fclientn}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fpaytype
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fdepositslip
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.faccount
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.faccountn
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {formatDate(
                                                                      item.fcomputerdate
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fusername
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {item.fproj}
                                                              </td>
                                                          </tr>
                                                      ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {this.state.receipts.length > 0 && (
                                    <Pagination
                                        onPageSizeChanged={
                                            this.onPageSizeChanged
                                        }
                                        onPageChanged={this.onPageChanged}
                                        hasPreviousPage={this.state.page > 1}
                                        hasNextPage={
                                            this.state.receipts.length >
                                            this.state.pageSize
                                        }
                                        page={this.state.page}
                                        pageSize={this.state.pageSize}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>

                    <Modal
                        size="xl"
                        show={this.state.selectedReceiptTransactions.length > 0}
                        aria-labelledby="action-modal"
                        className="a-modal mx-sm-5 mx-lg-0"
                        backdrop="static"
                        centered
                    >
                        <Modal.Body>
                            <div className="bg-secondary-grey">
                                <div className="d-flex p-4 flex-row justify-content-between align-items-center">
                                    <h6 className="fw-bold mb-0">
                                        Payment Distribution
                                    </h6>
                                    <div className="d-flex flex-row">
                                        {/* <Button as={Link} to="#" variant='ivory' size='sm' className='btn-icon btn-action ms-3'>
                                            <i className="ri-edit-line"></i> Edit
                                        </Button> */}
                                        <SecureButton
                                            attributeNo={54}
                                            attributeType={
                                                SECURITY_ATTRIBUTE_TYPES.DenySpec2
                                            }
                                            to="#"
                                            variant="ivory"
                                            size="sm"
                                            className="btn-icon btn-action ms-3"
                                            onClick={this.handleVoid}
                                            disabled={
                                                this.state.totalChecked1 === 0
                                            }
                                        >
                                            <i className="ri-delete-bin-line"></i>{' '}
                                            Void
                                        </SecureButton>
                                    </div>
                                </div>
                            </div>

                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr className="a-table-heading distribution">
                                            <th>
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`inline-check-th-1`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-check-th-1`}
                                                    checked={
                                                        this.state
                                                            .totalChecked1 > 0
                                                    }
                                                    className={`${
                                                        this.state
                                                            .totalChecked1 > 0
                                                            ? 'line'
                                                            : ''
                                                    }`}
                                                    onClick={
                                                        this
                                                            .handleSelectAllChecks1
                                                    }
                                                />
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="txnum"
                                                >
                                                    Tx Ref No.
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="_type"
                                                >
                                                    Type
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="amount"
                                                >
                                                    Amount
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="proj"
                                                >
                                                    Project/Order Code
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="projn"
                                                >
                                                    Project/Order Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="propnum"
                                                >
                                                    Prop. No.
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="payingtxnum"
                                                >
                                                    Invoice No.
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="txdesc"
                                                >
                                                    Transaction Description
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="singleoffsetaccount"
                                                >
                                                    Offset Account No.
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="accountn"
                                                >
                                                    Offset Account Name
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.state.isTransactionLoaded
                                            ? showLoading()
                                            : this.state.selectedReceiptTransactions.map(
                                                  (item, i) => (
                                                      <tr
                                                          key={i}
                                                          data-key={i}
                                                          className={
                                                              this.state
                                                                  .checks1[i]
                                                                  ? `active`
                                                                  : ''
                                                          }
                                                      >
                                                          <td>
                                                              <Form.Check
                                                                  label=""
                                                                  name={
                                                                      `group-` +
                                                                      i
                                                                  }
                                                                  type="checkbox"
                                                                  data-id={i}
                                                                  id={
                                                                      `chk-cashreceipt-items-` +
                                                                      i
                                                                  }
                                                                  className="chk-cashreceipt-items-item"
                                                                  checked={
                                                                      this.state
                                                                          .checks1[
                                                                          i
                                                                      ]
                                                                  }
                                                                  onClick={
                                                                      this
                                                                          .handleChecks1
                                                                  }
                                                              />
                                                          </td>
                                                          <td>
                                                              <Link
                                                                  onClick={(
                                                                      e
                                                                  ) =>
                                                                      this.handleClickTransItem(
                                                                          item,
                                                                          i
                                                                      )
                                                                  }
                                                                  to={'#'}
                                                                  className="text-charcoal hover-view-icon"
                                                              >
                                                                  {item.txnum}
                                                              </Link>
                                                          </td>
                                                          <td>
                                                              {
                                                                  this.state
                                                                      .transactionTypes[
                                                                      item.type
                                                                  ]
                                                              }
                                                          </td>
                                                          <td>
                                                              {currencyFormat(
                                                                  item.totalAmount,
                                                                  '$'
                                                              )}
                                                          </td>
                                                          <td>{item.proj}</td>
                                                          <td>{item.projn}</td>
                                                          <td>
                                                              {item.propnum}
                                                          </td>
                                                          <td>
                                                              {item.payingtxnum}
                                                          </td>
                                                          <td>{item.txdesc}</td>
                                                          <td>
                                                              {
                                                                  item.singleoffsetaccount
                                                              }
                                                          </td>
                                                          <td>
                                                              {item.accountn}
                                                          </td>
                                                      </tr>
                                                  )
                                              )}
                                    </tbody>
                                </Table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="bg-ivory py-4">
                            <Row>
                                <Col className="align-items-end">
                                    <Button
                                        variant="trans-light border-secondary-ash"
                                        onClick={() => {
                                            this.setState({
                                                selectedReceiptTransactions: [],
                                            })
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                    <>
                        {this.state.showEditCashReceiptModal && (
                            <EditCashReceiptModal
                                receipt={this.selectedCashReceipt}
                                distribution={this.selectedTransItem}
                                show={this.state.showEditCashReceiptModal}
                                hideModal={this.hideEditDistributionModal}
                                updateCashReceipt={this.handleUpdateData}
                            />
                        )}
                        <CreateDepositSlipListExistingModal
                            cashAccounts={this.extractCashAccountsSelect()}
                            receipt={this.selectedCashReceipt}
                            show={this.state.showModal}
                            hideModal={() =>
                                this.setState(
                                    (prev) => (prev.showModal = false)
                                )
                            }
                            onPostDepositSlip={this.handlePostDepositSlip}
                        />
                    </>
                </div>
            </>
        )
    }
}

export default WithRouter(CashReceiptListExisting)
