import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import Header from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import {
    fakeProjectData,
    fakeReactSelectOptions,
} from '../../../factory/fakeFactory'
import DatePicker from 'react-datepicker'

class VendorCreditList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            dates: [
                {
                    startDate: '',
                    minDate: new Date(),
                },
                {
                    startDate: '',
                    minDate: new Date(),
                },
            ],
            isDisabled: true,
            creditTypes: [
                { label: 'Refund with Check', value: 'Refund with Check' },
                {
                    label: 'Refund to Credit Card',
                    value: 'Refund to Credit Card',
                },
                { label: 'Credit on Account', value: 'Credit on Account' },
            ],
            creditFund: [
                { label: 'Deposit', value: 'Deposit' },
                { label: 'Expense', value: 'Expense' },
                { label: 'Invoice', value: 'Invoice' },
            ],
            creditType: '',
            refundType: '',
            data: fakeProjectData(4),
            checks: {},
            activeDateIndex: 0,
        }
    }

    componentInit() {
        this.setTitle('Vendor Credits').setFormAction(true)
    }

    handleDateChange = (date) => {
        this.setState(
            (prevState) =>
                (prevState.dates[prevState.activeDateIndex].startDate = date)
        )
    }

    handleSetActiveDateIndx = (id) => {
        this.setState((prevState) => (prevState.activeDateIndex = id))
    }
    handleExpenseChange = (e) => {
        this.setState({
            refundType: e.value,
        })
    }

    handleCreditChange = (e) => {
        this.setState({
            creditType: e.value,
        })
    }

    renderHeader() {
        return (
            <Header buttons={1}>
                <Header.Breadcumbs>
                    <Breadcrumb className="breadcrumb-light">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.accountsPayable.base }}
                            linkAs={Link}
                        >
                            Accounts Payable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Vendor Credits</Breadcrumb.Item>
                    </Breadcrumb>
                </Header.Breadcumbs>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Title>Vendor Credits</Header.Title>
                    </Header.LeftContent>

                    <Header.Actions list={true}>
                        <li>
                            <Button variant="light">Save</Button>
                        </li>
                    </Header.Actions>
                </Header.TitleContent>
            </Header>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2} className="mb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row>
                            <Col>
                                <div className="bg-secondary-grey py-4 mb-4 roundedx-4">
                                    <Row className="align-items-center px-3">
                                        <Col className="px-3">
                                            <h6 className="fw-bold mb-0">
                                                Credit Type
                                            </h6>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Credit Type
                                </Form.Label>
                            </Col>
                            <Col>
                                <Select
                                    onChange={this.handleCreditChange}
                                    options={this.state.creditTypes}
                                    className="react-select"
                                    placeholder="Select"
                                />
                            </Col>
                        </Row>
                        <div
                            className={
                                this.state.creditType == 'Refund with Check'
                                    ? 'd-block'
                                    : 'd-none'
                            }
                        >
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Account
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        options={fakeReactSelectOptions()}
                                        className="react-select"
                                        placeholder="Select"
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Check No.
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div
                            className={
                                this.state.creditType == 'Refund to Credit Card'
                                    ? 'd-block'
                                    : 'd-none'
                            }
                        >
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Credit Card
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        options={fakeReactSelectOptions()}
                                        className="react-select"
                                        placeholder="Select"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <div className="bg-secondary-grey py-4 mb-4 roundedx-4">
                                    <Row className="align-items-center px-3">
                                        <Col className="px-3">
                                            <h6 className="fw-bold mb-0">
                                                Credit/Refund For
                                            </h6>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Credit/Refund For
                                </Form.Label>
                            </Col>
                            <Col>
                                <Select
                                    onChange={this.handleExpenseChange}
                                    options={this.state.creditFund}
                                    className="react-select"
                                    placeholder="Select"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} className="py-4">
                    <Col>
                        <div className="bg-secondary-grey py-4 roundedx-4">
                            <Row className="align-items-center px-3">
                                <Col className="px-3">
                                    <h6 className="fw-bold mb-0">
                                        Credit/Refund Information
                                    </h6>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row xs={1} lg={2}>
                    <Col>
                        <div
                            className={
                                this.state.refundType == 'Expense'
                                    ? 'd-none'
                                    : 'd-block'
                            }
                        >
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Project
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        options={fakeReactSelectOptions()}
                                        className="react-select"
                                        placeholder="Select"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        PO Number
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter PO Number"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Vendor</Form.Label>
                            </Col>
                            <Col>
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Select vendor"
                                />
                            </Col>
                        </Row>

                        <div
                            className={
                                this.state.refundType == ''
                                    ? 'd-none'
                                    : 'd-block'
                            }
                        >
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Date
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            className="mb-3 mb-md-0"
                                        >
                                            <div className="react-select-header">
                                                <DatePicker
                                                    selected={
                                                        this.state.dates[0]
                                                            .startDate
                                                    }
                                                    minDate={
                                                        this.state.dates[0]
                                                            .minDate
                                                    }
                                                    showMonthDropdown="true"
                                                    showYearDropdown="true"
                                                    onChange={
                                                        this.handleDateChange
                                                    }
                                                    onFocus={() => {
                                                        this.handleSetActiveDateIndx(
                                                            0
                                                        )
                                                    }}
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <div className="react-select-header">
                                                <DatePicker
                                                    selected={
                                                        this.state.dates[1]
                                                            .startDate
                                                    }
                                                    minDate={
                                                        this.state.dates[1]
                                                            .minDate
                                                    }
                                                    showMonthDropdown="true"
                                                    showYearDropdown="true"
                                                    onChange={
                                                        this.handleDateChange
                                                    }
                                                    onFocus={() => {
                                                        this.handleSetActiveDateIndx(
                                                            1
                                                        )
                                                    }}
                                                    className="form-control"
                                                    placeholderText="Select Fiscal Month"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-4">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Description
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Description"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Amount
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={2}>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter amount"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div
                                className={
                                    this.state.refundType == 'Expense' ||
                                    this.state.refundType == ''
                                        ? 'd-none'
                                        : 'd-block'
                                }
                            >
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Re-stock fee / Loss
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row lg={2}>
                                            <Col
                                                xs={12}
                                                sm={6}
                                                className="mb-3 mb-md-0"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter amount"
                                                />
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <Select
                                                    options={fakeReactSelectOptions()}
                                                    className="react-select"
                                                    placeholder="Select expense account"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                    <Col
                        className={
                            this.state.refundType != '' ? 'd-none' : 'd-block'
                        }
                    >
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Date</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        className="mb-3 mb-md-0"
                                    >
                                        <div className="react-select-header">
                                            <DatePicker
                                                selected={
                                                    this.state.dates[0]
                                                        .startDate
                                                }
                                                minDate={
                                                    this.state.dates[0].minDate
                                                }
                                                showMonthDropdown="true"
                                                showYearDropdown="true"
                                                onChange={this.handleDateChange}
                                                onFocus={() => {
                                                    this.handleSetActiveDateIndx(
                                                        0
                                                    )
                                                }}
                                                className="form-control"
                                                placeholderText="Select Date"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className="react-select-header">
                                            <DatePicker
                                                selected={
                                                    this.state.dates[1]
                                                        .startDate
                                                }
                                                minDate={
                                                    this.state.dates[1].minDate
                                                }
                                                showMonthDropdown="true"
                                                showYearDropdown="true"
                                                onChange={this.handleDateChange}
                                                onFocus={() => {
                                                    this.handleSetActiveDateIndx(
                                                        1
                                                    )
                                                }}
                                                className="form-control"
                                                placeholderText="Select Fiscal Month"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-4">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Description
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Description"
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Amount</Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter amount"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Re-stock fee / Loss
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        className="mb-3 mb-md-0"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter amount"
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Select
                                            options={fakeReactSelectOptions()}
                                            className="react-select"
                                            placeholder="Select expense account"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(VendorCreditList)
