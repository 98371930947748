import React from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import { WithRouter } from '../../../helpers/Router'
import { ApiService } from '../../../lib/api/HttpService'
import ListFilter from '../../components/ListFilter'
import ThreadComponent from '../ThreadComponent'
import { pathParam } from '../../../utilities/Router'
import ConfirmModal from '../../../app/components/modal/ConfirmModal'
import { displayAlert, displayAlertLoader } from '../../../utilities/Response'
import { showLoading } from '../../../helpers/Loading'

class GlossaryListItemGroups extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            dataIsLoaded: false,
            showModal: false,
            disabledDeleteButton: true,
        }
        this.api = new ApiService()
        this.errIds = []

        this.apiPath = 'groups'
        this.pageTitle = 'Groups | Glossaries'
    }

    componentInit() {
        this.setTitle(this.pageTitle).setActionBar(true)
    }

    async fetchData() {
        const data = await this.api.get(this.apiPath, '')

        this.renderData(data)
        this.enableSearchAndSortTable()
    }
    async componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.dataIsLoaded !== this.state.dataIsLoaded) {
            this.fetchData()
        }
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        this.setState((prev) => {
            prev.showTableSearch = !prev.showTableSearch
            return prev
        })
    }
    toggleDeleteButton = (e) => {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    toggleModal = (e) => {
        this.toggleDeleteButton(e)
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async handleDetele(path, id) {
        await this.api
            .delete(path, id, null)
            .then((res) => {
                if (res.status !== 200) this.errIds.push(id)
            })
            .catch((error) => {
                this.errIds.push(id)
            })
    }

    async deleteData(e) {
        this.toggleDeleteButton(e)
        displayAlertLoader(MSG.loading.delete.msg)

        for (const [index, value] of Object.entries(this.state.checks)) {
            if (value) {
                await this.handleDetele(this.apiPath, index)
            }
        }

        const message = this.errIds.length
            ? 'The data with the ids [' +
              this.errIds.join() +
              "] couldn't be deleted"
            : 'The data was succesfully deleted'
        const variant = this.errIds.length ? 'danger' : 'success'

        displayAlert(variant, message)

        this.refreshDataAfterDelete(e)
    }

    // Refresh data
    refreshDataAfterDelete = (e) => {
        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: {},
            totalChecked: 0,
            showModal: false,
            deleteResponses: [],
        })
        headingCheck.checked = false

        this.fetchData()

        this.uncheckAll()
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked
            prevState.disabledDeleteButton = totalChecked > 0 ? false : true

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    uncheckAll() {
        const checks = document.querySelectorAll(
            '.chk-glossaryLocations-items-item input'
        )
        if (checks) {
            checks.forEach((e) => {
                e.checked = false
            })
        }
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[item.id] = true
                        document.getElementById(
                            'chk-glossaryLocations-items-' + i
                        ).checked = true
                        return null
                    })
                }
                prevState.totalChecked = this.state.data.length
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                this.uncheckAll()
            }
            prevState.disabledDeleteButton =
                prevState.totalChecked > 0 ? false : true

            return prevState
        })
    }

    renderFilter() {
        return (
            <>
                <ListFilter expandable={0} className="no-separator">
                    <ListFilter.Actions lg={12} xl={12}>
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <Button
                                as={Link}
                                to={URI.glossary.add.itemGroups}
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                            <Button
                                as={Link}
                                onClick={this.toggleModal.bind(this)}
                                to={'#'}
                                variant="primary"
                                size="sm"
                                className={
                                    this.state.disabledDeleteButton |
                                    (this.state.totalChecked < 1)
                                        ? 'btn-icon ms-3 disabled'
                                        : 'btn-icon ms-3'
                                }
                            >
                                Delete
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderFilter()}

                <div className="table-gradient">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr className="a-table-heading">
                                <th align="middle" className="w-70px">
                                    <div className="d-flex justify-content-center">
                                        <Form.Check
                                            inline
                                            label=""
                                            name={`inline-check-th-0`}
                                            type="checkbox"
                                            data-id={`th-0`}
                                            id={`inline-check-th-0`}
                                            defaultChecked={
                                                this.state.totalChecked > 0
                                                    ? true
                                                    : false
                                            }
                                            className={`${
                                                this.state.totalChecked > 0 &&
                                                this.state.totalChecked <
                                                    this.state.data.length
                                                    ? 'line'
                                                    : ''
                                            }`}
                                            onClick={this.handleSelectAllChecks}
                                        />
                                    </div>
                                </th>
                                <th className="w-200px ps-3">
                                    <span
                                        className="sort active asc"
                                        data-field="projectCodeNo"
                                    >
                                        Group Name
                                    </span>
                                </th>
                                <th>
                                    <span className="sort">Notes</span>
                                </th>
                                <th>
                                    <span className="sort">Style/Size</span>
                                </th>
                                <th>
                                    <span className="sort">Color</span>
                                </th>
                            </tr>
                            <tr
                                className={`a-table-search-fields ${
                                    this.state.showTableSearch ? '' : 'd-none'
                                }`}
                            >
                                <th className="ps-5">
                                    <Form.Control
                                        type="text"
                                        data-field="projectCodeNo"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="clientName"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.dataIsLoaded
                                ? showLoading()
                                : this.state.data.map((item, i) => (
                                      <tr
                                          key={i}
                                          data-key={i}
                                          className={
                                              this.state.checks[i]
                                                  ? `active`
                                                  : ''
                                          }
                                      >
                                          <td>
                                              <div className="d-flex justify-content-center">
                                                  <Form.Check
                                                      label=""
                                                      name={`group-` + i}
                                                      type="checkbox"
                                                      data-id={item.id}
                                                      id={
                                                          `chk-glossaryLocations-items-` +
                                                          i
                                                      }
                                                      className="chk-glossaryLocations-items-item"
                                                      defaultValue={
                                                          this.state.checks[
                                                              item.id
                                                          ]
                                                              ? true
                                                              : false
                                                      }
                                                      onClick={
                                                          this.handleChecks
                                                      }
                                                  />
                                              </div>
                                          </td>
                                          <td className="ps-3">
                                              <Link
                                                  to={pathParam(
                                                      URI.glossary.edit
                                                          .itemGroups,
                                                      { id: item.id }
                                                  )}
                                                  className="text-charcoal hover-view-icon"
                                              >
                                                  {item.groupname}
                                              </Link>
                                          </td>
                                          <td>{item.notes}</td>
                                          <td>{item.category2}</td>
                                          <td>{item.category1}</td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                </div>
                <ConfirmModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    toggleModal={this.toggleModal}
                    confirmAction={this.deleteData.bind(this)}
                />
            </>
        )
    }
}

export default WithRouter(GlossaryListItemGroups)
