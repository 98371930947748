import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { Item } from '../types/Item'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'

const api = new ApiService()

const getItem = async (id: string) => {
    const item = (await api.getProjectItems(
        `?$filter=id eq ${encodeURIComponent(id)}`
    )) as Item[]
    if (!item.length) {
        throw Error('Item not found')
    }

    return item[0]
}

const useGetItem = (id: string, options?: UseQueryOptions<Item>) => {
    const itemQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [id, 'items'],
        retry: (failureCount, error) => {
            if (failureCount >= 2) {
                return false
            }
            return (error as Error)?.message !== 'Item not found'
        },
        queryFn: () => getItem(id),
        ...(options || {}),
    })
    useEffect(() => {
        if (itemQuery.error) {
            displayAlertError(
                'An error occurred fetching the item, please try again'
            )
        }
    }, [itemQuery.error])
    return itemQuery
}
export default useGetItem
