import React from 'react'
import { Container, Col, Row, Form } from 'react-bootstrap'
import InputBar from '../components/InputBar'

class InputBarUi extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isEnabledInputBar1: false,
            isEnabledInputBar2: true,
        }

        // This binding is necessary to make `this` work in the callback
        this.handleInputBarChange = this.handleInputBarChange.bind(this)
    }

    handleInputBarChange = (e) => {
        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState) => {
            let data = {}
            data[id] = e.target.checked

            return data
        })
    }

    render() {
        return (
            <>
                <div className="bg-secondary-grey pt-5 pb-4 mt-5">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="ui-title">Input Bar</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="mt-5 pb-5 ">
                    <Row>
                        <Col lg="12" className="mb-5">
                            <InputBar className="toggle-input">
                                <InputBar.Links style={{ minWidth: '250px' }}>
                                    <InputBar.Link
                                        className="single-link"
                                        childprops={{
                                            className:
                                                'justify-content-center justify-content-md-start',
                                        }}
                                    >
                                        <div style={{ width: 'auto' }}>
                                            <Form.Check
                                                inline
                                                defaultChecked={
                                                    this.state
                                                        .isEnabledInputBar1
                                                }
                                                label="Manually Enter Total"
                                                name="inputbar-manual-1"
                                                type="checkbox"
                                                id="inputbar-manual-1"
                                                className="pe-3"
                                                onChange={
                                                    this.handleInputBarChange
                                                }
                                                data-state="isEnabledInputBar1"
                                            />
                                        </div>
                                    </InputBar.Link>
                                </InputBar.Links>
                                <InputBar.Links
                                    className={`${
                                        this.state.isEnabledInputBar1
                                            ? ''
                                            : 'inactive'
                                    } full-width`}
                                >
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Budget Total
                                        </Form.Label>
                                        <div className="form-group-extra reversed">
                                            <Form.Control
                                                type="text"
                                                placeholder="0.00"
                                                size="sm"
                                                disabled={
                                                    !this.state
                                                        .isEnabledInputBar1
                                                }
                                            />
                                            <span>$</span>
                                        </div>
                                    </InputBar.Link>
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Overall Warning
                                        </Form.Label>
                                        <Form.Select
                                            size="sm"
                                            disabled={
                                                !this.state.isEnabledInputBar1
                                            }
                                        >
                                            <option>None</option>
                                        </Form.Select>
                                    </InputBar.Link>
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Overall Tolerance
                                        </Form.Label>
                                        <div className="form-group-extra">
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                size="sm"
                                                disabled={
                                                    !this.state
                                                        .isEnabledInputBar1
                                                }
                                            />
                                            <span>%</span>
                                        </div>
                                    </InputBar.Link>
                                </InputBar.Links>
                            </InputBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" className="mt-5">
                            <InputBar className="toggle-input">
                                <InputBar.Links style={{ minWidth: '250px' }}>
                                    <InputBar.Link
                                        className="single-link"
                                        childprops={{
                                            className:
                                                'justify-content-center justify-content-md-start',
                                        }}
                                    >
                                        <div style={{ width: 'auto' }}>
                                            <Form.Check
                                                inline
                                                defaultChecked={
                                                    this.state
                                                        .isEnabledInputBar2
                                                }
                                                label="Manually Enter Total"
                                                name="inputbar-manual-2"
                                                type="checkbox"
                                                id="inputbar-manual-2"
                                                className="pe-3"
                                                onChange={
                                                    this.handleInputBarChange
                                                }
                                                data-state="isEnabledInputBar2"
                                            />
                                        </div>
                                    </InputBar.Link>
                                </InputBar.Links>
                                <InputBar.Links
                                    className={`${
                                        this.state.isEnabledInputBar2
                                            ? ''
                                            : 'inactive'
                                    } full-width`}
                                >
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Budget Total
                                        </Form.Label>
                                        <div className="form-group-extra reversed">
                                            <Form.Control
                                                type="text"
                                                placeholder="0.00"
                                                size="sm"
                                                disabled={
                                                    !this.state
                                                        .isEnabledInputBar2
                                                }
                                            />
                                            <span>$</span>
                                        </div>
                                    </InputBar.Link>
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Overall Warning
                                        </Form.Label>
                                        <Form.Select
                                            size="sm"
                                            disabled={
                                                !this.state.isEnabledInputBar2
                                            }
                                        >
                                            <option>None</option>
                                        </Form.Select>
                                    </InputBar.Link>
                                    <InputBar.Link>
                                        <Form.Label
                                            htmlFor="inputPassword5"
                                            className="ilabel"
                                        >
                                            Overall Tolerance
                                        </Form.Label>
                                        <div className="form-group-extra">
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                size="sm"
                                                disabled={
                                                    !this.state
                                                        .isEnabledInputBar2
                                                }
                                            />
                                            <span>%</span>
                                        </div>
                                    </InputBar.Link>
                                </InputBar.Links>
                            </InputBar>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default InputBarUi
