import React from 'react'
import { Accordion, Col, Row, Form, Button, Container } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'

import { WithRouter } from '../../../helpers/Router'

import Select from 'react-select'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import { FooterFormAction } from '../../components/Form'
import ProjectAddMarkup from './ProjectAddMarkup'
import { ApiService } from '../../../lib/api/HttpService'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../utilities/Rtf'
import { pick } from 'lodash'
import { displayAlertError } from '../../../utilities/Response'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureFormControl from '../../../app/components/security/SecureFormControl'
import { TIME_BILLING_TIERS } from './constants'

class ProjectViewSettings extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            company: {},
            selectData: TIME_BILLING_TIERS,
            defaultTier: { value: 0, label: 'Tier 1' },
            dataLoaded: false,
            isMarkupShown: false,
            noteshtml: '',
        }
        this.api = new ApiService()
        this.updatedData = props.updatedData
        this.htmlToRtfCoverter = new HtmlToRtfBrowser()
        this.salesTaxProps = ['td', 'tf', 'ti', 'tl', 'tm', 'to']
    }

    async componentDidMount() {
        await this.fetchInitialData()
    }

    async fetchInitialData() {
        let salesTaxCodes = []
        const company = await this.api.getCompany()

        let taxCodes = await this.api.get(
            'salestaxcodes',
            '?$filter=inactive eq false'
        )
        for (let tax of taxCodes) {
            salesTaxCodes.push({
                value: tax.taxc,
                label: tax.taxcn,
                ...tax,
            })
        }

        if (!this.props.project.taxcn && this.props.project.taxc) {
            let projectTaxCode = await this.api.get(
                'salestaxcodes',
                `?$filter=taxc eq '${this.props.project.taxc}'`
            )
            if (projectTaxCode.length) {
                salesTaxCodes.push({
                    value: projectTaxCode[0].taxc,
                    label: projectTaxCode[0].taxcn,
                    ...projectTaxCode[0],
                })
            }
        }

        this.setState({
            data: {
                ...this.props.project,
                salesTaxCodes: salesTaxCodes,
            },
            company,
            dataIsLoaded: true,
            noteshtml: await mapRtfToDraft(this.props.project.notesrtf ?? ''),
        })
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.project !== this.props.project) {
            this.setState({
                data: {
                    ...this.props.project,
                },
                dataIsLoaded: true,
            })
        }
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.updatedData[key] = value

        this.props.onChange(this.updatedData)
    }

    handleMarkupSaved = (markup) => {
        delete markup.salesTaxCodes
        this.updatedData = {
            ...this.updatedData,
            ...markup,
        }
        const propremarksNew = this.updatedData.propremarks.split('\n').join('')
        this.updatedData = {
            ...this.updatedData,
            propremarks: propremarksNew,
        }
        const maxLength = 60000
        const propremarksLength = propremarksNew?.length
        if (propremarksLength > maxLength) {
            displayAlertError(
                'Only a max of 60000 characters for the Remarks field is allowed'
            )
            return
        }
        this.props.onChange(this.updatedData)
        let { salesTaxCodes } = this.state.data
        if (this.updatedData.taxc) {
            const salesTaxIndex = salesTaxCodes.findIndex(
                (i) => i.taxc === this.updatedData.taxc
            )
            const salesTaxData = pick(this.updatedData, this.salesTaxProps)
            salesTaxCodes[salesTaxIndex] = {
                ...salesTaxCodes[salesTaxIndex],
                ...salesTaxData,
            }
        }
        this.setState({
            data: {
                ...this.state.data,
                ...markup,
                salesTaxCodes,
            },
            isMarkupShown: false,
        })

        if (!this.props.params.id) {
            return
        }

        this.handleSave(false)
    }

    handleSave = async () => {
        await this.props.onSave()
        await this.fetchInitialData()
    }

    setSelectDefaults(data) {
        if (data.timebillingtier != null) {
            this.state.selectData.map((tier) => {
                if (tier.value == data.timebillingtier) {
                    this.state.defaultTier = {
                        value: tier.value,
                        label: tier.label,
                    }
                }
            })
        }
    }

    renderDefaults() {
        let { data } = this.state
        data = { ...data, ...this.updatedData }

        this.setSelectDefaults(this.props.project)

        return (
            <>
                <Row>
                    <Col lg="6">
                        <div className="form-fields">
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                >
                                    PO Sidemark
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        type="text"
                                        placeholder="PO Sidemark"
                                        defaultValue={data.posidemark}
                                        id="posidemark"
                                        onChange={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                                <Col sm="9">
                                    <Form.Check
                                        inline
                                        label="Append Loc. to Sidemark"
                                        name="group2"
                                        type="checkbox"
                                        id="locinsidemark"
                                        defaultChecked={data.locinsidemark}
                                        onClick={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                >
                                    PO Designer/Ext.
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        type="text"
                                        placeholder="PO Designer/Ext."
                                        defaultValue={data.designer}
                                        id="designer"
                                        onChange={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                            </Form.Group>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                >
                                    Client Deposit
                                </Form.Label>
                                <Col sm="9">
                                    <SecureFormControl
                                        attributeNo={200}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        type="text"
                                        placeholder="Include Sales Tax in Deposit"
                                        defaultValue={data.clientdep}
                                        id="clientdep"
                                        onChange={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                                <Col sm="9">
                                    <Form.Check
                                        inline
                                        label="Include Sales Tax in Deposit"
                                        name="group2"
                                        type="checkbox"
                                        id="cldpsalestax"
                                        defaultChecked={data.cldpsalestax}
                                        onClick={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                >
                                    Client Invoice Terms
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        type="text"
                                        placeholder="Client Invoice Terms"
                                        defaultValue={data.clientinvterms}
                                        id="clientinvterms"
                                        onChange={this.handleChange}
                                        disabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                            </Form.Group>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                >
                                    Time Billing Tier
                                </Form.Label>
                                <Col sm="9">
                                    <Select
                                        name="timebillingtier"
                                        defaultValue={this.state.defaultTier}
                                        options={this.state.selectData}
                                        onChange={this.handleChange}
                                        className="react-select"
                                        placeholder="Select"
                                        isDisabled={this.props.closed}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                            </Form.Group>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                            >
                                <Form.Label
                                    column
                                    sm="3"
                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                ></Form.Label>
                                <Col sm="9">
                                    <SecureBootstrapButton
                                        attributeNo={13}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenySpec2
                                        }
                                        variant="primary"
                                        className=" w-100"
                                        disabled={this.props.closed}
                                        onClick={() => {
                                            this.setState({
                                                isMarkupShown: true,
                                            })
                                        }}
                                    >
                                        Advanced / Mark-up
                                    </SecureBootstrapButton>
                                </Col>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    renderNotes() {
        return (
            <Row>
                <Col xl={7}>
                    <WysiwygEditor
                        id="notes"
                        onChange={(e) =>
                            this.props.handleNotes(
                                e.target.value,
                                this.htmlToRtfCoverter.convertHtmlToRtf(
                                    e.target.html
                                )
                            )
                        }
                    >
                        {this.state.noteshtml}
                    </WysiwygEditor>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <>
                <div className="mb-4">
                    <Accordion alwaysOpen defaultActiveKey={['0', '1', '2']}>
                        <Accordion.Item className="mb-2" eventKey="0">
                            <Accordion.Header className="roundedx-4">
                                Defaults
                            </Accordion.Header>
                            <Accordion.Body>
                                <Container className="ms-0">
                                    {this.renderDefaults()}
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mb-2" eventKey="1">
                            <Accordion.Header>Notes</Accordion.Header>
                            <Accordion.Body>
                                <Container className="ms-0">
                                    {this.renderNotes()}
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <SecureBootstrapButton
                        variant="primary"
                        attributeNo={13}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        disabled={this.props.closed}
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </SecureBootstrapButton>
                </FooterFormAction>

                {this.state.dataIsLoaded && (
                    <ProjectAddMarkup
                        project={this.props.project}
                        company={this.state.company}
                        markupData={this.state.data}
                        isShowing={this.state.isMarkupShown}
                        onContinue={this.handleMarkupSaved}
                        onCancel={() => {
                            this.setState({ isMarkupShown: false })
                        }}
                    />
                )}
            </>
        )
    }
}

export default WithRouter(ProjectViewSettings)
