import React from 'react'
import { Container, Col, Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import ThreadComponent from '../../../ThreadComponent'
import { observer } from 'mobx-react'
import AddressAddEditViewModel from '../../Address/AddressAddEditViewModel'
import { AddressService } from '../../Address/api/AddressService'
import AddressListViewModel from '../../Address/AddressListViewModel'
import ClientAddEditViewModel from '../ClientAddEditViewModel'
import { ClientService } from '../api/ClientService'
import { toSortName } from '../../../../utilities/String'
import { ApiService } from '../../../../lib/api/HttpService'
import { getLocalStorage } from '../../../../utilities/LocalStorage'
import DeleteModal from '../../../components/modal/DeleteModal'
import {
    displayAlertLoader,
    hideAlertLoader,
} from '../../../../utilities/Response'
import HandleDeleteWorker from '../../../../utilities/DeleteWorker'
import URI from '../../../../defaults/RoutesDefault'
import MSG from '../../../../defaults/Message'
import InputMasked from '../../../components/form/MaskedInput'
import AsyncEmployeesDropdown from '../../../components/dropdowns/AsyncEmployeesDropdown'
import AsyncSalesTaxCodesDropdown from '../../../components/dropdowns/AsyncSalesTaxCodesDropdown'
import { isNullOrUndefined } from '../../../../utilities/Validation'
import SecureButton from '../../../components/security/SecureButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security'
import { nameToCode, formatCode } from 'app/utils/codes'

interface SelectOptions {
    label?: string
    value?: any
}

interface Props {
    data: any
    addressesTypes?: string[]
    defaultAddressType?: SelectOptions
    onDraft?: any
    navigate?: any
}

interface State {
    data: any
    sortTypes: SelectOptions[]
    isDisabledSort?: boolean
    salesTaxCoders: any
    defaultType?: SelectOptions
    showModal: boolean
    deleteResponses: any
    dataIsLoaded: boolean
    isEditing?: boolean
    clearedSelect?: string[]
}

class ClientsAddInfo extends ThreadComponent<Props, State> {
    readonly addressAddEditModel: AddressAddEditViewModel
    readonly addressListModel: AddressListViewModel
    readonly clientAddEditModel: ClientAddEditViewModel
    addressesTypes: string[]
    defaultAddressType: SelectOptions | null
    defaultSortType: SelectOptions | null
    defaultSalesTaxCode?: SelectOptions | null
    isDisabledSort: boolean
    deleteObjectParams: any

    private apiService = new ApiService()

    constructor(props: Props) {
        super(props)

        this.addressAddEditModel = new AddressAddEditViewModel(
            new AddressService()
        )
        this.addressListModel = new AddressListViewModel(new AddressService())
        this.clientAddEditModel = new ClientAddEditViewModel(
            new ClientService()
        )
        this.state = {
            data: {},
            sortTypes: [
                { value: 0, label: 'Name' },
                { value: 1, label: 'Company' },
                { value: 2, label: 'Manual' },
            ],
            isDisabledSort: false,
            salesTaxCoders: [],
            defaultType: undefined,
            showModal: false,
            deleteResponses: {},
            dataIsLoaded: false,
            isEditing: false,
            clearedSelect: [],
        }
        this.addressesTypes = []
        this.defaultAddressType = null
        this.defaultSortType = { value: 2, label: 'Manual' }
        this.isDisabledSort = false

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objClient',
            objectCodeOrId: '',
        }
    }

    async componentDidMount() {
        this.hasFormAction(true)

        const { data } = this.props

        displayAlertLoader(MSG.loading.info)
        this.setState(
            {
                data: data,
                salesTaxCoders:
                    await this.clientAddEditModel.fetchSalesTaxCodesSummaries(),
            },
            () => {
                this.setDefaultOptions()
                this.loadAdditionalData()
            }
        )

        this.setState({
            dataIsLoaded: true,
        })
    }

    componentDidUpdate(previousProps: Props, previousState: State): void {
        if (previousProps.data !== this.props.data) {
            const { data } = this.props

            if (data?.id) {
                this.setState({
                    isEditing: true,
                })
            }
            this.setState(
                {
                    data: data,
                },
                () => {
                    this.setDefaultOptions()
                    this.loadAdditionalData()
                }
            )
        }
    }

    async loadAdditionalData() {
        // Get list for types.
        this.addressListModel.setParams('addressType eq 4')
        await this.addressListModel.fetchAddresses()
        const lists = this.addressListModel.addressTypes.map(
            (type) => type.value
        )
        this.setState({
            defaultType: this.addressListModel.addressTypes.filter((type) => {
                return (
                    type.value === this.state.data.address?.typedesc ||
                    (type.value === 'Custom' &&
                        !isNullOrUndefined(this.state.data.address?.typedesc) &&
                        !lists.includes(this.state.data.address?.typedesc))
                )
            })[0],
        })
        hideAlertLoader()
    }

    async setDefaultOptions() {
        const newData = this.state.data
        if (this.state.data?.manager && !this.state.data?.defaultSalesperson) {
            const salesPerson = await this.apiService.getClientEmployeeByCode(
                this.state.data?.manager,
                true
            )
            newData.defaultSalesperson = salesPerson
            this.clientAddEditModel.setClientData(
                'defaultSalesperson',
                salesPerson
            )
        }

        if (this.state.data?.deftaxc && !this.state.data?.defaultTaxCode) {
            const txcode = await this.apiService.getSalesTaxCodeByCode(
                this.state.data?.deftaxc,
                true
            )
            newData.defaultTaxCode = txcode
            this.clientAddEditModel.setClientData('defaultTaxCode', txcode)
        }

        this.setState({
            data: newData,
        })
    }

    async setSelectDefaults() {
        for (const x of this.state.sortTypes) {
            if (x.value === this.state.data?.address?.parsetype)
                this.defaultSortType = x
        }

        const sortName = document.getElementById('sortName')

        if (this.defaultSortType?.value !== 2) {
            sortName?.setAttribute('readonly', 'readonly')
        } else {
            sortName?.removeAttribute('readonly')
        }
    }

    setAddressToClient() {
        const addressData: any = getLocalStorage('addressData', true)
        const clientData: any = getLocalStorage('clientData', true)

        clientData['address'] = addressData

        window.localStorage.setItem('clientData', JSON.stringify(clientData))
    }

    handleClientChange = (e: any, select?: any) => {
        if (!select) {
            // If checkbox
            if (
                e.target.tagName.toLowerCase() === 'input' &&
                e.target.getAttribute('type') === 'checkbox'
            ) {
                this.clientAddEditModel.setClientData(
                    e.target.name,
                    e.target.checked ? true : false
                )
                this.props.onDraft()
                return
            }

            if (!this.state.isEditing) {
                if (e.target.name === 'ClientName') {
                    const clientCode = nameToCode(e.target.value)
                    this.clientAddEditModel.setClientData('client', clientCode)
                } else if (e.target.name === 'client') {
                    e.target.value = formatCode(e.target.value)
                }
            }

            this.clientAddEditModel.setClientData(e.target.name, e.target.value)
        }

        // If select
        if (select) {
            if (!e) {
                this.setState((prev) => {
                    if (!this.isClearedSelect(select.name)) {
                        prev.clearedSelect?.push(select.name)
                    }
                    return prev
                })
            }

            this.clientAddEditModel.setClientData(select.name, e?.value ?? '')
            if (select.name === 'deftaxc') {
                this.defaultSalesTaxCode = e
                this.clientAddEditModel.setClientData('defaultTaxCode', e)
            } else if (select.name === 'manager') {
                this.clientAddEditModel.setClientData('defaultSalesperson', e)
            }
        }
        this.props.onDraft()
    }

    handleAddressChange = (e: any, select?: any) => {
        // If plain text
        if (!select) {
            this.addressAddEditModel.setAddressData(
                e.target.name,
                e.target.value
            )
        }
        // If select
        if (select && e.name) {
            this.addressAddEditModel.setAddressData(e.name, select.value)
        }
        this.setAddressToClient()
        this.props.onDraft()
    }

    handleSortTypeChange = (select: any) => {
        const clientName: any = document.getElementById('clientName')
        const sortName: any = document.getElementById('sortName')

        if (select.value === 0 || select.value === 1) {
            if (this.state.data?.ClientName) {
                if (select.value === 0) {
                    sortName.value = toSortName(clientName.value)
                } else {
                    sortName.value = clientName.value
                }
                sortName?.setAttribute('readonly', 'readonly')
            }
        } else {
            sortName?.removeAttribute('readonly')
        }

        this.clientAddEditModel.setClientData('sortName', sortName.value)
        this.addressAddEditModel.setAddressData('sortname', sortName.value)
        this.addressAddEditModel.setAddressData('parsetype', select.value)
        this.props.onDraft()
    }

    getSortString = () => {
        const { data } = this.state

        if (data.address?.parsetype === 0) {
            return toSortName(this.state.data?.ClientName || '')
        } else if (data.address?.parsetype === 1) {
            return this.state.data?.ClientName || ''
        }

        return (
            data.address?.sortname ??
            toSortName(this.state.data?.ClientName || '')
        )
    }

    handleTypeChange = (e: any, select?: any) => {
        if (!select) {
            this.addressAddEditModel.setAddressData('typedesc', e.target.value)
        } else {
            this.setState({
                defaultType: { value: select.value, label: select.value },
            })
            this.addressAddEditModel.setAddressData(
                'typedesc',
                select && select.value === 'Custom' ? '' : select.value
            )
        }

        this.setAddressToClient()
        this.props.onDraft()
    }

    refreshDataAfterDelete = (e: any) => {
        this.props.navigate(URI.contact.client)
    }

    hideModal = (e: any) => {
        this.setState({
            showModal: false,
        })
    }

    handleDelete = (e: any) => {
        const self = this
        const workers: any = []

        displayAlertLoader(MSG.loading.prepare.item)

        this.deleteObjectParams.objectCodeOrId = this.state.data.id
        workers.push(
            this.clientAddEditModel.deleteClient(this.deleteObjectParams)
        )

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: 'Client',
                    modulePlural: 'Clients',
                    reference: 'Projects',
                    objectType: self.deleteObjectParams.objectType,
                },
                false,
                (modalState: any) => {
                    this.setState(modalState)
                }
            )
        }
    }

    isClearedSelect(name: string) {
        return this.state.clearedSelect?.includes(name)
    }

    render(): JSX.Element {
        const { data, isEditing } = this.state
        this.setSelectDefaults()
        const salesTaxCodes = []

        for (const i of this.state.salesTaxCoders) {
            const tmp = { value: i.taxc, label: i.taxcn }
            if (
                i.taxc !== null &&
                i.taxcn !== null &&
                i.taxc !== '' &&
                i.taxcn !== ''
            )
                salesTaxCodes.push(tmp)
            if (
                i.taxc === this.state.data.deftaxc &&
                !this.isClearedSelect('deftaxc')
            )
                this.defaultSalesTaxCode = tmp
        }

        return (
            <>
                <Container className="px-0 ms-0">
                    <Form.Group>
                        <Row xs={1} lg={2}>
                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Name
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={40}
                                            placeholder="Please enter"
                                            defaultValue={data.ClientName}
                                            id="clientName"
                                            onChange={this.handleClientChange}
                                            name="ClientName"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Sort
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={40}
                                            placeholder="Please enter"
                                            value={this.getSortString()}
                                            id="sortName"
                                            onChange={this.handleAddressChange.bind(
                                                this
                                            )}
                                            name="sortname"
                                            readOnly={
                                                data.address?.parsetype === 0 ||
                                                data.address?.parsetype === 1
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Select
                                            options={this.state.sortTypes}
                                            value={
                                                this.state.sortTypes[
                                                    data.address?.parsetype
                                                ] ?? this.state.sortTypes[0]
                                            }
                                            className="react-select"
                                            placeholder="Please select"
                                            onChange={this.handleSortTypeChange.bind(
                                                this
                                            )}
                                            id="parsetype"
                                            name="parsetype"
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Code
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg={6}>
                                                {!isEditing ? (
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={5}
                                                        placeholder="Please enter"
                                                        value={data.client}
                                                        onChange={
                                                            this
                                                                .handleClientChange
                                                        }
                                                        name="client"
                                                        id="client"
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={5}
                                                        placeholder="Please enter"
                                                        disabled={
                                                            data.ClientName !==
                                                            undefined
                                                        }
                                                        readOnly={
                                                            data.ClientName !==
                                                            undefined
                                                        }
                                                        defaultValue={
                                                            data.client
                                                        }
                                                        onChange={(e) => {
                                                            const updatedEvent =
                                                                e
                                                            updatedEvent.target.value =
                                                                e.target.value
                                                                    .substring(
                                                                        0,
                                                                        5
                                                                    )
                                                                    .toUpperCase()
                                                            this.handleClientChange(
                                                                updatedEvent
                                                            )
                                                        }}
                                                        name="client"
                                                        id="client"
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Type
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg={6}>
                                                <Select
                                                    key={`${Math.floor(
                                                        Math.random() * 1000
                                                    )}-min`}
                                                    options={
                                                        this.addressListModel
                                                            .addressTypes
                                                    }
                                                    defaultValue={
                                                        this.state.defaultType
                                                    }
                                                    className="react-select"
                                                    placeholder="Please select"
                                                    name="typedesc"
                                                    onChange={this.handleTypeChange.bind(
                                                        this,
                                                        { name: 'typedesc' }
                                                    )}
                                                />
                                            </Col>
                                        </Row>

                                        {this.state.defaultType?.value ===
                                            'Custom' && (
                                            <Row className={'mt-2'}>
                                                <Col lg={6}>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={20}
                                                        placeholder="Please enter"
                                                        defaultValue={
                                                            data.address
                                                                ?.typedesc
                                                        }
                                                        onChange={
                                                            this
                                                                .handleTypeChange
                                                        }
                                                        name="typedesc"
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row xs={1} lg={2}>
                            <Col className="mb-3 mb-lg-0">
                                <Row className="mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>
                                                Contact
                                                <br />
                                                Information
                                            </strong>
                                        </Form.Label>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row xs={1} lg={2} className="mb-4">
                            <Col className="mb-3 mb-lg-0">
                                <Row className="mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Address
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            as="textarea"
                                            maxLength={255}
                                            placeholder="Please enter"
                                            style={{ height: '100px' }}
                                            defaultValue={data.address?.addr}
                                            onChange={this.handleAddressChange}
                                            name="addr"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            City
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={30}
                                            placeholder="Please enter"
                                            defaultValue={data.address?.city}
                                            onChange={this.handleAddressChange}
                                            name="city"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            State
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            maxLength={2}
                                            defaultValue={data.address?.state}
                                            onChange={this.handleAddressChange}
                                            name="state"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Zip Code
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={20}
                                            placeholder="Please enter"
                                            defaultValue={data.address?.zip}
                                            onChange={this.handleAddressChange}
                                            name="zip"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Contact
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={40}
                                            placeholder="Please enter"
                                            defaultValue={data.address?.contact}
                                            onChange={this.handleAddressChange}
                                            name="contact"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Phone
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <InputMasked
                                            type="text"
                                            placeholder="Please enter"
                                            value={data.address?.contactphone}
                                            onChange={this.handleAddressChange}
                                            name="contactphone"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Fax
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <InputMasked
                                            type="text"
                                            placeholder="Please enter"
                                            value={data.address?.fax}
                                            onChange={this.handleAddressChange}
                                            name="fax"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Email
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={255}
                                            placeholder="Please enter"
                                            defaultValue={data.address?.email}
                                            value={data.address?.email}
                                            onChange={this.handleAddressChange}
                                            name="email"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Website
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={50}
                                            placeholder="Please enter"
                                            defaultValue={data.address?.website}
                                            onChange={this.handleAddressChange}
                                            name="website"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Phone 2
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col
                                                lg={6}
                                                className="mb-3 mb-lg-0"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    maxLength={40}
                                                    placeholder="Phone 2 Description"
                                                    defaultValue={
                                                        data.address?.phonedesc1
                                                    }
                                                    onChange={
                                                        this.handleAddressChange
                                                    }
                                                    name="phonedesc1"
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <InputMasked
                                                    type="text"
                                                    placeholder="Phone 2 Number"
                                                    value={data.address?.phone1}
                                                    onChange={
                                                        this.handleAddressChange
                                                    }
                                                    name="phone1"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Phone 3
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col
                                                lg={6}
                                                className="mb-3 mb-lg-0"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    maxLength={40}
                                                    placeholder="Phone 3 Description"
                                                    defaultValue={
                                                        data.address?.phonedesc2
                                                    }
                                                    onChange={
                                                        this.handleAddressChange
                                                    }
                                                    name="phonedesc2"
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <InputMasked
                                                    type="text"
                                                    placeholder="Phone 3 Number"
                                                    value={data.address?.phone2}
                                                    onChange={
                                                        this.handleAddressChange
                                                    }
                                                    name="phone2"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row xs={1} lg={2}>
                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Salesperson/Mgr.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <AsyncEmployeesDropdown
                                            defaultValue={
                                                this.state.data
                                                    ?.defaultSalesperson
                                            }
                                            className="react-select"
                                            placeholder="Please select"
                                            name="manager"
                                            onChange={this.handleClientChange}
                                            isClearable={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row className="mb-3 align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Sales Tax Code
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg={6}>
                                                <AsyncSalesTaxCodesDropdown
                                                    defaultValue={
                                                        this.state.data
                                                            ?.defaultTaxCode
                                                    }
                                                    className="react-select"
                                                    placeholder="Please select"
                                                    name="deftaxc"
                                                    onChange={
                                                        this.handleClientChange
                                                    }
                                                    isClearable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="mb-3 align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Tax ID
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={16}
                                            placeholder="Please enter"
                                            defaultValue={data.taxid}
                                            onChange={this.handleClientChange}
                                            name="taxid"
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3 align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Invoice Terms
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={30}
                                            placeholder="Please enter"
                                            defaultValue={
                                                data.defclientinvterms
                                            }
                                            onChange={this.handleClientChange}
                                            name="defclientinvterms"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    ></Col>
                                    <Col lg={6}>
                                        <Form.Check
                                            inline
                                            label="Inactive"
                                            type="checkbox"
                                            id={`inline-radio-5`}
                                            defaultChecked={data.inactive}
                                            onClick={this.handleClientChange}
                                            name="inactive"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {!this.state.data.id ? (
                            ''
                        ) : (
                            <Row>
                                <Col xs={1}>
                                    <SecureButton
                                        attributeNo={39}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyDel
                                        }
                                        href="#"
                                        title={'Delete Client'}
                                        variant="danger"
                                        size="sm"
                                        className="btn-icon btn-action mt-3"
                                        onClick={this.handleDelete}
                                    >
                                        <i className="ri-close-line"></i> Delete
                                    </SecureButton>
                                </Col>
                            </Row>
                        )}
                    </Form.Group>
                </Container>

                <DeleteModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    hideModal={this.hideModal}
                    responses={this.state.deleteResponses}
                />
            </>
        )
    }
}

export default observer(ClientsAddInfo)
