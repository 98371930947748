import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Row, Col, Button } from 'react-bootstrap'
import ConfirmModal from '../../app/components/modal/ConfirmModal'

const MyDropzone = (props) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [displayedImage, setDisplayedImage] = useState(props.image)

    useEffect(() => {
        setDisplayedImage(props.image)
    }, [props])

    const toggleModal = () => {
        if (!props.doNotShowConfirmationModal) {
            setShowConfirmModal(!showConfirmModal)
        } else if (props.doNotShowConfirmationModal) {
            props.onRemoveFiles()
            setDisplayedImage(null)
        }
    }

    const removePhoto = () => {
        if (props.onRemoveFiles) {
            props.onRemoveFiles()
            setDisplayedImage(null)
        }
        toggleModal()
    }

    useEffect(() => {
        if (props.setImageOnEveryChange) {
            setDisplayedImage(props.image)
        }
    }, [props.image])

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (props.onAcceptedFiles) {
                props.onAcceptedFiles(acceptedFiles[0])
                setDisplayedImage(acceptedFiles[0])
            }
        },
    })

    let allowedFiles = acceptedFiles
    if (!acceptedFiles.length && props.image) {
        allowedFiles = [props.image]
    }

    const img =
        displayedImage && displayedImage instanceof Blob ? displayedImage : null

    const renderButtons = () => {
        if (img) {
            return (
                <>
                    <Row>
                        <Col>
                            <Button
                                variant="primary"
                                className="mt-4 w-100 btn-lg btn-block"
                                onClick={open}
                            >
                                Change Photo
                            </Button>
                        </Col>

                        <Col>
                            <Button
                                variant="light"
                                className="mt-4 w-100 btn-lg btn-block"
                                onClick={toggleModal}
                            >
                                Remove Photo
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }
        return null
    }

    return (
        <>
            <div className="dropzone-wrapper" {...getRootProps()}>
                <div className="dropzone-content">
                    <input {...getInputProps()} />
                    <i className="ri-image-add-fill"></i>
                    <p>Drag photo into here</p>

                    {img && (
                        <div
                            className="dropzone-image"
                            style={{
                                backgroundSize: 'contain',
                                backgroundImage: `url('${URL.createObjectURL(
                                    img
                                )}')`,
                            }}
                        />
                    )}
                </div>
            </div>

            <Button
                variant="primary"
                className={`${props.btnclass || 'mt-4'} w-100 btn-lg btn-block`}
                onClick={open}
                style={{ display: displayedImage ? 'none' : 'block' }}
            >
                Upload Photo
            </Button>

            {renderButtons()}

            <ConfirmModal
                show={showConfirmModal}
                toggleModal={toggleModal}
                confirmAction={removePhoto}
                message="Are you sure you want to delete the image?  This action cannot be reversed."
            />
        </>
    )
}

export default React.memo(MyDropzone)
