import React, { useState } from 'react'
import CashflowBar from '../CashflowBar/CashflowBar'
import RecentProjectsSelect from '../Selects/RecentProjectsSelect'
import Icon from '../Icon/Icon'
import ResourcesIcon from '../../../assets/images/icons/ResourcesIcon.svg'
import { BarType } from 'app/enums/dashboard/barType'
import { TRecentProject } from 'lib/api/types/RecentProject'
import { formatChartCurrency } from 'utilities/formatChartCurrency'

const CashflowInformation = () => {
    const [selectedProject, setSelectedProject] =
        useState<TRecentProject | null>(null)
    const total = selectedProject?.budgetTotal || 0
    const moneyIn = selectedProject?.totalPaymentsInFromClient || 0
    const moneyOut = selectedProject?.totalPaidOutToVendors || 0
    const moneyInRemainingCash = total === 0 ? 0 : Math.max(0, total - moneyIn)
    const moneyOutRemainingCash =
        total === 0 ? 0 : Math.max(0, total - moneyOut)
    const netCash = selectedProject ? moneyIn - moneyOut : 0
    const handleSelect = (project: TRecentProject | null) => {
        setSelectedProject(project)
    }

    return (
        <>
            <div className="tw-p-4 tw-bg-white tw-rounded-t-lg tw-shadow-md">
                <div className="tw-flex tw-justify-between tw-mb-4">
                    <div className="tw-flex tw-items-center">
                        <Icon path={ResourcesIcon} iconClass="tw-mr-3" />
                        <h2 className="tw-text-xl tw-font-bold tw-leading-none tw-m-0">
                            Cash Flow
                        </h2>
                    </div>
                    <div className="tw-w-2/4">
                        <RecentProjectsSelect handleSelect={handleSelect} />
                    </div>
                </div>
                <div className="tw-text-2xl tw-font-bold tw-text-gray-900">
                    {formatChartCurrency(netCash)}
                </div>
                <div className="tw-text-sm tw-text-gray-500">
                    {selectedProject?.projectName
                        ? `Net Cash for ${selectedProject.projectName}`
                        : 'Net Cash'}
                </div>
            </div>
            <div className="tw-p-4 tw-bg-white tw-rounded-b-lg tw-shadow-md">
                <CashflowBar
                    barColor="tw-bg-green-100"
                    progressBarColor="tw-bg-[#3b8158]"
                    cashAmount={moneyIn}
                    remainingCash={moneyInRemainingCash}
                    total={total}
                    cashAmountLabel="Money In"
                    barType={
                        selectedProject === null
                            ? BarType.Empty
                            : BarType.MoneyIn
                    }
                    placeholderWidth={'70%'}
                />
                <CashflowBar
                    barColor="tw-bg-[#e7f0f9]"
                    progressBarColor="tw-bg-[#224a9a]"
                    cashAmount={moneyOut}
                    remainingCash={moneyOutRemainingCash}
                    total={total}
                    cashAmountLabel="Money Out"
                    barType={
                        selectedProject === null
                            ? BarType.Empty
                            : BarType.MoneyOut
                    }
                    placeholderWidth={'40%'}
                />
            </div>
        </>
    )
}

CashflowInformation.displayName = 'CashflowInformation'

export default CashflowInformation
