import React from 'react'
import ThreadComponent from '../ThreadComponent'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import Select from 'react-select'
import { ApiService } from '../../../lib/api/HttpService'
import { isEmpty } from 'lodash'
import Spinner from '../../../app/components/help/Spinner'

class CreateClippedItemAsComponent extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            item: {},
            items: [],
            dataIsLoaded: false,
        }

        this.api = new ApiService()
    }

    async componentDidUpdate() {
        if (!this.props.show && !isEmpty(this.state.item)) {
            this.setState({
                item: {},
            })
        }

        if (
            this.props.show &&
            isEmpty(this.state.items) &&
            !isEmpty(this.props.selectedItem)
        ) {
            const itemCapture = await this.api.getClippedItems(
                `/${this.props.selectedItem.itemCaptureId}`
            )

            const projectItems = await this.api.getProjectItems(
                `?$filter=projectid eq ${itemCapture.project.id}`
            )

            const items = projectItems.map((item) => {
                return {
                    value: item.id,
                    label: `${item.item} - ${item.itemName}`,
                }
            })

            this.setState({
                items: items,
                dataIsLoaded: true,
            })
        }

        if (!this.props.show && !isEmpty(this.state.items)) {
            this.setState({
                items: [],
            })
        }
    }

    render() {
        return (
            <Modal
                size="md"
                show={this.props.show}
                onHide={() => this.hide()}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                centered
            >
                {this.state.dataIsLoaded ? <></> : <Spinner></Spinner>}
                <Modal.Body>
                    <Row className="mb-3">
                        <Col className="px-5">
                            <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
                                Create Component
                            </h5>
                            <div className="mx-auto text-center pt-3">
                                <h6>
                                    Select the item to include this as a
                                    component on
                                </h6>
                                <h6 className="fw-bold pb-3">
                                    {this.props.selectedItem?.itemTitle}
                                </h6>
                            </div>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Select item
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        options={this.state.items}
                                        className="react-select"
                                        placeholder="Select item"
                                        value={this.state.item}
                                        onChange={(option) => {
                                            this.setState({
                                                item: option,
                                            })
                                        }}
                                        isDisabled={!this.state.dataIsLoaded}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-3 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    this.props.handleCreateComponent(
                                        this.state.item
                                    )
                                }
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal}
                                className="w-100 m-2"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CreateClippedItemAsComponent
