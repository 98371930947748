import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { getClassNames } from '../../../utilities/DOM'

interface Props {
    children?: any
    className?: string
    expandable?: string
    md?: number
    lg?: number
    xl?: number
    style?: any
}
interface State {
    isBreakPoint: boolean
    isExpand: boolean
}

export class FilterComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            isExpand: false,
            isBreakPoint: false,
        }
    }

    static Fields(props: Props): JSX.Element {
        return (
            <>
                <Col
                    md={props.md || 12}
                    lg={props.lg || 9}
                    {...props}
                    className={getClassNames(
                        props,
                        `filters flex-wrap d-flex align-items-center`
                    )}
                >
                    {props.children}
                </Col>
                <div className="separator filter-separator"></div>
            </>
        )
    }

    static Field(props: Props): JSX.Element {
        return (
            <div
                {...props}
                className={getClassNames(props, `d-flex align-items-center`)}
            >
                {props.children}
            </div>
        )
    }

    static Actions(props: Props): JSX.Element {
        return (
            <Col
                md={props.md || 12}
                lg={props.lg || 3}
                {...props}
                className={getClassNames(
                    props,
                    `actions d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-xl-end`
                )}
            >
                {props.children}
            </Col>
        )
    }

    static Action(props: Props): JSX.Element {
        return (
            <div
                {...props}
                className={getClassNames(props, 'd-flex align-items-center')}
            >
                {props.children}
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <Container fluid>
                <Row
                    {...this.props}
                    className={getClassNames(
                        this.props,
                        `a-filter border-1 border-sand bg-ivory roundedx-4 
                             ${
                                 this.state.isBreakPoint
                                     ? 'list-breakpoint'
                                     : ''
                             } 
                             ${this.state.isExpand ? 'expanded' : ''} 
                             ${
                                 this.props.expandable ||
                                 this.props.expandable === undefined
                                     ? 'has-expand'
                                     : 'no-separator'
                             }`
                    )}
                >
                    {(this.props.expandable ||
                        this.props.expandable === undefined) && (
                        <>
                            <Col xs={12} className="filter-expand">
                                <Button
                                    variant="light"
                                    className="bg-transparent w-100 btn-icon no-outline no-outline-active"
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            isExpand: !this.state.isExpand,
                                        })
                                    }}
                                >
                                    {this.state.isExpand ? 'Hide' : 'Show'} Menu{' '}
                                    <i className="ri-arrow-down-s-line ri-lg"></i>
                                </Button>
                            </Col>
                            <div className="separator expand-separator"></div>
                        </>
                    )}
                    {this.props.children}
                </Row>
            </Container>
        )
    }
}
