export enum DeleteObjectTypes {
    objNone = 0,
    objProject = 1,
    objItem = 2,
    objComponent = 3,
    objLocation = 4,
    objShipTo = 5,
    objSalesTax = 6,
    objSupplier = 7,
    objAccount = 8,
    objSalesCat = 9,
    objEmployee = 18,
    objProposal = 20,
    objPO = 23,
    objTimeActivity = 30,
    objClient = 39,
    objOrder = 48,
    objQuote = 49,
    objInventoryPO = 46,
    objInventoryItem = 40,
}
