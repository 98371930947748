import React from 'react'
import { useQuery } from '@tanstack/react-query'
import CreatableSelect from 'react-select/creatable'

import { ApiService } from '../../../lib/api/HttpService'

const api = new ApiService()

/**
 * Props:
 *      * urlQuery: String -  query selector.
 *      * defaultValue: Object - { value: any, isNew: boolean, label: string }
 *          - isNew is to check if new record is created so the query will be refreshed or does not stale (using the value as the parameter)
 *      * ...the rest is based on the `react-select` attributes
 *
 *
 * IMPORTANT:
 *      When adding new vendor via Quick Add Vendor,
 *      please add delay on the success due to the delay from
 *      the API to reflect the newly added vendor.
 */

const getVendors = async (qry = '') => await api.getVendorsSummaries(qry)
const getUrlQuery = (props) => props.urlQuery ?? '?$filter=inactive eq false'

const VendorsDropdown = (props) => {
    const svalue = props.defaultValue
    const newId = svalue?.isNew ? svalue?.value : null
    let defaultValue = null

    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'vendors', getUrlQuery(props), newId],
        queryFn: getVendors.bind(this, getUrlQuery(props)),
    })

    if (isLoading) {
        return (
            <CreatableSelect
                placeholder="Loading..."
                options={[{ label: 'Loading...', value: '' }]}
                isDisabled={true}
            />
        )
    }

    const options = data?.map(({ key, value }) => {
        const option = {
            label: `${value} [${key}]`,
            value: key,
        }

        if (svalue?.value === key) {
            defaultValue = option
        }

        return option
    })

    return (
        <CreatableSelect
            {...props}
            {...{ value: defaultValue }}
            options={options}
        />
    )
}

export default VendorsDropdown
