import { BudgetBannerContext } from 'app/context/BudgetBannerContext'
import { useContext } from 'react'

export const useBudgetBanner = () => {
    const context = useContext(BudgetBannerContext)
    if (context === undefined) {
        throw new Error(
            'useBudgetBanner must be used within a BudgetBannerProvider'
        )
    }
    return context
}
