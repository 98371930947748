import React from 'react'
import { Outlet } from 'react-router-dom'

function LoginLayout() {
    return (
        <>
            <Outlet />
        </>
    )
}

export default LoginLayout
