import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Row, Col, Form, Breadcrumb, Container, Button } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import URI from '../../../defaults/RoutesDefault'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { HeaderLight } from '../../components/Header'
import { FooterFormAction, InlineFormGroupField } from '../../components/Form'
import { fakeReactSelectOptions } from '../../factory/fakeFactory'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'

class TaskAppointmentAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeMenu: 'info',
        }

        this.handleMenuClick = this.handleMenuClick.bind(this)
    }

    componentInit() {
        this.title = 'New Appointment'
        this.setFormAction(true)
    }

    handleMenuClick = (e) => {
        e.preventDefault()

        this.setState(
            (prevState) =>
                (prevState.activeMenu = e.target.getAttribute('data-menu'))
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.timeManagement.appointment}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.timeManagement.appointment }}
                            linkAs={Link}
                        >
                            Tasks & Appointments
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>To Do List Task</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>
                        To Do List Appointment
                    </HeaderLight.Title>

                    <div>
                        {/* Submit Button */}
                        <Button variant="primary me-2">Save & New</Button>

                        <Button variant="primary">Save</Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        return (
            <Container className="ms-0">
                <Row className="mb-4">
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="Subject"
                                controlId="formAppointmentSubject"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                />
                            </InlineFormGroupField>

                            <InlineFormGroupField
                                label="Location"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                />
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="Start Date"
                                controlId="formAppointmentStartDate"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                />

                                <Col sm="5" className="mt-3 mt-sm-0">
                                    <Select
                                        options={fakeReactSelectOptions()}
                                        className="react-select"
                                        placeholder="Please select"
                                    />
                                </Col>
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="End Date"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                />

                                <Col sm="5" className="mt-3 mt-sm-0">
                                    <Select
                                        options={fakeReactSelectOptions()}
                                        className="react-select"
                                        placeholder="Please select"
                                    />
                                </Col>
                            </InlineFormGroupField>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-fields ps-lg-4">
                            <InlineFormGroupField
                                label="Importance"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="Reminder"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                                className="fw-500 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end pt-sm-2"
                            >
                                <DatePicker
                                    selected={this.state.startDate}
                                    minDate={this.state.minDate}
                                    onChange={this.handleDateChange}
                                    className="form-control"
                                    placeholderText="Opened"
                                />

                                <Col
                                    xs={12}
                                    sm={5}
                                    lg={5}
                                    as={Row}
                                    className="mt-3 mt-sm-0"
                                >
                                    <Col
                                        sm={12}
                                        xxl={6}
                                        className="d-flex pe-3 align-items-center"
                                        style={{ width: '138px' }}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="00"
                                        />
                                        <strong className="px-2">:</strong>
                                        <Form.Control
                                            type="text"
                                            placeholder="00"
                                        />
                                    </Col>
                                    <Col
                                        sm={12}
                                        xxl={6}
                                        className="d-flex align-items-center justify-content-between pt-2"
                                        style={{ maxWidth: '140px' }}
                                    >
                                        <Form.Check
                                            inline
                                            defaultChecked={true}
                                            label="AM"
                                            name="appointment-time-code"
                                            type="radio"
                                            id={`appointment-time-code-1`}
                                            className="me-2 fw-600"
                                        />
                                        <Form.Check
                                            inline
                                            label="PM"
                                            name="appointment-time-code"
                                            type="radio"
                                            id={`appointment-time-code-2`}
                                            className="fw-600"
                                        />
                                    </Col>
                                </Col>
                            </InlineFormGroupField>
                        </div>

                        <div className="form-fields">
                            <InlineFormGroupField
                                label=""
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Form.Check
                                    inline
                                    label="Set a reminder"
                                    name="radio-setareminder"
                                    type="checkbox"
                                    id={`inline-radio-setareminder`}
                                    className="fw-600"
                                />
                            </InlineFormGroupField>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-fields ps-lg-4">
                            <InlineFormGroupField
                                label="Recurrence"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>
                        </div>
                        <div className="form-fields ps-lg-4">
                            <InlineFormGroupField
                                label="Number of Times"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="Contact Type"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>

                            <InlineFormGroupField
                                label="Code"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>

                            <InlineFormGroupField
                                label="This is for"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>

                            <InlineFormGroupField
                                label=""
                                controlId="formAppointmentCheck"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={5}
                            >
                                <div>
                                    <Form.Check
                                        inline
                                        label="Copy Me"
                                        name="radio-copyme"
                                        type="checkbox"
                                        id={`inline-radio-copyme`}
                                        className="d-block fw-600 mb-2"
                                    />
                                    <Form.Check
                                        inline
                                        label="Private"
                                        name="radio-private"
                                        type="checkbox"
                                        id={`inline-radio-private`}
                                        className="d-block fw-600"
                                    />
                                </div>
                            </InlineFormGroupField>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-fields ps-lg-4">
                            <InlineFormGroupField
                                label="Description"
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                                colField={10}
                                className="fw-500 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end pt-sm-2"
                            >
                                <WysiwygEditor></WysiwygEditor>
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col lg={6}>
                        <div className="form-fields">
                            <InlineFormGroupField
                                label="Link to Project"
                                controlId="formAppointmentSubject"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>

                            <InlineFormGroupField
                                label="Link to Item No."
                                controlId="formAppointmentLocation"
                                classNameParent="mb-3"
                                {...this.formGroupField}
                            >
                                <Select
                                    options={fakeReactSelectOptions()}
                                    className="react-select"
                                    placeholder="Please select"
                                />
                            </InlineFormGroupField>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderSubmitButton() {
        return (
            <FooterFormAction>
                <Button variant="primary" size="lg">
                    Save
                </Button>
            </FooterFormAction>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {this.renderSubmitButton()}
            </>
        )
    }
}

export default WithRouter(TaskAppointmentAdd)
