import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import VendorAddEditViewModel from '../VendorAddEditViewModel'
import { InsuranceService } from '../api/InsuranceService'
import { VendorService } from '../api/VendorService'
import useVendor from '../../../../hooks/useVendor'

interface TypeOption {
    value: number
    label: string
}

interface DefaultTypes {
    usediscountmerc?: number
    usediscountdesign?: number
    usediscountfreight?: number
    usediscountinstall?: number
    usediscountlabor?: number
    markupmerc?: string
    markupdesign?: string
    markupfreight?: string
    markupinstall?: string
    markuplabor?: string
    usemarkupspecs?: boolean
    usemarkupinvcat?: boolean
}

interface Props {
    data?: DefaultTypes
    handleChange: Function
    setIsDraft: Function
}

const options = [
    {
        value: -1,
        label: 'Discount',
    },
    {
        value: 0,
        label: 'Markup',
    },
    {
        value: 1,
        label: 'Fee',
    },
]

const VendorGeneralDefaults: React.FC<Props> = ({
    handleChange,
    setIsDraft,
}) => {
    const [defaultTypes, setDefaultTypes] = useState<DefaultTypes>({})
    const vendorAddEditModel = new VendorAddEditViewModel(
        new VendorService(),
        new InsuranceService()
    )
    const vendorData = useVendor()

    const handleSelectChange = (selectedOption: TypeOption, name: string) => {
        setDefaultTypes((prev) => ({ ...prev, [name]: selectedOption?.value }))

        const fakeEvent = {
            target: {
                name: name,
                value: selectedOption.value ?? null,
            },
        }
        handleChange(fakeEvent, selectedOption)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = event.target
        const isCheckbox = type === 'checkbox'
        setDefaultTypes((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }))

        const fieldValue = isCheckbox ? checked : Number(value)
        vendorAddEditModel.setVendorData(name, fieldValue)
        setIsDraft()
    }

    useEffect(() => {
        vendorData && setDefaultTypes(vendorData)
    }, [])

    return (
        <Fragment>
            <Row>
                <Col className="mb-3 mb-lg-0">
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0" />
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Label className="mb-0">
                                        <strong>% Type </strong>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className="mb-0">
                                        <strong>Percentage</strong>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">
                                Merchandise
                            </Form.Label>
                        </Col>
                        <Col>
                            <Row>
                                <Col lg={6}>
                                    <Select
                                        options={options}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="usediscountmerc"
                                        value={
                                            options.filter((item) => {
                                                return (
                                                    item.value ==
                                                    defaultTypes.usediscountmerc
                                                )
                                            }) || null
                                        }
                                        onChange={(option) =>
                                            handleSelectChange(
                                                option as TypeOption,
                                                'usediscountmerc'
                                            )
                                        }
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please enter"
                                        name="markupmerc"
                                        value={defaultTypes.markupmerc ?? '0'}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Freight Row */}
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">Freight</Form.Label>
                        </Col>
                        <Col>
                            <Row xs={2}>
                                <Col>
                                    <Select
                                        options={options}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="usediscountdesign"
                                        value={options.filter((item) => {
                                            return (
                                                item.value ==
                                                defaultTypes.usediscountdesign
                                            )
                                        })}
                                        onChange={(option) =>
                                            handleSelectChange(
                                                option as TypeOption,
                                                'usediscountdesign'
                                            )
                                        }
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please enter"
                                        name="markupdesign"
                                        value={defaultTypes.markupdesign ?? '0'}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Design Fee Row */}
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">Design Fee</Form.Label>
                        </Col>
                        <Col>
                            <Row xs={2}>
                                <Col>
                                    <Select
                                        options={options}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="usediscountfreight"
                                        value={options.filter((item) => {
                                            return (
                                                item.value ==
                                                defaultTypes.usediscountfreight
                                            )
                                        })}
                                        onChange={(option) =>
                                            handleSelectChange(
                                                option as TypeOption,
                                                'usediscountfreight'
                                            )
                                        }
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please enter"
                                        name="markupfreight"
                                        value={
                                            defaultTypes.markupfreight ?? '0'
                                        }
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Install/Labor Row */}
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">
                                Install/Labor
                            </Form.Label>
                        </Col>
                        <Col>
                            <Row xs={2}>
                                <Col>
                                    <Select
                                        options={options}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="usediscountinstall"
                                        value={options.filter((item) => {
                                            return (
                                                item.value ==
                                                defaultTypes.usediscountinstall
                                            )
                                        })}
                                        onChange={(option) =>
                                            handleSelectChange(
                                                option as TypeOption,
                                                'usediscountinstall'
                                            )
                                        }
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please enter"
                                        name="markupinstall"
                                        value={
                                            defaultTypes.markupinstall ?? '0'
                                        }
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">Crating</Form.Label>
                        </Col>
                        <Col>
                            <Row xs={2}>
                                <Col>
                                    <Select
                                        options={options}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="usediscountlabor"
                                        value={options.filter((item) => {
                                            return (
                                                item.value ==
                                                defaultTypes.usediscountlabor
                                            )
                                        })}
                                        onChange={(option) =>
                                            handleSelectChange(
                                                option as TypeOption,
                                                'usediscountlabor'
                                            )
                                        }
                                    />
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please enter"
                                        name="markuplabor"
                                        value={defaultTypes.markuplabor ?? '0'}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="align-items-start mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
                        <Col className="pe-5">
                            <Form.Check
                                inline
                                label="Apply Vendor Markup to Specifications (Override Project Defaults for Items/Components)"
                                type="checkbox"
                                id={`checkbox-group-vm-1`}
                                className="vtop mb-3"
                                name="usemarkupspecs"
                                checked={defaultTypes.usemarkupspecs || false}
                                onChange={handleInputChange}
                            />
                            <Form.Check
                                inline
                                label="Apply Vendor Markup to Inventory and Catalog Items (Only Merchandise Markup is used to Inventory, other settings are ignored)"
                                type="checkbox"
                                id={`checkbox-group-vm-2`}
                                className="vtop"
                                name="usemarkupinvcat"
                                checked={defaultTypes.usemarkupinvcat || false}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

VendorGeneralDefaults.displayName = 'VendorGeneralDefaults'

export default VendorGeneralDefaults
