import React from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import bold from '../../assets/images/bold.svg'
import italic from '../../assets/images/italic.svg'
import underline from '../../assets/images/underline.svg'
import colour from '../../assets/images/colour.svg'
import './WysiwygEditor.css'

/**
 * NNOTE: This script has a warning.
 * Can't call setState on a component that is not yet mounted. This is a no-op, but it might indicate a bug in your application. Instead, assign to `this.state` directly or define a `state = {};` class property with the desired state in the i component.
 */
class WysiwygEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    htmlToDraft(this.props.children || '')
                )
            ),
        }
    }

    componentDidMount() {
        if (this.props.children && this.props.triggerChangeOnLoad) {
            this.onEditorStateChange(this.state.editorState)
            return
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (
            this.props.children &&
            previousProps.children !== this.props.children
        ) {
            this.setState({
                editorState: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        htmlToDraft(this.props.children)
                    )
                ),
            })
        }
    }

    replaceText(element) {
        for (let childNode of element.childNodes) {
            if (childNode.nodeType === Node.TEXT_NODE) {
                childNode.data = childNode.data.replace(/ /g, '\u00a0')
                continue
            }

            // Recursively replace text for child node
            this.replaceText(childNode)
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
        })

        const plainContent = editorState.getCurrentContent().getPlainText()
        //const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const htmlObject = document.createElement(null)
        htmlObject.innerHTML = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        )
        this.replaceText(htmlObject)

        let html = htmlObject.innerHTML
        html = html.replaceAll('<ins>', '<u>')
        html = html.replaceAll('</ins>', '</u>')

        if (!this.props.keepEmptySpaces) {
            html = html.replaceAll('<p style="text-align:left;">&nbsp;</p>', '')
            html = html.replaceAll('<p style="text-align:left;"></p>', '')
        }

        if (this.props.onChange) {
            this.props.onChange({
                target: {
                    value: plainContent,
                    id: this.props.id,
                    html: plainContent === '' ? '' : html,
                },
            })
        }
    }

    render() {
        const { editorState } = this.state

        return (
            <Editor
                toolbarHidden={this.props.toolbarHidden}
                readOnly={this.props.isReadOnly}
                spellCheck={true}
                editorState={editorState}
                wrapperClassName={`${this.props.customWrapperClass} demo-wrapper`}
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                placeholder={'Start typing...'}
                toolbar={{
                    options: ['inline', 'colorPicker'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        options: ['bold', 'italic', 'underline'],
                        bold: { icon: bold, className: undefined },
                        italic: { icon: italic, className: undefined },
                        underline: { icon: underline, className: undefined },
                    },
                    colorPicker: {
                        icon: colour,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: [
                            'rgb(97,189,109)',
                            'rgb(26,188,156)',
                            'rgb(84,172,210)',
                            'rgb(44,130,201)',
                            'rgb(147,101,184)',
                            'rgb(71,85,119)',
                            'rgb(204,204,204)',
                            'rgb(65,168,95)',
                            'rgb(0,168,133)',
                            'rgb(61,142,185)',
                            'rgb(41,105,176)',
                            'rgb(85,57,130)',
                            'rgb(40,50,78)',
                            'rgb(0,0,0)',
                            'rgb(247,218,100)',
                            'rgb(251,160,38)',
                            'rgb(235,107,86)',
                            'rgb(226,80,65)',
                            'rgb(163,143,132)',
                            'rgb(239,239,239)',
                            'rgb(255,255,255)',
                            'rgb(250,197,28)',
                            'rgb(243,121,52)',
                            'rgb(209,72,65)',
                            'rgb(184,49,47)',
                            'rgb(124,112,107)',
                            'rgb(209,213,216)',
                        ],
                    },
                }}
            />
        )
    }
}

export default WysiwygEditor
