import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

class RadioCheckbox extends React.Component {
    render() {
        return (
            <>
                <div className="bg-secondary-grey pt-5 pb-4 mt-5">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="ui-title">Checkbox/Radiobox</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="mt-4">
                    <Row style={{ maxWidth: '600px' }}>
                        <Col>
                            <div className="d-block mt-4">
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    label="Label"
                                    name="group1"
                                    type="checkbox"
                                    id={`inline-radio-1`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    label="Label"
                                    name="group2"
                                    type="checkbox"
                                    id={`inline-radio-2`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    disabled
                                    label="Label"
                                    name="group4"
                                    type="checkbox"
                                    id={`inline-radio-4`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    disabled
                                    label="Label"
                                    name="group5"
                                    type="checkbox"
                                    id={`inline-radio-5`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    isInvalid
                                    label="Label"
                                    name="group6"
                                    type="checkbox"
                                    id={`inline-radio-6`}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="d-block mt-4">
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    label="Label"
                                    name="group11"
                                    type="checkbox"
                                    id={`inline-radio-11`}
                                    className="circle-check"
                                />
                                <br />
                                <Form.Check
                                    inline
                                    label="Label"
                                    name="group21"
                                    type="checkbox"
                                    id={`inline-radio-21`}
                                    className="circle-check"
                                />
                                <br />
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    disabled
                                    label="Label"
                                    name="group41"
                                    type="checkbox"
                                    id={`inline-radio-41`}
                                    className="circle-check"
                                />
                                <br />
                                <Form.Check
                                    inline
                                    disabled
                                    label="Label"
                                    name="group51"
                                    type="checkbox"
                                    id={`inline-radio-51`}
                                    className="circle-check"
                                />
                                <br />
                                <Form.Check
                                    inline
                                    isInvalid
                                    label="Label"
                                    name="group61"
                                    type="checkbox"
                                    id={`inline-radio-61`}
                                    className="circle-check"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="d-block mt-4">
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    label="Label"
                                    name="grouprd-1"
                                    type="radio"
                                    id={`inline-radio-rd-1`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    label="Label"
                                    name="group-rd-2"
                                    type="radio"
                                    id={`inline-radio-rd-2`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    disabled
                                    label="Label"
                                    name="group-rd-4"
                                    type="radio"
                                    id={`inline-radio-rd-4`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    disabled
                                    label="Label"
                                    name="group-rd-5"
                                    type="radio"
                                    id={`inline-radio-rd-5`}
                                />
                                <br />
                                <Form.Check
                                    inline
                                    isInvalid
                                    label="Label"
                                    name="group-rd-6"
                                    type="radio"
                                    id={`inline-radio-rd-6`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default RadioCheckbox
