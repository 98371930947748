import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom'
import Collapse from 'react-bootstrap/Collapse'

import logo from '../../assets/images/logo.png'

class SampleNavigation2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isToggleRelationship: true,
            isToggleInventory: false,
            isToggleTimeManagement: false,
        }

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = (e) => {
        e.preventDefault()
        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState) => {
            let data = {}
            data[id] = !prevState[id]

            return data
        })
    }

    render() {
        // IMPORTANT NOTE: Please remove `relative-navigation` class name
        // If this will be used as a fixed sidebar left.
        return (
            <Navbar
                bg="dark"
                expand="lg"
                variant="dark"
                className="sidemenu relative-navigation"
            >
                <Navbar.Brand href="/" className="d-flex w-100">
                    <Image src={logo} className="me-auto"></Image>
                </Navbar.Brand>
                <Nav className="side-nav">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-home-fill fsx-24"></i> Home
                    </NavLink>
                    <NavLink
                        to="projects"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-stack-fill fsx-24"></i> Projects
                    </NavLink>
                    <NavLink
                        to="clipper"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-clipboard-fill fsx-24"></i> Clipper
                    </NavLink>

                    <NavLink
                        to="relationships"
                        className={({ isActive }) =>
                            [
                                isActive ? 'active' : null,
                                'nav-link fw-bold has-collapse',
                                this.state.isToggleRelationship
                                    ? 'nav-link-show'
                                    : null,
                            ]
                                .filter(Boolean)
                                .join(' ')
                        }
                        data-toggle="collapse"
                        data-state="isToggleRelationship"
                        data-target="#isToggleRelationship"
                        aria-controls="isToggleRelationship"
                        aria-expanded={this.state.isToggleRelationship}
                        onClick={this.handleClick}
                    >
                        <i className="ri-group-fill fsx-24"></i> Relationships
                    </NavLink>
                    <Collapse in={this.state.isToggleRelationship}>
                        <ul className="nav-second-level">
                            <NavLink
                                to="relationships/clients"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-group-fill fsx-24"></i> Clients
                            </NavLink>
                            <NavLink
                                to="relationships/vendors"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-briefcase-4-fill fsx-24"></i>{' '}
                                Vendors
                            </NavLink>
                            <NavLink
                                to="relationships/employees"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-account-box-fill fsx-24"></i>{' '}
                                Employees
                            </NavLink>
                        </ul>
                    </Collapse>

                    <NavLink
                        to="inventory"
                        className={({ isActive }) =>
                            [
                                isActive ? 'active' : null,
                                'nav-link fw-bold has-collapse',
                                this.state.isToggleInventory
                                    ? 'nav-link-show'
                                    : null,
                            ]
                                .filter(Boolean)
                                .join(' ')
                        }
                        data-toggle="collapse"
                        data-state="isToggleInventory"
                        data-target="#isToggleInventory"
                        aria-controls="isToggleInventory"
                        aria-expanded={this.state.isToggleInventory}
                        onClick={this.handleClick}
                    >
                        <i className="ri-archive-fill fsx-24"></i> Inventory
                    </NavLink>
                    <Collapse in={this.state.isToggleInventory}>
                        <ul className="nav-second-level">
                            <NavLink
                                to="inventory/catalogs"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-book-2-fill fsx-24"></i>{' '}
                                Catalog
                            </NavLink>
                            <NavLink
                                to="inventory/stock-items"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-stock-fill fsx-24"></i> Stock
                                Items
                            </NavLink>
                        </ul>
                    </Collapse>

                    <NavLink
                        to="time-management"
                        className={({ isActive }) =>
                            [
                                isActive ? 'active' : null,
                                'nav-link fw-bold has-collapse',
                                this.state.isToggleTimeManagement
                                    ? 'nav-link-show'
                                    : null,
                            ]
                                .filter(Boolean)
                                .join(' ')
                        }
                        data-toggle="collapse"
                        data-state="isToggleTimeManagement"
                        data-target="#isToggleTimeManagement"
                        aria-controls="isToggleTimeManagement"
                        aria-expanded={this.state.isToggleTimeManagement}
                        onClick={this.handleClick}
                    >
                        <i className="ri-time-fill fsx-24"></i> Time Management
                    </NavLink>
                    <Collapse in={this.state.isToggleTimeManagement}>
                        <ul className="nav-second-level">
                            <NavLink
                                to="time-management/task-appointments"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-book-mark-fill fsx-24"></i>{' '}
                                Task {'&'} Appointments
                            </NavLink>
                            <NavLink
                                to="time-management/review"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-mail-send-fill fsx-24"></i>{' '}
                                Review & Send
                            </NavLink>
                            <NavLink
                                to="time-management/calendar"
                                className={({ isActive }) =>
                                    [
                                        isActive ? 'active' : null,
                                        'nav-link fw-bold',
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                <i className="ri-calendar-fill fsx-24"></i>{' '}
                                Calendar
                            </NavLink>
                        </ul>
                    </Collapse>

                    <NavLink
                        to="reporting"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-file-chart-fill fsx-24"></i> Reporting
                    </NavLink>
                    <NavLink
                        to="glossaries"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-book-fill fsx-24"></i> Glossaries
                    </NavLink>
                </Nav>

                <Nav className="side-nav at-bottom">
                    <NavLink
                        to="settings"
                        className={({ isActive }) =>
                            [isActive ? 'active' : null, 'nav-link fw-bold']
                                .filter(Boolean)
                                .join(' ')
                        }
                    >
                        <i className="ri-settings-4-fill fsx-24"></i> Settings
                    </NavLink>
                </Nav>
            </Navbar>
        )
    }
}

export default SampleNavigation2
