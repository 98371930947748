import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Breadcrumb,
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import CashReceiptModal from './CashReceiptModal'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import InputBar from '../../../components/InputBar'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import dayjs from 'dayjs'
import DepositWarningModal from '../../modal/DepositWarningModal'
import { Is } from '../../../../helpers/Util'
import MSG from '../../../../defaults/Message'
import { delay, isEmpty } from 'lodash'
import { getCheckAllClass } from '../../../../utilities/ModuleHelper'
import { setDraft } from '../../../../utilities/FormEvent'
import { currencyFormat } from '../../../../helpers/Number'
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting'
import CashReceiptPaymentModal from './CashReceiptPaymentModal'
import AsyncProjectsDropdown from '../../../../app/components/dropdowns/AsyncProjectsDropdown'
import AsyncClientsDropdown from '../../../../app/components/dropdowns/AsyncClientsDropdown'
import { formatDate } from '../../../../helpers/Date'

class CashReceiptAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            activeType: '',
            activeCashReceipt: {},
            activeCashReceiptPayment: {},
            data: { amount: 0 },
            checks: {
                deposits: {},
                payments: {},
            },
            totalDepositChecked: 0,
            totalPaymentChecked: 0,
            isShowModal: false,
            isShowPaymentModal: false,
            paymentTypes: [],
            deposits: [],
            payments: [],
            totalDeposits: 0,
            totalPayments: 0,
            totalReceipt: 0,
            totalDifference: 0,
            updatedData: {
                userdate: new Date().toISOString(),
                depositslip: '',
                amount: 0,
            },
            showWarningModal: false,
            checkedIndex: -1,
            checkedPropId: 0,
            showAllDeposits: false,
            showAllPayments: false,
            clientQry: '',
        }
        this.api = new ApiService()
        this.id = this.props.params.id
        this.userCode = getCookie('dmUsercode')

        this.setIsLoaded(true)
        this.initReadOnlyPros()
    }

    initReadOnlyPros() {
        this.saveSuccessMessage = this.isEditing
            ? MSG.success.msg
            : MSG.success.create.ARCashReceipt
        this.saveLoadingMessage = this.isEditing
            ? MSG.loading.update.msg
            : MSG.loading.create.ARCashReceipt
    }

    componentInit() {
        this.isEditing = this.props.params.id != null
        this.title = `${this.isEditing ? 'Edit' : 'Add'} Cash Receipt`
        this.setFormAction(true)
    }

    async componentDidMount() {
        this.dMloader(true)
        try {
            const paymentTypes = await this.api.getPaymentTypes()

            if (this.isEditing) {
                const dataResponse = await this.api.getNewCashReceipts(
                    `?$filter=groupnum eq ${this.id} and usercode eq ${this.userCode}`
                )
                const data = dataResponse[0]

                if (isEmpty(data)) {
                    throw {
                        response: {
                            status: 404,
                        },
                    }
                }
                data.proj = data.invProp.split(' - ')[0]
                await this.selectedProject(data.proj)
                await this.selectedClient(data.fClient)
                this.setState(
                    {
                        updatedData: {
                            ...this.state.updatedData,
                            ...{
                                proj: data.proj,
                                client: data.fClient,
                            },
                        },
                    },
                    async () => {
                        await this.createGrids()

                        const filter = `?$filter=usercode eq ${this.userCode} and postaxable eq true&$orderby=previnfo asc`
                        const deposits = await this.api.getCashReceiptsDeposits(
                            filter
                        )
                        const payments = await this.api.getCashReceiptsPayments(
                            filter
                        )

                        const checks = {
                            deposits: {},
                            payments: {},
                        }

                        deposits.forEach((deposit, index) => {
                            if (deposit.tag) {
                                checks.deposits[index] = deposit.tag
                            }
                        })

                        payments.forEach((payment, index) => {
                            if (payment.tag) {
                                checks.payments[index] = payment.tag
                            }
                        })

                        if (!data) {
                            window.location.href = routeParam(
                                URI.accountsReceivable.cashReceipt.listNew,
                                {}
                            )
                            return
                        }

                        this.setState(
                            {
                                updatedData: this.mapData(data),
                                deposits: deposits,
                                payments: payments,
                                checks: checks,
                            },
                            () => {
                                this.calcTotals()
                                tableSortingEnableSort({
                                    data: deposits,
                                    classRef: this,
                                    targetTable:
                                        '.a-table-heading.deposit .sort',
                                    stateKey: 'deposits',
                                })

                                tableSortingEnableSort({
                                    data: payments,
                                    classRef: this,
                                    targetTable:
                                        '.a-table-heading.payment .sort',
                                    stateKey: 'payments',
                                })
                                delay(() => this.dMloader(false), 1200)
                            }
                        )
                    }
                )
            } else {
                this.dMloader(false)
            }

            this.setState({
                paymentTypes: paymentTypes.map((paymentType) => {
                    return {
                        value: paymentType.type,
                        label: paymentType.type,
                        undeposited: paymentType.undeposited,
                    }
                }),
            })
        } catch (error) {
            this.dMloader(false)
            this.setIsFound(false)
            this.setError(error)
            this.setState({
                updated: Math.random(10),
            })
            displayAlertError(getErrorMessage(error))
        }
    }

    shouldRenderDepositSlip() {
        return (
            this.state.paymentTypes.find(
                (type) => type.value === this.state.updatedData?.paytype
            )?.undeposited === false
        )
    }

    mapData(data) {
        return {
            usercode: data.usercode,
            depositslip: data.fDepositSlip ?? '',
            checknum: data.fCheckNum,
            amount: data.fAmount,
            proj: data.proj,
            userdate: data.fUserDate,
            fiscalmonth: data.fFiscalMonth,
            paytype: data.fPayType,
            groupnum: data.groupnum,
            client: data.fClient,
            invnum: data.groupnum,
        }
    }

    async onCheckUpdateDepositTag(deposit, isTag) {
        try {
            const data = {
                tag: isTag,
                usercode: this.userCode,
                propnum: deposit.previnfo,
                proj: deposit.proj,
                orgpayee: deposit.orgpayee,
            }

            const clientDep = await this.updateClientDeposit(data)
            if (clientDep.warningMessage) {
                this.setState({
                    showWarningModal: true,
                    checkedPropId: deposit.previnfo,
                })
            }
        } catch (error) {
            // Ignore this error.
        }
    }

    async updateClientDeposit(data) {
        return await this.api.updateCashReceiptClientDeposits(data)
    }

    handleDepositsChecks = async (e) => {
        const indx = e.target.getAttribute('data-id')
        const deposits = this.state.deposits
        deposits[indx].tag = e.target.checked

        this.onCheckUpdateDepositTag(deposits[indx], e.target.checked)

        this.setState(
            (prevState) => {
                prevState.checkedIndex = indx
                prevState.deposits = deposits
                prevState.checks.deposits[indx] = e.target.checked
                prevState.totalDepositChecked = Object.values(
                    prevState.checks.deposits
                ).reduce((a, item) => a + item, 0)

                return prevState
            },
            () => {
                this.calcTotals()
            }
        )
    }

    handleSelectAllDepositChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        for (const deposit of this.state.deposits) {
            this.onCheckUpdateDepositTag(deposit, isChecked)
        }

        this.setState((prevState) => {
            if (isChecked) {
                const totalDepositChecked = Object.values(
                    prevState.checks.deposits
                ).reduce((a, item) => a + item, 0)

                if (totalDepositChecked < this.state.deposits.length) {
                    this.state.deposits.map((item, i) => {
                        prevState.checks.deposits[i] = true
                    })
                }

                prevState.totalDepositChecked = this.state.deposits.length
            } else {
                prevState.totalDepositChecked = 0
                Object.keys(prevState.checks.deposits).forEach(
                    (i) => (prevState.checks.deposits[i] = false)
                )
            }

            return prevState
        }, this.calcTotals)
    }

    async onCheckUpdatePaymentTag(payment, isTag) {
        try {
            await this.api.updateCashReceiptPayments({
                tag: isTag,
                usercode: this.userCode,
                invnum: payment.previnfo,
                proj: payment.proj,
                orgpayee: payment.orgpayee,
                amount: payment.amtdue,
            })
        } catch (error) {
            // This is an error
        }
    }

    handlePaymentsChecks = (e) => {
        this.checkPayment(e.target.getAttribute('data-id'), e.target.checked)
    }

    checkPayment = (index, checked) => {
        const payments = this.state.payments

        if (!payments[index]) return

        payments[index].tag = checked

        this.onCheckUpdatePaymentTag(payments[index], checked)

        this.setState(
            (prevState) => {
                prevState.payments = payments
                prevState.checks.payments[index] = checked
                prevState.totalPaymentChecked = Object.values(
                    prevState.checks.payments
                ).reduce((a, item) => a + item, 0)

                return prevState
            },
            () => {
                this.calcTotals()
            }
        )
    }

    handleSelectAllPaymentsChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        for (const payment of this.state.payments) {
            this.onCheckUpdatePaymentTag(payment, isChecked)
        }

        this.setState((prevState) => {
            if (isChecked) {
                const totalPaymentChecked = Object.values(
                    prevState.checks.payments
                ).reduce((a, item) => a + item, 0)

                if (totalPaymentChecked < this.state.payments.length) {
                    this.state.payments.map((item, i) => {
                        prevState.checks.payments[i] = true
                    })
                }
                prevState.totalPaymentChecked = this.state.payments.length
            } else {
                prevState.totalPaymentChecked = 0
                Object.keys(prevState.checks.payments).forEach(
                    (i) => (prevState.checks.payments[i] = false)
                )
            }

            return prevState
        }, this.calcTotals)
    }

    handleShowAllCheck = (e) => {
        const key = e.target.name
        const value = e.target.checked
        this.setState(
            {
                [key]: value,
            },
            this.fetchCashReceiptData
        )
    }

    handleChange = (e, meta = {}, callback = () => {}) => {
        let key, value
        if (meta && e?.hasOwnProperty('value') && e?.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e?.value
            if (key === 'paytypedata') {
                this.setState(
                    {
                        updatedData: {
                            ...this.state.updatedData,
                            [key]: e,
                            depositslip: e?.value,
                            paytype: e?.value,
                        },
                    },
                    () => callback()
                )
                return
            }
        } else if (e?.hasOwnProperty('target')) {
            // Form
            key = e?.target.attributes['data-field'].value
            if (e?.target.hasOwnProperty('value')) {
                value = e?.target.value
            } else if (e?.target.hasOwnProperty('checked')) {
                value = e?.target.checked
            }
        }

        setDraft(true)

        this.setState(
            {
                updatedData: {
                    ...this.state.updatedData,
                    [key]: value,
                },
            },
            () => callback()
        )
    }

    async createGrids() {
        const projCode = this.state.selectedProject?.value ?? ''
        const clientCode = this.state.selectedClient?.value ?? ''
        const project = await this.api.getProjectByCode(projCode)
        const client = await this.api.getClientByCode(clientCode)

        let data = {}

        if (this.props.params.id) {
            data.editGroupNumber = this.props.params.id
        }

        if (project) {
            data.projectId = project.id
        }

        if (client) {
            data.clientId = client.id
        }

        return this.api.createCashReceiptGrids(data)
    }

    handleProjectOrClientChange = (e, meta = {}) => {
        setDraft(true)
        if (meta.name === 'proj')
            this.setState({
                selectedProject: e,
            })
        else
            this.setState({
                selectedClient: e,
            })

        this.handleChange(e, meta, async () => {
            this.fetchCashReceiptData()
        })
    }

    handleAmountChange = (e, meta = {}) => {
        setDraft(true)
        this.handleChange(e, meta, () => {
            this.calcTotals()
        })
    }

    async fetchCashReceiptData(callback = () => {}) {
        this.reset()
        this.dMloader(true)

        try {
            await this.createGrids()
            let filter = `?$filter=usercode eq ${this.userCode}`
            if (!this.state.showAllDeposits) {
                filter += ` and postaxable eq true`
            }
            let deposits = await this.api.getCashReceiptsDeposits(
                `${filter}&$orderby=previnfo asc`
            )
            if (!this.state.showAllDeposits) {
                deposits = deposits.filter(
                    (deposit) =>
                        deposit.invamt > deposit.paidtodate ||
                        deposit.posdesc.includes('Use to enter retainer for')
                )
            }

            filter = `?$filter=usercode eq ${this.userCode}`
            if (!this.state.showAllPayments) {
                filter += ` and postaxable eq true and amtdue ne 0`
            }
            let payments = await this.api.getCashReceiptsPayments(
                `${filter}&$orderby=previnfo asc`
            )

            // Let's filter and remove without dates
            if (!Is.empty(payments)) {
                payments = payments.filter((d) => d.sinvdt !== null)
            }

            this.setState(
                {
                    deposits,
                    payments,
                },
                () => {
                    callback()
                    tableSortingEnableSort({
                        data: deposits,
                        classRef: this,
                        targetTable: '.a-table-heading.deposit .sort',
                        stateKey: 'deposits',
                        pattern: null,
                        callback: null,
                        dataKey: 'deposits',
                    })

                    tableSortingEnableSort({
                        data: payments,
                        classRef: this,
                        targetTable: '.a-table-heading.payment .sort',
                        stateKey: 'payments',
                        pattern: null,
                        callback: null,
                        dataKey: 'payments',
                    })
                }
            )
        } catch (error) {
            this.setState({
                deposits: [],
                payments: [],
            })
            displayAlertError(getErrorMessage(error))
        }

        this.dMloader(false)
    }

    handleDateChange = (date) => {
        this.setState({
            updatedData: {
                ...this.state.updatedData,
                userdate: date,
            },
        })
    }

    handleInvnumChange = async (e, meta = {}) => {
        const value = e.target.value

        if (value.length < 5) {
            return
        }

        const invoices = await this.api.getInvoices(
            `?$filter=invnum eq ${value}`
        )

        const checks = this.state.checks
        const payments = this.state.payments
        let client = this.updatedData?.client
        let selectedClient = this.state.selectedClient
        let clientQry = this.state.clientQry
        let selectedProject

        if (invoices.length) {
            client = invoices[0].client
            const project = invoices[0].proj
            clientQry = `?$filter=client eq '${encodeURIComponent(client)}'`
            const clientByCode = await this.api.getClientByCode(client)
            const projectByCode = await this.api.getProjectByCode(project)

            selectedClient = {
                label: `${clientByCode.ClientName ?? ''} [${
                    clientByCode.client
                }]`.trim(),
                value: clientByCode.client,
                id: clientByCode.id,
            }

            selectedProject = {
                label: `${projectByCode.projn ?? ''} [${
                    projectByCode.proj
                }]`.trim(),
                value: projectByCode.proj,
                id: projectByCode.id,
            }

            const index = this.state.payments.findIndex((payment) => {
                return payment.previnfo === value
            })

            if (index !== -1) {
                checks.payments[`${index}`] = true
                payments[`${index}`].tag = true
            }
        }

        this.setState(
            {
                selectedClient,
                clientQry,
                selectedProject,
                updatedData: {
                    ...this.state.updatedData,
                    client: client,
                },
                checks: checks,
                payments: payments,
            },
            async () => {
                if (!invoices.length) {
                    this.createGrids()
                } else {
                    await this.fetchCashReceiptData(() => {
                        const index = this.state.payments.findIndex(
                            (payment) => {
                                return payment.previnfo === value
                            }
                        )

                        this.checkPayment(index, true)
                    })
                }
                this.calcTotals()
            }
        )
    }

    calcTotals() {
        let depositsSum = this.updatedDeposits()
            .filter((deposit) => {
                return deposit.tag
            })
            .reduce((total, deposit) => total + deposit.amtdue, 0)
        let paymentsSum = this.updatedPayments()
            .filter((payment) => {
                return payment.tag
            })
            .reduce((total, payment) => total + payment.amtdue, 0)
        let totalSum = depositsSum + paymentsSum

        this.setState({
            totalDeposits: depositsSum,
            totalPayments: paymentsSum,
            totalReceipt: totalSum,
            totalDifference: this.state.updatedData.amount - totalSum,
        })
    }

    reset() {
        this.setState(
            {
                checks: {
                    deposits: {},
                    payments: {},
                },
                totalDepositChecked: 0,
                totalPaymentChecked: 0,
            },
            () => {
                this.calcTotals()
            }
        )
    }

    async selectedProject(code) {
        const selectedProject = await this.api.getProjectByCode(code, true)
        this.setState({
            selectedProject,
        })
    }

    async selectedClient(code) {
        const selectedClient = await this.api.getClientByCode(code, true)
        this.setState({
            selectedClient,
        })
    }

    updatedDeposits() {
        return Object.keys(this.state.checks.deposits)
            .filter((key) => {
                // Get only those checked deposits.
                return this.state.checks.deposits[key] === true
            })
            .map((key) => {
                return this.state.deposits[key]
            })
    }

    updatedPayments() {
        return Object.keys(this.state.checks.payments)
            .filter((key) => {
                // Get only those checked payments.
                return this.state.checks.payments[key] === true
            })
            .map((key) => {
                return this.state.payments[key]
            })
    }

    showModal = (type, index, { amtdue, orgpayee }) => {
        this.setState({
            isShowModal: true,
            activeIndex: index,
            activeType: type,
            activeCashReceipt: {
                amtdue: amtdue,
                orgpayee: orgpayee,
            },
        })
    }

    showPaymentModal = (type, index, amtdue, orgpayee) => {
        this.setState({
            isShowPaymentModal: true,
            activeIndex: index,
            activeType: type,
            activeCashReceiptPayment: {
                amtdue: amtdue,
                orgpayee: orgpayee,
            },
        })
    }

    savePaymentModal = (modalForm) => {
        this.setState((prevState) => {
            const shallowCopy = [...prevState[this.state.activeType]]
            const item = {
                ...shallowCopy[this.state.activeIndex],
                ...modalForm,
            }
            shallowCopy[this.state.activeIndex] = item
            return {
                [this.state.activeType]: shallowCopy,
            }
        })

        this.setState(
            {
                isShowPaymentModal: false,
                activeIndex: null,
                activeType: null,
            },
            this.calcTotals
        )
    }

    saveModalChangesLocally = (modalForm) => {
        this.setState((prevState) => {
            const shallowCopy = [...prevState[this.state.activeType]]
            const item = {
                ...shallowCopy[this.state.activeIndex],
                ...modalForm,
            }
            shallowCopy[this.state.activeIndex] = item
            return {
                [this.state.activeType]: shallowCopy,
            }
        })

        this.setState(
            {
                isShowModal: false,
                activeIndex: null,
                activeType: null,
            },
            this.calcTotals
        )
    }

    closeModal = () => {
        this.setState({
            isShowModal: false,
            isShowPaymentModal: false,
        })
    }

    handleHideWarningModal = (updateData = false) => {
        const indx = this.state.checkedIndex
        let deposits = this.state.deposits
        deposits[indx].tag = updateData

        if (updateData) {
            let data = {
                tag: updateData,
                usercode: this.userCode,
                propnum: deposits[indx].previnfo,
                proj: deposits[indx].proj,
                orgpayee: deposits[indx].orgpayee,
                forceSave: true,
            }
            this.updateClientDeposit(data)
        }

        this.setState(
            (prevState) => {
                prevState.deposits = deposits
                prevState.checks.deposits[indx] = updateData
                prevState.totalDepositChecked = Object.values(
                    prevState.checks.deposits
                ).reduce((a, item) => a + item, 0)
                prevState.checkedIndex = -1
                prevState.showWarningModal = false
            },
            () => {
                this.calcTotals()
            }
        )
    }

    handleSave = async (e, isNew) => {
        e.preventDefault()

        if (!this.state.updatedData.userdate) {
            displayAlert('danger', `Please enter a valid date.`)
            return
        }

        try {
            displayAlertLoader(this.saveLoadingMessage)

            for (const deposit of this.updatedDeposits()) {
                await this.api.updateCashReceiptClientDeposits({
                    tag: deposit.tag,
                    usercode: this.userCode,
                    propnum: deposit.previnfo,
                    proj: deposit.proj,
                    amount: deposit.amtdue,
                    orgpayee: deposit.orgpayee,
                    txdesc: deposit.orgpayee,
                    forceSave: true,
                })
            }

            for (const payment of this.updatedPayments()) {
                await this.api.updateCashReceiptPayments({
                    tag: payment.tag,
                    usercode: this.userCode,
                    invnum: payment.previnfo,
                    proj: payment.proj,
                    amount: payment.amtdue,
                    orgpayee: payment.orgpayee,
                    txdesc: payment.orgpayee,
                    forceSave: true,
                })
            }

            let data = { ...this.state.updatedData }
            data.userdate = dayjs(new Date(data.userdate)).format('YYYY-MM-DD')

            if (data.paytypedata) {
                data.paytype = data.paytypedata.value
            }
            if (data.paytype === 'CHECK') {
                data.depositslip = data.depositslip ?? 'CHECK'
            }

            delete data.paytypedata

            if (this.isEditing) {
                await this.api.updateCashReceipt(data)
            } else {
                await this.api.createCashReceipt(data)
            }

            setDraft(false)
            displayAlertSuccess(this.saveSuccessMessage)

            delay(() => {
                if (isNew) {
                    window.location.reload(false)
                } else {
                    this.props.navigate(
                        URI.accountsReceivable.cashReceipt.listNew
                    )
                }
            }, 1700)
        } catch (e) {
            displayAlert('danger', getErrorMessage(e))
        }
    }

    getTotalChecked(key) {
        return Object.keys(this.state.checks[key]).filter((key2) => {
            // Get only those checked deposits.
            return this.state.checks[key][key2] === true
        }).length
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsReceivable.cashReceipt.listNew}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.cashReceipt.listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.cashReceipt.listNew,
                            }}
                            linkAs={Link}
                        >
                            Cash Receipt
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.isEditing ? 'Edit' : 'Add'} Cash Receipt
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        {this.isEditing ? 'Edit' : 'Add'} Cash Receipt
                    </HeaderLight.Title>

                    <div>
                        <Button
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            onClick={() => {
                                setDraft(false)
                                this.props.navigate(
                                    URI.accountsReceivable.cashReceipt.listNew
                                )
                            }}
                        >
                            Cancel
                        </Button>

                        {/* Submit Button */}
                        <Button
                            className={this.isEditing ? 'd-none' : ''}
                            variant="primary me-2"
                            onClick={(e) => this.handleSave(e, true)}
                        >
                            Save & New
                        </Button>

                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderInputBar() {
        return (
            <InputBar className="large">
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Amount
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                type="number"
                                className="no-spinner"
                                placeholder="0.00"
                                size="sm"
                                data-field="amount"
                                value={this.state.updatedData.amount}
                                onChange={this.handleAmountChange}
                            />
                            <span style={{ width: '5%' }}>$</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Date
                        </Form.Label>
                        <div className="react-select-header">
                            <DatePicker
                                selected={
                                    this.state.updatedData.userdate
                                        ? new Date(
                                              this.state.updatedData.userdate
                                          )
                                        : ''
                                }
                                showMonthDropdown="true"
                                showYearDropdown="true"
                                className="form-control form-control-sm"
                                onChange={this.handleDateChange}
                            />
                        </div>
                    </InputBar.Link>
                    {/*<InputBar.Link>*/}
                    {/*    <Form.Label htmlFor="inputPassword5" className='ilabel'>Total Est. Cost</Form.Label>*/}
                    {/*    <div className='form-group-extra'><Form.Control type="text" placeholder="0.00" size='sm' data-field={''}/><span>USD</span></div>*/}
                    {/*</InputBar.Link>*/}
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Payment Type
                        </Form.Label>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                            options={this.state.paymentTypes}
                            value={this.state.paymentTypes.filter((account) => {
                                return (
                                    account.value ===
                                    this.state.updatedData?.paytype
                                )
                            })}
                            className="react-select"
                            placeholder="Please select"
                            name="paytypedata"
                            onChange={this.handleChange}
                        />
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Check No.
                        </Form.Label>
                        <Form.Control
                            type="text"
                            maxLength={20}
                            size="sm"
                            data-field="checknum"
                            value={this.state.updatedData.checknum}
                            onChange={this.handleChange}
                        />
                    </InputBar.Link>

                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Deposit Slip No.
                        </Form.Label>
                        <Form.Control
                            type="text"
                            maxLength={7}
                            size="sm"
                            data-field="depositslip"
                            value={this.state.updatedData.depositslip}
                            onChange={this.handleChange}
                            disabled={!this.shouldRenderDepositSlip()}
                        />
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderContent() {
        const { deposits, payments } = this.state
        const totalDChecked = this.getTotalChecked('deposits')
        const totalPChecked = this.getTotalChecked('payments')

        return (
            <Form.Group controlId="formGridText1">
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-4">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="mb-2 mb-lg-0 px-5">
                                    <h6 className="fw-bold mb-0">Lookup</h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    <strong>Project</strong>
                                </Form.Label>
                            </Col>
                            <Col>
                                <AsyncProjectsDropdown
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                    defaultValue={this.state.selectedProject}
                                    className="react-select"
                                    placeholder="Please select"
                                    name="proj"
                                    onChange={this.handleProjectOrClientChange}
                                    isClearable={true}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    <strong>Client</strong>
                                </Form.Label>
                            </Col>
                            <Col>
                                <AsyncClientsDropdown
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    isDisabled={this.isEditing}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                    defaultValue={this.state.selectedClient}
                                    className="react-select"
                                    placeholder="Please select"
                                    name="client"
                                    onChange={this.handleProjectOrClientChange}
                                    urlQuery={this.state.clientQry}
                                    isClearable={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className={this.isEditing ? 'd-none' : ''}>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    <strong>Invoice No.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                    value={this.state.updatedData.invnum}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/

                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            let updatedEvent = e
                                            updatedEvent.target.value =
                                                e.target.value.substring(0, 5)
                                            this.handleInvnumChange(
                                                updatedEvent
                                            )
                                        } else {
                                            e.target.value = ''
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="pb-4">
                    <Col sm={12}>{this.renderInputBar()}</Col>
                </Row>

                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-4">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="px-5 d-flex flex-wrap flex-md-nowrap">
                                    <h6 className="fw-bold mb-0">
                                        Client Deposit / Retainer (Proposals)
                                    </h6>
                                    <Form.Check
                                        inline
                                        label="Show all"
                                        name="showAllDeposits"
                                        type="checkbox"
                                        id={`chk-group-2`}
                                        className="ms-auto"
                                        onChange={this.handleShowAllCheck}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row className="py-4">
                    <Col
                        sm={12}
                        className="mb-3 mb-lg-0 position-relative table-action-bar"
                    >
                        <div className="table-gradient">
                            <Table striped responsive className="a-table">
                                <thead className="a-table-heading deposit">
                                    <tr key="0">
                                        <th>
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`group-all`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-radio-th-0`}
                                                    checked={totalDChecked > 0}
                                                    className={getCheckAllClass(
                                                        totalDChecked,
                                                        deposits
                                                    )}
                                                    onClick={
                                                        this
                                                            .handleSelectAllDepositChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="previnfo"
                                            >
                                                Proposal Doc. No.
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="sinvdt"
                                            >
                                                Date
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="posdesc"
                                            >
                                                Name/Description
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="invamt"
                                            >
                                                Requested Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="paidtodate"
                                            >
                                                Received to Date
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="tdinvdep"
                                            >
                                                Available
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="amtdue"
                                            >
                                                This Payment
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deposits.map((item, i) => (
                                        <tr
                                            key={i}
                                            className={
                                                this.state.checks[i]
                                                    ? `active`
                                                    : ''
                                            }
                                        >
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`group-` + i}
                                                        type="checkbox"
                                                        data-id={i}
                                                        id={
                                                            `inline-radio-deposit-` +
                                                            i
                                                        }
                                                        checked={
                                                            this.state.checks
                                                                .deposits[i] ===
                                                            true
                                                        }
                                                        onChange={this.handleDepositsChecks.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                            <td>{item.previnfo}</td>
                                            <td>
                                                {item.sinvdt
                                                    ? dayjs(
                                                          new Date(item.sinvdt)
                                                      ).format('MM/DD/YYYY')
                                                    : ''}
                                            </td>
                                            <td>{item.posdesc}</td>
                                            <td>
                                                {currencyFormat(
                                                    item.invamt,
                                                    '$'
                                                )}
                                            </td>
                                            <td>
                                                {currencyFormat(
                                                    item.paidtodate,
                                                    '$'
                                                )}
                                            </td>
                                            <td>
                                                {currencyFormat(
                                                    item.tdinvdep,
                                                    '$'
                                                )}
                                            </td>
                                            <td>
                                                <Link
                                                    className="text-charcoal hover-view-icon"
                                                    onClick={() => {
                                                        this.showModal(
                                                            'deposits',
                                                            i,
                                                            {
                                                                orgpayee:
                                                                    item.orgpayee,
                                                                amtdue: item.amtdue,
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <u>
                                                        {currencyFormat(
                                                            item.amtdue,
                                                            '$'
                                                        )}
                                                    </u>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-4">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="px-5 d-flex flex-wrap flex-md-nowrap">
                                    <h6 className="fw-bold mb-0">
                                        Payment (Invoices)
                                    </h6>
                                    <Form.Check
                                        inline
                                        label="Show all"
                                        name="showAllPayments"
                                        type="checkbox"
                                        id={`chk-group-2`}
                                        className="ms-auto"
                                        onChange={this.handleShowAllCheck}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row className="py-4">
                    <Col
                        sm={12}
                        className="mb-3 mb-lg-0 position-relative table-action-bar"
                    >
                        <div className="table-gradient">
                            <Table striped responsive className="a-table">
                                <thead className="a-table-heading payment">
                                    <tr key="0">
                                        <th>
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`group-all`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-radio-th-0`}
                                                    checked={totalPChecked > 0}
                                                    className={getCheckAllClass(
                                                        totalPChecked,
                                                        payments
                                                    )}
                                                    onClick={
                                                        this
                                                            .handleSelectAllPaymentsChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="proj"
                                            >
                                                Project
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="previnfo"
                                            >
                                                Invoice No.
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="sinvdt"
                                            >
                                                Date
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="sinvnum"
                                            >
                                                Proposal No.
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="posdesc"
                                            >
                                                Name/Description
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="invamt"
                                            >
                                                Balance Due
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="paidtodate"
                                            >
                                                Payments to Date
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="sort"
                                                data-field="amtdue"
                                            >
                                                This Payment
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payments.map((item, i) => (
                                        <tr
                                            key={i}
                                            className={
                                                this.state.checks[i]
                                                    ? `active`
                                                    : ''
                                            }
                                        >
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`group-` + i}
                                                        type="checkbox"
                                                        data-id={i}
                                                        id={
                                                            `inline-radio-payment-` +
                                                            i
                                                        }
                                                        checked={
                                                            this.state.checks
                                                                .payments[i] ===
                                                            true
                                                        }
                                                        onChange={
                                                            this
                                                                .handlePaymentsChecks
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    className="text-charcoal hover-view-icon"
                                                    onClick={() => {
                                                        this.showModal(
                                                            'payments',
                                                            i,
                                                            {
                                                                orgpayee:
                                                                    item.orgpayee,
                                                                amtdue: item.amtdue,
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <u>{item.proj}</u>
                                                </span>
                                            </td>
                                            <td>{item.previnfo}</td>
                                            <td>{formatDate(item.sinvdt)}</td>
                                            <td>{item.sinvnum}</td>
                                            <td>{item.posdesc}</td>
                                            <td>
                                                {currencyFormat(
                                                    item.invamt,
                                                    '$'
                                                )}
                                            </td>
                                            <td>
                                                {currencyFormat(
                                                    item.paidtodate,
                                                    '$'
                                                )}
                                            </td>
                                            <td>
                                                <Link
                                                    className="text-charcoal hover-view-icon"
                                                    onClick={() => {
                                                        this.showPaymentModal(
                                                            'payments',
                                                            i,
                                                            item.amtdue,
                                                            item.orgpayee
                                                        )
                                                    }}
                                                >
                                                    <u>
                                                        {currencyFormat(
                                                            item.amtdue,
                                                            '$'
                                                        )}
                                                    </u>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                <div className="table-gradient">
                    <Table responsive borderless className="">
                        <thead>
                            <tr key="0">
                                <th className="fs-md">
                                    Total Deposit/Retainer
                                </th>
                                <th className="fs-md"></th>
                                <th className="fs-md">Total Payments</th>
                                <th className="fs-md"></th>
                                <th className="fs-md">Total Receipt</th>
                                <th className="fs-md d-none d-sm-table-cell w-40px"></th>
                                <th className="fs-md">Difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={0}>
                                <td className="align-middle">
                                    <Form.Control
                                        type="text"
                                        placeholder="0.00"
                                        size="sm"
                                        style={{ minWidth: '60px' }}
                                        value={this.state.totalDeposits.toFixed(
                                            2
                                        )}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </td>
                                <td className="align-middle text-secondary-ash px-1">
                                    <i className="ri-add-line ri-lg"></i>
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        placeholder="0.00"
                                        size="sm"
                                        style={{ minWidth: '60px' }}
                                        value={this.state.totalPayments.toFixed(
                                            2
                                        )}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </td>
                                <td className="align-middle text-secondary-ash px-1">
                                    <span className="fsx-24">=</span>
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        placeholder="0.00"
                                        size="sm"
                                        style={{ minWidth: '60px' }}
                                        value={this.state.totalReceipt.toFixed(
                                            2
                                        )}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </td>
                                <td className="align-middle text-secondary-ash px-sm-0 px-md-5 d-none d-sm-table-cell w-40px"></td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        placeholder="0.00"
                                        size="sm"
                                        style={{ minWidth: '60px' }}
                                        value={this.state.totalDifference.toFixed(
                                            2
                                        )}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Form.Group>
        )
    }

    render() {
        return this.renderView(
            <>
                {this.renderHeader()}

                <div className="content-padding min-height mb-5">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
                <CashReceiptModal
                    show={this.state.isShowModal}
                    activeCashReceipt={this.state.activeCashReceipt}
                    onSave={this.saveModalChangesLocally}
                    hideModal={this.closeModal}
                />
                <CashReceiptPaymentModal
                    show={this.state.isShowPaymentModal}
                    activeCashReceiptPayment={
                        this.state.activeCashReceiptPayment
                    }
                    onSave={this.savePaymentModal}
                    hideModal={this.closeModal}
                />
                <DepositWarningModal
                    show={this.state.showWarningModal}
                    hideModal={this.handleHideWarningModal.bind(this)}
                    propId={this.state.checkedPropId}
                />
            </>
        )
    }
}

export default WithRouter(CashReceiptAdd)
