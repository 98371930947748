import React, { useContext } from 'react'
import {
    ChangeFunction,
    SubmitFunction,
    TimeZoneContext,
} from 'app/components/TimeZoneContextProvider/TimeZoneContextProvider'
import Spinner from '../help/Spinner'
import TimeZoneModal from '../modal/TimeZoneModal'

const TimeZoneChecker = ({ children }: { children: React.ReactNode }) => {
    const {
        handleChange,
        handleSubmit,
        isTimeZoneSet,
        isLoading,
        timeZoneCode,
    } = useContext(TimeZoneContext)

    if (isLoading) {
        return <Spinner />
    }
    return (
        <>
            <TimeZoneModal
                show={isTimeZoneSet === false}
                value={timeZoneCode}
                handleChange={handleChange as ChangeFunction}
                handleSubmit={handleSubmit as SubmitFunction}
            />
            {children}
        </>
    )
}

TimeZoneChecker.displayName = 'TimeZoneChecker'

export default TimeZoneChecker
