import CompTypeDropdown from 'app/components/dropdowns/CompTypeDropdown'
import FormCurrencyInput from 'app/components/form/FormCurrencyInput'
import Label from 'app/components/label/Label'
import useComponentRecalculate from 'lib/api/hooks/useComponentRecalculate'
import { Component } from 'lib/api/types/Component'
import { CompNumericPercentType } from 'lib/api/types/CompPercentType'
import {
    TWhatChangedComponent,
    TWhatChangedComponentPayload,
} from 'lib/api/types/WhatChangedComponent'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap'
import normalizeRecalculateDataPayload from '../utils/normalizeRecalculateDataPayload'
import convertObjectPropsToLowercase from 'utilities/convertObjectPropsToLowercase'
import normalizeRecalculateDataResponse from '../normalizeRecalculateDataResponse'
import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import { useFormContext } from 'react-hook-form'

type TSubComponentProps = {
    component: Component
    updateComponent: (newComp: Component) => void
    disableInputs: boolean
    setDeletedComponent: (component: Component) => void
}

const arePropsEqual = (
    prevProps: TSubComponentProps,
    nextProps: TSubComponentProps
) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

const SubComponent = memo(
    ({
        component,
        updateComponent,
        disableInputs,
        setDeletedComponent,
    }: TSubComponentProps) => {
        const [whatChanged, setWhatChanged] = useState<
            TWhatChangedComponentPayload | object
        >({})
        const methods = useFormContext()
        const componentRef = useRef(component)
        const supplier = methods.watch('supplier')
        const sellPrice = methods.watch('estprice')
        const compTypeRef = useRef<null | number>(null)
        const costRef = useRef<null | number>(null)
        const priceRef = useRef<null | number>(null)

        const { data: recalculatedData, isFetching: isRecalculating } =
            useComponentRecalculate(
                Number(component.id),
                JSON.stringify(whatChanged),
                {
                    enabled: Object.values(whatChanged).length > 0,
                }
            )

        useEffect(() => {
            componentRef.current = component
        }, [component])

        useEffect(() => {
            if (recalculatedData) {
                updateComponent(
                    convertObjectPropsToLowercase(
                        normalizeRecalculateDataResponse(recalculatedData)
                    ) as Component
                )
            }
        }, [recalculatedData, updateComponent])

        useEffect(() => {
            if (supplier && supplier !== componentRef.current.supplier) {
                componentRef.current.supplier = supplier
                setWhatChanged({
                    whatChanged: TWhatChangedComponent.cwcVendor,
                    ...normalizeRecalculateDataPayload(componentRef.current),
                })
            }
        }, [supplier])

        useEffect(() => {
            if (
                sellPrice &&
                componentRef.current.usedisc === CompNumericPercentType.Fee &&
                componentRef.current.feecost !== sellPrice
            ) {
                componentRef.current.feecost = sellPrice
                setWhatChanged({
                    whatChanged: TWhatChangedComponent.cwcEstimatedCost,
                    ...normalizeRecalculateDataPayload(componentRef.current),
                })
            }
        }, [sellPrice])

        const handleRecalculate = (
            whatPropertyChanged: `${TWhatChangedComponent}`,
            component: Component
        ) => {
            const newWhatChanged = {
                whatChanged: whatPropertyChanged,
                ...normalizeRecalculateDataPayload(component),
            }
            setWhatChanged(newWhatChanged)
        }

        const getCostInputProperty = () =>
            component.usedisc === CompNumericPercentType.Fee
                ? 'feecost'
                : 'estcost'

        return (
            <div className="tw-grid tw-grid-cols-6 tw-content-center tw-gap-4 tw-items-center">
                <div className="tw-self-end">
                    <CompTypeDropdown
                        isDisabled={isRecalculating || disableInputs}
                        value={{
                            label: '',
                            value: component.comptype,
                        }}
                        onChange={(e) => {
                            compTypeRef.current = e.value as number
                            updateComponent({
                                ...component,
                                comptype: Number(e.value),
                            })
                        }}
                        blurInputOnSelect
                        onBlur={() => {
                            handleRecalculate(
                                TWhatChangedComponent.cwcComponentType,
                                {
                                    ...component,
                                    // Need to use a ref because the onBlur event doesn´t have the value
                                    comptype: compTypeRef.current as number,
                                }
                            )
                        }}
                    />
                </div>
                <div>
                    <Label label="Cost" className="tw-pb-2 tw-font-bold" />
                    <FormCurrencyInput
                        disabled={isRecalculating || disableInputs}
                        id="Price"
                        style={{
                            height: 41,
                        }}
                        allowNegativeValue
                        value={component[getCostInputProperty()]}
                        maxLength={15}
                        onValueChange={(value) => {
                            const isNotAValidNumber = isNaN(Number(value))
                            // Need to use a ref because the onBlur event doesn´t normalize the value
                            costRef.current = Number(value)
                            updateComponent({
                                ...component,
                                [getCostInputProperty()]: isNotAValidNumber
                                    ? 0
                                    : Number(value),
                            })
                        }}
                        onBlur={() => {
                            handleRecalculate(
                                component.usedisc ===
                                    CompNumericPercentType.Discount
                                    ? TWhatChangedComponent.cwcListPrice
                                    : TWhatChangedComponent.cwcEstimatedCost,
                                {
                                    ...component,
                                    estunitcost: costRef.current as number,
                                    unitlist: costRef.current as number,
                                    list: costRef.current as number,
                                    [getCostInputProperty()]: costRef.current,
                                }
                            )
                        }}
                    />
                </div>
                <div>
                    <Label label="Markup" className="tw-pb-2 tw-font-bold" />
                    <FormControl
                        disabled={isRecalculating || disableInputs}
                        id="Markup"
                        onChange={(e) =>
                            updateComponent({
                                ...component,
                                markup: Number(e.target.value),
                            })
                        }
                        value={component.markup}
                        maxLength={15}
                        onBlur={(e) => {
                            const isNotAValidNumber = isNaN(
                                Number(e.target.value)
                            )
                            const isNotValid =
                                !e.target.value ||
                                isNotAValidNumber ||
                                e.target.value === '-0.'
                            if (isNotValid) {
                                updateComponent({
                                    ...component,
                                    markup: 0,
                                })
                            } else {
                                updateComponent({
                                    ...component,
                                    markup: Number(e.target.value),
                                })
                            }
                            handleRecalculate(
                                TWhatChangedComponent.cwcPercent,
                                {
                                    ...component,
                                    markup: isNotValid
                                        ? 0
                                        : Number(e.target.value),
                                }
                            )
                        }}
                    />
                </div>
                <div>
                    <Label label="Price" className="tw-pb-2 tw-font-bold" />
                    <FormCurrencyInput
                        disabled={isRecalculating || disableInputs}
                        id="Price"
                        style={{
                            height: 41,
                        }}
                        allowNegativeValue
                        value={component.estprice}
                        maxLength={15}
                        onValueChange={(value) => {
                            // Need to use a ref because the onBlur event doesn´t normalize the value
                            priceRef.current = Number(value)
                            const isNotAValidNumber = isNaN(Number(value))
                            updateComponent({
                                ...component,
                                estprice: isNotAValidNumber ? 0 : Number(value),
                            })
                        }}
                        onBlur={() => {
                            handleRecalculate(
                                TWhatChangedComponent.cwcEstimatedPrice,
                                {
                                    ...component,
                                    estprice: priceRef.current as number,
                                }
                            )
                        }}
                    />
                </div>
                <Form.Check
                    size={30}
                    disabled={isRecalculating || disableInputs}
                    className="tw-self-end"
                    type="checkbox"
                    label="Taxable"
                    checked={component.taxable}
                    maxLength={15}
                    onChange={(e) =>
                        updateComponent({
                            ...component,
                            taxable: e.target.checked,
                        })
                    }
                />

                <SecureBootstrapButton
                    attributeNo={14}
                    attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                    variant="ivory"
                    size="sm"
                    disabled={disableInputs}
                    onClick={() => setDeletedComponent(component)}
                    className="tw-self-end"
                >
                    <i className="ri-close-line" /> Delete
                </SecureBootstrapButton>
            </div>
        )
    },
    arePropsEqual
)
SubComponent.displayName = 'SubComponent'
export default SubComponent
