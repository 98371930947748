import React, { ReactNode } from 'react'

interface IBaseBanner {
    children?: ReactNode
    bannerClass?: string
    backgroundImage?: string
    imageClass?: string
    imageAlt?: string
}

const BaseBanner = ({
    bannerClass = '',
    children,
    backgroundImage,
    imageClass = '',
    imageAlt,
}: IBaseBanner) => (
    <>
        {backgroundImage ? (
            <div className={`tw-relative tw-w-full ${bannerClass}`}>
                <img
                    src={backgroundImage}
                    alt={`Banner-background-${imageAlt}`}
                    className={imageClass}
                />
                {children}
            </div>
        ) : (
            <>{children}</>
        )}
    </>
)

BaseBanner.displayName = 'BaseBanner'

export default BaseBanner
