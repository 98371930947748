import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiService } from 'lib/api/HttpService'
import { Summary } from 'lib/api/types/Summary'
import { uniqBy } from 'lodash'
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown'

const api = new ApiService()

const getLocations = async () => {
    const locations = (await api.getLocationsSummaries()) as Summary[]
    const uniqueLocations = uniqBy(locations, (obj) => obj.key)
    return uniqueLocations
}

const LocationDropdown = (props: TBaseDropdownProps) => {
    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'locationsSummaries'],
        queryFn: getLocations,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const options =
        data?.map((option) => ({
            label: `${option.value ?? ''} ${
                option.key ? `[${option.key}]` : ''
            }`.trim(),
            value: option.key,
        })) || []

    return <BaseDropdown {...props} isLoading={isLoading} options={options} />
}

LocationDropdown.displayName = 'LocationDropdown'

export default LocationDropdown
