import React, { useState } from 'react'
import { makeid } from '../../../utilities/String'

/* eslint-disable */
const InputMask = require('@mona-health/react-input-mask')
/* eslint-enable */

interface Props {
    type?: string
    /** Use `value` on .tsx else use `defaultValue` on .js */
    value?: any
    /* ###### */

    name?: string
    mask?: string
    maskPattern?: string
    onChange?: any
    className?: any
    maxLength?: any
    placeholder?: string
}

export default function InputMasked(props: Props) {
    const identity = `css-${makeid(20)}`

    return (
        <InputMask
            mask="(999) 999 9999 9999 9999"
            {...props}
            className={`form-control ${props.className ?? ''} ${identity}`}
            onBlur={(e: any) => {
                const inp: any = document.querySelector(`.${identity}`)
                inp.value = (inp.value ?? '').replace(/_/gi, ' ')

                if (!/\d/.test(inp.value)) {
                    inp.value = ''

                    props.onChange({
                        target: inp,
                    })
                }

                if (
                    inp.value.replace(/-/gi, '').replace(/\s/gi, '').length <= 8
                ) {
                    inp.value = inp.value.substr(0, length)
                }
            }}
            maskPlaceholder={' '}
        ></InputMask>
    )
}

InputMasked.displayName = 'InputMasked'
