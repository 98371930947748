import { isDraft, promptDraft } from './ModuleHelper'
import { removeDisplayAlert } from './Response'
import { makeid, stringReplaceArray } from './String'
import loaderIcon from '../assets/images/loader.svg'
import logoIcon from '../assets/images/logo-dark.svg'
import { renderToString } from 'react-dom/server'
import { delay, isNull, isUndefined } from 'lodash'
import { getLocalStorage, setLocalStorage } from './LocalStorage'

const SITE_LOADING_IMAGE = `
<div class="d-flex justify-content-center align-items-center" style="height: 100%">
    <div>
        <img
            src="${logoIcon}"
            width="180"
            height="auto"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
        />
        <br />
        <img
            src="${loaderIcon}"
            width="180"
            height="130"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            style="margin-top: -40px"
        />
    </div>
</div>
`

export { SITE_LOADING_IMAGE }

export function addBodyClass(name: string, el: any = null): void {
    el = el || document.body
    // Add additional class to `body` on change.
    setTimeout(() => el.classList.add(`${name}`), 50)
}

export function addDomClass(name: string, el: any = null): void {
    el = el || document.body
    // Add additional class to `body` on change.
    setTimeout(() => el.classList.add(`${name}`), 50)
}

export function getClassNames(props: any, defaultClass: string): string {
    return props.replaceClass
        ? props.className
        : props.replaceclassmatch
        ? replaceClassMatch(props, `${defaultClass} ${props.className || ''}`)
        : `${defaultClass} ${props.className || ''}`
}

export function hasClass(name: string, el: any): string {
    return el.classList.contains(name)
}

export function setDocumentTitle(title: string): void {
    document.title = (title ? title + ' | ' : '') + 'Design Manager'
}

export function removeBodyClass(name: string, el: any = null): void {
    el = el || document.body
    // Remove additional class to `body` on change.
    setTimeout(() => el.classList.remove(`${name}`), 50)
}

export function removeDomClass(name: string, el: any = null): void {
    el = el || document.body
    // Remove additional class to `body` on change.
    setTimeout(() => el.classList.remove(`${name}`), 50)
}

export function replaceClassMatch(props: any, classNames: string): string {
    const arrayString = props.replaceclassmatch.split('/')
    const arrayStringReplace = props.replaceclassmatchwith.split('/')
    return stringReplaceArray(classNames, arrayString, arrayStringReplace)
}

export function togglePageLoader(show: boolean, adjustSidebar = false) {
    const app: any = document.getElementById('app')
    const loader: any = document.getElementById('page-loader')

    if (show && !loader) {
        const div: any = document.createElement('div')
        div.id = `page-loader`
        div.innerHTML = `
            <div class="d-flex justify-content-center align-items-center" style="height: 100%">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
            </div>
        `
        app.append(div)
    }

    if (show && adjustSidebar && window.outerWidth >= 1200) {
        // Add adjustment to sidebar
        const loader: any = document.getElementById('page-loader')
        loader.classList += ' has-sidebar'
    }

    if (!show && loader) {
        loader.classList += ' fadeout'
        setTimeout(() => {
            loader.remove()
        }, 800)
    }
}

let toggleDMLoaderTimer: any = null
export function toggleDMPageLoader(
    params:
        | boolean
        | string
        | {
              show?: boolean
              hasSidebar?: boolean
              destroy?: boolean
              target?: string
              message?: string
              noOpacity?: boolean
          },
    hasSidebar = true
) {
    const app: any = document.getElementById('app')
    const nav: any = document.querySelector('.sidemenu-wrapper')
    let loader: any = document.getElementById('page-loader')

    if (typeof params === 'boolean') {
        params = {
            show: params,
            hasSidebar: hasSidebar,
        }
    }

    // If given param is an string identifier
    // this means remove loader
    if (typeof params === 'string') {
        params = {
            show: false,
            target: params,
        }
    }

    // If identifier is undefined don't proceed.
    if (isUndefined(typeof params) || isNull(typeof params)) {
        return
    }

    if (typeof hasSidebar === 'boolean') {
        params.hasSidebar = hasSidebar
    }

    if (params.show && !loader) {
        const identity = `css-${makeid(15)}`
        const div: any = document.createElement('div')
        div.id = `page-loader`
        div.innerHTML = SITE_LOADING_IMAGE

        if (
            params.show &&
            params.hasSidebar === true &&
            window.outerWidth >= 1200
        ) {
            div.classList += ' has-sidebar'
            div.style.width = `calc(100% - ${nav.offsetWidth}px)`
            div.style.marginLeft = `${nav.offsetWidth}px`
        }

        div.classList += ` ${identity} ${params?.noOpacity ? 'no-opacity' : ''}`
        app.append(div)

        return identity
    }

    if (toggleDMLoaderTimer) {
        clearTimeout(toggleDMLoaderTimer)
    }

    toggleDMLoaderTimer = delay(
        (params) => {
            loader = document.querySelector(
                `#page-loader.${params.target || 'not-existing'}`
            )

            if (!params.show) {
                if (params.hasSidebar && window.outerWidth >= 1200) {
                    loader = document.querySelector(
                        `#page-loader.has-sidebar.${
                            params.target || 'not-existing'
                        }`
                    )
                }

                if (params.destroy) {
                    loader = document.querySelector(`#page-loader`)
                }

                if (loader) {
                    loader.classList += ' fadeout'
                    delay(() => {
                        loader.remove()
                    }, 120)
                }
            }
        },
        10,
        params
    )
}

export function initDOMEvents() {
    const links = document.querySelectorAll('a[href]')

    if (links && links.length) {
        links.forEach((link) => {
            link.addEventListener('click', function () {
                // This will remove alert for loading clipper image.
                toggleDMPageLoader({ destroy: true })
            })
        })
    }
}

export function initClickBreadcrumb() {
    // Bind click event on breadcrumb links.
    // If draft is enabled, this will trigger browser confirm()
    // before leaving the current screen.
    const { href, origin } = window.location
    Event(
        '.breadcrumb a[href]:not([href="#"]):not([href="' +
            href +
            '"]):not([href="' +
            href.replace(origin, '') +
            '"])'
    ).click((e: any) => {
        if (isDraft() && !promptDraft()) {
            e.preventDefault()
            return false
        }

        // This will remove alert for loading clipper image.
        toggleDMPageLoader({ destroy: true })

        return true
    })
}

export function initClickNavLink() {
    // Bind click event on sidebar nav-links.
    // If draft is enabled, this will trigger browser confirm()
    // before leaving the current screen.
    Event('.nav-link:not(.has-collapse)').click((e: any) => {
        if (isDraft() && !promptDraft()) {
            e.preventDefault()
            return false
        }

        toggleDMPageLoader({ destroy: true })

        return true
    })
}

export function Event(selector: string, multiple = true) {
    const elements = multiple
        ? document.querySelectorAll(selector)
        : [document.querySelector(selector)]

    return {
        toggleCheck(status: boolean) {
            elements.forEach((e: any) => {
                e.checked = status
            })
        },
        check() {
            this.toggleCheck(true)
        },
        uncheck() {
            this.toggleCheck(false)
        },
        click(callback: Function, force = false) {
            elements.forEach((el: any) => {
                if (el.getAttribute('data-bind-event-click') && !force) {
                    return
                }

                el.addEventListener('click', (e: any) => callback(e, el), false)
                el.setAttribute('data-bind-event-click', 1)
            })
        },
        value(val: any) {
            elements.forEach((e: any) => {
                e.value = val
            })
        },
    }
}

export function loadingWindowHTML() {
    return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
        <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
        <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div>
            <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" class="d-inline-block align-top" alt="React Bootstrap logo">
            <br>
            <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" class="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
            </div>
        </div>
        </div>
    </div>`
}
