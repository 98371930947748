import React from 'react'
import SummaryCard from './SummaryCard'
import useBusinessSummary from 'lib/api/hooks/useGetBusinessSummary'
import OpenProposalsIcon from '../../../assets/images/icons/Open-Proposals-Icon.svg'
import OpenInvoicesIcon from '../../../assets/images/icons/Open_Invoices-Icon.svg'
import OverdueInvoicesIcon from '../../../assets/images/icons/Calendar-Icon.svg'

const BusinessSummary = () => {
    const { data } = useBusinessSummary()
    return (
        <div className="tw-bg-[#006a53] tw-p-2 tw-rounded-lg">
            <h2 className="tw-text-white tw-text-2xl tw-font-bold tw-mb-4">
                Business Summary
            </h2>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-4 tw-gap-4">
                <SummaryCard
                    iconPath={OpenProposalsIcon}
                    count={data?.proposalCount}
                    title="Open Proposals"
                    amount={data?.totalProposed}
                    iconAlt="proposals-icon"
                />
                <SummaryCard
                    iconPath={OpenInvoicesIcon}
                    count={data?.countOpenInvoices}
                    title="Open Invoices"
                    amount={data?.totalOpenInvoices}
                    iconAlt="invoices-icon"
                />
                <SummaryCard
                    iconPath={OverdueInvoicesIcon}
                    count={data?.countOverdueInvoices}
                    title="Overdue Invoices"
                    amount={data?.totalOverdueInvoices}
                    iconAlt="overdue-icon"
                />
                <SummaryCard
                    iconPath={OverdueInvoicesIcon}
                    count={data?.countOverdueBills}
                    title="Overdue Bills"
                    amount={data?.totalBills}
                    iconAlt="bills-icon"
                />
            </div>
        </div>
    )
}

BusinessSummary.displayName = 'BusinessSummary'

export default BusinessSummary
