import React from 'react'
import { Container, Col, Row, NavLink } from 'react-bootstrap'
import ActionBar from '../components/ActionBar'

class ActionBarUi extends React.Component {
    render() {
        return (
            <>
                <div className="bg-secondary-grey pt-5 pb-4 mt-5">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="ui-title">Action Bar</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="mt-5">
                    <Row>
                        <Col lg="12" className="mb-5">
                            <ActionBar isFloat={false}>
                                <ActionBar.Links>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-pencil-fill"></i>{' '}
                                            Edit
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-fridge-fill"></i>{' '}
                                            Items
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-door-closed-fill"></i>{' '}
                                            Close Projects
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-time-fill"></i>{' '}
                                            Status
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-send-plane-2-fill"></i>{' '}
                                            Request Retainer
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            href="#"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-close-line"></i>{' '}
                                            Delete
                                        </NavLink>
                                    </li>
                                </ActionBar.Links>
                            </ActionBar>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default ActionBarUi
