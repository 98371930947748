import { Breadcrumb, Dropdown, Container, Form, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { getActiveMenuUri, WithRouter } from '../../../../helpers/Router'
import URI from '../../../../defaults/RoutesDefault'
import Header from '../../../components/Header'
import ListFilter from '../../../components/ListFilter'
import { compareStr } from '../../../../helpers/String'
import { getSubComponent } from '../../../../helpers/Util'
import VendorInvoicesListNew from './VendorInvoiceListNew'
import VendorInvoicesListExisting from './VendorInvoiceListExisting'
import { renderSecureContent } from '../../../../utilities/modules/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureDropdownToggle from 'app/components/security/SecureDropdownToggle'
import BudgetBannerProvider from 'app/context/BudgetBannerContext'

class VendorInvoiceList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            showRetainerModal: false,
            activeMenu: getActiveMenuUri(
                this.props.paths.pathname,
                ['new', 'existing'],
                'new'
            ),
        }
    }

    componentInit() {
        this.setTitle('Vendor Deposits & Invoices').setActionBar(true)
    }

    handleMenuClick = (e) => {
        this.setState(
            (prevState) => (prevState.activeMenu = e.target.dataset.menu)
        )
    }

    renderHeader() {
        const { activeMenu } = this.state

        return (
            <Header buttons={1} style={{ position: 'relative', zIndex: 1020 }}>
                <Header.Breadcumbs>
                    <Breadcrumb className="breadcrumb-light">
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsPayable.vendorInvoice.base,
                            }}
                            linkAs={Link}
                        >
                            Accounts Payable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Vendor Deposits & Invoices
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Header.Breadcumbs>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Title>Vendor Deposits & Invoices</Header.Title>
                    </Header.LeftContent>
                    {activeMenu === 'new' && (
                        <Header.Actions list={true}>
                            <li>
                                <Dropdown
                                    className="d-flex justify-content-end ms-auto"
                                    align="end"
                                >
                                    <SecureDropdownToggle
                                        attributeNo={55}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        id="dropdown-autoclose-true"
                                        variant="transparent"
                                        size="lg"
                                        className="btn-icon text-white border-white"
                                    >
                                        <i className="ri-add-line ri-lg"></i>{' '}
                                        Add From
                                    </SecureDropdownToggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            as={Link}
                                            to={
                                                URI.accountsPayable
                                                    .vendorInvoice
                                                    .addQuickDeposit
                                            }
                                        >
                                            {' '}
                                            Quick Add Deposits
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item href={URI.accountsPayable.vendorInvoice.addRec}>Recurring Expenses</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown
                                    className="d-flex justify-content-end ms-auto"
                                    align="end"
                                >
                                    <SecureDropdownToggle
                                        attributeNo={55}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        id="dropdown-autoclose-true"
                                        variant="light"
                                        size="lg"
                                        className="btn-icon text-secondary-green"
                                    >
                                        <i className="ri-add-line ri-lg"></i>{' '}
                                        Add
                                    </SecureDropdownToggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            as={Link}
                                            to={
                                                URI.accountsPayable
                                                    .vendorInvoice.addDeposit
                                            }
                                        >
                                            {' '}
                                            Deposit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={
                                                URI.accountsPayable
                                                    .vendorInvoice.add
                                            }
                                        >
                                            Invoice
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={
                                                URI.accountsPayable
                                                    .vendorInvoice.addMisc
                                            }
                                        >
                                            Expense / Bill / Miscellaneous
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </Header.Actions>
                    )}
                </Header.TitleContent>

                <Header.Buttons>
                    <li>
                        <NavLink
                            to={URI.accountsPayable.vendorInvoice.listNew}
                            onClick={this.handleMenuClick}
                            data-menu="new"
                            className={`btn text-white ${compareStr(
                                this.props.page
                                    ? this.props.page
                                    : this.state.activeMenu,
                                'new',
                                'active',
                                ''
                            )}`}
                        >
                            New
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={URI.accountsPayable.vendorInvoice.listExisting}
                            onClick={this.handleMenuClick}
                            data-menu="existing"
                            className={`btn text-white ${compareStr(
                                this.props.page
                                    ? this.props.page
                                    : this.state.activeMenu,
                                'existing',
                                'active',
                                ''
                            )}`}
                        >
                            Existing
                        </NavLink>
                    </li>
                </Header.Buttons>
            </Header>
        )
    }

    renderFilters() {
        return (
            <>
                <ListFilter>
                    <ListFilter.Fields
                        md={10}
                        lg={10}
                        xl={10}
                        className="width-30"
                    >
                        <ListFilter.Field>
                            <Form.Label className="text-end">
                                <strong>
                                    Fiscal
                                    <br />
                                    Month
                                </strong>
                            </Form.Label>
                            <Form.Select size="sm">
                                <option>07/2022</option>
                            </Form.Select>
                        </ListFilter.Field>
                        <ListFilter.Field className="justify-content-center justify-content-md-start mt-1 mt-md-0">
                            <Form.Label className="text-start">
                                Total Purchases
                                <br />
                                <strong>$5000.00</strong>
                            </Form.Label>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    <ListFilter.Actions
                        md={2}
                        lg={2}
                        xl={2}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-md-end"
                    >
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                        <ListFilter.Action className="ms-auto ms-md-3">
                            <Dropdown
                                className="d-flex justify-content-end ms-auto"
                                align="end"
                            >
                                <Dropdown.Toggle
                                    id="dropdown-autoclose-true"
                                    variant="ivory"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="">
                                        <i className="ri-file-add-line"></i>{' '}
                                        Create Journal Report
                                    </Dropdown.Item>
                                    <Dropdown.Item href="">
                                        <i className="ri-upload-fill"></i> Post
                                    </Dropdown.Item>
                                    <Dropdown.Item href="">
                                        <i className="ri-delete-bin-5-line"></i>{' '}
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderContent() {
        return getSubComponent(
            this.props.page ? this.props.page : this.state.activeMenu,
            {
                new: renderSecureContent(
                    <BudgetBannerProvider>
                        <VendorInvoicesListNew />
                    </BudgetBannerProvider>
                )(55, SECURITY_ATTRIBUTE_TYPES.DenyAccess),
                existing: renderSecureContent(<VendorInvoicesListExisting />)(
                    57,
                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                ),
            }
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(VendorInvoiceList)
