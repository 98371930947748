import classNames from 'classnames'
import React from 'react'

type TLabelProps = {
    label: string
    className?: string
}

const Label = ({ label, className = '' }: TLabelProps) => (
    <label
        className={classNames('tw- xl:tw-text-right', className)}
        htmlFor={label}
    >
        {label}
    </label>
)

Label.displayName = 'Label'

export default Label
