import React from 'react'

interface IBaseButton {
    text: string
    onClick: () => void
    buttonClass?: string
}

const BaseButton = ({ text, onClick, buttonClass }: IBaseButton) => (
    <button className={buttonClass} onClick={onClick}>
        {text}
    </button>
)

BaseButton.displayName = 'BaseButton'

export default BaseButton
