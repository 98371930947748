import React from 'react'
import { observer } from 'mobx-react'
import { Container, Col, Row, Form, Alert } from 'react-bootstrap'
import Select from 'react-select'
import WysiwygEditor from '../../../components/editor/WysiwygEditor'
import ThreadComponent from '../../../ThreadComponent'
import EmployeeAddEditViewModel from '../EmployeeAddEditViewModel'
import { EmployeeService } from '../api/EmployeeService'

interface Props {
    data: any
    onDraft?: any
}

interface State {
    data: any
    showroomCodes: SelectOptions[]
    salesTaxCodes: any
}

interface SelectOptions {
    label?: string
    value?: string
}

class EmployeeAddEditNotes extends ThreadComponent<Props, State> {
    readonly employeeAddEditModel: EmployeeAddEditViewModel
    defaultSalesTaxCode: SelectOptions | null
    defaultShowroomCode: SelectOptions | null

    constructor(props: Props) {
        super(props)

        this.employeeAddEditModel = new EmployeeAddEditViewModel(
            new EmployeeService()
        )
        this.defaultSalesTaxCode = null
        this.defaultShowroomCode = null
        this.state = {
            data: {},
            showroomCodes: [
                { value: 'Opt1', label: 'Option 1' },
                { value: 'Opt2', label: 'Option 2' },
            ],
            salesTaxCodes: [],
        }
    }

    async componentDidMount() {
        this.setTitle('POS - Add Employees').hasFormAction(true)

        const { data } = this.props

        this.setState({
            data: data,
            salesTaxCodes: await this.employeeAddEditModel.fetchSalesTaxCodes(),
        })
    }

    componentDidUpdate(previousProps: Props, previousState: State): void {
        if (previousProps.data !== this.props.data) {
            const { data } = this.props

            this.setState({
                data: data,
            })
        }
    }

    handleEmployeeChange = (e: any, select?: any) => {
        if (!select) {
            // If checkbox
            if (
                e.target.tagName.toLowerCase() === 'input' &&
                e.target.getAttribute('type') === 'checkbox'
            ) {
                this.employeeAddEditModel.setEmployeeData(
                    e.target.name,
                    e.target.checked ? true : false
                )
                this.props.onDraft()
                return
            }

            this.employeeAddEditModel.setEmployeeData(
                e.target.name,
                e.target.value
            )
        }

        // If select
        if (select && e.name) {
            this.employeeAddEditModel.setEmployeeData(e.name, select.value)
        }
        this.props.onDraft()
    }

    render(): JSX.Element {
        const { data } = this.state
        const salesTaxCodes = []

        for (const i of this.state.salesTaxCodes) {
            const tmp = { value: i.taxc, label: i.taxcn }
            if (
                i.taxc !== null &&
                i.taxcn !== null &&
                i.taxc !== '' &&
                i.taxcn !== ''
            )
                salesTaxCodes.push(tmp)
            if (i.taxc === data.postaxc) this.defaultSalesTaxCode = tmp
        }

        if (data && data?.poswhc) {
            for (const sc of this.state.showroomCodes) {
                if (sc.value === data.poswhc) this.defaultShowroomCode = sc
            }
        }

        return (
            <>
                <Container className="ms-0">
                    <Row xs={1} lg={2} className="mb-0 mb-lg-5">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={5}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Commision %
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={2}>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                name="poscomperc"
                                                defaultValue={data?.poscomperc}
                                                onChange={
                                                    this.handleEmployeeChange
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={5}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Default Showroom (Warehouse Code)
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.state.showroomCodes}
                                        className="react-select"
                                        placeholder=""
                                        name="poswhc"
                                        defaultValue={this.defaultShowroomCode}
                                        onChange={this.handleEmployeeChange.bind(
                                            this,
                                            { name: 'poswhc' }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={5}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Default Sales Tax Code
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={salesTaxCodes}
                                        className="react-select"
                                        placeholder=""
                                        name="postaxc"
                                        defaultValue={this.defaultSalesTaxCode}
                                        onChange={this.handleEmployeeChange.bind(
                                            this,
                                            { name: 'postaxc' }
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Allow Receipt/Invoice Date Change"
                                        name="poschangedate"
                                        type="checkbox"
                                        id="poschangedate"
                                        defaultChecked={data?.poschangedate}
                                        onChange={this.handleEmployeeChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Allow Manual Items and Price Edits"
                                        name="poseditprice"
                                        type="checkbox"
                                        id="poseditprice"
                                        defaultChecked={data?.poseditprice}
                                        onChange={this.handleEmployeeChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default observer(EmployeeAddEditNotes)
