import React, { useState } from 'react'
import { isValidUrl } from '../../../utilities/Validation'
import { displayAlert } from '../../../utilities/Response'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

export const AddLinkModal = ({ onConfirm, onCancel }) => {
    const [data, setData] = useState({ url: '', name: '' })

    const handleChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value })
    }

    const handleConfirm = () => {
        if (!isValidUrl(data.url)) {
            displayAlert('danger', 'Invalid URL')

            return
        }

        onConfirm(data)
    }

    return (
        <Modal
            show={true}
            aria-labelledby="action-modal"
            className="a-modal"
            backdrop="static"
            centered
        >
            <Modal.Body>
                <Row className="mb-3">
                    <Col className="px-5">
                        <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
                            Add Link
                        </h5>
                        <div className="text-center mx-auto fw-bold pt-4">
                            <Row className="align-items-center mb-3">
                                <Col xs={2} className="text-end">
                                    <Form.Label className="mb-0">
                                        URL
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        id="url"
                                        placeholder="https://example.com"
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col xs={2} className="text-end">
                                    <Form.Label className="mb-0">
                                        Name
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        id="label"
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="bg-ivory py-3 px-5">
                <Row className="w-100">
                    <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                        <Button
                            variant="primary"
                            onClick={handleConfirm}
                            className="w-100 m-2"
                        >
                            OK
                        </Button>

                        <Button
                            variant="trans-light border-secondary-ash"
                            onClick={onCancel}
                            className="w-100 m-2"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
