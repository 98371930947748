import React from 'react'
import { Outlet } from 'react-router-dom'
import { WithRouter } from '../../../../helpers/Router'
import ThreadComponent from '../../ThreadComponent'

class Catalog extends ThreadComponent {
    render() {
        return <Outlet />
    }
}

export default WithRouter(Catalog)
