import { cloneDeep } from 'lodash'
import { round } from '../Number'

type TableSearch = {
    data: any[]
    searchProps: any
    customFilter: any
    callback: any
}

export function tableSearch({
    data,
    searchProps,
    customFilter,
    callback,
}: TableSearch) {
    const items = [...data]
    const filters = searchProps

    let newItems = items
    const hasFilters = Object.keys(filters).filter(
        (f) => filters[f].value !== ''
    ).length

    if (hasFilters && items) {
        Object.keys(filters).map((key) => {
            const filter = filters[key]
            const ftype = filter.type
            const fvalue = filter.value ?? ''
            const fexp = filter.exp
            const custom = Boolean(filter.custom)
            if (fvalue === '') {
                return
            }

            newItems = newItems.filter((item: any) => {
                let itemValue = item[key]
                if (custom && customFilter) {
                    itemValue = customFilter(item, key)
                }

                switch (ftype) {
                    case 'number':
                        switch (fexp) {
                            case '>=':
                                return itemValue >= fvalue
                            case '<=':
                                return itemValue <= fvalue
                            default:
                                return round(itemValue, 2) == fvalue
                        }

                    default:
                        switch (key) {
                            case 'custom':
                                return customFilter(itemValue, key)
                            default:
                                return (
                                    itemValue &&
                                    (itemValue || '')
                                        .toLowerCase()
                                        .replace(/\r\n/g, ' ')
                                        .indexOf(fvalue.toLowerCase()) > -1
                                )
                        }
                }
            })
        })
    }

    callback && callback(newItems)
}
