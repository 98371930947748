export function mapUseDisc(usedisc: string) {
    switch (usedisc) {
        case 'dmMarkup':
            return 0
        case 'dmDiscount':
            return -1
        case 'DmFee':
            return 1
    }
}

export function adjustData(data: any) {
    const usedisc = mapUseDisc(data.usedisc ?? data.useDisc)
    type ComponentCalc = Partial<typeof data>
    let adjustedData: ComponentCalc = {}
    adjustedData.usedisc = usedisc
    switch (usedisc) {
        case 0:
            adjustedData = {
                estunitcost: data.estunitcost ?? data.estUnitCost,
                quantity: data.quantity,
                estcost: data.estcost ?? data.estCost,
                estmarkup: data.estmarkup ?? data.estMarkup,
                estprice: data.estprice ?? data.estPrice,
                markup: data.markup,
                usedisc: usedisc,
            }
            if (data.useterms || data.useTerms) {
                adjustedData.unitlist = data.unitlist ?? data.unitList
                adjustedData.list = data.list
            }
            break
        case 1:
            adjustedData = {
                feeunitcost: data.feeunitcost ?? data.feeUnitCost,
                quantity: data.quantity,
                feecost: data.feecost ?? data.feeCost,
                estprice: data.estprice ?? data.estPrice,
                markup: data.markup,
                usedisc: usedisc,
            }
            break
        case -1:
            adjustedData = {
                estunitcost: data.estunitcost ?? data.estUnitCost,
                quantity: data.quantity,
                estcost: data.estcost ?? data.estCost,
                estdisc: data.estdisc ?? data.estDisc,
                estprice: data.estprice ?? data.estPrice,
                markup: data.markup,
                unitlist: data.unitlist ?? data.unitList,
                list: data.list,
                usedisc: usedisc,
            }
            break
    }

    if (data.useterms || data.useTerms) {
        adjustedData.bterm1 = data.bterm1 ?? data.bTerm1
        adjustedData.bterm2 = data.bterm2 ?? data.bTerm2
        adjustedData.bterm3 = data.bterm3 ?? data.bTerm3
    }
    if (data?.supplier || data?.Supplier) {
        adjustedData.usedisc = usedisc
        adjustedData.markup = data?.markup
        adjustedData.supdep = data?.supdep
        adjustedData.useterms = data?.useterms
    }
    return adjustedData
}
