import { action, makeAutoObservable } from 'mobx'
import { EmployeeListResponse, EmployeeService } from './api/EmployeeService'

interface SelectOptions {
    label: string
    value: string
}

export default class EmployeeListViewModel {
    private employeeService: EmployeeService
    private _employeeList: EmployeeListResponse[] = []
    private _isLoaded = false
    public _params = ''

    get employeeList(): EmployeeListResponse[] {
        return this._employeeList
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    get employeeListSelectOptions(): SelectOptions[] {
        const employeeOptions: SelectOptions[] = []
        if (this._employeeList && this._employeeList.length) {
            for (const emp of this._employeeList) {
                if (emp.vendor) {
                    employeeOptions.push({
                        value: emp.vendor || '',
                        label: emp.vendorn || '',
                    })
                }
            }
        }

        return employeeOptions
    }

    setEmployeeList(employees: any): EmployeeListViewModel {
        this._employeeList = employees
        return this
    }

    setParams(params: string): EmployeeListViewModel {
        this._params = params
        return this
    }

    setIsLoaded(isLoaded: boolean): EmployeeListViewModel {
        this._isLoaded = isLoaded
        return this
    }

    findByVendor(vendor: string): EmployeeListResponse | null {
        let employee: EmployeeListResponse | null = null
        if (this._employeeList && this._employeeList.length) {
            for (const emp of this._employeeList) {
                if (emp.vendor === vendor) {
                    employee = emp
                }
            }
        }

        return employee
    }

    updateSortName(code: string | null, updatedVal: string) {
        action(() => {
            const employee = this._employeeList.find(
                (emp) => code && emp.vendor === code
            )
            if (employee) {
                employee['sortName'] = updatedVal
            }
        })()
    }

    reset(): EmployeeListViewModel {
        this._params = ''
        this._employeeList = []
        this._isLoaded = false
        return this
    }

    constructor(employeeService: EmployeeService) {
        this.employeeService = employeeService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        this.fetchEmployees(1, '?$filter=inactive eq false')
    }

    fetchEmployees(page = 1, query = ''): void {
        // Set loading state
        this.setIsLoaded(false)

        this.employeeService
            .getEmployees(query)
            .then((response: any) => {
                this.setIsLoaded(true)

                if (response) {
                    this.setEmployeeList(response)
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }
}
