/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-extraneous-class */
interface Configuration {
    apiBaseUrl: string
    apiAuthBaseUrl: string
    url: string
}

enum AppConfigurationType {
    DEV,
    PRODUCTION,
}

export class DevConfiguration implements Configuration {
    apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? 'https://api.test.designmanager.com'
    apiAuthBaseUrl = process.env.REACT_APP_API_AUTH_BASE_URL ?? 'https://my.test.designmanager.com'
    url = process.env.REACT_APP_URL ?? 'https://my.test.designmanager.com'
}

class ProductionConfiguration implements Configuration {
    apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? 'https://api.designmanager.com'
    apiAuthBaseUrl = process.env.REACT_APP_API_AUTH_BASE_URL ?? 'https://my.designmanager.com'
    url = process.env.REACT_APP_URL ?? 'https://my.test.designmanager.com'
}

class ConfigurationFactory {
    static makeConfiguration(type: AppConfigurationType) {
        switch (type) {
            case AppConfigurationType.DEV:
                return this.makeDevConfiguration()
            case AppConfigurationType.PRODUCTION:
                return this.makeProductionConfiguration()
        }
    }

    private static makeDevConfiguration() {
        return new DevConfiguration()
    }

    private static makeProductionConfiguration() {
        return new ProductionConfiguration()
    }
}

export class AppConfiguration {
    static dev = ConfigurationFactory.makeConfiguration(
        AppConfigurationType.DEV
    )
    static production = ConfigurationFactory.makeConfiguration(
        AppConfigurationType.PRODUCTION
    )
    static default = AppConfiguration.dev
}
