import React from 'react'
import { NavLink } from 'react-router-dom'
import { Col, Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import { ApiService } from '../../../../lib/api/HttpService'

class CatalogItemSpecification extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            item: {},
            vendorOptions: [],
            defaultManufacturer: null,
            defaultSource: null,
            dataIsLoaded: true,
            templateOption: [],
            defaultTemplate: null,
        }
        this.api = new ApiService()
    }

    async componentDidMount() {
        if (Object.keys(this.props.item).length > 0) {
            this.setState({
                item: this.props.item,
                vendors: this.props.vendors,
                dataIsLoaded: true,
            })
        }

        this.loadSpecificationTemplates()
        this.loadVendors()
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.vendors !== this.props.vendors) {
            this.setState({
                item: this.props.item,
                vendors: this.props.vendors,
                dataIsLoaded: true,
            })
        }
    }

    async loadSpecificationTemplates() {
        const templates = await this.api.getSpecificationTemplates('')
        let templatesOptions = [],
            defaultTemplate = null
        if (templates && templates.length) {
            for (let tmp of templates) {
                templatesOptions.push({
                    value: tmp.speccode,
                    label: tmp.speccodename,
                })

                if (tmp.speccode === this.state.item.speccode) {
                    defaultTemplate = {
                        value: tmp.speccode,
                        label: tmp.speccodename,
                    }
                }
            }
        }

        this.setState({
            templateOption: templatesOptions,
            defaultTemplate: defaultTemplate,
        })
    }

    async loadVendors() {
        let vendorsData = await this.api.getVendors()

        let vendors = [],
            defaultManufacturer = null,
            defaultSource = null
        for (let v of vendorsData) {
            vendors.push({ value: v.vendor, label: v.vendorn })
            if (v.vendor === this.state.item.specmfg) {
                defaultManufacturer = {
                    label: v.vendorn,
                    value: v.vendor,
                }
            }

            if (v.vendor === this.state.item.specsourcesup) {
                defaultSource = {
                    label: v.vendorn,
                    value: v.vendor,
                }
            }
        }

        this.setState({
            vendorOptions: vendors,
            defaultManufacturer,
            defaultSource,
        })
    }

    componentInit() {
        this.hasFormAction = true
    }

    handleChange(event) {
        let fieldName = event.target.name
        let fleldVal = event.target.value
        this.setState({ item: { ...this.state.item, [fieldName]: fleldVal } })
    }

    render() {
        return (
            <>
                <Form.Group controlId="formGridText1">
                    <Row xs={1} lg={2} className="py-4">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Spec/Template No.</strong>
                                    </Form.Label>
                                </Col>
                                <Col lg={5}>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.state.templateOption}
                                        defaultValue={
                                            this.state.defaultTemplate
                                        }
                                        className="react-select"
                                        placeholder="Please select"
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Material"
                                        name="group2"
                                        type="checkbox"
                                        id={`inline-radio-2`}
                                        defaultChecked={
                                            this.state.item
                                                ? this.state.item.specmaterial
                                                : false
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Plan No.</strong>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        defaultValue={this.state.item.planno}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Manufacturer</strong>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.state.vendorOptions}
                                        defaultValue={
                                            this.state.defaultManufacturer
                                        }
                                        className="react-select"
                                        placeholder="Please select"
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Source</strong>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.state.vendorOptions}
                                        defaultValue={this.state.defaultSource}
                                        className="react-select"
                                        placeholder="Please select"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="bg-secondary-grey  roundedx-4">
                        <Row xs={1} lg={2} className="py-4">
                            <Col>
                                <Row className="align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <h6 className="fw-bold mb-0">
                                            Attributes
                                        </h6>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Row xs={1} lg={2} className="py-4">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Title</strong>
                                    </Form.Label>
                                </Col>
                                <Col lg={5}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        defaultValue={
                                            this.state.item
                                                ? this.state.item.spect1
                                                : ''
                                        }
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        <strong>Description</strong>
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        defaultValue={
                                            this.state.item
                                                ? this.state.item.specdesc
                                                : ''
                                        }
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                ></Col>
                                <Col>
                                    <NavLink
                                        to="/items/catalogs/items"
                                        className="btn btn-lg btn-icon text-primary-green bg-tertiary-green fw-bold"
                                    >
                                        <i className="ri-add-line"></i> Add
                                    </NavLink>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="bg-secondary-grey roundedx-4">
                        <Row xs={1} lg={2} className="py-4">
                            <Col>
                                <Row className="align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <h6 className="fw-bold mb-0">
                                            Instructions
                                        </h6>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Row xs={1} lg={2} className="py-4">
                        <Col className="mb-3 mb-lg-0">
                            <WysiwygEditor
                                content={
                                    this.state.item
                                        ? this.state.item.specdesc
                                        : ''
                                }
                            ></WysiwygEditor>
                        </Col>
                    </Row>
                </Form.Group>
            </>
        )
    }
}

export default WithRouter(CatalogItemSpecification)
