import { isEmpty } from 'lodash'
import { getCookie } from '../Auth'
import { getLocalStorage, setLocalStorage } from '../LocalStorage'

class FilterSort {
    modKey: string
    sortPrefix: string
    filterPrefix: string
    searchPrefix: string

    constructor(modKey: string) {
        this.modKey = modKey
        this.sortPrefix =
            'sort_dm_' +
            getCookie('dmInstanceId') +
            getCookie('dmUsercode') +
            '_'
        this.filterPrefix =
            'filter_dm_' +
            getCookie('dmInstanceId') +
            getCookie('dmUsercode') +
            '_'
        this.searchPrefix =
            'search_dm_' +
            getCookie('dmInstanceId') +
            getCookie('dmUsercode') +
            '_'
    }

    /**
     * Sort
     */
    setSort(value: any) {
        setLocalStorage(this.sortPrefix + this.modKey, value)
    }

    setDefaultSort(value: any) {
        const sort = this.getSort()
        this.setSort(sort ?? value)
    }

    getSort() {
        return getLocalStorage(this.sortPrefix + this.modKey)
    }

    getSortArr() {
        const sort = getLocalStorage(this.sortPrefix + this.modKey)

        return sort ? sort.split(' ') : []
    }

    isActiveSort(sort: string) {
        const sortArr = this.getSortArr()
        if (sortArr[0] === sort) {
            return `active ${sortArr[1]}`
        }

        return ''
    }

    /**
     * Filters
     */
    setFilter(name: string, value: any) {
        const storageName = this.filterPrefix + this.modKey
        const filter = getLocalStorage(storageName, true) ?? {}

        filter[name] = value
        setLocalStorage(storageName, filter, true)
    }

    getFilter(name: string) {
        const filters =
            getLocalStorage(this.filterPrefix + this.modKey, true) ?? {}

        return filters[name]
    }

    getFilters(exceptKeys = []) {
        const filters =
            getLocalStorage(this.filterPrefix + this.modKey, true) ?? {}

        if (exceptKeys.length) {
            for (const ek of exceptKeys) {
                delete filters[ek]
            }
        }

        return filters
    }

    isFilterSortKey(key: string) {
        return (
            key &&
            (key?.indexOf(this.filterPrefix) > -1 ||
                key?.indexOf(this.sortPrefix) > -1)
        )
    }

    deleteSortFilterStorage() {
        for (let i = 0; i < window.localStorage.length; i++) {
            const key = window.localStorage.key(i)
            if (this.isFilterSortKey(key ?? '') && key) {
                window.localStorage.removeItem(key)
            }
        }
    }

    /**
     * Searches
     */
    setSearch(name: string, value: any) {
        const storageName = this.searchPrefix + this.modKey
        const search = getLocalStorage(storageName, true) ?? {}

        search[name] = value
        setLocalStorage(storageName, search, true)
    }

    setSearches(searches: any) {
        const storageName = this.searchPrefix + this.modKey
        setLocalStorage(storageName, searches, true)
    }

    setIsActiveSearch(status: boolean) {
        const storageName = this.searchPrefix + this.modKey + '_is_active'
        setLocalStorage(storageName, status, true)
    }

    getSearch(name: string) {
        const storageName = this.searchPrefix + this.modKey
        const searches = getLocalStorage(storageName, true) ?? {}

        return searches[name]
    }

    getSearches(exceptKeys = []) {
        const storageName = this.searchPrefix + this.modKey
        const filters = getLocalStorage(storageName, true) ?? {}

        if (exceptKeys.length) {
            for (const ek of exceptKeys) {
                delete filters[ek]
            }
        }

        return filters
    }

    getSearchValue(search: any, props?: any) {
        search = isEmpty(search) ? {} : search
        let value = search[props?.valueKey ?? 'value'] ?? null

        if (search?.type === 'string') {
            value = search[props?.valueKey ?? 'value']
        } else if (search?.type === 'number') {
            if (search.min || search.max) {
                value = `${search.min ?? ''}-${search.max ?? ''}`
            }
        } else if (search?.type === 'date') {
            if (search.min) {
                value = [new Date(search.min), new Date(search.max)]
            }
        } else if (search?.type === 'month' && value) {
            value = new Date(value)
        }

        return value
    }

    isSearchActive() {
        const storageName = this.searchPrefix + this.modKey + '_is_active'
        return getLocalStorage(storageName, true) === true
    }

    getValue(field: string, options?: any) {
        const search = this.getSearch(field)
        return this.getSearchValue(search, options)
    }

    getSearchExpression(field: string) {
        const search = this.getSearch(field)
        return search?.exp ?? '>='
    }

    setSearchExperation(field: string, exp: string, classRef: any) {
        const search = classRef.state.searchProperties[field]
        classRef.setState(
            {
                searchProperties: {
                    ...classRef.state.searchProperties,
                    [field]: {
                        ...(search ?? {}),
                        exp,
                    },
                },
            },
            () => {
                this.setSearches(classRef.state.searchProperties)
                classRef.searchHandler()
            }
        )
    }
}

/**
 * Is Active Sort
 *
 * @param {string} name field name.
 * @param {mixed} ref class reference.
 * @returns {string} class name.
 */
export function IAS(name: string, ref: any): string {
    return ref ? ref.fs.isActiveSort(name) : ''
}

export default FilterSort
