import React from 'react'
import { WithRouter } from '../../../../helpers/Router'
import ThreadComponent from '../../ThreadComponent'
import { Container, Button, Form, Row, Col, Modal } from 'react-bootstrap'
import InputBar from '../../../components/InputBar'
import { FooterFormAction } from '../../../../templates/components/Form'
import { ApiService } from '../../../../lib/api/HttpService'
import Select from 'react-select'
import { displayAlert, displayAlertError } from '../../../../utilities/Response'
import MSG from '../../../../defaults/Message'
import { isEmpty } from 'lodash'
import { Event } from '../../../../utilities/DOM'

class JournalEntryAddDialog extends ThreadComponent {
    constructor(props) {
        super(props)

        this.editData = this.props.editData
        this.state = {
            accounts: [],
            selectedAccount:
                this.editData.account !== undefined
                    ? {
                          value: this.editData.account,
                          label: `${
                              this.editData.account
                          } - ${this.editData.accountName
                              .split('-')
                              .pop()
                              .trim()}`,
                          account: this.editData.account,
                      }
                    : { value: '', label: 'Please select', account: '' },
            isDebit: this.editData.isDebit,
            isCredit: !this.editData.isDebit,
            amount: this.editData.amount || 0,
            balance: this.props.netDebAndCred || 0,
            hasSelectedPaymentOpt: this.editData.hasOwnProperty('account'),
            isLoading: true,
        }
        this.api = new ApiService()
    }

    componentDidMount() {
        this.getGeneralLedgerAccounts()
    }

    async getGeneralLedgerAccounts() {
        const gEAccounts = await this.api.get('generalledgeraccounts')

        if (gEAccounts) {
            let acc = gEAccounts.map((a) => {
                return {
                    value: a.account,
                    label: `${a.account}-${a.accountn}`,
                    account: a.account,
                }
            })

            this.setState({
                accounts: acc,
                isLoading: false,
            })
        }
    }

    isValidData() {
        let valid = true
        const { selectedAccount } = this.state
        if (!selectedAccount.value) {
            displayAlertError(MSG.error.GLJe.editNoAcc)
            valid = false
        }

        if (!this.state.isCredit && !this.state.isDebit) {
            displayAlertError(MSG.error.GLJe.editNoOpt)
            valid = false
        }

        if (isNaN(this.state.amount) || !this.state.amount) {
            displayAlertError(MSG.error.GLJe.editInvAmt)
            valid = false
        }

        for (const [i, entry] of this.props.dataCreated.entries()) {
            // Beware of the logic below
            // First, test if its new,
            // Then we compare if selected account exists in the created already.
            // We also compare if created account has changed to new account and check similarity with selected.
            if (
                isEmpty(this.editData) &&
                ((entry.account === selectedAccount.account &&
                    !entry.newaccount) ||
                    entry.newaccount === selectedAccount.account)
            ) {
                displayAlertError(MSG.error.GLJe.editSameCode)
                valid = false
            }

            // Beware of the logic below
            // First, test if edit,
            // Then we compare if the edit account is not the same with selected and created accounts has similar with selected
            // Or if created new(an existing but changed account type) accounts is similar with selected
            if (
                !isEmpty(this.editData) &&
                this.editData.account !== selectedAccount.account &&
                entry.account === selectedAccount.account
            ) {
                displayAlertError(MSG.error.GLJe.editSameCode)
                valid = false
            }
        }

        return valid
    }

    handleSubmit = (e, isNew) => {
        e.preventDefault()

        if (!this.isValidData()) {
            return
        }

        const data = {
            accountName: this.state.selectedAccount.label,
            isDebit: this.state.isDebit,
            balance: this.state.balance,
            amount: this.state.isDebit
                ? Number(this.state.amount)
                : Number(this.state.amount),
        }

        if (isEmpty(this.editData)) {
            data.account = this.state.selectedAccount.account
            this.props.onOkay(data)
        } else {
            data.account = this.editData.account
            data.newaccount = this.state.selectedAccount.account
            this.props.updatedData(data)
        }

        this.resetState()
        if (isNew) {
            Event('[name="paymentmethod"]').uncheck()
            return
        }
        this.handleModalClose()
    }

    handleChange = (e, meta = {}) => {
        let newState = {}

        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            newState.selectedAccount = e
        } else if (e.target.getAttribute('data-field') === 'amount') {
            let currentNetDebAndCred = this.formatNumber(
                this.props.netDebAndCred
            )

            const amount_val = e.target.value

            if (/^\d*\.?\d{0,2}$/.test(amount_val)) {
                newState.amount = amount_val
                if (!isNaN(amount_val)) {
                    if (!isNaN(currentNetDebAndCred)) {
                        currentNetDebAndCred = this.state.isDebit
                            ? currentNetDebAndCred +
                              this.formatNumber(amount_val)
                            : currentNetDebAndCred -
                              this.formatNumber(amount_val)
                        newState.balance = this.formatNumber(
                            currentNetDebAndCred === 0
                                ? Math.abs(currentNetDebAndCred)
                                : currentNetDebAndCred
                        )
                    } else {
                        newState.balance = this.formatNumber(
                            this.state.isDebit ? amount_val : -amount_val
                        )
                    }
                }
            } else {
                newState.amount = ''
                newState.balance = isNaN(currentNetDebAndCred)
                    ? 0
                    : this.formatNumber(
                          currentNetDebAndCred === 0 ? 0 : currentNetDebAndCred
                      )
            }
        }
        this.setState(newState)
    }

    formatNumber = (num) => {
        return Number(Number(num).toFixed(2))
    }

    handlePaymentChange = (e) => {
        const type = e.target.value
        const isDebit = type === 'debit'
        const isCredit = type === 'credit'
        const amount = this.state.amount

        let balance = this.props.netDebAndCred
        if (!isNaN(amount)) {
            balance = isDebit
                ? balance + this.formatNumber(amount)
                : balance - this.formatNumber(amount)
        }

        this.setState({
            hasSelectedPaymentOpt: true,
            balance: this.formatNumber(balance),
            isDebit: isDebit,
            isCredit: isCredit,
        })
    }

    handleModalClose = () => {
        this.props.onCancel()
    }

    resetState = () => {
        this.editData = {}
        this.setState({
            selectedAccount: { value: '', label: 'Please select', account: '' },
            isDebit: false,
            isCredit: false,
            amount: 0,
            hasSelectedPaymentOpt: false,
        })
    }

    renderCreditAndDebitField() {
        return (
            <InputBar className="xsmall align-items-start">
                <InputBar.Links>
                    <InputBar.Link>
                        <Form.Label className="ilabel">
                            Net Debits and Credits
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                type="text"
                                placeholder="0.00"
                                size="sm"
                                readOnly
                                value={parseFloat(this.state.balance).toFixed(
                                    2
                                )}
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderAccountDropdown() {
        return (
            <div className="a-filter border-1 border-sand bg-ivory roundedx-4 has-expand row align-items-center">
                <div className="col-3">
                    <Form.Label className="text-end">
                        <strong>Account</strong>
                    </Form.Label>
                </div>
                <div className="col-9">
                    <Select
                        key={`${Math.floor(Math.random() * 1000)}-min`}
                        name="glaccount"
                        options={this.state.accounts}
                        className="react-select"
                        onChange={this.handleChange}
                        defaultValue={this.state.selectedAccount}
                        menuPosition={'fixed'}
                        isDisabled={this.state.isLoading}
                        styles={{
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                            }),
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                            }),
                        }}
                    />
                </div>
            </div>
        )
    }

    content() {
        return (
            <Form.Group className="m-4">
                <Row xs={12} lg={12} className="mb-4">
                    <Col>{this.renderAccountDropdown()}</Col>
                </Row>
                <Row xs={12} lg={12} className="mb-4">
                    <Col lg={12}>
                        <Row xs={12} lg={12} className="align-items-center">
                            <Col lg={2}>
                                <Form.Check
                                    inline
                                    label="Credit"
                                    name="paymentmethod"
                                    type="radio"
                                    id="formCredit"
                                    data-type="credit"
                                    value="credit"
                                    defaultChecked={
                                        this.editData.isDebit !== undefined &&
                                        !this.editData.isDebit
                                    }
                                    onClick={this.handlePaymentChange}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    inline
                                    label="Debit"
                                    name="paymentmethod"
                                    type="radio"
                                    id="formDebit"
                                    data-type="debit"
                                    value="debit"
                                    defaultChecked={
                                        this.editData.isDebit !== undefined &&
                                        this.editData.isDebit
                                    }
                                    onClick={this.handlePaymentChange}
                                />
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    id="amount"
                                    data-field="amount"
                                    value={this.state.amount}
                                    placeholder="Amount"
                                    onChange={this.handleChange}
                                    disabled={!this.state.hasSelectedPaymentOpt}
                                    onKeyPress={(event) => {
                                        const nextValue =
                                            event.target.value.concat(event.key)
                                        if (
                                            !/^\d*\.?\d{0,2}$/.test(nextValue)
                                        ) {
                                            event.preventDefault()
                                        }
                                    }}
                                    onFocus={(e) => {
                                        if (e.target.value == 0) {
                                            e.target.value = ''
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {this.editData.account === undefined ? (
                            this.renderCreditAndDebitField()
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
                {/* <Row className="mt-4">
                    <Col>
                        <FooterFormAction>
                            <a
                                className="btn btn-primary btn-icon btn-action me-0 me-lg-2 align-items-center"
                                name="ok"
                                onClick={this.handleSubmit}
                            >
                                <i className="ri-add-line ri-lg"></i>OK
                            </a>
                            <a
                                onClick={this.handleModalClose}
                                className="btn btn-trans-light btn-icon btn-action mb-2 mb-lg-0 align-items-center"
                            >
                                <i className="ri-close-line ri-lg text-primary-red"></i>
                                Cancel
                            </a>
                        </FooterFormAction>
                    </Col>
                </Row> */}
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                <Modal
                    size="md"
                    show={this.props.isShowing}
                    aria-labelledby="action-modal"
                    className="a-modal"
                    backdrop="static"
                    centered
                >
                    <div className="content-padding min-height">
                        <Container fluid className="mt-4">
                            {this.content()}
                        </Container>
                    </div>
                    <Modal.Footer className="bg-ivory py-3 px-4">
                        <Row className="w-100">
                            <Col
                                className="d-block d-sm-flex"
                                lg={{ span: 12 }}
                            >
                                <Button
                                    variant="primary"
                                    onClick={(e) => this.handleSubmit(e, true)}
                                    className="btn-icon w-100"
                                >
                                    <i className="ri-add-line ri-lg"></i>
                                    Save & New
                                </Button>

                                <Button
                                    variant="primary"
                                    onClick={(e) => this.handleSubmit(e, false)}
                                    className="btn-icon w-100 my-2 my-sm-0 mx-sm-3"
                                >
                                    <i className="ri-add-line ri-lg"></i>Save
                                </Button>

                                <Button
                                    variant="trans-light border-secondary-ash"
                                    onClick={this.handleModalClose}
                                    className="btn-icon w-100"
                                >
                                    <i className="ri-close-line ri-lg text-primary-red"></i>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default WithRouter(JournalEntryAddDialog)
