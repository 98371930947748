import React from 'react'
import { Row, Col, Modal, Button, Form, Table } from 'react-bootstrap'
import { WithRouter } from '../../../helpers/Router'
import ThreadComponent from '../ThreadComponent'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { ApiService } from '../../../lib/api/HttpService'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../utilities/Response'
import dayjs from 'dayjs'
import {
    displaySortableTableData,
    displaySortableTableHeader,
} from '../../../app/components/table/TableDisplay'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import { currencyFormat } from '../../../helpers/Number'
import { getCookie } from '../../../utilities/Auth'
import { delay } from 'lodash'
import { loadingWindowHTML } from '../../../utilities/DOM'
import { enableTableSortData } from '../../../utilities/modules/TableSorting'
import MSG from '../../../defaults/Message'

class PaymentActionPrintedChecksModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            disbursements: [],
            data: {},
            checkingAccountNo: '',
            checkDate: new Date(),
            fiscalMonth: new Date(),
            isLoaded: true,
            sortProperty: 'supplier asc',
            runTotalAmount: parseFloat(this.props.totalAmount),
            accountBalance: 0,
        }
        this.selectedCheckingAccounts = []
        this.api = new ApiService()
        this.updatedData = {
            cashAccountNu: '',
            checkDate: dayjs(this.state.fiscalMonth).format('YYYY-MM-DD'),
            fiscalMonth: dayjs(this.state.fiscalMonth).format('MM/YYYY'),
            NextCheckNum: '',
        }

        this.tableHeaders = [
            {
                label: 'Check No.',
                sortable: true,
                searchable: false,
                field: 'checknum',
            },
            {
                label: 'Vendor Code',
                sortable: true,
                searchable: false,
                field: 'supplier',
            },
            {
                label: 'Payee',
                sortable: true,
                searchable: false,
                field: 'payee',
            },
            {
                label: 'Amount',
                sortable: true,
                searchable: false,
                field: 'amount',
                override: (data) => {
                    return currencyFormat(data.amount)
                },
            },
        ]

        this.inputTimer = null
    }

    componentDidMount() {
        this.sortTable()
    }

    sortTable() {
        delay(() => {
            enableTableSortData(
                this,
                (sortProperty) =>
                    this.setState({
                        sortProperty,
                        isGrid: false,
                    }),
                '.mo-printed-check-table .a-table-heading .sort'
            )
        }, 700)
    }

    changePageHandler = () => {
        if (this.updatedData.cashAccountNu !== '') {
            this.getDisbursement(false)
        }
    }

    handleChange = (e, meta = {}) => {
        clearTimeout(this.inputTimer)
        let key, value
        if (e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        this.updatedData[key] = value

        if (key === 'cashAccountNu') {
            if (
                !this.selectedCheckingAccounts.find(
                    (account) => account === value
                )
            ) {
                this.selectedCheckingAccounts.push(value)
            }
        }
        if (key === 'cashAccountNu' || key === 'NextCheckNum') {
            this.setState({
                checkingAccountNo:
                    meta?.name === 'cashAccountNu' ? e?.nextchecknum : value,
            })

            if (key === 'cashAccountNu') {
                this.updatedData['NextCheckNum'] =
                    e?.nextchecknum ?? this.state.checkingAccountNo
            }
        }

        this.inputTimer = delay(
            () => {
                this.checkDisbursement()
            },
            key === 'NextCheckNum' ? 700 : 100
        )
    }

    handleDateChange = (key, date) => {
        this.setState({
            [key]: date,
        })

        date = dayjs(date).format(
            key === 'fiscalMonth' ? 'MM/YYYY' : 'YYYY-MM-DD'
        )
        this.updatedData[key] = date
        this.checkDisbursement()
    }

    checkDisbursement() {
        let valid = true
        Object.keys(this.updatedData).forEach((k) => {
            if (!this.updatedData[k]) {
                valid = false
            }
        })

        if (valid) {
            this.getDisbursement()
        }
    }

    async getDisbursement(isGrid = true) {
        try {
            let accountBalance = this.state.accountBalance
            this.setState({
                isLoaded: false,
            })

            if (isGrid) {
                const gdata = await this.api.postJson(
                    'temporarycheckdisbursments/checkrun/grid',
                    {
                        ...this.updatedData,
                    }
                )

                if (gdata) {
                    accountBalance = gdata.checkingAccountBalanace
                }
            }

            const data = await this.api.get(
                'temporarycheckdisbursments/checkrun',
                `?$orderby=${
                    this.state.sortProperty
                }&$filter=usercode eq ${parseInt(getCookie('dmUsercode'))}`
            )

            this.setState({
                isLoaded: true,
                disbursements: data ?? [],
                accountBalance,
            })
        } catch (error) {
            this.setState({
                isLoaded: true,
            })
            displayAlertError(getErrorMessage(error))
        }
    }

    handleRunReport = async (e) => {
        e.preventDefault()
        // Lets solve popup blocker.
        const windowReport = window.open('', '_blank')
        windowReport.document.write(loadingWindowHTML())
        displayAlertLoader(
            'Generating report, please wait<span class="dot-load"></span>'
        )
        try {
            let runReportParameters = [
                {
                    parameterId: 341,
                    value: JSON.stringify({
                        userCode: parseInt(getCookie('dmUsercode')),
                    }),
                },
            ]
            const reportArgs = {
                instanceId: getCookie('dmInstanceId'),
                userId: getCookie('dmUsercode'),
                reportId: 1067,
                reportFormatId: 1166,
                reportObjectType: 'Report',
                runReportParameters,
                objectId: '',
            }

            const report = await this.api.postJson('runreports', reportArgs)

            if (report?.isOk === false) {
                windowReport.close()
                displayAlertError(report?.internalError ?? report?.userError)
            } else if (!report?.id) {
                windowReport.close()
                displayAlertError(
                    report?.internalError ??
                        report?.userError ??
                        'Failed to run report. Please contact the administrator.'
                )
            } else {
                displayAlertLoader(
                    'Please wait for your report to be generated<span class="dot-load"></span>'
                )

                const maxRequest = 5
                let requestCount = 0
                let interval = window.setInterval(async () => {
                    try {
                        const reportId = await this.api.get(
                            'runreports',
                            `?$filter=id eq ${report?.id}`
                        )
                        const fileId = reportId
                            ? reportId[0].completedFileId
                            : null
                        if (fileId) {
                            clearInterval(interval)
                            const label = 'Printed Checks'
                            const { link } = await this.api.getPublicMappings(
                                fileId,
                                label
                            )
                            windowReport.location.href = link
                            displayAlertSuccess(MSG.success.report.msg)
                        } else if (requestCount === maxRequest) {
                            clearInterval(interval)
                            displayAlertError(
                                'Report is on pending status. Try again later.'
                            )
                        }
                    } catch (error) {
                        displayAlertError(getErrorMessage(error))
                        windowReport.close()
                    }
                    requestCount++
                }, 5000)
            }
        } catch (error) {
            windowReport.close()
            displayAlertError(getErrorMessage(error))
        }
    }

    handleRunPostChecks = async (e) => {
        e.preventDefault()
        const { disbursements } = this.state

        try {
            if (this.updatedData.cashAccountNu === '') {
                displayAlertError('Please select a Checking Account first.')
                return
            }
            displayAlertLoader(
                'Posting payment, please wait<span class="dot-load"></span>'
            )

            const firstCN = disbursements ? disbursements[0].checknum : ''
            const lastCN = disbursements
                ? disbursements[disbursements.length - 1].checknum
                : ''
            const response = await this.api.postJson(
                'vendorpayments/checksrun',
                {
                    allChecks: true,
                }
            )
            displayAlertSuccess('Successfully posted checks.')
            this.props.resetData()
            this.props.hideModal(true, true)
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
    }
    resetCheckNumbers = async () => {
        const selectedCheckingAccounts = this.props.glAccounts.filter(
            (account) =>
                this.selectedCheckingAccounts.find(
                    (checkingAccount) => checkingAccount === account.value
                )
        )
        if (selectedCheckingAccounts.length) {
            await Promise.all(
                selectedCheckingAccounts.map(
                    async ({ value, nextchecknum }) => {
                        return await this.api.editGLAccount(value, {
                            nextchecknum,
                        })
                    }
                )
            )
        }
    }

    render() {
        return (
            <Modal
                size="lg"
                show={true}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col className="px-5 mo-printed-check-table">
                            <Row className="align-items-start mb-3">
                                <Col>
                                    <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3 mb-4">
                                        Printed Check Payment
                                    </h5>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Fiscal Month
                                            </Form.Label>
                                        </Col>
                                        <Col lg={7}>
                                            <DatePicker
                                                selected={
                                                    this.state.fiscalMonth
                                                }
                                                className="form-control"
                                                dateFormat="MM/yyyy"
                                                placeholderText="Select"
                                                showMonthYearPicker
                                                name="fiscalMonth"
                                                onChange={this.handleDateChange.bind(
                                                    this,
                                                    'fiscalMonth'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center my-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Checking Account
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Select
                                                name="cashAccountNu"
                                                options={this.props.glAccounts}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Next Check No.
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="NextCheckNum"
                                                defaultValue={
                                                    this.state.checkingAccountNo
                                                }
                                                onBlur={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Check Date
                                            </Form.Label>
                                        </Col>
                                        <Col lg={7}>
                                            <DatePicker
                                                selected={this.state.checkDate}
                                                name="checkDate"
                                                dateFormat="MM/dd/yyyy"
                                                showMonthDropdown="true"
                                                showYearDropdown="true"
                                                className="form-control"
                                                placeholderText="Select"
                                                onChange={this.handleDateChange.bind(
                                                    this,
                                                    'checkDate'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="ms-1 mb-3">
                                <Col>
                                    <span className="fw-bold">
                                        Account Balance:{' '}
                                        {currencyFormat(
                                            this.state.accountBalance
                                        )}
                                    </span>
                                </Col>
                                <Col>
                                    <span className="fw-bold">
                                        Check Run Total:{' '}
                                        {currencyFormat(
                                            this.state.runTotalAmount
                                        )}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="align-items-start mb-3">
                                <Col>
                                    <div className="printed-check-modal">
                                        <Table
                                            striped
                                            responsive
                                            className="a-table"
                                        >
                                            <thead className="table-responsive">
                                                <tr className="a-table-heading">
                                                    {displaySortableTableHeader(
                                                        this.tableHeaders,
                                                        'supplier',
                                                        'asc'
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.updatedData
                                                    .cashAccountNu === ''
                                                    ? showEmpty(
                                                          12,
                                                          'Select a Checking Account first.'
                                                      )
                                                    : this.state.isLoaded
                                                    ? this.state.disbursements
                                                          ?.length
                                                        ? displaySortableTableData(
                                                              this.tableHeaders,
                                                              this.state
                                                                  .disbursements,
                                                              true
                                                          )
                                                        : showEmpty()
                                                    : showLoading()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-beige py-4 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={(e) => this.handleRunPostChecks(e)}
                                className="w-100 m-2"
                                disabled={!this.state.disbursements?.length}
                            >
                                Accept Check(s)
                            </Button>
                            <Button
                                variant="primary"
                                onClick={(e) => this.handleRunReport(e)}
                                className="w-100 m-2"
                                disabled={!this.state.disbursements?.length}
                            >
                                Create PDF
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={async () => {
                                    await this.resetCheckNumbers()
                                    this.props.hideModal(false)
                                }}
                                className="w-100 m-2"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default WithRouter(PaymentActionPrintedChecksModal)
