import React from 'react'
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { generatePath, Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import { ApiService } from '../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import {
    getBreadcrumbTitle,
    getSaveButtonName,
} from '../../../utilities/String'
import { FormEvent } from '../../../utilities/FormEvent'
import {
    displayAlertLoader,
    handleResponseAlert,
    hideAlertLoader,
} from '../../../utilities/Response'

class GlossaryAddUnitsOfMeasure extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            dataIsLoaded: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
        }
        this.api = new ApiService()

        this.formEvent = new FormEvent('glossaryUnits', true)
    }

    async componentDidMount() {
        this.setTitle('Add Unit of Measure | Glossary')
        this.setFormAction(true)

        let data = {}
        if (this.props.params.id) {
            this.dMloader(true)
            data = await this.api.get(
                'UnitsOfMeasures',
                `?$filter=id eq ${this.props.params.id}`
            )

            if (data) {
                this.setState({
                    dataIsLoaded: true,
                    data: data[0],
                })

                this.setTitle('Edit Unit of Measure | Glossary')
                data = data[0]
            }
        }

        setLocalStorage('glossaryUnits', data, true)

        setTimeout(() => {
            this.setState({
                showButtons: true,
            })
            this.dMloader(false)
        }, 250)
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    handleTextChange = (e) => {
        this.setDraft()
        this.formEvent.handleTextChange(e)
    }

    handleClickSave = (e) => {
        this.setIsLoading(true)
        setLocalStorage('isDraft', 'false')
        window.onbeforeunload = null

        this.state.data?.value ? this.handleUpdate(e) : this.handleCreate(e)
    }

    handleUpdate(e) {
        if (this.state.data && this.state.data?.value) {
            const data = getLocalStorage('glossaryUnits', true) || `{}`

            const request = this.api.patch(
                'UnitsOfMeasures',
                this.state.data?.id,
                data
            )
            handleResponseAlert(request, (res) => {
                this.setIsLoading(false)

                if (res && !isNaN(res.id)) {
                    window.location.href = getRouteWithParam(
                        URI.glossary.list,
                        { path: 'units-of-measure' }
                    )
                }
            })
        }
    }

    handleCreate(e) {
        const data = getLocalStorage('glossaryUnits', true) || `{}`

        data.proj = '$$$$$'

        const request = this.api.postJson('UnitsOfMeasures', data)
        handleResponseAlert(request, (res) => {
            this.setIsLoading(false)
            if (res && !isNaN(res.id)) {
                window.location.href =
                    e.target.name === 'saveNew'
                        ? URI.glossary.add.unitsOfMeasure
                        : getRouteWithParam(URI.glossary.list, {
                              path: 'units-of-measure',
                          })
            }
        })
    }

    handleClick = (e) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            if (confirm('Leaving this screen will discard your changes.')) {
                setLocalStorage('isDraft', 'false')
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    renderHeader() {
        const { data, isLoading, isDraft } = this.state

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        onClick={this.handleClick}
                        to={getRouteWithParam(URI.glossary.list, {
                            path: 'units-of-measure',
                        })}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{
                                to: getRouteWithParam(URI.glossary.list, {
                                    path: 'units-of-measure',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Units of Measure
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.add.unitsOfMeasure }}
                            linkAs={Link}
                        >
                            {getBreadcrumbTitle(data, 'value', 'Unit')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>
                        {getBreadcrumbTitle(data, 'value', 'Unit')}
                    </HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <Button
                            className={
                                this.props.params.id
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </Button>

                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { data } = this.state

        return (
            <Container className="ms-0">
                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Unit</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={6}
                                    placeholder="Please enter"
                                    defaultValue={data?.value}
                                    name="value"
                                    onChange={(e) => {
                                        e.target.value = e.target.value
                                            .substring(0, 6)
                                            .replace(/\s/gi, '')
                                        this.handleTextChange(e)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        name="save"
                        onClick={this.handleClickSave.bind(this)}
                        disabled={!isDraft || isLoading}
                    >
                        {getSaveButtonName(isLoading, 'Save')}
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(GlossaryAddUnitsOfMeasure)
