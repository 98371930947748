import React, { ReactNode } from 'react'

interface ISection {
    sectionClass?: string
    children: ReactNode
}

const Section = ({ sectionClass, children }: ISection) => (
    <div className={sectionClass}>{children}</div>
)

Section.displayName = 'Section'

export default Section
