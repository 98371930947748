import { Row, Col, Form } from 'react-bootstrap'
import { getClassNames } from '../../helpers/DOM'

/**
 * Displays a Form Group.
 *
 * Properties:
 * className - Label class name.
 * classNameAdd - Added Label class name.
 * classNameParent - Form Group class name.
 * controlId - Form Group label.
 * colLabel - column size for label
 * colField - column size for field
 *
 * @param {*} props
 * @returns
 */
function InlineFormGroupField(props) {
    return (
        <>
            <Form.Group
                as={Row}
                controlId={props.controlId || ''}
                className={props.classNameParent || ''}
            >
                <Form.Label
                    column
                    sm={props.colLabel || 2}
                    {...(props.labelSettings || {})}
                    className={
                        (props.className ||
                            (props.labelTop
                                ? `fw-500 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end pt-sm-2`
                                : `fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end `)) +
                        (props.classNameAdd || '')
                    }
                    dangerouslySetInnerHTML={{ __html: props.label }}
                ></Form.Label>
                <Col sm={props.colField || 10} {...(props.fieldSettings || {})}>
                    {/* display the first element child - usually form */}
                    {props.children[0] || props.children}
                </Col>

                {/* display other child here... */}
                {props.children.length > 1 &&
                props.children[0] &&
                props.children[1]
                    ? props.children.slice(1)
                    : ''}
            </Form.Group>
        </>
    )
}

function FooterFormAction(props) {
    return (
        <div className="a-footer-action bg-beige">
            <div className="a-footer-action-inner">{props.children}</div>
        </div>
    )
}

function FormInputAdOns(props) {
    function getAdsOn() {
        if (props.text) {
            return <span>{props.text}</span>
        }

        return ''
    }

    return (
        <div className={getClassNames(props, 'form-group-extra')}>
            {props.children} {getAdsOn()}
        </div>
    )
}

export { InlineFormGroupField, FooterFormAction, FormInputAdOns }
