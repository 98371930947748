import useStartPaymentProcessorSession from 'lib/api/hooks/useStartPaymentProcessorSession'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import React, { useState } from 'react'
import OnboardMerchantComponent from './OnboardingComponent'
import Spinner from 'app/components/help/Spinner'
import useGetPaymentProcessorInfo from 'lib/api/hooks/useGetPaymentProcessorStatus'
import { OnboardingStatus } from 'lib/api/types/PaymentProcessorResponse'
import useGetManagedAccounts from 'lib/api/hooks/useGetManagedAccounts'
import { PaymentSessionType } from 'lib/api/types/PaymentSessionType'
import PaymentProcessorInfo from './PaymentProcessorInfo'

const OnboardingStartFlow = () => {
    const {
        data: sessionInfo,
        refetch,
        isFetching,
        error,
    } = useStartPaymentProcessorSession(PaymentSessionType.ONBOARDING, {
        enabled: false,
    })

    const [showOnboardingComponent, setShowOnboardingComponent] =
        useState(false)

    const { data: stripeData, isLoading } = useGetPaymentProcessorInfo('Stripe')

    const {
        data: managedAccounts,
        error: accountsError,
        isLoading: isGettingAccounts,
    } = useGetManagedAccounts()

    const isAccountManager = managedAccounts?.activeUsers.find(
        (user) => user.userId === managedAccounts.currentUserId
    )?.isAdmin

    if (isFetching || isLoading || isGettingAccounts) {
        return <Spinner isChild />
    }

    if (error || accountsError) {
        return null
    }

    if (sessionInfo && showOnboardingComponent) {
        return (
            <div className="tw-p-10">
                <OnboardMerchantComponent
                    sessionKey={sessionInfo.sessionKey}
                    merchantApplicationId={sessionInfo.merchantApplicationId}
                    merchantId={sessionInfo.merchantId}
                />
            </div>
        )
    }

    return (
        <>
            <PaymentProcessorInfo stripeData={stripeData} />
            <div className="tw-flex tw-justify-center">
                <OverlayTrigger
                    show={isAccountManager ? false : undefined}
                    overlay={
                        <Tooltip style={{ zIndex: 20000 }}>
                            Only users with Account Manager permissions can
                            register for Design Pay. User permissions can be
                            changed in the User Management area of Settings.
                        </Tooltip>
                    }
                >
                    <span>
                        <Button
                            disabled={!isAccountManager}
                            size="lg"
                            onClick={() => {
                                refetch()
                                setShowOnboardingComponent(true)
                            }}
                        >
                            <span className="tw-text-base">
                                {stripeData?.onboardingStatus ===
                                OnboardingStatus.Verified
                                    ? 'Switch to Design Pay'
                                    : 'Start Accepting Payments'}
                            </span>
                        </Button>
                    </span>
                </OverlayTrigger>
            </div>
        </>
    )
}
OnboardingStartFlow.displayName = 'OnboardingStartFlow'

export default OnboardingStartFlow
