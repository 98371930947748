import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ThreadComponent from '../ThreadComponent'

class Typography extends ThreadComponent {
    render() {
        return (
            <Container className="px-0" fluid>
                <div className="bg-light pt-5 pb-4">
                    <Container>
                        <Row>
                            <Col>
                                <Breadcrumb className="mt-2">
                                    <Breadcrumb.Item href="/">
                                        UI Kit
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="/typography">
                                        Typography
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <h2 className="fw-normal ff-type-medium">
                                    Typography
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="mt-5">
                    <Row className="mb-5">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H1</strong> <br />
                                Ariata Text Bold - 50px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h1>Lorem ipsum dolor sit amet, consetetur</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H2</strong> <br />
                                Ariata Text Bold - 42px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h2>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H3</strong> <br />
                                Ariata Text Bold - 28px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h3>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed
                            </h3>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H4</strong> <br />
                                Public Sans SemiBold - 24px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h4>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed
                            </h4>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H5</strong> <br />
                                Public Sans SemiBold - 20px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h5>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed
                            </h5>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>H6</strong> <br />
                                Public Sans SemiBold - 16px
                            </p>
                        </Col>
                        <Col className="ps-5">
                            <h6>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed
                            </h6>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>Body - Large</strong> <br />
                                Public Sans SemiBold - 16px
                            </p>
                        </Col>
                        <Col lg="3" className="ps-5">
                            <p>
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="fw-bold">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="text-decoration-underline">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>Body - Medium (Default)</strong> <br />
                                Public Sans SemiBold - 14px
                            </p>
                        </Col>
                        <Col lg="3" className="ps-5">
                            <p className="fs-md">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="fw-bold fs-md">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="fs-md text-decoration-underline">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg="1" className="pe-2">
                            <p className="fsx-12">
                                <strong>Body - Small</strong> <br />
                                Public Sans SemiBold - 12px
                            </p>
                        </Col>
                        <Col lg="3" className="ps-5">
                            <p className="fs-sm">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="fw-bold fs-sm">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>

                        <Col lg="3" className="ps-5">
                            <p className="fs-sm text-decoration-underline">
                                Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam nonumy eirmod tempor
                                invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam
                                et justo duo
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}

export default Typography
