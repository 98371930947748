import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { isNumberValid, round, roundStr } from '../../../utilities/Number'

export default class InputCurrency extends Component<any, any> {
    isBlurred: boolean
    isDefault: boolean

    constructor(props: any) {
        super(props)

        this.state = {
            value: props.value,
        }

        this.isBlurred = true
        this.isDefault = false
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>
    ): void {
        if (this.props !== prevProps) {
            this.setState({
                value: this.props.value,
            })
        }
    }

    isDot(value: any) {
        const val = value.toString()
        return val.split('').pop() === '.' || val.split('.')[1] === '0'
    }

    isDecimalZero(value: any) {
        const dec = value.toString().split('.')[1]
        return dec?.length < 2 && (dec[0] === '0' || dec[1] === '0')
    }

    getDecimal(value: any) {
        const val = value.toString()
        const decimal = val.split('.')

        if (this.isBlurred && !decimal[1]) {
            return value + '.00'
        }

        if (this.isBlurred && decimal[1] && decimal[1].length === 1) {
            return value + '0'
        }

        return value
    }

    getLocaleAmount(value: any) {
        if (!value || value === '-') {
            return value
        }

        return parseFloat(value).toLocaleString('en-US')
    }

    render() {
        const { value } = this.state
        const dot = this.isDot(value) ? '.' : ''
        const decZero = this.isDecimalZero(value) ? '0' : ''
        const val = this.isDefault
            ? value
            : this.getDecimal(
                  value < 0
                      ? '-$' +
                            (value * -1).toLocaleString('en-US') +
                            dot +
                            decZero
                      : '$' + this.getLocaleAmount(value) + dot + decZero
              )

        return (
            <Form.Control
                {...this.props}
                type="text"
                value={val}
                onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '')
                    e.target.value = e.target.value.replace('$', '')
                    e.target.value = e.target.value.replace(',', '')

                    // If number is being typed. Don't proceed to change.
                    if (
                        !isNumberValid(e.target.value, {
                            decimal: 2,
                        })
                    ) {
                        return
                    }

                    this.isDefault = false
                    this.isBlurred = false
                    this.props.onChange && this.props.onChange(e)
                }}
                onBlur={(e) => {
                    if (this.props.readOnly || this.props.disabled) return

                    e.target.value = e.target.value.replace(/\s/g, '')
                    e.target.value = e.target.value.replace('$', '')
                    e.target.value = e.target.value.replace(',', '')

                    if (e.target.value.split('').pop() === '.') {
                        e.target.value = e.target.value.replace('.', '')
                    }

                    e.target.value = roundStr(parseFloat(e.target.value)) as any

                    this.isDefault = false
                    this.isBlurred = true
                    this.props.onChange && this.props.onChange(e)
                }}
                onFocus={(e) => {
                    if (this.props.readOnly || this.props.disabled) return

                    if (e.target.value === '$0.00') {
                        this.isDefault = true
                        this.setState({
                            value: '$',
                        })
                    }
                }}
            />
        )
    }
}
