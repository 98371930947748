import { Col, Row, Form, Container, Table } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import Select from 'react-select'

class ProjectAddMarkupGeneral extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = this.props.updatedData ?? {}
        this.markUpKeys = [
            {
                markUpKey: 'm',
                depositKey: 'merc',
            },
            {
                markUpKey: 'd',
                depositKey: 'design',
            },
            {
                markUpKey: 'f',
                depositKey: 'freight',
            },
            {
                markUpKey: 'i',
                depositKey: 'install',
            },
            {
                markUpKey: 'l',
                depositKey: 'labor',
            },
        ]
        this.typeOptions = [
            {
                value: -1,
                label: 'Discount',
            },
            {
                value: 0,
                label: 'Markup',
            },
            {
                value: 1,
                label: 'Fee',
            },
        ]
    }

    getDefaultTypeValue(key) {
        return this.typeOptions.find(
            (type) =>
                type.value === this.updatedData[key] ||
                type.value === this.props.project?.[key]
        )
    }

    getDefaultPercentageValue(key) {
        return this.updatedData[key] ?? this.props.company?.[key]
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.props.onDataChange({ key: key, value: value })
    }

    getTaxable(markupKey) {
        return this.updatedData[`tax${markupKey}`] ?? false
    }

    render() {
        return (
            <>
                <Container className="ms-0">
                    <Row className="mb-2">
                        <Col xxl={10}>
                            <div className="table-gradient">
                                <Table responsive borderless className="">
                                    <thead>
                                        <tr key="0">
                                            <th className="fs-lg"></th>
                                            <th className="fs-lg mw-140px">
                                                %Type
                                            </th>
                                            <th className="fs-lg"></th>
                                            <th className="fs-lg mw-140px">
                                                Percentage
                                            </th>
                                            <th className="fs-lg"></th>
                                            <th className="fs-lg mw-80px text-center">
                                                Taxable
                                            </th>
                                            <th className="fs-lg"></th>
                                            <th className="fs-lg mw-80px text-center">
                                                Inc. in Dep
                                            </th>
                                            <th className="fs-lg"></th>
                                            <th className="fs-lg mw-80px text-center">
                                                Auto
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.markUpKeys.map((markup) => (
                                            <tr key={markup.depositKey}>
                                                <td className="align-middle text-end">
                                                    {`${
                                                        this.props.company?.[
                                                            `compview${markup.markUpKey}`
                                                        ]
                                                    }`}
                                                </td>
                                                <td>
                                                    <Select
                                                        key={`${Math.floor(
                                                            Math.random() * 1000
                                                        )}-min`}
                                                        name={
                                                            'usediscount' +
                                                            markup.depositKey
                                                        }
                                                        options={
                                                            this.typeOptions
                                                        }
                                                        className="react-select"
                                                        defaultValue={this.getDefaultTypeValue(
                                                            'usediscount' +
                                                                markup.depositKey
                                                        )}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={{
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 99991,
                                                            }),
                                                        }}
                                                    />
                                                </td>
                                                <td className="align-middle text-center text-secondary-ash"></td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={30}
                                                        name={`markup${markup.depositKey}`}
                                                        defaultValue={this.getDefaultPercentageValue(
                                                            'markup' +
                                                                markup.depositKey
                                                        )}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </td>
                                                <td className="align-middle text-center text-secondary-ash"></td>
                                                <td className="align-middle text-center">
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`tax${markup.markUpKey}`}
                                                        type="checkbox"
                                                        id={`chk-taxable-${markup.markUpKey}`}
                                                        defaultChecked={this.getTaxable(
                                                            markup.markUpKey
                                                        )}
                                                        onClick={
                                                            this.handleChange
                                                        }
                                                    />
                                                </td>
                                                <td className="align-middle text-center text-secondary-ash"></td>
                                                <td className="align-middle text-center">
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`indeposit${markup.depositKey}`}
                                                        type="checkbox"
                                                        id={`chk-inc-in-${markup.depositKey}`}
                                                        defaultChecked={
                                                            this.updatedData[
                                                                'indeposit' +
                                                                    markup.depositKey
                                                            ] ??
                                                            this.props
                                                                .company?.[
                                                                'indeposit' +
                                                                    markup.depositKey
                                                            ]
                                                        }
                                                        onClick={
                                                            this.handleChange
                                                        }
                                                    />
                                                </td>
                                                <td className="align-middle text-center text-secondary-ash"></td>
                                                <td className="align-middle text-center">
                                                    {'merc' !==
                                                        markup.depositKey && (
                                                        <Form.Check
                                                            inline
                                                            label=""
                                                            name={`auto${markup.markUpKey}`}
                                                            type="checkbox"
                                                            id={`chk-auto-${markup.markUpKey}`}
                                                            defaultChecked={
                                                                this
                                                                    .updatedData[
                                                                    'auto' +
                                                                        markup.markUpKey
                                                                ] ??
                                                                this.props
                                                                    .company?.[
                                                                    'auto' +
                                                                        markup.markUpKey
                                                                ]
                                                            }
                                                            onClick={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="align-middle text-end">
                                                Time
                                            </td>
                                            <td></td>
                                            <td className="align-middle text-center text-secondary-ash"></td>
                                            <td></td>
                                            <td className="align-middle text-center text-secondary-ash"></td>
                                            <td className="align-middle text-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name="taxo"
                                                    type="checkbox"
                                                    id={`chk-taxo`}
                                                    defaultChecked={this.getTaxable(
                                                        'o'
                                                    )}
                                                    onClick={this.handleChange}
                                                />
                                            </td>
                                            <td className="align-middle text-center text-secondary-ash"></td>
                                            <td className="align-middle text-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name="indepositother"
                                                    type="checkbox"
                                                    id={`chk-indepositother`}
                                                    defaultChecked={
                                                        this.updatedData
                                                            .indepositother ??
                                                        this.props.company
                                                            ?.indepositother
                                                    }
                                                    onClick={this.handleChange}
                                                />
                                            </td>
                                            <td className="align-middle text-center text-secondary-ash"></td>
                                            <td className="align-middle text-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name="autoo"
                                                    type="checkbox"
                                                    id={`chk-autoo`}
                                                    defaultChecked={
                                                        this.updatedData
                                                            .autoo ??
                                                        this.props.company
                                                            ?.autoo
                                                    }
                                                    onClick={this.handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    {/* show v1
					<div className='bg-secondary-grey mb-4 roundedx-4'>
						<Row xs={1} className='py-3'>
							<Col>
								<Row className='align-items-center px-3'>
									<Col className='px-3'>
										<h6 className='fw-bold mb-0'>Options</h6>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<Row className='mb-5 px-2'>
						<Col lg='6' className='px-4'>
							<Row className='mb-3'>
								<Col xs={3} className='pe-0 text-lg-end'>
									Show Discount
								</Col>
								<Col>
									<Form.Check
										inline
										label=''
										name='alwaysact'
										type='checkbox'
										id={`chk-option-alwaysact`}
										className='vtop'
										defaultChecked={this.updatedData.alwaysact}
										onClick={this.handleChange}
									/>
								</Col>
							</Row>
							<Row className='mb-3'>
								<Col xs={3} className='pe-0 text-lg-end'>
									Show Markup
								</Col>
								<Col>
									<Form.Check
										inline
										label=''
										name='pofulldesc'
										type='checkbox'
										id={`chk-option-pofulldesc`}
										className='vtop'
										defaultChecked={this.updatedData.pofulldesc}
										onClick={this.handleChange}
									/>
								</Col>
							</Row>
							<Row className='mb-3'>
								<Col xs={3} className='pe-0 text-lg-end'>
									Bypass WIP
								</Col>
								<Col>
									<Form.Check
										inline
										label=''
										name='bypasswip'
										type='checkbox'
										id={`chk-option-bypasswip`}
										className='vtop'
										defaultChecked={this.updatedData.bypasswip}
										onClick={this.handleChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					*/}
                </Container>
            </>
        )
    }
}

export default WithRouter(ProjectAddMarkupGeneral)
