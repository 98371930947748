/***
 * @var URI contains all path names.
 *
 * Please use the variable ``URI`` as the default object for path names.
 * This will streamline our definition of the pathname and it is easier
 * to update the url patterns through the rest of the app.
 *
 * Example usage on Router Link: <Link to={URL.home}>...</Link>
 * Example usage on Router NavLink: <NavLink to={URL.home}>...</NavLink>
 * Example usage on simple anchor tag: <a href={URL.home}>...</a>
 */
const URI = {
    home: '/',
    invalidInstance: '/invalid-instance',
    project: {
        base: '/projects',
        list: '/projects',
        add: '/projects/add',
        addMarkup: '/projects/add/markup',
        editMarkup: '/projects/:id/add/markup',
        view: '/projects/:id',
        viewPage: '/projects/:id/:page',
        viewVendorOrderModify: '/projects/:id/vendor-order/modify-status',
        viewVendorOrderEdit: '/projects/:id/vendor-order/modify-status/edit',

        itemAdd: '/projects/:id/item/add',
        newItemCreate: '/projects/:id/items/add',
        newItemEdit: '/projects/:id/items/:itemId/edit',
        newComponentEdit:
            '/projects/:id/items/:itemId/components/:componentId/edit',
        projectItems: '/projects/:id/items',
        itemEdit: '/projects/:id/item/edit/:itemId',
        itemEditPage: '/projects/:id/item/edit/:itemId/:page',
        budgetAdd: '/projects/:id/budget/add',
        budgetEdit: '/projects/:id/budget/edit/:bid',
        componentAdd: '/projects/:id/component/add',
        itemsStore: '/projects/:id/items/store-item',
        proposal: {
            list: '/projects/:id/proposals',
            history: '/projects/:id/proposals/:pid/history',
            status: '/projects/:id/proposals/:pid/status',
            add: '/projects/:id/proposals/add',
            setStatus: '/projects/:id/proposals/set-status/:pid',
            edit: '/projects/:id/proposals/:pid',
            delete: '/projects/:id/proposals/delete',
            send: '/projects/:id/proposals/send',
            addItem: '/projects/:id/proposal/:pid/add-item',
        },
        item: {
            transferItem: '/projects/:id/items/transfer-item',
            transferItemEdit: '/projects/:id/items/transfer-item/edit',
            componentAdd: '/projects/:id/item/:itemId/add/component',
            componentEdit: '/projects/:id/item/edit/:itemId/component/:compId',
            componentEditPage:
                '/projects/:id/item/edit/:itemId/component/:compId/:page',
            componentAddPage: '/projects/:id/item/add/component/:page',
            componentEditOrderStatusPO:
                '/projects/:id/item/add/component/order-status/po/edit/:poId',
            componentEditOrderStatusInvoice:
                '/projects/:id/item/add/component/order-status/invoice/edit/:invId',
        },
        vendorOrder: {
            addWorkOrder: '/projects/:id/vendor-orders/add-work-order/add',
            editWorkOrder:
                '/projects/:id/vendor-orders/add-work-order/:orderId',
            wOcomponentEdit:
                '/projects/:id/vendor-orders/add-work-order/:orderId/item/:itemId/component/:compId',
            addWorkOrderGeneratedWO:
                '/projects/:id/vendor-orders/add-work-order/generated-wo',
            addWorkOrderEditGeneratedWO:
                '/projects/:id/vendor-orders/add-work-order/generated-wo/edit',
            addPurchaseOrder:
                '/projects/:id/vendor-orders/add-purchase-order/add',
            editPurchaseOrder:
                '/projects/:id/vendor-orders/add-purchase-order/:orderId',
            pOcomponentEdit:
                '/projects/:id/vendor-orders/add-purchase-order/:orderId/item/:itemId/component/:compId',
            addPurchaseOrderGeneratedPO:
                '/projects/:id/vendor-orders/add-purchase-order/generated-po',
            addPurchaseOrderEditGeneratedPO:
                '/projects/:id/vendor-orders/add-purchase-order/generated-po/edit',
            modifyStatus: '/projects/:id/vendor-orders/modify-status/:poId',
            editStatus: '/projects/:id/vendor-orders/modify-status/edit',
        },
    },
    item: {
        base: 'items',
        clippedItem: {
            base: '/items/clipped-items',
            edit: '/items/clipped-items/:id',
        },
        clippedItemGrid: '/items/clipped-items/grid',

        catalogItem: '/items/catalogs/items',
        catalog: {
            base: '/items/catalogs',
            add: '/items/catalogs/items/add',
            edit: '/items/catalogs/items/:id/edit',
            addComponent: '/items/catalogs/items/component',
            addComponentPage: '/items/catalogs/items/component/:page',
            addComponentEditOrderStatusPO:
                '/items/catalogs/items/component/order-status/po',
            addComponentEditOrderStatusInvoice:
                '/items/catalogs/items/component/order-status/invoice',
        },

        search: '/items/search',
    },
    stockItem: {
        base: '/stock-items',
        list: '/stock-items',
        edit: '/stock-items/:id/edit',
    },
    timeEntry: {
        base: '/time-entry',
        add: '/time-entry/add',
        edit: '/time-entry/edit/:id',
    },
    contact: {
        base: '/contacts',
        client: '/contacts/clients',
        clientAdd: '/contacts/clients/add/:tab',
        clientEdit: '/contacts/clients/:id/:tab',
        vendor: '/contacts/vendors',
        vendorAdd: '/contacts/vendors/add/:tab',
        vendorEdit: '/contacts/vendors/:id/:tab',
        vendorStatus: '/contacts/vendors/status',
        employee: '/contacts/employees',
        employeeAdd: '/contacts/employees/add/:tab',
        employeeEdit: '/contacts/employees/:id/:tab',
        employeeAddPage: '/contacts/employees/add/:page',
        employeeTimeActivityAdd: '/contacts/employees/:id/add/time-activity',
        employeeTimeActivityEdit:
            '/contacts/employees/:id/edit/time-activity/:aid',
    },
    accountsReceivable: {
        base: '/accounts-receivable',
        report: '/accounts-receivable/reports',
        // clientInvoices: '/accounts-receivable/reports',
        clientInvoices: {
            base: '/accounts-receivable/client-invoices',
            listNew: '/accounts-receivable/client-invoices/new',
            listExisting: '/accounts-receivable/client-invoices/existing',
            list: '/accounts-receivable/client-invoices/:page',
            existingInvoiceView:
                '/accounts-receivable/client-invoices/existing/:invnum',
            existingInvoiceAdjustment:
                '/accounts-receivable/client-invoices/existing/adjustment',
            existingInvoiceAdjustmentAdd:
                '/accounts-receivable/client-invoices/existing/adjustment/add',
            existingreverseCreditInvoice:
                '/accounts-receivable/client-invoices/existing/reverse-credit/:invnum',
            existingHighlightedInvoice:
                '/accounts-receivable/client-invoices/existing/highlighted-invoice/:id',
            add: '/accounts-receivable/client-invoices/add',
            edit: '/accounts-receivable/client-invoices/:invoiceId',
            addItem: '/accounts-receivable/client-invoices/add-item',
            addOverridePrice:
                '/accounts-receivable/client-invoices/override-price',
            financeCharges:
                '/accounts-receivable/client-invoices/finance-charges',
        },
        cashReceipt: {
            base: '/accounts-receivable/cash-receipts',
            listNew: '/accounts-receivable/cash-receipts/new',
            listExisting: '/accounts-receivable/cash-receipts/existing',
            existingReceiptView:
                '/accounts-receivable/cash-receipts/existing/:refno',
            listBankDeposit: '/accounts-receivable/cash-receipts/bank-deposit',
            list: '/accounts-receivable/cash-receipts/:page',
            add: '/accounts-receivable/cash-receipts/add',
            edit: '/accounts-receivable/cash-receipts/:id',
            addMisc: '/accounts-receivable/cash-receipts/add-misc',
            editMisc: '/accounts-receivable/cash-receipts/misc/:id',
        },
        returnsAndCredit: {
            base: '/accounts-receivable/returns-and-credits',
        },
        designPay: {
            base: '/accounts-receivable/design-pay',
        },
    },
    accountsPayable: {
        base: '/accounts-payable',
        report: '/accounts-payable/reports',
        comingSoon: '/accounts-payable/coming-soon',

        vendorInvoice: {
            base: '/accounts-payable/vendor-invoices',
            listNew: '/accounts-payable/vendor-invoices/new',
            listExisting: '/accounts-payable/vendor-invoices/existing',
            list: '/accounts-payable/vendor-invoices/:page',
            add: '/accounts-payable/vendor-invoices/add',
            addDeposit: '/accounts-payable/vendor-invoices/add-deposit',
            editDeposit: '/accounts-payable/vendor-invoices/edit-deposit/:id',
            editNewDeposit:
                '/accounts-payable/vendor-invoices/edit-new-deposit/:id',
            addMisc: '/accounts-payable/vendor-invoices/add-misc',
            editMisc: '/accounts-payable/vendor-invoices/misc/:txnum',
            editNewMisc: '/accounts-payable/vendor-invoices/new-misc/:txnum',
            addRec: '/accounts-payable/vendor-invoices/add-rec',
            addQuickDeposit:
                '/accounts-payable/vendor-invoices/add-quick-deposit',
            editRec: '/accounts-payable/vendor-invoices/edit-rec',
            editInvoice: '/accounts-payable/vendor-invoices/edit-invoice/:id',
            editNewInvoice:
                '/accounts-payable/vendor-invoices/edit-new-invoice/:id',
        },
        payment: {
            base: '/accounts-payable/payments',
            list: '/accounts-payable/payments',
            formModifyAmount: '/accounts-payable/payments/modify-amount',
            formCreditCardPayment:
                '/accounts-payable/payments/credit-card-payment',
            formOnlinePayment: '/accounts-payable/payments/online-payment',
            formCheckWirePayment:
                '/accounts-payable/payments/check-wire-payment',
        },
        checkbook: {
            base: '/accounts-payable/checkbook',
            list: '/accounts-payable/checkbook',
            reconcile: '/accounts-payable/checkbook/reconcile',
            history: '/accounts-payable/checkbook/reconcile/history',
            journalEntryDetail:
                '/accounts-payable/checkbook/journal-entry-detail',
            checkDetail: '/accounts-payable/checkbook/check-detail',
            depositDetail: '/accounts-payable/checkbook/deposit-detail',
        },
        creditCard: {
            base: '/accounts-payable/credit-card',
            list: '/accounts-payable/credit-card',
            history: '/accounts-payable/credit-card/reconcile/history',
            reconcile: '/accounts-payable/credit-card/reconcile',
        },
        vendorCredit: {
            base: '/accounts-payable/vendor-credits',
            list: '/accounts-payable/vendor-credits',
        },
    },
    generalLedger: {
        base: '/general-ledger',
        account: {
            base: '/general-ledger/accounts',
            list: '/general-ledger/accounts',
            add: '/general-ledger/accounts/add',
            edit: '/general-ledger/accounts/:id',
            balance: '/general-ledger/accounts/balance',
        },
        journalEntry: {
            base: '/general-ledger/journal-entries',
            listNew: '/general-ledger/journal-entries/new',
            listExisting: '/general-ledger/journal-entries/existing',
            add: '/general-ledger/journal-entries/add',
            edit: '/general-ledger/journal-entries/edit/:type/:id',
            recurringJournalEntry: '/general-ledger/journal-entries/recurring',
            recurringJournalEntryAdd:
                '/general-ledger/journal-entries/recurring/add',
        },
        transactionSearch: {
            base: '/general-ledger/transaction-search',
            list: '/general-ledger/transaction-search',
        },
        report: '/general-ledger/reports',
    },
    timeManagement: {
        base: '/time-management',
        appointment: '/time-management/appointment',
        appointmentAdd: '/time-management/appointment/add',
        appointmentToDoAdd: '/time-management/todo/add',
        calendar: '/time-management/calendar',
    },
    reviewSend: {
        base: '/review-send',
        add: '/review-send/add',
    },
    report: {
        base: '/reports',
    },
    glossary: {
        base: '/glossary',
        list: '/glossary/:path',
        salesTaxCodes: '/glossary/sales-tax-codes/:taxc',
        add: {
            locations: '/glossary/add/locations',
            salesCategory: '/glossary/add/sales-categories',
            itemGroups: '/glossary/add/item-groups',
            shipToCodes: '/glossary/add/ship-to-codes',
            remarks: '/glossary/add/remarks',
            specificationTemplates: '/glossary/add/specification-templates',
            timeActivities: '/glossary/add/time-activities',
            salesTaxCodes: '/glossary/add/sales-tax-codes',
            salesTaxCalculation:
                '/glossary/add/sales-tax-codes/calculation/:taxc',
            unitsOfMeasure: '/glossary/add/units-of-measure',
        },
        edit: {
            itemGroups: '/glossary/item-groups/:id',
            salesTaxCodes: '/glossary/sales-tax-codes/:code',
            locations: '/glossary/locations/:id',
            timeActivities: '/glossary/time-activities/:id',
            salesCategory: '/glossary/sales-categories/:id',
            specificationTemplates: '/glossary/specification-templates/:id',
            remarks: '/glossary/remarks/:code',
            shipToCodes: '/glossary/ship-to-codes/:id',
            unitsOfMeasure: '/glossary/add/units-of-measure/:id',
            salesTaxCalculation:
                '/glossary/edit/sales-tax-codes/calculation/:taxc/:seqnum',
        },
    },
    accountSettings: {
        base: '/account-settings',
    },
    settings: {
        base: '/settings',
        list: '/settings',
        userManagement: {
            base: '/settings/users',
            list: '/settings/users',
            add: '/settings/users/add',
            edit: '/settings/users/:id',
        },
        userSettings: {
            base: '/settings/user-settings',
            list: '/settings/user-settings',
        },
        paymentSettings: {
            base: '/settings/payments',
            list: '/settings/payments',
            connected: '/settings/payments/:connected',
        },
        companySettings: {
            base: '/settings/company',
        },
        workflowSettings: {
            base: '/settings/workflow',
            general: '/settings/workflow/general',
            print: '/settings/workflow/print',
            time: '/settings/workflow/time',
            proposals: '/settings/workflow/proposals',
            invoices: '/settings/workflow/invoices',
            powoorders: '/settings/workflow/powoorders',
            schedule: '/settings/workflow/schedule',
            budget: '/settings/workflow/budget',
        },
    },
    uikit: {
        base: '/ui-kit',
        component: '/ui-kit/components',
        typography: '/ui-kit/typography',
        icon: '/ui-kit/icons',
        colour: '/ui-kit/colours',
    },
    login: {
        base: '/login',
        password: '/login/password',
    },
    acceptInvitation: {
        base: '/accept-invitation',
    },
    resetPassword: {
        base: '/reset-password',
    },
    dashboard: {
        base: '/dashboard',
    },
}

export default URI
