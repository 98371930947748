import URI from 'defaults/RoutesDefault'
import { useHeader } from 'hooks/useHeader'
import React from 'react'

export const CheckbookReconcileHeader = () => {
    useHeader({
        title: 'Checkbook Reconcile',
        enableBackButton: true,
        breadcrumbs: [
            {
                text: 'Accounts Payable',
                to: URI.accountsPayable.creditCard.list,
            },
            {
                text: 'Checkbook',
                to: URI.accountsPayable.checkbook.list,
            },
            {
                text: 'Reconcile',
            },
        ],
        tabs: [
            {
                label: 'Checkbook Overview',
                to: URI.accountsPayable.checkbook.base,
                active: false,
                id: 'checkbook',
            },
            {
                label: 'Reconcile',
                to: URI.accountsPayable.checkbook.reconcile,
                active: true,
                id: 'reconcile',
            },
            {
                label: 'Reconcile History',
                to: URI.accountsPayable.checkbook.history,
                active: false,
                id: 'reconcile-history',
            },
        ],
    })
    return <></>
}

CheckbookReconcileHeader.displayName = 'CheckbookReconcileHeader'
