import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { Component } from '../types/Component'

const api = new ApiService()

const getComponents = async (
    id: string,
    projectId: string
): Promise<Component[]> => {
    const components = await api.getComponents(
        `?$filter=itemid eq ${id} AND projectid eq ${projectId}`
    )
    return components
}

const useGetComponents = (
    id: string,
    projectId: string,
    options?: UseQueryOptions<Component[], unknown, Component[], string[]>
): UseQueryResult<Component[]> => {
    const componentsQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [id, projectId, 'components'],
        queryFn: () => getComponents(id, projectId),
        enabled: !!id && !!projectId,
        ...{ ...(options || {}) },
    })
    useEffect(() => {
        if (componentsQuery.error) {
            displayAlertError(
                'An error occurred fetching the components, please try again'
            )
        }
    }, [componentsQuery.error])

    return componentsQuery
}
export default useGetComponents
