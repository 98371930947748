import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'

class ProjectVendorOrdersAddPurchaseOrderEditGeneratedPO extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
        }
    }

    componentInit() {
        this.setTitle('Edit Generated PO').setFormAction(true)
    }

    handleDateChange = (date) => {
        this.setState((prevState) => (prevState.startDate = date))
    }

    handleRadioChange = (e) => {
        this.setState(
            (prevState) =>
                (prevState.isDisabled = e.target.value === '1' ? true : false)
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsPayable.payment.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.base }}
                            linkAs={Link}
                        >
                            Projects
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.project.view, { id: 1 }),
                            }}
                            linkAs={Link}
                        >
                            JOH01
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.project.viewPage, {
                                    id: 1,
                                    page: 'vendor-orders',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Vendor Orders
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(
                                    URI.project.vendorOrder.addPurchaseOrder,
                                    { id: 1, page: 'vendor-orders' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Add Purchase Order
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(
                                    URI.project.vendorOrder
                                        .addPurchaseOrderGeneratedPO,
                                    { id: 1, page: 'vendor-orders' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Generated Purchase Order
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Edit Generated PO</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>Edit Generated PO</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2} className="mb-5">
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Ship Via
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-4">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Designer/ Phone Ext.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-start mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Override Special Instructions
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Override Special Instructions"
                                    name="group-check-override"
                                    type="checkbox"
                                    id={`group-check-override`}
                                    className="mb-3"
                                />
                                <WysiwygEditor />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Ship On or Before Date
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Verbal Order/ Ref No.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ProjectVendorOrdersAddPurchaseOrderEditGeneratedPO)
