export async function validateInput(validation, fields) {
    // hideErrorMessage()
    try {
        return await validation.validate(fields)
    } catch (error) {
        let errorMessage = ''

        if (error instanceof Error) {
            errorMessage = error.message
            // showErrorMessage(errorMessage.replaceAll('ValidationError: ', ''))
            return await { status: 'error', message: errorMessage }
        }

        return false
    }
}

export function showErrorMessage(error, show = true) {
    const errorDiv = document.getElementById('validation-error-area')
    const errorTextDiv = document.getElementById('validationError')

    hideErrorMessage()
    if (error) {
        errorDiv.style.display = 'block'
        errorTextDiv.innerHTML = error
    }
}

export function hideErrorMessage() {
    const errorDiv = document.getElementById('validation-error-area')
    const errorTextDiv = document.getElementById('validationError')
    errorDiv.style.display = 'none'
}
