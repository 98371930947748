import { makeAutoObservable } from 'mobx'
import { ClientListResponse, ClientService } from './api/ClientService'
import { getLocalStorage } from '../../../utilities/LocalStorage'
import { toSortName } from '../../../utilities/String'

export default class ClientAddEditViewModel {
    private clientService: ClientService
    private _client: any = {}
    private _clientData: any = {}
    private _isLoaded = false
    public _id = 0
    private _response: any = null

    get client(): ClientListResponse {
        return this._client
    }

    get clientData(): ClientListResponse {
        return this._clientData
    }

    get response(): boolean {
        return this._response
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    reset(): any {
        this._client = {}
        this._clientData = {}
        return this
    }

    setClient(client: ClientListResponse): ClientAddEditViewModel {
        this._client = client
        this._clientData = client
        window.localStorage.setItem('clientData', JSON.stringify(client))

        return this
    }

    setClientData(key: any, value: any): ClientAddEditViewModel {
        const clientData: any = getLocalStorage('clientData', true)
        const addressData: any = getLocalStorage('addressData', true)
        clientData[key] = value

        if (key === 'ClientName') {
            const sortName = toSortName(value)
            clientData.sortName = sortName
            addressData.sortname = sortName

            window.localStorage.setItem(
                'addressData',
                JSON.stringify(addressData)
            )
        }
        window.localStorage.setItem('clientData', JSON.stringify(clientData))

        this._clientData = clientData

        return this
    }

    setIsLoaded(isLoaded: boolean): ClientAddEditViewModel {
        this._isLoaded = isLoaded
        return this
    }

    setResponse(response: any): ClientAddEditViewModel {
        this._response = response
        return this
    }

    constructor(clientService: ClientService) {
        this.clientService = clientService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        await this.fetchClient()

        this.setIsLoaded(true)
    }

    setId(id: number): ClientAddEditViewModel {
        this._id = id
        return this
    }

    async fetchSalesTaxCodes() {
        return await this.clientService.getSalesTaxCodes(
            '?$filter=inactive eq false'
        )
    }

    async fetchSalesTaxCodesSummaries() {
        const salestaxCodesSummaries =
            await this.clientService.getSalesTaxCodesSummaries(
                '?$filter=inactive eq false'
            )
        return salestaxCodesSummaries.results
    }

    fetchClient() {
        // Set loading state
        this.setIsLoaded(false)

        if (!this._id) {
            const data: any = {}
            this.setClient(data)
            return
        }

        return this.clientService
            .getClientById(this._id)
            .then((response) => {
                this.setIsLoaded(true)
                if (response && response[0]) {
                    response.list = response[0]

                    this.setClient(response.list)
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }

    modifyClient(id?: number, params?: any, callback?: any) {
        delete params.address
        delete params.addressData
        if (!id) {
            return this.insertClient(params, callback)
        } else {
            return this.updateClient(id, params, callback)
        }
    }

    insertClient(params?: any, callback?: any) {
        return this.clientService.insert(params)
    }

    updateClient(id: number, params?: any, callback?: any) {
        return this.clientService.update(id, params)
    }

    async deleteClient(params?: any, callback?: any) {
        return this.clientService
            .delete(params)
            .then((res) => Promise.resolve(res))
            .catch((e) => Promise.reject(e))
    }
}
