import { Component, useCallback, useContext, useEffect } from 'react'
import {
    useLocation,
    useNavigate,
    useParams,
    UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom'
import { ApiService } from '../lib/api/HttpService'
import { isDraft } from './ModuleHelper'
import { setDraft } from './FormEvent'
import { displayAlertError, getErrorMessage } from './Response'
import { removeDisplayAlert } from './Response'
import { toggleDMPageLoader } from './DOM'
import { setLocalStorage } from './LocalStorage'

export function WithRouter(ComponentClass: any) {
    function ComponentWithRouter(props: any) {
        const params = useParams()
        const location = useLocation()
        const navigate = useNavigate()

        window.localStorage.setItem('isTabSwitch', 'false')

        return (
            <ComponentClass
                navigate={navigate}
                {...props}
                params={params}
                paths={location}
            />
        )
    }

    return ComponentWithRouter
}

/**
 * Get URL with parameter.
 *
 * @param {string} pathRegex
 * @param {object} params
 * @returns string
 */
export function pathParam(pathRegex: string, params: any) {
    const segments = pathRegex.split('/')

    return segments
        .map((segment: any) => {
            const offset: any = segment.indexOf(':') + 1

            if (!offset) return segment

            const key: any = segment.slice(offset)

            return params[key]
        })
        .join('/')
}

function useConfirmExit(confirmExit: () => boolean, when = true) {
    const { navigator } = useContext(NavigationContext)

    useEffect(() => {
        if (!when) {
            return
        }

        const push = navigator.push

        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit()
            if (result !== false) {
                push(...args)
            }
        }

        return () => {
            navigator.push = push
        }
    }, [navigator, confirmExit, when])
}

export function usePrompt(message: string, when = true) {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message
            }
        }
        return () => {
            window.onbeforeunload = null
        }
    }, [message, when])

    const confirmExit = useCallback(() => {
        const confirm = window.confirm(message)
        console.log('confirm', confirm)
        return confirm
    }, [message])
    useConfirmExit(confirmExit, when)
}

function deleteUnsavedRecords() {
    const api = new ApiService()
    const forDelete = window.localStorage.getItem('forDelete')

    if (forDelete) {
        try {
            let forDeleteArray = []

            if (forDelete.startsWith('[') && forDelete.endsWith(']')) {
                forDeleteArray = JSON.parse(forDelete)
                if (!Array.isArray(forDeleteArray)) {
                    throw new Error(
                        'Existing value of forDelete is not an array'
                    )
                }
            } else {
                forDeleteArray.push(JSON.parse(forDelete))
            }

            for (const item of forDeleteArray) {
                api.deleteObjects(item).catch((error) => {
                    displayAlertError(getErrorMessage(error))
                })
            }

            window.onbeforeunload = null
            window.localStorage.removeItem('forDelete')
        } catch (error) {
            console.error('Error processing existing forDelete:', error)
        }
    }
}

export function AppNavigationComponent(props: any) {
    // This is the proper way of detecting path change.
    // We use the `useEffect` hook to check if the location has changed.
    const { pathname } = useLocation()
    const { changeStatus } = props

    useEffect(() => {
        changeStatus()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        deleteUnsavedRecords()
    }, [pathname, changeStatus])

    return <>{props.children}</>
}

export function isActiveMenu(path: string) {
    const { origin, href } = window.location
    const currentPath = href.replace(origin, '')

    if (currentPath.split('/').length > 3) {
        const uri1 = currentPath.split('/').pop() || ''
        const uri2 = path.split('/').pop() || ''

        return currentPath.replace(uri1, '') === path.replace(uri2, '')
    }

    return false
}

/**
 * Navigate to specific route.
 *
 * @param refClass Component class
 * @param actionType Action type. Pass on "saveNew" to reload on creation URL.
 * @param newUrl The Module add URL. If `false`, page will reload instead.
 * @param listUrl The Module listing URL
 * @param reload Is reload page
 * @return {void} void
 */
export function navigateOnSuccess(
    refClass: any,
    isNew: string,
    newUrl: string | boolean,
    listUrl: string
): void {
    if (isNew) {
        false === newUrl
            ? window.location.reload()
            : (window.location.href = newUrl.toString())
    } else {
        refClass.navigate(listUrl)
    }
}
