import React from 'react'
import { Col, Row, Form, Container, Table } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import Select from 'react-select'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../utilities/Rtf'

class ProjectAddMarkupPOWO extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = this.props.updatedData ?? {}
        this.styleOptions = [
            {
                value: 1,
                label: 'List',
            },
            {
                value: 2,
                label: 'Total',
            },
            {
                value: 3,
                label: 'Ignore',
            },
        ]

        this.state = {
            delremarksHtml: '',
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
    }

    async componentDidMount() {
        this.setState({
            delremarksHtml: await mapRtfToDraft(
                this.updatedData?.delremarksrtf ??
                    this.props.company?.delremarksrtf
            ),
        })
    }

    getDefaultStyleValue(key) {
        return this.styleOptions.find((style) => {
            return (
                style.value === this.updatedData[key] ||
                style.value === this.props.project?.[key]
            )
        })
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name ?? e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.props.onDataChange({ key: key, value: value })

        if (key === 'delremarks') {
            const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(
                e.target.html
            )
            this.props.onDataChange({ key: `${key}rtf`, value: valueHtml })
        }
    }

    render() {
        return (
            <>
                <Container className="ms-0">
                    <Row xs={1} lg={2} className="mb-2 mb-lg-4">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        PO Title
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        maxLength={30}
                                        placeholder="Proposal Title"
                                        name="potitle"
                                        defaultValue={
                                            this.updatedData.potitle ??
                                            this.props.company?.potitle
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table borderless className="">
                                        <thead>
                                            <tr key="0">
                                                <th className="fs-lg w-120px"></th>
                                                <th className="fs-lg">
                                                    <Row>
                                                        <Col>Title</Col>
                                                        <Col>Style</Col>
                                                    </Row>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={0}>
                                                <td className="align-middle text-end">
                                                    {
                                                        this.props.company
                                                            ?.compviewd
                                                    }
                                                </td>
                                                <td className="pe-0">
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={30}
                                                                placeholder="Please enter"
                                                                name="podesigndesc"
                                                                defaultValue={
                                                                    this
                                                                        .updatedData
                                                                        .podesigndesc ??
                                                                    this.props
                                                                        .company
                                                                        ?.podesigndesc
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                name="podesignstyle"
                                                                key={`${Math.floor(
                                                                    Math.random() *
                                                                        1000
                                                                )}-min`}
                                                                options={
                                                                    this
                                                                        .styleOptions
                                                                }
                                                                className="react-select"
                                                                placeholder="Please select"
                                                                defaultValue={this.getDefaultStyleValue(
                                                                    'podesignstyle'
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr key={1}>
                                                <td className="align-middle text-end">
                                                    {
                                                        this.props.company
                                                            ?.compviewf
                                                    }
                                                </td>
                                                <td className="pe-0">
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={30}
                                                                placeholder="Please enter"
                                                                name="pofreightdesc"
                                                                defaultValue={
                                                                    this
                                                                        .updatedData
                                                                        .pofreightdesc ??
                                                                    this.props
                                                                        .company
                                                                        ?.pofreightdesc
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                name="pofreightstyle"
                                                                key={`${Math.floor(
                                                                    Math.random() *
                                                                        1000
                                                                )}-min`}
                                                                options={
                                                                    this
                                                                        .styleOptions
                                                                }
                                                                className="react-select"
                                                                placeholder="Please select"
                                                                defaultValue={this.getDefaultStyleValue(
                                                                    'pofreightstyle'
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr key={2}>
                                                <td className="align-middle text-end">
                                                    {
                                                        this.props.company
                                                            ?.compviewi
                                                    }
                                                </td>
                                                <td className="pe-0">
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={30}
                                                                placeholder="Please enter"
                                                                name="poinstalldesc"
                                                                defaultValue={
                                                                    this
                                                                        .updatedData
                                                                        .poinstalldesc ??
                                                                    this.props
                                                                        .company
                                                                        ?.poinstalldesc
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                name="poinstallstyle"
                                                                key={`${Math.floor(
                                                                    Math.random() *
                                                                        1000
                                                                )}-min`}
                                                                options={
                                                                    this
                                                                        .styleOptions
                                                                }
                                                                className="react-select"
                                                                placeholder="Please select"
                                                                defaultValue={this.getDefaultStyleValue(
                                                                    'poinstallstyle'
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr key={3}>
                                                <td className="align-middle text-end">
                                                    {
                                                        this.props.company
                                                            ?.compviewl
                                                    }
                                                </td>
                                                <td className="pe-0">
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={30}
                                                                placeholder="Please enter"
                                                                name="polabordesc"
                                                                defaultValue={
                                                                    this
                                                                        .updatedData
                                                                        .polabordesc ??
                                                                    this.props
                                                                        .company
                                                                        ?.polabordesc
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                name="polaborstyle"
                                                                key={`${Math.floor(
                                                                    Math.random() *
                                                                        1000
                                                                )}-min`}
                                                                options={
                                                                    this
                                                                        .styleOptions
                                                                }
                                                                className="react-select"
                                                                placeholder="Please select"
                                                                defaultValue={this.getDefaultStyleValue(
                                                                    'polaborstyle'
                                                                )}
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="mb-3 mb-lg-0">
                            {/* https://app.clickup.com/t/865cdv05d: Temporarily removed the field below */}
                            {/* <Row className="align-items-center mb-4">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Fax Prefix
                                    </Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Control
                                        type="text"
                                        name="pofaxprefix"
                                        defaultValue={
                                            this.updatedData.pofaxprefix ??
                                            this.props.company?.pofaxprefix
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row> */}
                            <Row className="align-items-start mb-3">
                                <Col className="ps-2 ps-lg-5">
                                    {/* <div className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Single Item"
                                            name="singleitempo"
                                            type="checkbox"
                                            id={`chk-option-singleitempo`}
                                            className="vtop"
                                            defaultChecked={
                                                this.updatedData.singleitempo ??
                                                this.props.company?.singleitempo
                                            }
                                            onClick={this.handleChange}
                                        />
                                    </div> */}
                                    <div className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Show Email & Website"
                                            name="poemail"
                                            type="checkbox"
                                            id={`chk-option-poemail`}
                                            className="vtop"
                                            defaultChecked={
                                                this.updatedData.poemail ??
                                                this.props.company?.poemail
                                            }
                                            onClick={this.handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            inline
                                            label="One PO Per Component"
                                            name="onepo"
                                            type="checkbox"
                                            id={`chk-option-onepo`}
                                            className="vtop"
                                            defaultChecked={
                                                this.updatedData.onepo ??
                                                this.props.company?.onepo
                                            }
                                            onClick={this.handleChange}
                                        />
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* https://app.clickup.com/t/865cdv05d: Temporarily removed the field below */}
                    {/* <div className="bg-secondary-grey mb-4 roundedx-4">
                        <Row xs={1} className="py-3">
                            <Col>
                                <Row className="align-items-center px-3">
                                    <Col className="px-3">
                                        <h6 className="fw-bold mb-0">
                                            Delivery Ticket Remarks
                                        </h6>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Row xs={1} lg={2} className="mb-5">
                        <Col className="mb-3 mb-lg-0">
                            <WysiwygEditor
                                id="delremarks"
                                onChange={this.handleChange}
                            >
                                {this.state.delremarksHtml}
                            </WysiwygEditor>
                        </Col>
                    </Row> */}
                </Container>
            </>
        )
    }
}

export default WithRouter(ProjectAddMarkupPOWO)
