import { ApiService } from '../../../../lib/networking/HttpService'

export class EmployeeService extends ApiService {
    getEmployees<T = EmployeeListResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(`/employees${params || ''}`, {})
    }
    getEmployeeById<T = EmployeeListResponse>(id: number): Promise<T> {
        return this.httpWorker.get(`/employees?$filter=id eq ${id}`, {})
    }

    insert<T = EmployeeListResponse>(params: any): Promise<T> {
        return this.httpWorker.post(`/employees`, params)
    }

    update<T = EmployeeListResponse>(id: number, params: any): Promise<T> {
        return this.httpWorker.patch(`/employees/${id}`, params)
    }

    getSalesTaxCodes<T = ISalesTaxCodesResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(`/salestaxcodes${params || ''}`, {})
    }

    getTimeActivities<T = ITimeActivitiesResponse>(
        params?: string
    ): Promise<T> {
        return this.httpWorker.get(`/timeactivities${params || ''}`, {})
    }

    delete<T = EmployeeListResponse>(params: any): Promise<T> {
        return this.httpWorker.post(`/DeleteObjects`, params)
    }
}

export interface EmployeeListResponse {
    sortName: string
    id: number
    vendor?: string
    vendorn?: string
    venaddrtype: number
    account?: any
    deppct: number
    terms?: any
    shipvia?: any
    payee?: string
    payeeaddrtype: number
    irs1099: boolean
    ein?: string
    holdpayments: boolean
    paydays: number
    discperc: number
    discdays: number
    acct?: any
    showfreight: boolean
    bterm1: number
    bterm2: number
    bterm3: number
    notes?: string
    category?: any
    employee: true
    onetime: boolean
    basecostperhr: number
    baserateperhr: number
    inactive: boolean
    faxpo: boolean
    faxdial?: any
    poschangedate: boolean
    poseditprice: boolean
    postaxc?: string
    posvoid: boolean
    poscomperc: number
    poswhc?: string
    notesrtf: string
    emailpo: boolean
    poswhaddrtype: number
    incometype: number
    printacctoncheck: boolean
    tier2costperhr: number
    tier2rateperhr: number
    tier3costperhr: number
    tier3rateperhr: number
    depm: boolean
    depd: boolean
    depf: boolean
    depi: boolean
    depl: boolean
    _1099int: boolean
    _1099rp?: any
    donotallowonspecs: boolean
    designer?: string
    payeesameasvendor: boolean
    markupmerc: number
    markupdesign: number
    markupfreight: number
    markupinstall: number
    markuplabor: number
    usediscountmerc: number
    usediscountdesign: number
    usediscountfreight: number
    usediscountinstall: number
    usediscountlabor: number
    usemarkupspecs: boolean
    usemarkupinvcat: boolean
    address: any
    addressData: any
    list: any
    0: any
    numAttachments: number
}

export interface ISalesTaxCodesResponse {
    taxc: any
    taxcn: string
}

export interface ITimeActivitiesResponse {
    activity: string
    activityn: string
    desc: string
    shortdesc: string
    descrtf: string
    inactive: boolean
    basecostperhr: number
    baserateperhr: number
    tier2costperhr: number
    tier2rateperhr: number
    tier3costperhr: number
    tier3rateperhr: number
    billingoption: number
    ssmaTimeStamp: string
    nonbillableDefault: boolean
    taxableDefault: boolean
    id: number
}
