import React from 'react'
import {
    Col,
    Row,
    Table,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import { ApiService } from '../../../../lib/api/HttpService'
import { formatDate, formatFilterDate } from '../../../../helpers/Date'
import { getCookie } from '../../../../utilities/Auth'
import { debounce, isEmpty } from 'lodash'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { isFiltered } from '../../../../helpers/Util'
import { generatePath } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import Select from 'react-select'
import {
    displayAlert,
    displayAlertLoader,
} from '../../../../utilities/Response'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import PostVendorInvoiceDialog from './PostVendorInvoiceDialog'
import MSG from '../../../../defaults/Message'
import ConfirmModal from '../../../../app/components/modal/ConfirmModal'
import { plural } from '../../../../utilities/String'
import { getCheckAllClass } from '../../../../utilities/ModuleHelper'
import {
    vendorInvoiceDeleteProcess,
    vendorInvoiceDisplayDeleteMessage,
    vendorInvoiceUpdateTagsProcess,
} from '../../../../utilities/modules/VendorInvoiceListNew'
import {
    addDomClass,
    hasClass,
    removeDomClass,
} from '../../../../utilities/DOM'
import FilterSort from '../../../../utilities/modules/FilterSort'
import {
    currencyFormat,
    currencyFormatNoWrap,
} from '../../../../helpers/Number'

class VendorInvoicesListNew extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('ap_vendorinvoice_new_list')
        this.fs.setDefaultSort('txnum asc')
        this.state = {
            checks: [],
            totalChecked: 0,
            data: [],
            tmpData: [],
            defaultFiscalMonth: {},
            dataIsLoaded: false,
            isShowingPostDialog: false,
            page: 1,
            pageSize: { value: 20, label: '20' },
            sortProperty: this.fs.getSort() ?? 'txnum asc',
            showDeleteModal: false,
            totalPurchases: 0,
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }
        this.fiscalMonths = []
        this.invoiceTypes = {
            '-1': 'Payroll',
            0: 'Expense / Misc',
            1: 'Proj.PO',
            2: 'Deposit(Proj.PO)',
            3: 'Deposit(Inventory PO)',
            4: 'Inventory PO',
        }
        this.api = new ApiService()
        this.userCode = getCookie('dmUsercode')
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )

        this.availablePageSize = [
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' },
            { value: 75, label: '75' },
            { value: 100, label: '100' },
        ]
    }

    componentInit() {
        this.setTitle('New | Vendor Invoices').setActionBar(true)
    }

    buildFiscalSelect() {
        let d = new Date()
        d.setMonth(d.getMonth() - 37)

        for (let i = 0; i < 72; i++) {
            d.setMonth(d.getMonth() + 1)
            let value =
                d.getFullYear() +
                '-' +
                ('0' + (d.getMonth() + 1)).slice(-2) +
                '-01T00:00:00'
            let label =
                d.getFullYear() + ' / ' + ('0' + (d.getMonth() + 1)).slice(-2)

            this.fiscalMonths.push({ value: value, label: label })
            if (i == 36)
                this.setState({
                    defaultFiscalMonth: { value: value, label: label },
                })
        }
    }

    async componentDidMount() {
        this.buildFiscalSelect()
        await this.getPayTypes()
        await this.reloadData()

        this.enableSearchAndSortTable()
    }

    async computeTotalPurchases() {
        const invoices = await this.api.get(
            'temporaryvendorinvoices',
            `?$filter=usercode eq ${this.userCode} and hold eq false`
        )

        const sum = invoices.reduce(
            (total, invoice) => total + invoice.netAmount,
            0
        )

        this.setState({
            totalPurchases: sum,
        })
    }

    async getPayTypes() {
        const generalLedgerAccounts = await this.api.getGLAccounts(
            '?$filter=specacct eq 2'
        )

        const payTypesResponse = await this.api.getPayTypes()

        const generalLedgerAccountsSelect = generalLedgerAccounts.map(
            (account) => {
                return {
                    label: `${account.accountn} (${account.account})`,
                    value: account.account,
                }
            }
        )

        const payTypeSelect = payTypesResponse.map((paytype, index) => {
            return {
                label: `${paytype.payType} (${paytype.defAcct})`,
                value: paytype.defAcct,
            }
        })

        this.payTypes = [...generalLedgerAccountsSelect, ...payTypeSelect]
    }

    async reloadData(page) {
        this.setState({ dataIsLoaded: false })

        let data = await this.api.get(
            'temporaryvendorinvoices',
            `?${this.buildFilters(page)}`
        )

        data = !isEmpty(data) ? await this.getDeposits(data) : data

        const checks = data
            .filter((invoice) => {
                return !invoice.hold
            })
            .map((invoice) => {
                return invoice.txnum
            })

        this.setState({
            tmpData: data,
            totalChecked: checks.length,
            checks,
            dataIsLoaded: true,
        })

        this.computeTotalPurchases()

        this.renderData(data)
    }

    async getDeposits(data) {
        const txnums = data.map((i) => i.txnum)
        const invoices = await this.api.get(
            'TemporaryVendorInvoiceDetails',
            `?$filter=txnum in (${txnums.join(',')})`
        )

        if (!isEmpty(invoices)) {
            for (const inv of data) {
                let invdep = 0
                invoices.map((m) => {
                    if (m.txnum === inv.txnum) {
                        invdep += m.depositapp
                    }
                })

                inv.depositapp = invdep ?? 0
            }
        }

        return data
    }

    handleFilter = (name) => (e) => {
        this.setState({ [name]: e })
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    matchFilter = (states) => (p) => {
        return this.checkData(states.defaultFiscalMonth.value, p.fiscalmonth)
    }

    checkData(val, objVal) {
        return val === '' || (val !== '' && val === objVal) ? true : false
    }

    handleMenuClick = (e) => {
        e.preventDefault()

        this.setState(
            (prevState) => (prevState.activeMenu = e.target.dataset.menu)
        )
    }

    handleToggle = (e) => {
        e.preventDefault()
        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState) => {
            let data = {}
            data[id] = !prevState[id]

            return data
        })
    }

    handleSelectAllChecks = async (e) => {
        let checks = []
        const isChecked = e.target.checked

        const invoices = await this.api.get(
            'temporaryvendorinvoices',
            `?$filter=usercode eq ${this.userCode}`
        )
        checks = invoices.map((invoice) => invoice.txnum)

        this.setState({
            checks: isChecked ? checks : [],
            totalChecked: isChecked ? checks.length : 0,
        })

        this.handleTagUpdate(checks, !isChecked)
    }

    handleCheck = (id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === id)
        const isChecked = index > -1

        index > -1 ? checks.splice(index, 1) : checks.push(id)

        this.setState({
            checks: checks,
            totalChecked: checks.length,
        })

        this.handleTagUpdate([id], isChecked)
    }

    async handleTagUpdate(ids, hold) {
        await vendorInvoiceUpdateTagsProcess(ids, hold)
        await this.computeTotalPurchases()
    }

    toggleDeleteModal = (state) => {
        if (true === state && isEmpty(this.state.checks)) {
            // Check if there are selected.
            displayAlert('danger', MSG.error.noSelected)
            return
        }

        this.setState({
            showDeleteModal: state,
        })
    }

    handleDelete = async () => {
        const { checks, totalChecked } = this.state
        const ctr = totalChecked

        if (!checks.length) {
            return
        }

        displayAlertLoader(
            plural(MSG.loading.delete.APVendorInv, 'invoice', ctr)
        )

        this.toggleDeleteModal(false)

        const response = await vendorInvoiceDeleteProcess(this.userCode, checks)
        await vendorInvoiceDisplayDeleteMessage(this, response, checks, ctr)
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    buildFilters(currentPage) {
        let queryString = ''
        let filters = []
        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.max) {
                if (property.type === 'number' || property.type === 'date') {
                    if (property.type === 'number') {
                        if (property.min) {
                            filters.push(`${key} ge ${property.min}`)
                        } else if (property.max) {
                            filters.push(`${key} le ${property.max}`)
                        } else {
                            filters.push(
                                `(contains(cast(${key}, 'Edm.String'),'${property.value}'))`
                            )
                        }
                    }

                    if (property.type === 'date') {
                        if (property.min) {
                            filters.push(`${key} ge ${property.min}`)
                        }
                        if (property.max) {
                            filters.push(`${key} le ${property.max}`)
                        }
                    }
                } else if (property.type === 'month') {
                    if (property.value)
                        filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        const filtersQuery =
            filters.length > 0
                ? `&$filter=usercode eq ${this.userCode} and ${filters.join(
                      ' and '
                  )}`
                : `&$filter=usercode eq ${this.userCode}`

        queryString += `$top=${this.state.pageSize.value + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize.value
        } ${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.reloadData(page)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleSearchDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handlePostSucceeded = () => {
        this.setState({ isShowingPostDialog: false })

        displayAlert('success', 'Post success')

        this.reloadData()
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    renderPagination() {
        return (
            <div className={'d-flex flex-wrap justify-content-center'}>
                <div
                    className={'d-flex flex-row align-items-center pagination'}
                >
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={this.state.page === 1}
                        onClick={() =>
                            this.handleChangePage(this.state.page - 1)
                        }
                    >
                        <i className="ri-arrow-left-s-line"></i> Previous
                    </Button>
                    <span className={'pagination-span'}>{this.state.page}</span>
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={
                            this.state.data.length <= this.state.pageSize.value
                        }
                        onClick={() =>
                            this.handleChangePage(this.state.page + 1)
                        }
                    >
                        Next <i className="ri-arrow-right-s-line ms-1"></i>
                    </Button>
                </div>
                <Select
                    onChange={this.handleFilter('pageSize')}
                    key={`${Math.floor(Math.random() * 1000)}-min`}
                    options={this.availablePageSize}
                    defaultValue={this.state.pageSize}
                    className="react-select pagination-select mx-3"
                    placeholder="Please select"
                />
            </div>
        )
    }

    getItemLink(item) {
        switch (item.type) {
            case 0:
                return (
                    <Link
                        to={generatePath(
                            URI.accountsPayable.vendorInvoice.editNewMisc,
                            {
                                txnum: item.txnum,
                            }
                        )}
                        className="text-charcoal hover-view-icon"
                    >
                        {item.txnum}
                    </Link>
                )
            case 1:
                return (
                    <Link
                        to={generatePath(
                            URI.accountsPayable.vendorInvoice.editNewInvoice,
                            {
                                id: item.txnum,
                            }
                        )}
                        className="text-charcoal hover-view-icon"
                    >
                        {item.txnum}
                    </Link>
                )
            default:
                return (
                    <Link
                        to={generatePath(
                            URI.accountsPayable.vendorInvoice.editNewDeposit,
                            {
                                id: item.txnum,
                            }
                        )}
                        className="text-charcoal hover-view-icon"
                    >
                        {item.txnum}
                    </Link>
                )
        }
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    getPayTypeName(item) {
        return this.payTypes.find((type) => {
            let acct = item.ccacct
            if (!acct) {
                acct = item.paywith
            }
            return type.value === acct || type.label === acct
        })?.label
    }

    renderFilters() {
        const renderPostAction = (action) => {
            const postBtn = (
                <Button
                    href="#"
                    to="#"
                    variant="ivory"
                    size="sm"
                    className={`btn-icon btn-action ${
                        this.state.totalChecked > 0 ? '' : 'disabled'
                    }`}
                    disabled={!this.state.totalChecked}
                    onClick={() => {
                        this.setState({ isShowingPostDialog: true })
                    }}
                >
                    <i className="ri-book-2-line"></i> Post
                </Button>
            )

            const deleteBtn = (
                <Button
                    href="#"
                    to="#"
                    variant="ivory"
                    size="sm"
                    className={`btn-icon btn-action ${
                        this.state.totalChecked > 0 ? '' : 'disabled'
                    }`}
                    disabled={!this.state.totalChecked}
                    onClick={this.toggleDeleteModal.bind(this, true)}
                >
                    <i className="ri-close-line"></i> Delete
                </Button>
            )
            const button = action === 'post' ? postBtn : deleteBtn
            return !this.state.totalChecked ? (
                <OverlayTrigger
                    id="otr"
                    overlay={
                        <Tooltip id="selprop">Please select an entry!</Tooltip>
                    }
                >
                    <div id="dact">{button}</div>
                </OverlayTrigger>
            ) : (
                button
            )
        }

        return (
            <>
                <ListFilter expandable={false}>
                    <ListFilter.Fields
                        md={12}
                        lg={10}
                        xl={10}
                        className="width-30"
                    >
                        <ListFilter.Field>
                            <Form.Label className="text-end">
                                <strong>
                                    Total
                                    <br />
                                    Purchases
                                </strong>
                            </Form.Label>
                            <div className="form-group-extra">
                                <Form.Control
                                    type="text"
                                    disabled={true}
                                    size="sm"
                                    value={currencyFormat(
                                        this.state.totalPurchases
                                    )}
                                />
                                <span className="text-secondary-ash">USD</span>
                            </div>
                        </ListFilter.Field>
                    </ListFilter.Fields>

                    <ListFilter.Actions
                        md={2}
                        lg={2}
                        xl={2}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-md-end"
                    >
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                        <ListFilter.Action className="ms-auto ms-md-3">
                            {renderPostAction('delete')}
                        </ListFilter.Action>
                        <ListFilter.Action className="ms-auto ms-md-3">
                            {renderPostAction('post')}
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        const { data, checks, totalChecked } = this.state
        return (
            <>
                <Row>
                    <Col sm="12">
                        {/* Filter */}
                        {this.renderFilters()}

                        <div className="table-gradient sticky-container">
                            <Table striped responsive className="a-table">
                                <thead>
                                    <tr className="a-table-heading">
                                        <th align="middle" className="mw-80px">
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    label=""
                                                    type="checkbox"
                                                    checked={totalChecked > 0}
                                                    className={getCheckAllClass(
                                                        totalChecked,
                                                        data
                                                    )}
                                                    onClick={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </div>
                                        </th>

                                        <th className="mw-110px">
                                            <span
                                                className={this.sortClass(
                                                    'txnum'
                                                )}
                                                data-field="txnum"
                                            >
                                                Tx Ref No.
                                            </span>
                                        </th>
                                        <th className="mw-110px">
                                            <span
                                                className={this.sortClass(
                                                    'type'
                                                )}
                                                data-field="type"
                                            >
                                                Type
                                            </span>
                                        </th>
                                        <th className="mw-100px">
                                            <span
                                                className={this.sortClass(
                                                    'paywith'
                                                )}
                                                data-field="paywith"
                                            >
                                                Pay With
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'supplier'
                                                )}
                                                data-field="supplier"
                                            >
                                                Vendor Code
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'vendorName'
                                                )}
                                                data-field="vendorName"
                                            >
                                                Vendor Name
                                            </span>
                                        </th>
                                        <th className="mw-140px">
                                            <span
                                                className={this.sortClass(
                                                    'sinvnum'
                                                )}
                                                data-field="sinvnum"
                                            >
                                                Invoice No.
                                            </span>
                                        </th>
                                        <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'ponum'
                                                )}
                                                data-field="ponum"
                                            >
                                                PO No.
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'sinvdt'
                                                )}
                                                data-field="sinvdt"
                                            >
                                                Inv. Date
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'paydt'
                                                )}
                                                data-field="paydt"
                                            >
                                                Pay Date
                                            </span>
                                        </th>
                                        <th>
                                            <span data-field="tcost">
                                                Gross Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'depositapp'
                                                )}
                                                data-field="depositapp"
                                            >
                                                Deposit Applied
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'tcost'
                                                )}
                                                data-field="tcost"
                                            >
                                                Net Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'discount'
                                                )}
                                                data-field="discount"
                                            >
                                                Discount Amount
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'discdays'
                                                )}
                                                data-field="discdays"
                                            >
                                                Disc. Days
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'ccacct'
                                                )}
                                                data-field="ccacct"
                                            >
                                                Credit Card
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className={this.sortClass(
                                                    'txdesc'
                                                )}
                                                data-field="txdesc"
                                            >
                                                Trans. Description
                                            </span>
                                        </th>
                                    </tr>
                                    <tr
                                        className={`a-table-search-fields ${
                                            this.state.showTableSearch
                                                ? ''
                                                : 'd-none'
                                        }`}
                                    >
                                        <th></th>
                                        <th>
                                            <Form.Control
                                                data-type="number"
                                                data-field="txnum"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'txnum'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            {/* <Form.Control
                                                data-type="text"
                                                data-field="type"
                                                onChange={this.handleSearch}
                                            /> */}
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="paywith"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'paywith'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="supplier"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'supplier'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="vendorName"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'vendorName'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="sinvnum"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'sinvnum'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="ponum"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'ponum'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <DateRangePicker
                                                style={{
                                                    minWidth: '200px',
                                                }}
                                                placement="auto"
                                                placeholder="Select date"
                                                format="MM/dd/yyyy"
                                                defaultValue={this.fs.getValue(
                                                    'sinvdt'
                                                )}
                                                onChange={this.handleSearchDateChange.bind(
                                                    this,
                                                    'sinvdt'
                                                )}
                                                onClean={this.handleSearchDateChange.bind(
                                                    this,
                                                    'sinvdt'
                                                )}
                                                ranges={[
                                                    {
                                                        label: 'today',
                                                        value: [
                                                            startOfDay(
                                                                new Date()
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'yesterday',
                                                        value: [
                                                            startOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'last7Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    6
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last 30 Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    30
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'This month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ),
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date()
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ) - 1,
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date(
                                                                        getYear(
                                                                            new Date()
                                                                        ),
                                                                        getMonth(
                                                                            new Date()
                                                                        ) - 1,
                                                                        1
                                                                    )
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                ]}
                                            />
                                        </th>
                                        <th>
                                            <DateRangePicker
                                                style={{
                                                    minWidth: '200px',
                                                }}
                                                placement="auto"
                                                placeholder="Select date"
                                                format="MM/dd/yyyy"
                                                defaultValue={this.fs.getValue(
                                                    'paydt'
                                                )}
                                                onChange={this.handleSearchDateChange.bind(
                                                    this,
                                                    'paydt'
                                                )}
                                                onClean={this.handleSearchDateChange.bind(
                                                    this,
                                                    'paydt'
                                                )}
                                                ranges={[
                                                    {
                                                        label: 'today',
                                                        value: [
                                                            startOfDay(
                                                                new Date()
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'yesterday',
                                                        value: [
                                                            startOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'last7Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    6
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last 30 Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    30
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'This month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ),
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date()
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ) - 1,
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date(
                                                                        getYear(
                                                                            new Date()
                                                                        ),
                                                                        getMonth(
                                                                            new Date()
                                                                        ) - 1,
                                                                        1
                                                                    )
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                ]}
                                            />
                                        </th>
                                        <th>
                                            {/*
                                            "userError": "Could not find a property named 'tcost' on type 'DesignManager.API.Db.DMProDb.Models.Views.GetTempVendorInvoices'."
                                            <Form.Control
                                                data-type="number"
                                                data-field="tcost"
                                                onChange={this.handleSearch}
                                            /> */}
                                        </th>
                                        <th>
                                            {/*
                                            "userError": "Could not find a property named 'depositapp' on type 'DesignManager.API.Db.DMProDb.Models.Views.GetTempVendorInvoices'.",
                                            <Form.Control
                                                data-type="number"
                                                data-field="depositapp"
                                                onChange={this.handleSearch}
                                            /> */}
                                        </th>
                                        <th>
                                            {/*
                                            "userError": "Could not find a property named 'tcost' on type 'DesignManager.API.Db.DMProDb.Models.Views.GetTempVendorInvoices'."
                                            <Form.Control
                                                data-type="number"
                                                data-field="tcost"
                                                onChange={this.handleSearch}
                                            /> */}
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="number"
                                                data-field="discount"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'discount'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="number"
                                                data-field="discdays"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'discdays'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="ccacct"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'ccacct'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                data-type="text"
                                                data-field="txdesc"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'txdesc'
                                                )}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.state.dataIsLoaded
                                        ? showLoading()
                                        : this.state.data.length === 0 &&
                                          isFiltered(
                                              this.state.searchProperties
                                          )
                                        ? showEmpty()
                                        : this.state.data
                                              .slice(
                                                  0,
                                                  this.state.pageSize.value
                                              )
                                              .map((item, i) => (
                                                  <tr
                                                      key={i}
                                                      data-key={i}
                                                      className={
                                                          this.state.checks.includes(
                                                              item.txnum
                                                          )
                                                              ? `active`
                                                              : ''
                                                      }
                                                  >
                                                      <td>
                                                          <div className="d-flex justify-content-center">
                                                              <Form.Check
                                                                  label=""
                                                                  type="checkbox"
                                                                  checked={this.state.checks.includes(
                                                                      item.txnum
                                                                  )}
                                                                  onClick={() => {
                                                                      this.handleCheck(
                                                                          item.txnum
                                                                      )
                                                                  }}
                                                              />
                                                          </div>
                                                      </td>
                                                      <td>
                                                          {this.getItemLink(
                                                              item
                                                          )}
                                                      </td>
                                                      <td>
                                                          {
                                                              this.invoiceTypes[
                                                                  item.type
                                                              ]
                                                          }
                                                      </td>
                                                      <td>
                                                          {this.getPayTypeName(
                                                              item
                                                          )}
                                                      </td>
                                                      <td>{item.supplier}</td>
                                                      <td>{item.vendorName}</td>
                                                      <td>{item.sinvnum}</td>
                                                      <td>{item.poNumber}</td>
                                                      <td>
                                                          {formatDate(
                                                              item.sinvdt
                                                          )}
                                                      </td>
                                                      <td>
                                                          {formatDate(
                                                              item.paydt
                                                          )}
                                                      </td>
                                                      <td>
                                                          {currencyFormatNoWrap(
                                                              item.grossAmount,
                                                              '$'
                                                          )}
                                                      </td>
                                                      <td>
                                                          {currencyFormatNoWrap(
                                                              item.depositapp,
                                                              '$'
                                                          )}
                                                      </td>
                                                      <td>
                                                          {currencyFormatNoWrap(
                                                              item.netAmount,
                                                              '$'
                                                          )}
                                                      </td>
                                                      <td>
                                                          {currencyFormatNoWrap(
                                                              item.discount,
                                                              '$'
                                                          )}
                                                      </td>
                                                      <td>{item.discdays}</td>
                                                      <td>{item.ccacct}</td>
                                                      <td>{item.txdesc}</td>
                                                  </tr>
                                              ))}
                                </tbody>
                            </Table>
                        </div>
                        {(() => {
                            if (this.state.data.length) {
                                return this.renderPagination()
                            }
                        })()}
                    </Col>
                </Row>
                {this.state.isShowingPostDialog && (
                    <PostVendorInvoiceDialog
                        onCancel={() => {
                            this.setState({ isShowingPostDialog: false })
                        }}
                        onPostSucceeded={this.handlePostSucceeded}
                        hasData={this.state.data.length}
                        totalInvoices={this.state.checks.length}
                        invoiceNumbers={this.state.checks}
                    />
                )}
                <ConfirmModal
                    show={this.state.showDeleteModal}
                    toggleModal={this.toggleDeleteModal.bind(this, false)}
                    confirmAction={this.handleDelete.bind(this)}
                />
            </>
        )
    }
}

export default WithRouter(VendorInvoicesListNew)
