import React from 'react'
import BaseBanner from '../BaseBanner'
import BannerContent from './BannerContent'
import BannerBackgroundImage from '../../../../assets/images/resources-banner-image.png'

const ImprovementsBanner = () => (
    <div className="tw-bg-white tw-rounded-lg">
        <BaseBanner
            backgroundImage={BannerBackgroundImage}
            imageAlt="improvements"
            imageClass="tw-hidden sm:tw-block tw-m-auto"
        >
            <BannerContent />
        </BaseBanner>
    </div>
)

ImprovementsBanner.displayName = 'ImprovementsBanner'

export default ImprovementsBanner
