import { HttpWorker, HttpWorkerConfigurationFactory } from './HttpWorker'

interface HttpService {
    httpWorker: HttpWorker
}

export class ApiService implements HttpService {
    readonly httpWorker = new HttpWorker(HttpWorkerConfigurationFactory.api)
    readonly httpAuthWorker = new HttpWorker(
        HttpWorkerConfigurationFactory.auth
    )
}
