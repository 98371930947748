import React from 'react'
import { Row, Col, Modal, Button, Form, Table } from 'react-bootstrap'
import { WithRouter } from '../../../../helpers/Router'
import ThreadComponent from '../../ThreadComponent'
import { ApiService } from '../../../../lib/api/HttpService'
import { currencyFormat } from '../../../../helpers/Number'

class CheckbookDepositDetailDialog extends ThreadComponent {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }

        this.payTypes = {
            2000: 'Misc.',
            2002: 'Retainer',
            2004: 'Deposit',
            2006: 'Payment',
        }

        this.api = new ApiService()
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.crgroupnum !== this.props.crgroupnum) {
            if (this.props.crgroupnum) {
                const filter = `?$filter=crgroupnum eq ${this.props.crgroupnum}`
                const data = await this.api.getPaydistDepositCheckbookDetail(
                    filter
                )

                this.setState({
                    data,
                })
            }
        }
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
                onHide={this.props.hideModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Distribution</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="w-100 px-5">
                        <Col>
                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr className="a-table-heading" key="0">
                                            <th>TX #</th>
                                            <th>Type</th>
                                            <th>Project Code</th>
                                            <th>Project Name</th>
                                            <th className="description">
                                                Description
                                            </th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((item, i) => (
                                            <tr>
                                                <td>{item.txnum}</td>
                                                <td>
                                                    {item.type
                                                        ? this.payTypes[
                                                              item.type
                                                          ]
                                                        : ''}
                                                </td>
                                                <td>{item.proj}</td>
                                                <td>{item.projn}</td>
                                                <td>{item.txdesc}</td>
                                                <td>
                                                    {currencyFormat(
                                                        item.amount,
                                                        '$'
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Row className="align-items-start mb-3"></Row>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={this.props.hideModal}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal}
                                className="w-100 m-2"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default WithRouter(CheckbookDepositDetailDialog)
