import React from 'react'
import { WithRouter } from '../../../helpers/Router'
import ResourceSectionGrid from '../../components/Grids/ResourceSectionGrid/ResourceSectionGrid'
import { useFeature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'
import ClipperGrid from 'app/components/Grids/ClipperGrid/ClipperGrid'
import ImprovementsBanner from '../../components/banners/ImprovementsBanner/ImprovementsBanner'
import NotFoundPage from '../Home/NotFoundPage'
import CashflowInformation from 'app/components/CashflowInformation/CashflowInformation'
import BusinessSummary from 'app/components/BusinessSummary/BusinessSummary'
import RecentProjects from 'app/components/RecentProjects/RecentProjects'
import { useUserQuery } from 'app/context/security'
import { IUser } from 'lib/api/types/ManagedAccounts'

const Dashboard = () => {
    const showDashboard = useFeature(FeatureFlags.DashboardHome)
    const userQuery = useUserQuery(['SecurityProvider', 'userInfo'])
    const { data } = userQuery
    const userData = (data as IUser[]) ?? []
    const [user] = userData
    const displayName = user?.username || ''

    const initials = displayName
        ?.split(' ')
        ?.map((word: string) => word[0]?.toUpperCase())
        ?.join('')

    return showDashboard ? (
        <>
            <div className="tw-bg-white">
                <div className="tw-bg-[#006a53] tw-px-11 tw-pb-8 tw-pt-4 tw-rounded-b-lg tw-text-white tw-flex tw-flex-col">
                    <button className="tw-bg-[#006a53] tw-hidden tw-text-xs tw-px-2 tw-py-1 tw-rounded-full tw-ml-4 tw-mt-1 tw-m-2.5 tw-border tw-border-white tw-self-end">
                        ✨ See what&apos;s new in V1.1
                    </button>
                    <div className="tw-flex tw-items-center">
                        <div className="tw-bg-[#cecece] tw-rounded-full tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-text-2xl tw-font-bold">
                            {initials}
                        </div>
                        <div className="tw-ml-4">
                            <h1 className="tw-text-3xl tw-font-bold">
                                {`Hello, ${displayName}`}
                            </h1>
                        </div>
                    </div>
                    <BusinessSummary />
                </div>
                <div className="tw-px-10 tw-bg-[#faf9f7] tw-grid tw-gap-5">
                    <RecentProjects />
                    <div className="tw-mt-5">
                        <CashflowInformation />
                    </div>
                    <ClipperGrid />
                    <ResourceSectionGrid />
                    <ImprovementsBanner />
                </div>
            </div>
        </>
    ) : (
        <NotFoundPage />
    )
}

Dashboard.displayName = 'Dashboard'

export default WithRouter(Dashboard)
