import { HeaderContext } from 'app/layouts/SuperHeader/SuperHeader'
import {
    EHeaderTheme,
    THeaderOptions,
} from 'app/layouts/SuperHeader/SuperHeaderTypes'
import { useContext, useEffect } from 'react'

export const useHeader = ({
    title,
    breadcrumbs,
    enableBackButton,
    theme = EHeaderTheme.DARK_GREEN,
    actions,
    tabs,
}: THeaderOptions) => {
    const [header, setHeader] = useContext(HeaderContext)

    useEffect(() => {
        if (!header) {
            setHeader({
                title,
                breadcrumbs,
                enableBackButton,
                theme,
                actions,
                tabs,
            })
        }

        return () => {
            if (header) {
                setHeader(null)
            }
        }
    }, [
        header,
        setHeader,
        title,
        breadcrumbs,
        enableBackButton,
        theme,
        actions,
        tabs,
    ])
}
