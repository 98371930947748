import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'react-bootstrap'
import { ApiService } from '../../../lib/api/HttpService'

const TimezoneSelect = React.forwardRef(
    ({ onChange, value, ...props }, ref) => {
        const { isLoading, isError, error, isSuccess, data } = useQuery({
            queryKey: ['settings', 'timezones'],
            queryFn: async () => {
                const api = new ApiService()
                const response = await api.getTimezones()
                const tzs = response.reverse()
                return tzs
            },
        })

        return (
            <Form.Select
                name="timeZoneCode"
                aria-label="Company Timezone"
                ref={ref}
                value={value}
                onChange={onChange}
                {...props}
            >
                {isLoading && <option>Loading...</option>}
                {isError && <option>Error!</option>}
                {isSuccess &&
                    data.map(({ timeZoneCode, displayName }) => (
                        <option key={timeZoneCode} value={timeZoneCode}>
                            {displayName}
                        </option>
                    ))}
            </Form.Select>
        )
    }
)

export default TimezoneSelect
