import React, { forwardRef } from 'react'

import { useHasAccess } from '../../../context/security'

const SecureA = forwardRef(
    ({ attributeNo, attributeType, children, ...props }, ref) => {
        const hasAccess = useHasAccess(attributeNo, attributeType)

        if (!hasAccess) {
            return (
                <span ref={ref} {...props} onClick={() => {}}>
                    {children}
                </span>
            )
        }

        return (
            <a ref={ref} {...props}>
                {children}
            </a>
        )
    }
)

export default SecureA
