import React from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'

export type TModalSize = 'sm' | 'lg' | 'xl'

interface ModalProps {
    show: boolean
    toggleModal?: () => void
    confirmAction?: () => void
    message?: string
    title?: string
    labelOK?: string
    labelCancel?: string
    okBtnStyle?: string
    children?: React.ReactNode
    hideCancel?: boolean
    size?: TModalSize
    footer?: boolean
}

const ConfirmationModal = ({
    show,
    title = 'Delete Confirm',
    labelCancel = 'Close',
    labelOK = 'OK',
    confirmAction,
    hideCancel = false,
    okBtnStyle = 'danger',
    message,
    toggleModal,
    children,
    size = 'sm',
    footer = true,
}: ModalProps) => {
    return (
        <Modal
            size={size}
            show={show}
            onHide={toggleModal}
            aria-labelledby="action-modal"
            className="a-modal"
            backdrop="static"
            centered
        >
            {title && (
                <Modal.Header>
                    <h5 className="text-center mx-auto fw-bold pt-4">
                        {title}
                    </h5>
                </Modal.Header>
            )}
            {(children || message) && (
                <Modal.Body>
                    {message && (
                        <Row className="mb-3">
                            <Col className="px-5">
                                <div className="text-center mx-auto fw-bold pt-4">
                                    <h6>{message}</h6>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {children}
                </Modal.Body>
            )}
            {footer && (
                <Modal.Footer className="bg-ivory py-3 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant={okBtnStyle}
                                onClick={() => {
                                    if (confirmAction) {
                                        confirmAction()
                                    }
                                }}
                                className="w-100 m-2"
                            >
                                {labelOK}
                            </Button>

                            {!hideCancel && (
                                <Button
                                    variant="trans-light border-secondary-ash"
                                    onClick={toggleModal}
                                    className="w-100 m-2"
                                >
                                    {labelCancel}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Modal.Footer>
            )}
        </Modal>
    )
}

ConfirmationModal.displayName = 'ConfirmationModal'

export default ConfirmationModal
