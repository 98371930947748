import { uniqBy, uniqWith } from 'lodash'
import { ApiService } from '../../../../lib/api/HttpService'
import AsyncDropdownQueryFilter from './AsyncDropdownQueryFilter'

type IDataOptions = {
    key: any
    value: any
    id?: any
}

export type IAsyncDropdownOptionsParams = {
    props?: any
    getDataFn?: any
    field?: string
    searchKeys?: string[]
    valueKey?: 'id' | 'key' | 'value'
    type?: string
}

function getAppendedOptions(props: any, search: string) {
    let options = []
    if (props.prependOptions) {
        options = props.prependOptions.filter((opt: any) => {
            const labelValue = `${opt.label} [${opt.value}]`.toLowerCase()
            return labelValue.indexOf(search) > -1
        })
    }

    return options
}

function getAsyncReactSelectOptions({
    props,
    getDataFn,
    field,
    searchKeys,
    valueKey,
    type,
}: IAsyncDropdownOptionsParams) {
    const loadOptions = async (
        search: string,
        loadedOptions: any,
        { page }: { page: number }
    ) => {
        let options = [],
            dataNextPage = [],
            localData = null

        try {
            const filter = new AsyncDropdownQueryFilter({
                props: {
                    ...props,
                    type,
                },
                page,
                search,
                field,
                searchKeys,
                urlQuery: props?.urlQuery ?? '',
            })
            const filterNext = new AsyncDropdownQueryFilter({
                props: {
                    ...props,
                    type,
                },
                page: page + 1,
                search,
                field,
                searchKeys,
                urlQuery: props?.urlQuery ?? '',
                isNext: true,
            })

            localData = await getDataFn(filter.getFilter())
            dataNextPage = await getDataFn(filterNext.getFilter(), {
                isNext: true,
            })

            options = (localData ?? [])?.map((data: any) => {
                let { id, key, value } = data
                const useKey = type === 'inventory' || type === 'warehouses'

                switch (type) {
                    case 'location':
                        id = data.id
                        key = data.loc
                        value = data.locn
                        break
                    case 'inventory':
                        id = data.id
                        key = data.stockno
                        value = data.itemName
                        break
                    case 'warehouses':
                        id = data.id
                        key = data.code
                        value = data.sortname
                        break

                    default:
                        break
                }

                const option: any = {
                    label: getOptionLabel(id, key, value, props),
                    value: useKey
                        ? key
                        : getOptionValue(id, key, value, valueKey, props),
                    name: value ?? '',
                    id: id ? parseInt(id) : id,
                }

                if (props?.addKeyValuePairs) {
                    for (const optkey of Object.keys(props?.addKeyValuePairs)) {
                        option[optkey] = props?.addKeyValuePairs[optkey]
                    }
                }

                return option
            })

            if (page === 1 && props.prependOptions) {
                options = [...getAppendedOptions(props, search), ...options]
            }
        } catch (error) {
            console.error(error)
        }

        return {
            options,
            hasMore: Boolean(dataNextPage?.length ?? false),
            additional: {
                page: page + 1,
            },
        }
    }

    return {
        loadOptions,
    }
}

export function getOptionValue(
    id: any,
    key: any,
    value: any,
    valueKey: any,
    props: any
) {
    if (props?.formatValue) {
        let valueStr = props.formatValue.replace(/%ID%/gi, id)
        valueStr = valueStr.replace(/%KEY%/gi, key)
        valueStr = valueStr.replace(/%VALUE%/gi, value)

        return valueStr
    }

    return valueKey === 'id' ? id : valueKey === 'value' ? value : key
}

export function getOptionLabel(id: any, key: any, value: any, props: any) {
    if (props?.formatLabel) {
        let label = props.formatLabel.replace(/%ID%/gi, id)
        label = label.replace(/%KEY%/gi, key)
        label = label.replace(/%VALUE%/gi, value)

        return label
    }

    return `${value ? `${value} ` : ''}[${key}]`
}

export default getAsyncReactSelectOptions
