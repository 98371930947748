import { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { getClassNames } from '../../helpers/DOM'

class ListFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isExpand: false,
            isBreakPoint: false,
        }

        //  setTimeout(() => { this.resize() }, 30);
    }
    componentDidMount() {
        this.resize()
    }

    checkWidth() {
        if (window.innerWidth <= this.props.breakpoint) {
            this.setState({
                isBreakPoint: true,
            })
        } else {
            this.setState({
                isBreakPoint: false,
            })
        }
    }

    resize() {
        this.checkWidth()

        if (this.props.breakpoint) {
            window.addEventListener('resize', () => {
                this.checkWidth()
            })
        }
    }

    handleClick = (e) => {
        this.setState((state) => {
            return {
                isExpand: !state.isExpand,
            }
        })
    }

    static Fields(props) {
        return (
            <>
                <Col
                    md={props.md || 12}
                    lg={props.lg || 9}
                    {...props}
                    className={getClassNames(
                        props,
                        `filters flex-wrap d-flex align-items-center`
                    )}
                >
                    {props.children}
                </Col>
                {false !== props.separator ? (
                    <div
                        className={`separator filter-separator ${props.separator}`}
                    ></div>
                ) : (
                    <></>
                )}
            </>
        )
    }

    static Field(props) {
        return (
            <div
                {...props}
                className={getClassNames(props, `d-flex align-items-center`)}
            >
                {props.children}
            </div>
        )
    }

    static Actions(props) {
        return (
            <Col
                md={props.md || 12}
                lg={props.lg || 3}
                {...props}
                className={getClassNames(
                    props,
                    `actions d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-xl-end`
                )}
            >
                {props.children}
            </Col>
        )
    }

    static Action(props) {
        return (
            <div
                {...props}
                className={getClassNames(props, 'd-flex align-items-center')}
            >
                {props.children}
            </div>
        )
    }

    static Spacer(props) {
        return (
            <div
                {...props}
                className={getClassNames(props, 'd-block mb-3 mb-md-4')}
            >
                {props.children}
            </div>
        )
    }

    render() {
        return (
            <Container fluid>
                <Row
                    {...this.props}
                    className={getClassNames(
                        this.props,
                        `a-filter border-1 border-sand bg-ivory roundedx-4 ${
                            this.state.isBreakPoint ? 'list-breakpoint' : ''
                        } ${this.state.isExpand ? 'expanded' : ''} ${
                            this.props.expandable ||
                            this.props.expandable === undefined
                                ? 'has-expand'
                                : 'no-separator'
                        }`
                    )}
                >
                    {(() => {
                        if (
                            this.props.expandable ||
                            this.props.expandable === undefined
                        ) {
                            return (
                                <>
                                    <Col xs={12} className="filter-expand">
                                        <Button
                                            variant="light"
                                            className="bg-transparent w-100 btn-icon no-outline no-outline-active"
                                            onClick={this.handleClick}
                                        >
                                            {this.state.isExpand
                                                ? 'Hide'
                                                : 'Show'}{' '}
                                            Menu{' '}
                                            <i className="ri-arrow-down-s-line ri-lg"></i>{' '}
                                        </Button>
                                    </Col>
                                    <div className="separator expand-separator"></div>
                                </>
                            )
                        }
                    })()}
                    {this.props.children}
                </Row>
            </Container>
        )
    }
}

export default ListFilter
