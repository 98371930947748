import { delay, isEmpty } from 'lodash'
import { ApiService } from '../lib/api/HttpService'
import XPromise from './Promise'

interface ILoadImages {
    data: any[]
    property: string
    callback?: any
    endpoint?: any
}

export default function loadItemImages(params: ILoadImages) {
    const { data, property } = params
    const win: any = window
    const api = new ApiService()

    const promises = []
    const itemIds: any = []
    // Let's save the images in local storage
    const imageData: any = {}

    if (data && data.length) {
        for (const item of data) {
            // Check if state current process is still this processId
            if (win.isMounted) {
                if (item[property]) {
                    const promise = api.getFile(
                        item[property] +
                            `/${params.endpoint ?? 'thumb?maxWidth=300'}`
                    )
                    promises.push(promise)
                    itemIds.push(item[property])
                }
            }
        }
    }

    function reflect(promise: any, index: any) {
        return promise.then(
            function (data: any) {
                return { data, status: 'fulfilled' }
            },
            function (error: any) {
                return { error, status: 'rejected' }
            }
        )
    }

    XPromise.all(promises.map(reflect)).then((results: any) => {
        results.map((d: any, i: any) => {
            if (d.status === 'fulfilled') {
                imageData[itemIds[i]] = d.data
            }
        })

        delay(() => {
            params.callback && params.callback(imageData)
        }, 800)
    })
}

interface IRenderImage {
    item?: any
    property?: any
    imageData?: any
    imagesLoaded?: boolean
    altProperty?: string
    props?: any
    isThumbnail?: boolean
    loaderType?: 'skeleton' | 'table'
    style?: object
    className?: string
}

export function getItemImageURL(params: IRenderImage) {
    if (params.item.imageThumb && params.isThumbnail) {
        return params.item.imageThumb
    }

    if (!isEmpty(params.imageData)) {
        return params.imageData[params.item[params.property]]
    }

    return null
}

export function renderItemImage({
    className = 'img-sm',
    ...rest
}: IRenderImage) {
    const params = { ...rest }
    if (!params.item[params.property]) {
        return <></>
    }

    const image = getItemImageURL(params)

    return !params.imagesLoaded ? (
        params.loaderType === 'skeleton' ? (
            <span className="skeleton-loader image"></span>
        ) : (
            <span className={'image'}></span>
        )
    ) : (
        image && (
            <img
                src={image instanceof Blob ? URL.createObjectURL(image) : image}
                alt={params.item[params?.altProperty ?? '']}
                className={className}
                style={params.style}
                {...params.props}
            />
        )
    )
}
