import React from 'react'
import { Form, Table, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'
import ListFilter from '../../components/ListFilter'
import URI from '../../../defaults/RoutesDefault'
import { ApiService } from '../../../lib/api/HttpService'
import { formatDate } from '../../../helpers/Date'
import { showLoading } from '../../../helpers/Loading'

class ProjectViewTicket extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            tmpData: [],
            data: [],
            dataIsLoaded: false,
        }

        this.api = new ApiService()
    }

    async componentDidMount() {
        const data = await this.api.getProjectTickets(
            "?$filter=proj eq '" + this.props.project.proj + "'"
        )

        this.setState({
            tmpData: data,
        })

        this.renderData(data)
        this.enableSearchAndSortTable()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
    }

    handleFilter = (name) => (e) => {
        this.setState({ [name]: e })
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[i] = true
                        document.getElementById(
                            'chk-projectview-items-' + i
                        ).checked = true
                        return null
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    renderFilter() {
        return (
            <>
                <ListFilter expandable={0} className="no-separator">
                    <ListFilter.Actions
                        lg={12}
                        xl={12}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-end"
                    >
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <Button
                                as={Link}
                                to={getRouteWithParam(URI.project.view, {
                                    id: 1,
                                })}
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                            <Button
                                as={Link}
                                to={getRouteWithParam(URI.project.view, {
                                    id: 1,
                                })}
                                variant="ivory"
                                size="sm"
                                className="btn-icon btn-action fw-bold ms-3"
                            >
                                Create Report
                            </Button>
                            <Button
                                as={Link}
                                to={getRouteWithParam(URI.project.view, {
                                    id: 1,
                                })}
                                variant="ivory"
                                size="sm"
                                className="btn-icon btn-action fw-bold ms-3"
                            >
                                Delete
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        return (
            <>
                {/* Filter */}
                {this.renderFilter()}

                <div className="table-gradient">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr key="0" className="a-table-heading">
                                <th align="middle" className="mw-70px">
                                    <div className="d-flex justify-content-center">
                                        <Form.Check
                                            inline
                                            label=""
                                            name={`inline-check-th-0`}
                                            type="checkbox"
                                            data-id={`th-0`}
                                            id={`inline-check-th-0`}
                                            defaultChecked={
                                                this.state.totalChecked > 0
                                                    ? true
                                                    : false
                                            }
                                            className={`${
                                                this.state.totalChecked > 0 &&
                                                this.state.totalChecked <
                                                    this.state.data.length
                                                    ? 'line'
                                                    : ''
                                            }`}
                                            onClick={this.handleSelectAllChecks}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <span
                                        className="sort active asc"
                                        data-field="id"
                                        data-type="number"
                                    >
                                        Ticket No.
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className="sort"
                                        data-field="generateddate"
                                    >
                                        Date
                                    </span>
                                </th>
                                <th>
                                    <span className="sort" data-field="usesite">
                                        Ship to Site
                                    </span>
                                </th>
                                <th>
                                    <span className="sort" data-field="proj">
                                        Ship to Code
                                    </span>
                                </th>
                                <th>
                                    <span className="sort" data-field="shipto">
                                        Ship to Name
                                    </span>
                                </th>
                                <th>
                                    <span className="sort" data-field="remarks">
                                        Remarks / Notes
                                    </span>
                                </th>
                            </tr>
                            <tr
                                className={`a-table-search-fields ${
                                    this.state.showTableSearch ? '' : 'd-none'
                                }`}
                            >
                                <th></th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="id"
                                        data-type="number"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="generateddate"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="usesite"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="proj"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="shipto"
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="remarks"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if (this.state.dataIsLoaded) {
                                    return this.state.data.map((item, i) => (
                                        <tr
                                            key={i}
                                            className={
                                                this.state.checks[i]
                                                    ? `active`
                                                    : ''
                                            }
                                        >
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        label=""
                                                        name={`group-` + i}
                                                        type="checkbox"
                                                        data-id={i}
                                                        id={
                                                            `chk-projectview-items-` +
                                                            i
                                                        }
                                                        className="chk-projectview-items-item"
                                                        defaultValue={i}
                                                        onClick={
                                                            this.handleChecks
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td>{item.id}</td>
                                            <td>
                                                {formatDate(item.generateddate)}
                                            </td>
                                            <td>
                                                <strong
                                                    className={
                                                        item.usesite
                                                            ? `dot-primary`
                                                            : `dot-ivory`
                                                    }
                                                ></strong>
                                            </td>
                                            <td>{item.proj}</td>
                                            <td>{item.shipto}</td>
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))
                                } else {
                                    return showLoading()
                                }
                            })()}
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }
}

export default WithRouter(ProjectViewTicket)
