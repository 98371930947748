import SalesCategoriesDropdown from 'app/components/dropdowns/SalesCategoriesDropdown'
import React from 'react'
import Label from '../../../components/label/Label'
import { Controller, useFormContext } from 'react-hook-form'
import { WhatChanged } from 'lib/api/types/WhatChangedItems'
import { ItemFormValues } from '../types/FormValues'

const SalesCategoriesSelect = ({
    disabled,
    handleRecalculate,
}: {
    disabled: boolean
    handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void
}) => {
    const { control, getValues } = useFormContext<ItemFormValues>()
    return (
        <>
            <Label label="Sales Category *" />
            <Controller
                control={control}
                name="scat"
                rules={{ required: true }}
                render={({ field }) => (
                    <div className="tw-col-span-2 tw-w-full">
                        <SalesCategoriesDropdown
                            isDisabled={disabled}
                            blurInputOnSelect
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => {
                                const selectedValue = getValues('scat')
                                if (selectedValue) {
                                    handleRecalculate(WhatChanged.SALES)
                                }
                            }}
                        />
                    </div>
                )}
            />
        </>
    )
}

SalesCategoriesSelect.displayName = 'SalesCategoriesSelect'

export default SalesCategoriesSelect
