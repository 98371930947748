import React from 'react'
import { Container, Col, Row, Table, Form, Badge } from 'react-bootstrap'
import { fakeProjectData } from '../factory/fakeFactory'

class TableUi extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
        }

        // This binding is necessary to make `this` work in the callback
        this.handleChecks = this.handleChecks.bind(this)
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            prevState.checks[indx] = e.target.checked

            return prevState
        })
    }

    generateFakeHeader(r, x) {
        const items = [],
            itemsData = []

        for (let i = 0; i < x; i++) {
            itemsData.push(<th key={i}>Table Header</th>)
        }

        for (let i = 0; i < r; i++) {
            items.push(<tr key={i}>{itemsData}</tr>)
        }

        return items
    }

    generateFakeRowData(r, x) {
        const items = [],
            itemsData = []

        for (let i = 0; i < x; i++) {
            itemsData.push(<td key={i}>Table Data</td>)
        }

        for (let i = 0; i < r; i++) {
            items.push(<tr key={i}>{itemsData}</tr>)
        }

        return items
    }

    render() {
        return (
            <>
                <div className="bg-secondary-grey pt-5 pb-4 mt-5">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="ui-title">Tables</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="mt-5">
                    <Row>
                        <Col lg="12" className="mb-5">
                            <h4 className="bg-secondary-grey px-2 py-1">
                                Responsive Table
                            </h4>

                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        {this.generateFakeHeader(1, 8)}
                                    </thead>
                                    <tbody>
                                        {this.generateFakeRowData(6, 8)}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-5">
                            <h4 className="bg-secondary-grey px-2 py-1">
                                Fixed Columns & Responsive Table
                            </h4>
                            <div className="table-gradient">
                                <Table
                                    striped
                                    responsive
                                    className="a-table fixed fr-5"
                                >
                                    <thead>
                                        {this.generateFakeHeader(1, 14)}
                                    </thead>
                                    <tbody>
                                        {this.generateFakeRowData(5, 14)}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-5">
                            <h4 className="bg-secondary-grey px-2 py-1">
                                Sample Table Data
                            </h4>
                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr key="0">
                                            <th>Tag</th>
                                            <th>Ref. No</th>
                                            <th>Location Name</th>
                                            <th>Sales Category</th>
                                            <th>Image</th>
                                            <th>Description</th>
                                            <th>Qty</th>
                                            <th>Status</th>
                                            <th>Last Proposal</th>
                                            <th>Last PO/WO</th>
                                            <th>Last Invoice</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fakeProjectData(10).map((item, i) => (
                                            <tr
                                                key={i}
                                                className={
                                                    this.state.checks[i]
                                                        ? `active`
                                                        : ''
                                                }
                                            >
                                                <td>
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`group-` + i}
                                                        type="checkbox"
                                                        data-id={i}
                                                        id={`inline-radio-` + i}
                                                        onChange={
                                                            this.handleChecks
                                                        }
                                                    />
                                                </td>
                                                <td>{item.ref}</td>
                                                <td>{item.location}</td>
                                                <td>{item.category}</td>
                                                <td>
                                                    <img
                                                        src={item.image}
                                                        alt="random"
                                                        className="img-sm"
                                                    />
                                                </td>
                                                <td>{item.description}</td>
                                                <td>{item.qty}</td>
                                                <td>
                                                    <Badge
                                                        bg={item.status.class}
                                                    >
                                                        {item.status.name}
                                                    </Badge>
                                                </td>
                                                <td>{item.proposal}</td>
                                                <td>{item.po}</td>
                                                <td>{item.invoice}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default TableUi
