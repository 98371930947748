import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ApiService } from '../HttpService'

const api = new ApiService()

const useUpdateFile = (
    options?: UseMutationOptions<
        unknown,
        unknown,
        {
            id: string
            file: Blob
        },
        unknown
    >
) => {
    return useMutation({
        mutationFn: ({ id, file }: { id: string; file: Blob }) => {
            const response = api.updateFile(id, { file })
            return response
        },
        ...(options || {}),
    })
}
export default useUpdateFile
