import React from 'react'
import MultiSearchComponents, {
    MultiSearchComponentsProps,
    MultiSearchComponentsState,
} from './MultiSearchComponents'
import Switch from '../../../templates/components/Switch'
import { delay } from 'lodash'

export default class MultiSearchComponentsSwitch extends MultiSearchComponents {
    timeout: any

    constructor(props: MultiSearchComponentsProps) {
        super(props)
        this.state = {
            props,
            toggleChecked: props.defaultValue ?? props.checked,
            cleared: false,
        }
    }

    componentDidUpdate(
        prevProps: Readonly<MultiSearchComponentsProps>,
        prevState: Readonly<MultiSearchComponentsState>
    ): void {
        const { isClear, checked } = this.props
        const { toggleChecked } = this.state

        if (prevProps !== this.props) {
            this.setState({
                props: this.props,
                cleared: isClear,
                toggleChecked: isClear ? checked : toggleChecked,
            })
        }
    }

    render() {
        const { props, toggleChecked } = this.state

        return (
            <div
                className="position-relative mb-2 me-2 rounded-1 border border-sand px-2 py-1 bg-white"
                style={{
                    ...props.style,
                    zIndex: 700 - (props?.zIndex ?? 0),
                }}
            >
                <div className="multisearch-switch d-flex align-items-center justify-content-between">
                    <div
                        className={`d-flex align-items-center ${
                            this.isFixed ? '' : 'me-2'
                        }`}
                    >
                        <span className="me-2">{props.label}</span>
                        <Switch
                            checked={toggleChecked}
                            nolabel={true}
                            onChange={(e: any) => {
                                this.setValue('toggleChecked', e.target.checked)

                                clearTimeout(this.timeout)
                                this.timeout = delay(() => {
                                    props.handleChangeField(e, props)
                                }, 300)
                            }}
                            id={props.id}
                            name={props.name}
                        ></Switch>
                    </div>
                    {!this.isFixed && (
                        <a
                            className="ff-close-dropdown"
                            onClick={() => {
                                props.handleDetach(props.id)
                            }}
                        >
                            <i className="ri-close-fill"></i>
                        </a>
                    )}
                </div>
            </div>
        )
    }
}
