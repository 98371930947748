import React from 'react'
import { Breadcrumb, Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { getActiveMenuUri, WithRouter } from '../../../../helpers/Router'
import URI from '../../../../defaults/RoutesDefault'
import Header from '../../../components/Header'
import { compareStr } from '../../../../helpers/String'
import { getSubComponent } from '../../../../helpers/Util'
import ClientInvoicesListNew from './ClientInvoicesListNew'
import ClientInvoicesListExisting from './ClientInvoicesListExisting'
import { renderSecureContent } from '../../../../utilities/modules/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'

class ClientInvoicesList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeMenu: getActiveMenuUri(
                this.props.paths.pathname,
                ['new', 'existing'],
                'new'
            ),
        }
    }

    handleMenuClick = (e) => {
        e.preventDefault()

        this.setState(
            (prevState) => (prevState.activeMenu = e.target.dataset.menu)
        )
    }

    renderHeader() {
        const { activeMenu } = this.state

        return (
            <Header buttons={1}>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{
                                        to: URI.accountsReceivable.listNew,
                                    }}
                                    linkAs={Link}
                                >
                                    Accounts Receivable
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Client Invoices
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>Client Invoices</Header.Title>
                    </Header.LeftContent>
                    <Header.Actions list={true}>
                        {/* <li className={compareStr(this.props.page, 'new', 'active', '') ? 'd-block' : 'd-none'}>
                            <Button as={Link} to={URI.accountsReceivable.clientInvoices.financeCharges} variant='transparent' size='lg' className='btn-icon text-white border-white'>
                                <i className="ri-add-line ri-lg"></i> Finance Charge
                            </Button>
                        </li> */}
                        <li
                            className={
                                compareStr(this.props.page, 'new', 'active', '')
                                    ? 'd-block'
                                    : 'd-none'
                            }
                        >
                            <SecureBootstrapButton
                                attributeNo={51}
                                attributeType={
                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                }
                                as={Link}
                                to={URI.accountsReceivable.clientInvoices.add}
                                variant="light"
                                size="lg"
                                className="btn-icon text-secondary-green"
                            >
                                <i className="ri-add-line ri-lg"></i> Add
                            </SecureBootstrapButton>
                        </li>
                    </Header.Actions>
                </Header.TitleContent>
                <Header.Buttons>
                    <li>
                        <Link
                            to={URI.accountsReceivable.clientInvoices.listNew}
                            data-menu="new"
                            className={`btn text-white ${compareStr(
                                this.props.page,
                                'new',
                                'active',
                                ''
                            )}`}
                        >
                            New
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={
                                URI.accountsReceivable.clientInvoices
                                    .listExisting
                            }
                            data-menu="existing"
                            className={`btn text-white ${compareStr(
                                this.props.page,
                                'existing',
                                'active',
                                ''
                            )}`}
                        >
                            Existing
                        </Link>
                    </li>
                </Header.Buttons>
            </Header>
        )
    }

    renderContent() {
        return getSubComponent(this.props.page, {
            new: renderSecureContent(<ClientInvoicesListNew />)(
                51,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
            existing: renderSecureContent(<ClientInvoicesListExisting />)(
                52,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
        })
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(ClientInvoicesList)
