import { isThisHour } from 'date-fns'
import React from 'react'
import { Modal, Row, Col, Form, Button } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'

class CashReceiptModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            modalForm: {
                amtdue: '',
                orgpayee: '',
            },
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.show !== this.props.show) {
            this.setState({
                modalForm: this.props.activeCashReceipt,
            })
        }
    }

    handleChange = (event) => {
        const field = event.target.attributes['data-field'].value
        const value = event.target.value
        this.setState((prevState) => ({
            modalForm: { ...prevState.modalForm, [field]: value },
        }))
    }

    resetForm = () => {
        this.setState({
            modalForm: {
                amtdue: '',
                orgpayee: '',
            },
        })
    }

    render() {
        return (
            <Modal
                size="md"
                show={this.props.show}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
            >
                <Modal.Header>Edit Cash Receipt Payment</Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">Amount</Form.Label>
                        </Col>
                        <Col lg={5}>
                            <Form.Control
                                type="text"
                                placeholder="Please enter"
                                data-field="amtdue"
                                value={this.state.modalForm.amtdue}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">
                                Trans. Desc.
                            </Form.Label>
                        </Col>
                        <Col lg={5}>
                            <Form.Control
                                type="text"
                                maxLength={30}
                                placeholder="Please enter"
                                data-field="orgpayee"
                                value={this.state.modalForm.orgpayee}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.props.onSave({
                                        amtdue: parseFloat(
                                            this.state.modalForm.amtdue
                                        ),
                                        orgpayee: this.state.modalForm.orgpayee,
                                    })
                                    this.resetForm()
                                }}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>

                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={() => {
                                    this.props.hideModal()
                                    this.resetForm()
                                }}
                                className="w-100 m-2"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CashReceiptModal
