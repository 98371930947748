import React from 'react'
import {
    Col,
    Container,
    Row,
    Table,
    Form,
    Spinner,
    Button,
} from 'react-bootstrap'
import { observer } from 'mobx-react'
import URI from '../../../defaults/RoutesDefault'
import VendorListViewModel from './VendorListViewModel'
import { Link } from 'react-router-dom'
import VendorListHeader from './components/VendorListHeader'
import VendorListFilter from './components/VendorListFilter'
import { pathParam } from '../../../utilities/Router'
import VendorAddEditViewModel from './VendorAddEditViewModel'
import DeleteModal from '../../components/modal/DeleteModal'
import HandleDeleteWorker from '../../../utilities/DeleteWorker'
import { Pagination } from '../../components/pagination/Pagination'
import { TableComponent, TableComponentState } from '../../TableComponent'

import noItems from '../../../assets/images/icons/vendors.svg'
import { isFiltered } from '../../../helpers/Util'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import AddressAddEditViewModel from '../Address/AddressAddEditViewModel'
import { AddressService } from '../Address/api/AddressService'
import AddressListViewModel from '../Address/AddressListViewModel'
import { displayAlertLoader } from '../../../utilities/Response'
import MSG from '../../../defaults/Message'
import FilterSort from '../../../utilities/modules/FilterSort'

interface Props {
    viewModel: VendorListViewModel
    vendorAddEditModel: VendorAddEditViewModel
}
interface State extends TableComponentState {
    checks: any
    totalChecked: number
    data: []
    tmpData: []
    dataIsLoaded: boolean
    inactive: boolean
    showTableSearch: boolean
    disabledDeleteButton: boolean
    showModal: boolean
    deleteResponses: any
    pageSize: number
    page: number
    sortProperty: string
    searchProperties: {}
}

class VendorListComponent extends TableComponent<Props, State> {
    readonly addressAddEditModel: AddressAddEditViewModel
    readonly viewModel: VendorListViewModel
    readonly vendorAddEditModel: VendorAddEditViewModel
    readonly fs: FilterSort
    deleteObjectParams: any

    constructor(props: Props) {
        super(props)

        this.addressAddEditModel = new AddressAddEditViewModel(
            new AddressService()
        )

        this.viewModel = props.viewModel
        this.vendorAddEditModel = props.vendorAddEditModel
        this.fs = new FilterSort('contact_vendor_list')
        this.fs.setDefaultSort('vendor asc')
        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            tmpData: [],
            dataIsLoaded: false,
            inactive: this.fs.getFilter('inactive') ?? false,
            disabledDeleteButton: true,
            showModal: false,
            deleteResponses: {},
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'vendor asc',
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objSupplier',
            objectCodeOrId: '',
        }
    }

    componentDidMount(): void {
        this.setTitle('Vendors').hasActionBar(true).setBodyClass()

        this.viewModel.reset()
        this.viewModel.fetchVendors(
            this.state.page,
            this.buildFilters(this.state.page)
        )
        this.vendorAddEditModel.componentDidMount()
        super.componentDidMount()
    }

    getFS() {
        return this.fs
    }

    handleSwitch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState(
            {
                inactive: e.target.checked,
            },
            () => {
                this.handleChangePage(1)
            }
        )
        this.fs.setFilter('inactive', e.target.checked)
    }

    hideModal = (e: any) => {
        this.setState({
            showModal: false,
        })
    }

    handleShowTableSearch: any = (state: any) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e: any) => {
                e.value = ''
            })
        }

        this.setState({
            showTableSearch: !state.showTableSearch,
        })
        this.fs.setIsActiveSearch(!state.showTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                () => {
                    this.handleChangePage(1)
                }
            )
        }
    }

    handleChecks = (e: any) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState: State) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked
            prevState.disabledDeleteButton = totalChecked <= 0

            const chkEl: any = document.getElementById('inline-check-th-0')

            if (totalChecked > 0) {
                chkEl.checked = true
            } else {
                chkEl.checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e: any) => {
        // Get the target menu.
        const isChecked: boolean = e.target.checked

        this.setState((prevState: State) => {
            if (isChecked) {
                if (
                    this.state.totalChecked < this.viewModel.vendorList.length
                ) {
                    this.viewModel.vendorList.map((item, i) => {
                        const chkEl: any = document.getElementById(
                            'chk-projectview-items-' + i
                        )
                        prevState.checks[i] = true
                        if (chkEl) {
                            chkEl.checked = true
                        }

                        return null
                    })
                }
                prevState.totalChecked = this.viewModel.vendorList.length
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e: any) => {
                        e.checked = false
                    })
                }
            }
            prevState.disabledDeleteButton = prevState.totalChecked <= 0

            return prevState
        })
    }

    toggleDeleteButton(e: any) {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    handleTestDeleteObjects = (e: any) => {
        const self = this

        self.toggleDeleteButton(e)

        const workers: any = []

        displayAlertLoader(MSG.loading.delete.Vendor)

        for (const [index, value] of Object.entries(this.state.checks)) {
            if (value) {
                this.deleteObjectParams.objectCodeOrId = index
                workers.push(
                    this.vendorAddEditModel.deleteVendor(
                        this.deleteObjectParams
                    )
                )
            }
        }

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: 'Vendor',
                    modulePlural: 'Vendors',
                    reference: 'Projects',
                    objectType: self.deleteObjectParams.objectType,
                },
                false,
                (modalState: any) => {
                    this.setState(modalState)
                }
            )
        }
    }

    // Refresh data
    refreshDataAfterDelete = (e: any) => {
        const headingCheck: any = document.getElementById('inline-check-th-0')

        this.viewModel.componentDidMount()
        this.setState({
            checks: {},
            totalChecked: 0,
            showModal: false,
            deleteResponses: {},
        })
        headingCheck.checked = false
    }

    filterResults() {
        return this.viewModel.vendorList.filter((item) => {
            return item.inactive === this.state.inactive
        })
    }

    buildFilters(currentPage: number) {
        let filtersQuery = ``
        const filters: string[] = []

        filters.push(`inactive eq ${this.state.inactive}`)

        Object.keys(this.state.searchProperties).forEach((key) => {
            // @ts-ignore
            const property = this.state.searchProperties[key]
            if (property.value) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? `&$filter=${filters.join(' and ')}` : ''
        let queryString = `?$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size: number) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page: number) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page: number) => {
        this.viewModel.fetchVendors(page, this.buildFilters(page))

        this.setState({
            page: page,
        })
    }

    handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const key = e.target.attributes['data-field' as any].value
        const value = e.target.value
        const type = e.target.attributes['data-type' as any]
            ? e.target.attributes['data-type' as any].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.handleChangePage(1)
            }
        )
    }

    sortClass(name: string) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    render() {
        let results: any = []
        if (this.viewModel.isLoaded) {
            results = this.filterResults()
        }

        return (
            <>
                {/* begin::Header */}
                <VendorListHeader />
                {/* begin::Header */}

                <div className="content-padding has-action-bar sticky-container">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* begin::Filter */}
                                <VendorListFilter
                                    onShowSearch={this.handleShowTableSearch}
                                    showTableSearch={this.state.showTableSearch}
                                    disabledDelete={
                                        this.state.disabledDeleteButton
                                    }
                                    onDeleteClick={this.handleTestDeleteObjects}
                                    onSwitch={this.handleSwitch}
                                    inactive={this.state.inactive}
                                />
                                {/* begin::Filter */}

                                <Table striped responsive className={`a-table`}>
                                    <thead>
                                        <tr className="a-table-heading">
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'vendor'
                                                    )}
                                                    data-field="vendor"
                                                >
                                                    Vendor Code
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'vendorn'
                                                    )}
                                                    data-field="vendorn"
                                                >
                                                    Vendor Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'payee'
                                                    )}
                                                    data-field="payee"
                                                >
                                                    Payee Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'typedesc'
                                                    )}
                                                    data-field="typedesc"
                                                >
                                                    Type
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'category'
                                                    )}
                                                    data-field="category"
                                                >
                                                    Category
                                                </span>
                                            </th>
                                        </tr>
                                        <tr
                                            className={`a-table-search-fields ${
                                                this.state.showTableSearch
                                                    ? ''
                                                    : 'd-none'
                                            }`}
                                        >
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="vendor"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'vendor'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="vendorn"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'vendorn'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="payee"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'payee'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="typedesc"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'typedesc'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="category"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'category'
                                                    )}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.viewModel.isLoaded
                                            ? showLoading()
                                            : this.viewModel.vendorList
                                                  .length === 0 &&
                                              isFiltered(
                                                  this.state.searchProperties
                                              )
                                            ? showEmpty()
                                            : this.viewModel.vendorList
                                                  .slice(0, this.state.pageSize)
                                                  .map(
                                                      (
                                                          item: any,
                                                          i: number
                                                      ) => (
                                                          <tr
                                                              key={i}
                                                              data-key={i}
                                                              className={
                                                                  this.state
                                                                      .checks[i]
                                                                      ? `active`
                                                                      : ''
                                                              }
                                                          >
                                                              <td>
                                                                  <Form.Check
                                                                      label=""
                                                                      name={
                                                                          `group-` +
                                                                          i
                                                                      }
                                                                      type="checkbox"
                                                                      data-id={
                                                                          item.id
                                                                      }
                                                                      id={
                                                                          `item-check-` +
                                                                          i
                                                                      }
                                                                      defaultChecked={
                                                                          this
                                                                              .state
                                                                              .checks[
                                                                              item
                                                                                  .id
                                                                          ]
                                                                              ? true
                                                                              : false
                                                                      }
                                                                      className="d-none"
                                                                  />
                                                                  <div className="d-flex flex-row align-content-center align-items-center">
                                                                      <Link
                                                                          to={pathParam(
                                                                              URI
                                                                                  .contact
                                                                                  .vendorEdit,
                                                                              {
                                                                                  id: item.id,
                                                                                  tab: 'info',
                                                                              }
                                                                          )}
                                                                          className="text-charcoal hover-view-icon"
                                                                      >
                                                                          {
                                                                              item.vendor
                                                                          }
                                                                      </Link>
                                                                      {item.numAttachments >
                                                                          0 && (
                                                                          <Link
                                                                              to={pathParam(
                                                                                  URI
                                                                                      .contact
                                                                                      .vendorEdit,
                                                                                  {
                                                                                      id: item.id,
                                                                                      tab: 'documents',
                                                                                  }
                                                                              )}
                                                                              style={{
                                                                                  textDecoration:
                                                                                      'none',
                                                                              }}
                                                                              className="text-charcoal border-0 fsx-16 ri-attachment-2 square ms-1"
                                                                          />
                                                                      )}
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.sortName1
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {item.payee}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.typeDesc
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.category
                                                                  }
                                                              </td>
                                                          </tr>
                                                      )
                                                  )}
                                    </tbody>
                                </Table>
                                {this.viewModel.isLoaded &&
                                    !this.viewModel.vendorList.length &&
                                    !isFiltered(
                                        this.state.searchProperties
                                    ) && (
                                        <div className="row justify-content-center text-center py-5">
                                            <div className="col-md-3">
                                                <img
                                                    src={noItems}
                                                    className="mw-100 mb-4"
                                                    alt=""
                                                />

                                                <h6>Manage Your Vendors</h6>
                                                <p>
                                                    This is where you will
                                                    manage the information about
                                                    the vendors you order
                                                    products from.
                                                </p>

                                                <Link
                                                    to={pathParam(
                                                        URI.contact.vendorAdd,
                                                        { tab: 'info' }
                                                    )}
                                                    className="btn btn-icon btn-primary mt-4"
                                                >
                                                    <i className="ri-add-line ri-lg"></i>{' '}
                                                    Add Vendor
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                    {Boolean(this.viewModel.vendorList.length) && (
                        <Pagination
                            onPageSizeChanged={this.onPageSizeChanged}
                            onPageChanged={this.onPageChanged}
                            hasPreviousPage={this.state.page > 1}
                            hasNextPage={
                                this.viewModel.vendorList.length >
                                this.state.pageSize
                            }
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                        />
                    )}
                </div>

                <DeleteModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    hideModal={this.hideModal}
                    responses={this.state.deleteResponses}
                />
            </>
        )
    }
}

export default observer(VendorListComponent)
