import React, { createContext, useContext } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import './SuperHeader.css'
import {
    EHeaderTheme,
    TCrumb,
    THeaderState,
} from 'app/layouts/SuperHeader/SuperHeaderTypes'

export const HeaderContext = createContext<THeaderState>([{}, () => {}])

export const SuperHeader = () => {
    const [header] = useContext(HeaderContext)
    const navigate = useNavigate()

    const breadcrumbTheme =
        header?.theme === EHeaderTheme.DARK_GREEN
            ? 'breadcrumb-light'
            : 'breadcrumb-dark-green'

    const titleTheme =
        header?.theme === EHeaderTheme.DARK_GREEN
            ? 'tw-text-white'
            : 'tw-text-black'

    const tabTheme =
        header?.theme === EHeaderTheme.DARK_GREEN
            ? 'tw-text-white hover:tw-bg-white/25'
            : 'text-black hover:tw-bg-gray-300/25'

    return header ? (
        <>
            <Helmet>
                {header.title && (
                    <title>Reconcile Checkbook History | Design Manager</title>
                )}
            </Helmet>
            <header
                className={`super-header a-header ${header.theme} gradient`}
                data-testid="heading-region"
            >
                <div className="tw-flex tw-items-center tw-mb-10">
                    {header.enableBackButton && (
                        <Button
                            onClick={() => navigate(-1)}
                            className="back-button text-primary active d-flex align-items-center text-decoration-none fw-bold me-4 fs-md"
                            variant="link"
                        >
                            <i className="ri-arrow-left-s-line"></i> Back
                        </Button>
                    )}
                    {header.breadcrumbs && (
                        <Breadcrumb className={breadcrumbTheme}>
                            {header.breadcrumbs.map(
                                (crumb: TCrumb, index: number) =>
                                    crumb.to ? (
                                        <Breadcrumb.Item
                                            key={index}
                                            linkProps={{
                                                to: crumb.to,
                                            }}
                                            linkAs={Link}
                                        >
                                            {crumb.text}
                                        </Breadcrumb.Item>
                                    ) : (
                                        <Breadcrumb.Item key={index}>
                                            {crumb.text}
                                        </Breadcrumb.Item>
                                    )
                            )}
                        </Breadcrumb>
                    )}
                </div>
                <div className="tw-flex tw-items-center tw-mb-6">
                    <div className="tw-grow">
                        {header.title && (
                            <div className="a-header-title d-flex justify-content-between align-items-end align-items-md-center mb-1">
                                <h2 className={titleTheme}>{header.title}</h2>
                            </div>
                        )}
                    </div>
                    {header.actions && (
                        <div className="tw-flex tw-space-x-4">
                            {header.actions}
                        </div>
                    )}
                </div>
                {header.tabs && (
                    <ul
                        className="tw-p-0 tw-mb-10 tw-flex tw-gap-x-2"
                        data-testid="heading-region-light-actions"
                    >
                        {header.tabs.map((tab, i) => {
                            const active = tab.active
                                ? `tw-bg-${
                                      header?.theme === EHeaderTheme.DARK_GREEN
                                          ? 'white'
                                          : 'gray-300'
                                  } tw-bg-opacity-25`
                                : 'tw-bg-transparent'

                            return (
                                <li key={`tab-${tab.id}`}>
                                    <Link
                                        to={tab.to}
                                        className={`tw-transition-colors tw-cursor-pointer tw-py-4 tw-px-6 tw-rounded tw-no-underline tw-font-bold ${active} ${tabTheme}`}
                                    >
                                        {tab.label}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </header>
        </>
    ) : null
}

SuperHeader.displayName = 'SuperHeader'
