import React, { FC, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { displayAlert } from 'utilities/Response'
import AsyncSalesCategoriesDropdown from 'app/components/dropdowns/AsyncSalesCategoriesDropdown'
import FormCurrencyInput from 'app/components/form/FormCurrencyInput'

interface IField {
    title: string
    ids: string[]
    type: 'text' | 'select' | 'currency'
    required?: boolean
    inputRegex?: RegExp
    maxLength?: number
    isUppercase?: boolean
    handler?: (data: Map<string, any>, value: any) => Map<string, any>
    blurHandler?: (data: Map<string, any>, value: any) => Map<string, any>
}

export const StockItemsAddModal: FC<{
    onConfirm: (data: Map<string, any>) => void
    onCancel: () => void
}> = ({ onConfirm, onCancel }) => {
    const [data, setData] = useState<Map<string, any>>(new Map())

    const handleUnitPrice = (data: Map<string, any>, value: number) => {
        const newData = new Map(data)

        if (newData.get('unitEstCost') || !value) {
            return newData
        }

        newData.set('unitEstCost', value)
        newData.set('initialUnitCost', value)

        return newData
    }

    const handleUnitCost = (data: Map<string, any>, value: number) => {
        const newData = new Map(data)

        if (newData.get('unitPrice') || !value) {
            return newData
        }

        newData.set('unitPrice', value)

        return newData
    }

    const fields: IField[] = [
        {
            title: 'Stock No.',
            ids: ['stockNo'],
            type: 'text',
            required: true,
            maxLength: 20,
            inputRegex: /^[a-z0-9]+$/i,
            isUppercase: true,
        },
        { title: 'Name', ids: ['itemName'], type: 'text', maxLength: 45 },
        {
            title: 'Sales Category',
            ids: ['scat'],
            type: 'select',
            required: true,
        },
        {
            title: 'Initial Quantity',
            ids: ['initialQty'],
            type: 'text',
            inputRegex: /^\d*\.?\d{0,2}$/,
        },
        {
            title: 'Unit Sell Price',
            ids: ['unitPrice'],
            type: 'currency',
            blurHandler: handleUnitPrice,
            inputRegex: /^\d*\.?\d{0,2}$/,
        },
        {
            title: 'Est. Unit Cost',
            ids: ['unitEstCost', 'initialUnitCost'],
            type: 'currency',
            blurHandler: handleUnitCost,
            inputRegex: /^\d*\.?\d{0,2}$/,
        },
    ]
    const handleChange = (field: IField, value: any) => {
        if (
            value.length > 0 &&
            field.inputRegex &&
            !field.inputRegex.test(value)
        ) {
            return
        }

        const newData = field.handler?.(new Map(data), value) ?? new Map(data)
        field.ids.forEach((id) => {
            newData.set(id, field.isUppercase ? value.toUpperCase() : value)
        })

        setData(newData)
    }
    const handleBlurChange = (field: IField, value: any) => {
        let newValue = value
        if (field.type == 'currency') {
            newValue = Number(value).toFixed(2)
        }
        const newData =
            field.blurHandler?.(new Map(data), newValue) ?? new Map(data)
        field.ids.forEach((id) => {
            if (field.type == 'currency' && value) {
                newData.set(id, Number(value).toFixed(2))
            }
        })

        setData(newData)
    }
    const handleConfirm = () => {
        if (!isValid()) {
            return
        }

        onConfirm(data)
    }
    const isValid = () => {
        const requiredFields = fields.filter((field) => field.required == true)
        for (const field of requiredFields) {
            for (const id of field.ids) {
                if (!data.get(id)?.length) {
                    displayAlert('danger', `${field.title} is required`)

                    return false
                }
            }
        }
        return true
    }
    const renderField = (field: IField) => {
        switch (field.type) {
            case 'select':
                return (
                    <AsyncSalesCategoriesDropdown
                        onChange={(e: any) => {
                            handleChange(field, e.value)
                        }}
                        placeholder="Please select"
                        className="react-select"
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base: any) => ({
                                ...base,
                                zIndex: 99999,
                            }),
                        }}
                    />
                )
            case 'text':
                return (
                    <Form.Control
                        type="text"
                        placeholder="Please enter"
                        onChange={(e) => {
                            handleChange(field, e.target.value)
                        }}
                        value={
                            field.isUppercase
                                ? data.get(field.ids[0])?.toUpperCase() ?? ''
                                : data.get(field.ids[0]) ?? ''
                        }
                        maxLength={field.maxLength}
                    />
                )
            case 'currency':
                return (
                    <FormCurrencyInput
                        sizeClass="lg"
                        placeholder="Please enter"
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^\d.]/g, '')
                            handleChange(field, value ?? '')
                        }}
                        onBlur={(e) => {
                            const value = e.target.value.replace(/[^\d.]/g, '')
                            handleBlurChange(field, value ?? '')
                        }}
                        value={data.get(field.ids[0]) ?? ''}
                    />
                )
        }
    }

    return (
        <Modal
            show={true}
            aria-labelledby="action-modal"
            className="a-modal"
            backdrop="static"
            centered
        >
            <Modal.Body>
                <Row className="mb-3">
                    <Col className="px-5">
                        <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
                            Add a New Stock Item to Inventory
                        </h5>
                        <div className="fw-bold pt-4">
                            {fields.map((field, index) => {
                                return (
                                    <Row
                                        key={index}
                                        className="align-items-center mb-3"
                                    >
                                        <Col xs={4} className="text-end">
                                            <Form.Label className="mb-0">
                                                {field.title}
                                            </Form.Label>
                                        </Col>
                                        <Col>{renderField(field)}</Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="bg-ivory py-3 px-5">
                <Row className="w-100">
                    <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                        <Button
                            variant="primary"
                            onClick={handleConfirm}
                            className="w-100 m-2"
                        >
                            OK
                        </Button>

                        <Button
                            variant="trans-light border-secondary-ash"
                            onClick={onCancel}
                            className="w-100 m-2"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
StockItemsAddModal.displayName = 'StockItemsAddModal'
