import React from 'react'
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap'
import Select from 'react-select'
import { WithRouter } from '../../../helpers/Router'
import { NavLink, Link, generatePath } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import ThreadComponent from '../ThreadComponent'
import { HeaderLight } from '../../components/Header'
import { FooterFormAction } from '../../components/Form'
import { ApiService } from '../../../lib/api/HttpService'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
    hideAlertLoader,
} from '../../../utilities/Response'
import { navigateOnSuccess } from '../../../utilities/Router'
import { delay, isEmpty } from 'lodash'
import MSG from '../../../defaults/Message'
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown'

class ProjectAddBudgetAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.api = new ApiService()
        this.projectId = props.params.id
        this.budgetId = props.params.bid
        this.isEditing = !isEmpty(this.budgetId)

        this.state = {
            selectData: [],
            company: null,
            project: null,
            isLoaded: false,
            projectName: '',
            form: {
                budgetm: 0,
                budgetd: 0,
                budgetf: 0,
                budgeti: 0,
                budgetl: 0,
                budgeto: 0,
                budgett: 0,
                code: '',
                note: '',
                type: 1,
                defaultCode: null,
            },
            locationQry: '',
        }
        this.initReadOnlyProps()
    }

    initReadOnlyProps() {
        this.saveSuccessMessage = this.isEditing
            ? MSG.success.msg
            : MSG.success.create.ProjectBudget
        this.saveLoadingMessage = this.isEditing
            ? MSG.loading.update.msg
            : MSG.loading.create.ProjectBudget
        this.listURL = generatePath(URI.project.viewPage, {
            id: this.projectId,
            page: 'budget',
        })
    }

    componentInit() {
        const budgetId = this.props.params.bid
        this.title = this.isEditing ? 'Edit Budget' : 'Add Budget'
    }

    async componentDidMount() {
        this.dMloader(true)
        const company = await this.api.get('company', '')
        const [projectData] = await this.api.get(
            'projects',
            `?$filter=id eq ${this.projectId}`
        )

        if (typeof this.budgetId !== 'undefined') {
            let [data] = await this.api.getBudgets(
                `?$filter=code eq '${this.budgetId}' AND proj eq '${projectData?.proj}'`
            )

            let defaultCode = {
                value: data?.code,
                label: data?.code,
            }

            if (data?.type === 1) {
                defaultCode = await this.api.getLocationByCode(
                    data?.code,
                    projectData?.id,
                    true
                )
            }

            this.setState({
                form: {
                    budgetm: data?.budgetm,
                    budgetd: data?.budgetd,
                    budgetf: data?.budgetf,
                    budgeti: data?.budgeti,
                    budgetl: data?.budgetl,
                    budgeto: data?.budgeto,
                    budgett: data?.budgett,
                    code: data?.code,
                    note: data?.note,
                    type: data?.type,
                    defaultCode,
                },
            })
        }

        const projectName = `${
            projectData?.projn ? `${projectData?.projn} ` : ''
        }[${projectData?.proj}]`

        this.setState({
            company: company,
            isLoaded: true,
            project: projectData,
            projectName: projectName,
            locationQry: `?$filter=(projectId eq null or projectId eq ${projectData?.id})`,
        })
        this.dMloader(false)
    }

    handleSaveClick = async (e) => {
        e.preventDefault()

        let budgetData = JSON.parse(JSON.stringify(this.state.form))
        budgetData.proj = this.state.project.proj

        if (
            !budgetData.hasOwnProperty('proj') ||
            !budgetData.hasOwnProperty('type') ||
            !budgetData.hasOwnProperty('code')
        ) {
            displayAlertError(MSG.error.requiredFields)
        } else {
            try {
                displayAlertLoader(this.saveLoadingMessage)

                if (this.isEditing) {
                    delete budgetData['defaultCode']
                    await this.api.updateBudget(budgetData)
                } else {
                    await this.api.postJson('budgets', budgetData)
                }

                displayAlertSuccess(this.saveSuccessMessage)
                const isNew = e.target.name === 'savenew'

                delay(() => {
                    navigateOnSuccess(this, isNew, false, this.listURL)
                }, 1200)
            } catch (error) {
                displayAlertError(getErrorMessage(error))
            }
        }
    }

    handleBudgetChange = (e, field) => {
        let obj = { ...this.state.form }
        let value = e.target.value != '' ? e.target.value : 0

        if (isNaN(value)) {
            return
        }

        obj[field] = value

        const total =
            parseFloat(obj['budgetm'] ?? 0) +
            parseFloat(obj['budgetd'] ?? 0) +
            parseFloat(obj['budgetf'] ?? 0) +
            parseFloat(obj['budgeti'] ?? 0) +
            parseFloat(obj['budgetl'] ?? 0) +
            parseFloat(obj['budgeto'] ?? 0)

        obj['budgett'] = total

        this.setState({ form: obj })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.project.add}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.base }}
                            linkAs={Link}
                        >
                            Projects
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: this.state.isLoaded
                                    ? generatePath(URI.project.viewPage, {
                                          id: this.projectId,
                                          page: 'items',
                                      })
                                    : generatePath(URI.project.add),
                            }}
                            linkAs={Link}
                        >
                            {this.state.isLoaded ? this.state.projectName : ''}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: this.state.isLoaded
                                    ? generatePath(URI.project.viewPage, {
                                          id: this.projectId,
                                          page: 'budget',
                                      })
                                    : generatePath(URI.project.budgetAdd, {
                                          id: this.projectId,
                                      }),
                            }}
                            linkAs={Link}
                        >
                            Budget
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.budgetId ?? 'Add Budget'}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>Budget</HeaderLight.Title>

                    <div>
                        <Button
                            variant="ivory"
                            name="savenew"
                            as={Link}
                            to={this.listURL}
                        >
                            Cancel
                        </Button>
                        {/* Submit Button */}
                        <Button
                            variant="primary ms-2"
                            name="savenew"
                            onClick={this.handleSaveClick.bind(this)}
                        >
                            Save & New
                        </Button>
                        <Button
                            variant="primary ms-2"
                            name="save"
                            onClick={this.handleSaveClick.bind(this)}
                        >
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                {this.state.isLoaded && (
                    <>
                        <div className="content-padding min-height has-action-bar">
                            <Container fluid>
                                <div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
                                    <Row xs={1} lg={2} className="py-4">
                                        <Col>
                                            <h6 className="fw-bold mb-0">
                                                Type
                                            </h6>
                                        </Col>
                                    </Row>
                                </div>

                                <Row className="mb-4">
                                    <Col lg={7}>
                                        <Form.Group controlId="formGridText1">
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0"
                                                >
                                                    <Form.Label className="mb-0">
                                                        <strong>
                                                            Location
                                                        </strong>
                                                    </Form.Label>
                                                </Col>
                                                <Col>
                                                    <Row className="align-items-center">
                                                        {/* show v1
                                            <Col>
                                                <Form.Check
                                                    inline
                                                    label="Sales category"
                                                    name="type-budget"
                                                    type='radio'
                                                    id='type-budget-1'
                                                    className='pe-3'
                                                />
                                            </Col>
                                            */}

                                                        <Col xxl={9}>
                                                            <Row className="align-items-center">
                                                                <Col className="d-flex">
                                                                    <AsyncLocationsDropdown
                                                                        defaultValue={
                                                                            this
                                                                                .state
                                                                                .form
                                                                                .defaultCode
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.setState(
                                                                                {
                                                                                    form: {
                                                                                        ...this
                                                                                            .state
                                                                                            .form,
                                                                                        code: e.value,
                                                                                    },
                                                                                }
                                                                            )
                                                                        }
                                                                        className="react-select flex-fill"
                                                                        placeholder="Please select"
                                                                        urlQuery={
                                                                            this
                                                                                .state
                                                                                .locationQry
                                                                        }
                                                                        isDisabled={
                                                                            this
                                                                                .isEditing
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0"
                                                >
                                                    <Form.Label className="mb-0 pt-2">
                                                        <strong>Notes</strong>
                                                    </Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        defaultValue={
                                                            this.state.form.note
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                form: {
                                                                    ...this
                                                                        .state
                                                                        .form,
                                                                    note: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
                                    <Row xs={1} lg={2} className="py-4">
                                        <Col>
                                            <h6 className="fw-bold mb-0">
                                                Budget
                                            </h6>
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="mb-4">
                                    <Col lg={4} xxl={4}>
                                        {' '}
                                        {/* show v1 change column to 7*/}
                                        <Form.Group controlId="formGridText2">
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0"
                                                ></Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <strong>
                                                                Amount
                                                            </strong>
                                                        </Col>
                                                        {/* show v1
                                            <Col><strong>Action</strong></Col>
                                            <Col><strong>Tolerance %</strong></Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewm
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgetm
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgetm'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewd
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgetd
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgetd'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewf
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgetf
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgetf'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewi
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgeti
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgeti'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewl
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgetl
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgetl'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    {
                                                        this.state.company
                                                            .compviewo
                                                    }
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgeto
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleBudgetChange(
                                                                        e,
                                                                        'budgeto'
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-3">
                                                <Col
                                                    lg={3}
                                                    className="text-lg-end mb-2 mb-lg-0 fsx-14"
                                                >
                                                    Total Budget
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="0.00"
                                                                value={
                                                                    this.state
                                                                        .form
                                                                        .budgett
                                                                }
                                                                disabled
                                                            />
                                                        </Col>
                                                        {/* show v1
                                            <Col>
                                                <Select options={this.state.selectData} className="react-select" placeholder="Please select" />
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder="Please enter" />
                                            </Col>
                                            */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {/* show v1
                                <Row className="align-items-center mb-3">
                                    <Col lg={3} className="text-lg-end mb-2 mb-lg-0 fsx-14 d-none d-lg-block"></Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    inline
                                                    label="Override Mark-up %"
                                                    name={`check-override-markup`}
                                                    type='checkbox'
                                                    data-id={`th-0`}
                                                    id={`check-override-markup`}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                */}
                                        </Form.Group>
                                    </Col>
                                    {/* show v1
                        <Col lg={4} xxl={4}>
                            <Row className="mb-2 align-items-center">
                                <Col xs={4} lg={6} className='d-flex justify-content-lg-end'>
                                    <Form.Check
                                        inline
                                        label="Override Client Deposit %"
                                        name={`budget-checkbox-1`}
                                        type='checkbox'
                                        data-id={`th-0`}
                                        id={`budget-checkbox-1`}
                                    />
                                </Col>
                                <Col xs={5} lg={6}>
                                    <Form.Control type="text" placeholder="Please enter" />
                                </Col>
                            </Row>
                        </Col>
                        */}
                                </Row>
                            </Container>
                        </div>

                        <FooterFormAction>
                            <Button
                                variant="primary"
                                size="lg"
                                name="save"
                                onClick={this.handleSaveClick.bind(this)}
                            >
                                Save
                            </Button>
                        </FooterFormAction>
                    </>
                )}
            </>
        )
    }
}

export default WithRouter(ProjectAddBudgetAdd)
