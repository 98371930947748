import CompPercentTypeDropdown from 'app/components/dropdowns/CompPercentTypeDropdown'
import CompTypeDropdown from 'app/components/dropdowns/CompTypeDropdown'
import React, { useEffect } from 'react'
import AddressesDropdown from 'app/components/dropdowns/ShipToDropdown'
import VendorDropdown from 'app/components/dropdowns/VendorDropdown'
import Label from 'app/components/label/Label'
import { CompNumericPercentType } from 'lib/api/types/CompPercentType'
import { FormControl, Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import BTermsInputs from './BTermsInputs'
import { TWhatChangedComponent } from 'lib/api/types/WhatChangedComponent'
import ImageUploader from './ImageUploader'
import { Component } from 'lib/api/types/Component'
import WysiwygEditor from 'templates/ui-kit/WysiwygEditor'

const MainSection = ({
    disableInputs,
    handleRecalculate,
    description,
    component,
}: {
    description: string
    component: Component
    disableInputs: boolean
    handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void
}) => {
    const methods = useFormContext()

    const useDisc = methods.watch('usedisc')

    const { setValue } = methods

    useEffect(() => {
        if (useDisc === CompNumericPercentType.Fee) {
            setValue('useterms', false)
        }
    }, [component, setValue, useDisc])

    return (
        <div className="tw-pt-7 tw-p-10 tw-flex tw-justify-center tw-gap-20 tw-flex-wrap">
            <div className="tw-grid tw-gap-y-4  tw-gap-x-8 tw-h-full tw-pl-24 tw-grid-cols-[20%_80%] tw-items-center tw-flex-1">
                <Label label="Name" />
                <Controller
                    name="itemName"
                    render={({ field: { value, onChange } }) => (
                        <FormControl
                            id="Name"
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
                <Label label="Description" />
                <Controller
                    control={methods.control}
                    name="description"
                    render={({ field }) => (
                        <WysiwygEditor
                            key={description}
                            keepEmptySpaces
                            triggerChangeOnLoad
                            onChange={(e: {
                                target: {
                                    html: string
                                    value: string
                                }
                            }) => field.onChange(e.target)}
                        >
                            {/* We are using description as key and props to trigger a state change on the Editor because the
                                        Editor internals are all messed up
                                         */}
                            {description}
                        </WysiwygEditor>
                    )}
                />
                <Label label="Type" />
                <Controller
                    control={methods.control}
                    name="comptype"
                    render={({ field }) => (
                        <CompTypeDropdown
                            isDisabled={disableInputs}
                            inputId="Type"
                            blurInputOnSelect
                            value={{
                                label: '',
                                value: field.value,
                            }}
                            onChange={(value) => field.onChange(value.value)}
                            onBlur={() =>
                                handleRecalculate(
                                    TWhatChangedComponent.cwcComponentType
                                )
                            }
                        />
                    )}
                />
                <Label label="Vendor" />
                <Controller
                    control={methods.control}
                    name="supplier"
                    render={({ field }) => (
                        <VendorDropdown
                            inputId="Vendor"
                            blurInputOnSelect
                            isDisabled={disableInputs}
                            value={{
                                label: '',
                                value: field.value,
                            }}
                            onChange={(value) => {
                                setValue('vendorName', value.label)
                                field.onChange(
                                    value.value === '' ? null : value.value
                                )
                            }}
                            onBlur={() =>
                                handleRecalculate(
                                    TWhatChangedComponent.cwcVendor
                                )
                            }
                        />
                    )}
                />
                <input
                    type="hidden"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...methods.register('vendorName')}
                />
                <Label label="Vendor Deposit %" />
                <Controller
                    name="supdep"
                    render={({ field: { value, onChange } }) => (
                        <FormControl
                            id="Vendor Deposit %"
                            onChange={onChange}
                            value={value}
                            disabled={disableInputs}
                            maxLength={15}
                            onBlur={(e) => {
                                const isNotAValidNumber = isNaN(
                                    Number(e.target.value)
                                )
                                if (
                                    !e.target.value ||
                                    isNotAValidNumber ||
                                    e.target.value === '-0.'
                                ) {
                                    methods.setValue('supdep', '0')
                                } else {
                                    methods.setValue(
                                        'supdep',
                                        String(Number(e.target.value))
                                    )
                                }
                                handleRecalculate(
                                    TWhatChangedComponent.cwcVendorDepositPercent
                                )
                            }}
                        />
                    )}
                />
                <div className="tw-col-start-2">
                    <Form.Check
                        disabled={
                            methods.watch('usedisc') ===
                                CompNumericPercentType.Fee || disableInputs
                        }
                        type="checkbox"
                        name={methods.register('useterms').name}
                        ref={methods.register('useterms').ref}
                        onChange={methods.register('useterms').onChange}
                        label="Use Buying Terms"
                        onBlur={() =>
                            handleRecalculate(
                                TWhatChangedComponent.cwcUseBuyingTerms
                            )
                        }
                    />
                </div>
                {methods.watch('useterms') &&
                    methods.watch('usedisc') !== CompNumericPercentType.Fee && (
                        <BTermsInputs
                            disableInputs={disableInputs}
                            handleRecalculate={handleRecalculate}
                        />
                    )}
                <Label label="% Type" />
                <div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
                    <Controller
                        control={methods.control}
                        name="usedisc"
                        render={({ field }) => (
                            <CompPercentTypeDropdown
                                inputId='"% Type'
                                blurInputOnSelect
                                isDisabled={disableInputs}
                                value={{
                                    label: '',
                                    value: field.value,
                                }}
                                onChange={(value) =>
                                    field.onChange(value.value)
                                }
                                onBlur={() => {
                                    if (
                                        methods.watch('usedisc') ===
                                        CompNumericPercentType.Fee
                                    ) {
                                        methods.setValue('useterms', false)
                                    }
                                    handleRecalculate(
                                        TWhatChangedComponent.cwcPercentType
                                    )
                                }}
                            />
                        )}
                    />
                    <div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
                        <Label label="%Value" />
                        <Controller
                            name="markup"
                            render={({ field: { value, onChange } }) => (
                                <FormControl
                                    className="tw-max-w-40"
                                    onChange={onChange}
                                    value={value}
                                    disabled={disableInputs}
                                    maxLength={15}
                                    onBlur={(e) => {
                                        const isNotAValidNumber = isNaN(
                                            Number(e.target.value)
                                        )
                                        if (
                                            !e.target.value ||
                                            isNotAValidNumber ||
                                            e.target.value === '-0.'
                                        ) {
                                            methods.setValue('markup', '0')
                                        } else {
                                            methods.setValue(
                                                'markup',
                                                String(Number(e.target.value))
                                            )
                                        }
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcPercent
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <Label label="Ship To" />
                <Controller
                    control={methods.control}
                    name="shipto"
                    render={({ field }) => (
                        <AddressesDropdown
                            inputId="Ship To"
                            blurInputOnSelect
                            value={field.value}
                            onChange={(value) => field.onChange(value.value)}
                        />
                    )}
                />
                <Label label="Mfg. Cat." />
                <Controller
                    name="catalog"
                    render={({ field: { value, onChange } }) => (
                        <FormControl
                            id="Mfg. Cat."
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </div>
            <div className="tw-flex-1">
                <ImageUploader component={component} />
            </div>
        </div>
    )
}

MainSection.displayName = 'MaiNSection'
export default MainSection
