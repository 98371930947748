import ThreadComponent from '../ThreadComponent'
import { ApiService } from '../../../lib/api/HttpService'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import React from 'react'

class CheckbookVoidModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            fiscalMonth: null,
        }

        this.api = new ApiService()
    }

    async componentDidMount() {
        const company = await this.api.getCompany()
        const company2 = await this.api.getCompany2()

        let fiscalMonth = new Date()
        if (company2.autodeffmo) {
            fiscalMonth = new Date(
                fiscalMonth.setMonth(
                    fiscalMonth.getMonth() + company.fiscaloffset
                )
            )
        } else {
            fiscalMonth = new Date(company2.closedapfmo)
            fiscalMonth.setMonth(fiscalMonth.getMonth() + 1)
        }

        this.setState({
            fiscalMonth: fiscalMonth,
        })
    }

    componentWillUnmount() {
        this.setState({
            fiscalMonth: '',
        })
    }

    handleFiscalMonthChange = (date) => {
        this.setState({ fiscalMonth: date })
    }

    render() {
        return (
            <Modal
                size="md"
                show={true}
                onHide={() => this.hide()}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Void Check</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label
                                htmlFor="inputPassword5"
                                className="ilabel"
                            >
                                Fiscal Month
                            </Form.Label>
                        </Col>
                        <Col>
                            <DatePicker
                                selected={this.state.fiscalMonth}
                                onChange={this.handleFiscalMonthChange}
                                className="form-control "
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hideModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() =>
                            this.props.handleSave(this.state.fiscalMonth)
                        }
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CheckbookVoidModal
