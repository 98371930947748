import {
    CompNumericPercentType,
    CompStringPercentType,
} from './CompPercentType'
import { CompTypes } from './CompTypes'

export enum TWhatChangedComponent {
    cwcEstimatedPrice = 'cwcEstimatedPrice',
    cwcRecalcStatus = 'cwcRecalcStatus',
    cwcQuantity = 'cwcQuantity',
    cwcPercent = 'cwcPercent',
    cwcEstimatedCost = 'cwcEstimatedCost',
    cwcEstimatedUnitCost = 'cwcEstimatedUnitCost',
    cwcEstimatedUnitPrice = 'cwcEstimatedUnitPrice',
    cwcEstimatedMarkup = 'cwcEstimatedMarkup',
    cwcListPrice = 'cwcListPrice',
    cwcUnitListPrice = 'cwcUnitListPrice',
    cwcDiscount = 'cwcDiscount',
    cwcBuyingTerms = 'cwcBuyingTerms',
    cwcUseBuyingTerms = 'cwcUseBuyingTerms',
    cwcVendorDepositPercent = 'cwcVendorDepositPercent',
    cwcComponentType = 'cwcComponentType',
    cwcTaxable = 'cwcTaxable',
    cwcStockInventory = 'cwcStockInventory',
    cwcTimeBilling = 'cwcTimeBilling',
    cwcPercentType = 'cwcPercentType',
    cwcVendor = 'cwcVendor',
    cwcCheckBudget = 'cwcCheckBudget',
}

export type TWhatChangedComponentPayload = {
    whatChanged: `${TWhatChangedComponent}`
    Quantity: number
    Markup: number
    UseDisc: `${CompNumericPercentType}`
    EstCost: number
    EstUnitCost: number
    FeeCost: number
    FeeUnitCost: number
    EstPrice: number
    EstUnitPrice: number
    EstMarkup: number
    List: number
    UnitList: number
    EstDisc: number
    UseTerms: boolean
    BTerm1: number
    BTerm2: number
    BTerm3: number
    SupDep: number
    CompType: `${CompTypes}`
    Taxable: boolean
    Supplier: string
    Activity: string
    NonBillTime: boolean
}

export type TWhatChangedComponentResponse = {
    componentId: number
    compType: keyof typeof CompTypes
    quantity: number
    useDisc: keyof typeof CompStringPercentType
    markup: number
    estUnitCost: number
    estCost: number
    estDisc: number
    estMarkup: number
    estUnitPrice: number
    estPrice: number
    estSalesTax: number
    unitList: number
    list: number
    supDep: number
    supDepAmt: number
    actUnitCost: number
    actCost: number
    actDisc: number
    actMarkup: number
    actUnitPrice: number
    actPrice: number
    actSalesTax: number
    feeUnitCost: number
    feeCost: number
    taxable: boolean
    bTerm1: number
    bTerm2: number
    bTerm3: number
    useTerms: boolean
    supplier: string
    actInvPrice: number
    clInvCost: number
    actInvSalesTax: number
    actInvTaxable: number
    billPrice: number
    billSalesTax: number
    billTaxable: number
    latestPoNumOnly: string
    latestWoNum: string
    latestPoNum: string
    latestPoDtOnly: string
    latestWoDt: string
    latestOrdDt: string
    latestAckDt: string
    latestAckNo: string
    shipBy: string
    latestShpDt: string
    latestExpDt: string
    latestSinDt: string
    latestSinNo: string
    totalChecks: number
    totalChecksDisc: number
    lastCheckNo: string
    lastCheckDt: string
    totSupDep: number
    lastSupDepCheckNo: string
    lastSupDepCheckDt: string
    latestRecDt: string
    totOrdQty: number
    totBoQty: number
    totRecQty: number
    totPaidQty: number
    stockNo: string
    commitWhCode: string
    createdBySInv: boolean
    createdByMisc: boolean
    budgetCheck: TBudgetCheck
}

export type TBudgetCheck = {
    passed: boolean
    isWarning: boolean
    reasonMessage: string
}
