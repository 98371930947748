import React from 'react'
import { Col, Row, Form, Container, Breadcrumb, Table } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import URI from '../../../../defaults/RoutesDefault'
import { Link } from 'react-router-dom'
import { ApiService } from '../../../../lib/api/HttpService'
import Header from '../../../components/Header'
import ListFilter from '../../../components/ListFilter'
import dayjs from 'dayjs'
import { getLocalStorage } from '../../../../utilities/LocalStorage'
import { check } from 'prettier'
import { currencyFormat } from '../../../../helpers/Number'
import { formatDate, formatFiscalMonth } from '../../../../helpers/Date'
import { showLoading } from '../../../../helpers/Loading'

class CheckbookCheckDetail extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            dataIsLoaded: false,
            isLoading: false,
            userDate: dayjs(new Date()).format('MM/YYYY'),
        }
        this.api = new ApiService()
    }

    async componentDidMount() {
        const checkbookData = getLocalStorage('checkbook_detail', true)
        const account = getLocalStorage('account_data')
        const date = dayjs(checkbookData.date).format('YYYY-MM-DD')

        const filter = `?$filter=checknum eq '${checkbookData.checknum}' and supplier eq '${checkbookData.supplier}' and userdate eq ${date} and account eq '${account}'`
        const data = await this.api.getCheckCheckbookDetail(filter)

        this.setState({
            data,
            dataIsLoaded: true,
        })
    }

    componentWillUnmount() {
        window.localStorage.removeItem('checkbook_detail')
        window.localStorage.removeItem('account')
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    renderHeader() {
        return (
            <Header buttons={1}>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{
                                        to: URI.accountsPayable.checkbook.list,
                                    }}
                                    linkAs={Link}
                                >
                                    Accounts Payable
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    linkProps={{
                                        to: URI.accountsPayable.checkbook.list,
                                    }}
                                    linkAs={Link}
                                >
                                    Checkbook
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Check Detail</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>Check Detail</Header.Title>
                    </Header.LeftContent>
                </Header.TitleContent>
            </Header>
        )
    }

    renderFilters() {
        return (
            <>
                <ListFilter>
                    <ListFilter.Fields className="width-33">
                        <ListFilter.Field>
                            <Form.Label className="text-end">
                                <strong>Check Fiscal Month</strong>
                            </Form.Label>
                            <Form.Control
                                size="sm"
                                placeholder="01/1970"
                                value={this.state.userDate}
                                readOnly
                            />
                        </ListFilter.Field>
                    </ListFilter.Fields>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderContent() {
        const { data } = this.state

        return (
            <Container className="ms-0">
                <div className="table-gradient">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr className="a-table-heading">
                                <th>TX #</th>
                                <th>Invoice No.</th>
                                <th>Invoice Date</th>
                                <th>Fiscal Month</th>
                                <th className="description">Description</th>
                                <th>PO No.</th>
                                <th>Amount</th>
                                <th>Discount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.dataIsLoaded
                                ? showLoading()
                                : this.state.data.map((item, i) => (
                                      <tr key={i} data-key={i}>
                                          <td>{item.txnum}</td>
                                          <td>{item.sinvnum}</td>
                                          <td>
                                              {item.sinvdt
                                                  ? formatDate(item.sinvdt)
                                                  : ''}
                                          </td>
                                          <td>
                                              {item.fiscalmonth
                                                  ? formatFiscalMonth(
                                                        item.fiscalmonth
                                                    )
                                                  : ''}
                                          </td>
                                          <td>{item.txdesc}</td>
                                          <td>{item.pon}</td>
                                          <td>
                                              {currencyFormat(item.amount, '$')}
                                          </td>
                                          <td>{item.discountamount}</td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                </div>
            </Container>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderFilters()}
                        {this.renderContent()}
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(CheckbookCheckDetail)
