import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import { compareStr } from '../../../../helpers/String'
import ClientInvoiceAddInvoice from './ClientInvoiceAddInvoice'
import ClientInvoiceAddRemarks from './ClientInvoiceAddRemarks'
import { Is, getSubComponent } from '../../../../helpers/Util'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import { generatePath } from 'react-router-dom'
import {
    displayAlert,
    displayAlertLoader,
    getErrorMessage,
} from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import MSG from '../../../../defaults/Message'
import { delay, find, isEmpty } from 'lodash'
import {
    setDraft,
    setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent'
import { formatDate } from '../../../../helpers/Date'
import { currencyFormat } from '../../../../helpers/Number'

class ClientInvoiceAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeMenu: 'invoice',
            statusCodes: [],
            activeInvoice: { projects: [], items: [], statusCodes: [] },
            dataIsLoaded: false,
            isFound: true,
            switchTab: false,
            imageData: {},
        }

        this.api = new ApiService()
        this.handleMenuClick = this.handleMenuClick.bind(this)
        this.setInitialData()
    }

    componentInit() {
        this.title = 'Add Invoice'
        const invoiceId = this.props.params.invoiceId
        this.title =
            typeof invoiceId !== 'undefined'
                ? `Edit Invoice [${invoiceId}]`
                : 'Add Invoice'
        this.updatedData = {
            selectedItems: [],
        }
        this.isEditing = this.props.params.invoiceId
        this.invoiceId = parseInt(this.props.params.invoiceId)

        this.setIsLoaded(true)
    }

    async componentDidMount() {
        let invoice = { projects: [], items: [] }

        try {
            if (this.props.params.invoiceId) {
                this.setTitle(`Edit Invoice [${this.props.params.invoiceId}]`)
                const filter = `?$filter=invnum eq ${this.props.params.invoiceId}`
                const resp = await this.api.getNewInvoices(filter)

                if (isEmpty(resp)) {
                    throw {
                        response: {
                            status: 404,
                        },
                    }
                }

                invoice = resp[0]

                await this.postInvoiceGrid(invoice)

                const invitems = await this.api.getNewInvoiceItems(filter)
                invoice.currentItems = invitems
                invoice.propNum = invoice.propnum
                setLocalStorageKeyValueObject(
                    'client_invoice_edit',
                    'propNum',
                    invoice.propNum
                )

                const project = await this.api.getProjectByCode(invoice?.proj)
                invoice.projectId = isEmpty(project) ? 0 : project?.id
            }

            let statusCodes = await this.api.getStatusCodes()

            // Update the state to include the retrieved statusCodes
            this.setState((prevState) => ({
                statusCodes,
                activeInvoice: {
                    ...prevState.activeInvoice,
                    statusCodes,
                    ...invoice,
                },
            }))
        } catch (error) {
            this.setError(error, true).setIsLoaded(true).setIsFound(false)
            this.setState({
                stateUpdated: Math.floor(Math.random() * 10000),
            })
        }
    }

    setSwitchTab(state) {
        this.setState({
            switchTab: state,
        })
    }

    postInvoiceGrid = async (invoice) => {
        if (this.props.params.invoiceId && invoice.invnum === undefined) {
            return
        }

        // Let's set the invoice grid first
        const param = {
            projectCode: invoice.proj,
            showAll: true,
            editInvNum: invoice?.invnum ?? 0,
            propNum: invoice?.propnum ?? '',
            creditAmount: 0,
            strItem: '',
            reSortOnly: false,
        }

        try {
            await this.api.postJson('clientinvoices/grid', param)
        } catch (error) {
            console.error('clientinvoices/grid', error)
        }
    }

    componentWillUnmount() {
        window.localStorage.removeItem('client_invoice_edit')
        window.localStorage.removeItem('client_invoice_selected_item')
    }

    setInitialData() {
        const data = {
            projectCode: '',
            invDate: '',
            depAmount: 0,
            retAmount: 0,
            propNum: '',
            txDesc: '',
            remarks: '',
            remarksRTF: '',
            editInvNum: 0,
            mgrCode: '',
            fromReturn: false,
            sendLater: true,
            items: [],
        }

        setLocalStorage(`client_invoice_edit`, data, true)
        this.userCode = getCookie('dmUsercode')
    }

    handleDataChange = (data) => {
        this.updatedData[data.key] = data.value
    }

    renderData(data) {
        this.setIsLoaded(true)
        this.setState({
            projects: data,
        })
    }

    handleMenuClick = (e, attr) => {
        e.preventDefault()

        if ('invoice' !== e.target.dataset.menu) {
            this.setSwitchTab(true)
        }

        this.setState(
            (prevState) => (prevState.activeMenu = e.target.dataset.menu)
        )
    }

    handleSave = async () => {
        if (
            (this.updatedData.retAmount ?? 0) >
            (this.updatedData.availableRetainer ?? 0)
        ) {
            displayAlert(
                'danger',
                `The total amount of deposit plus the retainer is more than what is available. There is ${currencyFormat(
                    this.updatedData.availableRetainer ?? 0
                )} of deposit/retainer available. Please print the Deposit Analysis Report to examine the deposit available.`
            )

            return
        }
        const storedProj = localStorage.getItem('projObj')
        const localStorageKey = storedProj
            ? `${JSON.parse(storedProj).id}_project_date`
            : null
        const storedDate = localStorage.getItem(localStorageKey)
        const date = storedDate ? JSON.parse(storedDate) : null

        if (!this.updatedData.invDate) {
            this.updatedData.invDate = date
        }
        if (!this.updatedData.invDate) {
            displayAlert('danger', `Please enter a valid date.`)
            return
        }

        const itemIds = this.updatedData.selectedItems.map((i) => i.item)

        displayAlertLoader(
            this.isEditing
                ? MSG.loading.update.msg
                : MSG.loading.create.ARClientInv
        )

        try {
            // Delete first the removed items if editing.
            if (
                this.isEditing &&
                this.state.activeInvoice.currentItems?.length
            ) {
                const deleteItems =
                    this.state.activeInvoice.currentItems.filter(
                        (item) => !itemIds.includes(item.item)
                    )

                if (!isEmpty(deleteItems)) {
                    // Let's delete the invoice items
                    const path = `?UserCode=${this.userCode}&InvNum=${this.invoiceId}`

                    await this.api
                        .deleteTemporaryClientInvoices(path)
                        .then((res) => {
                            // this is okay
                        })
                        .catch((error) => {
                            // this is okay
                        })
                }
            }

            for (let item of itemIds) {
                const param = {
                    projectCode: this.updatedData.projectCode,
                    userCode: this.userCode,
                    item: item,
                    tagged: true,
                }
                const resp = await this.api.editClientInvoicesItems(param)
            }

            const invParam = {
                projectCode: this.updatedData.projectCode ?? '',
                invDate:
                    formatDate(this.updatedData.invDate) ??
                    new Date().toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    }),
                depAmount: 0,
                retAmount: this.updatedData.retAmount ?? 0,
                propNum: this.updatedData.propNum ?? '',
                txDesc: this.updatedData.txDesc ?? '',
                remarks: this.updatedData.remarks ?? '',
                remarksrtf: this.updatedData.remarksrtf ?? '',
                editInvNum: this.props.params.invoiceId ?? 0,
                mgrCode: this.updatedData.mgrCode ?? '',
                fromReturn: false,
                sendLater: true,
            }

            this.api
                .postJson('temporaryclientinvoices', invParam)
                .then((res) => {
                    displayAlert(
                        'success',
                        `${
                            this.isEditing
                                ? MSG.success.msg
                                : MSG.success.create.ARClientInv
                        }`
                    )
                    const storedProj = localStorage.getItem('projObj')
                    const localStorageKey = storedProj
                        ? `${JSON.parse(storedProj)?.id}_project_date`
                        : null
                    localStorage.removeItem(localStorageKey)
                    const preData = getLocalStorage('preData')
                    if (preData !== null) {
                        window.localStorage.removeItem('preData')
                    }

                    delay(() => {
                        setDraft(false)
                        this.navigate(
                            generatePath(
                                URI.accountsReceivable.clientInvoices.listNew
                            )
                        )
                    }, 1500)
                })
                .catch((error) => {
                    displayAlert('danger', getErrorMessage(error), 6000)
                })
        } catch (error) {
            displayAlert('danger', getErrorMessage(error))
        }
    }

    setImageData(imageData) {
        this.setState({
            imageData,
        })
    }

    getImageURL(item) {
        if (item.imageThumb) {
            return item.imageThumb
        }

        const images = this.state.imageData
        if (!isEmpty(images)) {
            return images[item.id]
        }

        return null
    }

    renderImage(isImagesLoaded, item) {
        if (!item.primaryImageId) {
            return <></>
        }

        return !isImagesLoaded ? (
            <span className={'image'}></span>
        ) : (
            this.getImageURL(item) && (
                <img
                    src={URL.createObjectURL(this.getImageURL(item))}
                    alt={item.itemName}
                    className="img-sm"
                />
            )
        )
    }

    renderHeader() {
        const { activeMenu } = this.state

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsReceivable.clientInvoices.listNew}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Client Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{this.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>{this.title}</HeaderLight.Title>
                    <div>
                        <Button
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            onClick={() => {
                                window.localStorage.removeItem('preData')
                                window.localStorage.removeItem(
                                    'client_invoice_edit'
                                )
                                window.localStorage.removeItem(
                                    'client_invoice_selected_item'
                                )
                                this.props.navigate(
                                    URI.accountsReceivable.clientInvoices
                                        .listNew
                                )
                            }}
                        >
                            Cancel
                        </Button>
                        {/* Submit Button */}
                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
                <HeaderLight.Actions>
                    <li>
                        <Link
                            to={URI.accountsReceivable.clientInvoices.add}
                            data-menu="invoice"
                            className={
                                `btn text-charcoal ` +
                                compareStr(activeMenu, 'invoice', 'active', '')
                            }
                            onClick={this.handleMenuClick}
                        >
                            Invoice
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={URI.accountsReceivable.clientInvoices.add}
                            data-menu="remarks"
                            className={
                                `btn text-charcoal ` +
                                compareStr(activeMenu, 'remarks', 'active', '')
                            }
                            onClick={this.handleMenuClick}
                        >
                            Remarks
                        </Link>
                    </li>
                </HeaderLight.Actions>
            </HeaderLight>
        )
    }

    renderContent() {
        return getSubComponent(this.state.activeMenu, {
            invoice: (
                <ClientInvoiceAddInvoice
                    statusCodes={this.state.statusCodes}
                    data={this.state.activeInvoice}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                    onPostGrid={this.postInvoiceGrid.bind(this)}
                    switchTab={this.state.switchTab}
                    dataIsLoaded={this.state.dataIsLoaded}
                    renderImage={this.renderImage.bind(this)}
                    setImageData={this.setImageData.bind(this)}
                />
            ),
            remarks: (
                <ClientInvoiceAddRemarks
                    data={this.state.activeInvoice}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                />
            ),
        })
    }

    render() {
        return this.renderView(
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceAdd)
