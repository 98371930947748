import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import { ApiService } from '../../../../lib/api/HttpService'
import DatePicker from 'react-datepicker'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    getErrorMessage,
} from '../../../../utilities/Response'
import { InputModal } from '../../../../app/components/modal/InputModal'
import { isNumeric } from '../../../../helpers/Util'
import _, { capitalize, delay, isEmpty } from 'lodash'
import MSG from '../../../../defaults/Message'
import { setDraft } from '../../../../utilities/FormEvent'
import dayjs from 'dayjs'
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown'
import { removeSpaces } from '../../../../utilities/String'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'

class AccountAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
            data: {},
            budget: {
                budget01: '',
                budget02: '',
                budget03: '',
                budget04: '',
                budget05: '',
                budget06: '',
                budget07: '',
                budget08: '',
                budget09: '',
                budget10: '',
                budget11: '',
                budget12: '',
                fiscalyear: new Date().getFullYear(),
            },
            budgetYearOptions: this.makeDefaultBudgetYearOptions(),
            departments: [{}],
            showDistribute: false,
            isOpen: false,
            defaultSupplier: null,
        }

        this.api = new ApiService()
        this.accountsTypes = [
            { value: 1, label: 'Asset' },
            {
                value: 101,
                label: 'Asset (Bank/Checking)',
                type: 1,
                specacct: 1,
            },
            { value: 2, label: 'Liability' },
            {
                value: 202,
                label: 'Liability (Credit Card)',
                type: 2,
                specacct: 2,
            },
            { value: 3, label: 'Equity' },
            { value: 4, label: 'Revenue' },
            { value: 5, label: 'Cost of Goods' },
            { value: 6, label: 'Operating Expense' },
            { value: 7, label: 'Other Income Expense' },
        ]

        this.vendors = []
    }

    componentInit() {
        this.id = this.props.params.id
        this.isEditing = this.id != null
        this.budgets = []

        this.setTitle(
            `${this.isEditing ? 'Edit' : 'Add'} Account | Accounts`
        ).setFormAction(true)
    }

    async componentDidMount() {
        try {
            this.dMloader(true)
            await this.fetchData()
            this.dMloader(false)
        } catch (error) {
            displayAlert('danger', getErrorMessage(error))
        }
    }

    makeDefaultBudgetYearOptions() {
        const currentYear = new Date().getFullYear()
        return [
            { value: currentYear, label: `${currentYear}` },
            { value: currentYear + 1, label: `${currentYear + 1}` },
        ]
    }

    async fetchData() {
        let data
        if (this.isEditing) {
            const response = await this.api.getGLAccounts(
                `?$filter=account eq '${this.id}'`
            )

            if (response.length > 0) {
                data = response[0]
                this.setState({
                    data: response[0],
                    isOpen: response[0].closedfmo === null,
                })
            }
        }

        await this.fetchDefaultVendors(data)
        this.fetchDepartments()
        this.fetchBudget()
    }

    async fetchDefaultVendors(data) {
        if (data?.supplier) {
            const defaultSupplier = await this.api.getVendorByCode(
                data?.supplier,
                true
            )
            this.setState({
                defaultSupplier,
            })
        }
    }

    async fetchDepartments() {
        const response = await this.api.getGLDepartments()
        this.setState({
            departments: response.map((response) => {
                return { value: response.dept, label: response.deptn }
            }),
        })
    }

    handleChange = (e, meta = {}, callback) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value

            const stateName = `default${capitalize(meta.name)}`

            this.setState({
                [stateName]: e,
            })
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        setDraft(true)
        this.setState(
            {
                data: { ...this.state.data, ...{ [key]: value } },
            },
            callback
        )
    }

    handleDateChange = (date) => {
        this.setState({
            data: {
                ...this.state.data,
                ...{ closedfmo: date },
            },
        })
    }

    handleAccountTypeChange = (e, meta = {}) => {
        if (this.state.data.fixedType === e.value) {
            return
        }

        this.handleChange(e, meta, () => {
            this.setState({
                budget: {
                    budget01: '',
                    budget02: '',
                    budget03: '',
                    budget04: '',
                    budget05: '',
                    budget06: '',
                    budget07: '',
                    budget08: '',
                    budget09: '',
                    budget10: '',
                    budget11: '',
                    budget12: '',
                    fiscalyear: new Date().getFullYear(),
                    account: '',
                },
                data: {
                    ...this.state.data,
                    ...{
                        type: e.type ? e.type : e.value,
                        specacct: e.specacct ? e.specacct : 0,
                    },
                },
            })
        })
    }

    handleBudgetChange = (e, meta = {}, callback) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        let budget = this.state.budget
        budget[key] = value
        budget.total = this.calcTotalBudget(budget)

        this.setState(
            {
                budget: budget,
            },
            callback
        )
    }

    handleYearChange = (e, meta = {}) => {
        setDraft(true)
        this.handleBudgetChange(e, meta, () => {
            const existingBudget = this.budgets.filter((budget) => {
                return budget.fiscalyear === e.value
            })[0]
            if (existingBudget) {
                this.setState({
                    budget: {
                        ...existingBudget,
                        ...{ total: this.calcTotalBudget(existingBudget) },
                    },
                })
            } else {
                this.setState({
                    budget: {
                        ...this.state.budget,
                        ...{
                            budget01: '',
                            budget02: '',
                            budget03: '',
                            budget04: '',
                            budget05: '',
                            budget06: '',
                            budget07: '',
                            budget08: '',
                            budget09: '',
                            budget10: '',
                            budget11: '',
                            budget12: '',
                        },
                    },
                })
            }
        })
    }

    saveBudget() {
        if (!this.isAccountTypeWithBudget()) {
            return
        }

        const data = this.state.budget
        data.account = this.state.data.account

        return this.api.createGLAccountBudget(data)
    }

    updateBudget() {
        if (!this.isAccountTypeWithBudget()) {
            return
        }

        const data = this.state.budget
        data.account = this.state.data.account

        return this.api.editGLAccountBudget(this.state.budget)
    }

    async fetchBudget() {
        if (!this.isEditing) {
            return
        }

        const response = await this.api.getGLAccountBudget(
            `?$filter=account eq '${this.id}'`
        )
        if (response.length === 0) {
            return
        }

        this.budgets = response

        const budget =
            response.filter((response) => {
                return response.fiscalyear === new Date().getFullYear()
            })[0] ?? response[response.length - 1]
        budget.total = this.calcTotalBudget(budget)

        let options = [
            ...this.state.budgetYearOptions,
            ...response.map((response) => {
                return {
                    value: response.fiscalyear,
                    label: `${response.fiscalyear}`,
                }
            }),
        ]
        const uniqueOptions = [
            ...new Map(options.map((item) => [item['value'], item])).values(),
        ].sort(function (a, b) {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })

        this.setState({
            budget: budget,
            budgetYearOptions: uniqueOptions,
        })
    }

    calcTotalBudget(budget) {
        if (Object.keys(budget).length === 0) {
            return 0
        }

        let total = 0
        for (let i = 1; i <= 12; i++) {
            let value = budget[i < 10 ? `budget0${i}` : `budget${i}`]
            value = !isNumeric(value) ? 0 : parseFloat(value)

            total += value
        }

        return total.toFixed(2)
    }

    handleSave = async () => {
        displayAlertLoader(
            this.isEditing
                ? MSG.loading.update.msg
                : MSG.loading.create.GLAccount
        )
        try {
            let { data } = this.state
            if (isEmpty(removeSpaces(data.account))) {
                displayAlertError('Account No. is required.')
                return
            }

            if (isEmpty(removeSpaces(data.accountn))) {
                displayAlertError('Account Name is required.')
                return
            }

            if (!data.type) {
                displayAlertError('Account Type is required.')
                return
            }

            if (data.closedfmo) {
                data.closedfmo = dayjs(data.closedfmo).format('MM/YYYY')
            }

            if (this.isEditing) {
                await this.api.editGLAccount(this.id, data)
                await this.updateBudget()
            } else {
                await this.api.createGLAccount(data)
                await this.saveBudget()
            }

            displayAlert(
                'success',
                this.isEditing ? MSG.success.msg : MSG.success.create.GLAcc
            )
            this.disableForm()
            setDraft(false)

            delay(() => {
                this.props.navigate(URI.generalLedger.account.list)
            }, 1700)
        } catch (error) {
            displayAlert('danger', getErrorMessage(error))
        }
    }

    handleDistribution = (input) => {
        let value = 0

        if (isNumeric(input)) {
            value =
                parseFloat(input) < 0 ? 0 : (parseFloat(input) / 12).toFixed(2)
        }

        this.setState({
            showDistribute: false,
            budget: {
                ...this.state.budget,
                ...{
                    budget01: value,
                    budget02: value,
                    budget03: value,
                    budget04: value,
                    budget05: value,
                    budget06: value,
                    budget07: value,
                    budget08: value,
                    budget09: value,
                    budget10: value,
                    budget11: value,
                    budget12: value,
                    total: (value * 12).toFixed(2),
                },
            },
        })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.generalLedger.account.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.generalLedger.account.base }}
                            linkAs={Link}
                        >
                            General Ledger
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.generalLedger.account.base }}
                            linkAs={Link}
                        >
                            Accounts
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.isEditing ? 'Edit' : 'Add'} Account
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        {this.isEditing ? 'Edit' : 'Add'} Account
                    </HeaderLight.Title>

                    {/* Submit Button */}
                    <SecureBootstrapButton
                        attributeNo={31}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        onClick={this.handleSave}
                    >
                        Save
                    </SecureBootstrapButton>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderBankDetails() {
        if (this.state.data.fixedType !== 101) {
            return
        }

        return (
            <Col>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Bank Number
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    data-field="banknum"
                                    defaultValue={this.state.data.banknum}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Checking
                                    <br />
                                    Acct. No.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    data-field="checknum"
                                    defaultValue={this.state.data.checknum}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0" />
                            <Col lg={5}>
                                <Form.Check
                                    inline
                                    label="Include Voids"
                                    name="check-group-include-voids"
                                    type="checkbox"
                                    id={`check-group-include-voids`}
                                    data-field="ppincludevoids"
                                    defaultChecked={
                                        this.state.data.ppincludevoids
                                    }
                                    onClick={this.handleChange}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Include Hand Checks"
                                    name="check-group-payroll-hc"
                                    type="checkbox"
                                    id={`check-group-include-hc`}
                                    data-field="ppincludehc"
                                    defaultChecked={this.state.data.ppincludehc}
                                    onClick={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
            </Col>
        )
    }

    renderCreditCardDetails() {
        if (this.state.data.fixedType !== 202) {
            return
        }

        return (
            <Row xs={1} lg={2}>
                <Col>
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">CC Payee</Form.Label>
                        </Col>
                        <Col lg={5} className="mb-2 mb-lg-0">
                            <AsyncVendorsDropdown
                                className="react-select"
                                defaultValue={this.state.defaultSupplier}
                                placeholder={'Select Payee'}
                                name="supplier"
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                        <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                            <Form.Label className="mb-0">
                                Cr. Card
                                <br />
                                Number
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                maxLength={30}
                                data-field="checknum"
                                defaultValue={this.state.data.checknum}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    isAccountTypeWithBudget() {
        return [4, 5, 6, 7].includes(this.state.data.fixedType)
    }

    renderBudget() {
        if (!this.isAccountTypeWithBudget()) {
            return
        }

        return (
            <Col>
                {/* <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Dept</Form.Label>
                            </Col>
                            <Col lg={5} className="mb-2 mb-lg-0">
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    options={this.state.departments}
                                    className="react-select"
                                    defaultValue={
                                        this.state.departments.filter(
                                            (department) => {
                                                return (
                                                    department.value ===
                                                    this.state.data.dept
                                                )
                                            }
                                        ) ?? []
                                    }
                                    placeholder={''}
                                    name="dept"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Year</Form.Label>
                            </Col>
                            <Col lg={5} className="mb-2 mb-lg-0">
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    options={this.state.budgetYearOptions}
                                    className="react-select"
                                    defaultValue={
                                        this.state.budgetYearOptions.filter(
                                            (year) => {
                                                return (
                                                    year.value ===
                                                    this.state.budget.fiscalyear
                                                )
                                            }
                                        )[0]
                                    }
                                    placeholder={''}
                                    name="fiscalyear"
                                    onChange={this.handleYearChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col lg={1} className="text-lg-end ms-lg-5 ps-lg-5" />
                    <Col xs={4} lg={3}>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 01
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget01"
                                    value={this.state.budget.budget01}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 02
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget02"
                                    value={this.state.budget.budget02}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 03
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget03"
                                    value={this.state.budget.budget03}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 04
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget04"
                                    value={this.state.budget.budget04}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} lg={3}>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 05
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget05"
                                    value={this.state.budget.budget05}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 06
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget06"
                                    value={this.state.budget.budget06}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 07
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget07"
                                    value={this.state.budget.budget07}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 08
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget08"
                                    value={this.state.budget.budget08}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} lg={3}>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 09
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget09"
                                    value={this.state.budget.budget09}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 10
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget10"
                                    value={this.state.budget.budget10}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 11
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget11"
                                    value={this.state.budget.budget11}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Month 12
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="budget12"
                                    value={this.state.budget.budget12}
                                    onChange={this.handleBudgetChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col lg={1} className="ms-lg-5 ps-lg-5" />
                    <Col xs={4} lg={3}>
                        <Row className="align-items-center mb-3">
                            <Col lg={9} className="mb-2">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        this.setState(
                                            (prev) =>
                                                (prev.showDistribute = true)
                                        )
                                    }}
                                    className="w-100"
                                >
                                    Distribute
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} lg={3} />
                    <Col xs={4} lg={3}>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Total</Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    value={this.state.budget.total}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Account No.
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <Form.Control
                                    type="text"
                                    data-field="account"
                                    defaultValue={this.state.data.account}
                                    disabled={this.isEditing}
                                    onChange={this.handleChange}
                                    maxLength="5"
                                    onKeyPress={(e) =>
                                        !/[0-9]/.test(e.key) &&
                                        e.preventDefault()
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    data-field="accountn"
                                    defaultValue={this.state.data.accountn}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Account Type
                                </Form.Label>
                            </Col>
                            <Col lg={5} className="mb-2 mb-lg-0">
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    options={this.accountsTypes}
                                    className="react-select"
                                    defaultValue={this.accountsTypes.filter(
                                        (type) => {
                                            return (
                                                type.value ===
                                                this.state.data.fixedType
                                            )
                                        }
                                    )}
                                    placeholder={''}
                                    name="fixedType"
                                    onChange={this.handleAccountTypeChange}
                                />
                            </Col>
                            <Col lg={4}>
                                {/* <Form.Check
                                    inline
                                    label="Payroll Account"
                                    name="check-group-payroll-account"
                                    type="checkbox"
                                    id={`check-group-payroll-account`}
                                    data-field="payroll"
                                    defaultChecked={this.state.data.payroll}
                                    onClick={this.handleChange}
                                /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.renderBankDetails()}
                {this.renderCreditCardDetails()}
                {this.renderBudget()}
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0" />
                            <Col lg={5}>
                                <Form.Check
                                    inline
                                    label="Is Open"
                                    name="check-group-is-open"
                                    type="checkbox"
                                    id={`check-group-is-open`}
                                    checked={this.state.isOpen ?? false}
                                    onChange={() => {
                                        const isOpen = !this.state.isOpen
                                        this.setState({
                                            isOpen: isOpen,
                                            data: {
                                                ...this.state.data,
                                                closedfmo: isOpen
                                                    ? null
                                                    : Date.parse(
                                                          this.state.data
                                                              .closedfmo
                                                      ),
                                            },
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Closed as of the
                                    <br />
                                    End of Fiscal Mo.
                                </Form.Label>
                            </Col>
                            <Col lg={5}>
                                <DatePicker
                                    selected={Date.parse(
                                        this.state.data.closedfmo
                                    )}
                                    onChange={this.handleDateChange}
                                    showMonthYearPicker
                                    showMonthDropdown="true"
                                    showYearDropdown="true"
                                    className="form-control"
                                    dateFormat={'MM/yyyy'}
                                    disabled={this.state.isOpen}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height mh-270">
                    <Container className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <SecureBootstrapButton
                        attributeNo={31}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </SecureBootstrapButton>
                </FooterFormAction>

                <InputModal
                    message={'Enter the total budget for the year'}
                    show={this.state.showDistribute}
                    onCancel={() => {
                        this.setState((prev) => (prev.showDistribute = false))
                    }}
                    onConfirm={this.handleDistribution}
                />
            </>
        )
    }
}

export default WithRouter(AccountAdd)
