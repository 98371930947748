import React from 'react'
import { getRouteWithParam } from 'helpers/Router'
import { Breadcrumb, Button, NavLink } from 'react-bootstrap'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import URI from 'defaults/RoutesDefault'
import { HeaderLight } from 'templates/components/Header'
import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import useGetProject from 'lib/api/hooks/useGetProject'
import useDeleteObject from 'lib/api/hooks/useDeleteObject'
import { displayAlertError } from 'utilities/Response'
import BudgetBannerProvider from 'app/context/BudgetBannerContext'
import BudgetBanner, {
    BudgetBannerContext,
} from 'app/components/BudgetBanner/BudgetBanner'
import { WhatChangedResponse } from 'lib/api/types/WhatChangedItems'
import { DeleteType } from 'lib/api/types/DeleteType'
import { DeleteObjectTypes } from 'lib/api/types/DeleteObjectTypes'

type HeaderProps = {
    itemId: number
    disableButtons: boolean
    isEdit: boolean
    budgetCheck?: WhatChangedResponse['budgetCheck']
}

const Header = ({
    itemId,
    disableButtons,
    isEdit,
    budgetCheck,
}: HeaderProps) => {
    const { id } = useParams()

    const { data: project } = useGetProject(id as string)
    const navigate = useNavigate()
    const { mutate: deleteItem, isLoading } = useDeleteObject({
        onSuccess: () =>
            navigate(
                generatePath(URI.project.projectItems, {
                    id,
                })
            ),
        onError: () =>
            displayAlertError(
                'There was an error deleting the Item, please try again'
            ),
    })

    const projectName = `${project?.projn || ''}${
        project?.proj ? ` [${project.proj}]` : ''
    } `.trim()

    const onCancel = () => {
        const confirmation = confirm(
            'Leaving this screen will discard your changes.'
        )

        if (confirmation) {
            if (isEdit) {
                navigate(
                    generatePath(URI.project.projectItems, {
                        id,
                    })
                )
            } else {
                const deletePayload = {
                    deleteType: DeleteType.dmriTestAndFullDeleteIfPossible,
                    objectType: DeleteObjectTypes.objItem,
                    objectCodeOrId: itemId,
                }
                deleteItem(deletePayload)
            }
        }
    }

    return (
        <HeaderLight>
            <HeaderLight.Breadcumbs>
                <NavLink
                    onClick={onCancel}
                    className="tw-!flex tw-mr-6 tw-items-center tw-!text-[#008B6D] tw-!font-bold"
                >
                    <i className="ri-arrow-left-s-line"></i> Back
                </NavLink>
                <Breadcrumb>
                    <Breadcrumb.Item
                        linkProps={{ to: URI.project.base }}
                        linkAs={Link}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkProps={{
                            to: getRouteWithParam(URI.project.projectItems, {
                                id,
                            }),
                        }}
                        linkAs={Link}
                    >
                        {projectName}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkProps={{
                            to: getRouteWithParam(URI.project.projectItems, {
                                id,
                            }),
                        }}
                        linkAs={Link}
                    >
                        Items
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {isEdit ? 'Edit' : 'Add'}{' '}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </HeaderLight.Breadcumbs>
            <HeaderLight.Content>
                <HeaderLight.Title>
                    {isEdit ? 'Edit Item' : 'Add Item'}
                </HeaderLight.Title>
                <div>
                    <Button
                        type="button"
                        disabled={isLoading || disableButtons}
                        onClick={onCancel}
                        className="tw-mr-2"
                        variant="light"
                        data-testid="item-add-cancel-button"
                    >
                        Cancel
                    </Button>
                    <SecureBootstrapButton
                        type="submit"
                        disabled={isLoading || disableButtons}
                        attributeNo={14}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                    >
                        Save
                    </SecureBootstrapButton>
                </div>
            </HeaderLight.Content>
            <BudgetBannerProvider overrideBudgetCheck budgetCheck={budgetCheck}>
                <BudgetBanner
                    context={BudgetBannerContext.Header}
                    dataTestId="budget-banner-item"
                    className="tw-mb-4 tw-mt-4"
                />
            </BudgetBannerProvider>
        </HeaderLight>
    )
}

Header.displayName = 'Header'

export default Header
