import React from 'react'
import { Col, Row, Table, Form } from 'react-bootstrap'
import { currencyFormat } from '../../../../helpers/Number'
import { WithRouter } from '../../../../helpers/Router'
import InputBar from '../../../components/InputBar'
import ThreadComponent from '../../ThreadComponent'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import { debounce } from 'lodash'

class ItemAddPricing extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = props.updatedData

        this.state = {
            data: {},
            dataIsLoaded: false,
            calculate: 'est',
            sortProperty: 'comp asc',
        }

        this.changePageHandler = debounce(this.handleChangePage.bind(this), 200)

        this.cols = [
            'Cost',
            '+',
            'Mu',
            '=',
            'Price',
            '+',
            'STax',
            '=',
            'PriceTotal',
        ]
        this.rows = [
            { value: 'M', label: this.props.company.compviewm },
            { value: 'D', label: this.props.company.compviewd },
            { value: 'F', label: this.props.company.compviewf },
            { value: 'I', label: this.props.company.compviewi },
            { value: 'L', label: this.props.company.compviewl },
            { value: 'O', label: this.props.company.compviewo },
            { value: 'T', label: 'Total' },
        ]
    }

    handleClick = (e) => {
        this.setState({
            calculate: e.target.dataset.calculate,
        })
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.props.onDataChange({ key: key, value: value })
    }

    componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data || {}

            this.setState({
                data: data,
                dataIsLoaded: true,
            })
            this.enableSortTable()
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data || {}

            this.setState({
                data: data,
                dataIsLoaded: true,
            })
        }
    }

    handleChangePage = () => {
        this.props.changeSortOrder({ itemCompSort: this.state.sortProperty })
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        self.setState(
                            {
                                sortProperty: `${
                                    _e.attributes['data-field'].value
                                } ${hasClass('desc', _e) ? 'asc' : 'desc'}`,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    renderInputBar() {
        return (
            <InputBar className="toggle-input full">
                <InputBar.Links style={{ width: '440px', maxWidth: '100%' }}>
                    <InputBar.Link className="single-link">
                        <div>
                            <Form.Check
                                inline
                                label="Estimate"
                                name="inputbar-estimate-1"
                                type="radio"
                                id="inputbar-estimate-1"
                                className="pe-3"
                                onClick={this.handleClick}
                                data-calculate="est"
                                defaultChecked={true}
                            />

                            <Form.Check
                                inline
                                label="Actual"
                                name="inputbar-estimate-1"
                                type="radio"
                                id="inputbar-actual-2"
                                className="pe-3"
                                onClick={this.handleClick}
                                data-calculate="act"
                            />

                            <Form.Check
                                inline
                                label="Billing"
                                name="inputbar-estimate-1"
                                type="radio"
                                id="inputbar-billing-3"
                                className="pe-3"
                                onClick={this.handleClick}
                                data-calculate="bill"
                            />
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
                <InputBar.Links>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Budget Amount
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                type="number"
                                placeholder="0.00"
                                size="sm"
                                defaultValue={
                                    this.updatedData.budget ??
                                    this.props.data.budget
                                }
                                id="budget"
                                onChange={this.handleChange}
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Budget Qty/Hours
                        </Form.Label>
                        <div className="form-group-extra">
                            <Form.Control
                                type="number"
                                placeholder="0.00"
                                size="sm"
                                defaultValue={
                                    this.updatedData.budgethrs ??
                                    this.props.data.budgethrs
                                }
                                id="budgethrs"
                                onChange={this.handleChange}
                            />
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    render() {
        const { data } = this.state

        return (
            <>
                <Row>
                    <Col className="position-relative">
                        <div className="my-4">{this.renderInputBar()}</div>

                        <div className="mb-5">
                            <div className="table-gradient">
                                <Table responsive borderless className="">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Cost</th>
                                            <th></th>
                                            <th>Profit</th>
                                            <th></th>
                                            <th>Price</th>
                                            <th></th>
                                            <th>Sales Tax</th>
                                            <th></th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.rows.map((row) => {
                                            return (
                                                <tr
                                                    key={`${Math.floor(
                                                        Math.random() * 1000000
                                                    )}-min`}
                                                    className={
                                                        row.value === 'T'
                                                            ? 'bg-beige'
                                                            : ''
                                                    }
                                                >
                                                    <td
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    >
                                                        {row.label}
                                                    </td>
                                                    {this.cols.map((col) => {
                                                        if (
                                                            ['+', '='].includes(
                                                                col
                                                            )
                                                        ) {
                                                            return (
                                                                <td
                                                                    key={`${Math.floor(
                                                                        Math.random() *
                                                                            1000000
                                                                    )}-min`}
                                                                    style={{
                                                                        verticalAlign:
                                                                            'middle',
                                                                    }}
                                                                >
                                                                    <h6 className="text-secondary-ash mb-0">
                                                                        {col}
                                                                    </h6>
                                                                </td>
                                                            )
                                                        } else {
                                                            const prefix =
                                                                this.state
                                                                    .calculate ===
                                                                    'bill' &&
                                                                (col ===
                                                                    'Cost' ||
                                                                    col ===
                                                                        'Mu')
                                                                    ? 'est'
                                                                    : this.state
                                                                          .calculate
                                                            let _value =
                                                                data[
                                                                    `${prefix}${row.value}${col}`.toLowerCase()
                                                                ]
                                                            if (
                                                                col ===
                                                                'PriceTotal'
                                                            ) {
                                                                _value =
                                                                    data[
                                                                        `${prefix.toLowerCase()}${row.value.toLowerCase()}${col}`
                                                                    ]
                                                            }
                                                            let value =
                                                                _value ===
                                                                undefined
                                                                    ? 0
                                                                    : _value
                                                            return (
                                                                <td
                                                                    key={`${Math.floor(
                                                                        Math.random() *
                                                                            1000000
                                                                    )}-min`}
                                                                >
                                                                    <div className="form-group-extra reversed">
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="0.00"
                                                                            size="sm"
                                                                            className="align-right"
                                                                            data-id={`${this.state.calculate}${row.value}${col}`.toLowerCase()}
                                                                            disabled
                                                                            defaultValue={currencyFormat(
                                                                                value
                                                                            )}
                                                                        />
                                                                        <span>
                                                                            $
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="table-gradient">
                            <Table striped responsive className="a-table">
                                <thead>
                                    <tr className="a-table-heading">
                                        <th>
                                            <span
                                                className="sort active asc"
                                                data-field="comp"
                                            >
                                                #
                                            </span>
                                        </th>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Est. Cost</th>
                                        <th>Act. Cost</th>
                                        <th>Est. Price</th>
                                        <th>Billing Price</th>
                                        <th>Act. Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data.itemComponents || []).map(
                                        (item, i) => (
                                            <tr key={i}>
                                                <td>{item.comp}</td>
                                                <td>
                                                    {item.desc || item.descrtf}
                                                </td>
                                                <td>
                                                    {
                                                        this.props.data
                                                            .componentTypes[
                                                            `${item.comptype}`
                                                        ]
                                                    }
                                                </td>
                                                <td className="align-right">
                                                    {currencyFormat(
                                                        item.estcost,
                                                        '$'
                                                    )}
                                                </td>
                                                <td className="align-right">
                                                    {currencyFormat(
                                                        item.actcost,
                                                        '$'
                                                    )}
                                                </td>
                                                <td className="align-right">
                                                    {currencyFormat(
                                                        item.estprice,
                                                        '$'
                                                    )}
                                                </td>
                                                <td className="align-right">
                                                    {currencyFormat(
                                                        item.billprice,
                                                        '$'
                                                    )}
                                                </td>
                                                <td className="align-right">
                                                    {currencyFormat(
                                                        item.actprice,
                                                        '$'
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export default WithRouter(ItemAddPricing)
