import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import URI from 'defaults/RoutesDefault'
import { Button, Table } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'
import { ApiService, TReconciliation } from 'lib/api/HttpService'
import SecureContent from 'app/components/security/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import { useAccounts } from 'pages/accounts-payable/credit-card/reconcile/hooks'
import { CheckbookHistoryTableHeader } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/partials/CheckbookHistoryTableHeader'
import { CheckbookHistoryTableBody } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/partials/CheckbookHistoryTableBody'
import AccountsDropdown, {
    EAccountType,
    TAccountsDropdownOption,
} from 'pages/accounts-payable/credit-card/reconcile/AccountsDropdown'
import SearchButton from 'pages/accounts-payable/credit-card/reconcile/SearchButton'
import { SelectInstance } from 'react-select'
import { useHeader } from 'hooks/useHeader'
import { TCheckbookReconcileSort } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryTypes'

const api = new ApiService()

export const CheckbookHistory = () => {
    const [searching, setSearching] = useState(false)
    const [searches, setSearches] = useState<
        TReconciliation | Record<string, any>
    >({})
    const [sort, setSort] = useState<TCheckbookReconcileSort>([
        'statementdate',
        'desc',
    ])
    const [selectedAccount, setSelectedAccount] = useState('')
    const acctRef = useRef<SelectInstance<TAccountsDropdownOption> | null>(null)

    useHeader({
        title: 'Reconcile Checkbook History',
        breadcrumbs: [
            {
                text: 'Accounts Payable',
                to: URI.accountsPayable.creditCard.list,
            },
            {
                text: 'Checkbook',
                to: URI.accountsPayable.checkbook.list,
            },
            {
                text: 'Reconcile History',
            },
        ],
        tabs: [
            {
                label: 'Checkbook Overview',
                to: URI.accountsPayable.checkbook.base,
                active: false,
                id: 'checkbook',
            },
            {
                label: 'Reconcile',
                to: URI.accountsPayable.checkbook.reconcile,
                active: false,
                id: 'reconcile',
            },
            {
                label: 'Reconcile History',
                to: URI.accountsPayable.checkbook.history,
                active: true,
                id: 'reconcile-history',
            },
        ],
        enableBackButton: true,
    })

    const accountsQuery = useAccounts()

    const reconciliations = useQuery({
        queryKey: ['reconciliations'],
        queryFn: () => {
            return api.getReconciliations()
        },
    })

    const onAccountChange = (selected: TAccountsDropdownOption) => {
        if (selected) {
            setSelectedAccount(selected.value)
        }
    }

    return (
        <>
            <SecureContent
                attributeNo={61}
                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
            >
                <SecureContent.NoAccess>
                    <div className="content-padding min-height has-action-bar mt-2">
                        <SecureContent.GenericNoAccess />
                    </div>
                </SecureContent.NoAccess>
                <SecureContent.HasAccess>
                    {accountsQuery.data && (
                        <div className="content-padding min-height has-action-bar mt-2">
                            <div className="a-filter border-1 border-sand bg-ivory rounderdx-4">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="d-flex flex-row w-50">
                                        <AccountsDropdown
                                            className="accounts-dropdown"
                                            onChange={onAccountChange}
                                            topLabel="All Accounts"
                                            type={EAccountType.ALL}
                                            ref={acctRef}
                                        />
                                        {selectedAccount !== '' && (
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setSelectedAccount('')
                                                    acctRef?.current?.clearValue()
                                                }}
                                            >
                                                clear
                                            </Button>
                                        )}
                                    </div>

                                    <SearchButton
                                        className={''}
                                        onClick={() =>
                                            setSearching((prev) => !prev)
                                        }
                                        searching={searching}
                                    />
                                </div>
                            </div>
                            <div className="table-gradient sticky-container">
                                <div className="table-responsive">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                        id="cc-reconcile-history"
                                    >
                                        <CheckbookHistoryTableHeader
                                            searching={searching}
                                            searches={
                                                searches as TReconciliation
                                            }
                                            setSearches={
                                                setSearches as Dispatch<
                                                    SetStateAction<TReconciliation>
                                                >
                                            }
                                            sort={sort}
                                            setSort={setSort}
                                        />
                                        <CheckbookHistoryTableBody
                                            query={reconciliations}
                                            searches={
                                                searches as TReconciliation
                                            }
                                            selectedAccount={selectedAccount}
                                            sort={sort}
                                        />
                                    </Table>
                                </div>
                            </div>
                        </div>
                    )}
                </SecureContent.HasAccess>
            </SecureContent>
        </>
    )
}

CheckbookHistory.displayName = 'CheckbookHistory'
