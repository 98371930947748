import React from 'react'
import ThreadComponent from '../../ThreadComponent'
import { observer } from 'mobx-react'
import EmployeeAddEditHeader from './components/EmployeeAddEditHeader'
import { WithRouter, pathParam } from '../../../utilities/Router'
import { Container } from 'react-bootstrap'
import EmployeeAddEditInfo from './components/EmployeeAddEditInfo'
import EmployeeAddEditViewModel from './EmployeeAddEditViewModel'
import AddressAddEditViewModel from '../Address/AddressAddEditViewModel'
import EmployeeAddEditNotes from './components/EmployeeAddEditNotes'
import EmployeeAddEditTime from './components/EmployeeAddEditTime'
import EmployeeAddEditPOS from './components/EmployeeAddEditPOS'
import URI from '../../../defaults/RoutesDefault'
import { getLocalStorage } from '../../../utilities/LocalStorage'
import { displayAlert, handleResponseAlert } from '../../../utilities/Response'
import { EmployeeValidation } from './validations/EmployeeValidation'
import { validateInput } from '../../components/validators/Helpers'
import SecureContent from '../../components/security/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from '../../context/security'
import Documents from '../../../templates/modules/documents/Documents'

interface Props {
    employeeAddEditModel: EmployeeAddEditViewModel
    addressAddEditModel: AddressAddEditViewModel
    params?: any
    navigate?: any
}

interface State {
    data: any
    isLoading: boolean
    isDraft: boolean
    numAttachments?: number
}

class EmployeeAddEditComponent extends ThreadComponent<Props, State> {
    readonly employeeAddEditModel: EmployeeAddEditViewModel
    readonly addressAddEditModel: AddressAddEditViewModel

    constructor(props: Props) {
        super(props)

        this.employeeAddEditModel = props.employeeAddEditModel
        this.addressAddEditModel = props.addressAddEditModel
        this.state = {
            data: [],
            isLoading: false,
            isDraft: false,
            numAttachments: undefined,
        }

        this.setIsLoaded(true)
    }

    async componentDidMount() {
        this.setTitle('Add Employee').setBodyClass()

        this.employeeAddEditModel.reset()
        this.addressAddEditModel.reset()
        this.dMloader(true)

        try {
            if (this.props.params.id !== undefined) {
                this.employeeAddEditModel.setId(this.props.params.id)
                await this.employeeAddEditModel.componentDidMount()

                this.addressAddEditModel.setClientCode(
                    this.employeeAddEditModel.employee.vendor
                )
                await this.addressAddEditModel.componentDidMount()
            } else {
                this.employeeAddEditModel.setId(-1)
                await this.employeeAddEditModel.componentDidMount()
                await this.addressAddEditModel.componentDidMount()
                this.resetLocalStorage()
            }

            this.retrieveDraft()
        } catch (error) {
            this.setIsFound(false).setError(error, true)
        }

        this.dMloader(false)
        window.localStorage.setItem('isDraft', 'false')
    }

    componentWillUnmount(): void {
        this.resetLocalStorage()
        this.employeeAddEditModel.reset()
        this.addressAddEditModel.reset()
    }

    retrieveDraft() {
        const isDraft = getLocalStorage('isDraft')
        const epmloyeeData = isDraft
            ? getLocalStorage('employeeData', true)
            : this.employeeAddEditModel.employeeData
        const addressData = isDraft
            ? getLocalStorage('addressData', true)
            : this.addressAddEditModel.addressData
        this.employeeAddEditModel.setEmployeeData('address', addressData)

        if (!isDraft) {
            window.localStorage.setItem(
                'addressData',
                JSON.stringify(addressData)
            )
        }

        this.setState({
            data: this.employeeAddEditModel.employeeData,
        })
    }

    setIsLoading(isLoading: boolean): void {
        this.setState({
            isLoading: isLoading,
        })
    }

    resetLocalStorage(): void {
        window.localStorage.removeItem('employeeData')
        window.localStorage.removeItem('addressData')
        window.localStorage.setItem('isDraft', 'false')
        window.localStorage.removeItem('isAdd')
    }

    switchTab = (e: any) => {
        this.retrieveDraft()
    }

    onDraft: any = (e: any) => {
        window.localStorage.setItem('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
        this.retrieveDraft()
    }

    handleClickSave = async (e: any) => {
        const employeeData: any = getLocalStorage('employeeData', true)
        const addressData: any = getLocalStorage('addressData', true)
        const validated = await validateInput(
            EmployeeValidation,
            employeeData.addressData
        )

        if (!validated.status) {
            const request = this.employeeAddEditModel.modifyEmployee(
                this.employeeAddEditModel.employee.id,
                employeeData
            )

            addressData.vendorisemployee = true

            this.setIsLoading(true)
            handleResponseAlert(request, (res: any) => {
                addressData.code = employeeData.vendor
                addressData.addressTypeEnum = 'SupplierVendor'
                addressData.addresstype = 2

                this.setIsLoading(true)
                this.addressAddEditModel.modifyAddress(
                    addressData.id,
                    addressData,
                    () => {
                        this.setIsLoading(false)
                        if (!res.code) {
                            this.redirectPage(e)
                        } else {
                            this.setIsLoading(false)
                            const client = document.getElementById(
                                'vendor'
                            ) as any
                            client.disabled = false
                            client.readOnly = false
                        }
                    }
                )
            })
        } else {
            displayAlert('danger', validated.message)
        }
    }

    redirectPage(e: any) {
        this.resetLocalStorage()
        setTimeout(() => {
            if (e.target.name === 'saveNew') {
                window.location.href = pathParam(URI.contact.employeeAdd, {
                    tab: 'info',
                })
            } else {
                this.props.navigate(URI.contact.employee)
            }
        }, 500)
    }

    getActiveTabName() {
        return this.props.params && this.props.params.tab
            ? this.props.params.tab
            : 'info'
    }

    displayContent(): JSX.Element {
        const tab = this.getActiveTabName()

        const { data } = this.state

        if (this.employeeAddEditModel.isLoaded) {
            if (tab === 'info')
                return (
                    <EmployeeAddEditInfo
                        onDraft={this.onDraft}
                        data={data}
                        navigate={this.props.navigate}
                    />
                )

            if (tab === 'notes')
                return (
                    <EmployeeAddEditNotes onDraft={this.onDraft} data={data} />
                )

            if (tab === 'time')
                return (
                    <SecureContent
                        attributeNo={40}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
                    >
                        <SecureContent.HasAccess>
                            <EmployeeAddEditTime
                                onDraft={this.onDraft}
                                data={data}
                            />
                        </SecureContent.HasAccess>
                        <SecureContent.NoAccess>
                            <SecureContent.GenericNoAccess />
                        </SecureContent.NoAccess>
                    </SecureContent>
                )

            if (tab === 'showroom')
                return <EmployeeAddEditPOS onDraft={this.onDraft} data={data} />

            if (tab === 'documents')
                return (
                    <Documents
                        objectType="Vendor"
                        objectId={this.props.params.id}
                        onRefresh={(numAttachments: number) => {
                            this.setState({
                                numAttachments: numAttachments,
                            })
                        }}
                    />
                )
        }

        return <></>
    }

    render(): JSX.Element {
        const page = this.getActiveTabName()

        return (
            <>
                <EmployeeAddEditHeader
                    activeMenu={page}
                    employeeName={this.employeeAddEditModel.employee?.vendorn}
                    employeeId={this.employeeAddEditModel.employee?.id}
                    onSave={this.handleClickSave}
                    isLoading={this.state.isLoading}
                    onSwitchTab={this.switchTab}
                    isDraft={this.state.isDraft}
                    isSaveHidden={this.getActiveTabName() === 'documents'}
                    numAttachments={
                        this.state.numAttachments ??
                        this.employeeAddEditModel.employee?.numAttachments ??
                        0
                    }
                />

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.displayContent()}</Container>
                </div>
            </>
        )
    }
}

export default WithRouter(observer(EmployeeAddEditComponent))
