import React, { CSSProperties } from 'react'
import Icon from 'app/components/Icon/Icon'
import ArrowIcon from '../../../../assets/images/icons/Link-Arrow-Icon.svg'
import LinkItem from 'app/components/LinkItem/LinkItem'

interface IGridLinkItem {
    text: string
    subtext?: string
    hasIcon?: boolean
    linkIconClass?: string
    url?: string
    linkTextClass?: string
    linkIconStyle?: CSSProperties
}

const GridLinkItem = ({
    text,
    subtext = '',
    hasIcon = false,
    linkIconClass = '',
    url,
    linkTextClass,
    linkIconStyle,
}: IGridLinkItem) => {
    return (
        <LinkItem
            linkItemClass="tw-flex tw-items-center tw-pb-4 tw-pt-4 tw-border-b tw-border-gray-300 last:tw-border-b-0 hover:tw-cursor-pointer tw-no-underline tw-text-current "
            url={url}
            openInNewTab={true}
        >
            {hasIcon && (
                <div
                    className={`tw-w-10 tw-h-10 tw-bg-gray-100 tw-rounded-full tw-flex tw-items-center tw-justify-center`}
                >
                    <div className={linkIconClass} style={linkIconStyle} />
                </div>
            )}
            <div className="tw-ml-3 tw-flex-grow">
                <span className={linkTextClass}>{text}</span>
                {subtext && (
                    <p className="tw-text-gray-300 tw-mb-0">{subtext}</p>
                )}
            </div>
            <Icon path={ArrowIcon} iconClass="tw-mr-5  tw-max-w-none" />
        </LinkItem>
    )
}

GridLinkItem.displayName = 'GridLinkItem'

export default GridLinkItem
