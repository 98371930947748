import React from 'react'
import {
    addBodyClass,
    initClickBreadcrumb,
    removeBodyClass,
    setDocumentTitle,
    toggleDMPageLoader,
} from '../utilities/DOM'
import { delay, includes } from 'lodash'
import {
    getErrorMessage,
    getStatusCode,
    hideAlertLoader,
} from '../utilities/Response'
import Spinner from './components/help/Spinner'
import PageComponentError from './pages/Error/PageComponentError'
import PageModuleError from './pages/Error/PageModuleError'
import NotFoundPage from './pages/Home/NotFoundPage'
import tableEvent from '../utilities/Table'
import { setLocalStorage } from '../utilities/LocalStorage'

interface ErrorProps {
    message?: string
    code?: number
    isPage?: boolean
}
export default class ThreadComponent<P, S> extends React.Component<P, S> {
    private title = ''
    private description = ''
    private apiURL = ''
    private apiParams: object = {}

    private _hasActionBar = false
    private _hasFormAction = false
    private spinnerIsChild: boolean
    private isFound: boolean
    private isLoaded: boolean
    private errorObj: ErrorProps
    private dmLoaderId: string

    constructor(props: any) {
        super(props)

        this.spinnerIsChild = false
        this.isFound = true
        this.isLoaded = false
        this.errorObj = {
            message: 'Page not found.',
            code: 404,
            isPage: false,
        }
        this.dmLoaderId = ''

        this._init()
        this.componentInit()
        this.initEvents()
    }

    _init() {
        // Check if the page is time entry
        // If not, remove the default filters.
        if (window.location.href.indexOf('/time-entry') < 0) {
            setLocalStorage('resetTimeEntryFilter', 'yes')
        }
    }

    hasActionBar(status: boolean): ThreadComponent<P, S> {
        this._hasActionBar = status
        return this
    }

    hasFormAction(status: boolean): ThreadComponent<P, S> {
        this._hasFormAction = status
        return this
    }

    componentInit(): ThreadComponent<P, S> {
        // Called every time the `ThreadComponent` is extended.
        return this
    }

    dMloader(show: object | boolean = true, force = false, isSidebar = true) {
        if (show) {
            const loader = toggleDMPageLoader(
                typeof show === 'boolean' ? true : show,
                isSidebar
            )
            if (loader) {
                this.dmLoaderId = loader
            }
        } else {
            if (force === true) {
                toggleDMPageLoader({ destroy: true })
            } else if (this.dmLoaderId) {
                toggleDMPageLoader(this.dmLoaderId, isSidebar)
            }
        }
    }

    initEvents() {
        // Bind click event on breadcrumb links.
        // If draft is enabled, this will trigger browser confirm()
        // before leaving the current screen
        delay(() => {
            initClickBreadcrumb()
            tableEvent()
        }, 200)
    }

    setBodyClass(): ThreadComponent<P, S> {
        // remove scrolled class.
        removeBodyClass('app-scrolled-bottom')

        // Check if has form.
        if (this._hasFormAction) {
            addBodyClass('app-contains-form')
        } else {
            removeBodyClass('app-contains-form')
        }

        // Check if has action bar.
        if (this._hasActionBar) {
            addBodyClass('app-contains-action-bar')
        } else {
            removeBodyClass('app-contains-action-bar')
        }

        return this
    }

    setTitle(title: string): ThreadComponent<P, S> {
        setDocumentTitle(title)
        return this
    }

    setIsChildSpinner(state: boolean): ThreadComponent<P, S> {
        this.spinnerIsChild = state

        return this
    }

    setIsLoaded(state: boolean): ThreadComponent<P, S> {
        this.isLoaded = state

        return this
    }

    setIsFound(state: boolean): ThreadComponent<P, S> {
        this.isFound = state

        return this
    }

    setError(error: any, isPage = false): ThreadComponent<P, S> {
        this.errorObj = {
            message: getErrorMessage(error),
            code: getStatusCode(error),
            isPage,
        }

        // Display an error.
        if (!includes([200, 404], this.errorObj.code)) {
            console.error('Oops! Error found:\n', error)
        }

        return this
    }

    renderView(component: any): JSX.Element {
        this.initEvents()

        // Check if the page is still loading.
        if (!this.isLoaded) {
            return <Spinner isChild={this.spinnerIsChild} />
        }

        const ErrorComponent = this.errorObj.isPage
            ? PageComponentError
            : PageModuleError

        // If requests loaded,
        // Check if the page is found else, display the 404 message
        component = this.isFound ? (
            component
        ) : 404 === this.errorObj.code ? (
            <NotFoundPage />
        ) : (
            <ErrorComponent
                code={this.errorObj.code}
                message={this.errorObj.message}
            />
        )

        if (!this.isFound) {
            hideAlertLoader()
        }

        return component
    }
}
