import { Breadcrumb, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import { HeaderLight } from 'templates/components/Header'

function Crumb({ to, text }) {
    if (to) {
        return (
            <Breadcrumb.Item
                linkProps={{
                    to,
                }}
                linkAs={Link}
            >
                {text}
            </Breadcrumb.Item>
        )
    } else {
        return <Breadcrumb.Item>{text}</Breadcrumb.Item>
    }
}

export default function Breadcumbs({ trail }) {
    const navigate = useNavigate()

    return (
        <HeaderLight.Breadcumbs>
            <Button
                onClick={() => navigate(-1)}
                className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4 fs-md"
                variant="link"
            >
                <i className="ri-arrow-left-s-line"></i> Back
            </Button>
            <Breadcrumb>
                {trail.map(({ text, to }) => (
                    <Crumb key={text} to={to} text={text} />
                ))}
            </Breadcrumb>
        </HeaderLight.Breadcumbs>
    )
}
