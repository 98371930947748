import React from 'react'
import { Col, Row, Form, Breadcrumb, Container, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import { HeaderLight } from '../../../components/Header'
import ThreadComponent from '../../ThreadComponent'

class ItemTransferEdit extends ThreadComponent {
    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={routeParam(URI.project.vendorOrder.modifyStatus, {
                            id: 1,
                        })}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    {(() => {
                        if (this.props.params.compId) {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.i,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.data.proj}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: routeParam(
                                                URI.project.item.transferItem,
                                                { id: 1 }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Transfer Item
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Transfer Item Name
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        } else {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.data.proj}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: routeParam(
                                                URI.project.item.transferItem,
                                                { id: 1 }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Transfer Item
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Transfer Item Name
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        }
                    })()}
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>Transfer Item Name</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    render() {
        return (
            <>
                {/* Header */}
                {this.renderHeader()}

                {/* Content */}
                <div className="content-padding min-height">
                    <Container fluid>
                        <Row xs={1} lg={2} className="mb-4">
                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Description
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Type
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Paid Qty.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Act. Cost
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Transfer Qty
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Transfer Cost
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ItemTransferEdit)
