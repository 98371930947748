import React, { FC, useEffect, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Field, TForm as Model } from '../components/Components'
import { WarehouseTable } from './WarehouseTable'
import { TransactionsTable } from './TransactionsTable'
import { InventoryAdjustmentModal } from './InventoryAdjustmentModal'
import {
    displayAlert,
    displayAlertLoader,
    getErrorMessage,
    hideAlertLoader,
} from 'utilities/Response'
import {
    createInventoryAdjustment,
    deleteInventoryAdjustments,
    fetchProjects,
    fetchStockItems,
    fetchTransactions,
    fetchWarehouse,
    fetchWarehouseAddress,
    updateInventoryAdjustment,
} from '../../StockItemsService'
import MSG from 'defaults/Message'
import { currencyFormat } from 'helpers/Number'
import { getCookie } from 'utilities/Auth'
import ConfirmationModal from 'app/components/modal/ConfirmationModal'
import { TTypeOption } from 'app/components/dropdowns/types/TTypeOption'
import { TWarehouseQty } from 'lib/api/types/WarehouseQty'
import { TInventoryTransaction } from 'lib/api/types/InventoryTransaction'

export const StockItemsEditStatusAdjustment: FC<{
    _data: Map<string, any>
    _warehouse: TWarehouseQty[]
    _transactions: TInventoryTransaction[]
    companyWarehouse?: string
}> = ({ _data, _warehouse, _transactions, companyWarehouse }) => {
    const [selectedTransaction, setSelectedTransaction] = useState<
        Map<string, any> | undefined
    >()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [isPresentingDeleteModal, setPresentingDeleteModal] = useState(false)
    const [projects, setProjects] = useState<TTypeOption[]>([])
    const [addresses, setAddresses] = useState<TTypeOption[]>([])
    const [data, setData] = useState<Map<string, any>>(_data)
    const [warehouse, setWarehouse] = useState<TWarehouseQty[]>(_warehouse)
    const [transactions, setTransactions] =
        useState<TInventoryTransaction[]>(_transactions)
    const model: () => Model = () => {
        const unitAvgCost = (): number => {
            if (data.get('totalcost') == 0 || data.get('qtypaidfor') == 0) {
                return 0
            }

            return (
                Number(data.get('totalcost')) / Number(data.get('qtypaidfor'))
            )
        }
        return {
            sections: [
                {
                    key: 's0',
                    columns: [
                        {
                            key: 'c0',
                            fields: [
                                {
                                    type: 'text',
                                    title: 'On Hand',
                                    ids: [],
                                    value: data.get('onhand'),
                                    readOnly: true,
                                },
                                {
                                    type: 'text',
                                    title: 'Paid For',
                                    ids: [],
                                    value: data.get('qtypaidfor'),
                                    readOnly: true,
                                },
                                {
                                    type: 'text',
                                    title: 'Committed',
                                    ids: [],
                                    value: data.get('committed'),
                                    readOnly: true,
                                },
                                {
                                    type: 'text',
                                    title: 'On Order',
                                    ids: [],
                                    value: data.get('onorder'),
                                    readOnly: true,
                                },
                                {
                                    type: 'text',
                                    title: 'Unit Avg. Cost',
                                    ids: [],
                                    value: unitAvgCost(),
                                    readOnly: true,
                                    formatter: (value: any) => {
                                        return currencyFormat(value)
                                    },
                                },
                                {
                                    type: 'text',
                                    title: 'Total Cost',
                                    ids: [],
                                    value: data.get('totalcost'),
                                    formatter: (value: any) => {
                                        return currencyFormat(value)
                                    },
                                    readOnly: true,
                                },
                            ],
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                    ],
                },
            ],
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setProjects(await fetchProjects())
            setAddresses(await fetchWarehouseAddress())
        }
        fetchData()
    }, [])

    const handleTransaction = (transaction: any) => {
        setSelectedTransaction(new Map(Object.entries(transaction)))
    }
    const handleSaveInventoryAdjustment = async (form: Map<string, any>) => {
        if (!selectedTransaction) {
            return
        }

        let params: any = {
            proj: form.get('proj'),
            item: form.get('item'),
            comp: form.get('comp'),
            commitadj: form.get('commitadj') ?? 0,
            onorderadj: form.get('onorderadj') ?? 0,
            onhandadj: form.get('onhandadj') ?? 0,
            costadj: form.get('costadj') ?? 0,
            userdate: form.get('userdate') ?? new Date().toISOString(),
            txdesc: form.get('txdesc'),
            qtypaidforadj: form.get('qtypaidforadj') ?? 0,
            wh: form.get('wh') ?? companyWarehouse,
        }

        try {
            if (selectedTransaction.size == 0) {
                params = {
                    ...params,
                    stockno: data.get('stockno'),
                    usercode: form.get('usercode') ?? getCookie('dmUsercode'),
                    isTransferWarehouse: form.get('isTransferWarehouse'),
                    transferWhCode: form.get('transferWhCode'),
                }

                await createInventoryAdjustment(params)

                displayAlert(
                    'success',
                    'Inventory Adjustment has been successfully updated'
                )
            } else {
                await updateInventoryAdjustment(form.get('txnum'), params)

                displayAlert(
                    'success',
                    'Inventory Adjustment has been successfully created'
                )
            }
            setSelectedTransaction(undefined)
            update()
        } catch (error: any) {
            handleError(error)
        }
    }

    const handleError = (error: any) => {
        const message = error.backedError?.response
            ? error.backedError.response.data.userError
            : getErrorMessage(error)
        displayAlert('danger', message)
    }

    const handleCheckAll = () => {
        if (!selectedIds.length) {
            const checked = transactions.map((item) => {
                return item.txnum
            })
            setSelectedIds(checked)
        } else {
            setSelectedIds([])
        }
    }

    const handleCheck = (id: number) => {
        const _checks = [...selectedIds]
        const index = _checks.findIndex((check) => check == id)

        index > -1 ? _checks.splice(index, 1) : _checks.push(id)

        setSelectedIds(_checks)
    }

    const handleDelete = async (ids: number[]) => {
        try {
            setPresentingDeleteModal(false)
            displayAlertLoader(MSG.loading.delete.InventoryAdjustment)

            const failedItems = await deleteInventoryAdjustments(ids)
            if (failedItems.length) {
                displayAlert(
                    'danger',
                    'Some of the selected items could not be deleted'
                )
            } else {
                hideAlertLoader()
            }

            displayAlert(
                'success',
                'Successfully deleted selected inventory adjustment(s)'
            )
            setSelectedIds(failedItems)
        } catch (error: any) {
            displayAlert('danger', error.response.data.userError)
        } finally {
            update()
        }
    }

    const update = async () => {
        const [stockItem] = await fetchStockItems(
            `?$filter=id eq ${data.get('id')}`
        )
        const warehouse: any = await fetchWarehouse(stockItem.stockno)
        const transactions: any = await fetchTransactions(stockItem.stockno)

        setData(new Map(Object.entries(stockItem)))
        setWarehouse(warehouse)
        setTransactions(transactions)
    }

    return (
        <div>
            <Container className="ms-0">
                <Row>
                    <Col sm={12} lg={6}>
                        {model().sections.map((section) => {
                            return (
                                <Row key={section.key} className="mb-5">
                                    {section.columns.map((column) => {
                                        return (
                                            <Col
                                                key={column.key}
                                                sm={column.sm}
                                                md={column.md}
                                                lg={column.lg}
                                            >
                                                {column.fields.map((field) => {
                                                    return (
                                                        <Row
                                                            key={field.ids[0]}
                                                            className="mb-2"
                                                        >
                                                            <Field
                                                                model={field}
                                                            />
                                                        </Row>
                                                    )
                                                })}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            )
                        })}
                    </Col>
                    <Col>
                        <WarehouseTable data={warehouse} />
                    </Col>
                </Row>
                <Row>
                    <TransactionsTable
                        data={transactions}
                        selectedIds={selectedIds}
                        onTransaction={handleTransaction}
                        onAdd={() => {
                            setSelectedTransaction(new Map())
                        }}
                        onDelete={() => setPresentingDeleteModal(true)}
                        onCheck={handleCheck}
                        onCheckAll={handleCheckAll}
                    />
                </Row>
            </Container>
            {selectedTransaction && (
                <InventoryAdjustmentModal
                    data={selectedTransaction}
                    projects={projects}
                    addresses={addresses}
                    warehouse={companyWarehouse}
                    onCancel={() => {
                        setSelectedTransaction(undefined)
                    }}
                    onSave={handleSaveInventoryAdjustment}
                />
            )}
            {isPresentingDeleteModal && (
                <ConfirmationModal
                    show={true}
                    message="Are you sure you want to delete selected
                                entries? Once you delete, it's gone for good."
                    labelCancel="Cancel"
                    confirmAction={() => {
                        handleDelete(selectedIds)
                    }}
                    toggleModal={() => {
                        setPresentingDeleteModal(false)
                    }}
                />
            )}
        </div>
    )
}
StockItemsEditStatusAdjustment.displayName = 'StockItemsEditStatusAdjustment'
