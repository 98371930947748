import React from 'react'
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import { ApiService } from '../../../lib/api/HttpService'
import { displayAlert } from '../../../utilities/Response'
import { currencyFormat } from '../../../helpers/Number'

class ProjectViewSummary extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            showRetainerModal: false,
            data: {},
            isDataLoaded: false,
        }

        this.api = new ApiService()
        this.setIsChildSpinner(true)
    }

    async componentDidMount() {
        const id = this.props.params.id
        if (id) {
            try {
                const projectStatus = await this.api.getProjectStatus(id)

                this.setState({ data: projectStatus })
            } catch (error) {
                displayAlert('danger', 'Something went wrong!')
            }
        }

        this.setIsLoaded(true)
    }

    render() {
        return this.renderView(
            <>
                <div className="min-height">
                    <Container className="ms-0 px-0 min-height">
                        <Row>
                            <Col lg={6} xl={4}>
                                <Row className="h-100">
                                    <Col className="p-3">
                                        <div className="form-fields border h-100 roundedx-4 p-4">
                                            {/* sub heading */}
                                            <Form.Group
                                                as={Row}
                                                className="mb-4"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    className="px-sm-0 py-0 fw-bold fs-md"
                                                >
                                                    Client Deposits/Retainers
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Deposits Requested
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .depositRequested
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Retainers Received
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .retainersReceived
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Deposits Received
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .depositReceived
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Total Received
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .totalReceived
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            {/*  */}

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Dep./Ret. Applied (Or
                                                    credit/refund)
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .depositRetainerApplied
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Available
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .available
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                    <hr className="mb-1 mt-2" />
                                                    <hr className="mb-1 mt-1" />
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} xl={4}>
                                <Row className="h-100">
                                    <Col className="p-3">
                                        <div className="form-fields border h-100 roundedx-4 p-4">
                                            {/* sub heading */}
                                            <Form.Group
                                                as={Row}
                                                className="mb-4"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    className="px-sm-0 py-0 fw-bold fs-md"
                                                >
                                                    Client Invoices
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Total invoiced
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .totalInvoiced
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-4 pb-2"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Sales tax
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .salesTax
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Subtotal
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .subtotal
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-4 pb-2"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Dep./Ret. Applied (Or
                                                    credit/refund)
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .depositRetainerApplied
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>
                                            {/*  */}

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Subtotal
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .subtotal
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Payments (Or credit/refund)
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .paymentsOrCreditRefund
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Adjustments
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .adjustments
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Balance Due
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .balanceDue
                                                            )}
                                                            readOnly
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                    <hr className="mb-1 mt-1" />
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6} xl={4}>
                                <Row className="h-100">
                                    <Col className="p-3">
                                        <div className="form-fields border h-100 roundedx-4 p-4">
                                            {/* sub heading */}
                                            <Form.Group
                                                as={Row}
                                                className="mb-4"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    className="px-sm-0 py-0 fw-bold fs-md"
                                                >
                                                    Purchasing
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Open POs
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .openPOs
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-4 pb-2"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Open Vendor Deposits
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .openVendorDeposits
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                </Col>
                                            </Form.Group>
                                            {/*  */}

                                            <Form.Group
                                                as={Row}
                                                className="mb-4 pb-2"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Future due
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .purchasingFutureDue
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-2" />
                                                    <hr className="mb-1 mt-1" />
                                                </Col>
                                            </Form.Group>
                                            {/*  */}

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Gross vendor invoices
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .grossVendorInvoices
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-4 pb-2"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Deposit applied
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .purchasingDepositApplied
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-1" />
                                                </Col>
                                            </Form.Group>
                                            {/*  */}

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-bold d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Net invoices
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .purchasingNetInvoices
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>

                                                    <hr className="mb-1 mt-1" />
                                                    <hr className="mb-1 mt-1" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                            >
                                                <Form.Label
                                                    column
                                                    sm="4"
                                                    className="fw-500 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                                >
                                                    Total Payable
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="form-group-extra reversed">
                                                        <Form.Control
                                                            type="text"
                                                            className="align-right"
                                                            placeholder="0.00"
                                                            value={currencyFormat(
                                                                this.state.data
                                                                    .purchasingTotalPayable
                                                            )}
                                                        />
                                                        <span>$</span>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(ProjectViewSummary)
