import React from 'react'
import { Breadcrumb, Dropdown, Container, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { getActiveMenuUri, WithRouter } from '../../../../helpers/Router'
import { fakeProjectData } from '../../../factory/fakeFactory'
import URI from '../../../../defaults/RoutesDefault'
import Header from '../../../components/Header'
import ListFilter from '../../../components/ListFilter'
import ICON from '../../../../defaults/Icon'
import { compareStr } from '../../../../helpers/String'
import JournalEntryListNew from './JournalEntryListNew'
import JournalEntryListExisting from './JournalEntryListExisting'
import { getSubComponent } from '../../../../helpers/Util'
import { renderSecureContent } from '../../../../utilities/modules/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'

class JournalEntryList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            showRetainerModal: false,
        }
    }

    componentInit() {
        this.setTitle('Journal Entries').setFormAction(true)
    }

    renderHeader() {
        return (
            <Header buttons={1}>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{
                                        to: URI.generalLedger.journalEntry
                                            .listNew,
                                    }}
                                    linkAs={Link}
                                >
                                    General Ledger
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Journal Entries
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>Journal Entries</Header.Title>
                    </Header.LeftContent>
                    <Header.Actions list={true}>
                        <li
                            className={
                                compareStr(this.props.page, 'new', 'active', '')
                                    ? 'd-block'
                                    : 'd-none'
                            }
                        >
                            <SecureBootstrapButton
                                attributeNo={62}
                                attributeType={
                                    SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                }
                                as={Link}
                                to={URI.generalLedger.journalEntry.add}
                                variant="light"
                                size="lg"
                                className="btn-icon text-secondary-green"
                            >
                                <i className="ri-add-line ri-lg"></i> Add
                            </SecureBootstrapButton>
                        </li>
                    </Header.Actions>
                </Header.TitleContent>
                <Header.Buttons>
                    <li>
                        <NavLink
                            to={URI.generalLedger.journalEntry.listNew}
                            data-menu="new"
                            className={`btn text-white ${compareStr(
                                this.props.page,
                                'new',
                                'active',
                                ''
                            )}`}
                        >
                            New
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={URI.generalLedger.journalEntry.listExisting}
                            data-menu="existing"
                            className={`btn text-white ${compareStr(
                                this.props.page,
                                'existing',
                                'active',
                                ''
                            )}`}
                        >
                            Existing
                        </NavLink>
                    </li>
                </Header.Buttons>
            </Header>
        )
    }

    renderFilters() {
        return (
            <>
                <ListFilter expandable={0}>
                    <ListFilter.Actions md={12} lg={12} xl={12}>
                        <ListFilter.Action className="ms-auto">
                            <Dropdown
                                className="d-flex justify-content-end ms-auto"
                                align="end"
                            >
                                <Dropdown.Toggle
                                    id="dropdown-autoclose-true"
                                    variant="ivory"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="">
                                        <i className={ICON.a.listu}></i> Create
                                        Recurring Entry
                                    </Dropdown.Item>
                                    <Dropdown.Item href="">
                                        <i className={ICON.a.report}></i> Create
                                        Journal Report
                                    </Dropdown.Item>
                                    <Dropdown.Item href="">
                                        <i className={ICON.a.post}></i> Post
                                    </Dropdown.Item>
                                    <Dropdown.Item href="">
                                        <i className={ICON.a.delete}></i> Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer></ListFilter.Spacer>
            </>
        )
    }

    renderContent() {
        return getSubComponent(this.props.page, {
            new: renderSecureContent(<JournalEntryListNew />)(
                62,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
            existing: renderSecureContent(<JournalEntryListExisting />)(
                64,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
        })
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(JournalEntryList)
