import MSG from '../defaults/Message'
import { _delay } from '../helpers/Util'

export interface AlertProps {
    /**
     * @property {string} Class name `primary`|`info`|`success`|`danger`|`warning`|`white`|`light` | `default: primary`
     */
    variant?:
        | 'primary'
        | 'info'
        | 'success'
        | 'danger'
        | 'warning'
        | 'white'
        | 'light'
        | AlertProps
    /**
     * @property {string} Text to be displayed in the element. `default: Undefined`
     */
    message?: string | AlertProps
    /**
     * @property {string} Identifier for the alert element. This is important on multiple alerts. `default: ''`
     */
    className?: string
    /**
     * @property {number} Timer when the alert will be destroyed. `default: 5000`
     */
    timeout?: number
    /**
     * @property {boolean | number} Button that destoys the alert. `default: false`
     */
    closeBtn?: boolean | number
    /**
     * @property {boolean | number} Multiple alert. `default: false`
     */
    multiple?: boolean | number
    /**
     * @property {AlertIcon} Icon to be displayed. Set to null to remove. {AlertIcon}  `default: variant`
     */
    icon?: 'loader' | 'success' | 'danger' | 'warning' | 'info' | null
    /**
     * @property {boolean | number} Backdrop. `default: null`
     */
    backdrop?: boolean | number
}

/**
 * Handle multiple timeout event.
 * When a new alert is show, the settimeout
 * will not affect the newly created alert.
 */
let alertTimer: any = []

interface AlertIcon {
    loader: string
    success: string
    danger: string
    warning: string
    info: string
}

const alertIcons: AlertIcon = {
    loader: '<span class="spinner-border spinner-border-sm alert-icon" role="status" aria-hidden="true"></span> ',
    success: '<i class="ri-checkbox-circle-line alert-icon"></i> ',
    danger: '<i class="ri-error-warning-line alert-icon"></i> ',
    warning: '<i class="ri-error-warning-line alert-icon"></i> ',
    info: '<i class="ri-information-line alert-icon"></i> ',
}

/**
 * Create the alert DIV.
 *
 * @param {string} variant The class name
 * @param {string} message Text to display
 * @param {string|null} className Set class name identifier
 * @param {mixed|null} props Other properties, see `displayAlert()`
 * @returns {HTMLDivElement}
 */
export function createAlertElement(
    variant: string,
    message: string,
    className?: string,
    props: AlertProps = {}
): HTMLDivElement {
    const div: any = document.createElement('div')
    div.classList = `fade alert alert-response-all alert-${variant} ${
        className || ''
    } ${className ? 'has-identifier' : ''} ${props.icon ? 'has-icon' : ''}`
    div.id = 'response-alert'
    div.innerHTML =
        (props.icon ? alertIcons[props.icon] : '') +
        message +
        (false === props.closeBtn
            ? ''
            : `<a class="close" title="close">&times;</a>`)

    // Remove alert when close btn is clicked.
    if (false !== props.closeBtn) {
        div.querySelector('.close').addEventListener('click', () => {
            // Remove existing alerts.
            removeResponseAlert(className, true)

            // Clear old timer for destoying alert.
            clearAlertTimeout(className)
        })
    }

    return div
}

/**
 * Display alert v2.
 * Indicate a className or set `multiple: true` for multiple alert.
 * Example usage:
 * `showAlert('success', 'Thanks!')` | 
 * `showAlert('success', 'Thanks!', 5000)` | 
 * `showAlert('success', { message: 'Thanks!'})` |
 * `showAlert({ variant: 'success', message: 'Thanks!' })`
 *
 * @param {string|object} variant `AlertProps` or The class name `primary`|`info`|`success`|`danger`|`warning`|`white`|`light`
|* @param {string|object} message `AlertProps` or Text to display in the alert element
 * @param {object|number} props Timeout (ms) or AlertProps: { `variant`: string, `message`: string, `timeout`: number|null, `className`: string|null, `closeBtn`: boolean, `multiple`: boolean, `loader`: string }
 * @returns {void}
 */
export function displayAlert(
    variant: AlertProps['variant'] | AlertProps = 'primary',
    message?: string | AlertProps,
    props: AlertProps | number = {}
): void {
    if (typeof variant === 'object') {
        // ex. showAlert({ variant: 'success', message: 'Thanks!' })
        props = variant
    }

    if (typeof message === 'object') {
        // ex. showAlert('success', { message: 'Thanks!' })
        props = message
        props.variant = variant
    }

    if (typeof props === 'number') {
        // ex. showAlert('success', 'Thanks!', 5000)
        const timer = props

        props = {
            variant,
            message,
            timeout: timer,
        }
    }

    let _className = props.className || ''

    // Check if settings allow multiple alert to display in div
    // else override the existing alert with no className or identifier.
    if (true === props.multiple && !props.className) {
        _className = `alert-${Math.floor(Math.random() * 1000)}-${Math.floor(
            Math.random() * 1000
        )}`
    }

    const _variant = (props.variant || variant).toString()
    const _message = (props.message || message || 'Undefined').toString()

    // Set icon based on variant.
    if (null !== props.icon) {
        // @ts-ignore
        if (alertIcons[_variant]) {
            // @ts-ignore
            props.icon = _variant
        }
    }

    const alertDiv: any = document.querySelector('#response-alert-div')
    const alert: any = createAlertElement(_variant, _message, _className, props)

    // Remove existing alerts.
    removeResponseAlert(_className)

    // Clear old timer for destoying alert.
    clearAlertTimeout(_className)

    const timeout = props.timeout ?? 5000

    alertDiv.append(alert)
    _delay(() => (alert.classList += ' show'), 150)

    if (props.backdrop === true) {
        alertDiv.classList += ' has-backdrop'
    }

    // Add custom timeout.
    if (timeout > 0) {
        const timerKey = _className ? _className : 0
        alertTimer[timerKey] = _delay(() => {
            removeResponseAlert(_className, true)
        }, timeout)
    }
}

/**
 * Remove timeout event on alerts.
 *
 * @param {string|null} _class The class identifier is the same as timer identifier.
 * @return {void}
 */
function clearAlertTimeout(_class?: string): void {
    if (_class && _class.length) {
        clearTimeout(alertTimer[_class])
    } else {
        if (Object.keys(alertTimer).length) {
            Object.keys(alertTimer).forEach((i: any) => {
                // Clear only those with number as key.
                // else there is a class identifier that has its own clearing time.
                if (0 === i || '0' === i) {
                    clearTimeout(alertTimer[i])
                }
            })
        }
    }
}

/**
 * Remove all existing response instance.
 *
 * @param {string|null} _className Specify alert with className
 * @param {boolean|number|null} _isFade Specify if animation is fade or not.
 * @param {boolean|number|null} _isAll Specify if all alert will be affected.
 * @return {void}
 */
export function removeResponseAlert(
    _className?: string,
    _isFade?: boolean | number,
    _isAll?: boolean | number
): void {
    const alertDiv: any = document.getElementById('response-alert-div')
    // Get all alerts with no identifier or className.
    let alerts: any = alertDiv.querySelectorAll(
        '.alert-response-all:not(.has-identifier)'
    )

    // Check if search only for specific alert elements with className.
    if (_className && _className.length) {
        // override alerts with specific class identifier.
        alerts = alertDiv.querySelectorAll('.' + _className)
    }

    if (_isAll) {
        alerts = alertDiv.querySelectorAll('.alert-response-all')
    }

    // Reset timer variable if there is no existing alert.
    function resetTimer() {
        const alerts: any = alertDiv.querySelectorAll('.alert-response-all')

        if (!alerts.length) {
            alertTimer = []
        }
    }

    if (alerts && alerts.length) {
        // Remove backdrop
        alertDiv.classList.remove('has-backdrop')
        alerts.forEach((alert: any) => {
            if (_isFade) {
                alert.classList.remove('show')
                _delay(() => {
                    alert.remove()
                    _delay(resetTimer, 500)
                }, 150)
                return
            }

            alert.remove()
            _delay(resetTimer, 500)
        })
    }
}

/**
 * This extends the `removeResponseAlert()`
 * Remove display alert.
 *
 * @param {string|null} className Specify alert with className
 * @param {boolean|number|null} isFade Specify if animation is fade or not.
 * @return {void}
 */
export function removeDisplayAlert(
    className?: string,
    isFade?: boolean | number
): void {
    removeResponseAlert(className, isFade)
}

/**
 * This extends the `removeResponseAlert()`
 * Remove ALL display alert.
 *
 * @return {void}
 */
export function clearDisplayAlert(): void {
    removeResponseAlert('', false, 1)
}

/**
 * Get the response error message.
 *
 * @param {mixed} response Reponse object.
 * @param {string} _default Default message.
 * @returns {string}
 */
function errorMessage(response: any, _default?: string): string {
    let message: any = _default || 'Oops! Something went wrong.'

    if (response.response && response.response.status !== 200) {
        message =
            404 === response.response.status
                ? 'Page not found.'
                : response.response.data.UserError ??
                  response.response.data.userError ??
                  response.message
    }

    if (typeof response === 'string' && !_default) {
        message = response.toString()
    }

    return message
}

/**
 * Get the response error message.
 *
 * @param {mixed} response Reponse object.
 * @returns {number}
 */
function responseStatusCode(response: any): number {
    let stat = 500
    if (response.response && response.response.status) {
        stat = response.response.status
    }

    return stat
}

/**
 * Check if response has error.
 *
 * @param {mixed} response Reponse object.
 * @returns {boolean}
 */
function hasError(response: any): boolean {
    response = response || {}
    if (response.response && response.response.status !== 200) {
        return true
    }

    return false
}

/**
 * @var {any} timer
 */
let timer: any

/**
 * Handle response with custom alert.
 *
 * @param {object} request Request object.
 * @param {number} timeout Time out to remove the alert.
 * @param {method} callback Custom method after the request.
 * @param {string} field Field from the response object check if success.
 * @param {string} successMessage Custom success message on the alert.
 * @param {string} failedMessage Custom error message on the alert.
 * @return {void}
 */
export function handleResponseAlert<T>(
    request: Promise<T>,
    callback: any,
    timeout = 5000,
    field: any = 'id',
    successMessage = MSG.success.msg,
    failedMessage?: string,
    loadingMessage?: string
): void {
    field = field || 'id'
    timeout = timeout || 5000
    successMessage = successMessage || MSG.success.msg

    clearTimeout(timer)

    // Let's remove the existing alert instance.
    removeResponseAlert()
    // Display loader.
    displayAlertLoader(loadingMessage || MSG.loading.update.msg)

    request
        .then((res: any) => {
            if (res && res[field]) {
                displayAlert('success', successMessage, timeout)

                // Callback the method.
                if (callback) {
                    callback(res)
                }
            }
        })
        .catch((e) => {
            if (hasError(e)) {
                displayAlert(
                    'danger',
                    failedMessage ?? errorMessage(e),
                    timeout
                )

                // Callback the method.
                if (callback) {
                    callback(e)
                }
            }
        })
}

/**
 * Returns the reponse error message.
 *
 * @param {mixed} error Error object
 * @param {mixed} _default Default error message
 * @returns mixed
 */
export function getErrorMessage(error: any, _default?: string) {
    console.error(error)

    return errorMessage(error, _default)
}

/**
 * Returns the reponse error message.
 *
 * @param {mixed} response Response object
 * @returns mixed
 */
export function getStatusCode(response: any) {
    return responseStatusCode(response)
}

/**
 * Display alert loader with backdrop.
 *
 * @param {string} message The loading message.
 * @param {AlertProps} variant Alert style.
 * @param {boolean} backdrop Has backdrop
 * @return {void}
 */
export function displayAlertLoader(
    message?: string,
    variant: AlertProps['variant'] = 'light',
    backdrop = true,
    props: AlertProps = {}
): void {
    displayAlert(variant || 'light', {
        message: message || MSG.loading.info,
        icon: 'loader',
        timeout: -1,
        closeBtn: false,
        backdrop,
        ...props,
    })
}

/**
 * Remove/hide alert loader with backdrop.
 *
 * @return {void}
 */
export function hideAlertLoader(): void {
    removeResponseAlert()
}

/**
 * Display alert for errors.
 *
 * @param {Array<any>} errors Array error object
 * @return {void}
 */
export function displayErrors(errors?: Array<any>): void {
    if (errors && errors.length) {
        for (const error of errors) {
            displayAlert('danger', {
                message: getErrorMessage(error.error) + error.append,
                className: `alert-${Math.floor(
                    Math.random() * 1000
                )}-${Math.floor(Math.random() * 1000)}`,
                timeout: 7000,
            })
        }
    }
}

/**
 * Extend DisplayAlert for error alert.
 * Indicate a className or set `multiple: true` for multiple alert.
 * Example usage:
 * `displayAlertError('Thanks!')` |
 * `displayAlertError('Thanks!', 5000)` |
 * `displayAlertError({ message: 'Thanks!'})`
 *
 * @param {string|object} message `AlertProps` or Text to display in the alert element
 * @param {object|number} props Timeout (ms) or `AlertProps`
 * @returns {void}
 */
export function displayAlertError(
    message?: string | AlertProps,
    props: AlertProps | number = {}
): void {
    displayAlert('danger', message, props)
}

/**
 * Extend DisplayAlert for success alert.
 * Indicate a className or set `multiple: true` for multiple alert.
 * Example usage:
 * `displayAlertSuccess('Thanks!')` |
 * `displayAlertSuccess('Thanks!', 5000)` |
 * `displayAlertSuccess({ message: 'Thanks!'})`
 *
 * @param {string|object} message `AlertProps` or Text to display in the alert element
 * @param {object|number} props Timeout (ms) or `AlertProps`
 * @returns {void}
 */
export function displayAlertSuccess(
    message?: string | AlertProps,
    props: AlertProps | number = {}
): void {
    displayAlert('success', message, props)
}
