import React from 'react'
import { makeid } from '../../helpers/String'

/**
 * Display toggle switch.
 *
 * @param {string} off Off Text.
 * @param {string} on On Text.
 * @param {boolean} nolabel has Text.
 * @param {boolean} checked is On.
 * @param {boolean} disabled is Disabled input.
 * @returns
 */
class Switch extends React.Component {
    render() {
        const { nolabel, off, on, checked, disabled, onChange, name, id } =
            this.props
        const clssName = makeid(10)
        const offText = nolabel ? ' ' : off || 'Off'
        const onText = nolabel ? ' ' : on || 'On'

        return (
            <span className="a-switch">
                <style>
                    .chk-{clssName}::before{' '}
                    {"{ content: '" + offText + "' !important; }"}
                    .chk-{clssName}:checked::before{' '}
                    {"{ content: '" + onText + "' !important; }"}
                </style>
                <input
                    type="checkbox"
                    className={'chk-' + clssName}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    name={name}
                    id={id}
                />
            </span>
        )
    }
}

export default Switch
