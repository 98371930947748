import React, { useRef, useState } from 'react'
import { Overlay } from 'react-bootstrap'
import Popover from 'react-bootstrap/Popover'
import './RoleTooltip.css'

const roles = [
    {
        role: 'Administrators',
        desc: 'will have full access to ALL areas of the system.',
    },
    {
        role: 'Bookkeepers',
        desc: 'have the ability to do all A/R, A/P and General Ledger tasks. They can Add & Edit Projects and Items, but will be denied access to add, edit & delete Proposals and P’s. A bookkeeper can utilize ALL reports available in the system.',
    },
    {
        role: 'Designers',
        desc: 'have the ability to add new Projects and Budgets, as well as create Items & Proposals; but not P’s. Access to reprint a Client’s Invoice is granted, but no other A/R, A/P, General Ledger sections, nor corresponding reports can be reviewed. All Project Management Reports that do not show vendor cost or profit on them are accessible by a Designer.',
    },
    {
        role: 'Purchasers',
        desc: 'have similar access of a Designer, but are able to create P’s, not Proposals. They also have full access to the A/P section. All Project Management and A/P Reports are accessible by a Purchaser.',
    },
    {
        role: 'Design Assistants',
        desc: 'have access to view most Project sections, including reports that do not show vendor cost or profit. They can create new Items & Proposals, but not P’s. All access to A/R, A/P and the General Ledger and corresponding reports is denied.',
    },
]

const RoleTooltip = () => {
    const [show, setShow] = useState(false)
    const [target, setTarget] = useState<HTMLButtonElement | null>(null)
    const ref = useRef(null)

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setShow(!show)
        setTarget(e.target as HTMLButtonElement)
    }

    return (
        <div ref={ref} className="ml-1 position-relative d-flex">
            <button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(show
                    ? { 'aria-describedby': 'role-popover-contained' }
                    : {})}
                type="button"
                className="border-0 bg-white"
                onClick={handleClick}
            >
                <i className="ri-information-fill fs-5" />
            </button>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="role-popover-contained">
                    <Popover.Body>
                        <p className="mb-1">
                            User Security Roles : A User&#39;s Security Role
                            determines the permissions the user will have within
                            the Design Manager program.
                        </p>
                        <ul className="px-2">
                            {roles.map(({ role, desc }) => (
                                <li key={role} className="mb-1">
                                    <p className="fw-bold">
                                        {role}:
                                        <span className="fw-light">
                                            {`  ${desc}`}
                                        </span>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    )
}

export default RoleTooltip
