import React, { useCallback, useEffect, useState } from 'react'
import { Form, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../../lib/api/HttpService'
import { displayAlert } from '../../../../utilities/Response'
import { formatFiscalMonth } from '../../../../helpers/Date'
import { IBudgetCheck } from 'app/context/BudgetBannerContext'
import BudgetBanner, {
    BudgetBannerContext,
} from 'app/components/BudgetBanner/BudgetBanner'
import { useBudgetBanner } from 'app/components/BudgetBanner/hooks/useBudgetBanner'

const api = new ApiService()

interface IPostVendorInvoiceDialogProps {
    hasData: boolean
    invoiceNumbers: Array<number>
    onCancel: () => void
    onPostSucceeded: () => void
}

const PostVendorInvoiceDialog = ({
    hasData,
    invoiceNumbers,
    onCancel,
    onPostSucceeded,
}: IPostVendorInvoiceDialogProps) => {
    const [fiscalMonth, setFiscalMonth] = useState(new Date())
    const { show, level } = useBudgetBanner()
    const [isLoading, setIsLoading] = useState(false)
    const isBlockEntry = show && level === 'error'

    const loadData = async () => {
        const company = (await api.getCompany()) as {
            fiscaloffset: number
        }
        const company2 = (await api.getCompany2()) as {
            autodeffmo: boolean
            closedapfmo: string
        }

        let fiscalMonth = new Date()
        if (company2.autodeffmo) {
            fiscalMonth = new Date(
                fiscalMonth.setMonth(
                    fiscalMonth.getMonth() + company.fiscaloffset
                )
            )
        } else {
            fiscalMonth = new Date(company2.closedapfmo)
            fiscalMonth.setMonth(fiscalMonth.getMonth() + 1)
        }

        setFiscalMonth(fiscalMonth)
    }

    const checkVendorInvoicesBudget = useCallback(async () => {
        setIsLoading(true)
        for (const invoiceNumber of invoiceNumbers) {
            const { reasonMessage } = (await api.checkBudgetVendorInvoice(
                invoiceNumber.toString()
            )) as IBudgetCheck

            if (reasonMessage) {
                break
            }
        }
        setIsLoading(false)
    }, [invoiceNumbers])

    useEffect(() => {
        checkVendorInvoicesBudget()
        loadData()
    }, [checkVendorInvoicesBudget])

    const handleDateChange = (date: Date) => {
        setFiscalMonth(date)
    }

    const handleClose = () => {
        onCancel()
    }

    const handlePost = async () => {
        if (!fiscalMonth) {
            displayAlert('danger', 'Please select a fiscal month.')
            return
        }
        if (!hasData) {
            displayAlert('danger', 'No data available.')
            return
        }

        try {
            await api.postVendorInvoiceFiscalMonth({
                processType: 'allTransactions',
                fiscalMonth: formatFiscalMonth(String(fiscalMonth)),
            })
            onPostSucceeded()
        } catch (error: any) {
            displayAlert('danger', error.response.data.userError)
        }
    }

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>
                    Would you like to post these {invoiceNumbers.length} vendor
                    invoices?
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <Spinner variant="success" />
                    </div>
                ) : (
                    <>
                        <Row>
                            <BudgetBanner
                                context={BudgetBannerContext.Modal}
                                dataTestId="budget-banner-vendor-invoice"
                            />
                        </Row>
                        {isBlockEntry ? null : (
                            <Row className="align-items-center mt-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label
                                        htmlFor="inputPassword5"
                                        className="ilabel"
                                    >
                                        Fiscal Month
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={fiscalMonth}
                                        onChange={handleDateChange}
                                        className="form-control"
                                        placeholderText="Please select"
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handlePost}
                    disabled={isLoading || isBlockEntry}
                >
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PostVendorInvoiceDialog
