import useGetFile from 'lib/api/hooks/useGetFile'
import React from 'react'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import MyDropzone from 'templates/ui-kit/MyDropzone'
import { Item } from 'lib/api/types/Item'

const ImageUploader = ({
    itemToEdit,
}: {
    isEdit: boolean
    itemToEdit?: Item
}) => {
    const methods = useFormContext()
    const { setValue } = methods

    const { data: editItemImage, isFetching } = useGetFile(
        itemToEdit?.primaryImageId as string,
        {
            enabled: !!(itemToEdit && itemToEdit?.primaryImageId?.length > 0),
        }
    )

    useEffect(() => {
        if (editItemImage && !isFetching) {
            setValue('image', editItemImage)
        }
    }, [editItemImage, setValue, isFetching])

    return (
        <div className="tw-flex-1 lg:tw-flex-[0.5]">
            <Controller
                control={methods.control}
                name="image"
                render={({ field }) => (
                    <MyDropzone
                        doNotShowConfirmationModal
                        setImageOnEveryChange
                        image={field.value}
                        onRemoveFiles={field.onChange}
                        onAcceptedFiles={field.onChange}
                    />
                )}
            />
        </div>
    )
}

ImageUploader.displayName = 'ImageUploader'

export default ImageUploader
