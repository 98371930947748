import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { WithRouter } from '../helpers/Router'

/**
 * This is the default component.
 */
class Base extends React.Component {
    render() {
        return (
            <div className="content-padding">
                <Container fluid>
                    <Row>
                        <Col>
                            <h2 className="ff-type-bold mb-5">
                                {this.props.title || 'Hey There...'}
                            </h2>
                            <p className="fw-400">
                                This is the{' '}
                                <strong className="text-primary-red">
                                    default
                                </strong>{' '}
                                template.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default WithRouter(Base)
