import React from 'react'
import { NavLink } from 'react-router-dom'

import { useHasAccess } from '../../context/security'

export default function SecureNavLink({
    attributeNo,
    attributeType,
    ...props
}) {
    const hasAccess = useHasAccess(attributeNo, attributeType)

    if (!hasAccess) return null

    return <NavLink {...props} />
}
