import { Helmet } from 'react-helmet'
import { Form } from 'react-bootstrap'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useUsercode, useRunReport } from 'hooks'

import ConfirmModal from './ConfirmModal'
import ConfirmationMessage from './ConfirmationMessage'
import Grid from './Grid'
import Header from './Header'
import ReconcileSetup from './ReconcileSetup'
import ReconcileStatus from './ReconcileStatus'
import ReconcileHeader from './ReconcileHeader'
import ReconcileTabs from './ReconcileTabs'
import TransactionModal from './TransactionModal'

import {
    acceptReconciliation,
    getGridData,
    getTemporarycreditcardreconciliationTotals,
    updateTemporarycreditcardreconciliation,
} from './api'
import WarningMessage from './WarningMessage'
import SecureContent from 'app/components/security/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'

const fetchTotals = async (setup) => {
    const {
        endingBalance,
        statementDate,
        account: { value },
    } = setup
    if (typeof endingBalance === 'undefined' || !statementDate || !value) {
        return Promise.resolve(null)
    }

    return await getTemporarycreditcardreconciliationTotals({
        CreditCardAccount: value,
        DoClearFlags: true,
        DoSumFlags: true,
        StatementBalance: endingBalance,
        StatementEndingDate: statementDate,
    })
}

export default function ReconcileCreditCards() {
    const [isConfirmationShowing, setIsConfirmationShowing] = useState(false)
    const [isShowingDownloadModal, setIsShowingDownloadModal] = useState(false)
    const [isWarningShowing, setIsWarningShowing] = useState(false)
    const [reconcileSetup, setReconcileSetup] = useState(null)
    const [searching, setSearching] = useState(false)
    const [selected, setSelected] = useState({})
    const [showCleared, setShowCleared] = useState(false)
    const [tab, setTab] = useState('outstanding')
    const [transactionDetails, setTransactionDetails] = useState(null)

    const userCode = useUsercode()
    const runReport = useRunReport()

    const queryClient = useQueryClient()

    const mutation = useMutation(updateTemporarycreditcardreconciliation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['accounts-payable', 'credit-card'])
            setSelected({})
        },
    })

    const acceptMutation = useMutation(acceptReconciliation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['accounts-payable', 'credit-card'])
            reset()
        },
    })

    const totalsQuery = useQuery(
        ['accounts-payable', 'credit-card', 'totals'],
        () => fetchTotals(reconcileSetup),
        { enabled: !!reconcileSetup }
    )

    const gridQuery = useQuery(
        ['accounts-payable', 'credit-card', 'grid'],
        getGridData,
        { enabled: !!reconcileSetup }
    )

    const onConfirmationConfirm = () => {
        /*
         * After click on Yes, call
         * [POST]/api/v1/temporarycreditcardreconciliations/accept
         * Body:
         * {
         *   CreditCardAccount : string
         *   StatementEndingDate : DateTime
         *   StatementBalance : decimal
         * }
         */
        acceptMutation.mutate({
            creditCardAccount: reconcileSetup.account.value,
            statementEndingDate: reconcileSetup.statementDate,
            statementBalance: reconcileSetup.endingBalance,
            balance: totalsQuery.data.balance,
            computerBalance: totalsQuery.data.computerBalance,
        })

        setIsConfirmationShowing(false)
    }

    const onConfirmationCancel = () => {
        setIsConfirmationShowing(false)
    }

    const onDownload = () => {
        setIsShowingDownloadModal(true)
    }

    const onDownloadConfirm = () => {
        // "value": "AccountNumber,StatementEndingDate,StatementEndingBalance,Balance,ComputerBalance,ShowCleared,UserCode"
        const value = [
            reconcileSetup.account.value, // AccountNumber
            reconcileSetup.statementDate, // StatementEndingDate
            reconcileSetup.endingBalance, // StatementEndingBalance
            totalsQuery.data.balance, // Balance
            totalsQuery.data.computerBalance, // ComputerBalance
            showCleared, // ShowCleared
            userCode, // UserCode
        ].join(',')

        runReport({
            label: 'Reconcile Credit Card Preview',
            reportId: 1177,
            reportFormatId: 1353,
            runReportParameters: [
                {
                    parameterId: 735,
                    value,
                },
            ],
        })
    }

    const onFinanceChargesClick = () => {
        console.log('Disabled')
        // setIsShowingFincanceChrangesModal((prev) => !prev)
    }

    const onMarkCleared = () => {
        Object.keys(selected).forEach((recnum) => {
            if (!selected[recnum]) {
                return
            }

            mutation.mutate({ recnum, cleared: true })
        })
    }

    const onMarkUncleared = () => {
        Object.keys(selected).forEach((recnum) => {
            if (!selected[recnum]) {
                return
            }

            mutation.mutate({ recnum, cleared: false })
        })
    }

    const onReconcileCancel = () => {
        reset()
    }

    const onReconcileSave = () => {
        if (!totalsQuery.isLoading && totalsQuery.data.difference !== 0) {
            setIsWarningShowing(true)
        } else {
            setIsConfirmationShowing(true)
        }
    }

    const onReconcileStart = (data) => {
        setReconcileSetup(data)
        setTab('outstanding')
    }

    const onSearch = () => {
        setSearching((prev) => !prev)
    }

    const onSelect = (event) => {
        const field = event.target.value
        setSelected((prev) => ({ ...prev, [field]: !prev[field] }))
    }

    const onSelectAll = (event) => {
        const selected = gridQuery.data.reduce((prev, cur) => {
            prev[cur.recnum] = event.target.checked
            return prev
        }, {})
        setSelected(selected)
    }

    const onTransactionDetail = (transaction) => {
        setTransactionDetails(transaction)
    }

    const onWarningCancel = () => {
        setIsWarningShowing(false)
    }

    const onWarningConfirm = () => {
        setIsWarningShowing(false)
        setIsConfirmationShowing(true)
    }

    const reset = () => {
        setReconcileSetup(null)
        setTab('outstanding')
        setSearching(false)
        setSelected({})
    }

    const totalCleared = gridQuery.isLoading
        ? '?'
        : gridQuery.data.reduce((prev, cur) => {
              if (cur.cleared) return prev + 1
              return prev
          }, 0)

    const totalOutstanding = gridQuery.isLoading
        ? '?'
        : gridQuery.data.reduce((prev, cur) => {
              if (!cur.cleared) return prev + 1
              return prev
          }, 0)

    const totalSelected = Object.keys(selected).reduce((prev, cur) => {
        if (selected[cur]) return prev + 1
        return prev
    }, 0)

    const totalSelectedAmount = gridQuery.isLoading
        ? 0
        : Object.keys(selected)
              .filter((key) => selected[key] === true)
              .reduce((prev, cur) => {
                  const { data } = gridQuery
                  const currentItem = data.find(
                      ({ recnum }) => recnum === Number(cur)
                  )
                  return prev + currentItem.amount
              }, 0)

    const gridData = gridQuery.isLoading ? [] : gridQuery.data

    return (
        <>
            <Helmet>
                <title>Reconcile Credit Card | Design Manager</title>
            </Helmet>
            <Header />
            <SecureContent
                attributeNo={61}
                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
            >
                <SecureContent.NoAccess>
                    <div className="content-padding min-height has-action-bar mt-2">
                        <SecureContent.GenericNoAccess />
                    </div>
                </SecureContent.NoAccess>
                <SecureContent.HasAccess>
                    <ReconcileSetup
                        onStart={onReconcileStart}
                        onCancel={onReconcileCancel}
                        onDownload={onDownload}
                        onSave={onReconcileSave}
                        started={!!reconcileSetup}
                    />
                    {!reconcileSetup && (
                        <div className="container mt-4">
                            Select an{' '}
                            <span className="bg-ivory border-1 border-sand roundedx-4">
                                Account
                            </span>
                            ,{' '}
                            <span className="bg-ivory border-1 border-sand roundedx-4">
                                Statement date
                            </span>{' '}
                            and{' '}
                            <span className="bg-ivory border-1 border-sand roundedx-4">
                                Statement Ending balance
                            </span>{' '}
                            to start the reconciliation.
                        </div>
                    )}
                    {reconcileSetup && (
                        <>
                            <ReconcileStatus totalsQuery={totalsQuery} />
                            <ReconcileTabs activeTab={tab} setTab={setTab} />
                            <div className="content-padding min-height has-action-bar mt-2">
                                <ReconcileHeader
                                    cleared={tab === 'cleared'}
                                    endingBalance={reconcileSetup.endingBalance}
                                    onFinanceChargesClick={
                                        onFinanceChargesClick
                                    }
                                    onMarkCleared={onMarkCleared}
                                    onMarkUncleared={onMarkUncleared}
                                    onSearch={onSearch}
                                    searching={searching}
                                    totalClearedCount={totalCleared}
                                    totalOutstandingCount={totalOutstanding}
                                    totalsQuery={totalsQuery}
                                    totalSelected={totalSelected}
                                    totalSelectedAmount={totalSelectedAmount}
                                />
                                {!gridQuery.isLoading && (
                                    <Grid
                                        cleared={tab === 'cleared'}
                                        data={gridData}
                                        onSelect={onSelect}
                                        onSelectAll={onSelectAll}
                                        onTransactionDetail={
                                            onTransactionDetail
                                        }
                                        searching={searching}
                                        selected={selected}
                                    />
                                )}
                            </div>

                            <ConfirmModal
                                isShowing={isWarningShowing}
                                onConfirm={onWarningConfirm}
                                onCancel={onWarningCancel}
                            >
                                <WarningMessage />
                            </ConfirmModal>

                            <ConfirmModal
                                isShowing={isConfirmationShowing}
                                onConfirm={onConfirmationConfirm}
                                onCancel={onConfirmationCancel}
                            >
                                <ConfirmationMessage />
                            </ConfirmModal>

                            <ConfirmModal
                                isShowing={isShowingDownloadModal}
                                onConfirm={onDownloadConfirm}
                                onCancel={() =>
                                    setIsShowingDownloadModal(false)
                                }
                            >
                                <Form.Check
                                    type="checkbox"
                                    id="show-cleared"
                                    label="Show Cleared?"
                                    className="rounded"
                                    onChange={(e) =>
                                        setShowCleared(e.target.checked)
                                    }
                                />
                            </ConfirmModal>

                            <TransactionModal
                                accountn={reconcileSetup.account.label}
                                isShowing={!!transactionDetails}
                                onClose={() => setTransactionDetails(null)}
                                transaction={transactionDetails}
                            />
                        </>
                    )}
                </SecureContent.HasAccess>
            </SecureContent>
        </>
    )
}
