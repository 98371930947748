import React, { useEffect, useRef, useState } from 'react'
import {
    displayAlert,
    displayAlertLoader,
    getErrorMessage,
    hideAlertLoader,
} from '../../../utilities/Response'
import {
    deleteFiles,
    editFile,
    fetchDocuments,
    fetchFile,
    fetchThumbnails,
    uploadDocuments,
    uploadLink,
    getMaxFileSize,
} from './DocumentsService'
import { DeleteModal } from './DeleteModal'
import { AddLinkModal } from './AddLinkModal'
import { Content } from './Content'
import { Toolbar } from './Toolbar'
import { DocumentsViewer } from './DocumentsViewer'
import EditModal from './EditModal'
import MSG from '../../../defaults/Message'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { saveAs } from 'file-saver'

dayjs.extend(isBetween)

const COLUMNS = {
    DEFAULT: 4,
    MIN: 3,
    MAX: 6,
}
const ZOOM_LEVELS = { 0: COLUMNS.MAX, 1: COLUMNS.DEFAULT, 2: COLUMNS.MIN }

const MAX_FILE_SIZE_MB_DEFAULT = 30

function Documents({ objectType, objectId, onRefresh }) {
    const [viewType, setViewType] = useState('table')
    const [gridColumns, setGridColumns] = useState(4)
    const [data, setData] = useState({
        headers: [
            {
                label: 'Document Type',
                sortKey: 'filetype',
                isActive: false,
                sortDirection: 'asc',
            },
            {
                label: 'File/URL Name',
                sortKey: 'filename',
                isActive: false,
                sortDirection: 'asc',
            },
            {
                label: 'Uploaded by',
                sortKey: 'uploadedBy',
                sortDirection: 'asc',
                isActive: false,
            },
            {
                label: 'Date Uploaded',
                sortKey: 'dateUploaded',
                sortDirection: 'desc',
                isActive: true,
            },
            {},
        ],
        documents: [],
    })
    const [uploadingProgress, setUploadingProgress] = useState(undefined)
    const [isSearching, setSearching] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [presentingDeleteModal, setPresentingDeleteModal] = useState(false)
    const [presentingAddLinkModal, setPresentingAddLinkModal] = useState(false)
    const [viewedDocument, setViewedDocument] = useState(undefined)
    const [editedDocument, setEditedDocument] = useState(undefined)
    const [deletedDocument, setDeletedDocument] = useState(undefined)
    const [documents, setDocuments] = useState([])
    const [searchParams, setSearchParams] = useState({})
    const [selectedTypes, setSelectedTypes] = useState([])
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const dropzoneRef = useRef()
    const [maxFileSizeInMB, setMaxFileSizeInMB] = useState(
        MAX_FILE_SIZE_MB_DEFAULT
    )

    useEffect(() => {
        const fetchMaxFileSize = async () => {
            const { maxFileSizeMB } = await getMaxFileSize()
            setMaxFileSizeInMB(maxFileSizeMB)
        }

        const fetchDocuments = async () => {
            setLoading(true)
            await getDocuments()
            setLoading(false)
        }

        fetchDocuments()
        fetchMaxFileSize()
    }, [])

    const handleSwitchView = (e) => {
        e.preventDefault()

        const viewType = e.target.getAttribute('data-view-type')
        setViewType(viewType)
    }

    const handleZoomChange = (zoom) => {
        if (!ZOOM_LEVELS[zoom]) {
            return
        }

        setGridColumns(ZOOM_LEVELS[zoom])
    }

    const handleAddFile = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    }
    const handleSelect = (ids, isSelected) => {
        const newData = { ...data }

        ids.forEach((id) => {
            const document = newData.documents.find((doc) => doc.id === id)
            document.isChecked = isSelected
        })

        setData(newData)
    }
    const handleAddLink = async (data) => {
        try {
            setPresentingAddLinkModal(false)
            await uploadLink(objectType, objectId, data.url, data.label)
            displayAlert('success', 'Successfully added a link')
            getDocuments()
        } catch (error) {
            handleError(error)
        }
    }
    const handlePresentDelete = () => {
        setPresentingDeleteModal(true)
    }
    const handlePresentAddLink = () => {
        setPresentingAddLinkModal(true)
    }
    const download = (document) => {
        saveAs(document.file, document.name)
    }
    const handleDownload = async (documents) => {
        if (documents) {
            documents.forEach((document) => {
                download(document)
            })
        } else {
            for (const document of data.documents.filter(
                (document) => document.isChecked && document.filetype !== 'url'
            )) {
                const file = await fetchFile(document.id)
                download({ file, name: document.filename })
            }
        }
    }
    const handleDelete = async (document) => {
        try {
            if (document) {
                await deleteFiles([document.id])
                setDeletedDocument(undefined)
                setPresentingDeleteModal(false)
                getDocuments()
            } else {
                const ids = data.documents
                    .filter((document) => document.isChecked)
                    .map((document) => document.id)

                displayAlertLoader(MSG.loading.delete.Documents)
                await deleteFiles(ids)
                getDocuments()
                setPresentingDeleteModal(false)
                hideAlertLoader()
            }
            displayAlert('success', 'Successfully deleted documents')
        } catch (error) {
            handleError(error)
        }
    }

    const convertBytesToMB = (sizeInBytes) => {
        const BYTES_PER_KB = 1024
        const KB_PER_MB = 1024
        return sizeInBytes / (BYTES_PER_KB * KB_PER_MB)
    }

    const isFileSizeLimitExceeded = (acceptedFiles) => {
        const filesSizeMB = acceptedFiles.reduce(
            (acc, file) => acc + convertBytesToMB(file.size),
            0
        )

        return filesSizeMB > maxFileSizeInMB
    }

    const handleUpload = async (acceptedFiles) => {
        if (!acceptedFiles.length) {
            return
        }

        if (isFileSizeLimitExceeded(acceptedFiles)) {
            displayAlert(
                'danger',
                `File cannot be uploaded, it exceeds the size limit of ${maxFileSizeInMB}MB.`
            )
            return
        }

        try {
            await uploadDocuments(
                acceptedFiles,
                objectType,
                objectId,
                (progress) => {
                    setUploadingProgress(progress)
                }
            )
            displayAlert('success', 'Successfully uploaded documents')
            getDocuments()
        } catch (error) {
            handleError(error)
        } finally {
            setUploadingProgress(undefined)
        }
    }
    const getDocuments = async () => {
        try {
            let documents = await fetchDocuments(objectId, objectType)
            onRefresh(documents.length)
            setDocuments(documents)

            documents = applySearchParamsIfNeeded(documents, searchParams)
            documents = applySortingIfNeeded(documents, getActiveSorting())
            documents = applyTypesIfNeeded(documents, selectedTypes)
            documents = applyDateRangeIfNeeded(documents, selectedDateRange)

            const fileIds = documents
                .filter((document) =>
                    ['bmp', 'jpg', 'jpeg', 'png', 'gif', 'webp'].includes(
                        document.filetype
                    )
                )
                .map((document) => document.id)
            await fetchThumbnails(fileIds, (id, response) => {
                const document = documents.find(
                    (document) => document.id === id
                )
                const reader = new window.FileReader()
                reader.readAsDataURL(response)
                reader.onload = function () {
                    document.thumbnail = reader.result
                    setData({ ...data, documents })
                }
            })

            setData({ ...data, documents })
        } catch (error) {
            handleError(error)
        }
    }
    const viewFile = async (document) => {
        try {
            const file = await fetchFile(document.id)
            setViewedDocument({
                id: document.id,
                file,
                name: document.filename,
            })
        } catch (error) {
            handleError(error)
        }
    }
    const handleView = async (document) => {
        try {
            if (document.filetype === 'url') {
                window.open(document.location, '_blank')

                return
            }

            await viewFile(document)
        } catch (error) {
            handleError(error)
        }
    }
    const handleCloseDocument = () => {
        setViewedDocument(undefined)
    }
    const handlePresentEdit = (document) => {
        setEditedDocument(document)
    }
    const handleConfirmEdit = async (name, link) => {
        if (!editedDocument) {
            return
        }

        try {
            const params = { setLabel: true, newLabel: name }
            if (link) {
                params.newLink = link
            }
            await editFile(editedDocument.id, params)
            setEditedDocument(undefined)
            getDocuments()
        } catch (error) {
            handleError(error)
        }
    }
    const toggleSearch = () => {
        const _isSearching = !isSearching
        setSearching(_isSearching)

        if (!_isSearching) {
            setSearchParams({})
            setSelectedDateRange([])
            setSelectedTypes([])

            setData({ ...data, documents: documents })
        }
    }
    const handleSort = (sortKey) => {
        let sortDirection = ''
        const newData = { ...data }
        newData.headers.forEach((header) => {
            if (header.sortKey === sortKey) {
                header.sortDirection =
                    header.sortDirection === 'asc' ? 'desc' : 'asc'
                sortDirection = header.sortDirection
            } else {
                header.sortDirection = 'asc'
            }

            header.isActive = header.sortKey === sortKey
        })

        let sortedDocuments = applySortingIfNeeded(documents, {
            sortKey: sortKey,
            sortDirection: sortDirection,
        })
        sortedDocuments = applySearchParamsIfNeeded(
            sortedDocuments,
            searchParams
        )
        sortedDocuments = applyTypesIfNeeded(sortedDocuments, selectedTypes)
        sortedDocuments = applyDateRangeIfNeeded(
            sortedDocuments,
            selectedDateRange
        )

        setData({ ...newData, documents: sortedDocuments })
    }
    const handleSearch = (id, value) => {
        let newSearchParams = { ...searchParams }
        if (value === '') {
            delete newSearchParams[id]
        } else {
            newSearchParams = { ...searchParams, [id]: value }
        }
        setSearchParams(newSearchParams)

        let filteredDocuments = applySearchParamsIfNeeded(
            documents,
            newSearchParams
        )
        filteredDocuments = applySortingIfNeeded(
            filteredDocuments,
            getActiveSorting()
        )
        filteredDocuments = applyTypesIfNeeded(filteredDocuments, selectedTypes)
        filteredDocuments = applyDateRangeIfNeeded(
            filteredDocuments,
            selectedDateRange
        )

        setData({ ...data, documents: filteredDocuments })
    }
    const handleSelectedTypesChange = (filetypes) => {
        setSelectedTypes(filetypes)

        let filteredDocuments = applySearchParamsIfNeeded(
            documents,
            searchParams
        )
        filteredDocuments = applySortingIfNeeded(
            filteredDocuments,
            getActiveSorting()
        )
        filteredDocuments = applyTypesIfNeeded(filteredDocuments, filetypes)
        filteredDocuments = applyDateRangeIfNeeded(
            filteredDocuments,
            selectedDateRange
        )

        setData({ ...data, documents: filteredDocuments })
    }
    const handleDateRangeChange = (range) => {
        setSelectedDateRange(range)

        let filteredDocuments = applySearchParamsIfNeeded(
            documents,
            searchParams
        )
        filteredDocuments = applySortingIfNeeded(
            filteredDocuments,
            getActiveSorting()
        )
        filteredDocuments = applyTypesIfNeeded(filteredDocuments, selectedTypes)
        filteredDocuments = applyDateRangeIfNeeded(filteredDocuments, range)

        setData({ ...data, documents: filteredDocuments })
    }
    const applyDateRangeIfNeeded = (documents, range) => {
        if (range?.length === 2) {
            return documents.filter((document) => {
                return dayjs(document.dateUploaded).isBetween(
                    range[0],
                    range[1],
                    'day',
                    '[]'
                )
            })
        }

        return documents
    }
    const applyTypesIfNeeded = (documents, types) => {
        return types.length
            ? documents.filter((document) => types.includes(document.filetype))
            : documents
    }
    const applySearchParamsIfNeeded = (documents, searchParams) => {
        if (!Object.entries(searchParams).length) {
            return documents
        }

        return _.filter(documents, (document) => {
            let isValid = true
            Object.entries(searchParams).forEach((param) => {
                if (
                    !document[param[0]]
                        .toLowerCase()
                        .includes(param[1].toLowerCase())
                ) {
                    isValid = false
                }
            })

            return isValid
        })
    }
    const getActiveSorting = () => {
        const header = data.headers.find((header) => header.isActive === true)
        return {
            sortKey: header?.sortKey ?? 'dateUploaded',
            sortDirection: header?.sortDirection ?? 'desc',
        }
    }
    const applySortingIfNeeded = (documents, sorting) => {
        return _.orderBy(
            documents,
            [(document) => document[sorting.sortKey].toLowerCase()],
            [sorting.sortDirection]
        )
    }
    const isDownloadAvailable = () => {
        return (
            data.documents.find(
                (document) =>
                    document.filetype !== 'url' && document.isChecked === true
            ) === undefined
        )
    }
    const handleError = (error) => {
        const message = error.backedError?.response
            ? error.backedError.response.data.userError
            : getErrorMessage(error)
        displayAlert('danger', message)
    }

    return (
        <>
            <Toolbar
                viewType={viewType}
                actionsAvailable={
                    viewType === 'table' &&
                    data.documents.find(
                        (document) => document.isChecked === true
                    )
                }
                searchAvailable={viewType === 'table' && documents.length > 0}
                downloadAvailable={isDownloadAvailable()}
                onSwitchView={handleSwitchView}
                onZoomChange={handleZoomChange}
                onAddFile={handleAddFile}
                onAddLink={handlePresentAddLink}
                onDelete={handlePresentDelete}
                onDownload={() => {
                    handleDownload()
                }}
                onSearch={toggleSearch}
            />
            <Content
                data={data}
                documents={documents}
                isSearching={isSearching}
                isLoading={isLoading}
                viewType={viewType}
                gridColumns={gridColumns}
                uploadingProgress={uploadingProgress}
                onSelect={handleSelect}
                onSort={handleSort}
                onDrop={handleUpload}
                onView={handleView}
                onEdit={handlePresentEdit}
                onDownload={async (document) => {
                    const file = await fetchFile(document.id)
                    handleDownload([{ file, name: document.filename }])
                }}
                onDelete={(document) => {
                    setDeletedDocument(document)
                    handlePresentDelete()
                }}
                onSearch={handleSearch}
                onSelectedTypesChange={handleSelectedTypesChange}
                onDateRangeChange={handleDateRangeChange}
                dropzoneRef={dropzoneRef}
            />
            {presentingDeleteModal && (
                <DeleteModal
                    onConfirm={() => {
                        handleDelete(
                            deletedDocument ? deletedDocument : undefined
                        )
                    }}
                    onCancel={() => {
                        setPresentingDeleteModal(false)
                        setDeletedDocument(undefined)
                    }}
                />
            )}
            {presentingAddLinkModal && (
                <AddLinkModal
                    onConfirm={handleAddLink}
                    onCancel={() => setPresentingAddLinkModal(false)}
                />
            )}
            {viewedDocument && (
                <DocumentsViewer
                    document={viewedDocument}
                    onDownload={(document) => {
                        handleDownload([document])
                    }}
                    onClose={handleCloseDocument}
                />
            )}
            {editedDocument && (
                <EditModal
                    name={editedDocument.filename}
                    url={editedDocument.location}
                    fileType={editedDocument.filetype}
                    onConfirm={handleConfirmEdit}
                    onCancel={() => {
                        setEditedDocument(undefined)
                    }}
                />
            )}
        </>
    )
}

export default Documents
