import { CurrencyType, LocaleType } from 'app/enums/utiles/CurrencyTypes'
import { FocusEvent } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps'

type TFormCurrencyInputProps = {
    id?: string
    className?: string
    style?: React.CSSProperties
    disabled?: boolean
    sizeClass?: 'sm' | 'lg'
    value: string | number | undefined
    onValueChange?: (
        value: string,
        names: string | number | undefined,
        values: CurrencyInputOnChangeValues | undefined
    ) => void
    onChange?: (e: FocusEvent<HTMLInputElement, Element>) => void
    onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void
    allowNegativeValue?: boolean
    maxLength?: number
    placeholder?: string
    readOnly?: boolean
}

const FormCurrencyInput = ({
    id,
    className = '',
    style,
    disabled = false,
    value,
    onValueChange,
    onChange,
    onBlur,
    sizeClass = 'sm',
    allowNegativeValue,
    maxLength,
    placeholder = '$ 0.00',
    readOnly,
}: TFormCurrencyInputProps) => {
    return (
        <CurrencyInput
            {...(id && { id })}
            className={`form-control form-control-${sizeClass} ${className}`}
            intlConfig={{
                locale: LocaleType.EN_US,
                currency: CurrencyType.USD,
            }}
            allowNegativeValue={allowNegativeValue}
            {...(maxLength && { maxLength })}
            {...(style && { style })}
            {...(readOnly && { readOnly })}
            placeholder={placeholder}
            prefix="$ "
            decimalScale={2}
            decimalsLimit={2}
            value={value}
            onValueChange={(value, names, values) => {
                if (onValueChange) {
                    // If the "value" is undefined, then set it to an empty string
                    // as it means the user has deleted all numbers in the input field.
                    // If we don't do this, the value is set to 0 and the caret focus
                    // is set behind the 0, which is not the desired behavior. An example
                    // would be typing 2,450.00 then clicking backspace until the input
                    // is empty; except before this change, the input would show $0 and
                    // the cursor would be set behind the 0. Now, if the user begins to
                    // type `9`, the input would show `90` because the `0` was not removed.
                    onValueChange(value ? value : '', names, values)
                }
            }}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
        />
    )
}

FormCurrencyInput.displayName = 'FormCurrencyInput'

export default FormCurrencyInput
