export enum CompStringPercentType {
    'dmMarkup' = 0,
    'dmDiscount' = -1,
    'dmFee' = 1,
}

export enum CompNumericPercentType {
    Markup = 0,
    Discount = -1,
    Fee = 1,
}
