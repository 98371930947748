import React, { useEffect, useState } from 'react'
import { useSession } from '../../context/session'
import { type } from 'os'

export default function Intercom() {
    const {
        email,
        userId,
        username,
        fullName,
        clientId,
        clientName,
        isTrial,
        isWebOnly,
    } = useSession()
    const [hasBooted, setHasBooted] = useState(false)

    const hasIntercom = () =>
        typeof window !== 'undefined' &&
        typeof window.Intercom !== 'undefined' &&
        process.env.NODE_ENV === 'production'

    useEffect(() => {
        if (hasIntercom() && !hasBooted) {
            window.Intercom('boot', {
                app_id: 'i63sjysw',
                user_id: userId,
                email,
                username,
                name: fullName,
                isTrial,
                isWebOnly,
                company: {
                    company_id: clientId,
                    name: clientName,
                },
                appVersion: process.env.REACT_APP_VERSION,
                custom_launcher_selector: '.intercom-help-button',
            })

            setHasBooted(true)
        }
    }, [window])

    useEffect(() => {
        if (hasIntercom() && hasBooted) {
            const update = {
                email,
                name: username,
                user_id: userId,
                company: {
                    company_id: clientId,
                    name: clientName,
                    plan: `${isTrial ? 'Trial' : 'Paid'} - ${
                        isWebOnly ? 'Web Only' : 'Web Pro'
                    }
                    `,
                },
            }
            window.Intercom('update', update)
        }
    }, [userId, email, clientId, clientName, isTrial, isWebOnly])

    return null
}
