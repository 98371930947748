import React from 'react'
import { Col, Container, Button, Row, Table, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import { fakeData } from '../../factory/fakeFactory'
import Header from '../../components/Header'
import ListFilter from '../../components/ListFilter'

class ReviewSend extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: { 0: true },
            totalChecked: 1,
            data: fakeData(10),
            showRetainerModal: false,
            dataIsLoaded: false,
        }

        // This binding is necessary to make `this` work in the callback
        this.handleRowClick = this.handleRowClick.bind(this)
    }

    componentInit() {
        this.setTitle('Review & Send').setActionBar(true)

        // Set API calls.
        this.setApiURL('/data/products.json')
            .setApiParams({ responseKey: 'response' })
            .enableSearchAndSortTable()
            .fetchData()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        this.setState((prev) => {
            prev.showTableSearch = !prev.showTableSearch
            return prev
        })
    }

    handleRowClick = (e) => {
        // Get the target menu.
        const indx = e.target.parentNode.getAttribute('data-key')

        document.getElementById(`item-check-${indx}`).checked =
            !document.getElementById(`item-check-${indx}`).checked

        this.setState((prevState) => {
            prevState.checks[indx] = document.getElementById(
                `item-check-${indx}`
            ).checked

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[i] = true
                        return (document.getElementById(
                            'chk-reviewsend-' + i
                        ).checked = true)
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-reviewsend-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    handleToggle = (e) => {
        e.preventDefault()
        // Get the target menu.
        const id = e.target.getAttribute('data-state')

        // Set the new state.
        this.setState((prevState) => {
            let data = {}
            data[id] = !prevState[id]

            return data
        })
    }

    renderHeader() {
        return (
            <Header>
                <Header.Title>Review & Send</Header.Title>
            </Header>
        )
    }

    renderFilter() {
        return (
            <ListFilter expandable={0} className="no-separator">
                <ListFilter.Actions lg={12} xl={12}>
                    <ListFilter.Action className="ms-auto">
                        <Button
                            as={Link}
                            to="#"
                            variant="ivory"
                            size="sm"
                            className={`btn-icon btn-action ${
                                this.state.showTableSearch
                                    ? 'bg-primary-ash text-white'
                                    : ''
                            }`}
                            onClick={this.handleShowTableSearch}
                        >
                            <i className="ri-search-line"></i> Search
                        </Button>
                        <Button
                            as={Link}
                            to=""
                            variant="ivory"
                            size="sm"
                            className="btn-icon btn-action ms-3"
                        >
                            <i className="ri-send-plane-2-fill"></i> Send
                        </Button>
                        <Button
                            as={Link}
                            to=""
                            variant="ivory"
                            size="sm"
                            className="btn-icon btn-action ms-3"
                        >
                            <i className="ri-close-line"></i> Remove
                        </Button>
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                <div className="mb-3">
                                    {this.renderFilter()}
                                </div>

                                <div className="table-gradient">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                    >
                                        <thead>
                                            <tr className="a-table-heading">
                                                <th>
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`group-all`}
                                                        type="checkbox"
                                                        data-id={`th-0`}
                                                        id={`inline-radio-th-0`}
                                                        defaultChecked={
                                                            this.state
                                                                .totalChecked >
                                                            0
                                                                ? true
                                                                : false
                                                        }
                                                        className={`${
                                                            this.state
                                                                .totalChecked >
                                                                0 &&
                                                            this.state
                                                                .totalChecked <
                                                                this.state.data
                                                                    .length
                                                                ? 'line'
                                                                : ''
                                                        }`}
                                                        onClick={
                                                            this
                                                                .handleSelectAllChecks
                                                        }
                                                    />
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Type
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort active asc"
                                                        data-field="projectCodeNo"
                                                    >
                                                        Project
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Client
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Proposal No.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Date
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Proposal Name
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="sort">
                                                        Recipient's Email
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr
                                                className={`a-table-search-fields ${
                                                    this.state.showTableSearch
                                                        ? ''
                                                        : 'd-none'
                                                }`}
                                            >
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="projectCodeNo"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="clientName"
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((item, i) => (
                                                <tr
                                                    key={i}
                                                    data-key={i}
                                                    className={
                                                        this.state.checks[i]
                                                            ? `active`
                                                            : ''
                                                    }
                                                    onClick={
                                                        this.handleRowClick
                                                    }
                                                >
                                                    <td>
                                                        <Form.Check
                                                            inline
                                                            label=""
                                                            name={`group-` + i}
                                                            type="checkbox"
                                                            data-id={i}
                                                            id={
                                                                `chk-reviewsend-` +
                                                                i
                                                            }
                                                            onClick={
                                                                this
                                                                    .handleChecks
                                                            }
                                                            className="chk-reviewsend-item"
                                                            defaultChecked={
                                                                this.state
                                                                    .checks[i]
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={'/'}
                                                            className="text-charcoal hover-view-icon"
                                                        >
                                                            Proposal
                                                        </Link>
                                                    </td>
                                                    <td>{item.code} /</td>
                                                    <td>{item.code} / Test</td>
                                                    <td>001</td>
                                                    <td>{item.dateOpened}</td>
                                                    <td>Project Proposal</td>
                                                    <td>test@fastfwd.com</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(ReviewSend)
