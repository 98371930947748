export function formatMoney(amount) {
    if (!amount) return '$0.00'

    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    return USDollar.format(amount)
}

export function formatDate(date) {
    if (!date) return ''

    const [dateString] = date.split('T')
    const [year, month, day] = dateString.split('-')
    return `${month}/${day}/${year}`
}

export function formatFiscalMonth(date) {
    const [dateString] = date.split('T')
    const [year, month] = dateString.split('-')
    return `${month}/${year}`
}

export function normalize(str) {
    if (!str) return ''
    return String(str).toLowerCase().trim()
}

export function searchData(needle, field, haystack) {
    if (needle === '') return haystack

    return haystack.filter((row) => {
        if (normalize(row[field]).includes(normalize(needle))) {
            return true
        }

        return false
    })
}
