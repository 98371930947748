import { Item } from 'lib/api/types/Item'
import { mapRtfToDraft } from 'utilities/Rtf'
import { ItemFormValues } from '../types/FormValues'

const getPreloadValues = async (item: Item) => {
    const {
        budget,
        cldeppct,
        quantity,
        loc,
        scat,
        unitmeasure,
        itemName,
        descOnly,
        estdep,
        esttcost,
        esttprice,
        descOnlyRtf,
        descrtf,
    } = item
    // We calculate unit budget client side because the API currently doesn´t have this field
    const unitbudget =
        item.quantity !== undefined && item.budget !== undefined
            ? String(Number(item.budget / (item.quantity || 1)))
            : '0'
    const description = await mapRtfToDraft(descOnlyRtf || descrtf || descOnly)

    const newValues = {
        itemName,
        unitbudget,
        description: {
            value: descOnly,
            html: description,
        } as ItemFormValues['description'],
        estdep: String(estdep),
        esttcost: String(esttcost),
        esttprice: String(esttprice),
        budget: String(budget),
        cldeppct: String(cldeppct),
        quantity: String(quantity),
        loc: loc
            ? {
                  label: '',
                  value: loc,
              }
            : null,
        scat: {
            label: '',
            value: scat,
        },
        unitmeasure: unitmeasure
            ? {
                  label: unitmeasure,
                  value: unitmeasure,
              }
            : null,
    }
    return newValues
}
export default getPreloadValues
