import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { CreateComponentPayload } from '../types/CreateComponentPayload'
import { Component } from '../types/Component'
import { displayAlert } from 'utilities/Response'
import { UserError } from '../types/UserError'
import { AxiosError } from 'axios'
import { Company } from '../types/Company'
import { CompViews } from '../types/CompView'
import { CompStringPercentType } from '../types/CompPercentType'
import convertObjectPropsToLowercase from 'utilities/convertObjectPropsToLowercase'
import { CompTypes } from '../types/CompTypes'
import normaliseComponentPayload from 'utilities/normalizeComponentPayload'
import {
    TWhatChangedComponent,
    TWhatChangedComponentResponse,
} from '../types/WhatChangedComponent'

// Need to do a require because this module doesn´t have any types
/* eslint-disable @typescript-eslint/no-var-requires */
const HtmlToRtfBrowser = require('html-to-rtf-browser').default

const api = new ApiService()

type UseCreateComponentsPayload = {
    createComponentPayload: CreateComponentPayload
    updatePriceAndCost?: boolean
    updatedPriceAndCost?: {
        price: number
        cost: number
    }
    createAutoComponents?: boolean
}

const useCreateComponent = (
    projectId: number,
    options?: UseMutationOptions<
        Component,
        unknown,
        UseCreateComponentsPayload,
        unknown
    >
) => {
    return useMutation({
        mutationFn: async ({
            createComponentPayload,
            updatePriceAndCost,
            updatedPriceAndCost,
            createAutoComponents,
        }: UseCreateComponentsPayload) => {
            const newComponent = (await api.createComponent(
                createComponentPayload
            )) as Component

            if (updatePriceAndCost && updatedPriceAndCost !== undefined) {
                const editCompPayload = (await api.componentRecalculate(
                    newComponent.id,
                    {
                        whatChanged: TWhatChangedComponent.cwcEstimatedPrice,
                        estprice: updatedPriceAndCost.price,
                        estcost: updatedPriceAndCost.cost,
                    }
                )) as TWhatChangedComponentResponse
                const lowercaseCompPayload = convertObjectPropsToLowercase(
                    editCompPayload
                ) as Component

                const componentToEdit = {
                    ...lowercaseCompPayload,
                    usedisc:
                        (editCompPayload.useDisc as string) === 'DmFee'
                            ? 1
                            : Number(
                                  CompStringPercentType[
                                      lowercaseCompPayload.usedisc as number
                                  ]
                              ),
                    comptype: Number(CompTypes[lowercaseCompPayload.comptype]),
                    //TODO: Add supplier when we work on that refactor
                    supplier: null,
                }

                await api.editComponent(
                    newComponent.id,
                    normaliseComponentPayload(componentToEdit)
                )
            }
            if (createAutoComponents) {
                const company = (await api.get('company')) as Company
                const subcomps = (await api.get(
                    'components',
                    `?$filter=projectid eq ${projectId} and itemid eq ${createComponentPayload.itemId} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
                )) as Component[]
                await Promise.all(
                    subcomps.map(async (subcomp) => {
                        const type = CompViews[subcomp.comptype]
                        const desc = `..... ${
                            company[type as keyof Company]
                        } for component ${subcomp.comp}`
                        const compData = {
                            desc,
                            descrtf: new HtmlToRtfBrowser().convertHtmlToRtf(
                                desc
                            ),
                        }
                        await api
                            .editComponent(subcomp.id, compData)
                            .catch((error: AxiosError<UserError>) => {
                                displayAlert(
                                    'danger',
                                    error?.response?.data?.userError
                                )
                            })
                    })
                )
            }

            return newComponent
        },
        ...(options || {}),
    })
}
export default useCreateComponent
