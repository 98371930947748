import React from 'react'
import {
    Col,
    Row,
    Form,
    Table,
    Container,
    Breadcrumb,
    Button,
} from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import { ApiService } from '../../../../lib/api/HttpService'
import Spinner from '../../../../app/components/help/Spinner'
import { formatDate, formatDateTime } from '../../../../helpers/Date'
import { amountFormat, currencyFormat } from '../../../../helpers/Number'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../../utilities/Response'
import MSG from '../../../../defaults/Message'
import { enableTableSortData } from '../../../../utilities/modules/TableSorting'
import { delay } from 'lodash'
import { showLoading } from '../../../../helpers/Loading'

class ProjectProposalHistory extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tmpData: [],
            dataIsLoaded: false,
            dataTableIsLoaded: false,
            showTableSearch: false,
            sortProperty: 'generateddate desc',
        }

        this.api = new ApiService()
    }

    async componentDidMount() {
        await this.fetchData()
        delay(
            () =>
                enableTableSortData(
                    this,
                    () =>
                        this.setState({
                            dataTableIsLoaded: false,
                        }),
                    '.a-table-heading.tb-history .sort'
                ),
            500
        )
    }

    async fetchData() {
        const data = await this.api.get(
            'historyproposals',
            '?$filter=currentProposalId eq ' +
                (this.props.id ?? this.props.params.pid) +
                `&$orderby=${this.state.sortProperty}`
        )

        this.setState({
            tmpData: data,
        })

        this.renderData(data)
    }

    async changePageHandler() {
        await this.fetchData()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            dataTableIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
    }

    async handleDocument(e, item) {
        e.preventDefault()
        const { fileId, propnum, proj } = item
        let isError = false

        if (!fileId) {
            displayAlertError('No existing PDF file.')
            return
        }

        displayAlertLoader(MSG.loading.info)
        try {
            let document = await this.api.get(
                'publicmappings',
                `?fileId=${fileId}`
            )
            if (document) {
                window.open(document.link, '_blank').focus()
            }
            displayAlertSuccess('Successfully opened PDF link.')
        } catch (error) {
            isError = true
        }

        if (isError) {
            // Generate new PDF
            try {
                let document = await this.api.postJson('publicmappings', {
                    fileId,
                    label: `HISTORICAL-${proj}-${propnum}`,
                })
                if (document) {
                    window.open(document.link, '_blank').focus()
                }
                displayAlertSuccess('Successfully opened PDF link.')
            } catch (error) {
                displayAlertError(getErrorMessage(error))
            }
        }
    }

    renderFilter() {
        return (
            <>
                <ListFilter className="justify-content-end">
                    <ListFilter.Actions lg={3} xl={2}>
                        <ListFilter.Action className="ms-auto">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderContent() {
        const { dataTableIsLoaded } = this.state
        return (
            <div className="table-gradient">
                <Table striped responsive className="a-table table-check">
                    <thead>
                        <tr className="a-table-heading tb-history">
                            <th className="ps-4">
                                <span
                                    className="sort active asc"
                                    data-field="propdate"
                                >
                                    Date
                                </span>
                            </th>
                            <th>
                                <span className="sort" data-field="propnum">
                                    Proposal No.
                                </span>
                            </th>
                            <th>
                                <span
                                    className="sort active desc"
                                    data-field="generateddate"
                                >
                                    Created Date
                                </span>
                            </th>
                            <th>
                                <span className="sort" data-field="propname">
                                    Name / Phase
                                </span>
                            </th>
                            <th>
                                <span
                                    className="sort"
                                    data-field="totalProposed"
                                >
                                    Total Proposed
                                </span>
                            </th>
                            <th>
                                <span
                                    className="sort"
                                    data-field="requestedDeposit"
                                >
                                    Requested Deposit
                                </span>
                            </th>
                            <th>
                                <span className="sort" data-field="adddep">
                                    Additional Deposit Required
                                </span>
                            </th>
                            <th>
                                <span className="sort" data-field="adddepdt">
                                    Required Date
                                </span>
                            </th>
                            {!this.isModal() && (
                                <>
                                    <th>
                                        <span
                                            className="sort"
                                            data-field="style"
                                        >
                                            Style
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className="sort"
                                            data-field="usercode"
                                        >
                                            Created By User
                                        </span>
                                    </th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {!dataTableIsLoaded
                            ? showLoading()
                            : this.state.data.map((item, i) => (
                                  <tr key={i} data-key={i}>
                                      <td className="ps-4">
                                          {item.propdate
                                              ? formatDate(item.propdate)
                                              : ''}
                                      </td>
                                      <td>
                                          <Link
                                              to={getRouteWithParam(
                                                  URI.project.proposal.edit,
                                                  {
                                                      id: this.props.params.id,
                                                      pid: item.id,
                                                  }
                                              )}
                                              className="text-charcoal hover-view-icon"
                                              onClick={(e) => {
                                                  this.handleDocument(e, item)
                                              }}
                                          >
                                              {item.propnum}
                                          </Link>
                                      </td>
                                      <td>
                                          {item.generateddate &&
                                              formatDateTime(
                                                  item.generateddate
                                              )}
                                      </td>
                                      <td>{item.propname}</td>
                                      <td>
                                          {amountFormat(item.totalProposed)}
                                      </td>
                                      <td>
                                          {amountFormat(item.requestedDeposit)}
                                      </td>
                                      <td>{amountFormat(item.adddep)}</td>
                                      <td>
                                          {item.adddepdt &&
                                              formatDate(item.adddepdt)}
                                      </td>
                                      {!this.isModal() && (
                                          <>
                                              <td>{item.style}</td>
                                              <td>{item.usercode}</td>
                                          </>
                                      )}
                                  </tr>
                              ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.project.add}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.base }}
                            linkAs={Link}
                        >
                            Projects
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: getRouteWithParam(URI.project.view, {
                                    id: '1',
                                }),
                            }}
                            linkAs={Link}
                        >
                            JOH01
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: getRouteWithParam(URI.project.viewPage, {
                                    id: '1',
                                    page: 'proposals',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Proposal
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>History</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>Proposal History</HeaderLight.Title>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    isModal() {
        return !!this.props.isModal || this.props.isModal === undefined
    }

    render() {
        const { dataIsLoaded } = this.state

        if (dataIsLoaded) {
            return (
                <>
                    {!this.isModal() && this.renderHeader()}

                    <div className="content-padding min-height has-action-bar">
                        <Container
                            fluid
                            className={`${this.isModal() && 'pe-4'}`}
                        >
                            <Row>
                                <Col sm="12">
                                    {!this.isModal() && this.renderFilter()}

                                    {this.renderContent()}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Spinner />
                </>
            )
        }
    }
}

export default WithRouter(ProjectProposalHistory)
