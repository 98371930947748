import React from 'react'
import { Dropdown, Col, Row, Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import ICON from '../../../../defaults/Icon'
import URI from '../../../../defaults/RoutesDefault'
import { ApiService } from '../../../../lib/api/HttpService'
import debounce from 'lodash/debounce'
import { Pagination } from '../../../../app/components/pagination/Pagination'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import { formatDate, formatFilterDate } from '../../../../helpers/Date'
import ConfirmModal from '../../../../app/components/modal/ConfirmModal'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayErrors,
    getErrorMessage,
    hideAlertLoader,
} from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import PostJournalEntryDialog from './PostJournalEntryDialog'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { Is, _delay, isFiltered } from '../../../../helpers/Util'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import MSG from '../../../../defaults/Message'
import { getCheckAllClass } from '../../../../utilities/ModuleHelper'
import { delay } from 'lodash'
import FilterSort from '../../../../utilities/modules/FilterSort'

class JournalEntryListNew extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('gl_journal_new_list')
        this.state = {
            checks: [],
            totalChecked: 0,
            data: [],
            dataIsLoaded: false,
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? '',
            postDialogShow: false,
            showModal: false,
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.api = new ApiService()
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
        this.userCode = getCookie('dmUsercode')
        this.errIds = []
        this.updateErrIds = []
        this.updateErrors = []
    }

    async componentDidMount() {
        this.setTitle('Journal Entries').setFormAction(true)
        this.enableSortTable()
        this.fetchItems()
    }

    async fetchItems(page) {
        this.setState({
            dataIsLoaded: false,
        })

        let data = await this.api.get(
            'JournalEntries/new',
            `?${this.buildFilters(page)}`
        )

        if (data) {
            // Check if there's an extra item
            const hasNextPage = data.length > this.state.pageSize

            // Discard the extra item
            data = data.slice(0, this.state.pageSize)

            this.setState({
                data: data,
                hasNextPage: hasNextPage,
            })

            let holdArray = []
            data.forEach((item) => {
                if (!item.hold) {
                    holdArray.push(item.txnum)
                }
            })

            this.setState({
                checks: holdArray,
                totalChecked: holdArray.length,
                disabledDeleteButton: holdArray.length <= 0,
            })
        }

        this.setState({
            dataIsLoaded: true,
        })
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    toggleModal = (state) => {
        if (true === state && Is.empty(this.state.checks)) {
            // Check if there are selected.
            displayAlert('danger', 'Please select an entry to delete.', 4000)
            return
        }

        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async handleDelete(id) {
        const filter = `?UserCode=${this.userCode}&TxNum=${id}`
        await this.api
            .deleteTempJournalEntries(filter)
            .then((res) => {
                if (res.status !== 200) this.updateErrIds.push(id)
            })
            .catch((error) => {
                this.updateErrors.push({
                    error,
                    append: ` Tx Num: ${id}`,
                })
                this.updateErrIds.push(id)
            })
    }

    async deleteData(e) {
        this.updateErrors = []
        this.updateErrIds = []
        displayAlertLoader('Deleting entries. Please wait...')
        for (const id of this.state.checks) {
            if (id) {
                await this.handleDelete(id)
            }
        }

        const message = this.updateErrIds.length
            ? 'The data with the ids [' +
              this.updateErrIds.join() +
              "] couldn't be deleted"
            : 'The data was succesfully deleted'
        const variant = this.updateErrIds.length ? 'danger' : 'success'

        this.setState({
            showModal: false,
        })

        // This first delay will give us some time to process the api call.
        _delay(async () => {
            hideAlertLoader()
            displayErrors(this.updateErrors)
            displayAlert(variant, message)
            this.resetStates()
            await this.fetchItems()
        }, 1700)
    }

    // Refresh data
    refreshDataAfterDelete = (e) => {
        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: [],
            totalChecked: 0,
            showModal: false,
            deleteResponses: [],
        })
        headingCheck.checked = false

        this.fetchItems()

        this.uncheckAll()
    }

    handleCheck = async (entry) => {
        const { checks } = this.state

        const index = checks.findIndex((_id) => _id === entry.txnum)
        const checked = index > -1
        const updatedChecks = checked
            ? checks.slice(0, index).concat(checks.slice(index + 1))
            : checks.concat(entry.txnum)

        this.setState({
            checks: updatedChecks,
            totalChecked: updatedChecks.length,
        })

        await this.api
            .updateJournalEntry({
                usercode: this.userCode,
                txnum: entry.txnum,
                jedate: entry.jedate,
                hold: !entry.hold,
            })
            .catch((error) => {
                displayAlertError(getErrorMessage(error))
            })

        await this.fetchItems(this.state.page)
    }

    uncheckAll() {
        const checks = document.querySelectorAll(
            '.chk-journalentry-items-item input'
        )
        if (checks) {
            checks.forEach((e) => {
                e.checked = false
            })
        }
    }

    handleSelectAllChecks = (e) => {
        this.updateErrors = []
        this.updateErrIds = []
        let checks = []
        // Get the target menu.
        const isChecked = e.target.checked

        if (isChecked) {
            checks = this.state.data.map((item) => {
                return item.txnum
            })
        } else {
            checks = []
        }

        this.setState(
            {
                checks: checks,
                totalChecked: checks.length,
            },
            () => this.handleMultipleSelect(!isChecked)
        )
    }

    async handleMultipleSelect(hold) {
        displayAlertLoader(MSG.loading.hold)
        for (let item of this.state.data) {
            item.hold = hold

            const data = {
                usercode: this.userCode,
                txnum: item.txnum,
                jedate: item.jedate,
                hold: item.hold,
            }

            await this.api.updateJournalEntry(data).catch((error) => {
                this.updateErrors.push({
                    error,
                    append: ` Text Ref: ${item.txnum}`,
                })
                this.updateErrIds.push(item.txnum)
            })
        }

        const message = this.updateErrIds.length
            ? 'The data with the ids [' +
              this.updateErrIds.join() +
              "] couldn't be updated"
            : 'The data was succesfully updated'
        const variant = this.updateErrIds.length ? 'danger' : 'success'

        hideAlertLoader()

        if (this.updateErrIds.length) {
            displayErrors(this.updateErrors)
            displayAlert(variant, message)
        }
    }

    resetStates() {
        this.setState({
            checks: [],
            data: [],
            dataIsLoaded: false,
            pageSize: 20,
            page: 1,
            sortProperty: '',
            searchProperties: {},
            postDialogShow: false,
            showModal: false,
        })
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {}

    buildFilters(currentPage) {
        let filtersQuery = `&$filter=usercode eq ${this.userCode}`
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.ma) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else if (property.type === 'month') {
                    if (property.value)
                        filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''
        let queryString = `&$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderFilters() {
        return (
            <>
                <ListFilter>
                    <ListFilter.Fields
                        md={12}
                        lg={10}
                        xl={10}
                        className="width-33 no-auto"
                    >
                        {/* Client requested to hide this for now */}
                        {/* <ListFilter.Field>
                            <Form.Label className='text-end'><strong>Fiscal<br/>Month</strong></Form.Label>
                            <Form.Select size='sm'>
                                <option>07/2022</option>
                            </Form.Select>
                        </ListFilter.Field> */}
                    </ListFilter.Fields>
                    <ListFilter.Actions md={2} lg={2} xl={2}>
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                        <ListFilter.Action className="ms-auto ms-lg-3">
                            <Dropdown
                                className="d-flex justify-content-end ms-auto"
                                align="end"
                            >
                                <Dropdown.Toggle
                                    id="dropdown-autoclose-true"
                                    variant="ivory"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        className={
                                            this.state.checks.length
                                                ? ''
                                                : 'disabled'
                                        }
                                        onClick={() => {
                                            this.setState({
                                                postDialogShow: true,
                                            })
                                        }}
                                        href="#"
                                    >
                                        <i className={ICON.a.post}></i> Post
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className={
                                            this.state.data.length ||
                                            this.state.totalChecked
                                                ? ''
                                                : 'disabled'
                                        }
                                        href=""
                                        onClick={this.toggleModal.bind(
                                            this,
                                            true
                                        )}
                                    >
                                        <i className={ICON.a.delete}></i> Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        const { totalChecked, data } = this.state
        return (
            <>
                <Row>
                    <Col sm="12">
                        {/* Filter */}
                        {this.renderFilters()}

                        <div className="table-gradient sticky-container">
                            <Table striped responsive className="a-table">
                                <thead>
                                    <tr className="a-table-heading">
                                        <th align="middle" className="mw-70px">
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`inline-check-th-0`}
                                                    id={`inline-check-th-0`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    checked={totalChecked > 0}
                                                    className={getCheckAllClass(
                                                        totalChecked,
                                                        data
                                                    )}
                                                    onClick={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'txnum'
                                                )}
                                                data-field="txnum"
                                            >
                                                Tx Ref. No.
                                            </span>
                                        </th>
                                        <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'jenum'
                                                )}
                                                data-field="jenum"
                                            >
                                                Entry No.
                                            </span>
                                        </th>
                                        <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'jedate'
                                                )}
                                                data-field="jedate"
                                            >
                                                Date
                                            </span>
                                        </th>
                                        <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'txdesc'
                                                )}
                                                data-field="txdesc"
                                            >
                                                Trans Desc.
                                            </span>
                                        </th>
                                        <th className="">
                                            <span
                                                className={this.sortClass(
                                                    'autorev'
                                                )}
                                                data-field="autorev"
                                            >
                                                Auto Reverse
                                            </span>
                                        </th>
                                    </tr>
                                    <tr
                                        className={`a-table-search-fields ${
                                            this.state.showTableSearch
                                                ? ''
                                                : 'd-none'
                                        }`}
                                    >
                                        <th></th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="txnum"
                                                data-type="number"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'txnum'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="jenum"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'jenum'
                                                )}
                                            />
                                        </th>
                                        <th>
                                            <DateRangePicker
                                                style={{
                                                    minWidth: '200px',
                                                }}
                                                placement="auto"
                                                placeholder="Select date"
                                                format="MM/dd/yyyy"
                                                defaultValue={this.fs.getValue(
                                                    'jedate'
                                                )}
                                                onChange={this.handleDateChange.bind(
                                                    this,
                                                    'jedate'
                                                )}
                                                onClean={this.handleDateChange.bind(
                                                    this,
                                                    'jedate'
                                                )}
                                                ranges={[
                                                    {
                                                        label: 'today',
                                                        value: [
                                                            startOfDay(
                                                                new Date()
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'yesterday',
                                                        value: [
                                                            startOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                addDays(
                                                                    new Date(),
                                                                    -1
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'last7Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    6
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last 30 Days',
                                                        value: [
                                                            startOfDay(
                                                                subDays(
                                                                    new Date(),
                                                                    30
                                                                )
                                                            ),
                                                            endOfDay(
                                                                new Date()
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'This month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ),
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date()
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                    {
                                                        label: 'Last month',
                                                        value: [
                                                            startOfDay(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ) - 1,
                                                                    1
                                                                )
                                                            ),
                                                            endOfDay(
                                                                lastDayOfMonth(
                                                                    new Date(
                                                                        getYear(
                                                                            new Date()
                                                                        ),
                                                                        getMonth(
                                                                            new Date()
                                                                        ) - 1,
                                                                        1
                                                                    )
                                                                )
                                                            ),
                                                        ],
                                                    },
                                                ]}
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="txdesc"
                                                onChange={this.handleSearch}
                                                defaultValue={this.fs.getValue(
                                                    'txdesc'
                                                )}
                                            />
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.state.dataIsLoaded
                                        ? showLoading()
                                        : this.state.data.length === 0 &&
                                          isFiltered(
                                              this.state.searchProperties
                                          )
                                        ? showEmpty()
                                        : this.state.data.map((item, i) => (
                                              <tr
                                                  key={i}
                                                  data-key={i}
                                                  className={
                                                      this.state.checks.includes(
                                                          item.txnum
                                                      )
                                                          ? `active`
                                                          : ''
                                                  }
                                              >
                                                  <td>
                                                      <div className="d-flex justify-content-center">
                                                          <Form.Check
                                                              label=""
                                                              name={
                                                                  `group-` + i
                                                              }
                                                              type="checkbox"
                                                              data-id={
                                                                  item.txnum
                                                              }
                                                              id={
                                                                  `chk-journalentry-items-` +
                                                                  i
                                                              }
                                                              className="chk-journalentry-items-item single-data-checkbox"
                                                              defaultValue={
                                                                  item.txnum
                                                              }
                                                              onChange={(e) => {
                                                                  this.handleCheck(
                                                                      item
                                                                  )
                                                              }}
                                                              checked={this.state.checks.includes(
                                                                  item.txnum
                                                              )}
                                                          />
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <Link
                                                          to={routeParam(
                                                              URI.generalLedger
                                                                  .journalEntry
                                                                  .edit,
                                                              {
                                                                  id: item.txnum,
                                                                  type: 'new',
                                                              }
                                                          )}
                                                          className="text-charcoal hover-view-icon"
                                                      >
                                                          {item.txnum}
                                                      </Link>
                                                  </td>
                                                  <td>{item.jenum}</td>
                                                  <td>
                                                      {formatDate(item.jedate)}
                                                  </td>
                                                  <td>{item.txdesc}</td>
                                                  <td>
                                                      {item.autorev
                                                          ? 'Yes'
                                                          : ''}
                                                  </td>
                                              </tr>
                                          ))}
                                </tbody>
                            </Table>
                            <ConfirmModal
                                refreshData={this.refreshDataAfterDelete.bind(
                                    this
                                )}
                                show={this.state.showModal}
                                toggleModal={this.toggleModal.bind(this, false)}
                                confirmAction={this.deleteData.bind(this)}
                            />
                        </div>
                        {this.state.data.length > 0 && (
                            <Pagination
                                onPageSizeChanged={this.onPageSizeChanged}
                                onPageChanged={this.onPageChanged}
                                hasPreviousPage={this.state.page > 1}
                                hasNextPage={this.state.hasNextPage}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                            />
                        )}
                    </Col>
                    <PostJournalEntryDialog
                        isShowing={this.state.postDialogShow}
                        onCancel={() => {
                            this.setState({ postDialogShow: false })
                        }}
                        onSuccess={this.fetchItems.bind(this)}
                        hasData={this.state.data.length}
                        totalEntries={this.state.checks.length}
                    />
                </Row>
            </>
        )
    }
}

export default WithRouter(JournalEntryListNew)
