import React from 'react'
import { Container, Navbar, NavbarBrand } from 'react-bootstrap'

class UiKitFooter extends React.Component {
    render() {
        return (
            <div className="fixed-bottom">
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <NavbarBrand>
                            <p className="text-light">
                                <small>
                                    Copyright &copy; 2022 Design Manager
                                </small>
                            </p>
                        </NavbarBrand>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default UiKitFooter
