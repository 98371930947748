import { makeAutoObservable } from 'mobx'
import { ClientListResponse, ClientService } from './api/ClientService'

export default class ClientListViewModel {
    private clientService: ClientService
    private _clientList: ClientListResponse[] = []
    private _isLoaded = false
    public _params = ''

    get clientList(): ClientListResponse[] {
        return this._clientList
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    setClientList(clients: any): ClientListViewModel {
        this._clientList = clients
        return this
    }

    setParams(params: string): ClientListViewModel {
        this._params = params
        return this
    }

    setIsLoaded(isLoaded: boolean): ClientListViewModel {
        this._isLoaded = isLoaded
        return this
    }

    reset(): ClientListViewModel {
        this._params = ''
        this._clientList = []
        this._isLoaded = false
        return this
    }

    constructor(clientService: ClientService) {
        this.clientService = clientService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        this.fetchClients()
    }

    fetchClients(page = 1, query = ''): void {
        // Set loading state
        this.setIsLoaded(false)

        this.clientService
            .getClients(query)
            .then((response: any) => {
                this.setIsLoaded(true)

                if (response) {
                    this.setClientList(response)
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }
}
