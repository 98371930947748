import * as React from 'react'
import {
    Container,
    Row,
    Col,
    Form,
    FloatingLabel,
    Button,
} from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'

import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'

import logoDark from '../../../assets/images/logo-dark.svg'
import { ApiService } from '../../../lib/api/HttpService'
import { displayAlert } from '../../../utilities/Response'

class ForgotPassword extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            errorMessage: '',
            showErrorMessage: false,
            showLoginPage: false,
            isLoggingIn: false,
        }

        this.api = new ApiService()
        this.resetData = {}
    }

    handleResetClick = async (e) => {
        if (
            !String(this.resetData.email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) ||
            this.resetData.username === ''
        ) {
            displayAlert(
                'danger',
                'Username and valid email address are required.'
            )
        } else {
            await this.api
                .postResetPasswordInitiate(this.resetData)
                .then((resp) => {
                    displayAlert('success', 'Password reset successful')
                    window.onbeforeunload = null
                    setTimeout(() => {
                        window.location.href = generatePath(URI.login.base)
                    }, 2000)
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.userError
                        ? error.response?.data?.userError
                        : 'Could not reset password.'
                    displayAlert('danger', errorMessage)
                })
        }
    }

    handleChange = (e) => {
        let key, value
        if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.resetData[key] = value
    }

    render() {
        return (
            <>
                <Container fluid className="loginContainer">
                    <div className="px-4 pt-4 pb-5 mb-5">
                        <img
                            src={logoDark}
                            width="100%"
                            height="auto"
                            className="logo"
                            alt=""
                        />
                    </div>
                    <Row className="w-100 justify-content-center">
                        <Col lg={8} className="d-flex loginRight flex-column">
                            <div className="flex-grow-1 loginRight-form">
                                <div>
                                    <h1 className="fsx-40 mb-4 text-center">
                                        Forgot your password?
                                    </h1>

                                    <p className="text-secondary-ash fsx-16 mb-4 text-center">
                                        Enter the username and email associated
                                        with your acount and we'll send an email
                                        with instructions to reset your
                                        password.
                                    </p>

                                    <div className="d-grid gap-3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Username"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                placeholder="Username"
                                                size="lg"
                                                onChange={this.handleChange}
                                            />
                                        </FloatingLabel>

                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email address"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Email address"
                                                size="lg"
                                                onChange={this.handleChange}
                                            />
                                        </FloatingLabel>

                                        <Button
                                            variant="primary"
                                            size="lg"
                                            onClick={this.handleResetClick}
                                        >
                                            Reset password
                                        </Button>

                                        <div className="text-center">
                                            <Link to={URI.login.base}>
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default ForgotPassword
