import { ApiService } from '../../../../lib/networking/HttpService'

export class AddressService extends ApiService {
    getAddresses<T = AddressListResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(
            `/addresses${params ? `?$filter=${params}` : ``}`,
            {}
        )
    }

    getVendorAddresses<T = AddressListResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(`/addresses/type/2${params}`, {})
    }

    getAddressByClientCode<T = AddressListResponse>(
        code: string,
        addresstype: number
    ): Promise<T> {
        return addresstype !== 0
            ? this.httpWorker.get(
                  `/addresses?$filter=code eq '${code}' and addresstype eq ${addresstype}`,
                  {}
              )
            : this.httpWorker.get(`/addresses?$filter=code eq '${code}'`, {})
    }

    insert<T = AddressListResponse>(params: any): Promise<T> {
        return this.httpWorker.post(`/addresses`, params)
    }

    update<T = AddressListResponse>(id: number, params: any): Promise<T> {
        return this.httpWorker.patch(`/addresses/${id}`, params)
    }
}

export interface AddressListResponse {
    id: number
    attention?: string
    addr?: string
    city: string
    state?: string
    zip?: any
    phone1?: string
    phone2?: string
    contact: string
    contactphone?: string
    fax?: string
    email?: string
    addressTypeEnum?: string
    addresstype: number
    code: any
    phonedesc1?: string
    phonedesc2?: string
    website?: string
    warehouse?: boolean
    shiptofromorder?: boolean
    sortname?: string
    parsetype: number
    inactive: boolean
    lastmodified?: string
    miscnotes?: string
    miscnotesrtf?: string
    typedesc?: string
    vendorisemployee?: boolean
    ccsiteid?: number
    cckey?: string
    ccname?: string
    emailcc?: string
    emailbcc?: string
    email2?: string
    ssmaTimeStamp?: string
    list: []
}
