import useGetPaymentProcessorInfo from 'lib/api/hooks/useGetPaymentProcessorStatus'
import React, { useState } from 'react'
import Spinner from 'app/components/help/Spinner'
import { OnboardingStatus } from 'lib/api/types/PaymentProcessorResponse'
import OnboardingStartFlow from './components/OnboardingStartFlow'
import OnboardedMessageComponent from './components/OnboardedMessageComponent'
import Tabs, { TTabOption } from './components/DesignPayTabs'
import PaymentsReport from './components/Payments'
import DepositsReport from './components/Deposits'
import { useFeature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'

const TabComponentMap: { [key: string]: JSX.Element } = {
    Payments: <PaymentsReport />,
    Deposits: <DepositsReport />,
}

const DesignPay = () => {
    const showFeature = useFeature(FeatureFlags.RAINFOREST)
    const { data, isLoading, error } = useGetPaymentProcessorInfo('Rainforest')
    const [activeTab, setActiveTab] = useState<TTabOption>('Payments')

    const toggleTab = (tab: TTabOption) => setActiveTab(tab)

    if (isLoading) {
        return <Spinner />
    }

    if (error) {
        return <></>
    }

    const decideComponent = () => {
        if (data?.onboardingStatus === OnboardingStatus.Initiated) {
            return <OnboardingStartFlow />
        } else if (data?.onboardingStatus !== OnboardingStatus.Verified) {
            return <OnboardedMessageComponent />
        }
        return TabComponentMap[activeTab]
    }

    return !showFeature ? null : (
        <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-bg-[#006A53] tw-p-10">
                <h2 className="tw-text-4xl tw-text-white">Design Pay</h2>
                {data?.onboardingStatus === OnboardingStatus.Verified && (
                    <Tabs toggleTab={toggleTab} activeTab={activeTab} />
                )}
            </div>
            {decideComponent()}
        </div>
    )
}

DesignPay.displayName = 'DesignPay'

export default DesignPay
