import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Table, Col, Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import { Is } from '../../../../helpers/Util'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
import { removeSpaces } from '../../../../utilities/String'
import { isEmpty } from 'lodash'

class ItemAddSpecification extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = props.updatedData
        this.state = {
            data: {},
            selectedAttr: {},
            specDescHtml: '',
            fieldCount: this.updatedData?.fieldCount ?? 1,
            attributes: {},
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
    }

    async componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data || {}

            data = this.setData(data)

            let options = {
                specDescHtml: await mapRtfToDraft(
                    this.updatedData?.specdescrtf ?? data?.specdescrtf ?? ''
                ),
            }
            let specFields = 0
            if (data.defaultTemplate?.value) {
                let selected = {}
                _.each(data.specificationTemplates, function (tmp) {
                    if (tmp.speccode == data.defaultTemplate.value) {
                        selected = tmp
                    }
                })

                options.selectedAttr = selected
            }

            this.setState({
                ...options,
                data: data,
                dataIsLoaded: true,
            })
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data || {}

            data = this.setData(data)

            this.setState({
                data: data,
                dataIsLoaded: true,
                specDescHtml: await mapRtfToDraft(
                    this.updatedData?.specdescrtf ?? data?.specdescrtf ?? ''
                ),
            })
        }
    }

    setData(data) {
        let selectTemplate = []
        for (let tmp of data.specificationTemplates ?? []) {
            selectTemplate.push({
                value: tmp.speccode,
                label: tmp.speccodename,
            })

            if (!Is.empty(data) && tmp.speccode === data.speccode) {
                data.defaultTemplate = {
                    value: tmp.speccode,
                    label: tmp.speccodename,
                }
            }
        }

        data.allVendors = data.allVendors ?? []

        for (let vendor of data.allVendors) {
            if (!Is.empty(data) && vendor.vendor === data.specmfg) {
                data.defaultMfg = {
                    value: vendor.vendor,
                    label: vendor.vendorn + ` [${vendor.vendor}]`,
                }
            }

            if (!Is.empty(data) && vendor.vendor === data.specsourcesup) {
                data.defaultSrc = {
                    value: vendor.vendor,
                    label: vendor.vendorn + ` [${vendor.vendor}]`,
                }
            }
        }

        data.templateList = selectTemplate

        let fieldCount = 0

        if (isEmpty(this.updatedData)) {
            for (let i = 1; i <= 10; i++) {
                if (
                    removeSpaces(data['specd' + i]) ||
                    removeSpaces(data['spect' + i])
                ) {
                    fieldCount++
                }

                const specdval = data['specd' + i]
                const spectval = data['spect' + i]
                this.updatedData['specd' + i] = specdval
                this.updatedData['spect' + i] = spectval
            }

            this.props.onDataChange({ key: 'fieldCount', value: fieldCount })
            this.setState({
                fieldCount,
            })
        }

        return data
    }

    handleChange = async (e, meta = {}) => {
        const _self = this
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        this.props.onDataChange({ key: key, value: value })

        if (key === 'specdesc') {
            const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(
                e.target.html
            )
            this.props.onDataChange({ key: `${key}rtf`, value: valueHtml })
        }

        if (key === 'speccode') {
            let selected = {}
            _.each(this.state.data.specificationTemplates, function (tmp) {
                if (tmp.speccode == value) {
                    selected = tmp
                }
            })

            let specFields = 0
            let keys = Object.keys(selected)
            _.each(keys, function (idx) {
                if (idx != undefined && idx.indexOf('spect') === 0) {
                    specFields++
                }

                if (
                    idx != undefined &&
                    (idx.indexOf('spect') === 0 ||
                        idx.indexOf('specd') === 0 ||
                        idx == 'specmaterial')
                ) {
                    _self.props.onDataChange({ key: idx, value: selected[idx] })
                }
            })

            this.setState({
                selectedAttr: selected,
                fieldCount: specFields,
                specDescHtml: await mapRtfToDraft(selected.specdescrtf ?? ''),
            })
        }
    }

    defaultManufacturer() {
        const { data } = this.state
        let manufacturer = data.defaultMfg
        if (data.vendorsList && data.vendorsList.length > 0) {
            const selectedManufacturer = data.vendorsList.filter(
                (manufacturer) => {
                    return manufacturer?.value === this.updatedData.specmfg
                }
            )[0]
            if (selectedManufacturer) {
                manufacturer = selectedManufacturer
            }
        }
        return manufacturer
    }

    defaultSource() {
        const { data } = this.state
        let source = data.defaultSrc
        if (data.vendorsList && data.vendorsList.length > 0) {
            const selectedSource = data.vendorsList.filter((source) => {
                return source?.value === this.updatedData.specsourcesup
            })[0]
            if (selectedSource) {
                source = selectedSource
            }
        }
        return source
    }

    defaultTemplate() {
        const { data } = this.state
        let template = data.defaultTemplate
        if (data.templateList && data.templateList.length > 0) {
            const selectedTemplate = data.templateList.filter((template) => {
                return template?.value === this.updatedData.speccode
            })[0]
            if (selectedTemplate) {
                template = selectedTemplate
            }
        }
        return template
    }

    handleFieldAdd = () => {
        this.setState((prevState) => {
            const value = prevState.fieldCount + 1
            this.props.onDataChange({ key: 'fieldCount', value })
            return {
                fieldCount: value,
            }
        })
    }

    handleFieldDelete = (i) => {
        let { fieldCount, data } = this.state
        let ctr = 1
        for (let i2 = 1; i2 <= 10; i2++) {
            if (
                i !== i2 &&
                (removeSpaces(this.updatedData['specd' + i2]) ||
                    removeSpaces(this.updatedData['spect' + i2]))
            ) {
                const specdval = this.updatedData['specd' + i2]
                const spectval = this.updatedData['spect' + i2]
                this.updatedData['specd' + ctr] = specdval
                this.updatedData['spect' + ctr] = spectval

                this.props.onDataChange({ key: 'specd' + ctr, value: specdval })
                this.props.onDataChange({ key: 'spect' + ctr, value: spectval })
                ctr++
            }
        }

        for (; ctr <= 10; ctr++) {
            this.updatedData['specd' + ctr] = data['specd' + ctr] = ''
            this.updatedData['spect' + ctr] = data['spect' + ctr] = ''

            this.props.onDataChange({ key: 'specd' + ctr, value: '' })
            this.props.onDataChange({ key: 'spect' + ctr, value: '' })
        }

        fieldCount = fieldCount - 1
        this.setState({
            data,
            fieldCount: fieldCount,
        })
        this.props.onDataChange({ key: 'fieldCount', value: fieldCount })
    }

    getAttributeValue(key) {
        return this.updatedData[key] ?? this.state.data[key]
    }

    render() {
        const { data } = this.state
        return (
            <>
                <Form.Group controlId="formGridText1">
                    <Row xs={1} lg={2} className="py-4">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Spec/Template No.
                                    </Form.Label>
                                </Col>
                                <Col lg={5}>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={data.templateList}
                                        name="speccode"
                                        value={this.defaultTemplate()}
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Material"
                                        name="group2"
                                        type="checkbox"
                                        id="specmaterial"
                                        defaultChecked={
                                            this.updatedData.specmaterial ??
                                            this.state.selectedAttr.specmaterial
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Plan No.
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        id="planno"
                                        defaultValue={
                                            this.updatedData.planno ??
                                            data.planno
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Manufacturer
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={data.vendorsList}
                                        defaultValue={this.defaultManufacturer()}
                                        className="react-select"
                                        placeholder="Please select"
                                        name="specmfg"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Source
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={data.vendorsList}
                                        defaultValue={this.defaultSource()}
                                        name="specsourcesup"
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} className="py-4">
                        <Col lg={12} className="mb-2">
                            <Form.Label className="mb-0">
                                Instructions
                            </Form.Label>
                        </Col>
                        <Col lg={8} className="mb-3 mb-lg-0">
                            <WysiwygEditor
                                id="specdesc"
                                onChange={this.handleChange}
                                name="specdesc"
                            >
                                {this.state.specDescHtml}
                            </WysiwygEditor>
                        </Col>
                    </Row>

                    <div className="bg-secondary-grey px-4 roundedx-4 mb-4">
                        <Row xs={1} lg={2} className="py-3 align-items-center">
                            <Col>
                                <h6 className="fw-bold mb-0">Attributes</h6>
                            </Col>
                            <Col className="text-end">
                                <Button
                                    variant="primary"
                                    size="md"
                                    onClick={this.handleFieldAdd}
                                    disabled={this.state.fieldCount >= 10}
                                >
                                    <i className="ri-add-fill"></i> Add
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <Row className="mb-4">
                        <Col lg={12}>
                            <div className="table-gradient">
                                <Table responsive borderless>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="mw-100px">Title</th>
                                            <th className="mw-100px">
                                                Description
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(() => {
                                            let fields = []
                                            for (
                                                let i = 0;
                                                i <
                                                Math.min(
                                                    this.state.fieldCount,
                                                    10
                                                );
                                                i++
                                            ) {
                                                fields.push(
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                id={
                                                                    'spect' +
                                                                    (i + 1)
                                                                }
                                                                value={this.getAttributeValue(
                                                                    'spect' +
                                                                        (i + 1)
                                                                )}
                                                                name={
                                                                    'spect' +
                                                                    (i + 1)
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Please enter"
                                                                id={
                                                                    'specd' +
                                                                    (i + 1)
                                                                }
                                                                value={this.getAttributeValue(
                                                                    'specd' +
                                                                        (i + 1)
                                                                )}
                                                                name={
                                                                    'specd' +
                                                                    (i + 1)
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button
                                                                variant="light"
                                                                className="btn-icon"
                                                                size="md"
                                                                onClick={() => {
                                                                    this.handleFieldDelete(
                                                                        i + 1
                                                                    )
                                                                }}
                                                            >
                                                                <i className="ri-subtract-line"></i>
                                                                Remove
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            return fields
                                        })()}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
            </>
        )
    }
}

export default WithRouter(ItemAddSpecification)
