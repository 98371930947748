import React from 'react'
import TileBackground from '../../../assets/images/Card_Single-BG.png'

interface IFanImagesProps {
    images?: string[]
}

const ImagesFanFrame = ({ images = [] }: IFanImagesProps) => {
    const totalImages = 3
    const itemImages = []

    const IMAGE_ROTATION = {
        FIRST_IMAGE: 'tw-transform tw-rotate-[-15deg] tw--bottom-4 tw-left-2',
        SECOND_IMAGE: 'tw-transform tw-rotate-[15deg] tw--bottom-4 tw-right-2',
        THIRD_IMAGE: 'tw-transform tw-rotate-0 tw-left-[25%] tw-bottom-0',
    }

    while (itemImages.length < totalImages - images.length) {
        itemImages.push('FallbackFrame')
    }

    itemImages.push(...images)

    const imageRotation = (index: number) => {
        switch (index) {
            case 0:
                return IMAGE_ROTATION.FIRST_IMAGE
            case 1:
                return IMAGE_ROTATION.SECOND_IMAGE
            case 2:
                return IMAGE_ROTATION.THIRD_IMAGE
            default:
                return ''
        }
    }

    return (
        <div
            className="tw-relative tw-w-full tw-h-40 tw-rounded-lg"
            style={{
                backgroundImage: `url(${TileBackground})`,
            }}
        >
            {itemImages.map((image, index) => {
                const rotation = imageRotation(index)
                if (image === 'FallbackFrame') {
                    return (
                        <div
                            key={index}
                            className={`tw-absolute tw-w-[50%] tw-h-[60%] tw-rounded-lg tw-border-dotted tw-border-2 tw-border-gray-300 tw-flex tw-items-center tw-justify-center tw-bg-white ${rotation}`}
                        >
                            <span className="tw-text-gray-400 tw-text-3xl">
                                +
                            </span>
                        </div>
                    )
                }
                return (
                    <img
                        key={index}
                        src={image}
                        alt={`Fan Image ${index + 1}`}
                        className={`tw-absolute tw-w-[50%] tw-h-[60%] tw-rounded-lg tw-object-cover ${rotation}`}
                    />
                )
            })}
        </div>
    )
}

ImagesFanFrame.displayName = 'ImagesFanFrame'

export default ImagesFanFrame
