import { addDomClass, hasClass, removeDomClass } from '../DOM'
import { delay, isEmpty, sortBy, toLower } from 'lodash'

/*****************************************************************
 *      Non-API Table Sorting
 *****************************************************************
 * This sort the current data according to the given field.
 * In `sortBy` at line 21, if you think you have custom logic for
 * the value, then add your custom pattern and pass to the
 * `tableSortingEnableSort`
 */

export function tableSortingSortData({
    key,
    dir,
    data,
    pattern,
}: {
    key: string
    dir?: string
    data?: any[]
    pattern?: Function
}) {
    if (!isEmpty(data)) {
        const pttrn =
            typeof pattern === 'function'
                ? pattern(key) // pass an similar function using `pattern` method to customize
                : [
                      (d: any) => {
                          // NEVER UPDATE THIS PATTERN. Add custom via parameter if needed a different approach.
                          // If there is a custom format or calculation for the value,
                          // please add your custom pattern in the parameters,
                          // then use switch case to match the key and do the transforming of value.
                          // Below checks if the value is null or not. If null, we return an empty string
                          // so that the sortBy function will work on null values.
                          if (typeof d[key] === 'string') {
                              return toLower(d[key])
                          }

                          return d[key] ?? ''
                      },
                  ]
        let ndata = sortBy(data, pttrn)

        if (dir === 'desc') {
            ndata = ndata.reverse()
        }

        return ndata
    }
}

const tableSortingData: any = {}
export function tableSortingSetData(data: any, key: string) {
    tableSortingData[key] = data
}
export function getTableSortingSetData(key: string) {
    return tableSortingData[key] ?? []
}

export function tableSortingEnableSort({
    data,
    classRef,
    targetTable,
    stateKey,
    pattern,
    callback,
    dataKey,
}: {
    data: any[]
    classRef: { setState: (args: any) => void }
    targetTable: string
    stateKey: string
    pattern?: Function
    callback?: Function
    dataKey?: string
}) {
    const target =
        targetTable?.indexOf('.sort') > -1
            ? targetTable
            : targetTable + ' .sort'
    const sort = document.querySelectorAll(target)

    if (data !== undefined) {
        tableSortingSetData(data, dataKey ?? 'data')
    }

    // Add change event
    if (sort) {
        sort.forEach((_e) => {
            function init(e: any) {
                const dataArr = getTableSortingSetData(dataKey ?? 'data')

                if (hasClass('isSorting', _e)) {
                    return
                }

                addDomClass('isSorting', _e)
                sort.forEach((_e2) => {
                    if (_e !== _e2) {
                        removeDomClass('desc', _e2)
                        removeDomClass('asc', _e2)
                        removeDomClass('active', _e2)
                    }
                })

                addDomClass('active', _e)

                if (hasClass('desc', _e)) {
                    removeDomClass('desc', _e)
                    addDomClass('asc', _e)
                } else if (hasClass('asc', _e)) {
                    removeDomClass('asc', _e)
                    addDomClass('desc', _e)
                } else {
                    addDomClass('desc', _e)
                }

                const field: any = (_e.attributes as any)['data-field']

                const ndata = !isEmpty(dataArr)
                    ? tableSortingSortData({
                          key: field.value,
                          dir: hasClass('desc', _e) ? 'asc' : 'desc',
                          data: dataArr,
                          pattern,
                      })
                    : []

                if (stateKey) {
                    classRef.setState({
                        [stateKey]: ndata,
                    })
                }

                const sort1 = `${field?.value} ${
                    hasClass('desc', _e) ? 'asc' : 'desc'
                }`
                if (callback) {
                    callback(ndata, sort1)
                }

                delay(() => {
                    removeDomClass('isSorting', _e)
                }, 400)
            }

            _e.removeEventListener('click', init)
            _e.addEventListener('click', init, false)
        })
    }
}

/**
 * Enable table sort
 * @param classRef
 */
export function enableTableSortData(
    classRef: any,
    callback = (res: any) => res,
    target?: string
) {
    const sort = document.querySelectorAll(target ?? '.a-table-heading .sort')

    // Add change event
    if (sort) {
        sort.forEach((_e) => {
            _e.addEventListener(
                'click',
                (e) => {
                    sort.forEach((_e2) => {
                        if (_e !== _e2) {
                            removeDomClass('desc', _e2)
                            removeDomClass('asc', _e2)
                            removeDomClass('active', _e2)
                        }
                    })

                    addDomClass('active', _e)

                    if (hasClass('desc', _e)) {
                        removeDomClass('desc', _e)
                        addDomClass('asc', _e)
                    } else if (hasClass('asc', _e)) {
                        removeDomClass('asc', _e)
                        addDomClass('desc', _e)
                    } else {
                        addDomClass('desc', _e)
                    }

                    const sortProperty = `${
                        (_e.attributes as any)['data-field'].value
                    } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                    callback && callback(sortProperty)

                    // Save sortProperty to localStorage
                    classRef?.fs?.setSort && classRef.fs.setSort(sortProperty)

                    classRef.setState(
                        {
                            sortProperty,
                        },
                        classRef.changePageHandler
                    )
                },
                false
            )
        })
    }
}

export function sortLocalData(classRef: any, data: any, pattern: any) {
    const sortArr = classRef?.fs?.getSortArr()
    if (sortArr.length) {
        const [key, dir] = sortArr
        if (pattern) {
            return tableSortingSortData({
                key,
                dir,
                data,
                pattern: pattern,
            })
        }

        return tableSortingSortData({
            key,
            dir,
            data,
        })
    }

    return data
}
