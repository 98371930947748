import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { TDesignPayRates } from '../types/DesignPayRates'

const api = new ApiService()

const getDesignPayRates = async (
    isOnboarded: boolean
): Promise<TDesignPayRates> => {
    const rates = !isOnboarded
        ? await api.getDesignPayDefaultsForNonOnboardedCustomers()
        : await api.getDesignPayDefaultsForOnboardedCustomers()
    return rates
}

const useGetDesignPayDefaults = (
    isOnboarded = false,
    options?: UseQueryOptions<
        TDesignPayRates,
        unknown,
        TDesignPayRates,
        (string | boolean)[]
    >
) => {
    const ratesQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [isOnboarded, 'designpayrates'],
        queryFn: () => getDesignPayRates(isOnboarded),
        ...(options || {}),
    })

    useEffect(() => {
        if (ratesQuery.error) {
            displayAlertError(
                'An error occurred fetching the rates information, please try again'
            )
        }
    }, [ratesQuery.error])

    return ratesQuery
}

export default useGetDesignPayDefaults
