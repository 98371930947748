import { Col, Container, Row, Table, Form } from 'react-bootstrap'
import { observer } from 'mobx-react'
import TimeEntryFilter from './TimeEntryFilter'
import TimeEntryListViewModel from '../view-models/TimeEntryListViewModel'
import TimeEntryAddEditViewModel from '../view-models/TimeEntryAddEditViewModel'
import { ApiService } from '../../../../lib/api/HttpService'
import { renderBooleanToYesOrNo } from '../../../../helpers/Common'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { Header } from '../../../components/header/HeaderComponent'
import { Pagination } from '../../../components/pagination/Pagination'
import { TableComponent, TableComponentState } from '../../../TableComponent'
import { formatDate, formatFilterDate } from '../../../../helpers/Date'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { isFiltered } from '../../../../helpers/Util'
import {
    displayAlertError,
    displayAlertLoader,
    getErrorMessage,
    displayAlert,
    hideAlertLoader,
} from '../../../../utilities/Response'
import MSG from '../../../../defaults/Message'
import dayjs from 'dayjs'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import { isEmpty } from 'lodash'
import FilterSort from '../../../../utilities/modules/FilterSort'
import { getCookie, parseJwt } from '../../../../utilities/Auth'
import SecureLink from '../../../components/security/SecureLink'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security'
import {
    renderSecureContent,
    renderSecureHideContent,
} from '../../../../utilities/modules/SecureContent'
import ConfirmationModal from 'app/components/modal/ConfirmationModal'

interface ITimeEntryActionResult {
    action: string
    result: string
    message: string | null
}

interface Props {
    viewModel: TimeEntryListViewModel
    timeEntryAddEditModel: TimeEntryAddEditViewModel
    paths?: any
    navigate?: any
}
interface State extends TableComponentState {
    data: []
    tmpData: []
    showTableSearch: boolean
    timeEntryIdSelected: number | null
    showConfirmationModal: boolean
    filters: Record<string, any>
    tableHeight: string
    pageSize: number
    page: number
    sortProperty: string
    searchProperties: {}
}

class TimeEntryListView extends TableComponent<Props, State> {
    readonly viewModel: TimeEntryListViewModel
    readonly timeEntryAddEditModel: TimeEntryAddEditViewModel
    readonly api: ApiService
    readonly fs: FilterSort
    readonly fsFilter: FilterSort
    defaultEmployee: any

    constructor(props: Props) {
        super(props)

        this.fs = new FilterSort('time_entry_list')
        this.fsFilter = new FilterSort('timeEntryFilter')
        this.viewModel = props.viewModel
        this.timeEntryAddEditModel = props.timeEntryAddEditModel

        this.fs.setDefaultSort(
            'entrydate desc, proj asc, employee asc, activity asc'
        )
        this.state = {
            data: [],
            tmpData: [],
            timeEntryIdSelected: null,
            showConfirmationModal: false,
            filters: {
                entrydate: this.getDefaultFilter().entrydate,
                employee:
                    this.fsFilter.getFilter('defaultEmployee')?.value ??
                    this.fsFilter.getFilter('employee')?.value,
                proj: this.fsFilter.getFilter('proj')?.value,
            },
            tableHeight: '100px',
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort(),
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.api = new ApiService()
    }

    async componentDidMount() {
        super.componentDidMount()

        this.setTitle('Time Entry').hasActionBar(true).setBodyClass()

        // Check if we need to reset the time entry filter.
        if (getLocalStorage('resetTimeEntryFilter') === 'yes') {
            setLocalStorage('timeEntryFilter', '')
            setLocalStorage('resetTimeEntryFilter', 'no')
        }

        const token = getCookie('dmAuthToken')
        const tokenData = parseJwt(token)
        const { name: username } = tokenData

        const user: any = await this.api.getUserData(
            `?$filter=password eq '${username}'`
        )
        const userdata = user[0] || {}

        const employee: any = await this.api.getClientEmployeeSummaryByCode(
            userdata.emp
        )

        if (employee) {
            this.defaultEmployee = {
                value: employee.key,
                label: `[${employee.key}] ${employee.value}`,
            }
            this.fsFilter.setFilter('defaultEmployee', this.defaultEmployee)
            this.fsFilter.setFilter('employee', this.defaultEmployee)
        }

        await this.loadData()
    }

    getFS() {
        return this.fs
    }

    getDefaultFilter() {
        const filters: any = {}
        filters.entrydate = this.props.paths.state?.date ?? [
            startOfDay(subDays(new Date(), 6)),
            endOfDay(new Date()),
        ]
        filters.employee = this.props.paths.state?.employee
        filters.proj = this.props.paths.state?.proj

        if (getLocalStorage('resetTimeEntryFilter') === 'no') {
            const filter = getLocalStorage('timeEntryFilter', true)
            if (!isEmpty(filter)) {
                if (!isEmpty(filter.entrydate)) {
                    filters.entrydate = filter.entrydate
                    filters.entrydate[0] = new Date(filters.entrydate[0])
                    filters.entrydate[1] = new Date(filters.entrydate[1])
                } else if (filter.entrydate === null) {
                    filters.entrydate = null
                }

                if (!isEmpty(filter.employee)) {
                    filters.employee = filter.employee?.value
                }
                if (!isEmpty(filter.proj)) {
                    filters.proj = filter.proj?.value
                }
            }
        }

        return filters
    }

    async loadData() {
        await this.handleChangePage(1)
        await this.timeEntryAddEditModel.fetchFilters()
    }

    onFilterChange(label: string, value: any) {
        const filters = this.state.filters
        value ? (filters[label] = value) : delete filters[label]

        this.setState({
            filters: filters,
        })

        this.onPageChanged(1)
    }

    handleShowTableSearch: any = (state: any) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e: any) => {
                e.value = ''
            })
        }

        this.setState({
            showTableSearch: !state.showTableSearch,
        })
        this.fs.setIsActiveSearch(!state.showTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                () => {
                    this.handleChangePage(1)
                }
            )
        }
    }

    handleCheck = (timeEntryId: number, isChecked: boolean) => {
        this.setState({
            timeEntryIdSelected: isChecked ? timeEntryId : null,
        })
    }

    async handleTryDelete() {
        displayAlertLoader(MSG.loading.prepare.item)

        const timeTxNum = this.state.timeEntryIdSelected
        const tryDeleteResponse = (await this.api.postTimeEntryAction({
            action: 'TryDelete',
            timeTxNum,
        })) as ITimeEntryActionResult

        hideAlertLoader()
        if (tryDeleteResponse.result === 'dmriNoViolation') {
            this.setState({
                showConfirmationModal: true,
            })
        } else {
            displayAlertError(tryDeleteResponse.message!)
        }
    }

    async deleteTimeEntry() {
        displayAlertLoader(MSG.loading.delete.msg)

        const timeTxNum = this.state.timeEntryIdSelected
        const deleteResponse = (await this.api.postTimeEntryAction({
            action: 'Delete',
            timeTxNum,
        })) as ITimeEntryActionResult

        hideAlertLoader()
        if (deleteResponse.result === 'dmriNoViolation') {
            displayAlert('success', 'Successfully deleted Time Entry')
            this.refreshDataAfterDelete()
        } else {
            displayAlertError(deleteResponse.message!)
        }
    }

    refreshDataAfterDelete = () => {
        this.handleChangePage(1)
        this.setState({
            showConfirmationModal: false,
            timeEntryIdSelected: null,
        })
    }

    sortClass(name: string) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderHeader(): JSX.Element {
        return (
            <Header>
                <Header.LeftContent>
                    <Header.Title>Time Entry</Header.Title>
                </Header.LeftContent>
                <Header.Actions list={true}>
                    <li>
                        <SecureLink
                            attributeNo={15}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
                            to={URI.timeEntry.add}
                            state={{
                                date: this.state.filters.date,
                                employee: this.fsFilter.getFilter('employee'),
                                proj: this.fsFilter.getFilter('proj'),
                            }}
                            className="btn-icon text-secondary-green btn btn-light btn-lg"
                            title="Add Time Entry"
                        >
                            <i className="ri-add-line ri-lg"></i> Add
                        </SecureLink>
                    </li>
                </Header.Actions>
            </Header>
        )
    }

    buildFilters(currentPage: number) {
        let filtersQuery = ``
        const filters: string[] = []

        Object.keys(this.state.filters)
            .filter((key) => this.state.filters[key] !== undefined)
            .forEach((key) => {
                if (this.state.filters[key]) {
                    if (key !== 'entrydate') {
                        filters.push(`${key} eq '${this.state.filters[key]}'`)
                    } else {
                        filters.push(
                            `${key} ge ${dayjs(
                                this.state.filters[key][0]
                            ).format('YYYY-MM-DD')} and ${key} le ${dayjs(
                                this.state.filters[key][1]
                            ).format('YYYY-MM-DD')}`
                        )
                    }
                }
            })

        Object.keys(this.state.searchProperties).forEach((key) => {
            // @ts-ignore
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.ma) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? `$filter=${filters.join(' and ')}` : ''
        let queryString = `?$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }&${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        const resBody = {
            query: queryString,
            startDate: this.state.filters.entrydate?.[1].toISOString(),
            endDate: this.state.filters.entrydate?.[0].toISOString(),
            projectCode: this.state.filters.proj,
            employeeCode: this.state.filters.employee,
        }
        return resBody
    }

    onPageSizeChanged = (size: number) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page: number) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page: number) => {
        const filters = this.buildFilters(page)
        this.viewModel.fetchTimeEntries(
            page,
            filters.query,
            filters.projectCode,
            filters.employeeCode,
            filters.startDate,
            filters.endDate
        )

        this.setState({
            page: page,
        })
    }

    handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const key = e.target.attributes['data-field' as any].value
        const value = e.target.value
        const type = e.target.attributes['data-type' as any]
            ? e.target.attributes['data-type' as any].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.handleChangePage(1)
            }
        )
    }

    handleDateChange = (key: any, e: any) => {
        const tmp: {
            [key: string]: { min: string; max: string; type: string }
        } = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }

        this.setState(
            {
                searchProperties: tmp,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.handleChangePage(1)
            }
        )
    }

    isCannotBeEdited(msg: string) {
        if (!msg) return false
        return msg.toString().indexOf('cannot be deleted') > -1
    }

    handleOpenEntryClick = async (e: any) => {
        const timeTxNum = e.target?.dataset?.txnum
        let success = true
        const cannotEditMsg =
            'This Time Entry cannot be edited since it has been included on a Client Invoice!'
        e.preventDefault()

        if (timeTxNum) {
            const timeEntry = this.viewModel.timeEntryList.find(
                (t) => t.txnum === Number(timeTxNum)
            )

            // Set non-nullable properties to 0
            const timeEntryDto = {
                ...timeEntry,
                rate: timeEntry?.rate ?? 0,
                rateperhour: timeEntry?.rateperhour ?? 0,
                cost: timeEntry?.cost ?? 0,
                costperhour: timeEntry?.costperhour ?? 0,
            }

            try {
                const tryResult: any = await this.api.postTimeEntryAction({
                    ...timeEntryDto,
                    action: 'TryUpdate',
                    timeTxNum,
                })

                if (tryResult && this.isCannotBeEdited(tryResult?.message)) {
                    displayAlertError(cannotEditMsg)
                    success = false
                }
            } catch (error) {
                displayAlertError(getErrorMessage(error))
                success = false
            }

            e.preventDefault()
        }

        const { href, pathname } = e.target
        if (href && success) {
            this.props.navigate(pathname)
        }
    }

    getFormattedPrice = (price: number) =>
        price !== Math.floor(price) ? price?.toFixed(2) : price

    renderDateRangePicker(key: string) {
        return (
            <DateRangePicker
                style={{
                    minWidth: '200px',
                }}
                placement="auto"
                placeholder="Select date"
                format="MM/dd/yyyy"
                onChange={this.handleDateChange.bind(this, key)}
                onClean={this.handleDateChange.bind(this, key)}
                ranges={[
                    {
                        label: 'today',
                        value: [startOfDay(new Date()), endOfDay(new Date())],
                    },
                    {
                        label: 'yesterday',
                        value: [
                            startOfDay(addDays(new Date(), -1)),
                            endOfDay(addDays(new Date(), -1)),
                        ],
                    },
                    {
                        label: 'last7Days',
                        value: [
                            startOfDay(subDays(new Date(), 6)),
                            endOfDay(new Date()),
                        ],
                    },
                    {
                        label: 'Last 30 Days',
                        value: [
                            startOfDay(subDays(new Date(), 30)),
                            endOfDay(new Date()),
                        ],
                    },
                    {
                        label: 'This month',
                        value: [
                            startOfDay(
                                new Date(
                                    getYear(new Date()),
                                    getMonth(new Date()),
                                    1
                                )
                            ),
                            endOfDay(lastDayOfMonth(new Date())),
                        ],
                    },
                    {
                        label: 'Last month',
                        value: [
                            startOfDay(
                                new Date(
                                    getYear(new Date()),
                                    getMonth(new Date()) - 1,
                                    1
                                )
                            ),
                            endOfDay(
                                lastDayOfMonth(
                                    new Date(
                                        getYear(new Date()),
                                        getMonth(new Date()) - 1,
                                        1
                                    )
                                )
                            ),
                        ],
                    },
                ]}
            />
        )
    }

    render(): JSX.Element {
        return (
            <>
                {this.renderHeader()}
                {renderSecureContent(
                    <div className="content-padding has-action-bar sticky-container">
                        <Container fluid className="px-0">
                            <Row>
                                <Col sm="12">
                                    <TimeEntryFilter
                                        onFilterChange={this.onFilterChange.bind(
                                            this
                                        )}
                                        model={this.timeEntryAddEditModel}
                                        onShowSearch={
                                            this.handleShowTableSearch
                                        }
                                        showTableSearch={
                                            this.state.showTableSearch
                                        }
                                        disabledDelete={
                                            this.state.timeEntryIdSelected ===
                                            null
                                        }
                                        onDeleteClick={this.handleTryDelete.bind(
                                            this
                                        )}
                                        billable={this.viewModel.billable}
                                        nonBillable={this.viewModel.nonBillable}
                                        total={this.viewModel.total}
                                        entrydate={this.state.filters.entrydate}
                                        proj={this.state.filters.proj}
                                        employee={this.state.filters.employee}
                                    />
                                    <div className="table-gradient table-scrolled-gradient">
                                        <Table
                                            striped
                                            responsive
                                            className="a-table table-fixed"
                                        >
                                            <thead>
                                                <tr className="a-table-heading">
                                                    <th>
                                                        <div className="d-flex justify-content-center">
                                                            <Form.Check
                                                                inline
                                                                label=""
                                                                name={`inline-check-th-0`}
                                                                type="checkbox"
                                                                data-id={`th-0`}
                                                                id={`inline-check-th-0`}
                                                                disabled
                                                                defaultChecked={
                                                                    false
                                                                }
                                                            />
                                                        </div>
                                                    </th>
                                                    <th className="mw-130px">
                                                        <span
                                                            className={this.sortClass(
                                                                'entrydate'
                                                            )}
                                                            data-field="entrydate"
                                                        >
                                                            Entry Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'employee'
                                                            )}
                                                            data-field="employee"
                                                        >
                                                            Employee
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'proj'
                                                            )}
                                                            data-field="proj"
                                                        >
                                                            Project
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'item'
                                                            )}
                                                            data-field="item"
                                                        >
                                                            Item
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'activity'
                                                            )}
                                                            data-field="activity"
                                                        >
                                                            Activity
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'hours'
                                                            )}
                                                            data-field="hours"
                                                        >
                                                            Hours/Time
                                                        </span>
                                                    </th>
                                                    {renderSecureHideContent(
                                                        <>
                                                            <th>
                                                                <span
                                                                    className={this.sortClass(
                                                                        'rateperhour'
                                                                    )}
                                                                    data-field="rateperhour"
                                                                >
                                                                    Rate/Hr
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span>
                                                                    Price
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span
                                                                    className={this.sortClass(
                                                                        'costperhour'
                                                                    )}
                                                                    data-field="costperhour"
                                                                >
                                                                    Cost/Hr
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span
                                                                    className={this.sortClass(
                                                                        'cost'
                                                                    )}
                                                                    data-field="cost"
                                                                >
                                                                    Cost
                                                                </span>
                                                            </th>
                                                        </>
                                                    )(
                                                        15,
                                                        SECURITY_ATTRIBUTE_TYPES.DenySpec1
                                                    )}
                                                    <th className="mw-250px">
                                                        <span
                                                            className={this.sortClass(
                                                                'desc'
                                                            )}
                                                            data-field="desc"
                                                        >
                                                            Description
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'nonbillable'
                                                            )}
                                                            data-field="nonbillable"
                                                        >
                                                            Non-Billable
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'taxable'
                                                            )}
                                                            data-field="taxable"
                                                        >
                                                            Taxable
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span
                                                            className={this.sortClass(
                                                                'latestInvDt'
                                                            )}
                                                            data-field="latestInvDt"
                                                        >
                                                            Client Invoice Date
                                                        </span>
                                                    </th>
                                                </tr>
                                                <tr
                                                    className={`a-table-search-fields ${
                                                        this.state
                                                            .showTableSearch
                                                            ? ''
                                                            : 'd-none'
                                                    }`}
                                                >
                                                    <th></th>
                                                    <th>
                                                        <DateRangePicker
                                                            style={{
                                                                minWidth:
                                                                    '200px',
                                                            }}
                                                            placement="auto"
                                                            placeholder="Select date"
                                                            format="MM/dd/yyyy"
                                                            defaultValue={this.fs.getValue(
                                                                'entrydate'
                                                            )}
                                                            onChange={this.handleDateChange.bind(
                                                                this,
                                                                'entrydate'
                                                            )}
                                                            onClean={this.handleDateChange.bind(
                                                                this,
                                                                'entrydate'
                                                            )}
                                                            ranges={[
                                                                {
                                                                    label: 'today',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date()
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'yesterday',
                                                                    value: [
                                                                        startOfDay(
                                                                            addDays(
                                                                                new Date(),
                                                                                -1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            addDays(
                                                                                new Date(),
                                                                                -1
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'last7Days',
                                                                    value: [
                                                                        startOfDay(
                                                                            subDays(
                                                                                new Date(),
                                                                                6
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Last 30 Days',
                                                                    value: [
                                                                        startOfDay(
                                                                            subDays(
                                                                                new Date(),
                                                                                30
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            new Date()
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'This month',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ),
                                                                                1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            lastDayOfMonth(
                                                                                new Date()
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Last month',
                                                                    value: [
                                                                        startOfDay(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ) -
                                                                                    1,
                                                                                1
                                                                            )
                                                                        ),
                                                                        endOfDay(
                                                                            lastDayOfMonth(
                                                                                new Date(
                                                                                    getYear(
                                                                                        new Date()
                                                                                    ),
                                                                                    getMonth(
                                                                                        new Date()
                                                                                    ) -
                                                                                        1,
                                                                                    1
                                                                                )
                                                                            )
                                                                        ),
                                                                    ],
                                                                },
                                                            ]}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="employee"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'employee'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="proj"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'proj'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="item"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'item'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="activity"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'activity'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="hours"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'hours'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="rateperhour"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'rateperhour'
                                                            )}
                                                        />
                                                    </th>
                                                    <th></th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="costperhour"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'costperhour'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="cost"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'cost'
                                                            )}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="desc"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'desc'
                                                            )}
                                                        />
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>
                                                        <Form.Control
                                                            type="text"
                                                            data-field="latestInvDt"
                                                            data-type="number"
                                                            onChange={
                                                                this
                                                                    .handleSearch
                                                            }
                                                            defaultValue={this.fs.getValue(
                                                                'latestInvDt'
                                                            )}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                id={'table-scroll'}
                                                style={{
                                                    height: this.state
                                                        .tableHeight,
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {!this.viewModel.isLoaded
                                                    ? showLoading()
                                                    : (this.viewModel
                                                          .timeEntryList
                                                          .length === 0 &&
                                                          isFiltered(
                                                              this.state
                                                                  .searchProperties
                                                          )) ||
                                                      this.viewModel
                                                          .timeEntryList
                                                          .length === 0
                                                    ? showEmpty()
                                                    : this.viewModel.timeEntryList
                                                          .slice(
                                                              0,
                                                              this.state
                                                                  .pageSize
                                                          )
                                                          .map(
                                                              (
                                                                  item: any,
                                                                  i: number
                                                              ) => (
                                                                  <tr
                                                                      key={i}
                                                                      data-key={
                                                                          i
                                                                      }
                                                                  >
                                                                      <td>
                                                                          <div className="d-flex justify-content-center">
                                                                              <Form.Check
                                                                                  label=""
                                                                                  name={
                                                                                      `group-` +
                                                                                      i
                                                                                  }
                                                                                  type="checkbox"
                                                                                  id={
                                                                                      item.txnum
                                                                                  }
                                                                                  className="chk-projectview-items-item"
                                                                                  checked={
                                                                                      this
                                                                                          .state
                                                                                          .timeEntryIdSelected ===
                                                                                      Number(
                                                                                          item.txnum
                                                                                      )
                                                                                  }
                                                                                  onChange={({
                                                                                      currentTarget:
                                                                                          {
                                                                                              checked,
                                                                                          },
                                                                                  }) => {
                                                                                      this.handleCheck(
                                                                                          item.txnum,
                                                                                          checked
                                                                                      )
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <Link
                                                                              to={routeParam(
                                                                                  URI
                                                                                      .timeEntry
                                                                                      .edit,
                                                                                  {
                                                                                      id: item.txnum,
                                                                                  }
                                                                              )}
                                                                              data-txnum={
                                                                                  item.txnum
                                                                              }
                                                                              className="text-charcoal hover-view-icon"
                                                                              onClick={
                                                                                  this
                                                                                      .handleOpenEntryClick
                                                                              }
                                                                          >
                                                                              {formatDate(
                                                                                  item.entrydate
                                                                              )}
                                                                          </Link>
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              item.timeEmployee
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              item.proj
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              item.item
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              item.activity
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {this.getFormattedPrice(
                                                                              item.hours
                                                                          )}
                                                                      </td>
                                                                      {renderSecureHideContent(
                                                                          <>
                                                                              <td>
                                                                                  {
                                                                                      item.rateperhour
                                                                                  }
                                                                              </td>
                                                                              <td>
                                                                                  {Math.ceil(
                                                                                      item.hours *
                                                                                          item.rateperhour
                                                                                  )}
                                                                              </td>
                                                                              <td>
                                                                                  {
                                                                                      item.costperhour
                                                                                  }
                                                                              </td>
                                                                              <td>
                                                                                  {
                                                                                      item.cost
                                                                                  }
                                                                              </td>
                                                                          </>
                                                                      )(
                                                                          15,
                                                                          SECURITY_ATTRIBUTE_TYPES.DenySpec1
                                                                      )}
                                                                      <td>
                                                                          {
                                                                              item.desc
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {renderBooleanToYesOrNo(
                                                                              item.nonbillable
                                                                          )}
                                                                      </td>
                                                                      <td>
                                                                          {renderBooleanToYesOrNo(
                                                                              item.taxable
                                                                          )}
                                                                      </td>
                                                                      <td>
                                                                          {formatDate(
                                                                              item.latestInvDt
                                                                          )}
                                                                      </td>
                                                                  </tr>
                                                              )
                                                          )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        {this.viewModel.timeEntryList.length > 0 && (
                            <Pagination
                                onPageSizeChanged={this.onPageSizeChanged}
                                onPageChanged={this.onPageChanged}
                                hasPreviousPage={this.state.page > 1}
                                hasNextPage={
                                    this.viewModel.timeEntryList.length >
                                    this.state.pageSize
                                }
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                            />
                        )}
                    </div>
                )(15, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}

                {this.state.showConfirmationModal && (
                    <ConfirmationModal
                        show={true}
                        title="Delete Confirm"
                        message="This Time Entry will be permanently deleted. Are you sure you want to continue?"
                        confirmAction={this.deleteTimeEntry.bind(this)}
                        toggleModal={() =>
                            this.setState({ showConfirmationModal: false })
                        }
                    />
                )}
            </>
        )
    }
}

export default WithRouter(observer(TimeEntryListView))
