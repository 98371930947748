import Spinner from 'app/components/help/Spinner'
import useGetPaymentProcessorInfo from 'lib/api/hooks/useGetPaymentProcessorStatus'
import useStartPaymentProcessorSession from 'lib/api/hooks/useStartPaymentProcessorSession'
import {
    OnboardingStatus,
    RainForestOnboardingStatus,
} from 'lib/api/types/PaymentProcessorResponse'
import React from 'react'
import OnboardMerchantComponent from './OnboardingComponent'
import { PaymentSessionType } from 'lib/api/types/PaymentSessionType'

const OnboardedMessageComponent = () => {
    const { data: sessionInfo, isLoading } = useStartPaymentProcessorSession(
        PaymentSessionType.ONBOARDING
    )

    const { data } = useGetPaymentProcessorInfo('Rainforest')

    if (isLoading) {
        return <Spinner isChild />
    }

    if (data?.onboardingStatus === OnboardingStatus.DetailsSubmitted) {
        if (
            sessionInfo?.merchantApplicationStatus ===
            RainForestOnboardingStatus.NeedsInformation
        ) {
            return (
                <OnboardMerchantComponent
                    sessionKey={sessionInfo.sessionKey}
                    merchantApplicationId={sessionInfo.merchantApplicationId}
                    merchantId={sessionInfo.merchantId}
                />
            )
        }
        return (
            <p className="tw-pl-10">
                Your Design Pay account registration is in progress. This
                process may take a few days. Check back here soon for an update.
            </p>
        )
    }

    if (data?.onboardingStatus === OnboardingStatus.Failed) {
        return (
            <p className="tw-pl-10">
                Your Design Pay registration has encountered an issue. Please{' '}
                <a href="mailto:Support@designmanager.com?subject=Design Pay Registration Issue">
                    {' '}
                    contact support
                </a>{' '}
                for help.
            </p>
        )
    }
    return null
}

OnboardedMessageComponent.displayName = 'OnboardedMessageComponent'

export default OnboardedMessageComponent
