import React, { Fragment, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ConfirmationModal from 'app/components/modal/ConfirmationModal'
import InventoryItemForm from '../forms/InventoryItemForm/InventoryItemForm'
import { InventoryTypes } from '../../enums/inventoryTypes/inventoryTypes'
import { displayAlert } from '../../../utilities/Response'
import { AlertTypes } from '../../enums/alertTypes/alertTypes'
import usePageReload from 'lib/api/hooks/usePageReload'

interface IAddDropdownProps {
    locationQuery: string
    projectId: string
    type: InventoryTypes
    itemId?: string
}

interface IResponseMessage {
    message?: string
    type?: AlertTypes
}

const modalItemTitle = 'Add Item from Inventory'
const modalComponentTitle = 'Add new Component from Inventory'

const AddDropdown = ({
    locationQuery,
    projectId,
    type,
    itemId,
}: IAddDropdownProps) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const reloadPage = usePageReload()

    const title =
        type === InventoryTypes.Item ? modalItemTitle : modalComponentTitle

    const handleCloseModal = (response?: IResponseMessage) => {
        if (response?.message) {
            displayAlert(response?.type, response?.message, 7000)
            if (response?.type === AlertTypes.Success) {
                reloadPage()
            }
        }
        setShowConfirmationModal(false)
    }

    return (
        <Fragment>
            <Dropdown className="d-inline">
                <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    variant="light"
                    size="sm"
                >
                    Add From
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => setShowConfirmationModal(true)}
                    >
                        Inventory
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmationModal
                show={showConfirmationModal}
                title={title}
                okBtnStyle="primary"
                footer={false}
                size="lg"
            >
                <InventoryItemForm
                    closeModal={handleCloseModal}
                    locationQuery={locationQuery}
                    projectId={projectId}
                    type={type}
                    itemId={itemId}
                />
            </ConfirmationModal>
        </Fragment>
    )
}

AddDropdown.displayName = 'AddDropdown'

export default AddDropdown
