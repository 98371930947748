import { CompNumericPercentType } from 'lib/api/types/CompPercentType'
import { Component } from 'lib/api/types/Component'
import { TFormValues } from '../types/TFormValues'

const normalizeRecalculateDataPayload = (values: TFormValues | Component) => {
    const {
        supplier,
        usedisc,
        feecost,
        feeunitcost,
        estprice,
        useterms,
        estdisc,
        list,
        unitlist,
        bterm1,
        markup,
        supdep,
        estmarkup,
        estcost,
        estunitcost,
        comptype,
        quantity,
        bterm2,
        bterm3,
        taxable,
    } = values

    const compPercentType = usedisc
    const base = {
        supplier,
        comptype,
        supdep,
        quantity,
        usedisc,
        useterms,
        markup,
        bterm1,
        bterm2,
        bterm3,
        taxable,
        estprice,
    }

    if (compPercentType === CompNumericPercentType.Fee) {
        return {
            ...base,
            feecost,
            estmarkup,
            feeunitcost,
        }
    } else if (compPercentType === CompNumericPercentType.Markup) {
        return {
            ...base,
            estcost,
            estmarkup,
            estunitcost,
        }
    }

    return {
        ...base,
        estcost,
        estdisc,
        estunitcost,
        list,
        unitlist,
    }
}
export default normalizeRecalculateDataPayload
