import { ApiService } from '../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import { displayAlertError, getErrorMessage } from '../../../utilities/Response'

const api = new ApiService()

// Expire in 30mins
const DMStorageExpire: number = 1000 * 60 * 30
const DMStorage = {
    /**
     * Get all active Projects
     *
     * @returns projects: <array[]>, options<array[]> - can be used in select option
     */
    async getAllProjects() {
        let projects = getLocalStorage('DM_ALL_PROJECTS', true) ?? []
        let expireIn = getLocalStorage('DM_ALL_PROJECTS_EXPIRE') ?? 0
        let selectOptions =
            getLocalStorage('DM_ALL_PROJECTS_OPTIONS', true) ?? []

        const now = new Date()

        if (!projects.length || now.getTime() > expireIn) {
            expireIn = now.getTime() + DMStorageExpire
            try {
                projects = await api.get(
                    'projects',
                    '?$filter=order eq false and closeddt eq null'
                )
            } catch (error) {
                displayAlertError(getErrorMessage(error))
            }

            selectOptions = []
            for (const proj of projects) {
                const projName = `${proj.projn} [${proj.proj}]`
                selectOptions.push({
                    value: proj.id,
                    label: projName,
                    code: proj.proj,
                })
            }

            setLocalStorage('DM_ALL_PROJECTS', projects, true)
            setLocalStorage('DM_ALL_PROJECTS_OPTIONS', selectOptions, true)
            setLocalStorage('DM_ALL_PROJECTS_EXPIRE', expireIn)
        }

        return {
            projects,
            options: selectOptions,
        }
    },
}

export default DMStorage
