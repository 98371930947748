import React from 'react'
import { Col, Row, Form, Container, Breadcrumb } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { routeParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import { ApiService } from '../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import { getSaveButtonName } from '../../../utilities/String'
import { displayAlert, handleResponseAlert } from '../../../utilities/Response'
import { setLocalStorageKeyValueObject } from '../../../utilities/FormEvent'
import Select from 'react-select'
import { delay } from 'lodash'
import MSG from '../../../defaults/Message'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import { nameToCode, formatCode } from 'app/utils/codes'

class GlossaryAddSalesCategory extends ThreadComponent {
    constructor(props) {
        super(props)

        this.isEditing = Boolean(this.props.params.id)

        this.state = {
            company: '',
            data: {
                scat: '',
                scatn: '',
            },
            dataIsLoaded: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
            accounts: [],
            companyDefaults: {},
            selectedAccounts: {
                cmacct: { isCompanyDefault: true, account: {} },
                cfacct: { isCompanyDefault: true, account: {} },
                clacct: { isCompanyDefault: true, account: {} },
                cdacct: { isCompanyDefault: true, account: {} },
                ciacct: { isCompanyDefault: true, account: {} },
                coacct: { isCompanyDefault: true, account: {} },
                smacct: { isCompanyDefault: true, account: {} },
                sfacct: { isCompanyDefault: true, account: {} },
                slacct: { isCompanyDefault: true, account: {} },
                sdacct: { isCompanyDefault: true, account: {} },
                siacct: { isCompanyDefault: true, account: {} },
                soacct: { isCompanyDefault: true, account: {} },
            },
            macct: !this.isEditing,
            facct: !this.isEditing,
            lacct: !this.isEditing,
            dacct: !this.isEditing,
            iacct: !this.isEditing,
            oacct: !this.isEditing,
        }
        this.api = new ApiService()

        this.newCat = {
            cmacct: null,
            cfacct: null,
            clacct: null,
            cdacct: null,
            ciacct: null,
            coacct: null,
            smacct: null,
            sfacct: null,
            slacct: null,
            sdacct: null,
            siacct: null,
            soacct: null,
            scat: null,
            scatn: null,
            budgettype: 0,
            id: 0,
        }

        this.accounts = ['macct', 'facct', 'lacct', 'dacct', 'iacct', 'oacct']
    }

    async componentDidMount() {
        this.setTitle('Add Sales Category')
        this.setFormAction(true)
        this.dMloader(true)
        const additionalData = await this.getAdditionalData()
        let data = this.newCat
        if (this.props.params.id) {
            data = await this.api.get(
                'salescategories',
                `?$filter=id eq ${this.props.params.id}`
            )

            if (data) {
                data = data[0]
                Object.keys(data).forEach((key) => {
                    if (!data[key]) {
                        data[key] = additionalData.companyDefaults[key]
                    }
                })

                this.setState({
                    data: data,
                })
                this.setSelectedValues(data, additionalData)

                this.setTitle(`Edit ${data.scatn || data.scat}`)
            }
        } else {
            const { selectedAccounts } = this.state

            this.accounts.forEach((account) => {
                selectedAccounts[`s${account}`].account = this.getDefaultValue(
                    additionalData.companyDefaults[`s${account}`],
                    additionalData.accounts
                )

                selectedAccounts[`c${account}`].account = this.getDefaultValue(
                    additionalData.companyDefaults[`c${account}`],
                    additionalData.accounts
                )

                data[`s${account}`] =
                    selectedAccounts[`s${account}`].account.value
                data[`c${account}`] =
                    selectedAccounts[`c${account}`].account.value
            })

            this.setState({ selectedAccounts })
        }

        setLocalStorage('glossarySalesCategory', data, true)
        setTimeout(() => {
            this.setState({
                showButtons: true,
                dataIsLoaded: true,
            })
            this.dMloader(false)
        }, 250)
    }

    setCheckState(data) {
        const state = this.state

        this.accounts.forEach((account) => {
            state.selectedAccounts[`s${account}`].account =
                this.getDefaultValue(
                    data[`s${account}`]
                        ? data[`s${account}`]
                        : state.companyDefaults[`s${account}`]
                )

            state.selectedAccounts[`c${account}`].account =
                this.getDefaultValue(
                    data[`c${account}`]
                        ? data[`c${account}`]
                        : state.companyDefaults[`c${account}`]
                )

            state[account] =
                state.companyDefaults[`s${account}`] ===
                    state.selectedAccounts[`s${account}`].account.value &&
                state.companyDefaults[`c${account}`] ===
                    state.selectedAccounts[`c${account}`].account.value
        })

        this.setState({
            state,
        })
    }

    setSelectedValues(data) {
        let { selectedAccounts } = this.state
        for (const key in selectedAccounts) {
            if (data && data.hasOwnProperty(key)) {
                selectedAccounts[key].account = this.getDefaultValue(data[key])
            }
        }

        this.setState({
            selectedAccounts,
        })

        this.setCheckState(data)
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    async getAdditionalData(data) {
        const glAccounts = await this.api.getGLAccounts()

        let accounts = []
        for (let account of glAccounts) {
            const accountTmp = {
                value: account.account,
                label: `${account.accountn} - ${account.account}`,
            }
            accounts.push(accountTmp)
        }

        accounts.sort()

        const defaults = await this.api.get('company', '')

        const state = {
            accounts,
            company: defaults,
            companyDefaults: {
                smacct: defaults.samercacct,
                cmacct: defaults.comercacct,
                sfacct: defaults.safreightacct,
                cdacct: defaults.codesignacct,
                sdacct: defaults.sadesignacct,
                cfacct: defaults.cofreightacct,
                siacct: defaults.sainstallacct,
                ciacct: defaults.coinstallacct,
                slacct: defaults.salaboracct,
                clacct: defaults.colaboracct,
                soacct: defaults.saotheracct,
                coacct: defaults.cootheracct,
            },
        }

        this.setState(state)

        return state
    }

    getDefaultValue(value, accounts) {
        let selectedAccount = (accounts ?? this.state.accounts).find(
            (acc) => acc.value === value
        )

        return selectedAccount ? selectedAccount : {}
    }

    handleChange = (e) => {
        this.setDraft()
        const { name } = e.target
        let { value } = e.target

        if (!this.isEditing) {
            if (name === 'scatn') {
                setLocalStorageKeyValueObject(
                    'glossarySalesCategory',
                    'scat',
                    nameToCode(value)
                )
                this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        scat: nameToCode(value),
                    },
                }))
            } else if (name === 'scat') {
                value = formatCode(value)
            }
        }
        setLocalStorageKeyValueObject('glossarySalesCategory', name, value)
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [name]: value,
            },
        }))
    }

    handleSelectChange = (e, select) => {
        this.setDraft()
        let { selectedAccounts } = this.state
        selectedAccounts[select.name].account = e

        setLocalStorageKeyValueObject(
            'glossarySalesCategory',
            select.name,
            e.value
        )
        this.setState({
            selectedAccounts,
        })
    }

    handleCheck = (e) => {
        this.setDraft()
        const name = e.target.name
        const isChecked = e.target.checked

        let { selectedAccounts } = this.state
        selectedAccounts[`s${name}`].isCompanyDefault = isChecked
        selectedAccounts[`c${name}`].isCompanyDefault = isChecked

        if (isChecked) {
            selectedAccounts[`s${name}`].account = this.getDefaultValue(
                this.state.companyDefaults[`s${name}`]
            )
            selectedAccounts[`c${name}`].account = this.getDefaultValue(
                this.state.companyDefaults[`c${name}`]
            )

            setLocalStorageKeyValueObject(
                'glossarySalesCategory',
                `s${name}`,
                selectedAccounts[`s${name}`].account.value
            )
            setLocalStorageKeyValueObject(
                'glossarySalesCategory',
                `c${name}`,
                selectedAccounts[`c${name}`].account.value
            )
        } else {
            selectedAccounts[`s${name}`].account = {}
            selectedAccounts[`c${name}`].account = {}

            setLocalStorageKeyValueObject(
                'glossarySalesCategory',
                `s${name}`,
                null
            )
            setLocalStorageKeyValueObject(
                'glossarySalesCategory',
                `c${name}`,
                null
            )
        }

        this.setState({
            selectedAccounts,
            [name]: isChecked,
        })
    }

    handleClickSave = (e) => {
        this.setIsLoading(true)
        setLocalStorage('isDraft', 'false')
        window.onbeforeunload = null

        this.state.data.id ? this.handleUpdate(e) : this.handleCreate(e)
    }

    handleUpdate(e) {
        if (this.state.data && this.state.data.id) {
            const data = getLocalStorage('glossarySalesCategory', true) || `{}`

            const { macct, facct, dacct, iacct, lacct, oacct } = this.state

            if (!this.validate(data)) {
                this.setIsLoading(false)
                displayAlert('danger', "All fields except 'Name' are required")
                return
            }

            if (macct) {
                data.smacct = ''
                data.cmacct = ''
            }

            if (facct) {
                data.sfacct = ''
                data.cfacct = ''
            }

            if (dacct) {
                data.sdacct = ''
                data.cdacct = ''
            }

            if (iacct) {
                data.siacct = ''
                data.ciacct = ''
            }

            if (lacct) {
                data.slacct = ''
                data.clacct = ''
            }

            if (oacct) {
                data.soacct = ''
                data.coacct = ''
            }

            const request = this.api.patch(
                'salescategories',
                this.state.data.id,
                data
            )
            handleResponseAlert(request, (res) => {
                this.setIsLoading(false)

                if (res && !isNaN(res.id)) {
                    delay(() => {
                        this.navigate(
                            routeParam(URI.glossary.list, {
                                path: 'sales-categories',
                            })
                        )
                    }, 1500)
                }
            })
        }
    }

    handleCreate(e) {
        const data = getLocalStorage('glossarySalesCategory', true) || `{}`
        if (!this.validate(data)) {
            this.setIsLoading(false)
            displayAlert('danger', "All fields except 'Name' are required")

            return
        }

        const request = this.api.postJson('salescategories', data)
        handleResponseAlert(
            request,
            (res) => {
                this.setIsLoading(false)

                if (res && !isNaN(res.id)) {
                    delay(() => {
                        e.target.name === 'saveNew'
                            ? window.location.reload(false)
                            : this.navigate(
                                  routeParam(URI.glossary.list, {
                                      path: 'sales-categories',
                                  })
                              )
                    }, 1500)
                }
            },
            null,
            null,
            MSG.success.create.GlossarySC,
            null,
            MSG.loading.create.GlossarySC
        )
    }

    validate(data) {
        return ![
            data.cmacct,
            data.cfacct,
            data.clacct,
            data.cdacct,
            data.ciacct,
            data.coacct,
            data.smacct,
            data.sfacct,
            data.slacct,
            data.sdacct,
            data.siacct,
            data.soacct,
        ].some((element) => [undefined, null, ''].includes(element))
    }

    renderHeader() {
        const { isLoading, isDraft } = this.state

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={routeParam(URI.glossary.list, {
                            path: 'sales-categories',
                        })}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.settings.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.glossary.list, {
                                    path: 'sales-categories',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Sales Category
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.glossary.add.salesCategory }}
                            linkAs={Link}
                        >
                            {this.title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>{this.title}</HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <SecureBootstrapButton
                            attributeNo={44}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            className={
                                this.props.params.id
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </SecureBootstrapButton>

                        <SecureBootstrapButton
                            attributeNo={44}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </SecureBootstrapButton>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    getAccountTitle(title) {
        if (title) {
            return `${title} Accounts`
        }
        return ''
    }

    renderContent() {
        const { data, company } = this.state
        return (
            <Container className="ms-0">
                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    placeholder="Please enter"
                                    value={data?.scatn}
                                    name="scatn"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Category Code
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            name="scat"
                                            maxLength={5}
                                            value={data?.scat}
                                            readOnly={this.isEditing}
                                            disabled={this.isEditing}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewm
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="macct"
                                    type="checkbox"
                                    id={`inline-ma-company-default`}
                                    checked={this.state.macct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .smacct.account
                                            }
                                            isDisabled={this.state.macct}
                                            name="smacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .cmacct.account
                                            }
                                            isDisabled={this.state.macct}
                                            name="cmacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewd
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="dacct"
                                    type="checkbox"
                                    id={`inline-df-company-default`}
                                    checked={this.state.dacct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .sdacct.account
                                            }
                                            isDisabled={this.state.dacct}
                                            name="sdacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .cdacct.account
                                            }
                                            isDisabled={this.state.dacct}
                                            name="cdacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewf
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="facct"
                                    type="checkbox"
                                    id={`inline-fa-company-default`}
                                    checked={this.state.facct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .sfacct.account
                                            }
                                            isDisabled={this.state.facct}
                                            name="sfacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .cfacct.account
                                            }
                                            isDisabled={this.state.facct}
                                            name="cfacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewi
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="iacct"
                                    type="checkbox"
                                    id={`inline-ia-company-default`}
                                    checked={this.state.iacct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .siacct.account
                                            }
                                            isDisabled={this.state.iacct}
                                            name="siacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .ciacct.account
                                            }
                                            isDisabled={this.state.iacct}
                                            name="ciacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewl
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="lacct"
                                    type="checkbox"
                                    id={`inline-ca-company-default`}
                                    checked={this.state.lacct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .slacct.account
                                            }
                                            isDisabled={this.state.lacct}
                                            name="slacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .clacct.account
                                            }
                                            isDisabled={this.state.lacct}
                                            name="clacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        {this.getAccountTitle(
                                            company?.compviewo
                                        )}
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="pt-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end"></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Company Default"
                                    name="oacct"
                                    type="checkbox"
                                    id={`inline-ta-company-default`}
                                    checked={this.state.oacct}
                                    onChange={this.handleCheck}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sales</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .soacct.account
                                            }
                                            isDisabled={this.state.oacct}
                                            name="soacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">COGS</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Select
                                            options={this.state.accounts}
                                            className="react-select"
                                            onChange={this.handleSelectChange}
                                            value={
                                                this.state.selectedAccounts
                                                    .coacct.account
                                            }
                                            isDisabled={this.state.oacct}
                                            name="coacct"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <SecureBootstrapButton
                        attributeNo={44}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        size="lg"
                        name="save"
                        onClick={this.handleClickSave.bind(this)}
                        disabled={!isDraft || isLoading}
                    >
                        {getSaveButtonName(isLoading, 'Save')}
                    </SecureBootstrapButton>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(GlossaryAddSalesCategory)
