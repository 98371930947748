import React from 'react'
import { Container, Navbar } from 'react-bootstrap'

const ImpersonationBanner = ({ userName }) => {
    return (
        <Navbar className="impersonation-banner">
            <Container className="justify-content-center">
                <Navbar.Brand>
                    Currently impersonating user: {userName}
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export default ImpersonationBanner
