export function getLocalStorage(key: string, isObject = false): any {
    return isObject
        ? JSON.parse(window.localStorage.getItem(key) || '{}')
        : window.localStorage.getItem(key)
}

export function setLocalStorage(key: string, value: any, isObject = false) {
    window.localStorage.setItem(key, isObject ? JSON.stringify(value) : value)
}

export function setLocalStorageValue(objn: string, name: string, val: any) {
    const data = getLocalStorage(objn, true) || `{}`

    data[name] = val
    setLocalStorage(objn, data, true)
}
