import React from 'react'
import { Col, Row, Form, Container, Breadcrumb, Alert } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { routeParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import Select from 'react-select'
import { ApiService } from '../../../lib/api/HttpService'
import { setLocalStorage } from '../../../utilities/LocalStorage'
import {
    displayAlert,
    displayAlertLoader,
    getErrorMessage,
} from '../../../utilities/Response'
import { GlossaryAddShipToCodesValidation } from './validations/GlossaryAddShipToCodesValidation'
import { validateInput } from '../../../app/components/validators/Helpers'
import { toSortName } from '../../../utilities/String'
import { delay } from 'lodash'
import MSG from '../../../defaults/Message'
import InputMasked from '../../../app/components/form/MaskedInput'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import { nameToCode, formatCode } from 'app/utils/codes'

class GlossaryAddShipToCodes extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            dataIsLoaded: false,
            sortTypes: [
                { value: 0, label: 'Name' },
                { value: 1, label: 'Company' },
                { value: 2, label: 'Manual' },
            ],
            addressesTypes: [],
            inactive: false,
            warehouse: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
            defaultType: undefined,
        }

        this.api = new ApiService()
        this.updatedData = {}
        this.isEditing = Boolean(this.props.params.id)
    }

    componentInit() {
        this.setTitle('Add Ship To Code')
        this.setFormAction(true)
    }

    async componentDidMount() {
        this.dMloader(true)
        this.setState(
            {
                addressesTypes: await this.getAddressesTypes(),
            },
            async () => {
                await this.fetchAddressIfEditing()

                delay(() => {
                    this.setState({
                        showButtons: true,
                    })
                }, 250)
                this.dMloader(false)
            }
        )
    }

    async fetchAddressIfEditing() {
        if (!this.isEditing) {
            return
        }

        const data = await this.api.get(
            'addresses',
            '?$filter=id eq ' + this.props.params.id
        )

        if (!data.length) {
            this.setState({
                dataIsLoaded: true,
            })

            return
        }

        this.setState({
            data: data[0],
            dataIsLoaded: true,
            inactive: data[0].inactive,
            warehouse: data[0].warehouse,
            defaultType: this.state.addressesTypes.filter((type) => {
                return type.label === data[0].typedesc
            }),
        })
        this.setTitle(`Edit ${data[0].code}`)
    }

    async getAddressesTypes() {
        const addressesData = await this.api.get('addresses')
        const addressesTypes = []

        for (const addr of addressesData) {
            if (
                addr.addresstype === 1 &&
                addr.typedesc &&
                addr.typedesc.trim() !== ''
            ) {
                if (addressesTypes.indexOf(addr.typedesc.trim()) === -1)
                    addressesTypes.push(addr.typedesc.trim())
            }
        }

        return [
            ...[{ value: 'Custom', label: 'Custom' }],
            ...addressesTypes
                .map((x, index) => ({ value: index, label: x }))
                .sort((a, b) => {
                    return a.label.localeCompare(b.label)
                }),
        ]
    }

    handleChange = (e, meta = {}) => {
        let key, value
        const data = this.state.data
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.label
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }

            data[key] = value
            this.setState({ data })
        }

        if (e.target && e.target.dataset.field === 'attention') {
            const sortName = document.getElementById('sortName')
            if (
                this.defaultParseType().value === 0 ||
                this.defaultParseType().value === 1
            ) {
                sortName.value =
                    this.defaultParseType().value === 0
                        ? toSortName(value)
                        : value
                sortName?.setAttribute('readonly', 'readonly')
                data[key] = value
                this.setState({ data })
            }

            this.updatedData.sortname = sortName.value

            if (!this.isEditing) {
                const shipToCode = nameToCode(e.target.value)
                this.updatedData['code'] = shipToCode
                this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        code: shipToCode,
                    },
                }))
            }
        }

        this.updatedData[key] = value
        this.setDraft()
    }

    handleSortTypeChange = (select) => {
        const attention = document.getElementById('attention')
        const sortName = document.getElementById('sortName')
        if (select.value === 0 || select.value === 1) {
            sortName.value =
                select.value === 0
                    ? toSortName(attention.value)
                    : attention.value
            sortName?.setAttribute('readonly', 'readonly')
        } else {
            sortName?.removeAttribute('readonly')
        }

        this.updatedData['parsetype'] = select.value
        this.updatedData.sortname = sortName.value
        this.setDraft()
    }

    handleTypeChange = (e, meta = {}) => {
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            this.setState({
                defaultType: { value: e.value, label: e.label },
            })
        }

        this.handleChange(e, meta)
    }

    handleClickSave = async (e) => {
        e.preventDefault()

        this.setIsLoading(true)
        setLocalStorage('isDraft', 'false')
        window.onbeforeunload = null

        const validated = await validateInput(
            GlossaryAddShipToCodesValidation,
            this.updatedData
        )

        if (!validated.status) {
            try {
                displayAlertLoader(
                    this.isEditing
                        ? MSG.loading.update.msg
                        : MSG.loading.create.GlossarySTC
                )
                if (this.isEditing) {
                    await this.api.editAddress(
                        this.props.params.id,
                        this.updatedData
                    )
                } else {
                    // set the address type to default 1 since this is the address type used in ship to codes listing
                    this.updatedData.addresstype = 1
                    await this.api.createAddress(this.updatedData)
                }

                displayAlert(
                    'success',
                    this.isEditing
                        ? MSG.success.msg
                        : MSG.success.create.GlossarySTC
                )
                delay(() => {
                    this.setIsLoading(false)
                    e.target.name === 'saveNew'
                        ? window.location.reload(false)
                        : this.navigate(
                              routeParam(URI.glossary.list, {
                                  path: 'ship-to-codes',
                              })
                          )
                }, 1500)
            } catch (error) {
                this.setIsLoading(false)
                displayAlert(
                    'danger',
                    getErrorMessage(error, 'Ship to code could not be saved')
                )
            }
        } else {
            this.setIsLoading(false)
            displayAlert('danger', validated.message)
        }
    }

    defaultType() {
        if (
            !this.state.addressesTypes ||
            this.state.addressesTypes.length === 0
        ) {
            return { value: '', label: 'Please select' }
        }
        let existingType
        const selectedType = this.state.addressesTypes.filter((type) => {
            if (type.label === this.updatedData.typedesc) {
                return true
            }

            if (this.state.data.typedesc === type.label) {
                existingType = type
            }
        })[0]

        return (
            selectedType ??
            existingType ?? { value: '', label: 'Please select' }
        )
    }

    defaultParseType() {
        if (!this.state.sortTypes || this.state.sortTypes.length === 0) {
            return { value: '', label: 'Please select' }
        }
        let existingType
        const selectedType = this.state.sortTypes.filter((type) => {
            if (type.value === this.updatedData.parsetype) {
                return true
            }

            if (this.state.data.parsetype === type.value) {
                existingType = type
            }
        })[0]

        if (selectedType && selectedType.value === 0) {
            const attention = document.getElementById('attention')
            if (attention) {
                const sortName = document.getElementById('sortName')
                sortName.value = toSortName(attention.value)
                sortName.setAttribute('readonly', 'readonly')
            }
        }

        return selectedType ?? existingType ?? { value: 0, label: 'Name' }
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    renderHeader() {
        const { isLoading, isDraft } = this.state
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={routeParam(URI.glossary.list, {
                            path: 'ship-to-codes',
                        })}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.settings.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.glossary.list, {
                                    path: 'ship-to-codes',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Ship To Codes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{this.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>{this.title}</HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <SecureBootstrapButton
                            attributeNo={46}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            className={
                                this.isEditing ? 'd-none' : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </SecureBootstrapButton>

                        <SecureBootstrapButton
                            attributeNo={46}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </SecureBootstrapButton>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { data } = this.state
        return (
            <Container className="ms-0">
                <Row>
                    <Col>
                        <Alert
                            key={'form-errors'}
                            variant={'danger'}
                            id="validation-error-area"
                            style={{ display: 'none' }}
                        >
                            Please fix the following errors:
                            <br />
                            <li>
                                <span id="validationError"></span>
                            </li>
                        </Alert>
                    </Col>
                </Row>
                <Row xs={1} lg={2} className="pt-3">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-lg-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={40}
                                    placeholder="Please enter"
                                    id="attention"
                                    data-field="attention"
                                    value={data.attention}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Ship To Code
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row
                                    xs={1}
                                    lg={2}
                                    className="align-items-center"
                                >
                                    <Col className="mb-3 mb-lg-0">
                                        <Form.Control
                                            type="text"
                                            maxLength={5}
                                            placeholder="Please enter"
                                            data-field="code"
                                            value={data.code}
                                            disabled={this.isEditing}
                                            onChange={(e) => {
                                                e.target.value = formatCode(
                                                    e.target.value
                                                )

                                                this.handleChange(e)
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Warehouse/Showroom"
                                            name="checkbox-group-1"
                                            type="checkbox"
                                            className="me-3"
                                            data-field="warehouse"
                                            checked={data.warehouse}
                                            onChange={this.handleChange}
                                        />

                                        <Form.Check
                                            inline
                                            label="Inactive"
                                            name="checkbox-group-2"
                                            type="checkbox"
                                            data-field="inactive"
                                            checked={data.inactive}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Sort</Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={40}
                                            placeholder="Please enter"
                                            id="sortName"
                                            data-field="sortname"
                                            defaultValue={data.sortname}
                                            onChange={this.handleChange}
                                            readOnly={
                                                data.address?.parsetype === 0 ||
                                                data.address?.parsetype === 1
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Select
                                            key="parsetype"
                                            options={this.state.sortTypes}
                                            className="react-select"
                                            placeholder="Please select"
                                            name="parsetype"
                                            defaultValue={this.defaultParseType()}
                                            onChange={this.handleSortTypeChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Type</Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Select
                                            key="typedesc"
                                            options={this.state.addressesTypes}
                                            className="react-select"
                                            placeholder="Please select"
                                            name="typedesc"
                                            defaultValue={
                                                this.state.defaultType
                                            }
                                            onChange={this.handleTypeChange}
                                        />
                                    </Col>
                                </Row>

                                {this.state.defaultType?.value === 'Custom' && (
                                    <Row lg={2} className={'mt-2'}>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                maxLength={20}
                                                placeholder="Please enter"
                                                data-field="typedesc"
                                                defaultValue={''}
                                                onChange={this.handleTypeChange}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-lg-4">
                    <Col className="mb-lg-0">
                        <Row className="align-items-center">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    <strong>
                                        Contact
                                        <br />
                                        Information
                                    </strong>
                                </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row xs={1} lg={2} className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-start mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0 mt-2">
                                    Address
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    as="textarea"
                                    maxLength={255}
                                    style={{ height: '120px' }}
                                    placeholder="Please enter"
                                    data-field="addr"
                                    defaultValue={data.addr}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">City</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    placeholder="Please enter"
                                    data-field="city"
                                    defaultValue={data.city}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">State</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={2}
                                    placeholder="Please enter"
                                    data-field="state"
                                    defaultValue={data.state}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Zip Code
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={20}
                                    placeholder="Please enter"
                                    data-field="zip"
                                    defaultValue={data.zip}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Contact
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={40}
                                    placeholder="Please enter"
                                    data-field="contact"
                                    defaultValue={data.contact}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Phone</Form.Label>
                            </Col>
                            <Col>
                                <InputMasked
                                    type="text"
                                    placeholder="Please enter"
                                    data-field="contactphone"
                                    value={data.contactphone}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Fax</Form.Label>
                            </Col>
                            <Col>
                                <InputMasked
                                    type="text"
                                    placeholder="Please enter"
                                    data-field="fax"
                                    value={data.fax}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Email</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={255}
                                    placeholder="Please enter"
                                    data-field="email"
                                    defaultValue={data.email}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Phone 2
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2} className="align-items-center mb-3">
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={40}
                                            placeholder="Phone 2 Description"
                                            data-field="phonedesc2"
                                            defaultValue={data.phonedesc2}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col>
                                        <InputMasked
                                            type="text"
                                            placeholder="Phone 2 Number"
                                            data-field="phone2"
                                            value={data.phone2}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <SecureBootstrapButton
                        attributeNo={46}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        size="lg"
                        onClick={this.handleClickSave}
                        disabled={!isDraft || isLoading}
                    >
                        Save
                    </SecureBootstrapButton>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(GlossaryAddShipToCodes)
