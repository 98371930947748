import ConfirmationModal from 'app/components/modal/ConfirmationModal'
import React from 'react'
import { Component } from 'lib/api/types/Component'

const ComponentsDeleteWarningModal = ({
    show,
    confirmAction,
    message,
    component,
}: {
    show: boolean
    confirmAction: () => void
    message: string
    component: Component
}) => {
    if (!component) {
        return null
    }
    return (
        <ConfirmationModal
            title="Warning"
            show={show}
            confirmAction={confirmAction}
            hideCancel
        >
            <p className="tw-text-lg">
                The following component could not be deleted:
            </p>

            <div>
                <p className="tw-text-sm">
                    {component.comp} : <span>{message}</span>
                </p>
            </div>
        </ConfirmationModal>
    )
}

ComponentsDeleteWarningModal.displayName = 'ComponentsDeleteWarningModal'
export default ComponentsDeleteWarningModal
