import { Breadcrumb } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

import { HeaderLight } from 'templates/components/Header'

import URI from 'defaults/RoutesDefault'

export default function Header() {
    return (
        <HeaderLight>
            <HeaderLight.Breadcumbs>
                <NavLink
                    to={URI.accountsPayable.checkbook.list}
                    className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                >
                    <i className="ri-arrow-left-s-line"></i> Back
                </NavLink>
                <Breadcrumb>
                    <Breadcrumb.Item
                        linkProps={{
                            to: URI.accountsPayable.creditCard.list,
                        }}
                        linkAs={Link}
                    >
                        Accounts Payable
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkProps={{
                            to: URI.accountsPayable.creditCard.list,
                        }}
                        linkAs={Link}
                    >
                        Credit Card
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Reconcile Credit Card</Breadcrumb.Item>
                </Breadcrumb>
            </HeaderLight.Breadcumbs>
            <HeaderLight.Content>
                <HeaderLight.Title>Reconcile Credit Card</HeaderLight.Title>
            </HeaderLight.Content>
        </HeaderLight>
    )
}
