import axios, { AxiosError } from 'axios'
import * as querystring from 'query-string'
import { AppConfiguration } from '../../config/Configuration'
import { getCookie } from '../../utilities/Auth'
import { generatePath } from 'react-router-dom'
import URI from '../../defaults/RoutesDefault'

interface HttpWorkerConfiguration {
    baseURL: string
    authURL?: string
    headers: {}
}

export class HttpWorkerConfigurationFactory {
    private static baseURL = AppConfiguration.default.apiBaseUrl
    private static authURL = AppConfiguration.default.apiAuthBaseUrl

    private static headers = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-InstanceId': '',
        'X-ApiClientId': '42919f39-4705-447e-b93f-de5373c57b62',
        Authorization: '',
        responseType: 'json',
    }
    private static headersAuth = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    static api: HttpWorkerConfiguration = {
        baseURL: `${HttpWorkerConfigurationFactory.baseURL}/api/v1`,
        headers: HttpWorkerConfigurationFactory.headers,
    }

    static auth: HttpWorkerConfiguration = {
        baseURL: `${HttpWorkerConfigurationFactory.authURL}`,
        headers: HttpWorkerConfigurationFactory.headersAuth,
    }
}

export class HttpWorker {
    private configuration: HttpWorkerConfiguration

    constructor(configuration: HttpWorkerConfiguration) {
        this.configuration = configuration
    }

    async get<T>(route: string, params: {}): Promise<T> {
        try {
            const config = this.configuration
            const headers = config.headers

            const token = this.getToken()
            const instanceId = getCookie('dmInstanceId') || ''

            const newHeaders = {
                ...config,
                headers: {
                    ...headers,
                    'X-InstanceId': instanceId,
                    Authorization: `Bearer ${token}`,
                },
            }

            const response = await axios.get(route, {
                ...newHeaders,
                params: params,
            })
            return response.data as T
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.request) {
                    if (error.code === 'ECONNABORTED') {
                        throw new HttpWorkerError(
                            HttpWorkerErrorType.timeout,
                            error
                        )
                    } else {
                        throw new HttpWorkerError(
                            HttpWorkerErrorType.noInternetConnection,
                            error
                        )
                    }
                }
            }
            throw error
        }
    }

    auth<T>(path: string, params: {}): Promise<T> {
        const config = this.configuration
        return new Promise((resolve, reject) => {
            axios
                .post(path, params, config)
                .then((response) => {
                    resolve(response.data as T)
                })
                .catch(function (error: AxiosError) {
                    reject(error)
                })
        })
    }

    async post<T>(path: string, params: {}): Promise<T> {
        const config = this.configuration
        const headers = config.headers

        const token = this.getToken()
        const instanceId = getCookie('dmInstanceId') || ''

        const newHeaders = {
            ...config,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'X-InstanceId': instanceId,
                'Content-Type': 'application/json',
                dataType: 'json',
            },
        }

        return new Promise((resolve, reject) => {
            axios
                .post(path, params, newHeaders)
                .then((response) => {
                    resolve(response.data as T)
                })
                .catch(function (error: AxiosError) {
                    reject(error)
                })
        })
    }

    async patch<T>(path: string, params: {}): Promise<T> {
        const config = this.configuration
        const headers = config.headers

        const token = this.getToken()
        const instanceId = getCookie('dmInstanceId') || ''

        const newHeaders = {
            ...config,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
                'X-InstanceId': instanceId,
                'Content-Type': 'application/json',
                dataType: 'json',
            },
        }

        return new Promise((resolve, reject) => {
            axios
                .patch(path, params, newHeaders)
                .then((response) => {
                    resolve(response.data as T)
                })
                .catch(function (error: AxiosError) {
                    reject(error)
                })
        })
    }

    postForm<T>(path: string, params: {}): Promise<T> {
        return new Promise((resolve, reject) => {
            axios
                .post(path, querystring.stringify(params), this.configuration)
                .then((response) => {
                    resolve(response.data as T)
                })
                .catch(function (error: AxiosError) {
                    reject(error)
                })
        })
    }

    async delete<T>(route: string, params: {}): Promise<T> {
        try {
            const config = this.configuration
            const response = await axios.delete(route, {
                ...config,
                params: params,
            })
            return response.data as T
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.request) {
                    if (error.code === 'ECONNABORTED') {
                        throw new HttpWorkerError(
                            HttpWorkerErrorType.timeout,
                            error
                        )
                    } else {
                        throw new HttpWorkerError(
                            HttpWorkerErrorType.noInternetConnection,
                            error
                        )
                    }
                }
            }
            throw error
        }
    }

    private getToken(): string {
        const token = getCookie('dmAuthToken')
        if (token && token !== '') {
            return token
        } else {
            window.location.href = generatePath(URI.login.base)
            return ''
        }
    }
}

export interface AuthResponse {
    access_token: string
    expires_in: number
    scope: string
    token_type: string
}

export enum HttpWorkerErrorType {
    invalidRequest = 'INVALID_REQUEST',
    noInternetConnection = 'NO_INTERNET_CONNECTION',
    timeout = 'TIMEOUT',
}

export class HttpWorkerError extends Error {
    get type() {
        return this._type
    }
    get backedError() {
        return this._backedError
    }

    private _type: HttpWorkerErrorType
    private _backedError: AxiosError

    constructor(errorType: HttpWorkerErrorType, backedError: AxiosError) {
        super(errorType)
        this._type = errorType
        this._backedError = backedError
        Object.setPrototypeOf(this, HttpWorkerError.prototype)
    }
}
