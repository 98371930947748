import { useMutation, useQuery } from '@tanstack/react-query'
import invariant from 'invariant'
import { useEffect, useState } from 'react'

import { useInstanceId, useUsercode } from 'hooks'
import { ApiService } from 'lib/api/HttpService'
import { displayAlert, displayAlertError } from 'utilities/Response'

/*
 * Full run reports api docs are found on the confluence here:
 * https://designmanager.atlassian.net/wiki/spaces/WEBPRO/pages/573210625/Reporting+Microservice+-+RunReports
 *
 */

// Get our API service, maybe this should be a hook?
const api = new ApiService()

// wanna find a better way to do this but this seems to work
let retryCounter = 0

export default function useRunReport({
    retries = 10,
    retryInterval = 1000,
    showAlerts = true,
} = {}) {
    const [completedFileId, setCompletedFileId] = useState()
    const [label, setLabel] = useState()
    const [reportLink, setReportLink] = useState()
    const [runReportId, setRunReportId] = useState()

    const usercode = useUsercode()
    const instanceId = useInstanceId()

    const showAlert = (type, message) => {
        if (!showAlerts) return

        displayAlert(type, message)
    }

    const showWarning = (message) => {
        if (!showAlerts) return
        if (!message) return

        displayAlertError(message)
    }

    const onRequestError = (error) => {
        console.error(error)
        showWarning('Could not generate the report. Try again later.')
    }

    // first we Post our report payload
    const reportMutation = useMutation({
        mutationFn: ({
            label,
            reportId,
            reportFormatId,
            runReportParameters,
        }) => {
            setLabel(label)
            return api.postJson('RunReports', {
                label,
                reportId,
                reportFormatId,
                runReportParameters,
                instanceId: instanceId,
                userId: usercode,
                reportObjectType: 'Report',
                objectId: null,
            })
        },
        onSuccess: (data) => {
            const { id, userError } = data

            if (!id) {
                console.error('Error creating report:', response)
                showWarning(userError)
            } else {
                retryCounter = 0
                setRunReportId(id)
                showAlert(
                    'success',
                    'Please wait for your report to be generated.'
                )
            }
        },
        onError: onRequestError,
    })

    // Status is 0 = pending, 1 = complete, 2 = error.
    const runReportStatusPoll = useQuery({
        enabled: !!runReportId,
        queryKey: ['reports', 'polling', runReportId],
        queryFn: async () => {
            retryCounter++
            const [response] = await api.get(
                'runreports',
                `?$filter=id eq ${runReportId}`
            )
            return response
        },
        // onError: setReportError,
        refetchInterval: (data) => {
            if (!runReportId) return false
            if (!data) return false

            const status = data?.status

            if (status === 'Pending') {
                if (retryCounter >= retries) {
                    showWarning('Report is on pending status. Try again later.')
                    return false
                }
                return 2000
            }

            if (status === 'Error') {
                showWarning('Could not generate report. Try again later.')
            }

            if (status === 'Complete') {
                const { completedFileId } = data
                setCompletedFileId(completedFileId)
            }

            return false
        },
        onError: onRequestError,
        refetchOnWindowFocus: false,
    })

    // one we have a file ID we can resolve it to a url
    const linkQuery = useQuery({
        enabled: !!completedFileId,
        queryKey: ['reports', 'link', completedFileId],
        queryFn: () => api.getPublicMappings(completedFileId, label),
        // onError: setReportError,
        onSuccess: ({ link }) => setReportLink(link),
        onError: onRequestError,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (!reportLink) return

        const windowReport = window.open('', '_blank')
        windowReport.location.href = reportLink
    }, [reportLink])

    return async (data) => {
        invariant(data, 'Report config object is requried')

        invariant(data.label, 'Label is required')
        invariant(data.reportId, 'Report ID is required')
        invariant(data.reportFormatId, 'Report Format Id is required')
        invariant(
            data.runReportParameters,
            'Report Run Paramaters are required'
        )

        reportMutation.mutate(data)
    }
}
