import React from 'react'
import { Form, Table, Button, Col, Row } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import Switch from '../../components/Switch'
import { fakeData } from '../../factory/fakeFactory'
import ListFilter from '../../components/ListFilter'
import InputBar from '../../components/InputBar'
import RequestRetainerModal from '../modal/RequestRetainerModal'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../../helpers/Number'

import { ApiService } from '../../../lib/api/HttpService'
import { Pagination } from '../../../app/components/pagination/Pagination'
import debounce from 'lodash/debounce'
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM'
import { formatDate, formatFilterDate } from '../../../helpers/Date'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import noItems from '../../../assets/images/icons/addpayment.svg'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import { isFiltered } from '../../../helpers/Util'
import { isEmpty } from 'lodash'
import FilterNumberModal from '../../../app/components/modal/FilterNumberModal'
import FilterSort from '../../../utilities/modules/FilterSort'

class ProjectViewDeposit extends ThreadComponent {
    constructor(props) {
        super(props)

        this.projectId = this.props.params.id
        this.fs = new FilterSort('project_deposit_list_' + this.projectId)
        this.fs.setDefaultSort('maxUserdate asc')
        this.state = {
            checks: { 0: true },
            data: [],
            dataIsLoaded: false,
            open: this.fs.getFilter('open') ?? true,
            showRetainerModal: false,
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'maxUserdate asc',
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
            openRetainer: 0,
            openDeposit: 0,
            invoicesOrRefund: 0,
            available: 0,
            project: {},
            showNumberModal: false,
            modalTitle: '',
            activeNumberModal: '',
        }

        this.api = new ApiService()
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    componentInit() {
        this.setFormAction(true)
    }

    async componentDidMount() {
        window.setTimeout(() => {
            this.setState({ hideAlert: true })
        }, 5000)

        if (isEmpty(this.props.project?.proj)) {
            return
        }

        if (!isEmpty(this.props.project)) {
            this.setState(
                {
                    project: this.props.project,
                    dataIsLoaded: false,
                },
                this.loadData
            )
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        let project = {}
        if (previousProps.project !== this.props.project) {
            project = this.props.project || {}

            this.setState(
                {
                    project,
                    dataIsLoaded: false,
                },
                this.loadData
            )
        }
    }

    async loadData() {
        await this.fetchItems()
        await this.fetchDepositTotals()
        this.enableSortTable()
    }

    async fetchDepositTotals() {
        const totals = await this.api.getProjectDepositTotal(
            this.state.project?.proj,
            this.state.open
        )

        this.setState({
            openRetainer: totals.openRetainer,
            openDeposit: totals.openDeposit,
            available: totals.available,
            dataIsLoaded: true,
        })
    }

    async fetchItems(page, setLoading) {
        if (setLoading) this.setState({ dataIsLoaded: false })
        const data = await this.api.get(
            this.state.open ? 'ProjectDeposits/open' : 'ProjectDeposits',
            `?${this.buildFilters(page)}`
        )

        this.setState({
            data: data,
            dataIsLoaded: true,
        })
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.fs.setIsActiveSearch(newTableSearch)
        this.setState({
            showTableSearch: newTableSearch,
        })

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    handleSwitch = (e) => {
        this.setState(
            {
                open: e.target.checked,
                openRetainer: 0,
                openDeposit: 0,
                available: 0,
            },
            () => {
                this.handleChangePage(1)
                this.fetchDepositTotals()
            }
        )
        this.fs.setFilter('open', e.target.checked)
    }

    toggleNumberModal = (e) => {
        this.setState({
            showNumberModal: !this.state.showNumberModal,
        })
    }

    triggerNumberModal = (e) => {
        const idx = Array.from(e.target.parentNode.parentNode.children).indexOf(
            e.target.parentNode
        )
        const key = e.target.attributes['data-field'].value

        const labels = e.target.parentNode.parentNode.previousSibling.children

        this.setState({
            activeNumberModal: key,
            defaultNumberVal: e.target.value,
            modalTitle: labels[idx].children[0].textContent + ' Range',
        })
        this.toggleNumberModal()
    }

    updateSearch = (min, max) => {
        const key = this.state.activeNumberModal

        let obj = { type: 'number' }

        if (min !== '') {
            obj.min = min
        }
        if (max !== '') {
            obj.max = max
        }

        const field = document.querySelectorAll(
            '.a-table-search-fields input[data-field="' + key + '"]'
        )

        field[0].value = min !== '' || max !== '' ? min + '-' + max : ''

        let tmp = this.state.searchProperties

        tmp[key] = obj

        this.setState(
            {
                searchProperties: tmp,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {}

    buildFilters(currentPage) {
        let filtersQuery = `&$filter=proj eq '${this.state.project?.proj}'`
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.ma) {
                if (property.type === 'number' || property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        await this.fetchItems(page, true)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    isDisplaySearch() {
        return (
            (this.state.data && this.state.data.length > 0) ||
            isFiltered(this.state.searchProperties) ||
            !this.state.dataIsLoaded
        )
    }

    renderOpenInputBar() {
        return (
            <InputBar>
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Open Retainer
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.openRetainer)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Open Deposit
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.openDeposit)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Available
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.available)}
                            </span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderCloseInputBar() {
        return (
            <InputBar>
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Retainer
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.openRetainer)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Deposit
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.openDeposit)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Sum of All Amounts
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control type="text" size="sm" readOnly />
                            <span>
                                $ {currencyFormat(this.state.available)}
                            </span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderFilter() {
        return (
            <>
                <ListFilter expandable={0} className="no-separator">
                    <ListFilter.Fields sm={12} md={8} lg={8}>
                        <ListFilter.Field>
                            <Form.Label style={{ maxWidth: '100%' }}>
                                <strong>Show Open Only</strong>
                            </Form.Label>
                            <span>
                                <Switch
                                    onChange={this.handleSwitch}
                                    checked={this.state.open}
                                ></Switch>
                            </span>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    <ListFilter.Actions
                        sm={12}
                        md={4}
                        lg={4}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-lg-end"
                    >
                        <ListFilter.Action>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() =>
                                    this.setState(
                                        (prev) =>
                                            (prev.showRetainerModal = true)
                                    )
                                }
                            >
                                Request Retainer Email
                            </Button>
                            {this.isDisplaySearch() && (
                                <Button
                                    as={Link}
                                    to="#"
                                    variant="ivory"
                                    size="sm"
                                    className={`btn-icon btn-action ms-3 fw-bold ${
                                        this.state.showTableSearch
                                            ? 'bg-primary-ash text-white'
                                            : ''
                                    }`}
                                    onClick={this.handleShowTableSearch}
                                >
                                    <i className="ri-search-line"></i> Search
                                </Button>
                            )}
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        const { showTableSearch } = this.state

        return (
            <>
                <div>
                    {/* Filter */}
                    {this.renderFilter()}

                    {!this.state.dataIsLoaded ||
                    this.state.data.length > 0 ||
                    JSON.stringify(this.state.searchProperties) !== '{}' ? (
                        <>
                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr key="0" className="a-table-heading">
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'maxUserdate'
                                                    )}
                                                    data-field="maxUserdate"
                                                >
                                                    Date
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'txnum'
                                                    )}
                                                    data-field="txnum"
                                                >
                                                    Trans. #
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'maxPropnum'
                                                    )}
                                                    data-field="maxPropnum"
                                                >
                                                    Prop. #
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'maxChecknum'
                                                    )}
                                                    data-field="maxChecknum"
                                                >
                                                    Check #
                                                </span>
                                            </th>
                                            <th className="col-4">
                                                <span
                                                    className={this.sortClass(
                                                        'maxDescription'
                                                    )}
                                                    data-field="maxDescription"
                                                >
                                                    Description
                                                </span>
                                            </th>
                                            <th>
                                                <span data-field="depositType">
                                                    Type
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'totalAmount'
                                                    )}
                                                    data-field="totalAmount"
                                                >
                                                    Amount
                                                </span>
                                            </th>
                                        </tr>
                                        <tr
                                            className={`a-table-search-fields ${
                                                showTableSearch ? '' : 'd-none'
                                            }`}
                                        >
                                            <th>
                                                <DateRangePicker
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                    placement="auto"
                                                    placeholder="Select date"
                                                    format="MM/dd/yyyy"
                                                    defaultValue={this.fs.getValue(
                                                        'maxUserdate'
                                                    )}
                                                    onChange={this.handleDateChange.bind(
                                                        this,
                                                        'maxUserdate'
                                                    )}
                                                    onClean={this.handleDateChange.bind(
                                                        this,
                                                        'maxUserdate'
                                                    )}
                                                    ranges={[
                                                        {
                                                            label: 'today',
                                                            value: [
                                                                startOfDay(
                                                                    new Date()
                                                                ),
                                                                endOfDay(
                                                                    new Date()
                                                                ),
                                                            ],
                                                        },
                                                        {
                                                            label: 'yesterday',
                                                            value: [
                                                                startOfDay(
                                                                    addDays(
                                                                        new Date(),
                                                                        -1
                                                                    )
                                                                ),
                                                                endOfDay(
                                                                    addDays(
                                                                        new Date(),
                                                                        -1
                                                                    )
                                                                ),
                                                            ],
                                                        },
                                                        {
                                                            label: 'last7Days',
                                                            value: [
                                                                startOfDay(
                                                                    subDays(
                                                                        new Date(),
                                                                        6
                                                                    )
                                                                ),
                                                                endOfDay(
                                                                    new Date()
                                                                ),
                                                            ],
                                                        },
                                                        {
                                                            label: 'Last 30 Days',
                                                            value: [
                                                                startOfDay(
                                                                    subDays(
                                                                        new Date(),
                                                                        30
                                                                    )
                                                                ),
                                                                endOfDay(
                                                                    new Date()
                                                                ),
                                                            ],
                                                        },
                                                        {
                                                            label: 'This month',
                                                            value: [
                                                                startOfDay(
                                                                    new Date(
                                                                        getYear(
                                                                            new Date()
                                                                        ),
                                                                        getMonth(
                                                                            new Date()
                                                                        ),
                                                                        1
                                                                    )
                                                                ),
                                                                endOfDay(
                                                                    lastDayOfMonth(
                                                                        new Date()
                                                                    )
                                                                ),
                                                            ],
                                                        },
                                                        {
                                                            label: 'Last month',
                                                            value: [
                                                                startOfDay(
                                                                    new Date(
                                                                        getYear(
                                                                            new Date()
                                                                        ),
                                                                        getMonth(
                                                                            new Date()
                                                                        ) - 1,
                                                                        1
                                                                    )
                                                                ),
                                                                endOfDay(
                                                                    lastDayOfMonth(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ) -
                                                                                1,
                                                                            1
                                                                        )
                                                                    )
                                                                ),
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="txnum"
                                                    data-type="number"
                                                    onClick={this.triggerNumberModal.bind(
                                                        this
                                                    )}
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'txnum'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="maxPropnum"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'maxPropnum'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="maxChecknum"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'maxChecknum'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="maxDescription"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'maxDescription'
                                                    )}
                                                />
                                            </th>
                                            <th></th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="totalAmount"
                                                    data-type="number"
                                                    onClick={this.triggerNumberModal.bind(
                                                        this
                                                    )}
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'totalAmount'
                                                    )}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.state.dataIsLoaded
                                            ? showLoading()
                                            : this.state.data.length === 0 &&
                                              isFiltered(
                                                  this.state.searchProperties
                                              )
                                            ? showEmpty()
                                            : this.state.data
                                                  .slice(0, this.state.pageSize)
                                                  .map((item, i) => (
                                                      <tr key={i}>
                                                          <td>
                                                              {formatDate(
                                                                  item.maxUserdate
                                                              )}
                                                          </td>
                                                          <td>{item.txnum}</td>
                                                          <td>
                                                              {item.maxPropnum}
                                                          </td>
                                                          <td>
                                                              {item.maxChecknum}
                                                          </td>
                                                          <td>
                                                              {
                                                                  item.maxDescription
                                                              }
                                                          </td>

                                                          {(() => {
                                                              if (
                                                                  item.type ==
                                                                  '2002'
                                                              ) {
                                                                  return (
                                                                      <td>
                                                                          Retainer
                                                                      </td>
                                                                  )
                                                              } else if (
                                                                  item.type ==
                                                                  '3010'
                                                              ) {
                                                                  return (
                                                                      <td>
                                                                          Applied
                                                                      </td>
                                                                  )
                                                              } else if (
                                                                  item.type ==
                                                                  '2004'
                                                              ) {
                                                                  return (
                                                                      <td>
                                                                          Deposit
                                                                      </td>
                                                                  )
                                                              } else {
                                                                  return (
                                                                      <td></td>
                                                                  )
                                                              }
                                                          })()}

                                                          <td>
                                                              {currencyFormat(
                                                                  item.totalAmount,
                                                                  '$'
                                                              )}
                                                          </td>
                                                      </tr>
                                                  ))}
                                    </tbody>
                                </Table>
                            </div>
                            {(() => {
                                if (this.state.data.length) {
                                    return (
                                        <Pagination
                                            onPageSizeChanged={
                                                this.onPageSizeChanged
                                            }
                                            onPageChanged={this.onPageChanged}
                                            hasPreviousPage={
                                                this.state.page > 1
                                            }
                                            hasNextPage={
                                                this.state.data.length >
                                                this.state.pageSize
                                            }
                                            page={this.state.page}
                                            pageSize={this.state.pageSize}
                                        />
                                    )
                                }
                            })()}
                        </>
                    ) : (
                        <div className="row justify-content-center text-center py-5">
                            <div className="col-md-3">
                                <img
                                    src={noItems}
                                    className="mw-100 mb-4"
                                    alt=""
                                />

                                <h6>Track Your Client Deposits</h6>
                                <p>
                                    This is where you will manage deposits that
                                    you will receive from clients.
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="my-4">
                    <Row className="justify-content-center">
                        <Col>
                            {this.state.open
                                ? this.renderOpenInputBar()
                                : this.renderCloseInputBar()}
                        </Col>
                    </Row>
                </div>

                {/* Bind  `hideRetainerModal` method to this component. */}
                <RequestRetainerModal
                    projectCode={this.state.project?.proj}
                    show={this.state.showRetainerModal}
                    hideModal={() =>
                        this.setState(
                            (prev) => (prev.showRetainerModal = false)
                        )
                    }
                />
                <FilterNumberModal
                    defaultVal={this.state.defaultNumberVal}
                    defaultTitle={this.state.modalTitle}
                    show={this.state.showNumberModal}
                    toggleModal={this.toggleNumberModal}
                    updateSearch={this.updateSearch.bind(this)}
                ></FilterNumberModal>
            </>
        )
    }
}

export default WithRouter(ProjectViewDeposit)
