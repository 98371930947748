import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { Project } from '../types/Project'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'

const api = new ApiService()

const getProject = async (id: string) => {
    const project = (await api.getProjects(
        `?$filter=id eq ${parseInt(id)}`
    )) as Project[]
    if (!project.length) {
        throw Error('Project not found')
    }
    return project[0]
}

const useGetProject = (id: string, options?: UseQueryOptions<Project>) => {
    const projectQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [id, 'projects'],
        retry: (failureCount, error) => {
            if (failureCount >= 2) {
                return false
            }
            return (error as Error)?.message !== 'Project not found'
        },
        queryFn: () => getProject(id),
        ...(options || {}),
    })

    useEffect(() => {
        if (projectQuery.error) {
            displayAlertError(
                'An error occurred fetching the project, please try again'
            )
        }
    }, [projectQuery.error])

    return projectQuery
}

export default useGetProject
