import { CurrencyType, LocaleType } from 'app/enums/utiles/CurrencyTypes'

export const formatCurrency = (
    amount: number,
    locale = LocaleType.EN_US,
    currency = CurrencyType.USD
): string => {
    const options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }

    const formattedAmount = new Intl.NumberFormat(locale, options).format(
        amount
    )
    if (formattedAmount.endsWith('.00')) {
        return formattedAmount.slice(0, -3)
    }

    return formattedAmount
}
