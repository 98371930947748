import React from 'react'
import ThreadComponent from '../ThreadComponent'
import DatePicker from 'react-datepicker'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'

class CreateItemFromClippedItemsModal extends ThreadComponent {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Modal
                size="md"
                show={this.props.show}
                onHide={() => this.hide()}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Row className="mb-3">
                        <Col className="px-5">
                            <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
                                Create Item
                            </h5>
                            <div className="text-center mx-auto pt-3">
                                <h6>
                                    Would you like to create this as an item?
                                </h6>
                                <h6 className="fw-bold">
                                    {this.props.data?.itemTitle}
                                </h6>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-3 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={() => this.props.handleCreateItem()}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal}
                                className="w-100 m-2"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CreateItemFromClippedItemsModal
