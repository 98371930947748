import { Component } from 'react'
import { getClassNames } from '../../helpers/DOM'

/**
 * Displays the input bar.
 * Use className to set the size and variant
 *
 * size=large
 * variant=toggle-input
 */
class InputBar extends Component {
    static Links(props) {
        return (
            <li {...props} className={getClassNames(props, '')}>
                <ul
                    className={getClassNames(
                        props.childprops || {},
                        'mb-0 px-0 d-flex align-items-center list-unstyled'
                    )}
                >
                    {props.children}
                </ul>
            </li>
        )
    }

    static Link(props) {
        return (
            <li {...props} className={getClassNames(props, '')}>
                <div
                    className={getClassNames(
                        props.childprops || {},
                        'd-flex flex-wrap flex-lg-nowrap align-items-center'
                    )}
                >
                    {props.children}
                </div>
            </li>
        )
    }

    render() {
        return (
            <div
                {...this.props}
                className={getClassNames(
                    this.props,
                    `a-input-bar position-relative roundedx-6 bg-ivory border-1 border-sand mx-auto`
                )}
            >
                <ul
                    className={getClassNames(
                        this.props.childprops || {},
                        `mb-0 px-0 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between list-unstyled`
                    )}
                >
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

export default InputBar
