import { isEmpty } from 'lodash'
import { pF, pI, round } from '../Number'

export function getItemPrice(item: any) {
    if (!isEmpty(item)) {
        const { invprice, otherinvprice } = item

        return round(pF(invprice) + pF(otherinvprice), 2)
    }

    return round(0, 2)
}

export function getItemInvoiceToDate(item: any) {
    if (!isEmpty(item)) {
        const { tdinvprice, tdotherinvprice } = item

        return round(pF(tdinvprice) + pF(tdotherinvprice), 2)
    }

    return round(0, 2)
}

export function getItemQuantity(item: any) {
    if (!isEmpty(item)) {
        const { invqty, tdinvqty } = item

        return pI(invqty) + pI(tdinvqty)
    }

    return round(0, 2)
}
