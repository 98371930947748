import ConfirmationModal from 'app/components/modal/ConfirmationModal'
import { TFailedComponentRequest } from './PurchaseComponentsTable'
import React from 'react'

const ComponentsDeleteWarningModal = ({
    show,
    confirmAction,
    failedComponentsDelete,
}: {
    show: boolean
    confirmAction: () => void
    failedComponentsDelete: TFailedComponentRequest[]
}) => (
    <ConfirmationModal
        title="Warning"
        show={show}
        confirmAction={confirmAction}
        hideCancel
    >
        <p className="tw-text-lg">
            The following components could not be deleted:
        </p>
        {failedComponentsDelete
            .slice()
            .sort((a, b) => Number(a.comp) - Number(b.comp))
            .map((comp) => (
                <div key={comp.comp}>
                    <p className="tw-text-sm">
                        {comp.comp} : <span>{comp.message}</span>
                    </p>
                </div>
            ))}
    </ConfirmationModal>
)

ComponentsDeleteWarningModal.displayName = 'ComponentsDeleteWarningModal'
export default ComponentsDeleteWarningModal
