import { createContext, useContext, useEffect, useReducer } from 'react'
import { clearUserData, getCookie, parseJwt } from '../../utilities/Auth'
import { API_AUTH_ERROR_MESSAGES, ApiService } from '../../lib/api/HttpService'

const SessionContext = createContext()

function sessionReducer(state, action) {
    switch (action.type) {
        case 'setIsAdmin':
            return { ...state, isAdmin: action.payload }
        case 'setEmail':
            return { ...state, email: action.payload }
        case 'setUserId':
            return { ...state, userId: action.payload }
        case 'setUsername':
            return { ...state, username: action.payload }
        case 'setFullName':
            return { ...state, fullName: action.payload }
        case 'setClientSerialCode':
            return { ...state, clientSerialCode: action.payload }
        case 'setClientId':
            return { ...state, clientId: action.payload }
        case 'setClientName':
            return { ...state, clientName: action.payload }
        case 'setIsTrial':
            return { ...state, isTrial: action.payload }
        case 'setIsWebOnly':
            return { ...state, isWebOnly: action.payload }
        case 'setAllowInWeb':
            return { ...state, allowInWeb: action.payload }
        case 'setCompanyName':
            return { ...state, companyName: action.payload }
        default:
            return state
    }
}

function SessionProvider({ children }) {
    const token = getCookie('dmAuthToken')
    const usercode = getCookie('dmUsercode')
    const tokenData = parseJwt(token)
    const { name } = tokenData

    const [state, dispatch] = useReducer(sessionReducer, {
        token,
        usercode,
        name,
        isAdmin: null,
    })

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const api = new ApiService()
            try {
                const {
                    clientUserId,
                    username,
                    fullName,
                    clientId,
                    clientSerialCode,
                    clientName,
                    clientIsTrial,
                    clientAllowInWeb,
                    clientWebOnly,
                    email,
                    isAdmin,
                } = await api.getCurrentUser()

                dispatch({ type: 'setIsAdmin', payload: isAdmin })
                dispatch({ type: 'setEmail', payload: email })
                dispatch({ type: 'setUserId', payload: clientUserId })
                dispatch({ type: 'setUsername', payload: username })
                dispatch({ type: 'setFullName', payload: fullName })
                dispatch({
                    type: 'setClientSerialCode',
                    payload: clientSerialCode,
                })
                dispatch({ type: 'setClientId', payload: clientId })
                dispatch({ type: 'setClientName', payload: clientName })
                dispatch({ type: 'setIsTrial', payload: clientIsTrial })
                dispatch({ type: 'setAllowInWeb', payload: clientAllowInWeb })
                dispatch({ type: 'setIsWebOnly', payload: clientWebOnly })

                const { name } = await api.getCompany()

                dispatch({ type: 'setCompanyName', payload: name })
            } catch (e) {
                if (
                    e?.response?.data?.UserError ===
                    API_AUTH_ERROR_MESSAGES.INVALID_INSTANCE
                ) {
                    return
                }
                clearUserData()
                window.location = '/login'
            }
        }

        fetchCurrentUser()
    }, [usercode])

    const value = {
        state,
        dispatch,
    }

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    )
}

export function useSession() {
    const context = useContext(SessionContext)
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider')
    }
    const { state } = context
    return state
}

export default SessionProvider
