import React from 'react'
import Form from 'react-bootstrap/Form'

import { useHasAccess } from '../../../context/security'

export default function SecureFormControl({
    attributeNo,
    attributeType,
    ...props
}) {
    const hasAccess = useHasAccess(attributeNo, attributeType)
    const isDisabled = !hasAccess ? true : props.disabled
    const isReadOnly = !hasAccess ? true : props.readOnly

    return (
        <Form.Control {...props} disabled={isDisabled} readOnly={isReadOnly} />
    )
}
