import React from 'react'
import { Form, Table, Dropdown, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../ThreadComponent'
import {
    getRouteWithParam,
    routeParam,
    WithRouter,
} from '../../../helpers/Router'
import ListFilter from '../../components/ListFilter'
import { compareStr, truncateCharCount } from '../../../helpers/String'
import TreeView from '../../components/TreeView'
import URI from '../../../defaults/RoutesDefault'
import MiniZoomer from '../../components/MiniZoomer'
import { isFiltered } from '../../../helpers/Util'
import { ApiService } from '../../../lib/api/HttpService'
import Select, { components } from 'react-select'
import { generatePath } from 'react-router'
import DeleteModal from '../../../app/components/modal/DeleteModal'
import HandleDeleteWorker from '../../../utilities/DeleteWorker'
import debounce from 'lodash/debounce'
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM'
import ImageView from '../../components/ImageView'
import noItems from '../../../assets/images/icons/itemchecklist.svg'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import { setLocalStorage } from '../../../utilities/LocalStorage'
import {
    displayAlert,
    displayAlertLoader,
    hideAlertLoader,
} from '../../../utilities/Response'
import MSG from '../../../defaults/Message'
import { isEmpty } from 'lodash'
import FilterSort from '../../../utilities/modules/FilterSort'
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown'
import AsyncSalesCategoriesDropdown from '../../../app/components/dropdowns/AsyncSalesCategoriesDropdown'
import loadItemImages, { renderItemImage } from '../../../utilities/Images'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureDropdownItem from '../../../app/components/security/SecureDropdownItem'
import SecureContent from '../../../app/components/security/SecureContent'
import AddDropdown from '../../../app/components/dropdowns/AddDropdown'
import { Feature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'
import { InventoryTypes } from '../../../app/enums/inventoryTypes/inventoryTypes'
import { DropdownModal } from '../../../app/components/modal/DropdownModal'

const UpdateStatus = {
    NEXT: 0,
    NEXT_WITHOUT_WARNINGS: 1,
    CANCEL: 2,
}

class ProjectViewItem extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('project_item_list_' + this.props.params.id)
        this.fs.setDefaultSort('item desc')
        this.state = {
            checks: [],
            data: [],
            viewType: this.fs.getFilter('viewType') ?? 'table',
            showTableSearch: this.fs.isSearchActive(),
            gallerySize: 'md',
            disabledZoomer: true,
            dataIsLoaded: false,
            imagesLoaded: false,
            locations: [{ value: '', label: 'All' }],
            locationFilter: this.fs.getFilter('locationFilter') ?? {
                value: '',
                label: 'All',
            },
            categories: [{ value: '', label: 'All' }],
            categoryFilter: this.fs.getFilter('categoryFilter') ?? {
                value: '',
                label: 'All',
            },
            status: [
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
                { value: 'completed', label: 'Completed' },
                { value: 'all', label: 'All' },
            ],
            statusFilter: this.fs.getFilter('statusFilter') ?? {
                value: 'active',
                label: 'Active',
            },
            tree: [],
            modal: { show: false },
            disabledDeleteButton: true,
            itemComponents: [],
            page: 1,
            pageSize: { value: 20, label: '20' },
            sortProperty: this.fs.getSort() ?? 'item desc',
            searchProperties: this.fs.getSearches(),
            project: {},
            locationsQry: `?$filter=(projectId eq ${this.props.params.id} or projectId eq null)&$orderby=locn asc`,
            imageData: {},
            isShowingUpdateStatusModal: false,
            updateStatusProgress: undefined,
        }

        this.availablePageSize = [
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '75', label: '75' },
            { value: '100', label: '100' },
        ]
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )

        this.api = new ApiService()

        this.apiPath = 'salestaxcodes'
        this.apiParam = ''
        this.pageTitle = 'Project Items'
        this.objName = 'Project Item'
        this.objReference = 'Projects'

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objItem',
            objectCodeOrId: '',
        }

        this.updateStatusOptions = [
            { value: UpdateStatus.NEXT, label: 'Continue to next item ' },
            {
                value: UpdateStatus.NEXT_WITHOUT_WARNINGS,
                label: 'Continue without further warnings',
            },
            { value: UpdateStatus.CANCEL, label: 'Cancel update' },
        ]
    }

    componentInit() {
        this.setTitle(this.pageTitle).setActionBar(true)
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.removeEventListener('click', null)
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    buildFilters(currentPage) {
        let filtersQuery = `&$filter=projectId eq ${this.props.project.id}`
        let filters = []

        if (this.state.categoryFilter.value !== '') {
            const salesCatFilter = encodeURIComponent(
                this.state.categoryFilter.value
            )
            filters.push(`salesCategoryName eq '${salesCatFilter}'`)
        }

        if (
            this.state.locationFilter.name &&
            this.state.locationFilter.name !== ''
        ) {
            const locationFilter = encodeURIComponent(
                this.state.locationFilter.name
            )
            filters.push(`locationName eq '${locationFilter}'`)
        }

        if (this.state.statusFilter.value !== '') {
            let statusFilter = ''
            switch (this.state.statusFilter.value) {
                case 'active':
                    statusFilter = `inactive eq false and completed eq false`
                    break

                case 'inactive':
                    statusFilter = `inactive eq true`
                    break

                case 'completed':
                    statusFilter = `completed eq true`
                    break

                default:
                    statusFilter = ``
                    break
            }
            if (statusFilter) {
                filters.push(statusFilter)
            }
        }

        if (this.state.viewType === 'table') {
            Object.keys(this.state.searchProperties).forEach((key) => {
                const property = this.state.searchProperties[key]
                if (property.value) {
                    if (key === 'statusnumber') {
                        filters.push(`${key} in (${property.value.join(',')})`)
                    } else if (property.type === 'number') {
                        filters.push(`${key} eq ${property.value}`)
                    } else {
                        filters.push(`contains(${key}, '${property.value}')`)
                    }
                }
            })
        }

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        let queryString = ''
        if (this.state.viewType !== 'tree') {
            queryString = `$top=${this.state.pageSize.value + 1}&$skip=${
                ((currentPage ?? this.state.page) - 1) *
                this.state.pageSize.value
            }`
        }

        queryString += filtersQuery

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    async getProjectItems(page) {
        return await this.api.getProjectItems(`?${this.buildFilters(page)}`)
    }

    async fetchItems(page, isSwitch = false) {
        if (isSwitch || this.state.viewType === 'gallery') {
            this.dMloader(true)
        }

        this.setState({
            dataIsLoaded: false,
            imagesLoaded: false,
        })

        const rawItems = await this.getProjectItems(page)

        // get txnum for time items
        // this is a bit wonky, some have many, some have 1 and some have none
        // we can handle ones with 1 and link directly to the time entry, but we have to
        // query all to get them, none and many will go to item edit for now
        const items = await Promise.all(
            rawItems.map(async (item) => {
                if (item.salesCategoryName !== 'Professional Services / Time')
                    return item

                const timeEntries = await this.api.getTimeEntries(
                    `?$filter=item eq '${item.item}'`
                )

                if (timeEntries.length !== 1) return item

                return { ...item, txnum: timeEntries[0].txnum }
            })
        )

        if (items && items.length) {
            if (this.state.viewType === 'tree') {
                this.buildTree(items)
            }

            this.renderData(items)

            if (this.state.viewType === 'table') {
                this.loadThumbnails(items)
            }

            if (this.state.viewType === 'gallery') {
                this.loadImages(items)
            }
        } else {
            this.renderData(items)
        }

        this.dMloader(false, true)
    }

    getItemLink = ({ id, txnum = null }) =>
        txnum
            ? routeParam(URI.timeEntry.edit, { id: txnum })
            : routeParam(URI.project.itemEdit, {
                  id: this.props.project.id,
                  itemId: id,
              })

    async loadThumbnails(data) {
        loadItemImages({
            data,
            property: 'primaryImageId',
            callback: (imageData) => {
                this.setState({
                    imagesLoaded: true,
                    imageData,
                })
            },
            isThumbnail: true,
        })
    }

    async loadImages(data) {
        loadItemImages({
            endpoint: '',
            data,
            property: 'primaryImageId',
            callback: (imageData) => {
                this.setState({
                    imagesLoaded: true,
                    imageData,
                })
            },
        })
    }

    async fetchComponentsForItem(item) {
        let components = await this.api.getComponents(
            `?$filter=item eq '${item}' AND proj eq '${this.state.project?.proj}'`
        )
        let itemComponents = this.state.itemComponents ?? []
        itemComponents[item] = components.map((component) => {
            return {
                name: `${component.comp}${
                    component.itemName ? ' - ' + component.itemName : ''
                }`,
                id: component.id,
            }
        })
        this.setState({
            itemComponents: itemComponents,
        })
    }

    async buildTree(items) {
        // Group by location
        let locations = items
            .map(function (i) {
                return {
                    name:
                        // Item + - + shortdesc or desc
                        i.item +
                        (!i.shortdesc || i.shortdesc === 'null'
                            ? i.desc
                                ? ' - ' + i.desc
                                : ''
                            : ' - ' + i.shortdesc),
                    loc: i.loc ?? '',
                    item: i.item,
                    id: i.id,
                    project: i.projectId,
                    locationName: i.locationName,
                }
            })
            .reduce(function (r, a) {
                r[a.loc] = r[a.loc] || []
                r[a.loc].push(a)
                return r
            }, Object.create(null))

        locations = Object.keys(locations)
            .map((key) => {
                let items = locations[key]
                items = items.map((item) => {
                    return {
                        ...item,
                        icon: 'ri-server-fill',
                        isItem: true,
                        locationName: item.locationName,
                        children: (
                            this.state.itemComponents[item.item] ?? []
                        ).map((component) => {
                            return {
                                name: component.name,
                                compId: component.id,
                                project: item.project,
                                itemId: item.id,
                                component: true,
                                icon: 'ri-server-fill',
                            }
                        }),
                        onClick: async (target, isItem) => {
                            const icon = target.querySelector(
                                'span.spinner-border'
                            )
                            await this.fetchComponentsForItem(item.item)

                            const items = await this.getProjectItems()
                            this.buildTree(items)

                            if (isItem) {
                                icon.classList.add('ri-server-fill', 'me-1')
                                icon.classList.remove(
                                    'spinner-border',
                                    'spinner-border-sm',
                                    'alert-icon',
                                    'me-1'
                                )
                            }
                        },
                    }
                })
                return {
                    name: key === 'null' || key === '' ? 'NO LOCATION' : key,
                    children: items,
                    icon: 'ri-server-fill',
                }
            })
            .sort((a, b) => {
                if (a.name === 'NO LOCATION') {
                    return -1
                }
                return 0
            })

        this.setState({
            tree: locations,
        })
    }

    async componentDidMount() {
        window.isMounted = true
        if (!isEmpty(this.props.project?.proj)) {
            this.setState(
                {
                    project: this.props.project,
                    dataIsLoaded: false,
                },
                this.loadData
            )
        }

        this.enableSearchAndSortTable()
    }

    async componentDidUpdate(previousProps, previousState) {
        let project = {}
        if (previousProps.project !== this.props.project) {
            project = this.props.project || {}

            this.setState(
                {
                    project,
                    dataIsLoaded: false,
                },
                this.loadData
            )
        }
    }

    componentWillUnmount() {
        window.isMounted = false
    }

    async loadData() {
        this.api.checkBudgetProject(this.props.project.id)

        await this.fetchItems()

        window.localStorage.removeItem('preData')
        window.localStorage.removeItem('isQuickCreate')
        window.localStorage.removeItem('projObj')
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    toggleDeleteButton(e) {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.fs.setIsActiveSearch(newTableSearch)
        this.setState({
            showTableSearch: newTableSearch,
        })

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    hideModal = (e) => {
        this.toggleDeleteButton(e)
        this.setState({
            showModal: false,
        })
    }

    selectedIds = () => {
        return this.state.checks.map((item) => item.id)
    }

    async handleTestDeleteObjects(deactivateOnly) {
        if (!this.selectedIds().length) {
            return
        }

        let workers = []

        displayAlertLoader(MSG.loading.prepare.item)

        for (const id of this.selectedIds()) {
            const params = {
                deleteType: 'dmriTestOnly',
                objectType: this.deleteObjectParams.objectType,
                objectCodeOrId: id,
            }
            workers.push(await this.api.postJson('deleteobjects', params))
        }

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: this.objName,
                    modulePlural: this.objName + 's',
                    reference: this.objReference,
                    objectType: this.deleteObjectParams.objectType,
                    from: 'project_items',
                },
                deactivateOnly,
                (modalState) => {
                    this.setState(modalState)
                }
            )
        }
    }

    // Refresh data
    refreshDataAfterDelete = (e) => {
        this.api.checkBudgetProject(this.props.project.id)

        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: [],
            showModal: false,
            deleteResponses: [],
        })

        if (typeof headingCheck != 'undefined' && headingCheck != null) {
            headingCheck.checked = false
        }

        this.fetchItems(this.state.page)
    }

    removeCheckedItems = (e) => {
        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: [],
            showModal: false,
            deleteResponses: [],
        })

        if (typeof headingCheck != 'undefined' && headingCheck != null) {
            headingCheck.checked = false
        }
    }

    handleSwitchView = (e) => {
        e.preventDefault()
        this.removeCheckedItems()
        const view = e.target.getAttribute('data-view')
        // Set the new state.
        this.setState((prevState) => (prevState.viewType = view))
        this.setState(
            (prevState) => (prevState.disabledZoomer = view !== 'gallery')
        )
        this.setState((prevState) => (prevState.viewType = view))
        this.setState({
            viewType: view,
        })
        this.fs.setFilter('viewType', view)
        setTimeout(() => {
            this.fetchItems(1, true)
        }, 250)
    }

    handleZoomChange = (zoom) => {
        let size = 'md'
        if (zoom === 0) {
            size = 'sm'
        }

        if (zoom === 2) {
            size = 'lg'
        }

        this.setState({ gallerySize: size })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        let value = e.target.value
        const svalue = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        if (key == 'statusnumber') {
            let filterStatus = []
            this.props.statuses.forEach((v, k) => {
                if (v.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                    filterStatus.push(k)
                }
            })

            value = filterStatus.length ? filterStatus : ''
        }

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value, type, svalue },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleFilter = (name) => (e) => {
        this.setState(
            {
                [name]: e,
                page: 1,
            },
            this.changePageHandler
        )

        this.fs.setFilter(name, e)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page)

        this.setState({
            page: page,
        })
    }

    handleSelectAllChecks = () => {
        let checks = []
        const data = this.state.data.slice(0, this.state.pageSize.value)
        if (!this.state.checks.length) {
            checks = data
        }

        this.setState({
            checks: checks,
        })
    }

    handleCheck = (item) => {
        const checks = this.state.checks
        const index = checks.findIndex((_item) => _item.id === item.id)

        index > -1 ? checks.splice(index, 1) : checks.push(item)

        this.setState({
            checks: checks,
        })
    }

    handleDataObjSelected = (evt) => {
        const selectedPage = evt.target.text

        if (selectedPage === 'Invoice') {
            window.localStorage.removeItem('projObj')

            setLocalStorage(
                'projObj',
                {
                    id: this.props.project.id,
                    label: `${this.props.project.projn} [${this.props.project.proj}]`,
                    name: this.props.project.projn,
                    value: this.props.project.proj,
                },
                true
            )
        }

        setLocalStorage('preData', JSON.stringify(this.selectedIds()))
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    isDisplaySearch() {
        return (
            this.state.viewType === 'table' &&
            ((this.state.data && this.state.data.length > 0) ||
                isFiltered(this.state.searchProperties) ||
                !this.state.dataIsLoaded)
        )
    }

    handleUpdateStatusModal = () => {
        this.setState({ isShowingUpdateStatusModal: true })
    }

    handleUpdateStatus = async (status, ids, skipErrors) => {
        const updateIds = [...ids]

        this.setState({ isShowingUpdateStatusModal: false })
        displayAlertLoader(MSG.loading.status)

        for (const id of ids) {
            try {
                await this.api.itemRecalculate(id, {
                    whatChanged: 'iwcRecalcFromComponentsAndStatus',
                    manuallySetNewStatusNumber: status,
                    SaveChanges: true,
                })
                const index = updateIds.indexOf(id)
                if (index !== -1) {
                    updateIds.splice(index, 1)
                }

                let newChecks = [...this.state.checks]
                const checkedIndex = this.selectedIds().indexOf(id)
                if (checkedIndex !== -1) {
                    newChecks.splice(index, 1)
                    this.setState({ checks: newChecks })
                }
            } catch (error) {
                if (skipErrors) {
                    continue
                }

                const itemNumber =
                    this.state.data.find((item) => item.id === id)?.item ?? id

                const index = updateIds.indexOf(id)
                if (index !== -1) {
                    updateIds.splice(index, 1)
                }

                const progress = {
                    error: `#${itemNumber} - ${error.response.data.userError}`,
                    status: status,
                    ids: updateIds,
                }

                this.setState({
                    updateStatusProgress: progress,
                })
                hideAlertLoader()

                return
            }
        }

        hideAlertLoader()
        displayAlert(
            'success',
            'Successfully updated the selected item(s) status'
        )
        this.fetchItems(this.state.page)
    }

    handleUpdateStatusError = (value) => {
        const progress = this.state.updateStatusProgress
        progress.error = undefined

        this.setState({ updateStatusProgress: progress }, () => {
            switch (value) {
                case UpdateStatus.NEXT:
                case undefined:
                    this.handleUpdateStatus(
                        progress.status,
                        progress.ids,
                        false
                    )
                    break
                case UpdateStatus.NEXT_WITHOUT_WARNINGS:
                    this.handleUpdateStatus(progress.status, progress.ids, true)
                    break
                default:
                    this.setState({ updateStatusProgress: undefined })
                    break
            }
        })
    }

    createItemAndNavigate = async () => {
        try {
            const projectId = this.state.project.id

            if (localStorage.getItem(FeatureFlags.ItemsRefactor) === 'true') {
                displayAlert('info', 'Generating item...')
                // we need to first create the item before trying to land on
                // the edit page
                const response = await this.api.createProjectItem({ projectId })

                displayAlert('success', 'Item generated!')

                this.props.navigate(
                    routeParam(URI.project.newItemEdit, {
                        id: projectId,
                        itemId: response.id,
                    })
                )
            } else {
                setTimeout(() => {
                    this.props.navigate(
                        routeParam(URI.project.itemAdd, {
                            id: projectId,
                        })
                    )
                }, 500)
            }
        } catch (e) {
            displayAlert('error', 'Error generating item')
        }
    }

    renderListFilter() {
        const { viewType } = this.state

        return (
            <ListFilter className="project-item-view-filter" expandable={1}>
                <ListFilter.Fields
                    lg={12}
                    xl={8}
                    xxl={8}
                    className="position-relative zi-10 width-20"
                >
                    <ListFilter.Field>
                        <Form.Label className="text-end">
                            <strong>Location</strong>
                        </Form.Label>
                        <AsyncLocationsDropdown
                            urlQuery={this.state.locationsQry}
                            isDisabled={!this.state.dataIsLoaded}
                            onChange={this.handleFilter('locationFilter')}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: 230,
                                }),
                            }}
                            defaultValue={this.state.locationFilter}
                            className="react-select"
                            placeholder="Please select"
                            prependOptions={[
                                {
                                    label: 'All',
                                    value: '',
                                },
                            ]}
                        />
                    </ListFilter.Field>
                    <ListFilter.Field>
                        <Form.Label className="text-end">
                            <strong>
                                Sales
                                <br />
                                Category
                            </strong>
                        </Form.Label>
                        <AsyncSalesCategoriesDropdown
                            isDisabled={!this.state.dataIsLoaded}
                            onChange={this.handleFilter('categoryFilter')}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: 230,
                                }),
                            }}
                            // options={this.state.categories}
                            defaultValue={this.state.categoryFilter}
                            className="react-select"
                            placeholder="Please select"
                            prependOptions={[
                                {
                                    label: 'All',
                                    value: '',
                                },
                            ]}
                            valueKey="value"
                        />
                    </ListFilter.Field>
                    <ListFilter.Field>
                        <Form.Label className="text-end">
                            <strong>Show</strong>
                        </Form.Label>
                        <Select
                            isDisabled={!this.state.dataIsLoaded}
                            onChange={this.handleFilter('statusFilter')}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: 230,
                                }),
                            }}
                            key={`${Math.floor(Math.random() * 1000)}-min`}
                            options={this.state.status}
                            defaultValue={this.state.statusFilter}
                            className="react-select"
                            placeholder="Please select"
                        />
                    </ListFilter.Field>

                    <ListFilter.Field
                        className="flex-xl-column mt-3 mt-md-0"
                        replaceclassmatch="align-items-center"
                        replaceclassmatchwith="align-items-start"
                    >
                        <div className="d-flex align-items-center mb-1">
                            <Button
                                variant={`${compareStr(
                                    viewType,
                                    'table',
                                    'dark',
                                    'light'
                                )}`}
                                size="sm"
                                className={`${compareStr(
                                    viewType,
                                    'table',
                                    'bg-primary-ash'
                                )} square wrap-after py-0`}
                                data-view="table"
                                onClick={this.handleSwitchView}
                            >
                                <i
                                    className="ri-list-unordered fsx-22 position-relative"
                                    style={{ top: '-2px' }}
                                ></i>
                            </Button>
                            <Button
                                variant={`${compareStr(
                                    viewType,
                                    'tree',
                                    'dark',
                                    'light'
                                )}`}
                                size="sm"
                                className={`${compareStr(
                                    viewType,
                                    'tree',
                                    'bg-primary-ash'
                                )} square wrap-after py-0 mx-2`}
                                data-view="tree"
                                onClick={this.handleSwitchView}
                            >
                                <i
                                    className="ri-node-tree fsx-22 position-relative"
                                    style={{ top: '-2px' }}
                                ></i>
                            </Button>
                            <Button
                                variant={`${compareStr(
                                    viewType,
                                    'gallery',
                                    'dark',
                                    'light'
                                )}`}
                                size="sm"
                                className={`${compareStr(
                                    viewType,
                                    'gallery',
                                    'bg-primary-ash'
                                )} square wrap-after py-0 me-2`}
                                data-view="gallery"
                                onClick={this.handleSwitchView}
                            >
                                <i
                                    className="ri-microsoft-fill fsx-22 position-relative"
                                    style={{ top: '-2px' }}
                                ></i>
                            </Button>
                            <MiniZoomer
                                changeZoom={this.handleZoomChange}
                                disabled={this.state.disabledZoomer}
                            ></MiniZoomer>
                        </div>
                    </ListFilter.Field>
                </ListFilter.Fields>
                <ListFilter.Actions lg={12} xl={4} xxl={4}>
                    {this.isDisplaySearch() && (
                        <ListFilter.Action className="me-2 me-sm-3">
                            <Button
                                style={{ height: '32px' }}
                                // disabled={${this.state.}}
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                    )}
                    <Feature name={FeatureFlags.AddInventoryItem}>
                        <ListFilter.Action className="me-2 me-sm-3">
                            <AddDropdown
                                locationQuery={this.state.locationsQry}
                                projectId={this.props.params.id}
                                type={InventoryTypes.Item}
                            />
                        </ListFilter.Action>
                    </Feature>
                    <ListFilter.Action className="me-2 me-sm-3">
                        {!this.props.closed ? (
                            <SecureBootstrapButton
                                style={{ height: '32px' }}
                                attributeNo={14}
                                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
                                as={Link}
                                onClick={() => {
                                    this.createItemAndNavigate()
                                }}
                                variant="primary"
                                size="sm"
                                className="btn-icon"
                                data-testid="list-filter-add-button"
                            >
                                <i className="ri-add-fill"></i> Add
                            </SecureBootstrapButton>
                        ) : (
                            <Button
                                style={{ height: '32px' }}
                                disabled
                                variant="primary"
                                size="sm"
                                className="btn-icon"
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                        )}
                    </ListFilter.Action>
                    <ListFilter.Action className="me-2 me-sm-3">
                        {this.props.closed ? (
                            <Dropdown className="d-inline">
                                <Dropdown.Toggle
                                    style={{ height: '32px' }}
                                    disabled
                                    id="dropdown-autoclose-true"
                                    variant="light"
                                    size="sm"
                                >
                                    Create
                                </Dropdown.Toggle>
                            </Dropdown>
                        ) : (
                            <Dropdown className="d-inline">
                                <Dropdown.Toggle
                                    style={{ height: '32px' }}
                                    id="dropdown-autoclose-true"
                                    variant="light"
                                    size="sm"
                                >
                                    Create
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <SecureDropdownItem
                                        attributeNo={17}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAdd
                                        }
                                        as={Link}
                                        href="#"
                                        to={
                                            getRouteWithParam(
                                                URI.project.proposal.add,
                                                { id: this.props.project.id }
                                            ) + '?isQuickCreate=true'
                                        }
                                        onClick={this.handleDataObjSelected}
                                    >
                                        Proposal
                                    </SecureDropdownItem>
                                    <SecureDropdownItem
                                        attributeNo={18}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAdd
                                        }
                                        as={Link}
                                        href="#"
                                        to={
                                            getRouteWithParam(
                                                URI.project.vendorOrder
                                                    .addPurchaseOrder,
                                                { id: this.props.project.id }
                                            ) + '?isQuickCreate=true'
                                        }
                                        onClick={this.handleDataObjSelected}
                                    >
                                        Purchase Order
                                    </SecureDropdownItem>
                                    <SecureDropdownItem
                                        attributeNo={18}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAdd
                                        }
                                        as={Link}
                                        href="#"
                                        to={
                                            getRouteWithParam(
                                                URI.project.vendorOrder
                                                    .addWorkOrder,
                                                { id: this.props.project.id }
                                            ) + '?isQuickCreate=true'
                                        }
                                        onClick={this.handleDataObjSelected}
                                    >
                                        Work Order
                                    </SecureDropdownItem>
                                    <SecureDropdownItem
                                        attributeNo={51}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                        }
                                        as={Link}
                                        href="#"
                                        to={`${URI.accountsReceivable.clientInvoices.add}?isQuickCreate=true`}
                                        onClick={this.handleDataObjSelected}
                                    >
                                        Invoice
                                    </SecureDropdownItem>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </ListFilter.Action>
                    <ListFilter.Action className="ms-lg-0">
                        {this.props.closed ? (
                            <Dropdown className="d-inline">
                                {' '}
                                <Dropdown.Toggle
                                    style={{ height: '32px' }}
                                    id="dropdown-autoclose-true"
                                    disabled
                                    variant="light"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>
                            </Dropdown>
                        ) : (
                            <Dropdown
                                className="d-flex justify-content-end ms-auto"
                                align="end"
                            >
                                <Dropdown.Toggle
                                    style={{ height: '32px' }}
                                    id="dropdown-autoclose-true"
                                    variant="ivory"
                                    size="sm"
                                >
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <SecureDropdownItem
                                        attributeNo={14}
                                        attributeType={
                                            SECURITY_ATTRIBUTE_TYPES.DenyDel
                                        }
                                        onClick={this.handleTestDeleteObjects.bind(
                                            this
                                        )}
                                        href="#"
                                        className={
                                            this.selectedIds().length === 0
                                                ? 'disabled'
                                                : ''
                                        }
                                    >
                                        <i className="ri-delete-bin-5-line"></i>{' '}
                                        Delete
                                    </SecureDropdownItem>
                                    <Dropdown.Item
                                        onClick={this.handleUpdateStatusModal}
                                        href="#"
                                        className={
                                            this.selectedIds().length === 0
                                                ? 'disabled'
                                                : ''
                                        }
                                    >
                                        <i className="ri-refresh-line"></i>{' '}
                                        Update Status
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        )
    }

    renderTableView() {
        const { imagesLoaded, imageData } = this.state
        const data = this.state.data.slice(0, this.state.pageSize.value)
        const getAddItemURL = (item) => {
            let addItemURL = ''

            if (localStorage.getItem(FeatureFlags.ItemsRefactor) === 'true') {
                addItemURL = generatePath(URI.project.newItemEdit, {
                    id: this.props.project.id,
                    itemId: item.id,
                })
            } else {
                addItemURL = routeParam(URI.project.itemEdit, {
                    id: this.props.project.id,
                    itemId: item.id,
                })
            }

            return addItemURL
        }

        return (
            <div>
                <div className="table-gradient sticky-container">
                    <Table
                        striped
                        responsive
                        className="a-table mt-4"
                        data-testid="items-table"
                    >
                        <thead>
                            <tr key="0" className="a-table-heading">
                                <th align="middle" className="mw-70px">
                                    <div className="d-flex justify-content-center">
                                        <Form.Check
                                            label=""
                                            type="checkbox"
                                            checked={this.selectedIds().length}
                                            className={
                                                this.selectedIds().length
                                                    ? 'line'
                                                    : ''
                                            }
                                            onChange={
                                                this.handleSelectAllChecks
                                            }
                                        />
                                    </div>
                                </th>
                                <th className="w-90px">Image </th>
                                <th className="mw-90px">
                                    <span
                                        className={this.sortClass('item')}
                                        data-field="item"
                                    >
                                        Ref. No
                                    </span>
                                </th>
                                <th className="w-170px">
                                    <span
                                        className={this.sortClass(
                                            'locationName'
                                        )}
                                        data-field="locationName"
                                    >
                                        Location Name
                                    </span>
                                </th>
                                <th className="w-150px">
                                    <span
                                        className={this.sortClass(
                                            'salesCategoryName'
                                        )}
                                        data-field="salesCategoryName"
                                    >
                                        Sales Category
                                    </span>
                                </th>
                                <th className="w-260px">
                                    <span
                                        className={this.sortClass('shortdesc')}
                                        data-field="shortdesc"
                                    >
                                        Description
                                    </span>
                                </th>
                                <th className="mw-70px">
                                    <span
                                        className={this.sortClass('quantity')}
                                        data-field="quantity"
                                    >
                                        Qty
                                    </span>
                                </th>
                                <th className="w-150px">
                                    <span
                                        className={this.sortClass(
                                            'statusnumber'
                                        )}
                                        data-field="statusnumber"
                                    >
                                        Status
                                    </span>
                                </th>
                                <th className="w-100px">
                                    <span
                                        className={this.sortClass(
                                            'latestprpno'
                                        )}
                                        data-field="latestprpno"
                                    >
                                        Last Proposal
                                    </span>
                                </th>
                                <th className="w-70px">
                                    <span
                                        className={this.sortClass(
                                            'latestponum'
                                        )}
                                        data-field="latestponum"
                                    >
                                        Last PO/WO
                                    </span>
                                </th>
                                <th className="w-70px">
                                    <span
                                        className={this.sortClass(
                                            'latestinvno'
                                        )}
                                        data-field="latestinvno"
                                    >
                                        Last Invoice
                                    </span>
                                </th>
                            </tr>
                            <tr
                                className={`a-table-search-fields ${
                                    this.state.showTableSearch ? '' : 'd-none'
                                }`}
                            >
                                <th></th>
                                <th></th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="item"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue('item')}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="locationName"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'locationName'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="salesCategoryName"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'salesCategoryName'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="shortdesc"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'shortdesc'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="quantity"
                                        data-type="number"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'quantity'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="statusnumber"
                                        data-type="number"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'statusnumber',
                                            { valueKey: 'svalue' }
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="latestprpno"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'latestprpno'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="latestponum"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'latestponum'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="latestinvno"
                                        data-type="number"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'latestinvno'
                                        )}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.dataIsLoaded
                                ? showLoading()
                                : this.state.data.length === 0 &&
                                  isFiltered(this.state.searchProperties)
                                ? showEmpty()
                                : data.map((item, i) => (
                                      <tr
                                          key={i}
                                          data-key={i}
                                          className={`${
                                              this.selectedIds()[item.id]
                                                  ? `active`
                                                  : ''
                                          } tr-loading-1`}
                                      >
                                          <td>
                                              <div className="d-flex justify-content-center">
                                                  <Form.Check
                                                      label=""
                                                      type="checkbox"
                                                      checked={this.selectedIds().includes(
                                                          item.id
                                                      )}
                                                      onChange={() => {
                                                          this.handleCheck(item)
                                                      }}
                                                  />
                                              </div>
                                          </td>
                                          <td className="td-1">
                                              {renderItemImage({
                                                  item,
                                                  className:
                                                      'img-thumbnail p-0',
                                                  imagesLoaded,
                                                  imageData,
                                                  property: 'primaryImageId',
                                                  altProperty: 'itemName',
                                              })}
                                          </td>
                                          <td>
                                              {this.props.closed ? (
                                                  item.item
                                              ) : (
                                                  <div className="d-flex flex-row align-content-center align-items-center">
                                                      <Link
                                                          to={getAddItemURL(
                                                              item
                                                          )}
                                                          className="text-charcoal hover-view-icon"
                                                      >
                                                          {item.item}
                                                      </Link>
                                                      {item.numAttachments >
                                                          0 && (
                                                          <Button
                                                              as={Link}
                                                              to={generatePath(
                                                                  URI.project
                                                                      .itemEditPage,
                                                                  {
                                                                      id: this
                                                                          .props
                                                                          .project
                                                                          .id,
                                                                      itemId: item.id,
                                                                      page: 'documents',
                                                                  }
                                                              )}
                                                              variant="ivory"
                                                              size="sm"
                                                              className="border-0 fsx-16 ri-attachment-2 square ms-1"
                                                              style={{
                                                                  textDecoration:
                                                                      'none',
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )}
                                          </td>
                                          <td>{item.locationName}</td>
                                          <td>{item.salesCategoryName}</td>
                                          <td className="text-break">
                                              {truncateCharCount(
                                                  item.desc,
                                                  100
                                              )}
                                          </td>
                                          <td>
                                              {item.quantity && item.unitmeasure
                                                  ? item.quantity +
                                                    ' ' +
                                                    item.unitmeasure
                                                  : item.quantity ||
                                                    item.unitmeasure ||
                                                    0}
                                          </td>
                                          <td>
                                              {this.props.statuses.get(
                                                  item.statusnumber
                                              ) ? (
                                                  <strong
                                                      className={`dot`}
                                                      style={{
                                                          '--color':
                                                              this.props.statuses.get(
                                                                  item.statusnumber
                                                              )?.color ??
                                                              'white',
                                                      }}
                                                  >
                                                      {
                                                          this.props.statuses.get(
                                                              item.statusnumber
                                                          )?.name
                                                      }
                                                  </strong>
                                              ) : (
                                                  <strong>{'<Not Set>'}</strong>
                                              )}
                                          </td>
                                          <td>
                                              {this.props.closed ? (
                                                  item.latestprpno
                                              ) : (
                                                  <Link
                                                      to={getRouteWithParam(
                                                          URI.project.proposal
                                                              .edit,
                                                          {
                                                              id: this.props
                                                                  .params.id,
                                                              pid: item.latestPrpId,
                                                          }
                                                      )}
                                                      className="text-charcoal hover-view-icon"
                                                  >
                                                      {item.latestprpno}
                                                  </Link>
                                              )}
                                          </td>
                                          <td>
                                              {this.props.closed ? (
                                                  item.latestponum
                                              ) : (
                                                  <Link
                                                      to={getRouteWithParam(
                                                          URI.project
                                                              .vendorOrder
                                                              .editPurchaseOrder,
                                                          {
                                                              id: this.props
                                                                  .project.id,
                                                              orderId:
                                                                  item.latestponum,
                                                          }
                                                      )}
                                                      className="text-charcoal hover-view-icon"
                                                  >
                                                      {item.latestponum}
                                                  </Link>
                                              )}
                                          </td>
                                          <td>
                                              {item.latestinvno === 0 ? (
                                                  ''
                                              ) : (
                                                  <Link
                                                      to={getRouteWithParam(
                                                          URI.accountsReceivable
                                                              .clientInvoices
                                                              .existingInvoiceView,
                                                          {
                                                              invnum: item.latestinvno,
                                                          }
                                                      )}
                                                      className="text-charcoal hover-view-icon"
                                                  >
                                                      {item.latestinvno}
                                                  </Link>
                                              )}
                                          </td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                </div>
                {this.renderPagination()}
            </div>
        )
    }

    renderTreeView() {
        return (
            <div className="mt-5">
                <TreeView data={this.state.tree} variant={'style-2'}></TreeView>
            </div>
        )
    }

    renderGalleryView() {
        return (
            <>
                <ImageView
                    data={this.state.data}
                    className="mt-5"
                    handleSelectItem={this.handleChecks}
                    size={this.state.gallerySize}
                    imagesLoaded={this.state.imagesLoaded}
                    imageData={this.state.imageData}
                ></ImageView>
                {this.renderPagination()}
            </>
        )
    }

    renderContent() {
        if (
            (this.state.data && this.state.data.length > 0) ||
            isFiltered(this.state.searchProperties) ||
            !this.state.dataIsLoaded
        ) {
            if (this.state.viewType === 'table') {
                return this.renderTableView()
            }

            if (this.state.viewType === 'tree') {
                return this.renderTreeView()
            }

            if (this.state.viewType === 'gallery') {
                return this.renderGalleryView()
            }
        } else {
            return (
                <div className="row justify-content-center text-center py-5">
                    <div className="col-md-3">
                        <img src={noItems} className="mw-100 mb-4" alt="" />

                        <h6>Create an Item</h6>
                        <p>
                            This is where you manage the items to include in
                            your project.
                        </p>

                        <SecureBootstrapButton
                            attributeNo={14}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
                            as={Link}
                            to={generatePath(URI.project.itemAdd, {
                                id: this.props.project.id,
                            })}
                            variant="primary"
                            size="md"
                            className="btn-icon mt-4"
                            data-testid="add-an-item-button"
                        >
                            <i className="ri-add-line ri-lg"></i> Add an Item
                        </SecureBootstrapButton>
                    </div>
                </div>
            )
        }
    }

    renderPagination() {
        if (this.state.data.length) {
            return (
                <div className={'d-flex flex-wrap justify-content-center'}>
                    <div
                        className={
                            'd-flex flex-row align-items-center pagination'
                        }
                    >
                        <Button
                            variant="ivory"
                            size="sm"
                            className={'btn-icon pagination-btn'}
                            disabled={this.state.page === 1}
                            onClick={() =>
                                this.handleChangePage(this.state.page - 1)
                            }
                        >
                            <i className="ri-arrow-left-s-line"></i> Previous
                        </Button>
                        <span className={'pagination-span'}>
                            {this.state.page}
                        </span>
                        <Button
                            variant="ivory"
                            size="sm"
                            className={'btn-icon pagination-btn'}
                            disabled={
                                this.state.data.length <=
                                this.state.pageSize.value
                            }
                            onClick={() =>
                                this.handleChangePage(this.state.page + 1)
                            }
                        >
                            Next <i className="ri-arrow-right-s-line ms-1"></i>
                        </Button>
                    </div>
                    <Select
                        onChange={this.handleFilter('pageSize')}
                        key={`${Math.floor(Math.random() * 1000)}-min`}
                        options={this.availablePageSize}
                        defaultValue={this.state.pageSize}
                        className="react-select pagination-select mx-3"
                        placeholder="Please select"
                    />
                </div>
            )
        }
    }

    getSelectedItemStatus = () => {
        const options = Array.from(this.props.statuses).map(([key, value]) => {
            return { value: key, label: value.name }
        })

        const statuses = Array.from(
            new Set(this.state.checks.map((item) => item.statusnumber))
        )

        if (statuses.length === 1) {
            return options.find((option) => option.value === statuses[0])
        } else {
            return options.find((option) => option.value === -999)
        }
    }

    render() {
        return (
            <SecureContent
                attributeNo={14}
                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
            >
                <SecureContent.HasAccess>
                    {/* Filter */}
                    {this.renderListFilter()}

                    {/* Content */}
                    {this.renderContent()}

                    <DeleteModal
                        refreshData={this.refreshDataAfterDelete.bind(this)}
                        show={this.state.showModal}
                        hideModal={this.hideModal}
                        responses={this.state.deleteResponses}
                    />
                    {this.state.isShowingUpdateStatusModal && (
                        <DropdownModal
                            option={(props) => {
                                return (
                                    <UpdateStatusDropdownOption
                                        {...props}
                                        data={{
                                            ...props.data,
                                            statuses: this.props.statuses,
                                        }}
                                    />
                                )
                            }}
                            singleValue={(props) => {
                                return (
                                    <UpdateStatusDropdownSingleValue
                                        {...props}
                                        data={{
                                            ...props.data,
                                            statuses: this.props.statuses,
                                        }}
                                    />
                                )
                            }}
                            message={`Select a status to apply to the ${
                                this.selectedIds().length
                            } chosen items:`}
                            options={Array.from(this.props.statuses).map(
                                ([key, value]) => {
                                    return { value: key, label: value.name }
                                }
                            )}
                            onCancel={() => {
                                this.setState({
                                    isShowingUpdateStatusModal: false,
                                })
                            }}
                            onConfirm={(value) =>
                                this.handleUpdateStatus(
                                    value,
                                    this.selectedIds()
                                )
                            }
                            value={this.getSelectedItemStatus()}
                        />
                    )}
                    {this.state.updateStatusProgress?.error && (
                        <DropdownModal
                            message={this.state.updateStatusProgress?.error}
                            options={this.updateStatusOptions}
                            onConfirm={this.handleUpdateStatusError}
                            hasCancelButton={false}
                        />
                    )}
                </SecureContent.HasAccess>
            </SecureContent>
        )
    }
}

const UpdateStatusDropdownOption = (props) => {
    return (
        <components.Option {...props}>
            <strong
                className="dot"
                style={{
                    '--color':
                        props.data.statuses.get(props.data.value)?.color ??
                        'white',
                }}
            >
                {props.data.statuses.get(props.data.value)?.name ?? ''}
            </strong>
        </components.Option>
    )
}

const UpdateStatusDropdownSingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            <strong
                className="dot"
                style={{
                    '--color':
                        props.data.statuses.get(props.data.value)?.color ??
                        'white',
                }}
            >
                {props.data.label}
            </strong>
        </components.SingleValue>
    )
}

export default WithRouter(ProjectViewItem)
