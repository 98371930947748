import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Form,
    Row,
    Col,
    Table,
    Button,
    Breadcrumb,
    Container,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import { fakeData } from '../../../factory/fakeFactory'
import ListFilter from '../../../components/ListFilter'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { FooterFormAction } from '../../../components/Form'

class JournalEntryRecurring extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: fakeData(3),
            dataIsLoaded: false,
        }
    }

    handleDateChange = (date) => {
        this.setState((prevState) => (prevState.startDate = date))
    }

    componentInit() {
        this.setTitle('Recurring Journal Entries').setFormAction(true)

        // Set API calls.
        this.setApiURL('/data/products.json')
            .setApiParams({ responseKey: 'response' })
            .enableSearchAndSortTable()
            .fetchData()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                // eslint-disable-next-line
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                // eslint-enable-next-line
            }

            prevState.totalChecked = totalChecked

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[i] = true
                        document.getElementById(
                            'chk-projectview-items-' + i
                        ).checked = true
                        return null
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.generalLedger.journalEntry.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.generalLedger.journalEntry.base,
                            }}
                            linkAs={Link}
                        >
                            General Ledger
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Recurring Journal Entries
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Recurring Journal Entries
                    </HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderListFilter() {
        return (
            <>
                <ListFilter expandable={0}>
                    <ListFilter.Actions md={12} lg={12}>
                        <ListFilter.Action className="ms-auto">
                            <Button
                                as={Link}
                                to={
                                    URI.generalLedger.journalEntry
                                        .recurringJournalEntryAdd
                                }
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                <i className="ri-add-fill"></i> Add
                            </Button>
                            <Button
                                as={Link}
                                to={'#'}
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                Delete
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderContent() {
        return (
            <Form.Group className="mb-5">
                {this.renderListFilter()}

                <Row className="py-2">
                    <Col sm={12} className="mb-3 mb-lg-0 position-relative">
                        <div className="table-gradient">
                            <Table
                                striped
                                responsive
                                className="a-table table-check"
                            >
                                <thead>
                                    <tr key="0" className="a-table-heading">
                                        <th align="middle" className="w-90px">
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`inline-check-th-0`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-check-th-0`}
                                                    defaultChecked={
                                                        this.state
                                                            .totalChecked > 0
                                                            ? true
                                                            : false
                                                    }
                                                    className={`${
                                                        this.state
                                                            .totalChecked > 0 &&
                                                        this.state
                                                            .totalChecked <
                                                            this.state.data
                                                                .length
                                                            ? 'line'
                                                            : ''
                                                    }`}
                                                    onClick={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <span className="sort active asc">
                                                Entry No
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Description
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Reminder
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => {
                                        if (i < 5) {
                                            return (
                                                <tr
                                                    key={i}
                                                    className={
                                                        this.state.checks[i]
                                                            ? `active`
                                                            : ''
                                                    }
                                                >
                                                    <td
                                                        align="middle"
                                                        className="w-90px"
                                                    >
                                                        <Form.Check
                                                            inline
                                                            label=""
                                                            name={`group-` + i}
                                                            type="checkbox"
                                                            data-id={i}
                                                            id={
                                                                `chk-projectview-items-` +
                                                                i
                                                            }
                                                            className="chk-projectview-items-item"
                                                            onChange={
                                                                this
                                                                    .handleChecks
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={'/'}
                                                            className="text-charcoal hover-view-icon"
                                                        >
                                                            {item.projectCodeNo}
                                                        </Link>
                                                    </td>
                                                    <td>{item.Lorem}</td>
                                                    <td>
                                                        Lorem ipsum dolor sit
                                                        amet, consetetur
                                                        sadipscing elitr, sed
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        return ''
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(JournalEntryRecurring)
