import { RTFJS } from 'rtf.js'
import { stringToArrayBuffer } from '../helpers/String'

export async function mapRtfToDraft(rtf: string) {
    if (!rtf) {
        return ''
    }
    let html: any = ''

    try {
        const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {})

        const htmlElements = await doc.render()
        html = htmlElements
            .map((element) => {
                return element.outerHTML
            })
            .join(' ')
    } catch (error) {
        html = rtf
    }

    return `${html}`
}
