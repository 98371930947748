import React from 'react'
import {
    Col,
    Row,
    Form,
    Container,
    Breadcrumb,
    Button,
    Table,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import { ApiService } from '../../../lib/api/HttpService'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import Select from 'react-select'
import { generatePath } from 'react-router'
import ConfirmModal from '../../../app/components/modal/ConfirmModal'
import { displayAlert } from '../../../utilities/Response'
import _ from 'lodash'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import SecureLink from '../../../app/components/security/SecureLink'
import { nameToCode, formatCode } from 'app/utils/codes'

class GlossaryAddSalesTaxCodes extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            dataIsLoaded: false,
            taxc: '',
            tm: true,
            td: true,
            tf: true,
            ti: true,
            tl: true,
            to: true,
            inactive: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
            accounts: [],
            activeId: null,
            showModal: false,
            checks: [],
            company: {},
        }

        this.api = new ApiService()
        this.updatedData = {}

        this.statesOptions = [
            { value: 'AL', label: 'Alabama' },
            { value: 'AK', label: 'Alaska' },
            { value: 'AZ', label: 'Arizona' },
            { value: 'AR', label: 'Arkansas' },
            { value: 'CA', label: 'California' },
            { value: 'CO', label: 'Colorado' },
            { value: 'CT', label: 'Connecticut' },
            { value: 'DE', label: 'Delaware' },
            { value: 'DC', label: 'District of Columbia' },
            { value: 'FL', label: 'Florida' },
            { value: 'GA', label: 'Georgia' },
            { value: 'HI', label: 'Hawaii' },
            { value: 'ID', label: 'Idaho' },
            { value: 'IL', label: 'Illinois' },
            { value: 'IN', label: 'Indiana' },
            { value: 'IA', label: 'Iowa' },
            { value: 'KS', label: 'Kansas' },
            { value: 'KY', label: 'Kentucky' },
            { value: 'LA', label: 'Louisiana' },
            { value: 'ME', label: 'Maine' },
            { value: 'MD', label: 'Maryland' },
            { value: 'MA', label: 'Massachusetts' },
            { value: 'MI', label: 'Michigan' },
            { value: 'MN', label: 'Minnesota' },
            { value: 'MS', label: 'Mississippi' },
            { value: 'MO', label: 'Missouri' },
            { value: 'MT', label: 'Montana' },
            { value: 'NE', label: 'Nebraska' },
            { value: 'NV', label: 'Nevada' },
            { value: 'NH', label: 'New Hampshire' },
            { value: 'NJ', label: 'New Jersey' },
            { value: 'NM', label: 'New Mexico' },
            { value: 'NY', label: 'New York' },
            { value: 'NC', label: 'North Carolina' },
            { value: 'ND', label: 'North Dakota' },
            { value: 'OH', label: 'Ohio' },
            { value: 'OK', label: 'Oklahoma' },
            { value: 'OR', label: 'Oregon' },
            { value: 'PA', label: 'Pennsylvania' },
            { value: 'RI', label: 'Rhode Island' },
            { value: 'SC', label: 'South Carolina' },
            { value: 'SD', label: 'South Dakota' },
            { value: 'TN', label: 'Tennessee' },
            { value: 'TX', label: 'Texas' },
            { value: 'UT', label: 'Utah' },
            { value: 'VT', label: 'Vermont' },
            { value: 'VA', label: 'Virginia' },
            { value: 'WA', label: 'Washington' },
            { value: 'WV', label: 'West Virginia' },
            { value: 'WI', label: 'Wisconsin' },
            { value: 'WY', label: 'Wyoming' },
        ]
        this.isEditing = false
    }

    componentInit() {
        this.code = this.props.params.code

        this.setTitle(
            `${this.isEditing ? 'Edit' : 'Add'} Sales Tax Code | Glossary`
        )
        this.setFormAction(true)
    }

    async componentDidMount() {
        this.isEditing = Boolean(this.code)
        this.dMloader(true)
        await this.fetchAccounts()
        await this.fetchCompany()
        await this.fetchDataIfEditing()

        setTimeout(() => {
            this.setState(
                {
                    showButtons: true,
                },
                () => this.dMloader(false)
            )
        }, 250)
    }

    componentWillUnmount() {
        const taxCode = getLocalStorage('sales_tax_data', true)

        if (
            taxCode &&
            this.props.paths.state &&
            taxCode.taxc !== this.props.paths.state.taxc
        ) {
            window.localStorage.removeItem('sales_tax_data')
        }
    }

    async fetchAccounts() {
        const accountsRes = await this.api.getGLAccounts()
        const accounts = accountsRes.map((account) => {
            return {
                value: account.account,
                label: `${account.accountn} - ${account.account}`,
            }
        })

        this.setState({
            accounts,
        })
    }

    async fetchCompany() {
        const company = await this.api.get('company', '')
        this.setState({
            company,
        })
    }

    async fetchDataIfEditing() {
        if (!this.isEditing) {
            this.updatedData = {
                ...this.updatedData,
                invdesc: 'Sales Tax',
            }

            this.retrieveDraft()
            return
        }

        const salesTaxCodes = await this.api.getSalesTaxCodes(
            `?$filter=taxc eq '${this.code}'`
        )

        let data = {}

        if (salesTaxCodes && salesTaxCodes.length) {
            data = salesTaxCodes[0]
        }

        this.setData(data)
    }

    setData(data) {
        this.setState(
            {
                data: data,
                dataIsLoaded: true,
                tm: data.tm,
                td: data.td,
                tf: data.tf,
                ti: data.ti,
                tl: data.tl,
                to: data.to,
                inactive: data.inactive,
            },
            this.retrieveDraft
        )
    }

    async retrieveDraft() {
        const doSaveChanges = JSON.parse(getLocalStorage('doSaveChanges'))
        let data = doSaveChanges
            ? getLocalStorage('sales_tax_data', true)
            : Object.keys(this.updatedData).length > 0
            ? this.updatedData
            : this.state.data
        setLocalStorage('doSaveChanges', false)

        if (!this.isEditing) {
            data.tm = data?.tm ?? this.state.tm
            data.td = data?.td ?? this.state.td
            data.tf = data?.tf ?? this.state.tf
            data.ti = data?.ti ?? this.state.ti
            data.tl = data?.tl ?? this.state.tl
            data.to = data?.to ?? this.state.to
        }

        this.updatedData = data

        const taxCalculations = data?.taxc
            ? await this.api.getSalesTaxRules(`?$filter=taxc eq '${data.taxc}'`)
            : []
        data.taxCalculations = taxCalculations

        const isDraft = JSON.parse(getLocalStorage('isDraft'))
        if (isDraft) {
            this.setDraft()
        }

        this.setState({
            data: data,
            dataIsLoaded: true,
            tm: data.tm,
            td: data.td,
            tf: data.tf,
            ti: data.ti,
            tl: data.tl,
            to: data.to,
            inactive: data.inactive,
        })
    }

    handleTaxCodeChange = _.debounce(async (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value

        if (value) {
            const isTaxCodeExist = await this.checkTaxCodeExist(value)
            if (isTaxCodeExist) {
                this.setIsLoading(true)
                setLocalStorage('isDraft', false)
                displayAlert(
                    'danger',
                    'Another Sales Tax entry already exists with the same code as this one! Please change the current code before adding a new Sales Tax Rule.'
                )
                return
            }

            this.setIsLoading(false)
            setLocalStorage('isDraft', true)
        }

        this.updatedData[key] = value

        this.setState({
            [key]: value,
        })
    }, 500)

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }

            if (!this.isEditing && key === 'taxcn') {
                const taxCode = nameToCode(value)
                this.updatedData['taxc'] = taxCode
            }

            let state = this.state
            state[key] = value
            this.setState(state)
        }
        this.updatedData[key] = value

        this.setDraft()
    }

    handleClickSave = async (e) => {
        e.preventDefault()

        this.setIsLoading(true)
        setLocalStorage('isDraft', 'false')
        window.onbeforeunload = null

        const path =
            e.target.name === 'save'
                ? getRouteWithParam(URI.glossary.list, {
                      path: 'sales-tax-codes',
                  })
                : getRouteWithParam(URI.glossary.add.salesTaxCodes)

        if (this.isEditing) {
            delete this.updatedData.taxCalculations
            await this.api
                .editSalesTaxCodes({
                    taxc: this.state.data.taxc,
                    ...this.updatedData,
                })
                .then((res) => {
                    displayAlert('success', 'Successfully updated tax code')
                    this.resetStorage()
                    setTimeout(() => {
                        window.location.href = path
                    }, 2000)
                })
                .catch((err) => {
                    const errorMessage =
                        err.response?.data?.userError ||
                        'Sales tax code could not be saved'
                    displayAlert('danger', errorMessage)
                    this.setIsLoading(false)
                })
        } else {
            let taxCodeData = this.updatedData

            await this.api
                .createSalesTaxCodes(this.updatedData)
                .then((res) => {
                    displayAlert('success', 'Successfully created new tax code')
                    this.resetStorage()
                    if (e.target.name === 'saveNew') {
                        this.updatedData = {}
                        this.setState({
                            showButtons: true,
                        })
                    }
                    delete taxCodeData.taxCalculations
                    setTimeout(() => {
                        window.location.href = path
                    }, 2000)
                })
                .catch((err) => {
                    const errorMessage =
                        err.response?.data?.userError ||
                        'Sales tax code could not be saved'
                    displayAlert('danger', errorMessage)
                    this.setIsLoading(false)
                })
        }
    }

    async checkTaxCodeExist(val) {
        const hasTaxCode = await this.api.getSalesTaxCodes(
            `?$filter=taxc eq '${val}'`
        )

        return hasTaxCode.length
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    resetStorage() {
        setLocalStorage('isDraft', 'false')
        window.localStorage.removeItem('sales_tax_data')
        window.localStorage.removeItem('doSaveChanges')
    }

    handleClick = (e) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            if (confirm('Leaving this screen will discard your changes.')) {
                this.resetStorage()
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    handleSaveChanges = (e) => {
        setLocalStorage('doSaveChanges', true)
        setLocalStorage('sales_tax_data', JSON.stringify(this.updatedData))
        setLocalStorage('isEditing', this.isEditing)
        setLocalStorage('isDraft', true)
    }

    handleAddTaxCalculation = async (e) => {
        this.handleSaveChanges()
        window.location.href = `${generatePath(
            URI.glossary.add.salesTaxCalculation,
            { taxc: this.updatedData.taxc || this.code }
        )}`
    }

    handleDelete = () => {
        this.setState({
            showModal: true,
        })
    }

    toggleModal = (e) => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    async deleteData(e) {
        setLocalStorage('isDraft', true)

        this.toggleModal()

        const items = this.state.data.taxCalculations.filter((item) => {
            return this.state.checks.includes(item.seqnum)
        })

        const promises = []

        for (const item of items) {
            promises.push(
                await this.api.deleteSalesTaxCodeCalculations(
                    `?TaxCode=${item.taxc}&SeqNum=${item.seqnum}`
                )
            )
        }

        Promise.all(promises)
            .then(() => {
                displayAlert('success', 'The data was successfully deleted.')
                window.onbeforeunload = null
                setTimeout(() => {
                    location.reload(true)
                }, 2000)
            })
            .catch((error) => {
                displayAlert('danger', 'The data could not be deleted.')
            })
    }

    handleSelectAllChecks = () => {
        let checks = []
        if (
            this.state.checks.length !== this.state.data.taxCalculations.length
        ) {
            checks = this.state.data.taxCalculations.map((item) => {
                return item.seqnum
            })
        }

        this.setState({
            checks,
        })
    }

    handleCheck = (id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === id)

        index > -1 ? checks.splice(index, 1) : checks.push(id)

        this.setState({
            checks,
        })
    }

    createDescription(rule) {
        let desc = ''
        if (rule.ontotal) {
            desc += 'Total'
        } else if (rule.onbelow) {
            desc += 'Below ' + rule.lowamt
        } else if (rule.onabove) {
            desc += 'Above ' + rule.highamt
        } else if (rule.onbetween) {
            desc += 'Between ' + rule.lowamt + ' and ' + rule.highamt
        }

        if (rule.onunit) {
            desc += ' on each unit'
        } else {
            desc += ' on total item'
        }

        return desc
    }

    renderHeader() {
        const { isLoading, isDraft } = this.state

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        onClick={this.handleClick}
                        to={getRouteWithParam(URI.glossary.list, {
                            path: 'sales-tax-codes',
                        })}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.settings.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{
                                to: getRouteWithParam(URI.glossary.list, {
                                    path: 'sales-tax-codes',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Sales Tax Code
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: '#' }}
                            linkAs={Link}
                        >
                            {this.isEditing ? 'Edit' : 'Add'} Sales Tax Code{' '}
                            {this.isEditing && this.code}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>Sales Tax Code</HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <SecureBootstrapButton
                            attributeNo={45}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            className={
                                this.props.params.code
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </SecureBootstrapButton>

                        <SecureBootstrapButton
                            attributeNo={45}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </SecureBootstrapButton>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { data, tm, td, tf, tl, to, ti, inactive } = this.state

        return (
            <Container className="ms-0">
                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-3">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    data-field="taxcn"
                                    placeholder="Please enter"
                                    value={this.state.data.taxcn}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Tax Code
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={5}
                                            data-field="taxc"
                                            placeholder="Please enter"
                                            defaultValue={this.state.data.taxc}
                                            disabled={this.isEditing}
                                            readOnly={this.isEditing}
                                            onChange={(e) => {
                                                e.target.value = formatCode(
                                                    e.target.value
                                                )
                                                this.handleTaxCodeChange(e)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    State Abbrev.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 99999,
                                                }),
                                            }}
                                            key={`${Math.floor(
                                                Math.random() * 10000
                                            )}-min`}
                                            options={this.statesOptions}
                                            className="react-select"
                                            placeholder="Please select"
                                            data-field="state"
                                            name="state"
                                            defaultValue={this.statesOptions.filter(
                                                (item) => {
                                                    return (
                                                        item.value ===
                                                        (this.updatedData
                                                            .state ??
                                                            data.state)
                                                    )
                                                }
                                            )}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey px-4">
                    <Row
                        xs={'auto'}
                        className="py-4 justify-content-between align-items-center"
                    >
                        <Col>
                            <h6 className="fw-bold mb-0">Tax Calculations</h6>
                        </Col>
                        <Col>
                            <Row>
                                {this.isEditing && (
                                    <Col>
                                        <SecureBootstrapButton
                                            attributeNo={45}
                                            attributeType={
                                                SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                            }
                                            as={Link}
                                            to="#"
                                            variant="ivory"
                                            size="sm"
                                            className={`btn-icon btn-action ${
                                                this.state.checks.length === 0
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            disabled={
                                                this.state.checks.length === 0
                                            }
                                            onClick={this.handleDelete}
                                        >
                                            <i className="ri-close-line"></i>{' '}
                                            Delete
                                        </SecureBootstrapButton>
                                    </Col>
                                )}
                                {this.state.data.taxc ||
                                this.updatedData.taxc ? (
                                    <Col>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            className={`btn-icon btn-action`}
                                            onClick={
                                                this.handleAddTaxCalculation
                                            }
                                        >
                                            <i className="ri-add-line"></i> Add
                                        </Button>
                                    </Col>
                                ) : (
                                    <OverlayTrigger
                                        id="otr"
                                        overlay={
                                            <Tooltip id="selprop">
                                                Tax Code must be filled in
                                                before a rule can be added.
                                            </Tooltip>
                                        }
                                    >
                                        <Col>
                                            <SecureBootstrapButton
                                                attributeNo={45}
                                                attributeType={
                                                    SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                                }
                                                variant="primary"
                                                size="sm"
                                                className={`btn-icon btn-action disabled`}
                                            >
                                                <i className="ri-add-line"></i>{' '}
                                                Add
                                            </SecureBootstrapButton>
                                        </Col>
                                    </OverlayTrigger>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row className="mt-4">
                    <Col
                        xs={12}
                        className="table-actions position-relative table-action-bar"
                    >
                        <div className="table-gradient taxCalculationsTable">
                            <Table striped responsive className="a-table">
                                <thead>
                                    <tr className="a-table-heading">
                                        <th align="middle" className="mw-70px">
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    label=""
                                                    type="checkbox"
                                                    checked={
                                                        this.state.checks
                                                            .length ===
                                                        data.taxCalculations
                                                            ?.length
                                                    }
                                                    className={
                                                        this.state.checks
                                                            .length ===
                                                        data.taxCalculations
                                                            ?.length
                                                            ? 'line'
                                                            : ''
                                                    }
                                                    onChange={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th className="w-180px ps-4">
                                            <span className="sort active">
                                                Authority Name
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">
                                                {this.state.company.compviewm} %
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">
                                                {this.state.company.compviewd} %
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">
                                                {this.state.company.compviewf} %
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">
                                                {this.state.company.compviewi} %
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">
                                                {this.state.company.compviewl} %
                                            </span>
                                        </th>
                                        <th className="">
                                            <span className="sort">Time %</span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Calculations
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data.taxCalculations || []).map(
                                        (item, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            label=""
                                                            type="checkbox"
                                                            checked={this.state.checks.includes(
                                                                item.seqnum
                                                            )}
                                                            onChange={() => {
                                                                this.handleCheck(
                                                                    item.seqnum
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="ps-4">
                                                    <SecureLink
                                                        attributeNo={45}
                                                        attributeType={
                                                            SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                                        }
                                                        onClickCapture={
                                                            this
                                                                .handleSaveChanges
                                                        }
                                                        to={generatePath(
                                                            URI.glossary.edit
                                                                .salesTaxCalculation,
                                                            {
                                                                taxc: item.taxc,
                                                                seqnum: item.seqnum,
                                                            }
                                                        )}
                                                        className="text-charcoal hover-view-icon"
                                                    >
                                                        {item.rulen || 'N/A'}
                                                    </SecureLink>
                                                </td>
                                                <td>{item.tpm}</td>
                                                <td>{item.tpd}</td>
                                                <td>{item.tpf}</td>
                                                <td>{item.tpi}</td>
                                                <td>{item.tpl}</td>
                                                <td>{item.tpo}</td>
                                                <td>
                                                    {this.createDescription(
                                                        item
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        Tax Information
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-3">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Sales Tax Withholding Account
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row
                                    xs={1}
                                    lg={2}
                                    className="align-items-center"
                                >
                                    <Col className="mb-2 mb-lg-0">
                                        <Select
                                            key={`${Math.floor(
                                                Math.random() * 10000
                                            )}-min`}
                                            options={this.state.accounts}
                                            className="react-select"
                                            placeholder="Please select"
                                            name="acct"
                                            defaultValue={this.state.accounts.filter(
                                                (account) => {
                                                    return (
                                                        account.value ===
                                                        (this.updatedData
                                                            .acct ?? data.acct)
                                                    )
                                                }
                                            )}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Inactive"
                                            name="checkbox-group-inactive"
                                            type="checkbox"
                                            data-field="inactive"
                                            checked={inactive}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-start mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Taxable Defaults
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label={this.state.company.compviewm}
                                            name="checkbox-group-merch"
                                            type="checkbox"
                                            className="mb-2 mb-md-3"
                                            data-field="tm"
                                            checked={tm}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label={this.state.company.compviewd}
                                            name="checkbox-group-frei"
                                            type="checkbox"
                                            className="mb-2 mb-md-3"
                                            data-field="td"
                                            checked={td}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label={this.state.company.compviewi}
                                            name="checkbox-group-install"
                                            type="checkbox"
                                            className="mb-2 mb-md-0"
                                            data-field="ti"
                                            checked={ti}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label={this.state.company.compviewf}
                                            name="checkbox-group-desf"
                                            type="checkbox"
                                            className="mb-2 mb-md-0"
                                            data-field="tf"
                                            checked={tf}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label="Crating"
                                            name={this.state.company.compviewl}
                                            type="checkbox"
                                            className="mb-2 mb-md-0"
                                            data-field="tl"
                                            checked={tl}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Check
                                            inline
                                            label={this.state.company.compviewo}
                                            name="checkbox-group-time"
                                            type="checkbox"
                                            className="mb-2 mb-md-0"
                                            data-field="to"
                                            checked={to}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Description for Document Printout
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row
                                    xs={1}
                                    lg={2}
                                    className="align-items-center"
                                >
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            maxLength={30}
                                            data-field="invdesc"
                                            placeholder="Please enter"
                                            value={data.invdesc}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <SecureBootstrapButton
                        attributeNo={45}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        size="lg"
                        onClick={this.handleClickSave}
                        name="save"
                        disabled={!isDraft || isLoading}
                    >
                        Save
                    </SecureBootstrapButton>
                </FooterFormAction>

                <ConfirmModal
                    refreshData={''}
                    show={this.state.showModal}
                    toggleModal={this.toggleModal}
                    confirmAction={this.deleteData.bind(this)}
                />
            </>
        )
    }
}

export default WithRouter(GlossaryAddSalesTaxCodes)
