import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { WithRouter } from '../../../helpers/Router'
import ThreadComponent from '../ThreadComponent'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../lib/api/HttpService'
import { displayAlert } from '../../../utilities/Response'
import dayjs from 'dayjs'
import { _delay } from '../../../helpers/Util'
import { dateToPayload } from '../../../helpers/Date'

class CreateDepositSlipModal extends ThreadComponent {
    constructor(props) {
        super(props)
        this.state = {
            depositDate: new Date(),
            useDate: true,
            slipNumber: dayjs().format('MMDDYY'),
        }

        this.api = new ApiService()
    }

    handleDateChange = (name, date) => {
        if (this.state.useDate) {
            this.setState({
                slipNumber: dayjs(date).format('MMDDYY'),
            })
        }

        this.setState({
            [name]: new Date(date),
        })
    }

    handleChange = (e) => {
        this.setState({
            slipNumber: e.target.value,
        })
    }

    handleAction = async () => {
        const data = {
            slipNumber: this.state.slipNumber,
            slipNotNumberedUseDate: this.state.useDate,
            depositDate: dateToPayload(this.state.depositDate),
        }
        this.props.onPostDepositSlip(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col lg={{ span: 10, offset: 1 }}>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Deposit Date
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={this.state.depositDate}
                                        name="depositDate"
                                        showMonthDropdown="true"
                                        showYearDropdown="true"
                                        className="form-control"
                                        placeholderText="Select"
                                        onChange={this.handleDateChange.bind(
                                            this,
                                            'depositDate'
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Deposit Slip No.
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="slipNumber"
                                        type="text"
                                        placeholder="Please enter"
                                        onChange={this.handleChange}
                                        disabled={this.state.useDate}
                                        value={this.state.slipNumber}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        label="Slips are not numbered, just use the date"
                                        type="checkbox"
                                        checked={this.state.useDate}
                                        onChange={(e) => {
                                            const state = this.state
                                            state.useDate = e.target.checked

                                            if (e.target.checked) {
                                                state.slipNumber = dayjs(
                                                    state.depositDate
                                                ).format('MMDDYY')
                                            }
                                            this.setState(state)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Checks Total
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="checksTotal"
                                        type="text"
                                        disabled="true"
                                        defaultValue={this.props.checksTotal}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Cash Total
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="cashTotal"
                                        type="text"
                                        disabled="true"
                                        defaultValue={this.props.cashTotal}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Total deposit
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="totalDeposit"
                                        type="text"
                                        disabled="true"
                                        defaultValue={this.props.totalDeposit}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={this.handleAction}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal.bind(this)}
                                className="w-100 m-2"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default WithRouter(CreateDepositSlipModal)
