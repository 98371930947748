import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import Select from 'react-select'
import { ApiService } from '../../../lib/api/HttpService'
import { AuthorizationTemplateType } from 'app/enums/settings/userSettings'

const api = new ApiService()

const CUSTOM_ROLE_OPTION = {
    label: AuthorizationTemplateType.Custom,
    value: AuthorizationTemplateType.Custom,
    attribute: AuthorizationTemplateType.Custom,
    isDisabled: true,
}

const getRoles = async () => await api.getAllRoles()

const UserRoleDropdown = ({
    value = null,
    onChange,
    displayCustomRoleOption = false,
}) => {
    const [selectedValue, setSelectedValue] = useState(value)
    const initialValueSet = useRef(false)

    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'roles'],
        queryFn: getRoles,
    })

    const options =
        data?.authorizationTemplates?.map(({ id, type, value }) => ({
            label: `${type}`,
            value: id,
            attribute: value,
        })) || []

    if (displayCustomRoleOption) {
        options.push(CUSTOM_ROLE_OPTION)
    }

    useEffect(() => {
        if (value && options.length > 0 && !initialValueSet.current) {
            const selectedOption = options.find(
                (option) => option.attribute === value
            )
            setSelectedValue(selectedOption)
            initialValueSet.current = true
        }
    }, [value, options])

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption)
        if (onChange) {
            onChange(selectedOption ? selectedOption : null)
        }
    }

    if (isLoading) {
        return <Select options={[{ label: 'Loading...', value: '' }]} />
    }

    return (
        <Select
            placeholder="Please Select"
            options={options}
            onChange={handleSelectChange}
            value={selectedValue}
        />
    )
}

export default UserRoleDropdown
