import IntroductionVideoImage from '../../../assets/images/resources-image-1.png'
import IntroductionAccountImage from '../../../assets/images/resources-image-2.png'
import ManageClientsImage from '../../../assets/images/resources-image-3.png'

interface IGridLinkItem {
    text: string
    subtext?: string
    icon?: string
    url: string
}

interface IResourceConstant {
    sectionOneTitle: string
    sectionOneSubtitle: string
    sectionOneItems: Array<IGridLinkItem>
    sectionTwoTitle: string
    sectionTwoSubtitle: string
    sectionTwoItems: Array<IGridLinkItem>
    gridTitle: string
    bannerTitle: string
    bannerSubtitle: string
    bannerButton: string
    bannerSendMail: string
}

export const ResourcesGridConstants: IResourceConstant = {
    gridTitle: 'Resources',
    sectionOneTitle: 'Tutorials',
    sectionOneSubtitle: 'Start running a great interior design business.',
    sectionOneItems: [
        {
            text: 'Introduction to projects, items, and proposals',
            subtext: '38 minute video',
            icon: IntroductionVideoImage,
            url: 'https://help.designmanager.com/en/articles/9134152-introduction-to-project-features-video',
        },
        {
            text: 'Introduction to accounting features',
            subtext: '31 minute video',
            icon: IntroductionAccountImage,
            url: 'https://help.designmanager.com/en/articles/9134161-introduction-to-accounting-features-video',
        },
        {
            text: 'Managing clients and projects',
            subtext: '19 articles',
            icon: ManageClientsImage,
            url: 'https://help.designmanager.com/en/collections/3972088-managing-working-with-clients-and-projects',
        },
    ],

    sectionTwoTitle: 'Frequently Asked Questions',
    sectionTwoSubtitle: 'Answers to common questions from users.',
    sectionTwoItems: [
        {
            text: 'Where can I learn more about configuring some of the advanced settings?',
            url: 'https://help.designmanager.com/en/collections/5528638-settings',
        },
        {
            text: 'How do I enter a new entry for time billing?',
            url: 'https://help.designmanager.com/en/articles/8011713-adding-a-time-entry',
        },
        {
            text: 'How do I edit a proposal after it has been created?',
            url: 'https://help.designmanager.com/en/articles/8022725-editing-a-proposal',
        },
        {
            text: 'How do I track orders using Design Manager?',
            url: 'https://help.designmanager.com/en/articles/8505705-order-tracking',
        },
    ],
    bannerTitle: 'Suggest improvements',
    bannerSubtitle:
        'How are we doing? Send us your ideas or feedback about Design Manager.',
    bannerButton: 'Give feedback',
    bannerSendMail:
        'mailto:ideas@designmanager.com?subject=Design Manager suggestion/feedback',
}
