import useGetPaymentProcessorInfo from 'lib/api/hooks/useGetPaymentProcessorStatus'
import React, { useEffect, useState } from 'react'

type TOnboardingComponentProps = {
    sessionKey: string
    merchantId: string
    merchantApplicationId: string
}

const OnboardMerchantComponent = ({
    sessionKey,
    merchantId,
    merchantApplicationId,
}: TOnboardingComponentProps): JSX.Element => {
    const [mounted, setIsMounted] = useState(false)
    const { refetch } = useGetPaymentProcessorInfo('Rainforest', {
        enabled: false,
    })

    useEffect(() => {
        setIsMounted(true)
    }, [])

    useEffect(() => {
        const component = document.querySelector(
            'rainforest-merchant-onboarding'
        )
        const onSubmit = () => refetch()

        if (mounted) {
            component?.addEventListener('submitted', onSubmit)
        }
        return () => {
            component?.removeEventListener('submitted', onSubmit)
        }
    }, [mounted, refetch])

    return (
        <div className="tw-mx-auto tw-max-w-3xl">
            <rainforest-merchant-onboarding
                session-key={sessionKey}
                merchant-id={merchantId}
                merchant-application-id={merchantApplicationId}
            />
        </div>
    )
}

OnboardMerchantComponent.displayName = 'OnboardMerchantComponent'

export default OnboardMerchantComponent
