import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Breadcrumb,
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import { fakeData } from '../../../factory/fakeFactory'
import { ActionBarVertical } from '../../../components/ActionBar'
import ListFilter from '../../../components/ListFilter'

class ProjectVendorOrdersAddPurchaseOrderGeneratedPO extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: fakeData(10),
            checks: {},
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
        }
    }

    componentInit() {
        this.setTitle('Edit Generated PO').setFormAction(true)

        // Set API calls.
        this.setApiURL('/data/products.json')
            .setApiParams({ responseKey: 'response' })
            .enableSearchAndSortTable()
            .fetchData()
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        this.setState((prev) => {
            prev.showTableSearch = !prev.showTableSearch
            return prev
        })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsPayable.payment.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.base }}
                            linkAs={Link}
                        >
                            Projects
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.project.view, { id: 1 }),
                            }}
                            linkAs={Link}
                        >
                            JOH01
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(URI.project.viewPage, {
                                    id: 1,
                                    page: 'vendor-orders',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Vendor Orders
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: routeParam(
                                    URI.project.vendorOrder.addPurchaseOrder,
                                    { id: 1, page: 'vendor-orders' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Add Purchase Order
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Generated Purchase Order
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Generated Purchase Orders
                    </HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderFilters() {
        return (
            <>
                <ListFilter expandable={0}>
                    <ListFilter.Actions
                        md={12}
                        lg={12}
                        xl={12}
                        replaceclassmatch="justify-content-xl-end"
                        replaceclassmatchwith="justify-content-lg-end"
                    >
                        <ListFilter.Action className="">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderVerticalActionBar() {
        return (
            <ActionBarVertical>
                <ActionBarVertical.Links>
                    <li>
                        <NavLink
                            to={routeParam(
                                URI.project.vendorOrder
                                    .addPurchaseOrderEditGeneratedPO,
                                { id: 1, page: 'vendor-orders' }
                            )}
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-edit-line mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'#'} className="d-flex align-items-center">
                            <i className="ri-printer-fill mx-0"></i>
                        </NavLink>
                    </li>
                </ActionBarVertical.Links>
            </ActionBarVertical>
        )
    }

    renderContent() {
        return (
            <>
                <Row>
                    <Col lg="12">{this.renderFilters()}</Col>

                    <Col
                        lg="12"
                        className="mt-2 mb-5 pb-5 position-relative table-action-bar"
                    >
                        {this.renderVerticalActionBar()}

                        <div className="table-gradient">
                            <Table
                                striped
                                responsive
                                className="a-table table-check"
                            >
                                <thead>
                                    <tr className="a-table-heading" key="0">
                                        <th className="ps-4 mw-150px">
                                            <span className="sort active">
                                                PO#
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Verbal/Ref. No.
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Order Date
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">Vendor</span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Ship To
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Ship Via
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Ship By
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Designer/Ext.
                                            </span>
                                        </th>
                                        <th>
                                            <span className="sort">
                                                Special Instructions
                                            </span>
                                        </th>
                                    </tr>

                                    <tr
                                        className={`a-table-search-fields ${
                                            this.state.showTableSearch
                                                ? ''
                                                : 'd-none'
                                        }`}
                                    >
                                        <th className="ps-4">
                                            <Form.Control
                                                type="text"
                                                data-field="projectCodeNo"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                        <th>
                                            <Form.Control
                                                type="text"
                                                data-field="clientName"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr
                                            key={i}
                                            data-key={i}
                                            className={
                                                this.state.checks[i]
                                                    ? `active`
                                                    : ''
                                            }
                                        >
                                            <td className="ps-4">ABR01-0006</td>
                                            <td>0123456</td>
                                            <td>{item.dateOpened}</td>
                                            <td>UNIVE / Universal Furniture</td>
                                            <td>ABR01/ Amanda Brown</td>
                                            <td>{item.Lorem}</td>
                                            <td>{item.Lorem}</td>
                                            <td>
                                                Brad Martin x 102
                                                brad@designmanager.com
                                            </td>
                                            <td>{item.Lorem}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ProjectVendorOrdersAddPurchaseOrderGeneratedPO)
