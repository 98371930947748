import { ApiService } from '../../../../lib/networking/HttpService'

export class VendorService extends ApiService {
    getVendors<T = VendorListResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(`/vendors${params || ''}`, {})
    }

    getVendorById<T = VendorListResponse>(id: number): Promise<T> {
        return this.httpWorker.get(`/vendors?$filter=id eq ${id}`, {})
    }

    getVendorStatus<T = VendorStatusResponse>(id: number): Promise<T> {
        return this.httpWorker.get(`/vendors/${id}/status`, {})
    }

    insert<T = VendorListResponse>(params: any): Promise<T> {
        return this.httpWorker.post(`/vendors`, params)
    }

    update<T = VendorListResponse>(id: number, params: any): Promise<T> {
        return this.httpWorker.patch(`/vendors/${id}`, params)
    }

    getInsurances<T = IInsuranceResponse>(params?: string): Promise<T> {
        return this.httpWorker.get(`/insurances${params || ''}`, {})
    }

    delete<T = VendorListResponse>(params: any): Promise<T> {
        return this.httpWorker.post(`/DeleteObjects`, params)
    }
}

export interface VendorStatusResponse {
    openPos: number
    openVendorDeposits: number
    futureDue: number
    grossVendorInvoices: number
    depositApplied: number
    netInvoices: number
    totalPayable: number
    currentAccountsPayable: number
    accountsPayableOnHold: number
    yearToDatePurchases: number
    priorYtdPurchases: number
}

export interface VendorListResponse {
    sortName1: string
    sortName2: string
    typeDesc?: string
    id: number
    vendor: string
    vendorn: string
    venaddrtype: number
    account?: any
    deppct: number
    terms?: any
    shipvia?: string
    payee: string
    payeeaddrtype: number
    irs1099: boolean
    ein?: any
    holdpayments: boolean
    paydays: number
    discperc: number
    discdays: number
    acct?: any
    showfreight: boolean
    bterm1: number
    bterm2: number
    bterm3: number
    notes?: any
    category?: any
    employee: boolean
    onetime: boolean
    basecostperhr: number
    baserateperhr: number
    inactive: boolean
    faxpo: boolean
    faxdial?: any
    poschangedate: boolean
    poseditprice: boolean
    postaxc?: any
    posvoid: boolean
    poscomperc: number
    poswhc?: any
    notesrtf?: string
    emailpo: boolean
    poswhaddrtype: number
    incometype: number
    printacctoncheck: boolean
    tier2costperhr: number
    tier2rateperhr: number
    tier3costperhr: number
    tier3rateperhr: number
    depm: boolean
    depd: boolean
    depf: boolean
    depi: boolean
    depl: boolean
    _1099int: boolean
    _1099rp?: any
    donotallowonspecs: boolean
    designer?: any
    payeesameasvendor: boolean
    markupmerc: number
    markupdesign: number
    markupfreight: number
    markupinstall: number
    markuplabor: number
    usediscountmerc: number
    usediscountdesign: number
    usediscountfreight: number
    usediscountinstall: number
    usediscountlabor: number
    usemarkupspecs: boolean
    usemarkupinvcat: boolean
    address: any
    addressPayee: any
    list: any
    0: any
    numAttachments: number
}

export interface IInsuranceResponse {
    vendor: string
    insuranceno: number
    policytype: string
    carrier: string
    effectivedt: string
    expirationdt: string
    policyno: string
    active: boolean
    ssmaTimeStamp: string
}
