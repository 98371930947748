import { Form, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Pagination } from '../../../app/components/pagination/Pagination'
import { showEmpty, showLoading } from '../../../helpers/Loading'

export const BudgetTable = ({
    data,
    selectedItems,
    isLoading,
    isSearching,
    onSort,
    onSearch,
    onBudget,
    onPaginationChange,
    onSelect,
}) => {
    const [pagination, setPagination] = useState({ size: 20, page: 1 })
    const [checks, setChecks] = useState(selectedItems)
    const [budgets, setBudgets] = useState([])

    useEffect(() => {
        setBudgets(data.budgets.slice(0, pagination.size))
    }, [data])

    useEffect(() => {
        setChecks(selectedItems)
    }, [selectedItems])

    const handlePageSizeChange = (size) => {
        const newPagination = { size: size, page: 1 }
        onPaginationChange(newPagination)
        setPagination(newPagination)
    }
    const handlePageChange = (page) => {
        const newPagination = { ...pagination, page: page }
        onPaginationChange(newPagination)
        setPagination(newPagination)
    }
    const handleCheckAll = () => {
        if (!checks.length) {
            const checked = budgets.map((budget) => {
                return hash(budget)
            })
            onSelect(checked)
            setChecks(checked)
        } else {
            onSelect([])
            setChecks([])
        }
    }
    const handleCheck = (budget) => {
        const _checks = [...checks]
        const index = _checks.findIndex((check) => check === hash(budget))

        index > -1 ? _checks.splice(index, 1) : _checks.push(hash(budget))

        onSelect(_checks)

        setChecks(_checks)
    }
    const hash = (budget) => {
        return JSON.stringify({
            proj: budget.proj,
            type: budget.type,
            code: budget.code,
        })
    }

    return (
        <div className="table-gradient sticky-container w-100">
            <div>
                <Table striped responsive className="a-table">
                    <thead>
                        <tr>
                            <th style={{ width: '50px' }}>
                                <Form.Check
                                    label=""
                                    type="checkbox"
                                    checked={checks.length}
                                    className={checks.length ? 'line' : ''}
                                    onChange={() => {
                                        handleCheckAll()
                                    }}
                                />
                            </th>
                            {data.headers.map((header, index) => {
                                return (
                                    <th key={index}>
                                        <span
                                            className={`
                                        ${header.sortKey ? 'sort' : ''}
                                        ${
                                            header.sortDirection
                                                ? header.sortDirection
                                                : ''
                                        }
                                        ${header.isActive ? 'active' : ''}
                                        `}
                                            onClick={() => {
                                                if (header.sortKey) {
                                                    onSort(header.sortKey)
                                                }
                                            }}
                                        >
                                            {header.label}
                                        </span>
                                    </th>
                                )
                            })}
                        </tr>
                        {isSearching && (
                            <tr>
                                <th></th>
                                {data.headers.map((header, index) => {
                                    return (
                                        <th key={index}>
                                            {
                                                <SearchTextField
                                                    header={header}
                                                    onSearch={onSearch}
                                                />
                                            }
                                        </th>
                                    )
                                })}
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {isLoading
                            ? showLoading()
                            : budgets.length
                            ? budgets.map((budget, index) => {
                                  return (
                                      <tr key={index}>
                                          <td>
                                              <Form.Check
                                                  label=""
                                                  type="checkbox"
                                                  checked={checks.includes(
                                                      hash(budget)
                                                  )}
                                                  onChange={() => {
                                                      handleCheck(budget)
                                                  }}
                                              />
                                          </td>
                                          <td>
                                              <Link
                                                  to="#"
                                                  className="text-charcoal"
                                                  onClick={() => {
                                                      onBudget(budget)
                                                  }}
                                              >
                                                  {budget.typeName}
                                              </Link>
                                          </td>
                                          <td>{budget.code}</td>
                                          <td>{budget.codeName}</td>
                                          <td>{budget.note}</td>
                                          <td>{budget.budgett}</td>
                                          <td>
                                              {budget.overridedep
                                                  ? `${budget.clientdep}%`
                                                  : 'No'}
                                          </td>
                                          <td>
                                              {budget.overridemu ? 'Yes' : 'No'}
                                          </td>
                                      </tr>
                                  )
                              })
                            : showEmpty()}
                    </tbody>
                </Table>
            </div>
            {data.budgets.length > 0 && (
                <Pagination
                    onPageSizeChanged={handlePageSizeChange}
                    onPageChanged={handlePageChange}
                    hasPreviousPage={pagination.page > 1}
                    hasNextPage={data.budgets.length >= pagination.size}
                    page={pagination.page}
                    pageSize={pagination.size}
                />
            )}
        </div>
    )
}

const SearchTextField = ({ header, onSearch }) => {
    if (header.sortKey) {
        if (header.sortKey === 'budgett') {
            return (
                <div className="d-flex flex-row">
                    <Form.Control
                        placeholder="From"
                        type="number"
                        id={`${header.sortKey}-min`}
                        data-type="range"
                        size="sm"
                        onChange={(e) => {
                            onSearch(
                                e.target.id,
                                e.target.value,
                                e.target.attributes['data-type']?.value
                            )
                        }}
                    />
                    <Form.Control
                        placeholder="To"
                        className="ms-2"
                        type="number"
                        id={`${header.sortKey}-max`}
                        data-type="range"
                        size="sm"
                        onChange={(e) => {
                            onSearch(
                                e.target.id,
                                e.target.value,
                                e.target.attributes['data-type']?.value
                            )
                        }}
                    />
                </div>
            )
        } else {
            return (
                <Form.Control
                    type="text"
                    id={header.sortKey}
                    data-type="text"
                    size="sm"
                    onChange={(e) => {
                        onSearch(
                            e.target.id,
                            e.target.value,
                            e.target.attributes['data-type']?.value
                        )
                    }}
                />
            )
        }
    }

    return <></>
}
