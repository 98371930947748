import React from 'react'
import { Button, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { pathParam } from '../../../../utilities/Router'
import { compareStr } from '../../../../utilities/String'
import { HeaderLight } from '../../../components/header/HeaderComponent'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security'
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton'

interface Props {
    vendorName?: string
    activeMenu?: string
    onSave?: any
    vendorId?: any
    isLoading?: boolean
    onSwitchTab?: any
    isDraft?: boolean
    numAttachments: number
    isSaveHidden: boolean
}

interface State {
    vendorName?: string
    isLoading?: boolean
    showButtons: boolean
}

interface HeaderLink {
    info: string
    status: string
    defaults: string
    notes: string
    documents: string
}

export default class VendorAddEditHeader extends React.Component<Props, State> {
    isEditing: boolean

    constructor(props: Props) {
        super(props)

        this.state = {
            vendorName: 'Add Vendor',
            isLoading: false,
            showButtons: false,
        }
        this.isEditing = Boolean(this.props.vendorId)
    }

    componentDidMount(): void {
        if (!this.props.vendorName && this.props.vendorId) {
            this.setState({
                vendorName: 'Edit Vendor',
            })
        }
        if (this.props.vendorName) {
            const { vendorName, isLoading } = this.props

            this.setState({
                vendorName: vendorName || 'Add Vendor',
                isLoading: isLoading,
            })
        }

        window.localStorage.removeItem('isAdd')
        if (!this.props.vendorId) {
            setLocalStorage('isAdd', true)
        }

        setTimeout(() => {
            this.setState({
                showButtons: true,
            })
        }, 250)
    }

    componentDidUpdate(previousProps: Props, previousState: State): void {
        if (
            previousProps.vendorName !== this.props.vendorName ||
            previousProps.isLoading !== this.props.isLoading
        ) {
            const { vendorName, isLoading } = this.props

            this.setState({
                vendorName: vendorName || 'Add Vendor',
                isLoading: isLoading,
            })
        }
    }

    handleClickSave: any = (e: any) => {
        window.localStorage.setItem('isDraft', 'false')
        window.onbeforeunload = null
        if (this.props.onSave) {
            this.props.onSave(e)
        }
    }

    onSwitchTab: any = (e: any) => {
        if (this.props.onSwitchTab) {
            this.props.onSwitchTab(e)
        }
    }

    handleClick = (e: any) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            if (confirm('Leaving this screen will discard your changes.')) {
                window.localStorage.setItem('isDraft', 'false')
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    render(): JSX.Element {
        const { vendorName, isLoading } = this.state
        const { activeMenu, vendorId, isDraft } = this.props

        const link: HeaderLink = {
            info: pathParam(
                vendorId ? URI.contact.vendorEdit : URI.contact.vendorAdd,
                { id: this.props.vendorId, tab: 'info' }
            ),
            status: pathParam(URI.contact.vendorEdit, {
                id: this.props.vendorId,
                tab: 'status',
            }),
            defaults: pathParam(
                vendorId ? URI.contact.vendorEdit : URI.contact.vendorAdd,
                { id: this.props.vendorId, tab: 'defaults' }
            ),
            notes: pathParam(
                vendorId ? URI.contact.vendorEdit : URI.contact.vendorAdd,
                { id: this.props.vendorId, tab: 'notes' }
            ),
            documents: pathParam(
                vendorId ? URI.contact.vendorEdit : URI.contact.vendorAdd,
                { id: this.props.vendorId, tab: 'documents' }
            ),
        }

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.contact.vendor }}
                            linkAs={Link}
                        >
                            Vendors
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{vendorName}</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>{vendorName}</HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}

                        {!this.props.vendorId && (
                            <SecureBootstrapButton
                                attributeNo={49}
                                attributeType={
                                    this.isEditing
                                        ? SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                        : SECURITY_ATTRIBUTE_TYPES.DenyAdd
                                }
                                variant="primary me-2"
                                onClick={this.handleClickSave}
                                name="saveNew"
                                disabled={!isDraft || isLoading}
                            >
                                Save & New
                            </SecureBootstrapButton>
                        )}

                        <SecureBootstrapButton
                            attributeNo={49}
                            attributeType={
                                this.isEditing
                                    ? SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                    : SECURITY_ATTRIBUTE_TYPES.DenyAdd
                            }
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </SecureBootstrapButton>
                    </div>
                </HeaderLight.Content>
                <HeaderLight.Actions>
                    <li>
                        <Link
                            onClick={this.onSwitchTab}
                            to={link.info}
                            data-menu="info"
                            className={
                                `btn text-charcoal ` +
                                compareStr(
                                    activeMenu || '',
                                    'info',
                                    'active',
                                    ''
                                )
                            }
                        >
                            Info
                        </Link>
                    </li>
                    {vendorId && (
                        <li>
                            <Link
                                onClick={this.onSwitchTab}
                                to={link.status}
                                data-menu="info"
                                className={
                                    `btn text-charcoal ` +
                                    compareStr(
                                        activeMenu || '',
                                        'status',
                                        'active',
                                        ''
                                    )
                                }
                            >
                                Status
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link
                            onClick={this.onSwitchTab}
                            to={link.defaults}
                            data-menu="defaults"
                            className={
                                `btn text-charcoal ` +
                                compareStr(
                                    activeMenu || '',
                                    'defaults',
                                    'active',
                                    ''
                                )
                            }
                        >
                            Defaults
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={this.onSwitchTab}
                            to={link.notes}
                            data-menu="notes"
                            className={
                                `btn text-charcoal ` +
                                compareStr(
                                    activeMenu || '',
                                    'notes',
                                    'active',
                                    ''
                                )
                            }
                        >
                            Notes
                        </Link>
                    </li>
                    {vendorId && (
                        <li>
                            <Link
                                onClick={this.onSwitchTab}
                                to={link.documents}
                                data-menu="documents"
                                className={
                                    `btn text-charcoal ` +
                                    compareStr(
                                        activeMenu || '',
                                        'documents',
                                        'active',
                                        ''
                                    )
                                }
                            >
                                Documents{' '}
                                {this.props.numAttachments > 0 && (
                                    <i className="ri-attachment-2 ms-1"></i>
                                )}
                            </Link>
                        </li>
                    )}
                </HeaderLight.Actions>
            </HeaderLight>
        )
    }
}
