import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import ClipMedia from '../../../components/ClipMedia'
import Header from '../../../components/Header'
import ThreadComponent from '../../ThreadComponent'
import { ApiService } from '../../../../lib/api/HttpService'
import CONFIG from '../../../../defaults/Config'

class ClipperGrid extends ThreadComponent {
    componentInit() {
        this.title = 'Clipped Items'
    }

    constructor(props) {
        super(props)

        this.state = {
            checks: {},
        }

        this.api = new ApiService()

        // This binding is necessary to make `this` work in the callback
        this.handleChecks = this.handleChecks.bind(this)
    }

    async componentDidMount() {
        let items = await this.api.getClippedItems('')

        this.renderData(items.list)

        await this.loadImages(items.list)
    }

    async loadImages(inventory) {
        if (inventory && inventory.length) {
            for (let item of inventory) {
                let file = item.primaryImageId
                    ? await this.api.getFile(item.primaryImageId)
                    : ''
                item.image = file
            }
            this.setState({
                tmpData: inventory,
            })
        }
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            prevState.checks[indx] = e.target.checked

            return prevState
        })
    }

    renderHeader() {
        console.log(this.state.data?.length)
        return (
            <Header>
                <Header.Title>Clipped Items</Header.Title>
                <Header.Actions list={true}>
                    <li>
                        <Button
                            as={Link}
                            to={URI.item.clippedItem}
                            variant="light"
                            size="lg"
                            className="btn-icon text-secondary-green"
                        >
                            <i className="ri-list-check-2 ri-lg me-1"></i> Steps
                        </Button>
                    </li>
                </Header.Actions>
            </Header>
        )
    }

    render() {
        return (
            <>
                <div>dupa</div>
                {this.renderHeader()}

                <div className="content-padding min-height pt-5">
                    {this.state.data?.length ? (
                        <ClipMedia
                            data={this.state.data}
                            size={'lg'}
                        ></ClipMedia>
                    ) : (
                        <Container
                            fluid
                            className="a-clipper-instructions py-5 roundedx-4 mx-auto"
                        >
                            <Row
                                className="justify-content-center py-5 mx-auto"
                                style={{ maxWidth: '1600px' }}
                            >
                                <Col sm="12" lg="3">
                                    <Image
                                        src={CONFIG.clipper1}
                                        alt="image"
                                        className="mx-auto d-block mb-4 rounded-circle"
                                    ></Image>
                                    <div className="text-center">
                                        <h6 className="fsx-20">
                                            1. Install browser extension
                                        </h6>
                                        <p className="mb-0">
                                            <a href="https://chrome.google.com/webstore/detail/design-manager-product-cl/kloodacdcdmefdelbanijdonpljlbjnp">
                                                Click here
                                            </a>{' '}
                                            to download the clipper extension
                                            and install it.
                                        </p>
                                    </div>
                                </Col>
                                <Col
                                    sm="12"
                                    lg="1"
                                    className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
                                >
                                    <div className="d-none d-lg-block">
                                        <i className="ri-arrow-right-line ri-lg"></i>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        <i className="ri-arrow-down-line ri-lg"></i>
                                    </div>
                                </Col>
                                <Col sm="12" lg="3">
                                    <Image
                                        src={CONFIG.clipper2}
                                        alt="image"
                                        className="mx-auto d-block mb-4 rounded-circle"
                                    ></Image>
                                    <div className="text-center">
                                        <h6 className="fsx-20">
                                            2. Open any website
                                        </h6>
                                        <p className="mb-0">
                                            Go to a place where you want to clip
                                            items for your project.
                                        </p>
                                    </div>
                                </Col>
                                <Col
                                    sm="12"
                                    lg="1"
                                    className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
                                >
                                    <div className="d-none d-lg-block">
                                        <i className="ri-arrow-right-line ri-lg"></i>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        <i className="ri-arrow-down-line ri-lg"></i>
                                    </div>
                                </Col>
                                <Col sm="12" lg="3">
                                    <Image
                                        src={CONFIG.clipper3}
                                        alt="image"
                                        className="mx-auto d-block mb-4 rounded-circle"
                                    ></Image>
                                    <div className="text-center">
                                        <h6 className="fsx-20">
                                            3. Source products
                                        </h6>
                                        <p className="mb-0">
                                            Fill in the information you need and
                                            save items for your project.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </div>
            </>
        )
    }
}

export default WithRouter(ClipperGrid)
