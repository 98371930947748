import React from 'react'
import { useQuery } from '@tanstack/react-query'

import { ApiService } from 'lib/api/HttpService'
import { UnitOfMeasure } from 'lib/api/types/UnitsOfMeasure'
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown'

const api = new ApiService()

const getUnitsOfMeasure = async () => {
    const units = (await api.get('UnitsOfMeasures')) as UnitOfMeasure[]
    return units
}

const UnitsOfMeasureDropdown = (props: TBaseDropdownProps) => {
    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'unitsOfMeasure'],
        queryFn: getUnitsOfMeasure,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const options =
        data?.map((option) => ({
            label: option.value,
            value: option.value,
        })) || []

    return <BaseDropdown {...props} isLoading={isLoading} options={options} />
}

UnitsOfMeasureDropdown.displayName = 'UnitsOfMeasureDropdown'

export default UnitsOfMeasureDropdown
