import React from 'react'
import { Breadcrumb, Col, Container, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import { getActiveMenuUri, WithRouter } from '../../../helpers/Router'
import ListFilter from '../../components/ListFilter'
import Header from '../../components/Header'
import { fakeData } from '../../factory/fakeFactory'
import ThreadComponent from '../ThreadComponent'
import { compareStr } from '../../../helpers/String'
import { getMatchedValue, getSubComponent } from '../../../helpers/Util'
import GlossaryListLocations from './GlossaryListLocations'
import GlossaryListSalesCategories from './GlossaryListSalesCategories'
import GlossaryListItemGroups from './GlossaryListItemGroups'
import GlossaryListShipToCodes from './GlossaryListShipToCodes'
import GlossaryListRemarks from './GlossaryListRemarks'
import GlossaryListSpecificationTemplates from './GlossaryListSpecificationTemplates'
import GlossaryListTimeActivities from './GlossaryListTimeActivities'
import GlossaryListSalesTaxCodes from './GlossaryListSalesTaxCodes'
import GlossaryListUnitsOfMeasure from './GlossaryListUnitsOfMeasure'
import { renderSecureContent } from '../../../utilities/modules/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import { Feature } from 'use-feature'
import { FeatureFlags } from '../../../app/enums/featureFlags/featureFlags'

class GlossaryList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: fakeData(10),
            tmpData: fakeData(10),
            dataIsLoaded: false,
            activeMenu: getActiveMenuUri(
                this.props.paths.pathname,
                [
                    'locations',
                    'sales-categories',
                    'item-groups',
                    'ship-to-codes',
                    'remarks',
                    'specification-templates',
                    'time-activities',
                    'sales-tax-codes',
                    'units-of-measure',
                ],
                'locations'
            ),
        }
    }

    componentInit() {
        this.setTitle('Glossaries').setActionBar(true)
    }

    handleMenuClick = (e) => {
        e.preventDefault()

        this.setState(
            (prevState) => (prevState.activeMenu = e.target.dataset.menu)
        )
    }

    getHeaderTitle() {
        return getMatchedValue(this.state.activeMenu, {
            locations: 'Locations',
            'sales-categories': 'Sales Categories',
            'item-groups': 'Item Groups',
            'ship-to-codes': 'Ship To Codes',
            remarks: 'Remarks',
            'specification-templates': 'Specification Templates',
            'time-activities': 'Time Activities',
            'sales-tax-codes': 'Sales Tax Codes',
            'units-of-measure': 'Units of Measure',
        })
    }

    renderHeader() {
        const { activeMenu } = this.state

        return (
            <Header buttons={1}>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{ to: URI.settings.base }}
                                    linkAs={Link}
                                >
                                    Settings
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    linkProps={{ to: URI.glossary.base }}
                                    linkAs={Link}
                                >
                                    Glossary
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {this.getHeaderTitle()}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>{this.getHeaderTitle()}</Header.Title>
                    </Header.LeftContent>
                    {/* <Header.Actions list={true}>
                        <li>
                            <Button as={Link} to={URI.project.add} variant='light' size='lg' className='btn-icon text-secondary-green'>
                                <i className="ri-add-line ri-lg"></i> Add
                            </Button>
                        </li>
                    </Header.Actions> */}
                </Header.TitleContent>
                <Header.Buttons>
                    <li>
                        <Link
                            to={URI.glossary.base + '/locations'}
                            data-menu="locations"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'locations',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Locations
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={URI.glossary.base + '/sales-categories'}
                            data-menu="sales-categories"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'sales-categories',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Sales Categories
                        </Link>
                    </li>
                    {/* show v1
                    <li><Link to={URI.glossary.base + '/item-groups'} data-menu="item-groups" className={`btn text-white ${compareStr(activeMenu, 'item-groups', 'active', '')}`} onClick={this.handleMenuClick}>Item Groups</Link></li>
                    */}
                    <li>
                        <Link
                            to={URI.glossary.base + '/ship-to-codes'}
                            data-menu="ship-to-codes"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'ship-to-codes',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Ship To Codes
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={URI.glossary.base + '/remarks'}
                            data-menu="remarks"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'remarks',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Remarks
                        </Link>
                    </li>
                    {/* show v1
                    <li><Link to={URI.glossary.base + '/specification-templates'} data-menu="specification-templates" className={`btn text-white ${compareStr(activeMenu, 'specification-templates', 'active', '')}`} onClick={this.handleMenuClick}>Specification Templates</Link></li>
                    */}
                    <li>
                        <Link
                            to={URI.glossary.base + '/time-activities'}
                            data-menu="time-activities"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'time-activities',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Time Activities
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={URI.glossary.base + '/sales-tax-codes'}
                            data-menu="sales-tax-codes"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'sales-tax-codes',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Sales Tax Codes
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={URI.glossary.base + '/units-of-measure'}
                            data-menu="units-of-measure"
                            className={`btn text-white ${compareStr(
                                activeMenu,
                                'units-of-measure',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Units of Measure
                        </Link>
                    </li>
                    <Feature name={FeatureFlags.SpecificationTemplates}>
                        <li>
                            <Link
                                to={
                                    URI.glossary.base +
                                    '/specification-templates'
                                }
                                data-menu="specification-templates"
                                className={`btn text-white ${compareStr(
                                    activeMenu,
                                    'specification-templates',
                                    'active',
                                    ''
                                )}`}
                                onClick={this.handleMenuClick}
                            >
                                Specification Templates
                            </Link>
                        </li>
                    </Feature>
                </Header.Buttons>
            </Header>
        )
    }

    renderFilter() {
        return (
            <ListFilter expandable={0} className="no-separator">
                <ListFilter.Actions lg={12} xl={12}>
                    <ListFilter.Action className="ms-auto">
                        <Button
                            as={Link}
                            to="#"
                            variant="ivory"
                            size="sm"
                            className={`btn-icon btn-action fw-bold ${
                                this.state.showTableSearch
                                    ? 'bg-primary-ash text-white'
                                    : ''
                            }`}
                            onClick={this.handleShowTableSearch}
                        >
                            <i className="ri-search-line"></i> Search
                        </Button>
                        <Button
                            as={Link}
                            to=""
                            variant="ivory"
                            size="sm"
                            className="btn-icon btn-action ms-3"
                        >
                            <i className="ri-close-line"></i> Delete
                        </Button>
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        )
    }

    renderContent() {
        return getSubComponent(this.state.activeMenu, {
            locations: renderSecureContent(<GlossaryListLocations />)(
                42,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
            'sales-categories': renderSecureContent(
                <GlossaryListSalesCategories />
            )(44, SECURITY_ATTRIBUTE_TYPES.DenyAccess),
            'item-groups': <GlossaryListItemGroups />,
            'ship-to-codes': renderSecureContent(<GlossaryListShipToCodes />)(
                46,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
            remarks: renderSecureContent(<GlossaryListRemarks />)(
                43,
                SECURITY_ATTRIBUTE_TYPES.DenyAccess
            ),
            'specification-templates': <GlossaryListSpecificationTemplates />,
            'time-activities': renderSecureContent(
                <GlossaryListTimeActivities />
            )(48, SECURITY_ATTRIBUTE_TYPES.DenyAccess),
            'sales-tax-codes': renderSecureContent(
                <GlossaryListSalesTaxCodes />
            )(45, SECURITY_ATTRIBUTE_TYPES.DenyAccess),
            'units-of-measure': <GlossaryListUnitsOfMeasure />,
        })
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding has-action-bar">
                    <Container fluid>
                        <Row>
                            <Col sm="12">{this.renderContent()}</Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(GlossaryList)
