import { Button } from 'react-bootstrap'
import cn from 'classnames'

export default function SearchButton({ className, searching, ...props }) {
    const classes = cn('btn-icon btn-action me-3', className)
    const variant = searching ? 'dark' : 'ivory'

    return (
        <Button variant={variant} className={classes} {...props}>
            <i className="ri-search-line"></i> Search
        </Button>
    )
}
