import React from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import { WithRouter } from '../../../helpers/Router'
import { ApiService } from '../../../lib/api/HttpService'
import { pathParam } from '../../../utilities/Router'
import ListFilter from '../../components/ListFilter'
import ThreadComponent from '../ThreadComponent'
import DeleteModal from '../../../app/components/modal/DeleteModal'
import HandleDeleteWorker from '../../../utilities/DeleteWorker'
import Switch from '../../components/Switch'
import debounce from 'lodash/debounce'
import { Pagination } from '../../../app/components/pagination/Pagination'
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import { isFiltered } from '../../../helpers/Util'
import { displayAlertLoader } from '../../../utilities/Response'
import MSG from '../../../defaults/Message'
import FilterSort from '../../../utilities/modules/FilterSort'
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'

class GlossaryListTimeActivities extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('glossary_timeactivity_list')
        this.fs.setDefaultSort('activity asc')
        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            dataIsLoaded: false,
            modal: { show: false },
            disabledDeleteButton: true,
            showInactive: this.fs.getFilter('showInactive') ?? false,
            page: 1,
            pageSize: 20,
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
            sortProperty: this.fs.getSort() ?? 'activity asc',
        }
        this.api = new ApiService()

        this.apiPath = 'timeactivities'
        this.apiParam = ''
        this.pageTitle = 'Time Activities | Glossaries'
        this.objName = 'Time Activity'
        this.objReference = 'Projects'

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objTimeActivity',
            objectCodeOrId: '',
        }

        this.availablePageSize = [
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' },
            { value: 75, label: '75' },
            { value: 100, label: '100' },
        ]

        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    componentInit() {
        this.setTitle(this.pageTitle).setActionBar(true)
    }

    async componentDidMount() {
        this.fetchItems()
        this.enableSearchAndSortTable()
    }

    async fetchItems(page) {
        this.setState({
            dataIsLoaded: false,
        })
        const data = await this.api.get(
            this.apiPath,
            `?${this.buildFilters(page)}`
        )

        if (data) {
            this.setState({
                dataIsLoaded: true,
                data: data,
            })
        }
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    buildFilters(currentPage) {
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filters.push(`inactive eq ${this.state.showInactive}`)
        const filtersQuery =
            filters.length > 0 ? `&$filter=${filters.join(' and ')}` : ''

        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    toggleDeleteButton(e) {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    hideModal = (e) => {
        this.toggleDeleteButton(e)
        this.setState({
            showModal: false,
        })
    }

    handleSwitchChange = async (e) => {
        const newVal = !this.state.showInactive
        this.setState({
            dataIsLoaded: false,
        })

        this.setState(
            {
                showInactive: newVal,
            },
            this.changePageHandler
        )
        this.fs.setFilter('showInactive', newVal)
    }

    async handleTestDeleteObjects(e) {
        this.toggleDeleteButton(e)

        let workers = []

        displayAlertLoader(MSG.loading.prepare.item)

        for (const [index, value] of Object.entries(this.state.checks)) {
            if (value) {
                this.deleteObjectParams.objectCodeOrId = index
                workers.push(
                    await this.api.postJson(
                        'deleteobjects',
                        this.deleteObjectParams
                    )
                )
            }
        }

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: this.objName,
                    modulePlural: this.objName + 's',
                    reference: this.objReference,
                    objectType: this.deleteObjectParams.objectType,
                },
                false,
                (modalState) => {
                    this.setState(modalState)
                }
            )
        }
    }

    // Refresh data
    refreshDataAfterDelete = (e) => {
        const headingCheck = document.getElementById('inline-check-th-0')

        this.setState({
            checks: {},
            totalChecked: 0,
            showModal: false,
            deleteResponses: [],
        })
        headingCheck.checked = false

        this.fetchItems()

        this.uncheckAll()
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked
            prevState.disabledDeleteButton = totalChecked > 0 ? false : true

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    uncheckAll() {
        const checks = document.querySelectorAll(
            '.chk-glossaryLocations-items-item input'
        )
        if (checks) {
            checks.forEach((e) => {
                e.checked = false
            })
        }
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data
                        .slice(0, this.state.pageSize)
                        .map((item, i) => {
                            prevState.checks[item.id] = true
                            document.getElementById(
                                'chk-glossaryLocations-items-' + i
                            ).checked = true
                            return null
                        })
                }
                prevState.totalChecked = this.state.data.length
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                this.uncheckAll()
            }
            prevState.disabledDeleteButton =
                prevState.totalChecked > 0 ? false : true

            return prevState
        })
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderFilter() {
        return (
            <>
                <ListFilter expandable={0} className="no-separator">
                    <ListFilter.Fields lg={8} xl={8} className="width-20">
                        <ListFilter.Field>
                            <Form.Label style={{ maxWidth: '100%' }}>
                                <strong>Show Inactive</strong>
                            </Form.Label>
                            <span>
                                <Switch
                                    checked={this.state.showInactive}
                                    onChange={this.handleSwitchChange}
                                ></Switch>
                            </span>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    <ListFilter.Actions lg={4} xl={4}>
                        <ListFilter.Action>
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <SecureBootstrapButton
                                attributeNo={48}
                                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
                                as={Link}
                                to={URI.glossary.add.timeActivities}
                                variant="primary"
                                size="sm"
                                className="btn-icon ms-3"
                            >
                                <i className="ri-add-fill"></i> Add
                            </SecureBootstrapButton>
                            <SecureBootstrapButton
                                attributeNo={48}
                                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
                                as={Link}
                                onClick={this.handleTestDeleteObjects.bind(
                                    this
                                )}
                                to={'#'}
                                variant="danger"
                                size="sm"
                                className={
                                    this.state.disabledDeleteButton |
                                    (this.state.totalChecked < 1) |
                                    this.state.showInactive
                                        ? 'btn-icon ms-3 disabled'
                                        : 'btn-icon ms-3'
                                }
                            >
                                Delete
                            </SecureBootstrapButton>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderFilter()}

                <div className="table-gradient sticky-container">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr className="a-table-heading">
                                <th align="middle" className="w-70px">
                                    <div className="d-flex justify-content-center">
                                        <Form.Check
                                            inline
                                            label=""
                                            name={`inline-check-th-0`}
                                            type="checkbox"
                                            data-id={`th-0`}
                                            id={`inline-check-th-0`}
                                            defaultChecked={
                                                this.state.totalChecked > 0
                                                    ? true
                                                    : false
                                            }
                                            className={`${
                                                this.state.totalChecked > 0 &&
                                                this.state.totalChecked <
                                                    this.state.data.length
                                                    ? 'line'
                                                    : ''
                                            }`}
                                            onClick={this.handleSelectAllChecks}
                                        />
                                    </div>
                                </th>
                                <th className="w-200px ps-3">
                                    <span
                                        className={this.sortClass('activity')}
                                        data-field="activity"
                                    >
                                        Time Activity Code
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className={this.sortClass('activityn')}
                                        data-field="activityn"
                                    >
                                        Time Activity Name
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className={this.sortClass('desc')}
                                        data-field="desc"
                                    >
                                        Description
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className={this.sortClass(
                                            'nonbillableDefault'
                                        )}
                                        data-field="nonbillableDefault"
                                    >
                                        Non-Billable
                                    </span>
                                </th>
                                <th>
                                    <span
                                        className={this.sortClass(
                                            'taxableDefault'
                                        )}
                                        data-field="taxableDefault"
                                    >
                                        Taxable
                                    </span>
                                </th>
                            </tr>
                            <tr
                                className={`a-table-search-fields ${
                                    this.state.showTableSearch ? '' : 'd-none'
                                }`}
                            >
                                <th className="w-70px"></th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="activity"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'activity'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="activityn"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue(
                                            'activityn'
                                        )}
                                    />
                                </th>
                                <th>
                                    <Form.Control
                                        type="text"
                                        data-field="desc"
                                        onChange={this.handleSearch}
                                        defaultValue={this.fs.getValue('desc')}
                                    />
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.dataIsLoaded
                                ? showLoading()
                                : this.state.data.length === 0 &&
                                  isFiltered(this.state.searchProperties)
                                ? showEmpty()
                                : this.state.data
                                      .slice(0, this.state.pageSize)
                                      .map((item, i) => (
                                          <tr
                                              key={i}
                                              data-key={i}
                                              className={
                                                  this.state.checks[item.id]
                                                      ? `active`
                                                      : ''
                                              }
                                          >
                                              <td>
                                                  <div className="d-flex justify-content-center">
                                                      <Form.Check
                                                          label=""
                                                          name={`group-` + i}
                                                          type="checkbox"
                                                          data-id={item.id}
                                                          id={
                                                              `chk-glossaryLocations-items-` +
                                                              i
                                                          }
                                                          className="chk-glossaryLocations-items-item"
                                                          defaultChecked={
                                                              this.state.checks[
                                                                  item.id
                                                              ]
                                                                  ? true
                                                                  : false
                                                          }
                                                          onClick={
                                                              this.handleChecks
                                                          }
                                                      />
                                                  </div>
                                              </td>
                                              <td className="ps-3">
                                                  <Link
                                                      to={pathParam(
                                                          URI.glossary.edit
                                                              .timeActivities,
                                                          { id: item.id }
                                                      )}
                                                      className="text-charcoal hover-view-icon"
                                                  >
                                                      {item.activity}
                                                  </Link>
                                              </td>
                                              <td>{item.activityn}</td>
                                              <td>{item.desc}</td>
                                              <td>
                                                  {item.nonbillableDefault
                                                      ? 'Yes'
                                                      : 'No'}
                                              </td>
                                              <td>
                                                  {item.taxableDefault === 1
                                                      ? 'Yes'
                                                      : 'No'}
                                              </td>
                                          </tr>
                                      ))}
                        </tbody>
                    </Table>

                    {(() => {
                        if (this.state.data.length) {
                            return (
                                <Pagination
                                    onPageSizeChanged={this.onPageSizeChanged}
                                    onPageChanged={this.onPageChanged}
                                    hasPreviousPage={this.state.page > 1}
                                    hasNextPage={
                                        this.state.data.length >
                                        this.state.pageSize
                                    }
                                    page={this.state.page}
                                    pageSize={this.state.pageSize}
                                />
                            )
                        }
                    })()}
                </div>

                <DeleteModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    hideModal={this.hideModal}
                    responses={this.state.deleteResponses}
                />
            </>
        )
    }
}

export default WithRouter(GlossaryListTimeActivities)
