import { addDomClass, hasClass, removeDomClass } from '../helpers/DOM'
import ThreadComponent from './ThreadComponent'

export interface TableComponentState {
    sortProperty?: string
}

export class TableComponent<
    P,
    S extends TableComponentState
> extends ThreadComponent<P, S> {
    componentDidMount() {
        setTimeout(() => {
            this.enableSortTable()
        }, 500)
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            (_e.attributes as any)['data-field']?.value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        if (self.getFS()) {
                            self.getFS()?.setSort(sortProperty)
                        }

                        self.setState(
                            {
                                sortProperty,
                            },
                            () => {
                                self.handleChangePage(1)
                            }
                        )
                    },
                    false
                )
            })
        }
    }

    handleChangePage = async (page: number) => {}

    getFS(): any {
        return null
    }
}
