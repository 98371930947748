import { makeAutoObservable } from 'mobx'
import { VendorListResponse, VendorService } from './api/VendorService'
import { IInsuranceResponse, InsuranceService } from './api/InsuranceService'
import { getLocalStorage } from '../../../utilities/LocalStorage'
import { toSortName } from '../../../utilities/String'

interface SelectOptions {
    label: string
    value: string
}

export default class VendorAddEditViewModel {
    private vendorService: VendorService
    private insuranceService?: InsuranceService
    private _vendor: any = {}
    private _vendorData: any = {}
    private _isLoaded = false
    public _id = 0
    private _response: any = null
    private _categories: any = []
    private _insurances: IInsuranceResponse[] = []

    get vendor(): VendorListResponse {
        return this._vendor
    }

    get requestedVendor(): VendorListResponse | null {
        const requestedVendorData = localStorage.getItem('vendorData')

        if (requestedVendorData) {
            return JSON.parse(requestedVendorData)
        }
        return null
    }

    get vendorData(): VendorListResponse {
        return this._vendorData
    }

    get response(): boolean {
        return this._response
    }

    get categories(): SelectOptions[] {
        return this._categories
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    get insurances(): IInsuranceResponse[] {
        return this._insurances
    }
    reset(): any {
        this._vendor = {}
        this._vendorData = {}
        this._insurances = []
        return this
    }

    setVendor(vendor: VendorListResponse): VendorAddEditViewModel {
        this._vendor = vendor
        this._vendorData = vendor
        window.localStorage.setItem('vendorData', JSON.stringify(vendor))
        return this
    }

    setVendorData(key: any, value: any): VendorAddEditViewModel {
        const vendorData: any = getLocalStorage('vendorData', true)
        const addressData: any = getLocalStorage('addressData', true)

        vendorData[key] = value

        if (key === 'vendorn') {
            vendorData.sortName1 = value
            addressData.sortname = value

            window.localStorage.setItem(
                'addressData',
                JSON.stringify(addressData)
            )
        }

        window.localStorage.setItem('vendorData', JSON.stringify(vendorData))

        this._vendorData = vendorData

        return this
    }

    setInsurances(insurances: any): VendorAddEditViewModel {
        this._insurances = insurances
        this._vendor.insurances = insurances
        this._vendorData.insurances = insurances
        return this
    }

    setCategories(categories: any): VendorAddEditViewModel {
        this._categories = categories
        return this
    }

    setIsLoaded(isLoaded: boolean): VendorAddEditViewModel {
        this._isLoaded = isLoaded
        return this
    }

    setResponse(response: any): VendorAddEditViewModel {
        this._response = response
        return this
    }

    constructor(
        vendorService: VendorService,
        insuranceService?: InsuranceService
    ) {
        this.vendorService = vendorService
        this.insuranceService = insuranceService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        //this.fetchCategories()

        await this.fetchVendor()
        this.setIsLoaded(true)
    }

    setId(id: number): VendorAddEditViewModel {
        this._id = id
        return this
    }

    async fetchVendor() {
        // Set loading state
        this.setIsLoaded(false)
        if (!this._id) {
            this.setIsLoaded(true)
            const data: any = {}
            this.setVendor(data)
            return
        }

        await this.vendorService
            .getVendorById(this._id)
            .then((response) => {
                this.setIsLoaded(true)
                if (response && response[0]) {
                    response.list = response[0]
                    response.list.insurances = []
                    this.setVendor(response.list)
                    const filter = `?$filter=vendor eq '${response.list.vendor}'`

                    this.insuranceService
                        ?.getInsurances(filter)
                        .then((resp) => {
                            if (resp) {
                                this.setInsurances(resp)
                            }
                        })
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }

    async fetchCategories() {
        // Set loading state
        this.setIsLoaded(false)

        try {
            const response: any = await this.vendorService.getVendors(
                `?$filter=employee eq false and category ne null and category ne ''`
            )
            this._isLoaded = true

            if (response) {
                const cats = []
                for (const v of response) {
                    if (v.category !== '' && v.category != null) {
                        if (cats.indexOf(v.category) === -1) {
                            cats.push(v.category)
                        }
                    }
                }

                cats.sort()
                this.setCategories([
                    ...[{ value: 'Custom', label: 'Custom' }],
                    ...cats.map((x) => ({ value: x, label: x })),
                ])
            }
        } catch {
            this._isLoaded = true
        }
    }

    modifyVendor(id?: number, params?: any, callback?: any) {
        const _params = params
        if (params.payeesameasvendor === undefined) {
            params.payeesameasvendor = true
        }

        if (!id) {
            return this.insertVendor(_params, callback)
        } else {
            return this.updateVendor(id, _params, callback)
        }
    }

    insertVendor(params?: any, callback?: any) {
        return this.vendorService.insert(params)
    }

    updateVendor(id: number, params?: any, callback?: any) {
        return this.vendorService.update(id, params)
    }

    async deleteVendor(params?: any, callback?: any) {
        return this.vendorService
            .delete(params)
            .then((res) => Promise.resolve(res))
            .catch((e) => Promise.reject(e))
    }
}
