import React from 'react'

import { useHasAccess } from '../../../context/security'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function SecureButton({
    attributeNo,
    attributeType,
    children,
    ...props
}) {
    const hasAccess = useHasAccess(attributeNo, attributeType)
    const titleToUse = !hasAccess
        ? 'The assigned user role restricts access to this area'
        : props.title

    if (!hasAccess) {
        props.onClick = () => {}
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">{titleToUse}</Tooltip>}
            >
                <span>
                    <Button {...props} disabled>
                        {children}
                    </Button>
                </span>
            </OverlayTrigger>
        )
    }

    return <Button {...props}>{children}</Button>
}
