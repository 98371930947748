import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'

class ProjectAddNotes extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = props.updatedData
        this.state = {}
    }

    componentInit() {
        this.hasFormAction = true
    }

    async componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data || {}

            this.setState({
                data: data,
                dataIsLoaded: true,
            })
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data || {}

            this.setState({
                data: data,
                dataIsLoaded: true,
            })
        }
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        this.props.onDataChange({ key: key, value: value })
    }

    render() {
        const { data } = this.state
        return (
            <>
                <Container className="ms-0">
                    <Row>
                        <Col lg="7">
                            <WysiwygEditor
                                id="notes"
                                onChange={this.handleChange}
                            >
                                {this.updatedData?.notes ?? data?.notes}
                            </WysiwygEditor>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default WithRouter(ProjectAddNotes)
