import { Component } from 'lib/api/types/Component'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import SubComponent from './Subcomponent'
import useCreateComponent from 'lib/api/hooks/useCreateComponent'
import { useParams } from 'react-router-dom'
import { displayAlert, displayAlertError } from 'utilities/Response'
import useDeleteObject from 'lib/api/hooks/useDeleteObject'
import { DeleteType } from 'lib/api/types/DeleteType'
import { DeleteObjectTypes } from 'lib/api/types/DeleteObjectTypes'
import ComponentsDeleteConfirmationModal from 'app/components/ComponentDeleteConfirmationModal/ComponentsDeleteConfirmationModal'
import ComponentsDeleteWarningModal from './ComponentsDeleteWarningModal'
import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'

const SubComponents = ({
    components,
    updateComponents,
}: {
    components: Component[]
    updateComponents: (components: Component[]) => void
}) => {
    const { id: projectId, itemId } = useParams()

    const { mutate: createComponents, isLoading: isCreating } =
        useCreateComponent(Number(projectId), {
            onError: () =>
                displayAlertError(
                    'There was an error creating the component, please try again'
                ),
            onSuccess(data) {
                const compsCopy = structuredClone(components)
                compsCopy.unshift(data)
                updateComponents(compsCopy)
            },
        })

    const [cantDeleteMessage, setCantDeleteMessage] = useState('')
    const [deletedComponent, setDeletedComponent] = useState<Component | null>(
        null
    )
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const { mutateAsync, isLoading: isDeleting } = useDeleteObject()

    const componentsRef = useRef<Component[]>(components)

    useEffect(() => {
        componentsRef.current = components
    }, [components])

    const updateComponent = useCallback(
        (component: Component) => {
            const compsCopy = structuredClone(componentsRef.current)

            const oldCompIndex = compsCopy.findIndex(
                (comp: Component) => component.id === comp.id
            )
            compsCopy[oldCompIndex] = component
            updateComponents(compsCopy)
        },
        [updateComponents]
    )

    const handleComponentDelete = async (component: Component) => {
        try {
            const canBeDeleted = await mutateAsync({
                deleteType: DeleteType.dmriTestOnly,
                objectType: DeleteObjectTypes.objComponent,
                objectCodeOrId: component.id,
            })

            if (canBeDeleted.fullDeletePossible) {
                await mutateAsync({
                    deleteType: DeleteType.dmriTestAndFullDeleteIfPossible,
                    objectType: DeleteObjectTypes.objComponent,
                    objectCodeOrId: component.id,
                })

                const compsCopy = structuredClone(componentsRef.current)
                const filteredComps = compsCopy.filter(
                    (comp: Component) => comp.id !== component.id
                )
                displayAlert('success', 'Component deleted successfully!')
                return updateComponents(filteredComps)
            }

            setCantDeleteMessage(canBeDeleted.message)
            setShowWarningModal(true)
        } catch {
            setCantDeleteMessage('Network error, please try again')
            setShowWarningModal(true)
        }
    }

    const handleCreateComponent = () => {
        const createComponentPayload = {
            itemId: Number(itemId),
            componentType: 0,
            associatedComponentNumber: '001',
            createAutoComponents: false,
            setDefaultsFromItem: true,
        }
        createComponents({
            createComponentPayload,
        })
    }

    return (
        <div className="tw-p-4">
            <ComponentsDeleteConfirmationModal
                show={showConfirmationModal}
                toggleModal={() => {
                    setDeletedComponent(null)
                    setShowConfirmationModal(false)
                }}
                confirmAction={() => {
                    setShowConfirmationModal(false)
                    handleComponentDelete(deletedComponent as Component)
                }}
            />
            <ComponentsDeleteWarningModal
                message={cantDeleteMessage}
                show={showWarningModal}
                component={deletedComponent as Component}
                confirmAction={() => {
                    setShowWarningModal(false)
                    setTimeout(() => setCantDeleteMessage(''), 500)
                }}
            />
            <div className="tw-flex tw-justify-between tw-items-center tw-p-5 tw-bg-[#EEEDE9]">
                <p className="tw-text-base tw-font-bold tw-m-0">
                    Freight / Design Fee / Additional Charges
                </p>
                <SecureBootstrapButton
                    attributeNo={14}
                    attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                    variant="primary"
                    size="sm"
                    onClick={handleCreateComponent}
                    disabled={
                        isCreating || isDeleting || components.length === 4
                    }
                >
                    <i className="ri-add-line" /> Add
                </SecureBootstrapButton>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4 tw-mb-4">
                {components.map((comp) => (
                    <SubComponent
                        disableInputs={isDeleting || isCreating}
                        setDeletedComponent={(component) => {
                            setDeletedComponent(component)
                            setShowConfirmationModal(true)
                        }}
                        component={comp}
                        updateComponent={updateComponent}
                        key={comp.id}
                    />
                ))}
            </div>
        </div>
    )
}
SubComponents.displayName = 'SubComponents'
export default SubComponents
