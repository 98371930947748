import { Component } from 'react'
import { Row, Col, Dropdown, Form, Image, Spinner } from 'react-bootstrap'
import { Resource } from '../../resources/Resources'
import { Link } from 'react-router-dom'
import URI from '../../defaults/RoutesDefault'
import { SITE_LOADING_IMAGE } from '../../utilities/DOM'

class ClipMedia extends Component {
    constructor(props) {
        super(props)

        this.state = {
            imageData: props.imageData,
            isImagesLoaded: props.isImagesLoaded,
        }
    }

    componentDidUpdate(prevProps, provState) {
        if (prevProps.isImagesLoaded !== this.props.isImagesLoaded) {
            this.setState({
                imageData: this.props.imageData,
                isImagesLoaded: this.props.isImagesLoaded,
            })
        }
    }

    static Media(props) {
        const data = props.data || {}
        const index = props.index || {}
        const handleChecks = props.handleChecks || null
        const handleSelectItem = props.handleSelectItem || null
        const isActive = props.isActive || false
        const source = props.source
        const imageData = props.imageData ?? {}
        const isImagesLoaded = props.isImagesLoaded ?? false

        const imageBlob = imageData[data.primaryImageId]

        let handleClick = (item, modalKey) => {
            handleSelectItem(item, modalKey)
        }

        return (
            <Col className="a-clipper-media-item">
                <div
                    className={
                        `a-clipper-media-item-handle border rounded ` +
                        (data.checked || isActive ? `active` : '')
                    }
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            {(() => {
                                if (!props.noCheck) {
                                    return (
                                        <Form.Check
                                            inline
                                            label=""
                                            name={`a-clipper-chk-` + index}
                                            type="checkbox"
                                            id={`a-clipper-chk-` + index}
                                            data-id={index}
                                            onChange={handleChecks}
                                        />
                                    )
                                }
                            })()}
                        </div>

                        <Dropdown>
                            <Dropdown.Toggle
                                variant="light"
                                className="no-outline no-min-height"
                            >
                                <i className="ri-more-2-fill ri-lg"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu align="end">
                                {source === 'clipper' ? (
                                    <Dropdown.Item
                                        onClick={(event) =>
                                            window.open(data.url, '_blank')
                                        }
                                    >
                                        Go to...
                                    </Dropdown.Item>
                                ) : (
                                    <Dropdown.Item
                                        onClick={(event) =>
                                            handleClick(data, 'editModal')
                                        }
                                    >
                                        Edit
                                    </Dropdown.Item>
                                )}

                                <Dropdown.Item
                                    onClick={(event) =>
                                        handleClick(data, 'createItemModal')
                                    }
                                >
                                    Create Item
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) =>
                                        handleClick(
                                            data,
                                            'createComponentModal'
                                        )
                                    }
                                >
                                    Create as Component
                                </Dropdown.Item>
                                {/* show v1
								<Dropdown.Item href="#/">Create Inventory Item</Dropdown.Item>
								<Dropdown.Item href="#/">Create Catalog Item</Dropdown.Item>
								*/}
                                <Dropdown.Item
                                    onClick={(event) =>
                                        handleClick(data, 'duplicateModal')
                                    }
                                >
                                    Duplicate
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) =>
                                        handleClick(data, 'transferModal')
                                    }
                                >
                                    Transfer to Another User
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) =>
                                        handleClick(data, 'deleteModal')
                                    }
                                >
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {(() => {
                        if (imageBlob instanceof Blob && isImagesLoaded) {
                            const img = URL.createObjectURL(imageBlob)

                            return (
                                <div
                                    className="image-container"
                                    onClick={() =>
                                        handleClick(data, 'editModal')
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="inner d-flex align-items-center justify-content-center">
                                        <Image
                                            style={{
                                                objectFit: 'contain',
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                            src={img}
                                            alt={data.itemTitle}
                                        ></Image>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    className="image-container"
                                    onClick={() =>
                                        handleClick(data, 'editModal')
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="inner">
                                        {data.primaryImageId &&
                                        isImagesLoaded === false ? (
                                            <span className="skeleton-loader image"></span>
                                        ) : (
                                            <Image
                                                src={
                                                    Resource.Image
                                                        .ClipperFallback.default
                                                }
                                                alt={data.itemTitle}
                                            ></Image>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    })()}

                    <div className="text-center">
                        <h6 className="fs-md  px-2">
                            {source === 'clipper' ? (
                                <Link
                                    onClick={() =>
                                        handleClick(data, 'editModal')
                                    }
                                    to={URI.item.clippedItem.base}
                                    className="text-charcoal"
                                >
                                    {data.itemTitle
                                        ? data.itemTitle
                                        : '<No Title>'}
                                </Link>
                            ) : (
                                <a
                                    href={data.url}
                                    target="_blank"
                                    className="text-charcoal"
                                    rel="noreferrer"
                                >
                                    {data.itemTitle
                                        ? data.itemTitle
                                        : '<No Title>'}
                                </a>
                            )}
                        </h6>
                        <div className="text-secondary-ash">{data.project}</div>
                    </div>
                </div>
            </Col>
        )
    }

    render() {
        const data = this.props.data || []
        const noCheck = this.props.noCheck || null
        const showModal = this.props.showModal
        const handleSelectItem = this.props.handleSelectItem
        const compareKey = this.props.IsActiveCompareKey
        const compareValue = this.props.IsActiveCompareValue
        const source = this.props.source
        const { imageData, isImagesLoaded } = this.props

        return (
            <div className={`a-clipper-media px-0 ${this.props.className}`}>
                <Row
                    className={`a-clipper-sizer a-clipper-${
                        this.props.size || 'md'
                    }`}
                >
                    {data.map(function (object, i) {
                        return (
                            <ClipMedia.Media
                                data={object}
                                index={i}
                                key={i}
                                noCheck={noCheck}
                                handleSelectItem={handleSelectItem}
                                isActive={object[compareKey] == compareValue}
                                source={source}
                                imageData={imageData}
                                isImagesLoaded={isImagesLoaded}
                            />
                        )
                    })}
                </Row>
            </div>
        )
    }
}

export default ClipMedia
