import React from 'react'
import { getRouteWithParam } from 'helpers/Router'
import { Breadcrumb, Button, NavLink } from 'react-bootstrap'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import URI from 'defaults/RoutesDefault'
import { HeaderLight } from 'templates/components/Header'
import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import useGetProject from 'lib/api/hooks/useGetProject'
import useGetComponent from 'lib/api/hooks/useGetComponent'
import useGetAssociatedComponents from 'lib/api/hooks/useGetAssociatedComponents'
import { Component } from 'lib/api/types/Component'
import useDeleteObject from 'lib/api/hooks/useDeleteObject'
import { DeleteType } from 'lib/api/types/DeleteType'
import { DeleteObjectTypes } from 'lib/api/types/DeleteObjectTypes'

type THeaderProps = {
    disableButtons?: boolean
}

const Header = ({ disableButtons }: THeaderProps) => {
    const { id, itemId, componentId } = useParams()

    const { data: component } = useGetComponent(componentId as string)

    const { data: associatedComps } = useGetAssociatedComponents(
        {
            projectId: id as string,
            itemId: itemId as string,
            component: component?.[0] as Component,
        },
        {
            enabled: !!component,
        }
    )

    const { mutateAsync, isLoading: isDeleting } = useDeleteObject()

    const [searchParans] = useSearchParams()

    const { data: project } = useGetProject(id as string)

    const projectName = `${project?.projn || ''}${
        project?.proj ? ` [${project.proj}]` : ''
    } `.trim()

    const navigate = useNavigate()

    const onCancel = async () => {
        const confirmation = confirm(
            'Leaving this screen will discard your changes.'
        )

        if (confirmation) {
            if (searchParans.get('isFromAdd') && associatedComps) {
                await Promise.all(
                    associatedComps?.map(async (comp) => {
                        try {
                            const canBeDeleted = await mutateAsync({
                                deleteType: DeleteType.dmriTestOnly,
                                objectType: DeleteObjectTypes.objComponent,
                                objectCodeOrId: comp.id,
                            })

                            if (canBeDeleted.fullDeletePossible) {
                                await mutateAsync({
                                    deleteType:
                                        DeleteType.dmriTestAndFullDeleteIfPossible,
                                    objectType: DeleteObjectTypes.objComponent,
                                    objectCodeOrId: comp.id,
                                })
                            }
                        } catch {
                            // We do nothing if for some reason we can´t delete
                        }
                    })
                )
            }
            navigate(
                getRouteWithParam(URI.project.newItemEdit, {
                    id,
                    itemId: itemId,
                })
            )
        }
    }

    return (
        <HeaderLight>
            <HeaderLight.Breadcumbs>
                <NavLink
                    disabled={isDeleting}
                    onClick={onCancel}
                    className="tw-flex tw-mr-6 tw-items-center tw-text-[#008B6D] tw-font-bold"
                >
                    <i className="ri-arrow-left-s-line"></i> Back
                </NavLink>
                <Breadcrumb>
                    <Breadcrumb.Item
                        linkProps={{ to: URI.project.base }}
                        linkAs={Link}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkProps={{
                            to: getRouteWithParam(URI.project.projectItems, {
                                id,
                            }),
                        }}
                        linkAs={Link}
                    >
                        {projectName}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        linkProps={{
                            to: getRouteWithParam(URI.project.newItemEdit, {
                                id,
                                itemId,
                            }),
                        }}
                        linkAs={Link}
                    >
                        Item
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{component?.[0].comp}</Breadcrumb.Item>
                </Breadcrumb>
            </HeaderLight.Breadcumbs>
            <HeaderLight.Content>
                <HeaderLight.Title>
                    Edit Component
                    {component?.[0].itemName
                        ? `: ${component[0].itemName} `
                        : ''}
                </HeaderLight.Title>
                <div className="tw-flex tw-gap-4">
                    <Button
                        disabled={isDeleting || disableButtons}
                        type="button"
                        onClick={onCancel}
                        variant="light"
                        data-testid="item-add-cancel-button"
                    >
                        Cancel
                    </Button>
                    <SecureBootstrapButton
                        type="submit"
                        disabled={isDeleting || disableButtons}
                        attributeNo={14}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                    >
                        Save
                    </SecureBootstrapButton>
                </div>
            </HeaderLight.Content>
            <p className="tw-m-0 tw-text-base tw-mt-2">
                Ref.No. {component?.[0].refNum}
            </p>
        </HeaderLight>
    )
}

Header.displayName = 'Header'

export default Header
