import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import { Col, Row } from 'react-bootstrap'
import { setLocalStorageKeyValueObject } from '../../../../utilities/FormEvent'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
import HtmlToRtfBrowser from 'html-to-rtf-browser'

class ClientInvoiceAddRemarks extends ThreadComponent {
    constructor(props) {
        super(props)
        this.updatedData = props.updatedData
        this.htmlToRtfConverter = new HtmlToRtfBrowser()
    }

    componentInit() {
        this.setTitle('Add Proposal').setFormAction(true)

        this.state = {
            data: {},
            remarksHtml: '',
        }
    }

    async componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data

            this.setState({
                data: data,
                dataIsLoaded: true,
                remarksHtml: await mapRtfToDraft(
                    this.updatedData?.remarksrtf ?? data?.remarksrtf ?? ''
                ),
            })
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data

            this.setState({
                data: data,
                dataIsLoaded: true,
                remarksHtml: await mapRtfToDraft(
                    this.updatedData?.remarksrtf ?? data?.remarksrtf ?? ''
                ),
            })
        }
    }

    handleWysiwygChange = (e) => {
        let { data } = this.state
        data.remarks = e.target.value
        const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(
            e.target.html
        )

        this.props.onDataChange({ key: 'remarks', value: e.target.value })
        this.props.onDataChange({ key: 'remarksrtf', value: valueHtml })

        setLocalStorageKeyValueObject(
            'client_invoice_edit',
            'remarks',
            e.target.value
        )
        setLocalStorageKeyValueObject(
            'client_invoice_edit',
            'remarksrtf',
            valueHtml
        )
    }

    render() {
        return (
            <>
                {/* Content */}
                <Row lg={2}>
                    <Col>
                        <WysiwygEditor
                            onChange={this.handleWysiwygChange}
                            children={this.state.remarksHtml ?? ''}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceAddRemarks)
