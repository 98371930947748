/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useImperativeHandle } from 'react'
import { useDropzone } from 'react-dropzone'
import { DocumentsAssets } from './Assets'

const Overlay = () => {
    return (
        <div className="documents-dropzone-overlay d-flex flex-column justify-content-center align-items-center w-100 p-5 position-absolute">
            <p className="text-primary-ash fw-bold">
                Drop files to upload them to this page
            </p>
            <img
                src={DocumentsAssets.Image.dropzoneOverlay}
                width="120"
                alt=""
            />
        </div>
    )
}

export const Dropzone = forwardRef(({ children, onDrop }, ref) => {
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: onDrop,
        noClick: children,
    })

    useImperativeHandle(ref, () => ({
        open() {
            open()
        },
    }))

    return (
        <div
            className={`documents-dropzone d-flex position-relative ${
                children && !isDragActive ? 'border-0' : ''
            }`}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <div className={`${isDragActive ? 'opacity-25' : ''} w-100`}>
                {children}
            </div>
            {!children || isDragActive ? <Overlay /> : <></>}
        </div>
    )
})
Dropzone.displayName = 'Dropzone'
