import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../../../assets/styles/components/error-pages.scss'
import { ErrorContent } from '../Error/PageComponentError'

class NotFoundPage extends React.Component {
    render(): JSX.Element {
        return (
            <div className="content-padding error-layout">
                <Container fluid>
                    <Row>
                        <Col
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{ minHeight: 'calc(100vh - 200px)' }}
                        >
                            <ErrorContent
                                code={404}
                                message={`Oops! Can't find what you're looking for.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default NotFoundPage
