export function getDeleteMessage(
    canDelete: boolean,
    moduleNames: string[],
    customMessage = '',
    total = 1,
    reference: string,
    canDeactivate = true,
    from: string
) {
    const pronoun: string = total === 1 ? 'This' : 'These'
    const moduleName: string = total === 1 ? moduleNames[0] : moduleNames[1]
    let message = ''

    if (canDelete) {
        message = `${pronoun} ${moduleName} will be permanently deleted. Are you sure you want to continue?`
    } else if (customMessage && total > 1) {
        return customMessage
    } else {
        if (reference) {
            message = canDeactivate
                ? `${pronoun} ${moduleName} cannot be deleted because it is being referenced by one or more ${reference}. Would you like to ${
                      from === 'project_items' ? 'deactivate' : 'close'
                  } it instead?`
                : `${pronoun} ${moduleName} cannot be deleted because it is being referenced by one or more ${reference}.`
        } else {
            message = canDeactivate
                ? `${pronoun} ${moduleName} cannot be deleted. Would you like to ${
                      from === 'project_items' ? 'deactivate' : 'close'
                  } it instead?`
                : `${pronoun} ${moduleName} cannot be deleted for some unknown reason.`
        }
    }

    if (total > 1 && !canDelete) {
        if (reference) {
            message = `Some of the selected ${moduleName} cannot be deleted because they are being referenced from multiple ${reference}. These ${moduleName} can instead be ${
                from === 'project_items' ? 'inactive' : 'closed'
            }. Would you like to continue?`
        } else {
            message = `Some of the selected ${moduleName} cannot be deleted. These ${moduleName} can instead be ${
                from === 'project_items' ? 'inactive' : 'closed'
            }. Would you like to continue?`
        }
    }

    return message
}

export function getDeactivateMessage(total = 1, from = '') {
    return `Proceed on ${
        from === 'project_items' ? 'deactivating' : 'closing'
    } ${total === 1 ? 'this item' : 'selected items'}?`
}
