import Select from '../form/Select'
import React from 'react'
import { TTypeOption } from './types/TTypeOption'

export type TBaseDropdownProps = {
    options?: TTypeOption[]
    value: TTypeOption | null
    isDisabled?: boolean
    isLoading?: boolean
    height?: number
    placeholder?: string
    inputId?: string
    blurInputOnSelect?: boolean
    onChange?: (newValue: TTypeOption) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    isClearable?: boolean
}

const BaseDropdown = ({
    options = [],
    value,
    inputId,
    isDisabled,
    isLoading,
    height,
    blurInputOnSelect,
    placeholder = 'Please select',
    onChange,
    onBlur,
    isClearable = true,
}: TBaseDropdownProps) => {
    const { value: selectedValue } = value || {}

    return (
        <Select
            styles={{
                control: (base) => ({
                    ...base,
                    height: height || 41,
                    minHeight: height || 41,
                }),
            }}
            inputId={inputId}
            blurInputOnSelect={blurInputOnSelect}
            value={options.find((cat) => cat.value === selectedValue) || null}
            onChange={(newValue) => {
                onChange?.((newValue as TTypeOption) || '')
            }}
            placeholder={placeholder}
            isDisabled={isLoading || isDisabled}
            onBlur={onBlur}
            options={isLoading ? [{ label: 'Loading...', value: '' }] : options}
            isClearable={isClearable}
        />
    )
}

BaseDropdown.displayName = 'BaseDropdown'

export default BaseDropdown
