import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import ItemAddInfo from './ItemAddInfo'
import ItemAddSpecification from './ItemAddSpecification'
import ItemAddPricing from './ItemAddPricing'
import ItemAddStatus from './ItemAddStatus'
import ItemAddWorkroom from './ItemAddWorkroom'
import URI from '../../../../defaults/RoutesDefault'
import {
    getRouteWithParam,
    WithRouter,
    routeParam,
    getActiveMenuUri,
} from '../../../../helpers/Router'
import { compareStr, StringCompare } from '../../../../helpers/String'
import { FooterFormAction } from '../../../components/Form'
import { ApiService } from '../../../../lib/api/HttpService'
import { getSubComponent, Is } from '../../../../helpers/Util'
import {
    displayAlert,
    displayAlertLoader,
} from '../../../../utilities/Response'
import { adjustData } from '../../../../utilities/modules/ItemComponent'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { delay } from 'lodash'
import MSG from '../../../../defaults/Message'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'
import SecureContent from '../../../../app/components/security/SecureContent'
import BudgetBannerProvider from 'app/context/BudgetBannerContext'
import BudgetBanner, {
    BudgetBannerContext,
} from 'app/components/BudgetBanner/BudgetBanner'
import Documents from '../../documents/Documents'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'

class ItemAdd extends ThreadComponent {
    constructor(props) {
        super(props)
        this.api = new ApiService()

        this.state = {
            activeMenu: 'info',
            company: null,
            data: {},
            newId: '',
            projectName: '',
            showModal: true,
            isChanged: false,
            dataLoaded: false,
            isFound: true,
            stateUpdated: false,
            project: null,
            itemCompSort: 'comp asc',
            itemInvoiceSort: 'invoiceNumber asc',
            itemPropSort: 'proposalNumber asc',
        }
        this.errIds = []
        this.handleMenuClick = this.handleMenuClick.bind(this)
        this.htmlToRtfConverter = new HtmlToRtfBrowser()

        this.compViews = [
            'compviewm',
            'compviewd',
            'compviewf',
            'compviewi',
            'compviewl',
            'compviewo',
        ]
        window.clearedSelect = []
    }

    componentInit() {
        this.updatedData = {}
        this.uploadedImage = null
        this.id = this.props.params.itemId
        this.projectId = parseInt(this.props.params.id)
        this.isEditing = this.props.params.itemId != null

        if (!this.isEditing) {
            this.updatedData.projectId = this.projectId
        }

        this.setTitle(`${this.isEditing ? 'Edit' : 'Add'} Item`)
    }

    async componentDidMount() {
        const company = await this.api.get('company')
        window.addEventListener('beforeunload', this.handleBeforeUnload)
        let project = await this.api.getProjects(
            `?$filter=id eq ${parseInt(this.props.params.id)}`
        )
        project = project ? project[0] : 0

        if (project) {
            const projectName = `${project.projn} [${project.proj}]`

            this.setState({
                company,
                projectName: projectName,
                project,
            })
        }
        // Pre-create the items on load
        this.addItem()

        if (this.isEditing) {
            this.fetchItemIfEditing()
        } else {
            let data = {}
            data = await this.addShipToData(data)
            this.renderData(data)
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.data.isChanged !== this.state.isChanged && this.id) {
            this.api.checkBudgetItem(this.id)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload)
        this.handleBeforeUnload()
    }

    handleBeforeUnload = () => {
        // successSaved will check if the item is saved to avoid deleting the item.
        if (!this.isEditing && this.state.successSaved !== true) {
            window.localStorage.setItem(
                'forDelete',
                JSON.stringify({
                    deleteType: 2,
                    objectType: 2,
                    objectCodeOrId: this.id,
                })
            )
        }
    }

    async handleDelete() {
        this.props.navigate(
            routeParam(URI.project.viewPage, {
                id: this.projectId,
                page: 'items',
            })
        )
        // try {
        //     console.log('handleDelete')
        //     const response = await this.api.deleteObjects({
        //         deleteType: 2,
        //         objectType: 2,
        //         objectCodeOrId: this.state.newId,
        //     })
        //     if (response.result === 'dmriFullyDeleted') {
        //         this.props.navigate(
        //             routeParam(URI.project.viewPage, {
        //                 id: this.projectId,
        //                 page: 'items',
        //             })
        //         )
        //     }
        // } catch (err) {
        //     displayAlert('error', 'Could not delete the temporary item.')
        // }
    }

    async fetchItemIfEditing() {
        if (!this.isEditing) {
            let data = await this.getAdditionalData([])
            this.renderData(data)
            return
        }
        let data = {}
        let items = await this.api
            .getProjectItems(`?$filter=id eq ${encodeURIComponent(this.id)}`)
            .then(async (res) => {
                const data = await this.getAdditionalData(res[0])

                this.setIsLoaded(true)
                this.renderData(data)
            })
            .catch((res) => {
                this.setState({
                    stateUpdated: true,
                })
                this.setIsLoaded(true).setIsFound(false)
            })
    }

    async getAdditionalData(data) {
        data = await this.addVendorsData(data)
        data = await this.addShipToData(data)
        data = await this.addStatusData(data)
        data = await this.addProposalData(data)
        data = await this.addInvoiceData(data)
        data = await this.addItemComponentData(data)
        data = await this.addComponentTypeMapping(data)
        data = await this.addSpecificationTemplates(data)
        data = await this.addImageData(data)

        return data
    }

    async addImageData(data) {
        if (!data.primaryImageId) {
            return data
        }

        data.primaryImage = await this.api.getFile(
            data.primaryImageId + '?maxHeight=300'
        )

        return data
    }

    async addVendorsData(data) {
        // Vendors
        const vendors = await this.api.getVendors('?$filter=inactive eq false')
        data.defVendorValue = ''
        data.defVendorLabel = 'Please select'
        let vendorsList = []
        for (let vendor of vendors) {
            vendorsList.push({
                value: vendor.vendor,
                label: vendor.vendorn + ` [${vendor.vendor}]`,
            })
            if (
                !Is.empty(data) &&
                data.workroom &&
                data.workroom === vendor.vendor
            ) {
                data.defVendorValue = vendor.vendor
                data.defVendorLabel = vendor.vendorn + ` [${vendor.vendor}]`
            }
        }
        data.vendorsList = vendorsList
        data.allVendors = vendors
        return data
    }

    async addShipToData(data) {
        // Ship Tos from Clients
        const shipTos = await this.api.getAddresses(
            '?&$filter=addresstype eq 1&$orderby=code asc'
        )

        data.defShipToValue = ''
        data.defShipToLabel = 'Please select'

        let shipToList = []
        for (let shipTo of shipTos) {
            shipToList.push({
                value: shipTo.code,
                label:
                    (shipTo.attention ? shipTo.attention : '') +
                    ` [${shipTo.code}]`,
            })

            if (
                !Is.empty(data) &&
                data.wrshipto &&
                data.wrshipto === shipTo.code
            ) {
                data.defShipToValue = shipTo.code
                data.defShipToLabel =
                    (shipTo.attention ? shipTo.attention : '') +
                    ` [${shipTo.code}]`
            }
        }

        data.shipToList = shipToList

        return data
    }

    async addStatusData(data) {
        // Status Data
        const statusCodes = await this.api.getStatusCodes('')

        data.defStatusValue = ''
        data.defStatusLabel = 'Specifying'

        let statusList = []
        for (let status of statusCodes) {
            statusList.push({
                value: status.statusNumber,
                label: status.statusName,
            })

            if (
                !Is.empty(data) &&
                data.statusnumber &&
                data.statusnumber === status.statusNumber
            ) {
                data.defStatusValue = status.statusNumber
                data.defStatusLabel = status.statusName
            }
        }

        data.statusList = statusList

        return data
    }

    async addProposalData(data) {
        // Proposal
        const proposals = await this.api.getItemProposals(
            `?$filter=projectCode eq '${encodeURIComponent(
                data.proj
            )}' AND itemNumber eq '${data.item}'&$orderby=${
                this.state.itemPropSort
            }`
        )
        data.proposals = proposals

        return data
    }

    async addInvoiceData(data) {
        // Client Invoice
        const invoices = await this.api.getItemInvoices(
            `?$filter=projectCode eq '${encodeURIComponent(
                data.proj
            )}' AND itemNumber eq '${data.item}'&$orderby=${
                this.state.itemInvoiceSort
            }`
        )
        data.invoices = invoices

        return data
    }

    async addItemComponentData(data) {
        // Item components
        const components = await this.api.getComponents(
            `?$filter=itemid eq ${this.props.params.itemId} AND projectid eq ${this.props.params.id}&$orderby=${this.state.itemCompSort}`
        )

        const itemComponents = await Promise.all(
            components.map(async (item) => {
                if (item.comptype !== 5) return item

                const [{ txnum = null }] = await this.api.getTimeEntries(
                    `?$filter=comp eq '${item.comp}' AND item eq '${item.item}' AND proj eq '${item.proj}'`
                )

                return { ...item, txnum }
            })
        )

        data.itemComponents = itemComponents

        return data
    }

    async addComponentTypeMapping(data) {
        const company = await this.api.get('company')
        data.componentTypes = {
            0: company.compviewm ?? '0',
            1: company.compviewd ?? '1',
            2: company.compviewf ?? '2',
            3: company.compviewi ?? '3',
            4: company.compviewl ?? '4',
            5: company.compviewo ?? '5',
        }

        return data
    }

    async addSpecificationTemplates(data) {
        const templates = await this.api.getSpecificationTemplates('')
        data.specificationTemplates = templates

        return data
    }

    renderData(data) {
        this.setIsLoaded(true)
        this.setState({
            data: data ?? {},
        })
    }

    handleChangeSortOrder = async (objSort) => {
        const [key, sort] = Object.entries(objSort)[0]
        this.setState({
            [key]: sort,
        })

        await this.fetchItemIfEditing()
    }

    handleMenuClick = (e) => {
        e.preventDefault()

        this.setState(
            (prevState) =>
                (prevState.activeMenu = e.target.getAttribute('data-menu'))
        )
    }

    isStringAtFirstPosition(fullString, searchString) {
        fullString = fullString.trim()
        searchString = searchString.trim()
        return fullString.startsWith(searchString)
    }

    async setDescription() {
        const itemName = this.updatedData.itemName ?? this.state.data.itemName
        const company = await this.api.get('company2')
        let descOnlyRtf =
            this.updatedData.descOnlyRtf ?? this.state.data.descOnlyRtf

        let descOnly = this.updatedData.descOnly ?? this.state.data.descOnly

        if (!descOnly && itemName) {
            descOnly = ''
            descOnlyRtf = this.htmlToRtfConverter.convertHtmlToRtf(itemName)
        }

        const itemNameFormatted = company.boldItemName
            ? '{\\b ' + itemName + ' }\\sb70'
            : itemName ?? ''

        this.updatedData.descrtf = descOnlyRtf?.replace(
            '{\\pard',
            `{\\pard ${itemNameFormatted?.replace(' ', '\\~')}\\line`
        )

        this.updatedData.desc = itemName
            ? itemName + '\r\n' + descOnly
            : descOnly
    }

    handleSaveClick = async (e) => {
        e.preventDefault()
        if (this.updatedData.descOnlyRtf || this.updatedData.itemName) {
            await this.setDescription()
        }

        this.editItem(true)
    }

    addComponent = async (data) => {
        const createAutoComponents =
            this.state.project.autom ||
            this.state.project.autod ||
            this.state.project.autof ||
            this.state.project.autoi ||
            this.state.project.autol ||
            this.state.project.autoo
        const stubData = {
            itemId: this.state.newId,
            componentType: 0,
            associatedComponentNumber: '001',
            createAutoComponents,
            setDefaultsFromItem: true,
        }

        const mergedData = { ...data, ...stubData }

        const component = await this.api.createComponent(mergedData)

        if (this.updatedData.esttcost || this.updatedData.esttprice) {
            let calcData = null
            if (this.updatedData.esttcost) {
                const stubData = {
                    whatChanged: 'cwcEstimatedCost',
                    estcost: this.updatedData.esttcost,
                }

                calcData = await this.api.componentRecalculate(
                    component.id,
                    stubData
                )
            }

            if (this.updatedData.esttprice) {
                calcData = {
                    ...calcData,
                    whatChanged: 'cwcEstimatedPrice',
                    estprice: this.updatedData.esttprice,
                }

                calcData = await this.api.componentRecalculate(
                    component.id,
                    calcData
                )
            }

            if (calcData) {
                await this.api.editComponent(component.id, adjustData(calcData))

                const stubData = {
                    whatChanged: 'iwcRecalcFromComponentsAndStatus',
                    saveChanges: true,
                }
                await this.api.itemRecalculate(this.state.newId, stubData)
            }
        }

        if (createAutoComponents) {
            const company = await this.api.get('company')
            const acomps = await this.api.get(
                'components',
                `?$filter=projectid eq ${this.projectId} and itemid eq ${this.state.newId} and assocwithcomp eq '${component.comp}' and comp ne '${component.comp}'`
            )

            for await (let associatedComponent of acomps) {
                const type = this.compViews[associatedComponent.comptype]
                const desc = `..... ${company[type]} for component ${component.comp}`
                const compData = {
                    desc,
                    descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
                }
                await this.api
                    .editComponent(associatedComponent.id, compData)
                    .catch((error) => {
                        displayAlert('danger', error.response.data.userError)
                    })
            }
        }
    }

    setDefaultDescription = () => {
        if (this.props.params.itemId) {
            return
        }
        if (!this.updatedData.desc) {
            this.updatedData.desc = ''
        }
        if (!this.updatedData.descOnly) {
            this.updatedData.descOnly = ''
        }
        if (!this.updatedData.descOnlyRtf) {
            this.updatedData.descOnlyRtf =
                this.htmlToRtfConverter.convertHtmlToRtf('')
        }
        if (!this.updatedData.descrtf) {
            this.updatedData.descrtf =
                this.htmlToRtfConverter.convertHtmlToRtf('')
        }
    }

    editItem = async (redirect = true) => {
        displayAlertLoader(MSG.loading.update.msg)
        if (this.updatedData.descOnlyRtf || this.updatedData.itemName) {
            await this.setDescription()
        }
        this.setDefaultDescription()
        delete this.updatedData['budgetcheck']

        await this.uploadImage(this.id)
        try {
            if (
                // this.updatedData.cldeppctUpdated &&
                !this.updatedData.estdepUpdated &&
                this.updatedData.cldeppct > 0
            ) {
                delete this.updatedData.estdep
            } else if (this.updatedData.estdepUpdated) {
                this.updatedData.cldeppct = 0
            }

            this.updatedData.cldeppct = Number(
                this.updatedData.cldeppct ?? this.state.project.clientdep
            )

            if (!this.updatedData.cldeppctUpdated) {
                delete this.updatedData.cldeppct
            }

            delete this.updatedData.cldeppctUpdated
            delete this.updatedData.estdepUpdated
            delete this.updatedData.selectedWorkroomVendor
            delete this.updatedData.defaultLocation
            delete this.updatedData.defaultSalesCategory

            if (this.updatedData.selectedShipto) {
                this.updatedData.wrshipto =
                    this.updatedData.selectedShipto.value
                delete this.updatedData.selectedShipto
            }

            await this.api.editProjectItem(this.id, this.updatedData)

            displayAlert('success', 'Item has been updated successfully')

            window.removeEventListener('beforeunload', this.handleBeforeUnload)
            window.localStorage.removeItem('forDelete')

            if (!this.isEditing && redirect) {
                await this.addComponent(this.updatedData)
            }

            if (redirect) {
                this.setState({
                    successSaved: true,
                })
                delay(() => {
                    this.props.navigate(
                        routeParam(URI.project.viewPage, {
                            id: this.projectId,
                            page: 'items',
                        })
                    )
                }, 1500)
            } else {
                return true
            }
        } catch (err) {
            displayAlert(
                'danger',
                err?.response?.data?.UserError ||
                    err?.response?.data?.userError ||
                    'An error happened, please try again'
            )
            return false
        }
    }

    addItem = async () => {
        try {
            const res = await this.api.createProjectItem(this.updatedData)
            const newId = (this.id = res.id)

            this.setState({
                newId: newId,
                dataLoaded: true,
            })
            return newId
        } catch (err) {
            return false
        }
    }

    handleCancel = () => {
        this.handleDelete()
    }

    handleDataChange = (data) => {
        this.updatedData[data.key] = data.value
        this.setState({
            isChanged: true,
        })
    }

    async uploadImage(itemId) {
        if (!this.uploadedImage) {
            return
        }
        this.setState({
            isChanged: true,
        })
        let uploadedImage = await this.api.saveFile({
            file: this.uploadedImage,
            ObjectType: 'item',
            ObjectId: itemId,
            FileType: 1,
        })

        this.updatedData.primaryImageId = uploadedImage.id

        if (this.state.data.primaryImageId) {
            try {
                this.api.deleteFile(this.state.data.primaryImageId)
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }

    onAcceptedFiles = (file) => {
        this.setState({
            isChanged: true,
        })
        this.uploadedImage = file
    }

    onRemoveFiles = () => {
        this.setState({
            isChanged: true,
            data: {
                ...this.state.data,
                primaryImage: null,
            },
        })
        this.uploadedImage = null
        this.updatedData.primaryImageId = null
    }

    handleClick = (e) => {
        if (!this.isEditing) {
            if (this.state.isChanged === true) {
                if (confirm('Leaving this screen will discard your changes.')) {
                    this.handleDelete()
                }
            } else {
                this.handleDelete()
            }
        } else {
            location.href = '/projects/' + this.props.params.id + '/items'
        }
    }

    header() {
        const { activeMenu } = this.state
        const { data } = this.state
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        onClick={this.handleClick}
                        //to={"/projects/" + this.props.params.id + "/items"}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                        data-testid="breadcrumbs-back-button"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    {(() => {
                        if (this.props.params.itemId) {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.projectName}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.itemEdit,
                                                {
                                                    id: this.props.params.id,
                                                    itemId: this.props.params
                                                        .itemId,
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {'Edit Item'}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Info</Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        } else {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.projectName}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.itemAdd,
                                                {
                                                    id: this.props.params.id,
                                                    itemId: this.props.params
                                                        .itemId,
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {'Add Item'}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Info</Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        }
                    })()}
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>
                        {this.isEditing
                            ? `Edit ${
                                  data.itemName
                                      ? `Item: ${data.itemName}`
                                      : 'Item:'
                              }`
                            : 'Add Item'}
                    </HeaderLight.Title>
                    <div>
                        {!this.state.data.itemName && (
                            <Button
                                className="me-2"
                                variant="light"
                                onClick={this.handleCancel}
                                data-testid="item-add-cancel-button"
                            >
                                Cancel
                            </Button>
                        )}

                        <SecureBootstrapButton
                            attributeNo={14}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            variant="primary"
                            onClick={this.handleSaveClick}
                        >
                            Save
                        </SecureBootstrapButton>
                    </div>
                </HeaderLight.Content>
                {data?.item && (
                    <h6 className="mb-4">{`Ref. No. ${data?.item}`}</h6>
                )}

                <BudgetBannerProvider>
                    <BudgetBanner
                        context={BudgetBannerContext.Header}
                        dataTestId="budget-banner-item"
                        className="mb-4"
                    />
                </BudgetBannerProvider>

                <HeaderLight.Actions className="text-charcoal">
                    <li>
                        <Button
                            as={Link}
                            to={getRouteWithParam(URI.project.itemAdd, {
                                id: 1,
                            })}
                            data-menu="info"
                            className={`${compareStr(
                                activeMenu,
                                'info',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Info
                        </Button>
                    </li>
                    {localStorage.getItem(
                        FeatureFlags.SpecificationTemplates
                    ) === 'true' && (
                        <li>
                            <Button
                                as={Link}
                                to={getRouteWithParam(URI.project.itemAdd, {
                                    id: 1,
                                })}
                                data-menu="specification"
                                className={`${compareStr(
                                    activeMenu,
                                    'specification',
                                    'active',
                                    ''
                                )}`}
                                onClick={this.handleMenuClick}
                            >
                                Specification
                            </Button>
                        </li>
                    )}
                    <li>
                        <SecureContent
                            attributeNo={14}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec7}
                        >
                            <SecureContent.HasAccess>
                                <Button
                                    as={Link}
                                    to={getRouteWithParam(URI.project.itemAdd, {
                                        id: 1,
                                    })}
                                    data-menu="pricing"
                                    className={`${compareStr(
                                        activeMenu,
                                        'pricing',
                                        'active',
                                        ''
                                    )}`}
                                    onClick={this.handleMenuClick}
                                >
                                    Pricing
                                </Button>
                            </SecureContent.HasAccess>
                        </SecureContent>
                    </li>
                    <li>
                        <Button
                            as={Link}
                            to={getRouteWithParam(URI.project.itemAdd, {
                                id: 1,
                            })}
                            data-menu="status"
                            className={`${compareStr(
                                activeMenu,
                                'status',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Status
                        </Button>
                    </li>
                    <li>
                        <Button
                            as={Link}
                            to={getRouteWithParam(URI.project.itemAdd, {
                                id: 1,
                            })}
                            data-menu="workroom"
                            className={`${compareStr(
                                activeMenu,
                                'workroom',
                                'active',
                                ''
                            )}`}
                            onClick={this.handleMenuClick}
                        >
                            Workroom
                        </Button>
                    </li>
                    {this.isEditing && (
                        <li>
                            <Button
                                as={Link}
                                data-menu="documents"
                                className={`${compareStr(
                                    activeMenu,
                                    'documents',
                                    'active',
                                    ''
                                )}`}
                                onClick={this.handleMenuClick}
                            >
                                Documents{' '}
                                {this.state.data.numAttachments > 0 && (
                                    <i className="ri-attachment-2 ms-1"></i>
                                )}
                            </Button>
                        </li>
                    )}
                </HeaderLight.Actions>
            </HeaderLight>
        )
    }

    getContent() {
        const { activeMenu, data, project } = this.state

        return getSubComponent(activeMenu, {
            info: (
                <ItemAddInfo
                    isEditing={this.isEditing}
                    data={data}
                    uploadedImage={this.uploadedImage}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                    onAcceptedFiles={this.onAcceptedFiles}
                    onRemoveFiles={this.onRemoveFiles}
                    newItemId={this.state.newId}
                    onRecalculate={(data) => {
                        this.updatedData = {
                            ...this.updatedData,
                            ...data,
                        }
                    }}
                    addItem={this.addItem}
                    editItem={this.editItem}
                    project={project}
                    addItemComponentData={this.addItemComponentData.bind(this)}
                />
            ),
            specification: (
                <ItemAddSpecification
                    data={data}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                />
            ),
            pricing: (
                <ItemAddPricing
                    data={data}
                    company={this.state.company}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                    changeSortOrder={this.handleChangeSortOrder}
                />
            ),
            status: (
                <ItemAddStatus
                    data={data}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                    changeSortOrder={this.handleChangeSortOrder}
                />
            ),
            workroom: (
                <ItemAddWorkroom
                    data={data}
                    project={this.state.project}
                    updatedData={this.updatedData}
                    onDataChange={this.handleDataChange}
                />
            ),
            documents: (
                <Documents
                    objectType="Item"
                    objectId={this.id}
                    onRefresh={(numAttachments) => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                numAttachments: numAttachments,
                            },
                        })
                    }}
                />
            ),
        })
    }

    render() {
        return this.renderView(
            <>
                {this.header()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.getContent()}</Container>
                </div>

                <FooterFormAction>
                    <SecureBootstrapButton
                        attributeNo={14}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        variant="primary"
                        size="lg"
                        onClick={this.handleSaveClick}
                    >
                        Save
                    </SecureBootstrapButton>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ItemAdd)
