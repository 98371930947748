import { useController } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'

import { ApiService } from '../../../lib/api/HttpService'
import MyDropzone from '../../../templates/ui-kit/MyDropzone'

const LogoControl = ({ name, control, ...props }) => {
    const api = new ApiService()

    const {
        field: { value, onChange },
    } = useController({ name, control })

    const { isLoading, data } = useQuery({
        queryKey: ['settings', 'company', 'logo', value],
        queryFn: ({ queryKey: [, , , value] }) => {
            if (!value) return ''
            if (typeof value !== 'string') return value

            return api.getFile(value)
        },
    })

    const handleAcceptedFiles = (file) => onChange(file)

    const handleRemoveFiles = () => onChange('')

    if (isLoading) return null

    return (
        <MyDropzone
            {...props}
            image={data}
            onAcceptedFiles={handleAcceptedFiles}
            onRemoveFiles={handleRemoveFiles}
        />
    )
}

export default LogoControl
