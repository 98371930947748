import React from 'react'
import { Button, Form } from 'react-bootstrap'
import ListFilter from '../../components/ListFilter'
import { useEffect, useState } from 'react'
import { FLOAT } from 'app/regex/budgets'

export const BudgetToolbar = ({
    budgetData,
    id,
    isSearching,
    onSearch,
    onAdd,
    onDelete,
    onOverrideChange,
    onSave,
    onOverallToleranceChange,
    budgetTotal,
    isOverride,
    overallWarning,
    setOverallWarning,
    setBudgetTotal,
    overallTolerance,
    setOverallTolerance,
    setOverride,
    getAllBudget,
    isDeleteAvailable,
}) => {
    const [data, setData] = useState(budgetData)
    const [budgett, setBudgett] = useState(0)
    useEffect(() => {
        setData(budgetData)
        setBudgett(budgetData.budgett)
        setOverallTolerance(budgetData.budgettol)
        setOverallWarning(budgetData.budgetwarn)
        if (budgetData.budgettoverride) {
            setBudgetTotal(budgetData.budgett)
        }

        if (typeof budgetData.budgettoverride !== 'undefined') {
            setOverride(budgetData.budgettoverride)
            if (!budgetData.budgettoverride) {
                getAllBudget()
            }
        }
    }, [budgetData])
    return (
        <>
            <ListFilter className="project-item-view-filter" expandable={1}>
                {id && (
                    <ListFilter.Fields
                        lg={12}
                        xl={8}
                        xxl={8}
                        className="position-relative zi-10 width-20"
                    >
                        <ListFilter.Field>
                            <Form.Check
                                type="checkbox"
                                id="overrideTotal"
                                label="Override Total"
                                checked={isOverride}
                                onChange={onOverrideChange}
                                className="me-3"
                            />
                            <Form.Label className="me-2">
                                Budget
                                <br />
                                Total
                            </Form.Label>
                            <Form.Control
                                className="me-2"
                                id="budgett"
                                type="number"
                                size="sm"
                                value={budgetTotal}
                                disabled={!isOverride}
                                onChange={(e) => {
                                    setBudgett(e.target.value)
                                    setBudgetTotal(e.target.value)
                                }}
                            />
                        </ListFilter.Field>
                        <ListFilter.Field>
                            <Form.Label className="me-2">
                                Overall
                                <br />
                                Warning
                            </Form.Label>
                            <Form.Select
                                className="me-2"
                                id="budgetwarn"
                                size="sm"
                                value={data.budgetwarn}
                                onChange={(e) =>
                                    setOverallWarning(e.target.value)
                                }
                            >
                                <option value="0">None</option>
                                <option value="1">Display Warning</option>
                                <option value="2">Block Entry</option>
                            </Form.Select>
                        </ListFilter.Field>
                        <ListFilter.Field>
                            <Form.Label className="me-2">
                                Overall Tolerance%
                            </Form.Label>
                            <Form.Control
                                className="me-3"
                                id="budgettol"
                                type="number"
                                step="0.01"
                                size="sm"
                                value={overallTolerance}
                                onChange={(e) => {
                                    const inputValue = e.target.value.trim()
                                    if (
                                        inputValue === '' ||
                                        FLOAT.test(inputValue)
                                    ) {
                                        if (inputValue === '') {
                                            setOverallTolerance(
                                                budgetData.budgettol
                                            )
                                        } else {
                                            const roundedValue =
                                                Math.round(
                                                    parseFloat(inputValue) * 100
                                                ) / 100
                                            onOverallToleranceChange(
                                                roundedValue
                                            )
                                        }
                                    }
                                }}
                                min="0"
                                max="100"
                            />
                        </ListFilter.Field>
                        <ListFilter.Field>
                            <Button
                                variant="primary"
                                size="sm"
                                className="btn-icon me-2"
                                onClick={() => {
                                    onSave({
                                        id,
                                        budgett: budgett,
                                        budgetwarn:
                                            typeof overallWarning != 'undefined'
                                                ? Number(overallWarning)
                                                : data.budgetwarn,
                                        budgettol:
                                            typeof overallTolerance !=
                                            'undefined'
                                                ? Number(overallTolerance)
                                                : data.budgettol,
                                        budgettoverride: isOverride,
                                    })
                                }}
                            >
                                Save
                            </Button>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                )}
                <ListFilter.Actions lg={12} xl={4} xxl={4}>
                    <ListFilter.Action className="me-2 me-sm-3">
                        <Button
                            variant="ivory"
                            size="sm"
                            className={`btn-icon btn-action fw-bold me-2 ${
                                isSearching ? 'bg-primary-ash text-white' : ''
                            }`}
                            onClick={onSearch}
                        >
                            <i className="ri-search-line"></i> Search
                        </Button>
                    </ListFilter.Action>
                    <ListFilter.Action className="me-2 me-sm-3">
                        <Button
                            variant="primary"
                            size="sm"
                            className="btn-icon me-2"
                            onClick={onAdd}
                        >
                            <i className="ri-add-fill"></i> Add
                        </Button>
                    </ListFilter.Action>
                    <ListFilter.Action className="me-2 me-sm-3">
                        <Button
                            variant="danger"
                            size="sm"
                            className="btn-icon me-2"
                            onClick={onDelete}
                            disabled={!isDeleteAvailable}
                        >
                            <i className="ri-close-line"></i> Delete
                        </Button>
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        </>
    )
}
