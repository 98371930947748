import { Button, Modal, Table } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'

import { getVendorInvoiceDetails } from './api'
import { formatDate, formatFiscalMonth, formatMoney } from './utils'
/*
amount: 32118.15
bfmatch: false
cleared: true
entrytype: 3
pon: null
ponum: null
proj: null
recnum: 2065386
servicechg: false
sinvno: "Other G/L Entry (8001)"
sumtag: false
supplier: null
txdesc: "Year End AJE"
txnum: 13423
type: 8001
usercode: 63
userdate: "2015-12-31T00:00:00"
vendorn: null

`?$filter=txnum eq ${this.props.params.id}`

https://xd.adobe.com/view/61dfb526-b0fb-44f0-ad62-5ee95d4c379d-348c/screen/c8ce4569-bb85-4675-8254-16f0f75e7d6c
*/
function SectionHeader({ children }) {
    return (
        <div className="bg-secondary-grey px-4">
            <div className="py-3 justify-content-center align-items-center row row-cols-lg-2 row-cols-1">
                {children}
            </div>
        </div>
    )
}
function Group({ children }) {
    return <div className="align-items-center mb-0 mb-lg-4 row">{children}</div>
}
function Label({ children }) {
    return (
        <div className="text-lg-end mb-2 mb-lg-0 col-4">
            <span className="mb-0 form-label">{children}</span>
        </div>
    )
}

function Value({ children }) {
    return (
        <div className="col">
            <span className="fw-bold">{children}</span>
        </div>
    )
}

export function Grid({ columns, processor, query }) {
    const columnCount = columns.length
    const { data, isLoading } = query

    const processedData = isLoading
        ? null
        : typeof processor === 'function'
        ? processor(data)
        : data

    return (
        <Table striped responsive className="a-table">
            <thead>
                <tr>
                    {columns.map(({ field, label }) => (
                        <th key={field}>{label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isLoading ? (
                    <>
                        <tr className="tr-loading">
                            <td className="td-1" colSpan={columnCount}>
                                <span>&nbsp;</span>
                            </td>
                        </tr>
                        <tr className="tr-loading">
                            <td className="td-1" colSpan={columnCount}>
                                <span>&nbsp;</span>
                            </td>
                        </tr>
                    </>
                ) : (
                    processedData.map((item) => {
                        return (
                            <tr>
                                {columns.map(({ field }) => (
                                    <td key={`${field}-${item[field]}`}>
                                        {item[field]}
                                    </td>
                                ))}
                            </tr>
                        )
                    })
                )}
            </tbody>
        </Table>
    )
}

export default function TransactionModal({
    accountn,
    isShowing,
    onClose,
    transaction,
}) {
    const vendorInvoiceDetailsQuery = useQuery({
        enabled: isShowing,
        queryKey: [
            'accounts-payable',
            'credit-card',
            'reconcile',
            'vendor-invoide-detail',
            transaction?.txnum,
        ],
        queryFn: () => getVendorInvoiceDetails(`?$filter=txnum eq ${txnum}`),
    })

    if (!transaction) return null

    const { amount, ponum, sinvno, txdesc, txnum, type, userdate } = transaction

    return (
        <Modal show={isShowing}>
            <Modal.Header>
                <Modal.Title>Transaction Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        <SectionHeader>
                            <h6 className="fw-bold mb-0">Charge Detail</h6>
                            <div className="text-end"></div>
                        </SectionHeader>
                        <div className="mt-4 d-flex justify-content-between">
                            <div>
                                <Group>
                                    <Label>TX #</Label>
                                    <Value>{txnum}</Value>
                                </Group>
                                <Group>
                                    <Label>Type</Label>
                                    <Value>{type}</Value>
                                </Group>
                                <Group>
                                    <Label>Invoice No.</Label>
                                    <Value>{sinvno}</Value>
                                </Group>
                                <Group>
                                    <Label>Invoice Date</Label>
                                    <Value>{formatDate(userdate)}</Value>
                                </Group>
                                <Group>
                                    <Label>Fiscal Month</Label>
                                    <Value>{formatFiscalMonth(userdate)}</Value>
                                </Group>
                            </div>
                            <div className="flex-grow-1">
                                <Group>
                                    <Label>Account</Label>
                                    <Value>{accountn}</Value>
                                </Group>
                                <Group>
                                    <Label>Description</Label>
                                    <Value>{txdesc}</Value>
                                </Group>
                                <Group>
                                    <Label>PO No.</Label>
                                    <Value>{ponum}</Value>
                                </Group>
                                <Group>
                                    <Label>Amount</Label>
                                    <Value>{formatMoney(amount)}</Value>
                                </Group>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SectionHeader>
                            <h6 className="fw-bold mb-0">
                                Vendor Invoice Dtail
                            </h6>
                            <div className="text-end">
                                Transaction No.:{' '}
                                <span className="fw-bold">{txnum}</span>
                            </div>
                        </SectionHeader>
                        <div>
                            <Grid
                                query={vendorInvoiceDetailsQuery}
                                columns={[
                                    {
                                        label: 'Account No.',
                                        field: 'account',
                                    },
                                    {
                                        label: 'Account Name',
                                        field: 'accountName',
                                    },
                                    {
                                        label: 'Debit',
                                        field: 'debit',
                                    },
                                    {
                                        label: 'Credit',
                                        field: 'credit',
                                    },
                                ]}
                                processor={(data) =>
                                    data.map((item) => {
                                        const { amount } = item

                                        if (amount > 0) {
                                            return {
                                                ...item,
                                                credit: null,
                                                debit: amount,
                                            }
                                        }
                                        return {
                                            ...item,
                                            credit: amount,
                                            debit: null,
                                        }
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                    variant="trans-light border-secondary-ash"
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
