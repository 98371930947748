import React from 'react'
import { ResourcesGridConstants } from 'app/constants/dashboard/resourcesGridConstants'
import BaseButton from 'app/components/Buttons/BaseButton'

const BannerContent = () => (
    <div className="tw-p-4  tw-relative sm:tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-flex tw-flex-col sm:tw-p-0">
        <div className="tw-flex tw-flex-col tw-items-center tw-bg-white tw-bg-opacity-75tw-rounded-lg ">
            <h2 className="tw-text-xl tw-font-bold">
                {ResourcesGridConstants.bannerTitle}
            </h2>
            <p className="tw-mt-2 tw-text-gray-400 tw-mb-0">
                {ResourcesGridConstants.bannerSubtitle}
            </p>
            <BaseButton
                text={ResourcesGridConstants.bannerButton}
                buttonClass="tw-mt-4 tw-px-4 tw-py-2 tw-bg-[#008B6D] tw-text-white tw-rounded"
                onClick={() =>
                    (window.location.href =
                        ResourcesGridConstants.bannerSendMail)
                }
            />
        </div>
    </div>
)

BannerContent.displayName = 'BannerContent'

export default BannerContent
