import React from 'react'
import {
    addBodyClass,
    addDomClass,
    hasClass,
    removeBodyClass,
    removeDomClass,
    setDocumentTitle,
    WindowScroll,
} from '../../helpers/DOM'
import _, { delay } from 'underscore'
import NotFoundPage from '../../app/pages/Home/NotFoundPage'
import {
    getErrorMessage,
    getStatusCode,
    hideAlertLoader,
} from '../../utilities/Response'
import PageModuleError from '../../app/pages/Error/PageModuleError'
import PageComponentError from '../../app/pages/Error/PageComponentError'
import { initClickBreadcrumb, toggleDMPageLoader } from '../../utilities/DOM'
import Spinner from '../../app/components/help/Spinner'
import { ApiService } from '../../lib/api/HttpService'
import { setLocalStorage } from '../../utilities/LocalStorage'
import tableEvent from '../../utilities/Table'
import { isEmpty } from 'lodash'

class ThreadComponent extends React.Component {
    constructor(props) {
        super(props)

        // Initialize properties which can be overriten on `componentInit` child method.
        this.title = ''
        this.description = ''
        this.hasFormAction = false
        this.hasActionBar = false
        this.apiURL = ''
        this.apiParams = {}
        this.rawData = ''
        this.spinnerIsChild = false
        this.isFound = true
        this.isLoaded = false
        this.errorObj = {
            message: 'Page not found.',
            code: 404,
            isPage: false,
        }
        this.dmLoaderId = ''

        // Set api handler.
        this.api = new ApiService()

        /**
         * Initialize the component
         * Override this inside your class component.
         */
        this._init()
        this.componentInit()

        // Let's call the default method.
        this.setDocumentTitle().setBodyClass()

        // Call window and dom events.
        delay(() => {
            WindowScroll(this.onScroll)
            this.initEvents()
        }, 30)
    }

    _init() {
        // Check if the page is time entry
        // If not, remove the default filters.
        if (window.location.href.indexOf('/time-entry') < 0) {
            setLocalStorage('resetTimeEntryFilter', 'yes')
        }
    }

    componentInit() {
        // Called every time the `ThreadComponent` is extended.
        return this
    }

    dMloader(show = true, force = false, isSidebar = true) {
        if (show) {
            const loader = toggleDMPageLoader(
                typeof show === 'boolean' ? true : show,
                isSidebar
            )
            if (loader) {
                this.dmLoaderId = loader
            }
        } else {
            if (force === true) {
                toggleDMPageLoader({ destroy: true })
            } else if (this.dmLoaderId) {
                toggleDMPageLoader(this.dmLoaderId, isSidebar)
            }
        }
    }

    setTitle(title) {
        this.title = title
        return this
    }

    setActionBar(status) {
        this.hasActionBar = status
        return this
    }

    setFormAction(status) {
        this.hasFormAction = status
        return this
    }

    setApiURL(url) {
        this.apiURL = url
        return this
    }

    setRawData(data) {
        this.rawData = data
        return this
    }

    setApiParams(params) {
        this.apiParams = params
        return this
    }

    setDocumentTitle() {
        if (this.title) {
            setTimeout(() => setDocumentTitle(this.title), 30)
        }

        return this
    }

    setBodyClass() {
        // remove scrolled class.
        removeBodyClass('app-scrolled-bottom')

        // Check if has form.
        if (this.hasFormAction) {
            addBodyClass('app-contains-form')
        } else {
            removeBodyClass('app-contains-form')
        }

        // Check if has form.
        if (this.hasActionBar) {
            addBodyClass('app-contains-action-bar')
        } else {
            removeBodyClass('app-contains-action-bar')
        }
    }

    onScroll() {
        // set scrolled class.
        if (
            window.innerHeight + window.pageYOffset >=
            document.body.offsetHeight - 2
        ) {
            addBodyClass('app-scrolled-bottom')
        } else {
            removeBodyClass('app-scrolled-bottom')
        }
    }

    initEvents() {
        // Bind click event on breadcrumb links.
        // If draft is enabled, this will trigger browser confirm()
        // before leaving the current screen
        delay(() => {
            initClickBreadcrumb()
        }, 200)

        tableEvent()
    }

    fetchData() {
        if (this.rawData) {
            if (this.renderData) {
                this.doTableSearch(this.doSortTable(this.rawData))
            }
        } else {
            fetch(this.apiURL)
                .then((res) => res.json())
                .then((json) => {
                    let data = json

                    if (this.apiParams && this.apiParams.responseKey) {
                        data = json[this.apiParams.responseKey]
                    }

                    if (this.renderData) {
                        this.doTableSearch(this.doSortTable(data))
                    }
                })
        }
    }

    enableSearchAndSortTable() {
        delay(() => {
            this.enableTableSearch()
            this.enableSortTable()
        }, 100)

        return this
    }

    enableTableSearch() {
        const input = document.querySelectorAll('.a-table-search-fields input')
        const self = this

        // Add change event
        if (input) {
            input.forEach((_in) => {
                _in.addEventListener(
                    'change',
                    function (e) {
                        self.fetchData()
                    },
                    false
                )

                _in.addEventListener(
                    'keyup',
                    function (e) {
                        self.fetchData()
                    },
                    false
                )
            })
        }
    }

    enableSortTable(callback = null) {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const field = _e.attributes['data-field']
                        const sort = hasClass('desc', _e) ? 'asc' : 'desc'

                        delay(() => {
                            if (typeof callback === 'function') {
                                callback(field, sort)
                            } else if (typeof self.fetchData === 'function') {
                                self.fetchData(field, sort)
                            }
                        }, 100)
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {
        const input = document.querySelectorAll('.a-table-search-fields input')
        let _data = data

        let filters = {}
        let d = _data

        input.forEach((_in) => {
            if (_in.value && _in.dataset.type !== 'number') {
                d = _.filter(d, function (d) {
                    d[_in.dataset.field] =
                        d[_in.dataset.field] === null
                            ? ''
                            : d[_in.dataset.field]

                    if (typeof d[_in.dataset.field] == 'number') {
                        return d[_in.dataset.field]
                            .toString()
                            .match(new RegExp(_in.value, 'g'))
                    }

                    return (
                        d[_in.dataset.field]
                            .toLowerCase()
                            .indexOf(
                                _in.value.toLowerCase().replace(/\s+$/, '')
                            ) > -1
                    )
                })
            }
        })

        input.forEach((_in) => {
            if (_in.value && _in.dataset.type === 'number') {
                filters[_in.dataset.field] = parseFloat(_in.value)
            }
        })

        if (Object.keys(filters).length) {
            d = _.where(_data, { ...filters })
        }

        if (this.renderData) {
            this.renderData(d)
        }
    }

    doSortTable(data) {
        const sort = document.querySelector('.a-table-heading .sort.active')

        if (sort) {
            data = _.sortBy(data, sort.dataset.field)

            if (hasClass('desc', sort)) {
                data = data.reverse()
            }
        }

        return data
    }

    getFilters() {
        const input = document.querySelectorAll('.a-table-search-fields input')
        let filters = []

        input.forEach((_in) => {
            if (_in.value && _in.dataset.type !== 'number') {
                filters.push(_in.dataset.field + " eq '" + _in.value + "'")
            }
        })

        input.forEach((_in) => {
            if (_in.value && _in.dataset.type === 'number') {
                filters.push(_in.dataset.field + ' eq ' + _in.value)
            }
        })

        return filters
            ? filters.length > 1
                ? filters.join(' and ')
                : filters[0]
            : ''
    }

    hasSearchFilter(filters, hasFilter = false) {
        Object.keys(filters ?? {}).map((k) => {
            // Check if there are active search query on any form field.
            if (!isEmpty(filters[k].value)) {
                hasFilter = true
            }
        })

        return hasFilter
    }

    disableForm() {
        const contentEl = document.querySelector('.main-content')
        const forms = contentEl.querySelectorAll(
            'input, select, button, textarea, legend, fieldset'
        )

        if (forms.length) {
            forms.forEach((i) => {
                i.setAttribute('disabled', true)
                i.setAttribute('readOnly', true)
            })
        }
    }

    navigate(path) {
        this.props.navigate(path)
    }

    setIsChildSpinner(state) {
        this.spinnerIsChild = state

        return this
    }

    setIsLoaded(state) {
        this.isLoaded = state

        return this
    }

    setIsFound(state) {
        this.isFound = state

        return this
    }

    setError(error, isPage = false) {
        this.errorObj = {
            message: getErrorMessage(error),
            code: getStatusCode(error),
            isPage,
        }

        // Display an error.
        if (200 !== this.errorObj.code || 404 !== this.errorObj.code) {
            console.error('Oops! Error found:\n', error)
        }

        return this
    }

    renderView(component) {
        this.initEvents()

        // Check if the page is still loading.
        if (!this.isLoaded) {
            return <Spinner isChild={this.spinnerIsChild} />
        }

        const ErrorComponent = this.errorObj.isPage
            ? PageComponentError
            : PageModuleError

        // If requests loaded,
        // Check if the page is found else, display the 404 message
        component = this.isFound ? (
            component
        ) : 404 === this.errorObj.code ? (
            <NotFoundPage />
        ) : (
            <ErrorComponent
                code={this.errorObj.code}
                message={this.errorObj.message}
            />
        )

        if (!this.isFound) {
            hideAlertLoader()
        }

        return component
    }
}

export default ThreadComponent
