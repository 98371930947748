import { makeAutoObservable } from 'mobx'
import { VendorListResponse, VendorService } from './api/VendorService'

export default class VendorListViewModel {
    private vendorService: VendorService
    private _vendorList: VendorListResponse[] = []
    private _vendor: any = {}
    private _isLoaded = false
    public _findOne = false
    public _params = ''

    get vendorList(): VendorListResponse[] {
        return this._vendorList
    }

    get vendor(): VendorListResponse {
        return this._vendor
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    setIsLoaded(isLoaded: boolean): VendorListViewModel {
        this._isLoaded = isLoaded
        return this
    }

    setParams(params: string): VendorListViewModel {
        this._params = params
        return this
    }

    setOne(findOne: boolean): VendorListViewModel {
        this._findOne = findOne
        return this
    }

    setVendor(vendor: VendorListResponse): VendorListViewModel {
        this._vendor = vendor
        return this
    }

    setVendorList(vendorList: VendorListResponse[]): VendorListViewModel {
        this._vendorList = vendorList
        return this
    }

    reset(): VendorListViewModel {
        this._findOne = false
        this._params = ''
        this._vendorList = []
        this._vendor = {}
        this._isLoaded = false
        return this
    }

    constructor(vendorService: VendorService) {
        this.vendorService = vendorService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        this.fetchVendors()
    }

    fetchVendors(page = 1, query = ''): void {
        // Set loading state
        this.setIsLoaded(false)

        this.vendorService
            .getVendors(query)
            .then((response: any) => {
                this.setIsLoaded(true)

                if (response) {
                    if (this._findOne) {
                        // Set vendor if searched by id
                        this.setVendor(response[0])
                    } else {
                        this.setVendorList(response)
                    }
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }
}
