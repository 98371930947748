import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useSession } from '../../../context/session'
import { useHasAccess } from '../../../context/security'
import Spinner from '../../help/Spinner'

interface IProps {
    children: ReactNode
    attributeNo?: number
    attributeType?: number
}

const RouteGuard = ({ children, attributeNo, attributeType }: IProps) => {
    const state = useSession()
    const hasAccess = useHasAccess(attributeNo, attributeType)
    if (state.isAdmin === null) {
        return <Spinner />
    }
    if (attributeNo !== undefined && attributeType !== undefined) {
        return hasAccess ? <>{children}</> : <Navigate to="/" />
    }

    return state.isAdmin === false ? <Navigate to="/" /> : <>{children}</>
}

RouteGuard.displayName = 'RouteGuard'

export default RouteGuard
