import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { WithRouter } from 'helpers/Router'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../../components/Header'
import { Breadcrumb, Button, Container, Spinner } from 'react-bootstrap'
import URI from 'defaults/RoutesDefault'
import { compareStr } from 'helpers/String'
import {
    deleteImage,
    fetchCompanyWarehouseAddress,
    fetchStockItems,
    fetchTransactions,
    fetchWarehouse,
    updateStockItem,
    uploadImage,
} from '../StockItemsService'
import { displayAlert, getErrorMessage } from 'utilities/Response'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { StockItemsEditItem } from './item/StockItemsEditItem'
import { StockItemsEditStatusAdjustment } from './statusAdjustment/StockItemsEditStatusAdjustment'
import { TWarehouseQty } from 'lib/api/types/WarehouseQty'

type TTab = 'item' | 'adjustment'
type TParams = {
    id: number
}

export const StockItemEdit: FC<{
    params: TParams
    navigate: NavigateFunction
}> = ({ params, navigate }) => {
    const [tab, setTab] = useState<TTab>('item')
    const [warehouse, setWarehouse] = useState<TWarehouseQty[]>()
    const [companyWarehouse, setCompanyWarehouse] = useState<
        string | undefined
    >()
    const [transactions, setTransactions] = useState<any>()

    const [isLoading, setLoading] = useState(false)
    const image = useRef<File>()
    const originalData = useRef<Map<string, any>>(new Map())
    const data = useRef<Map<string, any>>(new Map())

    const handleTabChange = (tab: TTab) => {
        setTab(tab)
    }

    const fetchData = useCallback(async () => {
        setLoading(true)
        const [stockItem] = await fetchStockItems(`?$filter=id eq ${params.id}`)
        const warehouse = await fetchWarehouse(stockItem.stockno)
        const transactions = await fetchTransactions(stockItem.stockno)
        const companyWarehouse = await fetchCompanyWarehouseAddress()

        originalData.current = new Map(Object.entries(stockItem))
        data.current = new Map(Object.entries(stockItem))
        setWarehouse(warehouse ?? [])
        setTransactions(transactions)
        setCompanyWarehouse(companyWarehouse)

        setLoading(false)
    }, [params.id])
    const handleDataUpdate = (newData: Map<string, any>) => {
        newData.forEach((value, key) => {
            data.current.set(key, value)
        })
    }
    const handleSave = async () => {
        try {
            const data = await handleImageIfNeeded()
            await updateStockItem(params.id, Object.fromEntries(data.entries()))

            displayAlert('success', 'Stock Item has been successfully updated')

            navigate(URI.stockItem.list)
        } catch (error: any) {
            handleError(error)
        }
    }
    const handleImageIfNeeded = async () => {
        const newData = new Map(data.current)

        if (image.current) {
            const uploadedImage: any = await uploadImage(image.current)
            newData.set('primaryImageId', uploadedImage.id)
        } else if (originalData.current.get('primaryImageId') && !image) {
            deleteImage(originalData.current.get('primaryImageId'))
            newData.set('primaryImageId', null)
        }

        return newData
    }
    const handleError = (error: any) => {
        const message = error.backedError?.response
            ? error.backedError.response.data.userError
            : getErrorMessage(error)
        displayAlert('danger', message)
    }
    const handleImageUpdate = (newImage: File) => {
        image.current = newImage
    }
    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <StockItemsEditHeader
                tab={tab}
                onTab={handleTabChange}
                onSave={handleSave}
                stockNo={data.current.get('stockno')}
            />
            <div className="content-padding min-height w-100">
                {isLoading ? (
                    <div
                        className="w-100 justify-content-center align-items-center align-content-center text-center"
                        style={{ height: '300px' }}
                    >
                        <Spinner className="align-self-center" />
                    </div>
                ) : (
                    <Container fluid className="px-0 stock-item">
                        {tab == 'item' ? (
                            <StockItemsEditItem
                                data={data.current}
                                onDataUpdate={handleDataUpdate}
                                onImageUpdate={handleImageUpdate}
                                image={image.current}
                            />
                        ) : (
                            <StockItemsEditStatusAdjustment
                                _data={data.current}
                                _warehouse={warehouse ?? []}
                                _transactions={transactions}
                                companyWarehouse={companyWarehouse}
                            />
                        )}
                    </Container>
                )}
            </div>
        </>
    )
}
StockItemEdit.displayName = 'StockItemEdit'

export default WithRouter(StockItemEdit)

const StockItemsEditHeader: FC<{
    tab: TTab
    onTab: (tab: TTab) => void
    onSave: () => void
    stockNo: string
}> = ({ tab, onTab, onSave, stockNo }) => {
    return (
        <HeaderLight>
            <HeaderLight.Breadcumbs>
                <NavLink
                    to="/stock-items"
                    className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                >
                    <i className="ri-arrow-left-s-line"></i> Back
                </NavLink>
                <Breadcrumb className="ms-4">
                    <Breadcrumb.Item
                        linkProps={{ to: URI.stockItem.base }}
                        linkAs={Link}
                    >
                        Stock Items
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {`Inventory Stock Item No. ${stockNo ?? ''}`}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </HeaderLight.Breadcumbs>
            <HeaderLight.Content actions={true}>
                <HeaderLight.Title>
                    {`Inventory Stock Item No. ${stockNo ?? ''}`}
                </HeaderLight.Title>
                <Button variant="primary" onClick={onSave}>
                    Save
                </Button>
            </HeaderLight.Content>
            <HeaderLight.Actions className="text-charcoal">
                <li>
                    <Button
                        as="a"
                        href=""
                        className={`${compareStr(tab, 'item', 'active', '')}`}
                        onClick={(e) => {
                            e.preventDefault()

                            onTab('item')
                        }}
                    >
                        Item
                    </Button>
                </li>
                <li>
                    <Button
                        as="a"
                        href=""
                        className={`${compareStr(tab, 'adjustment', 'active')}`}
                        onClick={(e) => {
                            e.preventDefault()

                            onTab('adjustment')
                        }}
                    >
                        Status/Adjustment
                    </Button>
                </li>
            </HeaderLight.Actions>
        </HeaderLight>
    )
}
StockItemsEditHeader.displayName = 'StockItemsEditHeader'
