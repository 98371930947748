import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { WithRouter } from '../../../../helpers/Router'
import { getStatusColor } from '../../../../helpers/Util'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import { displayAlert } from '../../../../utilities/Response'
import ThreadComponent from '../../ThreadComponent'
import { truncateCharCount } from '../../../../helpers/String'
import { currencyFormat } from '../../../../helpers/Number'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import React from 'react'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import { SearchExpressionButton } from '../../uikit/SearchExpressionButton'
import { tableSearch } from '../../../../utilities/modules/TableSearch'
import {
    tableSortingEnableSort,
    tableSortingSortData,
} from '../../../../utilities/modules/TableSorting'
import { Event } from '../../../../utilities/DOM'
import Select from 'react-select'
import FilterSort from '../../../../utilities/modules/FilterSort'
import loadItemImages, { renderItemImage } from '../../../../utilities/Images'
import DisabledCheckBoxWithTooltip from './DisabledCheckboxWithTooltip'

class ProjectProposalAddProposalAddItemModal extends ThreadComponent {
    constructor(props) {
        super(props)
        this.projId = this.props.params.id
        this.fs = new FilterSort(
            'project_proposal_add_item_modal_' + this.projId
        )
        this.state = {
            data: [],
            statusCodes: this.props.statusCodes || [],
            processedData: [],
            show: false,
            checks: [],
            page: 1,
            pageSize: { value: 10, label: '10' },
            images: [],
            imagesLoaded: false,
            imageData: {},
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.availablePageSize = [
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '75', label: '75' },
            { value: '100', label: '100' },
        ]
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )

        this.searchHandler = debounce(this.handleDoSearch.bind(this, 1), 200)
    }

    async componentDidMount() {
        window.isMounted = true
        const { data, show } = this.props

        this.setState(
            {
                show,
                data,
                initialData: data,
                checks: [],
            },
            () => this.paginateSearchSortData()
        )
        this.enableSearchAndSortTable()
    }

    async componentDidUpdate(previousProps) {
        if (
            previousProps.data !== this.props.data ||
            previousProps.show !== this.props.show
        ) {
            const { data, show } = this.props

            this.setState(
                {
                    show,
                    checks: [],
                    data,
                    initialData: data,
                    imagesLoaded: false,
                },
                () => {
                    this.enableSortTable()
                    this.paginateSearchSortData()
                    this.loadThumbnails(data)
                }
            )
        }
    }

    componentWillUnmount() {
        window.isMounted = false
    }

    paginateSearchSortData = () =>
        tableSearch({
            data: this.state.initialData,
            searchProps: this.state.searchProperties,
            callback: async (data) => {
                await this.paginateLocalData(data)
            },
        })

    paginateLocalData = async (data) => {
        let dataToUse = data || []

        const hasSearchValue = Object.values(this.state.searchProperties).find(
            (prop) => prop.value
        )

        // We check if there´s a sort value saved in local storage. This is for page refreshes
        const savedSort = this.fs.getSort()

        if (
            !hasSearchValue &&
            (this.state.sortProperty || savedSort) &&
            data.length
        ) {
            const [field, order] = !this.state.sortProperty
                ? savedSort.split(' ')
                : this.state.sortProperty.split(' ')
            dataToUse = tableSortingSortData({
                key: field,
                dir: order,
                data,
            }).slice(
                (this.state.page - 1) * this.state.pageSize.value,
                this.state.page * this.state.pageSize.value
            )
        }

        this.setState(
            {
                data: dataToUse,
                imagesLoaded: false,
                processedData: data ?? [],
            },
            () => this.loadThumbnails(dataToUse)
        )
    }

    async loadThumbnails(data) {
        if (!this.state.imagesLoaded)
            await loadItemImages({
                data,
                property: 'primaryImageId',
                callback: (imageData) => {
                    this.props.loadImage(imageData)
                    this.setState({
                        imagesLoaded: true,
                        imageData,
                    })
                },
            })
    }

    handleDoSearch = () => {
        // reset the current page to 1 when searching
        this.setState(
            {
                page: 1,
            },
            () => {
                tableSearch({
                    data: this.state.initialData,
                    searchProps: this.state.searchProperties,
                    callback: async (data) => {
                        await this.paginateLocalData(data)
                    },
                })
            }
        )
    }

    handleSelectAllChecks = (e) => {
        const isChecked = e.target.checked

        const newChecks = isChecked
            ? [
                  ...this.state.checks,
                  ...this.state.data
                      .map((item) =>
                          isChecked &&
                          (item.isSelectable || item.isSelectable === undefined)
                              ? item.id
                              : null
                      )
                      .filter((id) => id !== null),
              ]
            : this.state.checks.filter(
                  (checkedId) =>
                      !this.state.data.find((item) => item.id === checkedId)
              )

        this.setState({
            checks: newChecks,
        })
    }

    handleChecks = (id) => {
        const checks = [...this.state.checks]
        const index = checks.findIndex((_id) => _id === id)

        index > -1 ? checks.splice(index, 1) : checks.push(id)

        this.setState({
            checks,
        })
    }

    addItemClick = (e) => {
        let ids = getLocalStorage('addProposalSelected', true) || []
        let selectedIds = []
        setLocalStorage('isDraft', 'true')

        /* eslint-disable no-unused-vars */
        for (const [key, value] of Object.entries(this.state.checks)) {
            if (value) {
                ids.push(parseInt(value))
                selectedIds.push(parseInt(key))
            }
        }
        /* eslint-enable no-unused-vars */

        if (!selectedIds.length) {
            displayAlert('danger', 'Please select at least one item', 2000)
        } else {
            if (this.props.addItemCall) {
                this.props.addItemCall(ids)
            }
        }
    }

    formatDesc(item) {
        const itemName = item.itemName || ''
        const itemDesc = item.desc || ''

        let result = ''

        if (itemName && itemDesc) {
            const truncatedDesc = truncateCharCount(itemDesc, 100)
            result = truncatedDesc.includes(itemName)
                ? truncatedDesc
                : `${itemName} ${truncatedDesc}`
        } else {
            result = itemName || itemDesc || ''
        }

        return result
    }

    enableSortTable = () =>
        tableSortingEnableSort({
            targetTable: '.a-table-heading-modal-no-border',
            data: this.state.initialData,
            classRef: this,
            callback: (data, sort) => {
                tableSearch({
                    data,
                    searchProps: this.state.searchProperties,
                    callback: async (data) => {
                        this.setState({ sortProperty: sort }, () => {
                            // We need to save the sort async because for some reason it doesn´t work otherwise
                            setTimeout(() => this.fs.setSort(sort))
                            this.paginateLocalData(data)
                        })
                    },
                })
            },
        })

    handleShowTableSearch = (e) => {
        if (!this.props.data.length) {
            return
        }

        this.fs.setIsActiveSearch(!this.state.showTableSearch)
        this.setState(
            {
                showTableSearch: !this.state.showTableSearch,
            },
            () => {
                if (!this.state.showTableSearch) {
                    this.fs.setSearches({})
                    Event('.a-table-search-fields .form-control').value('')
                    this.setState(
                        {
                            searchProperties: {},
                        },
                        this.paginateSearchSortData
                    )
                }
            }
        )
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']?.value ?? 'string'
        const exp = e.target.attributes['placeholder']?.value ?? '>='

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value, type, exp },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.searchHandler()
            }
        )
    }

    handleShowAll = async (e) => {
        this.props.showAllItems(e.target.checked)
    }

    handleChangePage = async (page) => {
        this.setState(
            {
                page,
            },
            this.paginateSearchSortData
        )
    }

    handleFilter = (name) => (e) => {
        this.setState(
            {
                [name]: e,
                page: 1,
            },
            this.changePageHandler
        )
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    isNextDisabled() {
        const { processedData, page, pageSize } = this.state
        const total = page * pageSize.value

        return Number(processedData?.length) <= total
    }

    renderPagination() {
        return (
            <div className={'d-flex flex-wrap justify-content-center'}>
                <div
                    className={'d-flex flex-row align-items-center pagination'}
                >
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={this.state.page === 1}
                        onClick={() =>
                            this.handleChangePage(this.state.page - 1)
                        }
                    >
                        <i className="ri-arrow-left-s-line"></i> Previous
                    </Button>
                    <span className={'pagination-span'}>{this.state.page}</span>
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={this.isNextDisabled()}
                        onClick={() =>
                            this.handleChangePage(this.state.page + 1)
                        }
                    >
                        Next <i className="ri-arrow-right-s-line ms-1"></i>
                    </Button>
                </div>
                <Select
                    onChange={this.handleFilter('pageSize')}
                    key={`${Math.floor(Math.random() * 1000)}-min`}
                    options={this.availablePageSize}
                    defaultValue={this.state.pageSize}
                    className="react-select pagination-select mx-3"
                    placeholder="Please select"
                />
            </div>
        )
    }

    render() {
        const { checks, imagesLoaded, imageData, statusCodes } = this.state

        const itemsOnPage = this.state.data
            .filter(
                (item) => item.isSelectable || item.isSelectable === undefined
            )
            .map((item) => item.id)

        const selectedItemsOnPage = itemsOnPage.filter((item) =>
            checks.includes(item)
        )

        return (
            <Modal
                size="xxl"
                show={this.state.show}
                onHide={this.props.hideModal.bind(null)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="right a-modal modal-quarter fixed-footer"
                backdrop="static"
            >
                <Modal.Header className="bg-ivory py-4 px-5">
                    <Row className="w-100">
                        <Col className="text-start">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`d-inline-block ms-3 mt-2 btn-icon btn-action fw-bold ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <Form.Check
                                inline
                                label="Show All"
                                name="group-check-show-all"
                                type="checkbox"
                                id={`group-check-show-all`}
                                className="ms-3 align-middle pt-2"
                                checked={this.props.showAllAvailableItems}
                                onChange={this.handleShowAll}
                            />
                        </Col>
                        <Col className="text-end">
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal.bind(null)}
                                className="d-inline-block w-150px"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={this.addItemClick.bind(null)}
                                className="d-inline-block w-150px ms-3"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body className="bg-ivory">
                    <Row className="w-100 px-5">
                        <Col sm={12}>
                            <div className="table-gradient mb-5 sticky-container">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr
                                            key="0"
                                            className="a-table-heading-modal-no-border"
                                        >
                                            <th className="ps-4">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`group-all`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-radio-th-0-modal`}
                                                    checked={
                                                        !!selectedItemsOnPage.length
                                                    }
                                                    className={`${
                                                        selectedItemsOnPage.length <
                                                        itemsOnPage.length
                                                            ? 'line'
                                                            : ''
                                                    }`}
                                                    onChange={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'latestprpno'
                                                    )}
                                                    data-field="latestprpno"
                                                >
                                                    Already Proposed
                                                </span>
                                            </th>
                                            <th>Image</th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'locationName'
                                                    )}
                                                    data-field="locationName"
                                                >
                                                    Location Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'item'
                                                    )}
                                                    data-field="item"
                                                >
                                                    Ref. No.
                                                </span>
                                            </th>
                                            <th className="mw-280px">
                                                <span
                                                    className={this.sortClass(
                                                        'desc'
                                                    )}
                                                    data-field="desc"
                                                >
                                                    Description
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'proposalPrice'
                                                    )}
                                                    data-field="proposalPrice"
                                                >
                                                    Proposal Price
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'statusName'
                                                    )}
                                                    data-field="statusName"
                                                >
                                                    Status
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estmprice'
                                                    )}
                                                    data-field="estmprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewm
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estdprice'
                                                    )}
                                                    data-field="estdprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewd
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estfprice'
                                                    )}
                                                    data-field="estfprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewf
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estiprice'
                                                    )}
                                                    data-field="estiprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewi
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estlprice'
                                                    )}
                                                    data-field="estlprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewl
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estoprice'
                                                    )}
                                                    data-field="estoprice"
                                                >
                                                    {
                                                        this.props.company
                                                            .compviewo
                                                    }
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'estdep'
                                                    )}
                                                    data-field="estdep"
                                                >
                                                    Requested Deposit
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'quantity'
                                                    )}
                                                    data-field="quantity"
                                                >
                                                    Quantity
                                                </span>
                                            </th>
                                            <th className="mw-100px">
                                                <span
                                                    className={this.sortClass(
                                                        'propsTax'
                                                    )}
                                                    data-field="propsTax"
                                                >
                                                    Sales Tax
                                                </span>
                                            </th>
                                        </tr>
                                        <tr
                                            className={`a-table-search-fields ${
                                                this.state.showTableSearch
                                                    ? ''
                                                    : 'd-none'
                                            }`}
                                        >
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th className="mw-120px">
                                                <Form.Control
                                                    type="text"
                                                    data-field="locationName"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'locationName'
                                                    )}
                                                />
                                            </th>
                                            <th className="mw-120px">
                                                <Form.Control
                                                    type="text"
                                                    data-field="item"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'item'
                                                    )}
                                                />
                                            </th>
                                            <th className="mw-100px">
                                                <Form.Control
                                                    type="text"
                                                    data-field="desc"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'desc'
                                                    )}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="proposalPrice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'proposalPrice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'proposalPrice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'proposalPrice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'proposalPrice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="mw-100px">
                                                <Form.Control
                                                    type="text"
                                                    data-field="statusName"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'statusName'
                                                    )}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estmprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estmprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estmprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estmprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estmprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estdprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estdprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estdprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estdprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estdprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estfprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estfprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estfprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estfprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estfprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estlprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estlprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estlprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estlprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estlprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estiprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estiprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estiprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estiprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estiprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estoprice"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estoprice'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estoprice'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estoprice'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estoprice',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="estdep"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'estdep'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'estdep'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'estdep'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'estdep',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="quantity"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'quantity'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'quantity'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'quantity'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'quantity',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                            <th className="position-relative">
                                                <Form.Control
                                                    type="number"
                                                    data-field="propsTax"
                                                    data-type="number"
                                                    onChange={this.handleSearch}
                                                    placeholder={this.fs.getSearchExpression(
                                                        'propsTax'
                                                    )}
                                                    defaultValue={this.fs.getValue(
                                                        'propsTax'
                                                    )}
                                                />
                                                <SearchExpressionButton
                                                    operator={this.fs.getSearchExpression(
                                                        'propsTax'
                                                    )}
                                                    callback={(exp) => {
                                                        this.fs.setSearchExperation(
                                                            'propsTax',
                                                            exp,
                                                            this
                                                        )
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(() => {
                                            if (this.props.dataIsLoaded) {
                                                return (
                                                    this.state.data || []
                                                ).map((item, i) => (
                                                    <tr
                                                        key={i}
                                                        className="tr-loading-1"
                                                    >
                                                        <td className="ps-4">
                                                            {item.isSelectable ===
                                                            false ? (
                                                                <DisabledCheckBoxWithTooltip tooltipText="This item is already on a proposal and cannot be added to a new proposal" />
                                                            ) : (
                                                                <Form.Check
                                                                    inline
                                                                    label=""
                                                                    name={
                                                                        `group-` +
                                                                        i
                                                                    }
                                                                    type="checkbox"
                                                                    data-id={
                                                                        item.id
                                                                    }
                                                                    data-index={
                                                                        i
                                                                    }
                                                                    data-item={
                                                                        item.item
                                                                    }
                                                                    id={
                                                                        `chk-projectview-proposal-modal-` +
                                                                        i
                                                                    }
                                                                    checked={checks.includes(
                                                                        item.id
                                                                    )}
                                                                    className="chk-projectview-proposal-modal-item"
                                                                    onChange={() =>
                                                                        this.handleChecks(
                                                                            item.id
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.latestprpno}
                                                            {!item.latestprpdt
                                                                ? ''
                                                                : `\n${dayjs(
                                                                      item.latestprpdt
                                                                  ).format(
                                                                      'MM/DD/YYYY'
                                                                  )}`}
                                                        </td>
                                                        <td className="td-1">
                                                            {renderItemImage({
                                                                item,
                                                                className:
                                                                    'img-thumbnail p-0',
                                                                imagesLoaded,
                                                                imageData,
                                                                property:
                                                                    'primaryImageId',
                                                                altProperty:
                                                                    'itemName',
                                                            })}
                                                        </td>
                                                        <td>
                                                            {item.locationName}
                                                        </td>
                                                        <td>{item.item}</td>
                                                        <td>
                                                            {this.formatDesc(
                                                                item
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.proposalPrice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            <strong
                                                                className={`dot`}
                                                                style={{
                                                                    '--color':
                                                                        getStatusColor(
                                                                            item.statusName,
                                                                            statusCodes
                                                                        ),
                                                                }}
                                                            >
                                                                {
                                                                    item.statusName
                                                                }
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estmprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estdprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estfprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estlprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estiprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.estoprice,
                                                                '$'
                                                            )}
                                                        </td>
                                                        <td>{item.estdep}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>
                                                            {currencyFormat(
                                                                item.propsTax,
                                                                '$'
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            } else {
                                                return showLoading()
                                            }
                                        })()}
                                        {this.state.data.length === 0 &&
                                        this.props.dataIsLoaded ? (
                                            showEmpty(16)
                                        ) : (
                                            <></>
                                        )}
                                    </tbody>
                                </Table>
                                {this.renderPagination()}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default WithRouter(ProjectProposalAddProposalAddItemModal)
