import React from 'react'
import cn from 'classnames'
import { TSortDirection } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryTypes'

type TCheckbookHistoryTHSpanProps = {
    children: React.ReactNode
    field: string
    sortDirection: TSortDirection
    sortField: string
    onSpanClick: (field: string) => void
}

export const CheckbookHistoryTHSpan = ({
    children,
    field,
    sortDirection,
    sortField,
    onSpanClick,
}: TCheckbookHistoryTHSpanProps) => {
    const classes = cn('sort', {
        active: sortField === field,
        asc: sortDirection === 'asc',
        desc: sortDirection === 'desc',
    })
    return (
        <span className={classes} onClick={() => onSpanClick(field)}>
            {children}
        </span>
    )
}

CheckbookHistoryTHSpan.displayName = 'CheckbookHistoryTHSpan'
