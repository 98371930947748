import React, { ReactNode } from 'react'

interface IBaseGrid {
    children: ReactNode
    gridClass: string
}

const BaseGrid = ({ gridClass, children }: IBaseGrid) => (
    <div className={gridClass}>{children}</div>
)

BaseGrid.displayName = 'BaseGrid'

export default BaseGrid
