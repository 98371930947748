import { Button, Col, Modal, Row } from 'react-bootstrap'
import ThreadComponent from '../../../app/ThreadComponent'
import ProjectProposalHistory from '../project/proposal/ProjectProposalHistory'

export default class ProposalHistoryModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }
    }

    componentDidMount() {
        const { show } = this.props

        this.setState({
            show,
        })
    }

    componentDidUpdate(previousProps, previousState) {
        const { show } = this.props
        if (show !== previousProps.show) {
            this.setState({
                show,
            })
        }
    }

    render = () => (
        <Modal
            size="xxl"
            show={this.state.show}
            onHide={() => this.props.hideModal.bind(null)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="right a-modal modal-quarter fixed-footer"
            backdrop="static"
        >
            <Modal.Header className="bg-ivory py-4 px-0">
                <Row className="w-100">
                    <Col className="text-end">
                        <Button
                            variant="trans-light border-secondary-ash"
                            onClick={this.props.hideModal.bind(null)}
                            className="d-inline-block w-150px"
                        >
                            Close
                        </Button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body className="bg-ivory">
                <ProjectProposalHistory isModal id={this.props.id} />
            </Modal.Body>
        </Modal>
    )
}
