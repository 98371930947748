import React from 'react'
import {
    Col,
    Row,
    Form,
    Table,
    Breadcrumb,
    Container,
    Button,
} from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import Select from 'react-select'
import URI from '../../../../defaults/RoutesDefault'
import { routeParam, WithRouter } from '../../../../helpers/Router'
import { ActionBarVertical } from '../../../components/ActionBar'
import { FooterFormAction } from '../../../components/Form'
import { HeaderLight } from '../../../components/Header'
import { fakeData } from '../../../factory/fakeFactory'
import ThreadComponent from '../../ThreadComponent'

class ItemTransfer extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: fakeData(10),
            dataIsLoaded: false,
            selectData: [
                { value: 'option-1', label: 'Option 1' },
                { value: 'option-2', label: 'Option 2' },
                { value: 'option-3', label: 'Option 3' },
            ],
        }
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.project.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    {(() => {
                        if (this.props.params.compId) {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.data.proj}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Transfer Item
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        } else {
                            return (
                                <Breadcrumb className="ms-4">
                                    <Breadcrumb.Item
                                        linkProps={{ to: '/projects' }}
                                        linkAs={Link}
                                    >
                                        Projects
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        {this.state.data.proj}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkProps={{
                                            to: getRouteWithParam(
                                                URI.project.viewPage,
                                                {
                                                    id: this.props.params.id,
                                                    page: 'items',
                                                }
                                            ),
                                        }}
                                        linkAs={Link}
                                    >
                                        Items
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Transfer Item
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        }
                    })()}
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>Transfer Item</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderVerticalActionBar() {
        return (
            <ActionBarVertical>
                <ActionBarVertical.Links>
                    <li>
                        <NavLink
                            to={URI.project.item.transferItemEdit}
                            className="d-flex align-items-center"
                        >
                            <i className="ri-edit-fill mx-0"></i>
                        </NavLink>
                    </li>
                </ActionBarVertical.Links>
            </ActionBarVertical>
        )
    }

    render() {
        return (
            <>
                {/* Header */}
                {this.renderHeader()}

                {/* Content */}
                <div className="content-padding min-height">
                    <Container fluid>
                        <Row className="mb-4">
                            <Col lg={10} className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={2}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Source Project
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col
                                                lg={3}
                                                className="mb-2 mb-lg-0"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="CAR04"
                                                />
                                            </Col>
                                            <Col className="mb-2 mb-lg-0">
                                                <Row xs={1} lg={2}>
                                                    <Col className="mb-2 mb-lg-0">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Carter’s Office – NJ"
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Eric and Joanne Carter"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={2}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Source Item No.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg={3}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="0002"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12">
                                <div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
                                    <Row xs={1} lg={2} className="py-4">
                                        <Col>
                                            <h6 className="fw-bold mb-0">
                                                Transfer To
                                            </h6>
                                        </Col>
                                    </Row>
                                </div>

                                <Row xs={1} lg={2} className="mt-2 mb-4">
                                    <Col>
                                        <Row className="align-items-center">
                                            <Col>
                                                <Form.Check
                                                    inline
                                                    defaultChecked={true}
                                                    label="To Another Project"
                                                    name="grouprd-1"
                                                    type="radio"
                                                    id={`inline-radio-1`}
                                                    value={1}
                                                    className="me-3"
                                                />
                                                <Form.Check
                                                    inline
                                                    defaultChecked={false}
                                                    label="To Stock"
                                                    name="grouprd-1"
                                                    type="radio"
                                                    id={`inline-radio-2`}
                                                    value={1}
                                                    className="me-3"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col lg={10} className="mb-3 mb-lg-0">
                                        <Row className="align-items-center mb-3">
                                            <Col
                                                lg={2}
                                                className="text-lg-end mb-2 mb-lg-0"
                                            >
                                                <Form.Label className="mb-0">
                                                    Destination Project
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col
                                                        lg={4}
                                                        className="mb-2 mb-lg-0"
                                                    >
                                                        <Select
                                                            options={
                                                                this.state
                                                                    .selectData
                                                            }
                                                            className="react-select"
                                                            placeholder="Please select"
                                                        />
                                                    </Col>
                                                    <Col lg={5}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Please enter"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col lg={10} className="mb-3 mb-lg-0">
                                        <Row className="align-items-center mb-3">
                                            <Col>
                                                <Row className="align-items-center">
                                                    <Col
                                                        lg={5}
                                                        className="mb-2 mb-lg-0"
                                                    >
                                                        <Form.Check
                                                            inline
                                                            defaultChecked={
                                                                true
                                                            }
                                                            label="As a New Item"
                                                            name="grouprd-2"
                                                            type="radio"
                                                            id={`inline-radio-3`}
                                                            value={1}
                                                            className="me-3"
                                                        />
                                                        <Form.Check
                                                            inline
                                                            defaultChecked={
                                                                false
                                                            }
                                                            label="As a Component of Item #"
                                                            name="grouprd-2"
                                                            type="radio"
                                                            id={`inline-radio-4`}
                                                            value={1}
                                                        />
                                                    </Col>
                                                    <Col
                                                        lg={5}
                                                        className="ps-lg-0 pe-lg-5"
                                                    >
                                                        <Select
                                                            options={
                                                                this.state
                                                                    .selectData
                                                            }
                                                            className="react-select"
                                                            placeholder="Please select"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <div className="mb-4">
                            <Row>
                                <Col
                                    sm={12}
                                    className="position-relative table-action-bar"
                                >
                                    <div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
                                        <Row xs={1} lg={2} className="py-4">
                                            <Col>
                                                <h6 className="fw-bold mb-0">
                                                    Components To Transfer
                                                </h6>
                                            </Col>
                                        </Row>
                                    </div>

                                    {this.renderVerticalActionBar()}

                                    <Table
                                        striped
                                        responsive
                                        className="a-table table-check"
                                    >
                                        <thead>
                                            <tr className="a-table-heading">
                                                <th className="ps-4">#</th>
                                                <th className="col-4">
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Description
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Type
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Paid Qty.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Act. Cost
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Transfer Qty
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="excerpt"
                                                    >
                                                        Transfer Cost
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((item, i) => (
                                                <tr key={i} data-key={i}>
                                                    <td className="ps-4">
                                                        001
                                                    </td>
                                                    <td>
                                                        Lorem ipsum dolor sit
                                                        amet, consetetur
                                                        sadipscing elitr, sed
                                                        diam nonumy eirmod
                                                        tempor invidunt ut
                                                        labore et dolore magna.
                                                    </td>
                                                    <td>Merchandise</td>
                                                    <td>1</td>
                                                    <td>700.00</td>
                                                    <td>1</td>
                                                    <td>700.00</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ItemTransfer)
