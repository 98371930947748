import { Button } from 'react-bootstrap'
import cn from 'classnames'
import Stack from 'react-bootstrap/Stack'

import SearchButton from './SearchButton'

import { formatMoney } from './utils'

function HeaderBox({ children, className }) {
    const classes = cn(
        'a-box-basis px-4 py-1 d-inline-block mt-2 border-start',
        className
    )

    return <div className={classes}>{children}</div>
}

function HeaderBoxLabel({ children }) {
    return <div className="fs-sm">{children}</div>
}

function HeaderBoxValue({ children }) {
    return <h3 className="ff-type-bold w-100 h-100 pb-3">{children}</h3>
}

function AsyncBoxValue({ query, selector }) {
    const { isLoading, data } = query

    if (isLoading) {
        return (
            <HeaderBoxValue>
                <div className="text-center">
                    <div
                        className="spinner-grow spinner-grow-sm text-primary"
                        role="status"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </HeaderBoxValue>
        )
    }

    return <HeaderBoxValue>{selector(data)}</HeaderBoxValue>
}

export default function ReconcileHeader({
    cleared,
    endingBalance,
    onFinanceChargesClick,
    onMarkCleared,
    onMarkUncleared,
    onSearch,
    searching,
    totalClearedCount,
    totalOutstandingCount,
    totalSelected,
    totalSelectedAmount,
    totalsQuery,
}) {
    const onClearClick = () => {
        if (cleared) {
            onMarkUncleared()
        } else {
            onMarkCleared()
        }
    }

    const totalCleared = ({ totalClearedCharges, totalClearedPayments }) =>
        totalClearedCharges - totalClearedPayments

    const totalClearedSelector = (data) => formatMoney(totalCleared(data))

    const totalOutstanding = (data) => endingBalance - totalCleared(data)

    const totalOutstandingSelector = (data) =>
        formatMoney(totalOutstanding(data))

    return (
        <div className="a-filter bg-ivory border-1 border-sand roundedx-4 row">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row">
                    <HeaderBox className="ps-0 border-start-0">
                        <HeaderBoxLabel>
                            Total Outstanding ({totalOutstandingCount || '?'})
                        </HeaderBoxLabel>
                        <AsyncBoxValue
                            query={totalsQuery}
                            selector={totalOutstandingSelector}
                        />
                    </HeaderBox>
                    <HeaderBox>
                        <HeaderBoxLabel>
                            Total Cleared ({totalClearedCount || '?'})
                        </HeaderBoxLabel>
                        <AsyncBoxValue
                            query={totalsQuery}
                            selector={totalClearedSelector}
                        />
                    </HeaderBox>
                    <HeaderBox>
                        <HeaderBoxLabel>
                            Total Selected ({totalSelected || '0'})
                        </HeaderBoxLabel>
                        <HeaderBoxValue>
                            {formatMoney(totalSelectedAmount)}
                        </HeaderBoxValue>
                    </HeaderBox>
                </div>
                <Stack direction="horizontal" gap={2}>
                    <SearchButton
                        onClick={() => onSearch()}
                        searching={searching}
                    />
                    <Button onClick={onFinanceChargesClick} variant="ivory">
                        Finance Charges
                    </Button>
                    <Button variant="primary" onClick={onClearClick}>
                        {!cleared ? <>Mark Cleared</> : <>Mark Uncleared</>}
                    </Button>
                </Stack>
            </div>
        </div>
    )
}
