import { useLocation, useNavigate, useParams } from 'react-router-dom'

/* This is a higher order component that
 * inject a special prop to our component.
 * Use this for main `Component` class only
 *
 * @return React.Component
 */
function WithRouter(Component) {
    function ComponentWithRouter(props) {
        const params = useParams()
        const location = useLocation()
        const navigate = useNavigate()

        return (
            <Component
                navigate={navigate}
                {...props}
                params={params}
                paths={location}
            />
        )
    }

    return ComponentWithRouter
}

/* This is a higher order component that
 * inject a special prop to our component.
 * Use this for other `Component`
 *
 * @return React.Component
 */
function OnRouter(Component) {
    function ComponentWithRouter(props) {
        const params = useParams()
        const location = useLocation()

        return <Component {...props} params={params} paths={location} />
    }

    return ComponentWithRouter
}

/**
 * Check if path name contains some string.
 *
 * @return {boolean} boolean
 */
function hasPath(path, name) {
    return path.indexOf(name) > -1
}

/**
 * Get URL with parameter.
 *
 * @param {string} pathRegex
 * @param {array} params
 * @returns string
 */
function getRouteWithParam(pathRegex, params) {
    var segments = pathRegex.split('/')

    return segments
        .map((segment) => {
            var offset = segment.indexOf(':') + 1
            if (!offset) return segment

            var key = segment.slice(offset)
            return params[key]
        })
        .join('/')
}

/**
 * Get URL with parameter.
 *
 * @param {string} pathRegex
 * @param {array} params
 * @returns string
 */
function routeParam(pathRegex, params) {
    var segments = pathRegex.split('/')

    return segments
        .map((segment) => {
            var offset = segment.indexOf(':') + 1
            if (!offset) return segment

            var key = segment.slice(offset)
            return params[key]
        })
        .join('/')
}

/**
 * Get active menu uri.
 *
 * @param {*} path The location path name.
 * @param {*} allowedUri List of uri on the page.
 * @param {*} _default Default value if uri not found on allowedUri.
 * @returns
 */
function getActiveMenuUri(path, allowedUri, _default) {
    let uri = path.split('/')
    uri = uri[uri.length - 1] === '' ? uri[uri.length - 2] : uri[uri.length - 1]

    if (allowedUri.indexOf(uri) > -1) {
        return uri
    }

    return _default ? _default : false
}

export {
    WithRouter,
    OnRouter,
    hasPath,
    getRouteWithParam,
    routeParam,
    getActiveMenuUri,
}
