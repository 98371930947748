import { Button, Modal } from 'react-bootstrap'

export default function ConfirmModal({
    children,
    onConfirm,
    onCancel,
    isShowing,
}) {
    return (
        <Modal show={isShowing}>
            <Modal.Header>
                <Modal.Title>Reconcile Credit Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                    Ok
                </Button>
                <Button
                    variant="trans-light border-secondary-ash"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
