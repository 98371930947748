import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Breadcrumb,
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import DatePicker from 'react-datepicker'
import InputBar from '../../../components/InputBar'
import JournalEntryAddDialog from './JournalEntryAddDialog'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    displayAlert,
    displayAlertLoader,
    getErrorMessage,
    hideAlertLoader,
    removeResponseAlert,
} from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import _, { delay, update } from 'lodash'
import MSG from '../../../../defaults/Message'
import { setDraft } from '../../../../utilities/FormEvent'
import { currencyFormat } from '../../../../helpers/Number'

class JournalEntryAdd extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            jeDate: new Date(),
            jeDesc: '',
            jeNum: '',
            data: [],
            autorev: false,
            transactionNumber: '',
            isShowing: false,
            netDebitAndCredit: 0,
            totalChecked: 0,
            checks: [],
            editData: {},
            currentEditIndex: null,
            isSaving: false,
        }
        this.api = new ApiService()
        this.isExistingList = this.props.params.type === 'existing'
    }

    handleDateChange = (date) => {
        this.setState(
            (prevState) => (prevState.jeDate = date ?? ''),
            () => setDraft(true)
        )
    }

    async componentDidMount() {
        await this.getTransactionNumber(false)
    }

    async getTransactionNumber(message = true) {
        let je_trans_num = localStorage.getItem('je_trans_num')

        if (!je_trans_num) {
            const transaction = await this.api.getTransactionCounterNext(
                'txNextGLJE'
            )
            if (transaction) {
                je_trans_num = transaction.newTransactionNumber
                localStorage.setItem(
                    'je_trans_num',
                    transaction.newTransactionNumber
                )

                if (message) {
                    displayAlert('success', MSG.success.create.GLJeTn, {
                        timeout: 12000,
                        className: 'gl-je-fetch-tansno',
                    })
                }

                this.updateExistingData(je_trans_num)
            }
        }

        this.setState({
            transactionNumber: je_trans_num,
        })
    }

    hasDataError() {
        if (!this.state.data || this.state.data.length < 2) {
            displayAlert('danger', MSG.error.GLJe.editReqTwo)

            return true
        }

        if (this.state.netDebitAndCredit !== 0) {
            displayAlert('danger', MSG.error.GLJe.editNotBalanced)

            return true
        }

        return false
    }

    handleSaveJournalEntries = async (e) => {
        e.preventDefault()

        if (!this.state.jeDate) {
            displayAlert('danger', MSG.error.GLJe.editNoDate)
            return
        }

        if (this.hasDataError()) {
            return
        }

        displayAlertLoader(MSG.loading.create.GLJe)
        this.setState({
            isSaving: true,
        })

        let has_error = false
        let isTemporaryJournalExists = false
        const userCode = getCookie('dmUsercode')
        for (let i in this.state.data) {
            const currentData = this.state.data[i]
            await this.api
                .createJournalEntryDetails({
                    usercode: userCode,
                    txnum: currentData.txnum,
                    account: currentData.account,
                    damt: currentData.isDebit ? currentData.amount : 0,
                    camt: currentData.isDebit ? 0 : currentData.amount,
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error)
                    has_error = !error.response.data.isOk
                    displayAlert('danger', errorMessage, {
                        timeout: 5000,
                        className: `journal-details-${i}`,
                    })

                    if (
                        errorMessage.toString().indexOf('already exists') > -1
                    ) {
                        isTemporaryJournalExists = true
                    }
                })
        }

        // #1 If transaction details are successfully created.
        // Otherwise, call #2 below to generate new transation number.
        if (!isTemporaryJournalExists) {
            await this.api
                .createJournalEntry({
                    usercode: userCode,
                    jenum: this.state.jeNum,
                    txnum: this.state.transactionNumber,
                    jedate: this.state.jeDate,
                    txdesc: this.state.jeDesc,
                    fiscalmonth: this.state.jeDate,
                    autorev: this.state.autorev,
                })
                .catch((error) => {
                    has_error = !error.response.data.isOk
                    displayAlert('danger', error.response.data.userError)
                })
        }

        // Remove loader first.
        hideAlertLoader()

        // #2 If transaction exists, Let's generate new transaction number if one exists.
        if (isTemporaryJournalExists) {
            displayAlertLoader(MSG.loading.create.GLJeTn)
            // remove item before creating since it's stored in localStorage.
            localStorage.removeItem('je_trans_num')
            // generate new key.
            try {
                await this.getTransactionNumber()
            } catch (error) {
                // Ignore error.
            }
            displayAlert('info', MSG.try.GLJe, -1)
        }

        if (!has_error) {
            displayAlert('success', MSG.success.create.GLJe)
            this.disableForm()
            setDraft(false)

            if (e.target.textContent === 'Save & New') {
                delay(() => {
                    window.location.reload(false)
                }, 800)
            } else {
                delay(() => {
                    this.props.navigate(URI.generalLedger.journalEntry.listNew)
                }, 800)
            }

            localStorage.removeItem('je_trans_num')
        } else {
            this.setState({
                isSaving: false,
            })
        }
    }

    handleModalData = (entry) => {
        entry.txnum = this.state.transactionNumber
        this.setState((prevState) => ({
            data: [...prevState.data, entry],
        }))

        setDraft(true)
        this.setState({
            netDebitAndCredit: entry.balance,
        })
    }

    handleSelectAllChecks = () =>
        this.setState({
            checks:
                this.state.checks.length !== this.state.data.length
                    ? this.state.data.map((item, i) => {
                          return i
                      })
                    : [],
        })

    handleCheck = (id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === id)
        index > -1 ? checks.splice(index, 1) : checks.push(id)

        this.setState({
            checks,
        })
    }

    handleDelete = (e) => {
        e.preventDefault()

        const newData = this.state.data.filter(
            (val, idx) => !this.state.checks.includes(idx)
        )

        // calculate current net and debits
        let newNetDebAndCred = 0
        if (newData.length) {
            newData.map((obj) => {
                if (obj.isDebit) {
                    newNetDebAndCred += obj.amount
                } else {
                    newNetDebAndCred -= obj.amount
                }
            })
        }

        newNetDebAndCred === 0 ? Math.abs(newNetDebAndCred) : newNetDebAndCred

        this.setState({
            data: newData,
            netDebitAndCredit: newNetDebAndCred,
            checks: [],
        })
    }

    handleUpdateData = (updatedData) => {
        const newData = _.cloneDeep(this.state.data)
        const prevAmount = newData[this.state.currentEditIndex].amount
        const prevIsDebit = newData[this.state.currentEditIndex].isDebit
        newData[this.state.currentEditIndex] = {
            account: updatedData.account,
            accountName: updatedData.accountName,
            amount: updatedData.amount,
            isDebit: updatedData.isDebit,
            txnum: this.state.transactionNumber,
        }

        let currentNetDebAndCred = prevIsDebit
            ? this.state.netDebitAndCredit - prevAmount
            : this.state.netDebitAndCredit + prevAmount
        let newDebitAndCredit = 0
        if (updatedData.isDebit) {
            newDebitAndCredit = currentNetDebAndCred + updatedData.amount
        } else {
            newDebitAndCredit = currentNetDebAndCred - updatedData.amount
        }

        this.setState({
            data: newData,
            netDebitAndCredit: newDebitAndCredit,
            editData: {},
        })
    }

    updateExistingData(transNumber) {
        const newData = _.cloneDeep(this.state.data)
        if (this.state.data.length) {
            this.state.data.forEach((item, index) => {
                newData[index].txnum = transNumber
            })

            this.setState({
                data: newData,
            })
        }
    }

    handleCancel = () => {
        setDraft(false)
    }

    componentInit() {
        this.title = 'Add Journal Entry'
        this.setFormAction(true)
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={
                            this.isExistingList
                                ? URI.generalLedger.journalEntry.listExisting
                                : URI.generalLedger.journalEntry.listNew
                        }
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.home }}
                            linkAs={Link}
                        >
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: this.isExistingList
                                    ? URI.generalLedger.journalEntry
                                          .listExisting
                                    : URI.generalLedger.journalEntry.listNew,
                            }}
                            linkAs={Link}
                        >
                            Journal Entries
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Add Journal Entry</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>Add Journal Entry</HeaderLight.Title>

                    <div>
                        <Button
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            onClick={this.handleCancel}
                            href={URI.generalLedger.journalEntry.listNew}
                        >
                            Cancel
                        </Button>

                        {/* Submit Button */}
                        <Button
                            onClick={this.handleSaveJournalEntries}
                            variant="primary me-2"
                            disabled={this.state.isSaving}
                        >
                            Save & New
                        </Button>

                        <Button
                            onClick={this.handleSaveJournalEntries}
                            variant="primary"
                            disabled={this.state.isSaving}
                        >
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderInputBar() {
        return (
            <InputBar className="xsmall">
                <InputBar.Links>
                    <InputBar.Link>
                        <Form.Label className="ilabel">
                            Net Debits and Credits
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                type="text"
                                placeholder="0.00"
                                size="sm"
                                value={parseFloat(
                                    this.state.netDebitAndCredit
                                ).toFixed(2)}
                                onChange={(e) =>
                                    this.setState(
                                        {
                                            netDebitAndCredit: e.target.value,
                                        },
                                        () => setDraft(true)
                                    )
                                }
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link style={{ width: '180px' }}>
                        <Form.Check
                            inline
                            label="Auto Reverse"
                            name={`group-inputbar`}
                            type="checkbox"
                            id={`group-inputbar`}
                            onChange={() =>
                                this.setState(
                                    { autorev: !this.state.autorev },
                                    () => setDraft(true)
                                )
                            }
                            defaultChecked={this.state.autorev}
                        />
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderContent() {
        return (
            <Form.Group controlId="formGridText1">
                <Row xs={1} lg={2} className="mb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Entry No.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col className="mb-3 mb-lg-0">
                                        <Form.Control
                                            type="text"
                                            maxLength={5}
                                            data-field="jeNum"
                                            value={this.state.jeNum}
                                            placeholder="Entry No."
                                            onChange={(e) => {
                                                this.setState({
                                                    jeNum: e.target.value,
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <div className="react-select-header">
                                            <DatePicker
                                                selected={this.state.jeDate}
                                                onChange={this.handleDateChange}
                                                showMonthDropdown="true"
                                                showYearDropdown="true"
                                                className="form-control"
                                                placeholderText="Select Entry Date"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Description
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    placeholder="Enter description"
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                jeDesc: e.target.value,
                                            },
                                            () => setDraft(true)
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mb-5 pb-5">
                    <Col
                        sm={12}
                        className="mb-3 mb-lg-0 position-relative table-action-bar"
                    >
                        {/* ---------- section */}
                        <div className="bg-secondary-grey px-4">
                            <Row
                                xs={'auto'}
                                className="py-4 justify-content-end align-items-center"
                            >
                                <Col>
                                    <Row>
                                        <Col>
                                            <Button
                                                to="#"
                                                variant="ivory"
                                                size="sm"
                                                className={`btn-icon btn-action ${
                                                    this.state.checks.length ===
                                                    0
                                                        ? 'disabled'
                                                        : ''
                                                }`}
                                                disabled={
                                                    this.state.checks.length ===
                                                    0
                                                }
                                                onClick={this.handleDelete}
                                            >
                                                <i className="ri-close-line"></i>{' '}
                                                Delete
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className={`btn-icon btn-action`}
                                                onClick={() => {
                                                    this.setState({
                                                        isShowing: true,
                                                    })
                                                }}
                                            >
                                                <i className="ri-add-line"></i>{' '}
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className="table-gradient">
                            <Table striped responsive className="a-table">
                                <thead>
                                    <tr key="0">
                                        <th align="middle" className="w-70px">
                                            <div className="d-flex justify-content-center">
                                                <Form.Check
                                                    inline
                                                    label=""
                                                    name={`inline-check-th-0`}
                                                    type="checkbox"
                                                    data-id={`th-0`}
                                                    id={`inline-check-th-0`}
                                                    defaultChecked={
                                                        this.state
                                                            .totalChecked > 0
                                                            ? true
                                                            : false
                                                    }
                                                    className={`${
                                                        this.state
                                                            .totalChecked > 0 &&
                                                        this.state
                                                            .totalChecked <
                                                            this.state.data
                                                                .length
                                                            ? 'line'
                                                            : ''
                                                    }`}
                                                    onClick={
                                                        this
                                                            .handleSelectAllChecks
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th>Account No.</th>
                                        <th>Account Name</th>
                                        <th>Debit Amount</th>
                                        <th>Credit Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        label=""
                                                        type="checkbox"
                                                        checked={this.state.checks.includes(
                                                            i
                                                        )}
                                                        onChange={() => {
                                                            this.handleCheck(i)
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <a
                                                    onClick={() => {
                                                        this.setState({
                                                            isShowing: true,
                                                            editData: item,
                                                            currentEditIndex: i,
                                                        })
                                                    }}
                                                    className="text-decoration-underline text-charcoal hover-view-icon"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {item.account}
                                                </a>
                                            </td>
                                            <td>{item.accountName}</td>
                                            <td>
                                                {currencyFormat(
                                                    item.isDebit
                                                        ? item.amount
                                                        : 0
                                                )}
                                            </td>
                                            <td>
                                                {currencyFormat(
                                                    !item.isDebit
                                                        ? item.amount
                                                        : 0
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>{this.renderInputBar()}</Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        onClick={this.handleSaveJournalEntries}
                        variant="primary"
                        size="lg"
                        disabled={this.state.isSaving}
                    >
                        Save
                    </Button>
                </FooterFormAction>

                <>
                    {this.state.isShowing && (
                        <JournalEntryAddDialog
                            editData={this.state.editData}
                            currentEditIndex={this.state.currentEditIndex}
                            isShowing={this.state.isShowing}
                            netDebAndCred={this.state.netDebitAndCredit}
                            onOkay={this.handleModalData}
                            onCancel={() => {
                                this.setState({
                                    isShowing: false,
                                    editData: {},
                                })
                            }}
                            dataCreated={this.state.data}
                            updatedData={this.handleUpdateData}
                        />
                    )}
                </>
            </>
        )
    }
}

export default WithRouter(JournalEntryAdd)
