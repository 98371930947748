import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import {
    WhatChangedPayload,
    WhatChangedResponse,
} from '../types/WhatChangedItems'
import { AxiosError } from 'axios'
import { INVALID_REQUESTS, UserError } from '../types/UserError'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'

const api = new ApiService()

const recalculate = async (id: number, payload: WhatChangedPayload) => {
    const recalculatedData = api.itemRecalculate(
        id,
        payload
    ) as Promise<WhatChangedResponse>
    return recalculatedData
}

const useRecalculate = (
    id: number,
    queryKey: string,
    options?: UseQueryOptions<WhatChangedResponse, unknown, WhatChangedResponse>
) => {
    const payload = JSON.parse(queryKey)
    const recalculateHook = useQuery({
        refetchOnWindowFocus: false,
        queryKey: [id, 'recalculate', queryKey],
        queryFn: () => recalculate(id, payload),
        enabled: false,
        retry: (_, error) => {
            return (
                (error as AxiosError<UserError>)?.response?.data?.userError !==
                INVALID_REQUESTS.INVALID_INPUT
            )
        },
        ...(options || {}),
    })

    useEffect(() => {
        if (
            (recalculateHook.error as AxiosError<UserError>)?.response?.data
                ?.userError === INVALID_REQUESTS.INVALID_INPUT
        ) {
            displayAlertError(INVALID_REQUESTS.INVALID_INPUT)
        }
    }, [recalculateHook.error])
    return recalculateHook
}

export default useRecalculate
