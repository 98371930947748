import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import DocViewer, {
    JPGRenderer,
    PDFRenderer,
    PNGRenderer,
    BMPRenderer,
    HTMLRenderer,
    TIFFRenderer,
    TXTRenderer,
    CSVRenderer,
    GIFRenderer,
    VideoRenderer,
} from '@cyntler/react-doc-viewer'
import { bytesToSize } from '../../../helpers/Util'
import { getDocumentUrl } from './DocumentsService'
import cn from 'classnames'
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf'
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const NoRenderer = () => {
    return (
        <div className="d-flex justify-content-center align-self-center w-100 text-white">
            Preview for this file type is unavailable. Please download the file.
        </div>
    )
}

export const DocumentsViewer = ({ document, onDownload, onClose }) => {
    const { id, name, file } = document
    const isImage = file.type.includes('image')

    return (
        <Modal
            className="documents-viewer"
            show={true}
            backdrop="static"
            aria-labelledby="action-modal"
            fullscreen={true}
        >
            <Modal.Header>
                <div className="d-flex flex-row justify-content-between w-100 text-white">
                    <div className="d-flex flex-column">
                        <div className="fw-bold">{name}</div>
                        <div>
                            {file.type} &#x2022; {bytesToSize(file.size)}
                        </div>
                    </div>
                    <div className="d-flex">
                        <Button
                            variant="ivory"
                            size="sm"
                            className="border-0 fsx-20 ri-download-cloud-line square me-2 text-white"
                            onClick={() => {
                                onDownload(document)
                            }}
                        />
                        <Button
                            variant="ivory"
                            size="sm"
                            className="border-0 fsx-20 ri-close-line square text-white"
                            onClick={onClose}
                        />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className={cn({ 'text-white': isImage })}>
                <DocViewer
                    documents={[
                        {
                            uri: getDocumentUrl(id),
                        },
                    ]}
                    pluginRenderers={[
                        JPGRenderer,
                        PDFRenderer,
                        PNGRenderer,
                        BMPRenderer,
                        HTMLRenderer,
                        TIFFRenderer,
                        TXTRenderer,
                        CSVRenderer,
                        GIFRenderer,
                        VideoRenderer,
                    ]}
                    config={{
                        header: {
                            disableHeader: true,
                        },
                        noRenderer: {
                            overrideComponent: NoRenderer,
                        },
                    }}
                />
            </Modal.Body>
        </Modal>
    )
}
