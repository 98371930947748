import React, { useCallback, useEffect, useState } from 'react'
import Header from './partials/Header'
import useGetComponent from 'lib/api/hooks/useGetComponent'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Spinner from 'app/components/help/Spinner'
import { FormProvider, useForm } from 'react-hook-form'
import useGetAssociatedComponents from 'lib/api/hooks/useGetAssociatedComponents'
import { Component } from 'lib/api/types/Component'
import getPreloadValues from './utils/getPreloadValues'
import useGetAddressByCode from 'lib/api/hooks/useGetAddressByCode'
import { Summary } from 'lib/api/types/Summary'
import MainSection from './partials/MainSection'
import CostPricingSection from './partials/CostPricingSection'
import {
    TWhatChangedComponent,
    TWhatChangedComponentPayload,
} from 'lib/api/types/WhatChangedComponent'
import useComponentRecalculate from 'lib/api/hooks/useComponentRecalculate'
import convertObjectPropsToLowercase from 'utilities/convertObjectPropsToLowercase'
import normalizeRecalculateDataPayload from './utils/normalizeRecalculateDataPayload'
import normalizeRecalculateDataResponse from './normalizeRecalculateDataResponse'
import SubComponents from './partials/SubComponents'
import useDeleteFile from 'lib/api/hooks/useDeleteFile'
import useUpdateFile from 'lib/api/hooks/useUpdateFile'
import useUploadFile from 'lib/api/hooks/useUploadFile'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    hideAlertLoader,
} from 'utilities/Response'
import MSG from 'defaults/Message'
import getItemDescription from 'utilities/getItemDescription'
import { AxiosError } from 'axios'
import URI from 'defaults/RoutesDefault'
import { UserError } from 'lib/api/types/UserError'
import normaliseComponentPayload from 'utilities/normalizeComponentPayload'
import { TFormValues } from './types/TFormValues'
import useEditComponent from 'lib/api/hooks/useEditComponent'
import { useFeature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'

const ComponentPage = () => {
    const { componentId, itemId, id: projectId } = useParams()

    const isEnabled = useFeature(FeatureFlags.ItemsRefactor)

    const [whatChanged, setWhatChanged] = useState<
        TWhatChangedComponentPayload | object
    >({})

    const navigate = useNavigate()

    const { mutateAsync: deleteFile } = useDeleteFile()

    const { mutateAsync: updateFile } = useUpdateFile()

    const { mutateAsync: uploadImage } = useUploadFile()

    const { mutateAsync: editComponent } = useEditComponent()

    const [subcomponents, setSubcomponents] = useState<Component[]>([])

    const [description, setDescription] = useState('')

    const {
        refetch: recalculate,
        data: recalculatedData,
        isFetching: isRecalculating,
    } = useComponentRecalculate(
        Number(componentId),
        JSON.stringify(whatChanged),
        {
            enabled: Object.values(whatChanged).length > 0,
        }
    )

    const {
        data: component,
        isLoading,
        error,
    } = useGetComponent(componentId as string)

    const {
        data: associatedComps,
        isLoading: isFetchingAssociatedComps,
        error: errorAssociatedComps,
    } = useGetAssociatedComponents(
        {
            projectId: projectId as string,
            itemId: itemId as string,
            component: component?.[0] as Component,
        },
        {
            enabled: !!component,
        }
    )

    const { data: defaultAddress, isFetching: isGettingAddress } =
        useGetAddressByCode(
            component?.[0].shipto as string,
            component?.[0].shiptoaddrtype as number
        )

    const methods = useForm<TFormValues>()

    const { setValue } = methods

    useEffect(() => {
        if (associatedComps) {
            setSubcomponents(associatedComps)
        }
    }, [associatedComps])

    useEffect(() => {
        if (recalculatedData) {
            Object.entries(
                convertObjectPropsToLowercase(
                    normalizeRecalculateDataResponse(recalculatedData)
                )
            ).forEach(([name, value]) => {
                if (name === 'id') {
                    return
                }
                return setValue(name as keyof TFormValues, value)
            })
        }
    }, [setValue, recalculatedData])

    useEffect(() => {
        const preloadValues = async () => {
            if (component && defaultAddress) {
                const preloadValues = await getPreloadValues(
                    component[0],
                    defaultAddress as Summary[]
                )
                setDescription(preloadValues.description.html)
                Object.entries(preloadValues).forEach(([name, value]) =>
                    setValue(name as keyof TFormValues, value)
                )
            }
        }
        preloadValues()
    }, [component, setValue, defaultAddress])

    const handleRecalculate = (
        whatPropertyChanged: `${TWhatChangedComponent}`
    ) => {
        const newWhatChanged = {
            whatChanged: whatPropertyChanged,
            ...normalizeRecalculateDataPayload(methods.getValues()),
        }

        // The query will refetch whenever whatChanged changes. But if there hasn´t been any change, we trigger a manual refetch
        if (JSON.stringify(whatChanged) === JSON.stringify(newWhatChanged)) {
            recalculate()
        }
        setWhatChanged(newWhatChanged)
    }

    const updateComponents = useCallback(
        (components: Component[]) => setSubcomponents(components),
        []
    )

    const handleImage = async () => {
        const image = methods.watch('image')

        const componentToEdit = component?.[0]
        if (componentToEdit?.primaryImageId && !image) {
            await deleteFile(componentToEdit.primaryImageId)
            return ''
        } else if (componentToEdit?.primaryImageId && image) {
            await updateFile({
                id: componentToEdit?.primaryImageId,
                file: image,
            })
            return componentToEdit?.primaryImageId
        }

        if (image) {
            const uploadedImage = await uploadImage({
                file: image,
                ObjectType: 'item',
                ObjectId: componentToEdit?.id as number,
                FileType: 1,
            })
            return uploadedImage.id
        }
    }

    const onSubmit = async (data: TFormValues) => {
        displayAlertLoader(MSG.loading.update.msg)
        try {
            const itemDescription = getItemDescription(
                data.itemName,
                data.description
            )

            const uploadedImage = await handleImage()

            const values: Partial<TFormValues> = structuredClone(data)

            delete values.image

            delete values.description

            const payload = normaliseComponentPayload(
                values as unknown as Component
            )

            await editComponent({
                ...itemDescription,
                ...payload,
                supplier: data.supplier || null,
                id: Number(componentId),
                primaryImageId: uploadedImage || '',
            })

            await Promise.all(
                subcomponents.map(async (comp) => {
                    const subComPayload = {
                        ...comp,
                        vendorName: values.vendorName as string,
                        supplier: values.supplier as string,
                    }
                    editComponent(normaliseComponentPayload(subComPayload))
                })
            )

            hideAlertLoader()
            displayAlertSuccess(`Component(s) edited successfully!`)

            navigate(
                generatePath(URI.project.newItemEdit, {
                    id: projectId,
                    itemId,
                })
            )
        } catch (error) {
            hideAlertLoader()
            displayAlertError(
                (error as AxiosError<UserError>)?.response?.data?.userError ||
                    'There was an error saving the information, please try again'
            )
        }
    }

    if (isLoading || isFetchingAssociatedComps || isGettingAddress) {
        return <Spinner />
    }

    if (error || errorAssociatedComps) {
        return null
    }

    return !isEnabled ? null : (
        <FormProvider
            resetField={methods.resetField}
            getFieldState={methods.getFieldState}
            handleSubmit={methods.handleSubmit}
            watch={methods.watch}
            setError={methods.setError}
            getValues={methods.getValues}
            reset={methods.reset}
            setValue={methods.setValue}
            clearErrors={methods.clearErrors}
            control={methods.control}
            register={methods.register}
            unregister={methods.unregister}
            setFocus={methods.setFocus}
            trigger={methods.trigger}
            formState={methods.formState}
        >
            <form
                className="tw-flex tw-flex-col tw-gap-4"
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <Header />
                <MainSection
                    description={description}
                    component={component?.[0] as Component}
                    disableInputs={isRecalculating}
                    handleRecalculate={handleRecalculate}
                />
                <CostPricingSection
                    disableInputs={isRecalculating}
                    handleRecalculate={handleRecalculate}
                />
                {component?.[0]?.comp === '001' && (
                    <SubComponents
                        components={subcomponents}
                        updateComponents={updateComponents}
                    />
                )}
            </form>
        </FormProvider>
    )
}

ComponentPage.displayName = 'ComponentPage'

export default ComponentPage
