import React from 'react'
import { Col, Row, Form, Table, Container } from 'react-bootstrap'
import Select from 'react-select'
import { WithRouter } from '../../../../helpers/Router'
import { NavLink } from 'react-router-dom'
import InputBar from '../../../components/InputBar'
import MyDropzone from '../../../ui-kit/MyDropzone'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import ThreadComponent from '../../ThreadComponent'
import { ActionBarVertical } from '../../../components/ActionBar'
import URI from '../../../../defaults/RoutesDefault'

class CatalogItemInfo extends ThreadComponent {
    constructor(props) {
        super(props)
        this.state = {
            item: {},
            itemComponents: [],
            vendors: [],
            unitsOfMeasures: [],
            salesCategories: [],
            groupNames: [],
            content: '',
            dataIsLoaded: false,
        }
    }

    componentDidMount() {
        if (Object.keys(this.props.item).length > 0) {
            this.setState({
                item: this.props.item,
                itemComponents: this.props.itemComponents,
                vendors: this.props.vendors,
                unitsOfMeasures: this.props.unitsOfMeasures,
                salesCategories: this.props.salesCategories,
                content: this.props.item.descOnly,
                dataIsLoaded: true,
            })
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.item !== this.props.item) {
            this.setState({
                item: this.props.item,
                itemComponents: this.props.itemComponents,
                vendors: this.props.vendors,
                unitsOfMeasures: this.props.unitsOfMeasures,
                salesCategories: this.props.salesCategories,
                content: this.props.item.descOnly,
                dataIsLoaded: true,
            })
        }
    }

    componentInit() {
        this.hasFormAction = true
    }

    handleChange(event) {
        let fieldName = event.target.name
        let fleldVal = event.target.value
        this.setState({ item: { ...this.state.item, [fieldName]: fleldVal } })
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            item: data,
        })
    }

    renderInputBar() {
        return (
            <InputBar className="full">
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Client Deposit %
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                defaultValue={this.state.item.cldeppct}
                                onChange={this.handleChange.bind(this)}
                                type="text"
                                placeholder="0.00"
                                size="sm"
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <span className="fw-bold">JOH01</span>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Est. Cost
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                defaultValue={this.state.item.esttcost}
                                onChange={this.handleChange.bind(this)}
                                type="text"
                                placeholder="0.00"
                                size="sm"
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Est. Price
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                defaultValue={this.state.item.esttprice}
                                onChange={this.handleChange.bind(this)}
                                type="text"
                                placeholder="0.00"
                                size="sm"
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    render() {
        return (
            <>
                <Container className="ms-0">
                    <Row>
                        <Col lg={8}>
                            <Form.Group controlId="formGridText1">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>Name</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            defaultValue={
                                                this.state.item.itemName
                                            }
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>Description</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            defaultValue={this.state.item.desc}
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                            type="text"
                                            placeholder="Description"
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0 pt-2">
                                            <strong>Notes</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <WysiwygEditor>
                                            {this.state.content
                                                ? this.state.content
                                                : ''}
                                        </WysiwygEditor>
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>Group/Name</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            key={`${Math.floor(
                                                Math.random() * 1000
                                            )}-min`}
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                            options={this.state.groupNames}
                                            defaultValue={{
                                                key: '',
                                                label: '',
                                            }}
                                            className="react-select"
                                            placeholder="Please select"
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>Units of Measure</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={6}>
                                        <Select
                                            key={`${Math.floor(
                                                Math.random() * 1000
                                            )}-min`}
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                            options={this.state.unitsOfMeasures}
                                            defaultValue={{
                                                key: this.state.item
                                                    .unitmeasure,
                                                label: this.state.item
                                                    .unitmeasure,
                                            }}
                                            className="react-select"
                                            placeholder="Please select"
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    ></Col>
                                    <Col lg={6}>
                                        <Form.Check
                                            inline
                                            label="Time Budget"
                                            name="group2"
                                            type="checkbox"
                                            id={`inline-radio-2`}
                                            className="fw-bold"
                                            onChange={this.handleChange.bind(
                                                this
                                            )}
                                            defaultValue={
                                                this.state.item.timebudget
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row className="align-items-center">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            <strong>Sales Category</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            key={`${Math.floor(
                                                Math.random() * 1000
                                            )}-min`}
                                            options={this.state.salesCategories}
                                            defaultValue={
                                                this.state.item
                                                    .defaultSalesCategory
                                            }
                                            className="react-select"
                                            placeholder="Please select"
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        <Col lg={4} className="mb-3 mb-lg-0">
                            <MyDropzone></MyDropzone>
                        </Col>
                    </Row>

                    <div className="my-4">
                        <Row className="justify-content-center">
                            <Col lg="12">{this.renderInputBar()}</Col>
                        </Row>
                    </div>

                    <div className="bg-secondary-grey ps-4 roundedx-4">
                        <Row xs={1} lg={2} className="py-4">
                            <Col>
                                <h6 className="fw-bold mb-0">
                                    Purchase Order Components
                                </h6>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col
                            xs={12}
                            className="table-actions position-relative table-action-bar"
                        >
                            <ActionBarVertical>
                                <ActionBarVertical.Links>
                                    <li>
                                        <NavLink
                                            to={URI.item.catalog.addComponent}
                                            className="d-flex align-items-center mb-2"
                                        >
                                            <i className="ri-add-box-fill mx-0"></i>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/"
                                            className="d-flex align-items-center mb-2"
                                        >
                                            <i className="ri-edit-line mx-0"></i>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/"
                                            className="d-flex align-items-center mb-2"
                                        >
                                            <i className="ri-close-line mx-0"></i>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/"
                                            className="d-flex align-items-center"
                                        >
                                            <i className="ri-restart-line mx-0"></i>
                                        </NavLink>
                                    </li>
                                </ActionBarVertical.Links>
                            </ActionBarVertical>

                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr>
                                            <th>Comp.#</th>
                                            <th>Description</th>
                                            <th>Vendor</th>
                                            <th>Type</th>
                                            <th>Est. Cost</th>
                                            <th>Est. Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.itemComponents.map(
                                            (c, i) => (
                                                <tr key={i} data-key={i}>
                                                    <td>{c.comp}</td>
                                                    <td>{c.descOnly}</td>
                                                    <td>{c.supplier}</td>
                                                    <td>{c.compType}</td>
                                                    <td>{c.estcost}</td>
                                                    <td>{c.estprice}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default WithRouter(CatalogItemInfo)
