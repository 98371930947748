import Documents from '../../../templates/modules/documents/Documents'
import { Container } from 'react-bootstrap'

const DocumentsForm = ({ onRefresh }) => (
    <Container className="p-4">
        <Documents objectType="Company" objectId={0} onRefresh={onRefresh} />
    </Container>
)

export default DocumentsForm
