import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import { delay, isEmpty } from 'lodash'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../../utilities/Response'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
import MSG from '../../../../defaults/Message'
import { truncateCharCount } from '../../../../helpers/String'
import { navigateOnSuccess } from '../../../../utilities/Router'

class ClientInvoiceExistingUpdatedHighlightedInvoice extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
            data: {},
            items: {
                lists: [],
                options: [],
            },
            invoiceStyleTypes: [],
            updatedData: {
                selectedItem: null,
                style: null,
                transDesc: '',
                remarks: '',
                remarksRtf: '',
            },
            remarksHTML: '',
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
        this.invnum = Number(this.props.params.id)
    }

    async componentDidMount() {
        try {
            const filter = `?$filter=invnum eq ${this.invnum}`
            const [data] = await this.api.getInvoices(filter)

            if (isEmpty(data)) {
                throw {
                    response: {
                        status: 404,
                    },
                }
            }

            const remarksHTML = await mapRtfToDraft(data?.remarksrtf)
            const [project] = await this.api.getProjects(
                `?$filter=order eq false and closeddt eq null and proj eq '${data?.proj}'`
            )

            const invoices = await this.api.getItemInvoices(
                `?$filter=projectCode eq '${data.proj}' and invoiceNumber eq ${this.invnum}`
            )
            let itemOptions = []

            if (invoices) {
                invoices.map(async (item) => {
                    const [itemData] = await this.api.getProjectItems(
                        `?$filter=projectId eq ${project?.id} and inactive eq false and item eq '${item.itemNumber}'`
                    )
                    const itemDesc = this.formatItemDesc(itemData)
                    itemOptions.push({
                        label: `${item.itemNumber}${
                            itemDesc ? ' - ' + itemDesc : ''
                        }`,
                        value: item.itemNumber,
                    })
                })
            }

            let invoiceStyleTypes = await this.api.get(
                'reportformats/types',
                'invoice'
            )
            invoiceStyleTypes = invoiceStyleTypes.reportFormats
                .filter((format) => format.allowInWeb)
                .map((format) => ({
                    value: format.id,
                    label: format.name,
                }))

            const style = invoiceStyleTypes.filter(
                (s) => s.value === data?.style
            )

            this.setIsFound(true)
            this.setState({
                data,
                items: {
                    lists: invoices,
                    options: itemOptions,
                },
                invoiceStyleTypes,
                updatedData: {
                    ...this.state.updatedData,
                    transDesc: data?.txdesc,
                    remarks: data?.remarks,
                    remarksrtf: data?.remarksrtf,
                    style,
                },
                remarksHTML,
            })
        } catch (error) {
            this.setIsFound(false)
            this.setError(error, true)
            this.setState({
                stateUpdated: Math.floor(Math.random() * 10000),
            })
        }
        this.setIsLoaded(true)
    }

    componentInit() {
        this.setTitle('Update Selected Invoice').setFormAction(true)
    }

    formatItemDesc(item) {
        const itemName = item.itemName || ''
        const itemDesc = item.desc || ''

        let result = ''

        if (itemName && itemDesc) {
            const truncatedDesc = truncateCharCount(itemDesc, 100)
            result = truncatedDesc.includes(itemName)
                ? truncatedDesc
                : `${itemName} ${truncatedDesc}`
        } else {
            result = itemName || itemDesc || ''
        }

        return result
    }

    handleChange = (e, select = null) => {
        let key, value
        if (select) {
            key = select?.name
            value = e?.value
        } else {
            key = e.target.name
            value = e.target.value
        }

        this.setState({
            updatedData: {
                ...this.state.updatedData,
                [key]: select ? { label: e.label, value } : value,
            },
        })
    }

    handleNotesChange = (e) =>
        this.setState({
            updatedData: {
                ...this.state.updatedData,
                remarks: e.target.value,
                remarksRtf: this.htmlToRtfConverter.convertHtmlToRtf(
                    e.target.html
                ),
            },
        })

    checkboxChange = (e, name) => {
        const { checked } = e.target
        const { selectedItem, remarks, remarksRtf, transDesc, style } =
            this.state.updatedData

        switch (name) {
            case 'isItemDisabled':
                this.setState({
                    isItemDisabled: !checked,
                })
                break
            case 'isEditorDisabled':
                this.setState({
                    isEditorDisabled: !checked,
                })
                break
            case 'isDescDisabled':
                this.setState({
                    isDescDisabled: !checked,
                })
                break
            default:
                this.setState({
                    isStyleDisabled: !checked,
                })
                break
        }
    }

    getData() {
        const {
            updatedData: {
                transDesc,
                remarks,
                remarksRtf,
                style,
                selectedItem,
            },
        } = this.state
        const data = {}

        if (!this.isDisabledCheck('isDescDisabled')) {
            data.updateDescription = true
            data.updateDescriptionText = transDesc
        }

        if (!this.isDisabledCheck('isEditorDisabled')) {
            data.updateRemarks = true
            data.remarksTxt = remarks
            data.remarksRtf = remarksRtf
        }

        if (!this.isDisabledCheck('isStyleDisabled')) {
            data.reportFormatStyle = style?.value
        }

        if (!this.isDisabledCheck('isItemDisabled')) {
            data.updateItemsDescriptions = true
            if (selectedItem?.value) {
                data.updateSingleItemDescription = selectedItem?.value
            }
        }

        return data
    }

    handleSave = async () => {
        const data = this.getData()

        if (isEmpty(data)) {
            displayAlertError('Please select the details to update')
            return
        }

        displayAlertLoader(MSG.loading.update.mgs)

        // Run the other update if any changes.
        this.api
            .postJson('clientinvoices/update', {
                ...data,
                invNum: this.invnum,
            })
            .then(() => {
                displayAlertSuccess(MSG.success.msg)
                delay(
                    () =>
                        navigateOnSuccess(
                            this,
                            false,
                            false,
                            URI.accountsReceivable.clientInvoices.listExisting
                        ),
                    700
                )
            })
            .catch((err) => {
                displayAlertError(getErrorMessage(err))
            })
    }

    isDisabledCheck(name) {
        return this.state[name] || this.state[name] === undefined
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={getRouteWithParam(
                            URI.accountsReceivable.clientInvoices.list,
                            { page: 'existing' }
                        )}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Client Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: getRouteWithParam(
                                    URI.accountsReceivable.clientInvoices.list,
                                    { page: 'existing' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Existing Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Update Selected Invoice
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Update Selected Invoice
                    </HeaderLight.Title>
                    <div>
                        <Button
                            as={Link}
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            to={
                                URI.accountsReceivable.clientInvoices
                                    .listExisting
                            }
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.handleSave}>
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        const { selectedItem, transDesc, style } = this.state.updatedData
        const { remarksHTML } = this.state

        return (
            <Form.Group>
                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="mb-1 mb-lg-3">
                            <Col>
                                <Form.Check
                                    inline
                                    label="Update descriptions to match specifications"
                                    name="check-group-item"
                                    type="checkbox"
                                    id={`check-group-item`}
                                    className="vtop mt-3"
                                    onChange={(e) =>
                                        this.checkboxChange(e, 'isItemDisabled')
                                    }
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row className="align-items-start mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0 mt-2 pt-1">
                                            Item No.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            options={this.state.items.options}
                                            value={selectedItem}
                                            name="selectedItem"
                                            className="react-select"
                                            placeholder="Please select"
                                            onChange={this.handleChange}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            isDisabled={Boolean(
                                                this.isDisabledCheck(
                                                    'isItemDisabled'
                                                )
                                            )}
                                        />
                                        <div className="mt-2">
                                            (Leave blank for all)
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-1 mb-lg-3">
                            <Col>
                                <Form.Check
                                    inline
                                    label="Update remarks:"
                                    name="check-group-remarks"
                                    type="checkbox"
                                    id={`check-group-remarks`}
                                    className="vtop mt-3"
                                    onChange={(e) =>
                                        this.checkboxChange(
                                            e,
                                            'isEditorDisabled'
                                        )
                                    }
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row className="align-items-start mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0 mt-2 pt-1">
                                            Remarks
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <WysiwygEditor
                                            isReadOnly={Boolean(
                                                this.isDisabledCheck(
                                                    'isEditorDisabled'
                                                )
                                            )}
                                            onChange={this.handleNotesChange}
                                        >
                                            {remarksHTML}
                                        </WysiwygEditor>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row className="mb-3">
                            <Col>
                                <Form.Check
                                    inline
                                    label="Update Transaction Description"
                                    name="check-group-desc"
                                    type="checkbox"
                                    id={`check-group-desc`}
                                    className="vtop mt-3"
                                    onChange={(e) =>
                                        this.checkboxChange(e, 'isDescDisabled')
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="align-items-start mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Transaction Desc.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="transDesc"
                                            placeholder="Please enter"
                                            disabled={Boolean(
                                                this.isDisabledCheck(
                                                    'isDescDisabled'
                                                )
                                            )}
                                            readOnly={Boolean(
                                                this.isDisabledCheck(
                                                    'isDescDisabled'
                                                )
                                            )}
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.substring(
                                                        0,
                                                        30
                                                    )
                                                this.handleChange(e)
                                            }}
                                            value={transDesc}
                                            maxLength={30}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-1 mb-lg-3">
                            <Col>
                                <Form.Check
                                    inline
                                    label="Update Style:"
                                    name="check-group-style"
                                    type="checkbox"
                                    id={`check-group-style`}
                                    className="vtop mt-3"
                                    onChange={(e) =>
                                        this.checkboxChange(
                                            e,
                                            'isStyleDisabled'
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="align-items-start mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0 mt-2 pt-1">
                                            Style
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            name="style"
                                            value={style}
                                            options={
                                                this.state.invoiceStyleTypes
                                            }
                                            className="react-select"
                                            placeholder="Please select"
                                            isDisabled={Boolean(
                                                this.isDisabledCheck(
                                                    'isStyleDisabled'
                                                )
                                            )}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return this.renderView(
            <>
                {this.renderHeader()}

                <div className="content-padding min-height mh-270">
                    <Container fluid className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceExistingUpdatedHighlightedInvoice)
