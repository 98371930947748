import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { PolicyTypes } from '../../../constants/vendor/constants'
import { ApiService } from '../../../../lib/api/HttpService'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { displayAlert } from '../../../../utilities/Response'
import {
    displayAlertError,
    getErrorMessage,
} from '../../../../utilities/Response'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { DATE_FORMAT } from '../../../constants/dates'

const api = new ApiService()

interface VendorModalProps {
    show: boolean
    onHide: () => void
    selectedInsurance?: Insurance | null
    vendor: string
}

interface Insurance {
    active: boolean
    carrier: string
    effectivedt: string
    expirationdt: string
    insuranceno: number | null
    policyno: string
    policytype: string
    ssmaTimeStamp: string
    vendor: string
}

interface FormValues {
    active: boolean
    carrier: string
    effectivedt: string
    expirationdt: string
    policyno: string
    policytype: string
    [key: string]: string | boolean | number
}

interface UpdateInsuranceData {
    insuranceNo: number
    data: object
}

const blankInsurance = {
    active: false,
    carrier: '',
    effectivedt: '',
    expirationdt: '',
    policyno: '',
    policytype: '',
}

const VendorInsuranceAddEditModal = ({
    show,
    onHide,
    selectedInsurance,
    vendor,
}: VendorModalProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const {
        register,
        handleSubmit,
        reset,
        formState: { dirtyFields },
        watch,
        control,
    } = useForm<FormValues>()

    const queryClient = useQueryClient()

    const watchedFields = watch(['carrier', 'policyno', 'policytype'])

    useEffect(() => {
        if (
            dirtyFields.carrier ||
            dirtyFields.policyno ||
            dirtyFields.policytype
        ) {
            errorMessage && setErrorMessage(null)
        }
    }, [watchedFields, dirtyFields])

    const createInsurance = useMutation(
        (insurance: object) => api.createInsurance(insurance),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['insurances'])
                displayAlert('success', 'Insurance created successfully.')
            },
            onError: (error) => {
                displayAlertError(getErrorMessage(error))
            },
        }
    )

    const updateInsurance = useMutation(
        ({ insuranceNo, data }: UpdateInsuranceData) =>
            api.updateInsurance(insuranceNo, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['insurances'])
                displayAlert('success', 'Insurance was updated successfully.')
            },
            onError: (error) => {
                displayAlertError(getErrorMessage(error))
            },
        }
    )

    const onSubmit = (data: FormValues) => {
        const updatedData = Object.keys(dirtyFields).reduce(
            (acc: Partial<FormValues>, key: string) => {
                acc[key] = data[key]
                return acc
            },
            {}
        )
        const hasInsuranceDetails =
            updatedData?.carrier ||
            updatedData?.policyno ||
            updatedData?.policytype

        if (!selectedInsurance && !hasInsuranceDetails) {
            setErrorMessage(
                'Please add a Policy Type, License/Policy Number or Insurance Carrier '
            )
            return
        }
        const formData = selectedInsurance
            ? updatedData
            : { ...data, vendor: vendor }

        if (selectedInsurance) {
            const insuranceNo: number = selectedInsurance?.insuranceno ?? -1
            const data = { data: formData, insuranceNo: insuranceNo }

            updateInsurance.mutate(data)
        } else {
            createInsurance.mutate(formData)
        }

        onClose()
    }

    useEffect(() => {
        if (selectedInsurance) {
            type data = Omit<
                Insurance,
                'insuranceno' | 'ssmaTimeStamp' | 'vendor'
            >

            const formattedData = {
                ...(selectedInsurance as data),
                effectivedt: selectedInsurance.effectivedt
                    ? format(
                          new Date(selectedInsurance.effectivedt),
                          DATE_FORMAT
                      )
                    : '',
                expirationdt: selectedInsurance.expirationdt
                    ? format(
                          new Date(selectedInsurance.expirationdt),
                          DATE_FORMAT
                      )
                    : '',
            }
            reset(formattedData)
        } else {
            reset(blankInsurance)
        }
    }, [selectedInsurance, reset])

    const onClose = () => {
        reset(blankInsurance)
        setErrorMessage(null)
        onHide()
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
            >
                <h5 className="text-center mx-auto mt-2 fw-bold pt-1 border-bottom pb-3">
                    Vendor Insurance
                </h5>
                {errorMessage && (
                    <p className="text-center text-danger">{errorMessage}</p>
                )}
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* Policy Type */}
                        <Form.Group
                            as={Col}
                            controlId="policyType"
                            className="mb-3"
                        >
                            <Form.Label>Policy Type</Form.Label>
                            <Form.Select
                                name={register('policytype').name}
                                onChange={register('policytype').onChange}
                                onBlur={register('policytype').onBlur}
                                ref={register('policytype').ref}
                            >
                                <option value="" disabled selected>
                                    Select a Policy Type
                                </option>
                                {Object.values(PolicyTypes).map(
                                    (type, index) => (
                                        <option key={index} value={type}>
                                            {type}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        </Form.Group>

                        {/* Insurance Carrier */}
                        <Form.Group
                            as={Col}
                            controlId="carrier"
                            className="mb-3"
                        >
                            <Form.Label>Insurance Carrier</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Carrier"
                                name={register('carrier').name}
                                onChange={register('carrier').onChange}
                                onBlur={register('carrier').onBlur}
                                ref={register('carrier').ref}
                            />
                        </Form.Group>

                        {/* License/Policy Number */}
                        <Form.Group
                            as={Col}
                            controlId="policyNumber"
                            className="mb-3"
                        >
                            <Form.Label>License/Policy Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Policy Number"
                                name={register('policyno').name}
                                onChange={register('policyno').onChange}
                                onBlur={register('policyno').onBlur}
                                ref={register('policyno').ref}
                            />
                        </Form.Group>
                        <Row>
                            {/* Effective Date */}
                            <Col md={4}>
                                <Form.Group controlId="effectiveDate">
                                    <Form.Label>Effective Date</Form.Label>
                                    <Controller
                                        name="effectivedt"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                onBlur,
                                                ref,
                                            },
                                        }) => (
                                            <DatePicker
                                                onChange={(date: Date) =>
                                                    onChange(
                                                        format(
                                                            new Date(date),
                                                            DATE_FORMAT
                                                        )
                                                    )
                                                }
                                                dateFormat={DATE_FORMAT}
                                                className="form-control"
                                                placeholderText="mm-dd-yyyy"
                                                isClearable={true}
                                                clearButtonTitle="clear"
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>

                            {/* Expiration Date */}
                            <Col md={4}>
                                <Form.Group controlId="expirationDate">
                                    <Form.Label>Expiration Date</Form.Label>
                                    <Controller
                                        name="expirationdt"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                onBlur,
                                                ref,
                                            },
                                        }) => (
                                            <DatePicker
                                                onChange={(date: Date) =>
                                                    onChange(
                                                        format(
                                                            new Date(date),
                                                            DATE_FORMAT
                                                        )
                                                    )
                                                }
                                                dateFormat={DATE_FORMAT}
                                                className="form-control"
                                                placeholderText="mm-dd-yyyy"
                                                isClearable={true}
                                                clearButtonTitle="clear"
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>

                            {/* Active */}
                            <Col md={4} className="d-flex align-items-center">
                                <Form.Group controlId="active">
                                    <Form.Check
                                        type="checkbox"
                                        label="Active"
                                        name={register('Active').name}
                                        onChange={register('Active').onChange}
                                        onBlur={register('Active').onBlur}
                                        ref={register('Active').ref}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="primary"
                                onClick={() => handleSubmit(onSubmit)()}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={onClose}
                                className="w-100 m-2"
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

VendorInsuranceAddEditModal.displayName = 'VendorInsuranceAddEditModal'

export default VendorInsuranceAddEditModal
