import { Component } from 'react'
import { Badge, Card } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { getClassNames } from '../../helpers/DOM'

class ABox extends Component {
    static CardLink(props) {
        return (
            <div
                className={getClassNames(
                    props,
                    `a-box-cards-basis ${props.size || ''}`
                )}
            >
                <Card
                    border={props.border || 'sand'}
                    bg={props.bg || 'ivory'}
                    className={`rounded-1 ${
                        props.cardtype || 'card-type-3'
                    } h-100`}
                >
                    <NavLink
                        to={props.to || '/'}
                        className="card-body text-decoration-none"
                    >
                        <Card.Text
                            as="div"
                            className="d-flex justify-content-between flex-column h-100 text-charcoal"
                        >
                            {props.children}
                        </Card.Text>
                    </NavLink>
                </Card>
            </div>
        )
    }

    static Card(props) {
        return (
            <div
                className={getClassNames(
                    props,
                    `a-box-cards-basis ${props.size || ''}`
                )}
            >
                <Card
                    border={props.border || 'sand'}
                    bg={props.bg || 'ivory'}
                    className={`rounded-1 ${
                        props.cardtype || 'card-type-3'
                    } h-100`}
                >
                    {props.children}
                </Card>
            </div>
        )
    }

    static BoxItem(props) {
        return (
            <div className="d-flex">
                <div
                    className={`a-box-basis ${
                        props.padding || props.padding === undefined
                            ? ''
                            : 'no-padding'
                    } ${
                        props.border || props.border === undefined
                            ? 'border-1'
                            : ''
                    } border-sand rounded-1 w-100`}
                >
                    {(() => {
                        /**
                         * Display badge.
                         * @param {string} _badge
                         * @returns
                         */
                        function getBadge(_badge) {
                            if (_badge) {
                                return (
                                    <div className="ms-2">
                                        <Badge>{_badge}</Badge>
                                    </div>
                                )
                            }
                        }

                        if (props.heading) {
                            return (
                                <div className="d-flex align-items-center mb-3 mb-md-4">
                                    <h3
                                        className="mb-0"
                                        dangerouslySetInnerHTML={{
                                            __html: props.heading,
                                        }}
                                    ></h3>
                                    {getBadge(props.badge)}
                                </div>
                            )
                        }
                    })()}

                    <div
                        className={`d-flex flex-wrap a-box-cards ${
                            props.boxsize || ''
                        }`}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div
                className={getClassNames(
                    this.props,
                    `d-flex flex-wrap a-box ${this.props.size || 'full'}`
                )}
            >
                {this.props.children}
            </div>
        )
    }
}

export default ABox
