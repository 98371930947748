import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { Component } from '../types/Component'

const api = new ApiService()

const getComponent = async (
    projectId: string,
    itemId: string,
    component: Component
): Promise<Component[]> => {
    const components = await api.getComponents(
        `?$filter=projectid eq ${projectId} and itemid eq ${itemId} and assocwithcomp eq '${component.comp}' and comp ne '${component.comp}'`
    )

    return components
}

const useGetAssociatedComponents = (
    {
        projectId,
        itemId,
        component,
    }: { projectId: string; itemId: string; component: Component },
    options?: UseQueryOptions<Component[], unknown, Component[], string[]>
): UseQueryResult<Component[]> => {
    const componentsQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [projectId, itemId, JSON.stringify(component), 'components'],
        queryFn: () => getComponent(projectId, itemId, component),
        enabled: !!projectId && !!itemId && !!component,
        ...{ ...(options || {}) },
    })
    useEffect(() => {
        if (componentsQuery.error) {
            displayAlertError(
                'An error occurred fetching the components, please try again'
            )
        }
    }, [componentsQuery.error])
    return componentsQuery
}
export default useGetAssociatedComponents
