import * as React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Resource } from '../../../resources/Resources'
import '../../../assets/styles/components/error-pages.scss'
import { ErrorContent } from './PageComponentError'

interface Props {
    code?: number
    message?: string
}

class PageModuleError extends React.Component<Props> {
    readonly code: number
    readonly message: string

    constructor(props: Props) {
        super(props)

        this.code = props.code ?? 500
        this.message = props.message ?? 'Oops! Something went wrong.'
    }

    render(): JSX.Element {
        return (
            <div className="content-padding error-layout">
                <Container fluid>
                    <Row>
                        <Col
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{ minHeight: '320px' }}
                        >
                            <ErrorContent
                                code={this.code}
                                message={this.message}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PageModuleError
