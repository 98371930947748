import React from 'react'
import {
    Dropdown,
    Col,
    Container,
    Row,
    Table,
    Form,
    Button,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import ListFilter from '../../../components/ListFilter'
import { ApiService } from '../../../../lib/api/HttpService'
import { Pagination } from '../../../../app/components/pagination/Pagination'
import debounce from 'lodash/debounce'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import { formatDate, formatFilterDate } from '../../../../helpers/Date'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import { pathParam } from '../../../../utilities/Router'
import URI from '../../../../defaults/RoutesDefault'
import CashReceiptsModal from '../../modal/CashReceiptsModal'
import { format } from 'date-fns'
import { getCookie } from '../../../../utilities/Auth'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import { Is, _delay, isFiltered } from '../../../../helpers/Util'
import { Event } from '../../../../utilities/DOM'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayErrors,
    getErrorMessage,
    hideAlertLoader,
    removeResponseAlert,
} from '../../../../utilities/Response'
import { currencyFormat } from '../../../../helpers/Number'
import ConfirmModal from '../../../../app/components/modal/ConfirmModal'
import MSG from '../../../../defaults/Message'
import { getCheckAllClass } from '../../../../utilities/ModuleHelper'
import { plural } from '../../../../utilities/String'
import FilterSort from '../../../../utilities/modules/FilterSort'

class CashReceiptListNew extends ThreadComponent {
    constructor(props) {
        super(props)

        this.fs = new FilterSort('ar_cashreceipts_new_list')
        this.fs.setDefaultSort('groupnum asc')
        this.state = {
            checks: [],
            totalChecked: 0,
            showRetainerModal: false,
            activeMenu: 'new',
            receipts: [],
            totalReceipts: 0,
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'groupnum asc',
            showPostModal: false,
            showDeleteModal: false,
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.api = new ApiService()

        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
        this.userCode = getCookie('dmUsercode')
        this.errIds = []

        this.updateErrIds = []
        this.updateErrors = []
    }

    componentInit() {
        this.setTitle('Cash Receipts - New')
    }

    async componentDidMount() {
        this.fetchItems()
        this.enableSortTable()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.checks !== this.state.checks) {
            this.computeTotalReceipts(
                this.state.receipts
                    .filter((item) => this.state.checks.includes(item.groupnum))
                    .map((item) => item)
            )
        }
    }

    computeTotalReceipts(receipts) {
        let sum = 0
        for (let receipt of receipts) {
            sum += receipt.fAmount
        }

        this.setState({
            totalReceipts: sum,
        })
    }

    resetState() {
        this.setState({
            dataIsLoaded: false,
        })
    }

    async fetchItems(page) {
        this.resetState()
        let receipts = await this.api.getNewCashReceipts(
            `?${this.buildFilters(page)}`
        )

        this.setState({
            receipts: receipts,
            dataIsLoaded: true,
        })

        const holdArray = []
        const getHoldChecks = receipts
            .slice(0, this.state.pageSize)
            .forEach((item, i) => {
                if (!item.hold) {
                    holdArray.push(item.groupnum)
                }
            })

        this.setState({
            checks: holdArray,
            totalChecked: holdArray.length,
        })
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.setState({
            showTableSearch: newTableSearch,
        })
        this.fs.setIsActiveSearch(newTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    handleSelectAllChecks = (e) => {
        this.updateErrors = []
        this.updateErrIds = []
        let checks = []
        // Get the target menu.
        const isChecked = e.target.checked

        if (isChecked) {
            checks = this.state.receipts.map((item) => {
                return item.groupnum
            })
        } else {
            checks = []
        }

        this.setState({
            checks: checks,
            totalChecked: checks.length,
        })

        this.handleMultipleSelect(!isChecked)
    }

    async handleMultipleSelect(hold) {
        displayAlertLoader(MSG.loading.hold)
        for (let item of this.state.receipts) {
            item.hold = hold

            const data = {
                groupnum: item.groupnum,
                hold: item.hold,
                usercode: this.userCode,
            }

            await this.api
                .patchTemporaryCashReceiptHold(data)
                .catch((error) => {
                    this.updateErrors.push({
                        error,
                        append: ` GroupNum: ${item.txnum}`,
                    })
                    this.updateErrIds.push(item.txnum)
                })
        }

        const message = this.updateErrIds.length
            ? 'The data with the ids [' +
              this.updateErrIds.join() +
              "] couldn't be updated"
            : 'The data was succesfully updated'
        const variant = this.updateErrIds.length ? 'danger' : 'success'

        await this.fetchItems()
        hideAlertLoader()

        if (this.updateErrIds.length) {
            displayErrors(this.updateErrors)
            displayAlert(variant, message)
        }
    }

    handleCheck = async (entry) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === entry.groupnum)
        const checked = index > -1
        const updatedChecks = checked
            ? checks.slice(0, index).concat(checks.slice(index + 1))
            : checks.concat(entry.groupnum)

        this.setState({
            checks: updatedChecks,
            totalChecked: updatedChecks.length,
        })

        this.api
            .patchTemporaryCashReceiptHold({
                groupnum: entry.groupnum,
                hold: checked,
                usercode: this.userCode,
            })
            .then((data) => {
                hideAlertLoader()
            })
            .catch((error) => {
                displayAlert('danger', getErrorMessage(error))
            })
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    doTableSearch(data) {}

    buildFilters(currentPage) {
        let filtersQuery = `&$filter=usercode eq ${this.userCode}`
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.ma) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    openPostModal = () => {
        this.setState({
            showPostModal: true,
        })
    }

    hidePostModal = () => {
        this.setState({
            showPostModal: false,
        })
    }

    handleSave = async (fiscalMonth) => {
        this.dMloader(true, null, false)
        try {
            await this.api.patchTemporaryCashReceiptFiscalMonth({
                fiscalMonth: format(fiscalMonth, 'MM/yyyy'),
                userCode: parseInt(this.userCode),
            })

            for (let i = 0; i < this.state.checks.length; i++) {
                if (this.state.checks[i]) {
                    await this.api.patchTemporaryCashReceiptHold({
                        groupnum: this.state.checks[i],
                        hold: false,
                        usercode: this.userCode,
                    })
                }
            }

            await this.api.postTemporaryCashReceipt({
                fiscalMonth: format(fiscalMonth, 'MM/yyyy'),
                PostingProcessType: 'allTransactions',
            })

            this.hidePostModal()
            displayAlert('success', 'Successfully posted receipt.')
            this.fetchItems()
            this.enableSortTable()
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
        this.dMloader(false, true)
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    toggleDeleteModal = (state) => {
        if (true === state && Is.empty(this.state.checks)) {
            // Check if there are selected.
            displayAlert('danger', MSG.error.noSelected, 4000)
            return
        }

        this.setState({
            showDeleteModal: state,
        })
    }

    onDeleteData = async () => {
        this.updateErrors = []
        this.updateErrIds = []
        const count = this.state.totalChecked

        displayAlertLoader(
            plural(MSG.loading.delete.CashReceipt, 'receipt', count)
        )
        this.toggleDeleteModal(false)

        for (const id of this.state.checks) {
            if (id) {
                await this.handleDelete(id)
            }
        }

        const message = this.updateErrIds.length
            ? 'The data with the ids [' +
              this.updateErrIds.join() +
              "] couldn't be deleted"
            : plural(MSG.success.delete.CashReceipt, 'receipt', count)
        const variant = this.updateErrIds.length ? 'danger' : 'success'

        // This first delay will give us some time to process the api call.
        _delay(async () => {
            hideAlertLoader()
            displayErrors(this.updateErrors)
            displayAlert(variant, message, -1)
            await this.fetchItems()
        }, 1700)
    }

    async handleDelete(id) {
        const path = `?UserCode=${this.userCode}&GroupNum=${id}`

        await this.api
            .deleteCashReceipt(path)
            .then((res) => {
                if (res.status !== 200) this.updateErrIds.push(id)
            })
            .catch((error) => {
                this.updateErrIds.push(id)
                this.updateErrors.push({
                    error,
                    append: ` Tx Num: ${id}`,
                })
            })
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    renderFilters() {
        return (
            <ListFilter>
                <ListFilter.Fields md={10} lg={10} xl={10} className="width-30">
                    {/* Client requested to hide this for now */}
                    {/* <ListFilter.Field>
                        <Form.Label className='text-end'><strong>Fiscal<br/>Month</strong></Form.Label>
                        <Form.Select size='sm'>
                            <option>07/2022</option>
                        </Form.Select>
                    </ListFilter.Field> */}
                    <ListFilter.Field>
                        <Form.Label className="text-end">
                            <strong>
                                Total
                                <br />
                                Receipts
                            </strong>
                        </Form.Label>
                        <div className="form-group-extra">
                            <Form.Control
                                type="text"
                                disabled={true}
                                size="sm"
                                value={currencyFormat(this.state.totalReceipts)}
                            />
                            <span className="text-secondary-ash">USD</span>
                        </div>
                    </ListFilter.Field>
                </ListFilter.Fields>
                <ListFilter.Actions
                    md={2}
                    lg={2}
                    xl={2}
                    replaceclassmatch="justify-content-xl-end"
                    replaceclassmatchwith="justify-content-md-end"
                >
                    <ListFilter.Action>
                        <Button
                            as={Link}
                            to="#"
                            variant="ivory"
                            size="sm"
                            className={`btn-icon btn-action ${
                                this.state.showTableSearch
                                    ? 'bg-primary-ash text-white'
                                    : ''
                            }`}
                            onClick={this.handleShowTableSearch}
                        >
                            <i className="ri-search-line"></i> Search
                        </Button>
                    </ListFilter.Action>
                    <ListFilter.Action className="ms-auto ms-md-3">
                        <Dropdown
                            className="d-flex justify-content-end ms-auto"
                            align="end"
                        >
                            <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                variant="ivory"
                                size="sm"
                            >
                                Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={this.openPostModal}>
                                    <i className="ri-upload-fill"></i> Post
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={this.toggleDeleteModal.bind(
                                        this,
                                        true
                                    )}
                                >
                                    <i className="ri-delete-bin-5-line"></i>{' '}
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ListFilter.Action>
                </ListFilter.Actions>
            </ListFilter>
        )
    }

    render() {
        const { totalChecked, receipts } = this.state
        return (
            <>
                <div className="d-block sticky-container">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* Filter */}
                                {this.renderFilters()}

                                <div className="d-block mt-3 mt-md-4"></div>

                                <div className="table-gradient">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                    >
                                        <thead>
                                            <tr className="a-table-heading">
                                                <th
                                                    align="middle"
                                                    className="mw-80px"
                                                >
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            label=""
                                                            type="checkbox"
                                                            id={`inline-check-th-0`}
                                                            name={`inline-check-th-0`}
                                                            checked={
                                                                totalChecked > 0
                                                            }
                                                            className={getCheckAllClass(
                                                                totalChecked,
                                                                receipts
                                                            )}
                                                            onChange={
                                                                this
                                                                    .handleSelectAllChecks
                                                            }
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fClient'
                                                        )}
                                                        data-field="fClient"
                                                    >
                                                        Client Code
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fClientName'
                                                        )}
                                                        data-field="fClientName"
                                                    >
                                                        Client Name
                                                    </span>
                                                </th>
                                                <th className="w-140px mw-140px">
                                                    <span
                                                        className={this.sortClass(
                                                            'fCheckNum'
                                                        )}
                                                        data-field="fCheckNum"
                                                    >
                                                        Check No. (Payment
                                                        Status)
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fUserDate'
                                                        )}
                                                        data-field="fUserDate"
                                                    >
                                                        Date
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fPayType'
                                                        )}
                                                        data-field="fPayType"
                                                    >
                                                        Payment Type
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fAmount'
                                                        )}
                                                        data-field="fAmount"
                                                    >
                                                        Amount
                                                    </span>
                                                </th>
                                                <th className="mw-140px">
                                                    <span
                                                        className={this.sortClass(
                                                            'proj'
                                                        )}
                                                        data-field="proj"
                                                    >
                                                        Project -
                                                        Invoice/Proposal
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fDepositSlip'
                                                        )}
                                                        data-field="fDepositSlip"
                                                    >
                                                        Deposit Slip
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className={this.sortClass(
                                                            'fCashAccount'
                                                        )}
                                                        data-field="fCashAccount"
                                                    >
                                                        Cash Account
                                                    </span>
                                                </th>
                                                <th className="mw-140px">
                                                    <span
                                                        className={this.sortClass(
                                                            'caName'
                                                        )}
                                                        data-field="caName"
                                                    >
                                                        Cash Account Name
                                                    </span>
                                                </th>
                                                <th className="mw-200px">
                                                    <span data-field="">
                                                        Offset Account
                                                    </span>
                                                </th>
                                                <th className="mw-90px">
                                                    <span
                                                        className={this.sortClass(
                                                            'groupnum'
                                                        )}
                                                        data-field="groupnum"
                                                    >
                                                        Ref No.
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr
                                                className={`a-table-search-fields ${
                                                    this.state.showTableSearch
                                                        ? ''
                                                        : 'd-none'
                                                }`}
                                            >
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fClient"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fClient'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fClientName"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fClientName'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fCheckNum"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fCheckNum'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <DateRangePicker
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                        placement="auto"
                                                        placeholder="Select date"
                                                        format="MM/dd/yyyy"
                                                        defaultValue={this.fs.getValue(
                                                            'fUserDate'
                                                        )}
                                                        onChange={this.handleDateChange.bind(
                                                            this,
                                                            'fUserDate'
                                                        )}
                                                        onClean={this.handleDateChange.bind(
                                                            this,
                                                            'fUserDate'
                                                        )}
                                                        ranges={[
                                                            {
                                                                label: 'today',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date()
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'yesterday',
                                                                value: [
                                                                    startOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        addDays(
                                                                            new Date(),
                                                                            -1
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'last7Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            6
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last 30 Days',
                                                                value: [
                                                                    startOfDay(
                                                                        subDays(
                                                                            new Date(),
                                                                            30
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        new Date()
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'This month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ),
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date()
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                            {
                                                                label: 'Last month',
                                                                value: [
                                                                    startOfDay(
                                                                        new Date(
                                                                            getYear(
                                                                                new Date()
                                                                            ),
                                                                            getMonth(
                                                                                new Date()
                                                                            ) -
                                                                                1,
                                                                            1
                                                                        )
                                                                    ),
                                                                    endOfDay(
                                                                        lastDayOfMonth(
                                                                            new Date(
                                                                                getYear(
                                                                                    new Date()
                                                                                ),
                                                                                getMonth(
                                                                                    new Date()
                                                                                ) -
                                                                                    1,
                                                                                1
                                                                            )
                                                                        )
                                                                    ),
                                                                ],
                                                            },
                                                        ]}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fPayType"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fPayType'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fAmount"
                                                        data-type="number"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fAmount'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="proj"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'proj'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fDepositSlip"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fDepositSlip'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="fCashAccount"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'fCashAccount'
                                                        )}
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="caName"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'caName'
                                                        )}
                                                    />
                                                </th>
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="groupnum"
                                                        data-type="number"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        defaultValue={this.fs.getValue(
                                                            'groupnum'
                                                        )}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.state.dataIsLoaded
                                                ? showLoading()
                                                : this.state.receipts.length ===
                                                      0 &&
                                                  isFiltered(
                                                      this.state
                                                          .searchProperties
                                                  )
                                                ? showEmpty()
                                                : this.state.receipts
                                                      .slice(
                                                          0,
                                                          this.state.pageSize
                                                      )
                                                      .map((item, i) => (
                                                          <tr
                                                              key={i}
                                                              data-key={i}
                                                              className={
                                                                  this.state.checks.includes(
                                                                      item.groupnum
                                                                  )
                                                                      ? `active`
                                                                      : ''
                                                              }
                                                          >
                                                              <td>
                                                                  <div className="d-flex justify-content-center">
                                                                      <Form.Check
                                                                          label=""
                                                                          type="checkbox"
                                                                          checked={this.state.checks.includes(
                                                                              item.groupnum
                                                                          )}
                                                                          onClick={() => {
                                                                              this.handleCheck(
                                                                                  item
                                                                              )
                                                                          }}
                                                                          className="single-data-checkbox"
                                                                      />
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  <Link
                                                                      to={
                                                                          item.fType ===
                                                                          3
                                                                              ? pathParam(
                                                                                    URI
                                                                                        .accountsReceivable
                                                                                        .cashReceipt
                                                                                        .editMisc,
                                                                                    {
                                                                                        id: item.groupnum,
                                                                                    }
                                                                                )
                                                                              : pathParam(
                                                                                    URI
                                                                                        .accountsReceivable
                                                                                        .cashReceipt
                                                                                        .edit,
                                                                                    {
                                                                                        id: item.groupnum,
                                                                                    }
                                                                                )
                                                                      }
                                                                      className="text-charcoal hover-view-icon"
                                                                  >
                                                                      {item.fClient ??
                                                                          '#'}
                                                                  </Link>
                                                              </td>
                                                              <td>
                                                                  {item.fClientName ??
                                                                      item.fName}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fCheckNum
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {formatDate(
                                                                      item.fUserDate
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fPayType
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {item.fAmount
                                                                      ? item.fAmount.toFixed(
                                                                            2
                                                                        )
                                                                      : ''}
                                                              </td>
                                                              <td>
                                                                  {item.invProp}
                                                                  {item.txdesc}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fDepositSlip
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.fCashAccount
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {item.caName}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.offsetAccount
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.groupnum
                                                                  }
                                                              </td>
                                                          </tr>
                                                      ))}
                                        </tbody>
                                    </Table>
                                </div>

                                {this.state.receipts.length > 0 && (
                                    <Pagination
                                        onPageSizeChanged={
                                            this.onPageSizeChanged
                                        }
                                        onPageChanged={this.onPageChanged}
                                        hasPreviousPage={this.state.page > 1}
                                        hasNextPage={
                                            this.state.receipts.length >
                                            this.state.pageSize
                                        }
                                        page={this.state.page}
                                        pageSize={this.state.pageSize}
                                    />
                                )}

                                {this.state.showPostModal && (
                                    <CashReceiptsModal
                                        hideModal={this.hidePostModal}
                                        handleSave={this.handleSave}
                                        totalReceipts={
                                            this.state.checks.length ?? ''
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <ConfirmModal
                    show={this.state.showDeleteModal}
                    toggleModal={this.toggleDeleteModal.bind(this, false)}
                    confirmAction={this.onDeleteData.bind(this)}
                />
            </>
        )
    }
}

export default WithRouter(CashReceiptListNew)
