import { useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { TAddressByCode } from '../types/AddressByCode'

const api = new ApiService()

const useGetCompanyLabel = (warehouseCode: string) => {
    return useQuery<TAddressByCode, Error>(
        ['label', warehouseCode],
        async () =>
            await api.getAddressByCode(warehouseCode, true, 'addresstype eq 1'),
        {
            refetchOnWindowFocus: false,
        }
    )
}

export default useGetCompanyLabel
