import { isEmpty, join, replace } from 'lodash'
import { ApiService } from '../../lib/api/HttpService'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    displayErrors,
    hideAlertLoader,
} from '../Response'
import { plural } from '../String'
import MSG from '../../defaults/Message'
import { getCookie } from '../Auth'

export async function vendorInvoiceUpdateTagsProcess(
    txnums: any[],
    hold: boolean
) {
    const api = new ApiService()
    const usercode = getCookie('dmUsercode')
    const errors: any = [],
        errorIds: any = []

    if (isEmpty(txnums)) {
        return
    }

    if (txnums.length > 1) {
        displayAlertLoader(MSG.loading.hold)
    }

    for (const txnum of txnums) {
        await api
            .updateTemporaryVendorInvoice({
                usercode,
                hold,
                txnum: txnum,
            })
            .catch((error) => {
                errors.push({
                    error: error,
                    append: ` TxNum: ${txnum}`,
                })
                errorIds.push(txnum)
            })
    }

    if (!isEmpty(errorIds)) {
        displayErrors(errors)
        displayAlertError(
            plural(
                replace(
                    MSG.error.InvoiceFailedTag,
                    '[IDS]',
                    join(errorIds, ', ')
                ),
                'Invoice',
                errorIds.length
            )
        )
    } else {
        hideAlertLoader()
    }
}

export async function vendorInvoiceDeleteProcess(
    usercode: string,
    txNums: any[]
) {
    const api = new ApiService()
    const errorIds: any = [],
        errors: any = []

    for (const txnum of txNums) {
        const path = `?usercode=${usercode}&txnum=${txnum}`

        await api
            .deleteTemporaryVendorInvoices(path)
            .then((res: any) => {
                if (res.status !== 200) errorIds.push(txnum)
            })
            .catch((error) => {
                errorIds.push(txnum)
                errors.push({
                    error,
                    append: ` Inv Num: ${txnum}`,
                })
            })
    }

    return {
        errorIds,
        errors,
    }
}

export async function vendorInvoiceDisplayDeleteMessage(
    component: any,
    response: any = {},
    checks: any[],
    ctr: number
) {
    if (!isEmpty(response.errorIds)) {
        displayErrors(response.errors)
        displayAlertError(
            plural(
                replace(
                    MSG.error.delete.APVendorInv,
                    '[IDS]',
                    join(response.errorIds, ', ')
                ),
                'Invoice',
                response.errorIds.length
            )
        )
    } else {
        displayAlertSuccess(
            replace(
                plural(MSG.success.delete.APVendorInv, 'invoice', ctr),
                '[IDS]',
                join(checks, ', ')
            )
        )
        await component.reloadData()
    }
}
