import React from 'react'

export type MultiSearchComponentsProps = {
    // general
    type:
        | 'text'
        | 'number'
        | 'combobox'
        | 'switch'
        | 'react-select'
        | 'date-range'
        | 'number-range'
    id?: string
    name?: string
    label?: string
    style?: any
    isFixed?: boolean
    handleChangeField?: any
    handleDetach?: any
    isNumber?: boolean
    isString?: boolean
    apifield?: string
    operator?: string
    isClear?: boolean
    defaultValue?: any
    placeholder?: string

    // combobox
    title?: string
    moduletype?: string
    handleComboboxSelect?: any
    queryFilter?: string
    field?: string
    getDataFn?: any
    zIndex?: number
    map?: (data: any) => any
    customKey?: string
    customValue?: string
    formatLabel?: string
    fetchAll?: boolean
    filters?: string
    overrideOptionLabel?: any
    overrideOptionKey?: any
    overrideOptionValue?: any

    // switch/toggle
    defaultChecked?: boolean
    checked?: boolean

    // react-select
    options?: any[]

    // numbers
    min?: number
    max?: number
}

export interface MultiSearchComponentsState {
    props: MultiSearchComponentsProps
    dateValues?: any
    textValue?: string
    toggleChecked?: boolean
    comboSelected?: any
    selected?: any
    numberRange?: any
    cleared?: boolean
}

export default class MultiSearchComponents extends React.Component<
    MultiSearchComponentsProps,
    MultiSearchComponentsState
> {
    isFixed: boolean
    constructor(props: MultiSearchComponentsProps) {
        super(props)

        this.state = {
            props,
            cleared: false,
        }

        this.isFixed = props.isFixed === true || props.isFixed === undefined
    }

    componentDidUpdate(
        prevProps: Readonly<MultiSearchComponentsProps>,
        prevState: Readonly<MultiSearchComponentsState>
    ): void {
        const { isClear } = this.props

        if (prevProps !== this.props) {
            this.setState({
                props: this.props,
                cleared: isClear,
            })
        }
    }

    setValue(key: any, val: any) {
        this.setState({
            [key]: val,
        } as any)
    }

    renderLabel(valueKey: string) {
        const { props } = this.state
        const value: any = (this.state as any)[valueKey]
        if (value) {
            return (
                <span className="group-label position-absolute bg-white fs-8">
                    {props.label}
                </span>
            )
        }
    }
}
