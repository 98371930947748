import { ApiService } from '../../../../lib/networking/HttpService'

export class ProjectsService extends ApiService {
    getProjectLists<T = ProjectListsResponse>(): Promise<T> {
        return this.httpWorker.get(`/projects`, {})
    }

    addProject<T = AddProjectResponse>(params: AddProjectParams): Promise<T> {
        return this.httpWorker.post(`/projects`, params)
    }
}

interface ProjectListsResponse {}
interface AddProjectResponse {}
export interface AddProjectParams {}
