import React from 'react'
import Spinner from 'app/components/help/Spinner'
import {
    OnboardingStatus,
    TPaymentProcessorResponse,
} from 'lib/api/types/PaymentProcessorResponse'
import useGetDesignPayDefaults from 'lib/api/hooks/useGetDesignPayDefaults'

interface IPaymentProcessorInfoProps {
    stripeData?: TPaymentProcessorResponse | null
}

const PaymentProcessorInfo = ({ stripeData }: IPaymentProcessorInfoProps) => {
    const {
        data: rates,
        error: ratesError,
        isLoading: isFetchingRatesInfo,
    } = useGetDesignPayDefaults(false)

    if (isFetchingRatesInfo) {
        return <Spinner isChild />
    }

    if (ratesError) {
        return null
    }

    return (
        <div className="tw-grid lg:tw-grid-flow-col tw-justify-evenly tw-gap-8 lg:tw-gap-2 tw-font-bold tw-p-10 tw-text-3xl tw-mt-0 tw-ml-10 tw-mr-10 tw-mb-0">
            <div className="tw-flex tw-flex-col tw-gap-2">
                <p className="tw-m-0 tw-font-normal">
                    {stripeData?.onboardingStatus === OnboardingStatus.Verified
                        ? 'Switch to Design Pay'
                        : 'Accept Both Cards and Bank Transfers'}
                </p>
                <p className="tw-text-base tw-m-0 tw-font-normal tw-pt-4 tw-text-[#7C7C7C]">
                    Choose what&apos;s best for your business - you can accept
                    card payments, bank transfers, or both.
                </p>
                <div className="tw-flex tw-gap-2 tw-flex-col tw-mt-8">
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <i className="ri-checkbox-circle-fill tw-text-[#008B6D]"></i>
                        <p className="tw-text-base tw-m-0 tw-font-normal tw-text-[#7C7C7C]">
                            Cards:
                            <span className="tw-text-[#008B6D] tw-font-bold">
                                {' '}
                                ${rates?.creditCardFixedDollarFee}{' '}
                            </span>{' '}
                            <span className="tw-font-bold">+</span>
                            <span className="tw-text-[#008B6D] tw-font-bold">
                                {' '}
                                {rates?.creditCardTotalFeePercentage}%{' '}
                            </span>{' '}
                            per transaction
                        </p>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <i className="tw-text-[#008B6D] ri-checkbox-circle-fill"></i>
                        <p className="tw-text-base tw-m-0 tw-font-normal tw-text-[#7C7C7C]">
                            Bank Transfers:
                            <span className="tw-text-[#008B6D] fw-bold">
                                {' '}
                                ${rates?.achProcessorFeeDollarCap}{' '}
                            </span>{' '}
                            <span className="fw-bold">+</span>
                            <span className="tw-text-[#008B6D] fw-bold">
                                {' '}
                                {rates?.achTotalFeePercentage}%{' '}
                            </span>{' '}
                            per transaction
                        </p>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <i className="tw-text-[#008B6D] ri-checkbox-circle-fill"></i>
                        <div>
                            <p className="tw-text-base tw-m-0 tw-font-normal tw-text-[#7C7C7C]">
                                No transaction caps means you can process large
                                transactions with ease.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-grid tw-grid-flow-row tw-gap-4">
                <img
                    className="tw-h-auto tw-min-w-24 tw-object-contain tw-max-h-96 tw-rounded"
                    src={'/DesignPay/designpay.jpg'}
                />
                <a
                    href="https://www.designmanager.com/feature/design-pay"
                    target="_blank"
                    rel="noreferrer"
                    className="tw-justify-self-end tw-text-base tw-font-normal tw-text-[#212529]"
                >
                    Learn More
                </a>
            </div>
        </div>
    )
}
PaymentProcessorInfo.displayName = 'PaymentProcessorInfo'

export default PaymentProcessorInfo
