import React, { createContext, useEffect, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'

type TMessageLevel = 'warning' | 'error'

interface IBudgetBannerContext {
    show: boolean
    level: TMessageLevel
    message: string | null
}

export interface IBudgetCheck {
    passed: boolean
    isWarning: boolean
    reasonMessage: string | null
}

export const BudgetBannerContext = createContext<IBudgetBannerContext>({
    show: false,
    level: 'error',
    message: null,
})

interface IBudgetBannerProviderProps {
    children: React.ReactNode
    budgetCheck?: IBudgetCheck
    overrideBudgetCheck?: boolean
}

const BudgetBannerProvider = ({
    children,
    budgetCheck,
    overrideBudgetCheck,
}: IBudgetBannerProviderProps) => {
    const [show, setIsShow] = useState(false)
    const [level, setLevel] = useState<TMessageLevel>('warning')
    const [message, setMessage] = useState<string | null>(null)

    useEffect(() => {
        let interceptor: number
        if (!overrideBudgetCheck) {
            interceptor = axios.interceptors.response.use(
                (response: AxiosResponse) => {
                    const { passed }: IBudgetCheck = response.data
                    if (typeof passed !== 'undefined') {
                        handleBudgetCheck(response.data)
                    } else if (response.data.budgetCheck) {
                        handleBudgetCheck(response.data.budgetCheck)
                    }
                    return response
                },
                (error: AxiosError) => {
                    return Promise.reject(error)
                }
            )
        }

        return () => {
            interceptor && axios.interceptors.response.eject(interceptor)
        }
    }, [overrideBudgetCheck])

    useEffect(() => {
        if (budgetCheck) {
            handleBudgetCheck(budgetCheck)
        }
    }, [budgetCheck])

    const handleBudgetCheck = (budgetCheck: IBudgetCheck) => {
        const { passed, isWarning, reasonMessage } = budgetCheck

        // If Passed is true and IsWarning is false, no banner is displayed.
        if (passed && !isWarning) {
            setIsShow(false)
        }
        // If Passed is false, display a red banner.
        if (!passed) {
            setIsShow(true)
            setLevel('error')
            setMessage(reasonMessage)
        }
        // If IsWarning is true display a yellow banner.
        if (isWarning) {
            setIsShow(true)
            setLevel('warning')
            setMessage(reasonMessage)
        }
    }

    return (
        <BudgetBannerContext.Provider value={{ show, level, message }}>
            {children}
        </BudgetBannerContext.Provider>
    )
}

BudgetBannerProvider.displayName = 'BudgetBannerProvider'

export default BudgetBannerProvider
