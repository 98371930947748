import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { Summary } from '../types/Summary'

const api = new ApiService()

const getAddressByCode = async (
    code: string,
    addressType: number
): Promise<Summary[]> => {
    const address = (await api.getAddressesSummaries(
        `?$filter=code eq '${encodeURIComponent(
            code
        )} ' and addresstype eq ${addressType}`
    )) as Promise<Summary[]>

    return address
}

const useGetComponent = (
    code: string,
    addressType: number,
    options?: UseQueryOptions<
        Summary[],
        unknown,
        Summary[],
        (string | number)[]
    >
): UseQueryResult<Summary[]> => {
    const addressByCodeQuery = useQuery({
        refetchInterval: false,
        refetchOnWindowFocus: false,
        queryKey: [code, addressType, 'addresses'],
        queryFn: () => getAddressByCode(code, addressType),
        enabled: !!code && !!addressType,
        ...(options || {}),
    })
    useEffect(() => {
        if (addressByCodeQuery.error) {
            displayAlertError(
                'An error occurred fetching the component address, please try again'
            )
        }
    }, [addressByCodeQuery.error])
    return addressByCodeQuery
}
export default useGetComponent
