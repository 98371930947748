import ThreadComponent from '../../ThreadComponent'
import { Col, Row, Form, Button, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { WithRouter } from '../../../../utilities/Router'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    displayAlertError,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../../utilities/Response'
import { formatDate, formatFiscalMonth } from '../../../../helpers/Date'
import { find } from 'lodash'

class EditCashReceiptModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedDate: new Date(),
            checkno: null,
            depositslip: null,
            fiscalMonth: new Date(),
            name: '',
            paytype: {},
            type: {},
            proj: null,
            desc: null,
            amount: 0,
            propnum: null,
            offsetAcct: {},
            paymentTypes: [],
            invoiceNo: null,
            isLoading: true,
        }

        this.api = new ApiService()
        this.transactionType = [
            { label: 'Pmt.', value: 2006 },
            { label: 'Dep.', value: 2004 },
            { label: 'Ret.', value: 2002 },
            { label: 'Misc.', value: 2000 },
        ]

        this.crTypes = [
            { label: 'Deposit', value: 0 },
            { label: 'Retainer', value: 1 },
            { label: 'Payment', value: 2 },
            { label: 'Misc', value: 3 },
            { label: 'InvAdjustment', value: 4 },
        ]
    }

    componentDidMount() {
        this.getPayTypes()
        this.setData()
    }

    setData() {
        const type = this.getDefaultType(this.props.distribution.type)
        const typeVal = this.getMapTypeValue(type.value)
        const selectedProj = this.props.distribution.projn
            ? `${this.props.distribution.projn}[${this.props.distribution.proj}]`
            : ''

        this.setState({
            selectedDate: new Date(this.props.receipt.fuserdate),
            checkno: this.props.receipt.fchecknum,
            depositslip: this.props.receipt.fdepositslip,
            fiscalMonth: Date.parse(this.props.receipt.ffiscalmonth),
            name: this.props.receipt.forgname,
            paytype: {
                label: this.props.distribution.paytype,
                value: this.props.distribution.paytype,
            },
            type: find(this.crTypes, { value: typeVal }),
            proj: selectedProj,
            desc: this.props.distribution.txdesc,
            amount: this.formatAmount(
                String(this.props.distribution.totalAmount)
            ),
            propnum: this.props.distribution.propnum,
            payingtxnum: this.props.distribution.payingtxnum,
            offsetAcct: {
                label: `${this.props.distribution.accountn}[${this.props.distribution.singleoffsetaccount}]`,
                value: this.props.distribution.singleoffsetaccount,
            },
            isLoading: false,
        })
    }

    async getPayTypes() {
        const payTypes = await this.api.getPaymentTypes()

        if (payTypes) {
            const ptypes = payTypes.map((paymentType) => {
                return {
                    value: paymentType.type,
                    label: paymentType.type,
                    undeposited: paymentType.undeposited,
                }
            })

            this.setState({
                paymentTypes: ptypes,
            })
        }
    }

    getDefaultType(selectedType) {
        return this.transactionType.find((type) => type.value === selectedType)
    }

    handleChange = (e, meta) => {
        let key, value
        if (!meta) {
            key = e.target.getAttribute('name')
            value = e.target.value
        } else if (
            meta &&
            meta.hasOwnProperty('name') &&
            e.hasOwnProperty('value')
        ) {
            key = e.value
            value = e.value
        }

        if (meta && meta.name === 'paytype') {
            key = 'paytype'
            value = {
                label: value,
                value,
            }
        }

        this.setState((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    handleDateChange = (val, name) => {
        this.setState({
            [name]: val,
        })
    }

    formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })
            .format(this.normalizeAmount(amount))
            .replace('$', '')
    }

    handleAmountBlur = (e) => {
        if (this.isValidAmount(e.target.value)) {
            e.target.value = this.formatAmount(e.target.value)
        }
    }

    isValidAmount = (amount) => {
        const isValidAmount = /^\d*(?:[.,]\d+)?$/.test(
            this.normalizeAmount(amount)
        )
        return isValidAmount
    }

    normalizeAmount = (amount) => {
        return amount.replaceAll(',', '').replace('$', '')
    }

    handleSave = async () => {
        this.dMloader(true)
        const isValidAmount = this.isValidAmount(this.state.amount)

        if (!isValidAmount) {
            this.dMloader(false)
            return displayAlertError('Please enter a valid Amount.')
        }

        let extData = {}
        if (!this.state.proj && this.state.type.value !== 3) {
            displayAlertError('The project is invalid.')
            this.dMloader(false)
            return
        }

        if (this.state.type.value === 2 || this.state.type.value === 4) {
            extData = {
                invnum: this.state.payingtxnum,
            }
        }

        const edittedData = Object.assign(extData, {
            revisestxnum: this.props.distribution.txnum,
            type: this.state.type.value,
            usercode: this.props.distribution.usercode,
            txnum: this.props.distribution.txnum,
            depositslip: this.state.depositslip,
            checknum: this.state.checkno,
            txdesc: this.state.desc,
            propnum: this.props.distribution.propnum,
            totalAmount: Number(this.normalizeAmount(this.state.amount)),
            amount: Number(this.normalizeAmount(this.state.amount)),
            fiscalmonth: formatDate(this.state.fiscalMonth),
            miscaccount: this.props.distribution.singleoffsetaccount,
            groupnum: this.props.receipt.crgroupnum,
            name: this.state.name,
            paytype: this.state.paytype.value,
            userdate: formatDate(this.state.selectedDate),
            singleoffsetaccount: this.state.offsetAcct.value,
            proj: this.props.distribution.proj,
        })

        try {
            await this.api.editExistingTemporaryCashReceipt(edittedData)
            await this.api.postTemporaryCashReceipt({
                fiscalMonth: formatFiscalMonth(this.state.fiscalMonth),
                PostingProcessType: 'reviseTransaction',
            })

            this.props.updateCashReceipt()
            displayAlertSuccess('Successfully updated cash receipt.')

            setTimeout(() => {
                this.props.hideModal()
            }, 2000)
        } catch (error) {
            displayAlertError(getErrorMessage(error))
        }
        this.dMloader(false)
    }

    getMapTypeValue(selectedId) {
        const type = [[2004], [2002, 2013], [2006, 2017], [2000, 2011, 2015]]
        return type.findIndex((subArray) => subArray.includes(selectedId))
    }

    handleContentCRType() {
        switch (this.state.type.value) {
            case 0:
                return (
                    <Col lg={5}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-100px">
                                <Form.Label className="mb-0">
                                    <strong>Prp/Doc No.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    defaultValue={this.state.propnum}
                                    name="propnum"
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                )
            case 2:
            case 4:
                return (
                    <Col lg={5}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-100px">
                                <Form.Label className="mb-0">
                                    <strong>Invoice No.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    defaultValue={this.state.payingtxnum}
                                    name="payingtxnum"
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                )
            case 3:
                return (
                    <Col lg={5}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-100px">
                                <Form.Label className="mb-0">
                                    <strong>Offset Account</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    name="payType"
                                    options={[]}
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={{
                                        value: this.state.offsetAcct.value,
                                        label: this.state.offsetAcct.label,
                                    }}
                                    menuPosition={'fixed'}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                )
        }
    }

    content() {
        return (
            <>
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-4">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="mb-2 mb-lg-0 px-5">
                                    <h6 className="fw-bold mb-0">
                                        Receipt Information
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={12} className="pt-4 pb-2">
                    <Col lg={4}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col lg={3} className="mb-2 mb-lg-0 pr-0">
                                <Form.Label className="mb-0 w-45px">
                                    <strong>Date</strong>
                                </Form.Label>
                            </Col>
                            <Col
                                lg={9}
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: '15px',
                                }}
                            >
                                <DatePicker
                                    selected={this.state.selectedDate}
                                    showMonthDropdown="true"
                                    showYearDropdown="true"
                                    onChange={(e) => {
                                        this.handleDateChange(e, 'selectedDate')
                                    }}
                                    className="form-control w-100"
                                    placeholderText="Select date"
                                    isDisabled={this.state.isLoading}
                                    name="receiptDate"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col
                                lg={4}
                                className="mb-2 mb-lg-0 text-lg-end px-0"
                            >
                                <Form.Label className="mb-0">
                                    <strong>Payment Type</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    name="paytype"
                                    options={this.state.paymentTypes}
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.paytype}
                                    menuPosition={'fixed'}
                                    isDisabled={this.state.isLoading}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col lg={4} className="mb-2 mb-lg-0 px-0 w-70px">
                                <Form.Label className="mb-0">
                                    <strong>Check No.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                    defaultValue={this.state.checkno}
                                    onChange={this.handleChange}
                                    name="checkno"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={12} className="py-4">
                    <Col lg={6}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-120px">
                                <Form.Label className="mb-0">
                                    <strong>Deposit Slip No.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                    defaultValue={this.state.depositslip}
                                    onChange={this.handleChange}
                                    name="depositslip"
                                    maxLength="7"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 px-0 w-100px">
                                <Form.Label className="mb-0">
                                    <strong>Fiscal Month</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <DatePicker
                                    selected={this.state.fiscalMonth}
                                    onChange={(e) => {
                                        this.handleDateChange(e, 'fiscalMonth')
                                    }}
                                    showMonthYearPicker
                                    showMonthDropdown="true"
                                    showYearDropdown="true"
                                    className="form-control"
                                    dateFormat={'MM/yyyy'}
                                    disabled={this.state.isLoading}
                                    name="fiscalmonth"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-4">
                        <Col>
                            <Row className="align-items-center">
                                <Col className="mb-2 mb-lg-0 px-5">
                                    <h6 className="fw-bold mb-0">
                                        Payment Distribution
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row xs={1} lg={12} className="pt-4 pb-2">
                    <Col lg={5}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-70px">
                                <Form.Label className="mb-0">
                                    <strong>Type</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Select
                                    key={`${Math.floor(
                                        Math.random() * 1000
                                    )}-min`}
                                    name="type"
                                    options={this.transactionType}
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.type}
                                    menuPosition={'fixed'}
                                    isDisabled={true}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={6}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-100px">
                                <Form.Label className="mb-0">
                                    <strong>Trans Desc.</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                    defaultValue={this.state.desc}
                                    name="desc"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row lg={12} xs={1} className="py-2">
                    <Col lg={7}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-70px">
                                <Form.Label className="mb-0">
                                    <strong>
                                        {this.state.type.value === 3
                                            ? 'Name'
                                            : 'Project'}
                                    </strong>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                {this.state.type.value === 3 ? (
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                        name="name"
                                    />
                                ) : (
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        name="payType"
                                        options={[]}
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={{
                                            value: this.state.proj,
                                            label: this.state.proj,
                                        }}
                                        menuPosition={'fixed'}
                                        isDisabled={true}
                                        styles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row lg={12} xs={1} className="py-2">
                    <Col lg={5}>
                        <Row lg={12} xs={1} className="align-items-center">
                            <Col className="mb-2 mb-lg-0 pr-0 w-70px">
                                <Form.Label className="mb-0">
                                    <strong>Amount</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Form.Control
                                    type="text"
                                    maxLength={9}
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                    onBlur={this.handleAmountBlur}
                                    placeholder="Please enter"
                                    name="amount"
                                />
                            </Col>
                        </Row>
                    </Col>

                    {this.handleContentCRType()}
                </Row>
            </>
        )
    }

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    show={this.props.show}
                    aria-labelledby="action-modal"
                    className="a-modal mx-sm-5 mx-lg-0"
                    backdrop="static"
                    centered
                    onHide={this.props.hideModal}
                >
                    <Modal.Header className="px-4" closeButton>
                        <Modal.Title className="ms-auto h6">
                            {`Cash Receipt [TX# ${this.props.distribution.txnum}]`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.content()}</Modal.Body>
                    <Modal.Footer className="bg-ivory py-4">
                        <Row>
                            <Col className="align-items-end">
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={this.handleSave}
                                >
                                    OK
                                </Button>
                                <Button
                                    className="ml-2"
                                    variant="primary"
                                    onClick={this.props.hideModal}
                                >
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default WithRouter(EditCashReceiptModal)
