const TIME_BILLING_TIERS: ReadonlyArray<{
    value: number
    label: string
}> = [
    { value: 0, label: 'Tier 1' },
    { value: 1, label: 'Tier 2' },
    { value: 2, label: 'Tier 3' },
]

export { TIME_BILLING_TIERS }
