import { TReconciliation } from 'lib/api/HttpService'
import React, { Dispatch, SetStateAction } from 'react'
import { Form } from 'react-bootstrap'
import { TCheckbookReconcileSort } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryTypes'
import { CheckbookHistoryTHSpan } from 'templates/modules/accounts-payable/checkbook/CheckbookHistory/partials/CheckbookHistoryTHSpan'

type TCheckbookHistoryTableHeaderProps = {
    searches: TReconciliation
    searching: boolean
    setSearches: Dispatch<SetStateAction<TReconciliation>>
    setSort: Dispatch<SetStateAction<TCheckbookReconcileSort>>
    sort: TCheckbookReconcileSort
}

export const CheckbookHistoryTableHeader = ({
    searches,
    searching,
    setSearches,
    setSort,
    sort,
}: TCheckbookHistoryTableHeaderProps) => {
    const onSearchChange = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setSearches((prev: TReconciliation) => {
            return { ...prev, [name]: value }
        })
    }

    const [sortField, sortDirection] = sort

    const onSpanClick = (field: string) => {
        if (sortField === field) {
            setSort((prev) => [field, prev[1] === 'asc' ? 'desc' : 'asc'])
        } else {
            setSort([field, 'desc'])
        }
    }

    return (
        <thead className="sticky-top">
            <tr className="a-table-heading">
                <th>
                    <CheckbookHistoryTHSpan
                        field="ccaccount"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Account
                    </CheckbookHistoryTHSpan>
                </th>
                <th>
                    <CheckbookHistoryTHSpan
                        field="statementdate"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Statement Date
                    </CheckbookHistoryTHSpan>
                </th>
                <th>
                    <CheckbookHistoryTHSpan
                        field="statementbalance"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Statement Balance
                    </CheckbookHistoryTHSpan>
                </th>
                <th>
                    <CheckbookHistoryTHSpan
                        field="bookbalance"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Book Balance
                    </CheckbookHistoryTHSpan>
                </th>
                <th>
                    <CheckbookHistoryTHSpan
                        field="computerdate"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Completed On
                    </CheckbookHistoryTHSpan>
                </th>
                <th>
                    <CheckbookHistoryTHSpan
                        field="userName"
                        onSpanClick={onSpanClick}
                        sortField={sortField}
                        sortDirection={sortDirection}
                    >
                        Username
                    </CheckbookHistoryTHSpan>
                </th>
            </tr>
            {searching && (
                <tr className="a-table-search-fields">
                    <th></th>
                    <th>
                        <Form.Control
                            type="text"
                            name="statementdate"
                            onChange={onSearchChange}
                            value={searches.statementdate}
                        />
                    </th>
                    <th>
                        <Form.Control
                            type="text"
                            name="statementbalance"
                            onChange={onSearchChange}
                            value={searches.statementbalance}
                        />
                    </th>
                    <th>
                        <Form.Control
                            type="text"
                            name="bookbalance"
                            onChange={onSearchChange}
                            value={searches.bookbalance}
                        />
                    </th>
                    <th>
                        <Form.Control
                            type="text"
                            name="computerdate"
                            onChange={onSearchChange}
                            value={searches.computerdate}
                        />
                    </th>
                    <th>
                        <Form.Control
                            type="text"
                            name="userName"
                            onChange={onSearchChange}
                            value={searches.userName}
                        />
                    </th>
                </tr>
            )}
        </thead>
    )
}

CheckbookHistoryTableHeader.displayName = 'CheckbookHistoryTableHeader'
