const getItemDescription = (
    name = '',
    {
        value = '',
        html = '',
    }: {
        value: string
        html: string
    }
) => {
    // Need to do a require because this module doesn´t have any types
    /* eslint-disable @typescript-eslint/no-var-requires */

    const HtmlToRtfBrowser = require('html-to-rtf-browser').default
    const formatter = new HtmlToRtfBrowser()
    const descOnly = value
    const descOnlyRtf = formatter
        .convertHtmlToRtf(html ?? '')
        .replaceAll('\\~', ' ')

    const descrtf = descOnlyRtf?.replace(
        '{\\pard',
        `{\\pard ${name?.replace(' ', '\\~')}\\line`
    )
    const desc = `${name ?? ''} ${value ?? ''}`

    return { descOnly, descOnlyRtf, desc, descrtf }
}

export default getItemDescription
