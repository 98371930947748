import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    Breadcrumb,
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import DatePicker from 'react-datepicker'
import InputBar from '../../../components/InputBar'
import JournalEntryAddDialog from './JournalEntryAddDialog'
import { ApiService } from '../../../../lib/api/HttpService'
import {
    displayAlert,
    displayAlertLoader,
    hideAlertLoader,
} from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import _, { delay, update } from 'lodash'
import { Is } from '../../../../helpers/Util'
import { setDraft } from '../../../../utilities/FormEvent'
import MSG from '../../../../defaults/Message'
import { currencyFormat } from '../../../../helpers/Number'

class JournalEntryEdit extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            jeDate: new Date(),
            jeDesc: '',
            data: [],
            fiscalMonth: new Date(),
            autorev: false,
            transactionNumber: '',
            jeNum: '',
            isShowing: false,
            netDebitAndCredit: 0,
            totalChecked: 0,
            checks: [],
            editData: {},
            currentEditIndex: null,
            transactionID: 0,
            existAccounts: [],
            isSaving: false,
            deletedAccounts: [],
        }
        this.api = new ApiService()
        this.userCode = getCookie('dmUsercode')
        this.isExistingList = this.props.params.type === 'existing'
        this.setIsLoaded(true)
    }

    async componentDidMount() {
        setDraft(false)
        this.dMloader(true)
        try {
            const journalEntry = await this.getJournalEntry()

            if (Is.empty(journalEntry) && this.props.params.id) {
                throw {
                    response: {
                        status: 404,
                    },
                }
            }

            if (Object.values(journalEntry).length) {
                const journalEntryDetails = await this.getJournalEntryDetails()
                await this.initData(journalEntry, journalEntryDetails)
            }
        } catch (error) {
            this.setError(error).setIsFound(false).setIsLoaded(true)
            this.setState({
                stateUpdated: Math.floor(Math.random() * 10000),
            })
        }
        this.dMloader(false)

        let autorev = localStorage.getItem(
            'autorev-' + this.state.transactionNumber
        )
        if (autorev !== null) {
            this.setState({ autorev: autorev === 'true' })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.recomputeCreditAndDebit()
        }
    }

    async getJournalEntry() {
        let result =
            this.props.params.type === 'new'
                ? await this.api.get(
                      'JournalEntries/new',
                      `?$filter=txnum eq ${this.props.params.id}`
                  )
                : await this.api.get(
                      'JournalEntries/existing',
                      `?$filter=txnum eq ${this.props.params.id}`
                  )

        return result.length ? result[0] : result
    }

    async getJournalEntryDetails() {
        let txnum = this.props.params.id

        try {
            if (this.props.params.type === 'existing') {
                const transaction = await this.api.getTransactionCounterNext(
                    'txNextGLJE'
                )
                this.newTransactionNumber = transaction.newTransactionNumber
                txnum = transaction.newTransactionNumber
                await this.api.createTemporaryJournalDeitalsPopulatedDistrbution(
                    {
                        sourceTXNum: this.props.params.id,
                        destTXNum: transaction.newTransactionNumber,
                    }
                )
            }
        } catch {
            /* empty */
        }

        return this.api.getTempJournalEntryDetails(`?$filter=txnum eq ${txnum}`)
    }

    async initData(je, jeSubItems = []) {
        let existAccounts = []

        jeSubItems.forEach((item) => {
            existAccounts.push(item.account)
        })

        if (this.props.params.type === 'new') {
            this.setState({
                jeDate: new Date(je.jedate),
                fiscalMonth: je?.fiscalmonth ? new Date(je.fiscalmonth) : null,
                transactionNumber: je.txnum,
                jeNum: je.jenum,
                jeDesc: je.txdesc,
                autorev: je.autorev,
                data: this.formatSubJournalEntries(jeSubItems),
                existAccounts,
            })
        } else {
            this.setState({
                jeDate: je?.userDate ? new Date(je.userDate) : null,
                fiscalMonth: je?.fiscalMonth ? new Date(je.fiscalMonth) : null,
                transactionNumber: je.txnum,
                jeNum: je.journalEntryNumber,
                jeDesc: je.transactionDescription,
                autorev: je.autorev,
                data: this.formatSubJournalEntries(jeSubItems),
                existAccounts,
            })
        }
    }

    formatSubJournalEntries(items) {
        return items.map((item) => {
            return {
                account: item.account,
                accountName: item.accountName,
                isDebit: item.damt !== 0,
                amount: item.damt !== 0 ? item.damt : item.camt,
                txnum: item.txnum,
            }
        })
    }

    handleModalData = (entry) => {
        setDraft(true)
        entry.txnum = parseInt(this.state.transactionNumber)
        this.setState((prevState) => ({
            data: [...prevState.data, entry],
        }))
    }

    handleDeleteClick = async (e) => {
        if (this.state.checks.length) {
            const deletedData = this.state.data.filter((val, idx) =>
                this.state.checks.includes(val.account)
            )

            const remainingData = this.state.data.filter(
                (val, idx) => !this.state.checks.includes(val.account)
            )

            this.setState(
                {
                    data: remainingData,
                    totalChecked: 0,
                    checks: [],
                },
                () => setDraft(true)
            )

            if (deletedData.length) {
                const deletedDataAccounts = deletedData.map(
                    (data) => data.account
                )
                this.setState((prev) => {
                    const accounts = _.uniq([
                        ...prev.deletedAccounts,
                        ...deletedDataAccounts,
                    ])

                    prev.deletedAccounts = accounts
                    return prev
                })
            }
        }
    }

    checkIfDataHasAccount(account) {
        return this.state.data.filter((data) => data.account === account).length
    }

    async deleteAccounts() {
        const toDeleteAccounts = this.state.deletedAccounts.filter(
            (data, i) =>
                this.state.existAccounts.includes(data) &&
                !this.checkIfDataHasAccount(data)
        )

        let errorAccounts = []

        if (toDeleteAccounts.length) {
            displayAlert('light', {
                message: 'Deleting entries...',
                icon: 'loader',
                timeout: -1,
            })
            for (const account of toDeleteAccounts) {
                const postData = {
                    usercode: this.userCode,
                    txnum: this.state.transactionNumber,
                    account: account,
                }

                await this.api
                    .deleteTempJournalEntryDetails(postData)
                    .catch((err) => {
                        errorAccounts.push(account)
                    })
            }

            const message = errorAccounts.length
                ? 'The data with the accounts [' +
                  errorAccounts.join() +
                  "] couldn't be deleted"
                : 'The data was succesfully deleted'
            const variant = errorAccounts.length ? 'danger' : 'success'

            displayAlert(variant, message)
        }

        return errorAccounts
    }

    recomputeCreditAndDebit = () => {
        let netDebitAndCredit = 0
        if (this.state.data.length) {
            this.state.data.map((obj) => {
                netDebitAndCredit = parseFloat(
                    (obj.isDebit
                        ? parseFloat(netDebitAndCredit) + parseFloat(obj.amount)
                        : parseFloat(netDebitAndCredit) - parseFloat(obj.amount)
                    ).toFixed(2)
                )
            })
        }

        netDebitAndCredit === 0
            ? Math.abs(netDebitAndCredit)
            : netDebitAndCredit

        this.setState((prevState) => ({
            netDebitAndCredit,
        }))
    }

    handleUpdateData = (updatedData) => {
        const newData = _.cloneDeep(this.state.data)
        newData[this.state.currentEditIndex] = {
            account: updatedData.account,
            newaccount: updatedData.newaccount,
            accountName: updatedData.accountName,
            amount: updatedData.amount,
            isDebit: updatedData.isDebit,
            txnum: parseInt(this.state.transactionNumber),
        }

        this.setState({
            data: newData,
            editData: {},
        })
    }

    handleNewUpdateData = async () => {
        const jeData = {
            usercode: getCookie('dmUsercode'),
            txnum: this.state.transactionNumber,
            jenum: this.state.jeNum,
            txdesc: this.state.jeDesc,
            jedate: new Date(this.state.jeDate),
            fiscalMonth: this.state.fiscalMonth,
            autorev: this.state.autorev,
        }

        if (this.hasDataError() || (await this.deleteAccounts()).length) {
            return
        }

        displayAlertLoader(MSG.loading.update.msg)
        this.setState({
            isSaving: true,
        })

        let hasError = false

        for (const item of this.state.data) {
            const postData = {
                usercode: this.userCode,
                txnum: item?.txnum,
                account: item.account,
                damt: item.isDebit ? item.amount : 0,
                camt: item.isDebit ? 0 : item.amount,
            }

            if (this.state.existAccounts.includes(item.account)) {
                // for update existing entry
                postData.newaccount = item.newaccount
                await this.api
                    .updateJournalEntryDetails(postData)
                    .catch((error) => {
                        hasError = true
                        displayAlert('danger', error.response.data.userError)
                    })
            } else {
                // create new entry
                await this.api
                    .createJournalEntryDetails(postData)
                    .catch((error) => {
                        hasError = true
                        displayAlert('danger', error.response.data.userError)
                    })
            }
        }

        await this.api.updateJournalEntry(jeData).catch((error) => {
            hasError = true
            displayAlert('danger', error.response.data.userError)
        })

        // Remove loader first.
        hideAlertLoader()

        if (!hasError) {
            setDraft(false)
            displayAlert('success', MSG.success.msg)
            delay(() => {
                this.props.navigate(URI.generalLedger.journalEntry.listNew)
            }, 800)
        } else {
            this.setState({
                isSaving: false,
            })
        }
    }

    hasDataError() {
        if (!this.state.data || this.state.data.length < 2) {
            displayAlert('danger', MSG.error.GLJe.editReqTwo)

            return true
        }

        if (this.state.netDebitAndCredit !== 0) {
            displayAlert('danger', MSG.error.GLJe.editNotBalanced)

            return true
        }

        if (!this.state.jeDate) {
            displayAlert('danger', `Please enter a valid date.`)
            return true
        }

        return false
    }

    handleExistingUpdateData = async () => {
        if (!this.newTransactionNumber) {
            return
        }

        const jeData = {
            usercode: getCookie('dmUsercode'),
            txnum: this.state.transactionNumber,
            jenum: this.state.jeNum,
            txdesc: this.state.jeDesc,
            jedate: this.state.jeDate,
            fiscalmonth: this.state.fiscalMonth,
            autorev: this.state.autorev,
        }

        if (this.hasDataError() || (await this.deleteAccounts()).length) {
            return
        }

        this.dMloader(true)

        try {
            jeData.revisestxnum = jeData.txnum
            jeData.txnum = this.newTransactionNumber
            jeData.jenum = this.state.jeNum

            for (const item of this.state.data) {
                const postData = {
                    usercode: getCookie('dmUsercode'),
                    txnum: this.newTransactionNumber,
                    account: item.account,
                    jenum: this.state.jeNum,
                    damt: item.isDebit ? item.amount : 0,
                    camt: item.isDebit ? 0 : item.amount,
                }

                if (this.state.existAccounts.includes(item.account)) {
                    // for update existing entry
                    postData.newaccount = item.newaccount
                    await this.api.updateJournalEntryDetails(postData)
                } else {
                    // create new entry
                    await this.api.createJournalEntryDetails(postData)
                }
            }

            const entry = await this.api.createJournalEntry(jeData)
            try {
                await this.api.createJournalEntryRevise()
            } catch {
                this.api.deleteTempJournalEntries(
                    `?UserCode=${this.userCode}&TxNum=${entry.txnum}`
                )
            } finally {
                displayAlert('success', MSG.success.msg)
                delay(() => {
                    this.props.navigate(
                        URI.generalLedger.journalEntry.listExisting
                    )
                }, 800)
            }
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        }

        this.dMloader(false)
    }

    handleSelectAllChecks = (e) => {
        let checks = []
        // Fetch item.account if checked.
        if (e.target.checked) {
            checks = this.state.data.map((item, i) => {
                return item.account
            })
        }

        this.setState({
            checks: checks,
            totalChecked: checks.length,
        })
    }

    handleCheck = (item, id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === item.account)

        index > -1 ? checks.splice(index, 1) : checks.push(item.account)

        this.setState({
            checks: checks,
            totalChecked: checks.length,
        })
    }

    componentInit() {
        this.title = 'Edit Journal Entry'
        this.setFormAction(true)
    }

    handleDateChange = (date, field) => {
        this.setState(
            {
                [field]: date ? new Date(date) : '',
            },
            () => setDraft(true)
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={
                            this.isExistingList
                                ? URI.generalLedger.journalEntry.listExisting
                                : URI.generalLedger.journalEntry.listNew
                        }
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.home }}
                            linkAs={Link}
                        >
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: this.isExistingList
                                    ? URI.generalLedger.journalEntry
                                          .listExisting
                                    : URI.generalLedger.journalEntry.listNew,
                            }}
                            linkAs={Link}
                        >
                            Journal Entries
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.props.params.id}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>Edit Journal Entry</HeaderLight.Title>

                    <div>
                        <Button
                            variant="ivory"
                            name="cancel"
                            className="mx-3"
                            href={
                                this.props.params.type === 'existing'
                                    ? URI.generalLedger.journalEntry
                                          .listExisting
                                    : URI.generalLedger.journalEntry.listNew
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={
                                this.props.params.type === 'existing'
                                    ? this.handleExistingUpdateData
                                    : this.handleNewUpdateData
                            }
                            variant="primary"
                            disabled={this.state.isSaving}
                        >
                            Update
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderInputBar() {
        return this.props.params.type !== 'new' ? (
            <InputBar className="small">
                <InputBar.Links>
                    <InputBar.Link>
                        <Form.Label className="ilabel">
                            Net Debits and Credits
                        </Form.Label>
                        <div className="form-group-extra">
                            <Form.Control
                                type="text"
                                placeholder="0.00"
                                size="sm"
                                value={parseFloat(
                                    this.state.netDebitAndCredit
                                ).toFixed(2)}
                                readOnly
                            />
                            <span>USD</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Row xs={12} lg={12} className="align-items-center">
                            <Col lg={3}>
                                <Form.Label className="text-end">
                                    <strong>Fiscal Month</strong>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <div className="react-select-header">
                                    <DatePicker
                                        selected={this.state.fiscalMonth}
                                        onChange={(date) => {
                                            this.handleDateChange(
                                                date,
                                                'fiscalMonth'
                                            )
                                        }}
                                        className="form-control"
                                        placeholderText="Please select"
                                        showMonthYearPicker="true"
                                        dateFormat="MM/yyyy"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        ) : (
            <InputBar className="xsmall">
                <InputBar.Links>
                    <InputBar.Link>
                        <Form.Label className="ilabel">
                            Net Debits and Credits
                        </Form.Label>
                        <div className="form-group-extra">
                            <Form.Control
                                type="text"
                                placeholder="0.00"
                                size="sm"
                                value={parseFloat(
                                    this.state.netDebitAndCredit
                                ).toFixed(2)}
                                readOnly
                            />
                            <span>USD</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link style={{ width: '180px' }}>
                        <Form.Check
                            inline
                            label="Auto Reverse"
                            name={`group-inputbar`}
                            type="checkbox"
                            id={`group-inputbar`}
                            onChange={(e) => {
                                this.setState(
                                    { autorev: e.target.checked },
                                    () => {
                                        setDraft(true)
                                        localStorage.setItem(
                                            'autorev-' +
                                                this.state.transactionNumber,
                                            e.target.checked
                                        )
                                    }
                                )
                            }}
                            checked={this.state.autorev}
                        />
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderContent() {
        return (
            <Form.Group controlId="formGridText1">
                <Row xs={1} lg={2} className="mb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Entry No.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col className="mb-3 mb-lg-0">
                                        <Form.Control
                                            type="text"
                                            data-field="jenum"
                                            value={this.state.jeNum}
                                            placeholder="Entry No."
                                            maxLength={5}
                                            onChange={(e) =>
                                                this.setState({
                                                    jeNum: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <div className="react-select-header">
                                            <DatePicker
                                                selected={this.state.jeDate}
                                                onChange={(date) => {
                                                    this.handleDateChange(
                                                        date,
                                                        'jeDate'
                                                    )
                                                }}
                                                showMonthDropdown="true"
                                                showYearDropdown="true"
                                                className="form-control"
                                                placeholderText="Select Entry Date"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Description
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter description"
                                    defaultValue={this.state.jeDesc}
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                jeDesc: e.target.value,
                                            },
                                            () => setDraft(true)
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*{this.props.params.type === 'new' && (*/}
                <>
                    <Row className="mb-5 pb-5">
                        <Col
                            sm={12}
                            className="mb-3 mb-lg-0 position-relative table-action-bar"
                        >
                            {/* ---------- section */}
                            <div className="bg-secondary-grey px-4">
                                <Row
                                    xs={'auto'}
                                    className="py-4 justify-content-end align-items-center"
                                >
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Button
                                                    to="#"
                                                    variant="ivory"
                                                    size="sm"
                                                    className={`btn-icon btn-action ${
                                                        this.state.checks
                                                            .length === 0
                                                            ? 'disabled'
                                                            : ''
                                                    }`}
                                                    disabled={
                                                        this.state.checks
                                                            .length === 0
                                                    }
                                                    onClick={
                                                        this.handleDeleteClick
                                                    }
                                                >
                                                    <i className="ri-close-line"></i>{' '}
                                                    Delete
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className={`btn-icon btn-action`}
                                                    onClick={() => {
                                                        this.setState({
                                                            isShowing: true,
                                                        })
                                                    }}
                                                >
                                                    <i className="ri-add-line"></i>{' '}
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr key="0">
                                            <th
                                                align="middle"
                                                className="w-70px"
                                            >
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        inline
                                                        label=""
                                                        name={`inline-check-th-0`}
                                                        type="checkbox"
                                                        data-id={`th-0`}
                                                        id={`inline-check-th-0`}
                                                        checked={
                                                            this.state
                                                                .totalChecked >
                                                            0
                                                                ? true
                                                                : false
                                                        }
                                                        className={`${
                                                            this.state
                                                                .totalChecked >
                                                                0 &&
                                                            this.state
                                                                .totalChecked <
                                                                this.state.data
                                                                    .length
                                                                ? 'line'
                                                                : ''
                                                        }`}
                                                        onClick={
                                                            this
                                                                .handleSelectAllChecks
                                                        }
                                                    />
                                                </div>
                                            </th>
                                            <th>Account No.</th>
                                            <th>Account Name</th>
                                            <th>Debit Amount</th>
                                            <th>Credit Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((item, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            label=""
                                                            type="checkbox"
                                                            checked={this.state.checks.includes(
                                                                item.account
                                                            )}
                                                            onChange={() => {
                                                                this.handleCheck(
                                                                    item,
                                                                    i
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        onClick={() => {
                                                            this.setState({
                                                                isShowing: true,
                                                                editData: item,
                                                                currentEditIndex:
                                                                    i,
                                                            })
                                                        }}
                                                        className="text-decoration-underline text-charcoal hover-view-icon"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {item.newaccount ??
                                                            item.account}
                                                    </a>
                                                </td>
                                                <td>{item.accountName}</td>
                                                <td>
                                                    {currencyFormat(
                                                        item.isDebit
                                                            ? item.amount
                                                            : 0
                                                    )}
                                                </td>
                                                <td>
                                                    {currencyFormat(
                                                        !item.isDebit
                                                            ? item.amount
                                                            : 0
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </>
                {/*)}*/}
                <Row>
                    <Col>{this.renderInputBar()}</Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return this.renderView(
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        onClick={
                            this.props.params.type === 'existing'
                                ? this.handleExistingUpdateData
                                : this.handleNewUpdateData
                        }
                        variant="primary"
                        size="lg"
                        disabled={this.state.isSaving}
                    >
                        Update
                    </Button>
                </FooterFormAction>

                <>
                    {this.state.isShowing && (
                        <JournalEntryAddDialog
                            editData={this.state.editData}
                            currentEditIndex={this.state.currentEditIndex}
                            isShowing={this.state.isShowing}
                            netDebAndCred={this.state.netDebitAndCredit}
                            onOkay={this.handleModalData}
                            onCancel={() => {
                                this.setState({
                                    isShowing: false,
                                    editData: {},
                                })
                            }}
                            dataCreated={this.state.data}
                            updatedData={this.handleUpdateData}
                            existingAccounts={this.state.existAccounts}
                        />
                    )}
                </>
            </>
        )
    }
}

export default WithRouter(JournalEntryEdit)
