import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { useEffect } from 'react'
import { displayAlertError } from 'utilities/Response'
import { TProcessor } from '../types/PaymentProcessors'
import { TPaymentProcessorResponse } from '../types/PaymentProcessorResponse'

const api = new ApiService()

const getPaymentProcessorStatus = async (
    processor: TProcessor
): Promise<TPaymentProcessorResponse> => {
    await api.connectPaymentProcessor(processor)
    const processorInfo = await api.getPaymentProcessors(processor)

    return processorInfo
}

const useGetPaymentProcessorInfo = (
    processor: TProcessor,
    options?: UseQueryOptions<
        TPaymentProcessorResponse,
        unknown,
        TPaymentProcessorResponse
    >
) => {
    const processorQuery = useQuery({
        refetchOnWindowFocus: false,
        queryKey: [processor, 'payments'],
        queryFn: () => getPaymentProcessorStatus(processor),
        ...(options || {}),
    })

    useEffect(() => {
        if (processorQuery.error) {
            displayAlertError(
                'An error occurred fetching the payment processor info, please try again'
            )
        }
    }, [processorQuery.error])

    return processorQuery
}
export default useGetPaymentProcessorInfo
