import SecureLink from 'app/components/security/SecureLink'
import { SECURITY_ATTRIBUTE_TYPES } from 'app/context/security'
import URI from 'defaults/RoutesDefault'
import { currencyFormat } from 'helpers/Number'
import { truncateCharCount } from 'helpers/String'
import { Table, Button } from 'react-bootstrap'
import { Link, LinkProps, generatePath, useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import useGetComponents from 'lib/api/hooks/useGetComponents'
import useGetCompany from 'lib/api/hooks/useGetCompanyInfo'
import React from 'react'
import getCompanyTypeMap from 'utilities/getComponentTypeMap'
import useDeleteObject from 'lib/api/hooks/useDeleteObject'
import { DeleteType } from 'lib/api/types/DeleteType'
import { DeleteObjectTypes } from 'lib/api/types/DeleteObjectTypes'
import PurchaseComponentsButton from './PurchaseComponentsButton'
import ComponentsDeleteWarningModal from './ComponentsDeleteWarningModal'
import { displayAlertSuccess } from 'utilities/Response'
import { Component } from 'lib/api/types/Component'
import { CompNumericPercentType } from 'lib/api/types/CompPercentType'
import ComponentsDeleteConfirmationModal from 'app/components/ComponentDeleteConfirmationModal/ComponentsDeleteConfirmationModal'
import SecureBootstrapButton from 'app/components/security/SecureBootstrapButton'

export type TFailedComponentRequest = {
    comp: string
    message: string
}

type TCustomBootstrapLinkType = React.ComponentClass<LinkProps> &
    keyof JSX.IntrinsicElements

const PurchaseComponentsTable = ({
    isEdit,
    submitMethods,
}: {
    isEdit: boolean
    submitMethods: () => Promise<void>
}) => {
    const [checkedItems, setCheckedItems] = useState<{
        [key: number]: boolean
    }>({})

    const [failedComponentsDelete, setFailedComponentsDelete] = useState<
        TFailedComponentRequest[]
    >([])

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const [showFailedComponentsnModal, setShowFailedComponentsModal] =
        useState(false)

    const [disableAllInputsAndButtons, setDisableAllInputsAndButtons] =
        useState(false)

    const { id: projectId, itemId } = useParams()

    const { mutateAsync, isLoading: isDeleting } = useDeleteObject()

    const {
        data: components,
        isFetching: isFetchingComponents,
        refetch: fetchComponents,
    } = useGetComponents(itemId as string, projectId as string)

    const disableInputs =
        disableAllInputsAndButtons || isFetchingComponents || isDeleting

    const { data: company } = useGetCompany({
        enabled: !!itemId && !!projectId,
    })

    const actualCheckedItems = Object.keys(checkedItems).filter(
        (key) => checkedItems[Number(key)]
    )

    const handleComponentDelete = async () => {
        setDisableAllInputsAndButtons(true)

        const notDeletedComponents: TFailedComponentRequest[] = []
        if (!components) {
            return
        }
        await Promise.allSettled(
            components.map(async (comp) => {
                const component = actualCheckedItems.find(() =>
                    actualCheckedItems.includes(String(comp.id))
                )
                if (component) {
                    try {
                        const canBeDeleted = await mutateAsync({
                            deleteType: DeleteType.dmriTestOnly,
                            objectType: DeleteObjectTypes.objComponent,
                            objectCodeOrId: comp.id,
                        })

                        if (canBeDeleted.fullDeletePossible) {
                            await mutateAsync({
                                deleteType:
                                    DeleteType.dmriTestAndFullDeleteIfPossible,
                                objectType: DeleteObjectTypes.objComponent,
                                objectCodeOrId: comp.id,
                            })
                            setCheckedItems({
                                [comp.id]: false,
                            })
                            return
                        }
                        notDeletedComponents.push({
                            comp: comp.comp,
                            message: canBeDeleted.message,
                        })
                    } catch {
                        notDeletedComponents.push({
                            comp: comp.comp,
                            message: 'Network error, please try again',
                        })
                    }
                }
            })
        )
        setFailedComponentsDelete(notDeletedComponents)

        if (notDeletedComponents.length) {
            setShowFailedComponentsModal(true)
        }

        if (!notDeletedComponents.length) {
            displayAlertSuccess('Components deleted successfully!')
        }

        const newComponents = await fetchComponents()

        const newCheckedItems: {
            [key: number]: boolean
        } = {}

        newComponents.data?.map((comp) => {
            if (checkedItems[comp.id]) {
                newCheckedItems[comp.id] = true
            }
        })

        setCheckedItems(newCheckedItems)
        setDisableAllInputsAndButtons(false)
    }

    const checkOrUnCheckAll = () => {
        if (actualCheckedItems.length === components?.length) {
            return setCheckedItems({})
        }
        if (components?.length) {
            const allCheckedItems = components.reduce((prev, curr) => {
                const checkedItems = {
                    ...prev,
                    [curr.id]: true,
                }
                return checkedItems
            }, {})

            setCheckedItems(allCheckedItems)
        }
    }

    return (
        <div className="tw-max-w-full tw-p-4">
            <div className="tw-flex tw-justify-between tw-items-center tw-p-7 tw-bg-[#EEEDE9]">
                <p className="tw-text-base tw-font-bold tw-m-0">
                    Purchase Components Information
                </p>
                <div className="tw-flex tw-gap-2">
                    <SecureBootstrapButton
                        attributeNo={14}
                        attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                        as={Link}
                        to="#"
                        variant="ivory"
                        size="sm"
                        disabled={Object.keys(checkedItems).length === 0}
                        onClick={() => setShowConfirmationModal(true)}
                    >
                        <i className="ri-close-line"></i> Delete
                    </SecureBootstrapButton>
                    <PurchaseComponentsButton
                        components={components as Component[]}
                        disableDelete={
                            disableInputs || actualCheckedItems.length === 0
                        }
                        disableAdd={disableInputs}
                        submitMethods={submitMethods}
                        onDeleteClick={() => setShowConfirmationModal(true)}
                        isEdit={isEdit}
                    />
                </div>
            </div>

            <ComponentsDeleteWarningModal
                failedComponentsDelete={failedComponentsDelete}
                show={showFailedComponentsnModal}
                confirmAction={() => {
                    setShowFailedComponentsModal(false)
                    setTimeout(() => setFailedComponentsDelete([]), 200)
                }}
            />
            <ComponentsDeleteConfirmationModal
                show={showConfirmationModal}
                toggleModal={() => setShowConfirmationModal(false)}
                confirmAction={() => {
                    setShowConfirmationModal(false)
                    handleComponentDelete()
                }}
            />
            {components && components.length > 0 && (
                <Table striped responsive className="a-table">
                    <thead>
                        <tr>
                            <th className="tw-max-w-16">
                                <div className="tw-flex tw-justify-center">
                                    <Form.Check
                                        disabled={disableInputs}
                                        label=""
                                        onChange={checkOrUnCheckAll}
                                        type="checkbox"
                                        checked={actualCheckedItems.length > 0}
                                        className={
                                            actualCheckedItems.length ===
                                            components?.length
                                                ? ''
                                                : 'line'
                                        }
                                    />
                                </div>
                            </th>
                            <th>Comp.#</th>
                            <th>Description</th>
                            <th>Vendor</th>
                            <th>Type</th>
                            <th>Est. Cost</th>
                            <th>Est. Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {components?.map((item, i) => (
                            <tr key={i} data-id={item.id}>
                                <td>
                                    <div className="tw-flex tw-justify-center">
                                        <Form.Check
                                            label=""
                                            type="checkbox"
                                            disabled={disableInputs}
                                            onChange={(e) =>
                                                setCheckedItems({
                                                    ...checkedItems,
                                                    [item.id]: e.target.checked,
                                                })
                                            }
                                            checked={
                                                checkedItems?.[item.id] || false
                                            }
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="tw-flex tw-justify-center tw-items-center">
                                        <SecureLink
                                            title=""
                                            attributeNo={14}
                                            attributeType={
                                                SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                            }
                                            to={generatePath(
                                                URI.project.newComponentEdit,
                                                {
                                                    itemId,
                                                    id: projectId,
                                                    componentId: item.id,
                                                }
                                            )}
                                            className="tw-text-[#211f21]"
                                        >
                                            {item.comp}
                                        </SecureLink>
                                        {item.numAttachments > 0 && (
                                            <Button
                                                as={
                                                    Link as unknown as TCustomBootstrapLinkType
                                                }
                                                to={generatePath(
                                                    URI.project.item
                                                        .componentEditPage,
                                                    {
                                                        id: item.projectId,
                                                        itemId: item.itemId,
                                                        compId: item.id,
                                                        page: 'documents',
                                                    }
                                                )}
                                                variant="ivory"
                                                size="sm"
                                                className="tw-border-0 fsx-16 ri-attachment-2 square tw-ml-1 tw-no-underline"
                                            />
                                        )}
                                    </div>
                                </td>
                                <td>{truncateCharCount(item.desc, 100)}</td>
                                <td>{item.vendorName}</td>
                                <td>
                                    {company &&
                                        getCompanyTypeMap(company)[
                                            item.comptype
                                        ]}
                                </td>
                                <td>
                                    <div>
                                        {currencyFormat(
                                            item.usedisc ===
                                                CompNumericPercentType.Fee
                                                ? item.feecost
                                                : item.estcost,
                                            '$'
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {currencyFormat(item.estprice, '$')}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    )
}
PurchaseComponentsTable.displayName = 'PurchaseComponentsTable'
export default PurchaseComponentsTable
