import React from 'react'
import {
    Col,
    Row,
    Form,
    Container,
    Breadcrumb,
    Button,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'
import { FooterFormAction } from '../../components/Form'
import URI from '../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import WysiwygEditor from '../../ui-kit/WysiwygEditor'
import MyDropzone from '../../ui-kit/MyDropzone'
import { ActionBarVertical } from '../../components/ActionBar'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../utilities/LocalStorage'
import {
    getBreadcrumbTitle,
    getSaveButtonName,
} from '../../../utilities/String'
import { handleResponseAlert } from '../../../utilities/Response'
import { FormEvent } from '../../../utilities/FormEvent'
import { ApiService } from '../../../lib/api/HttpService'
import { mapRtfToDraft } from '../../../utilities/Rtf'

class GlossaryAddItemGroups extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: { items: [] },
            dataIsLoaded: false,
            isLoading: false,
            showButtons: false,
            isDraft: false,
            notesHtml: '',
        }
        this.api = new ApiService()
        this.formEvent = new FormEvent('glossaryItemGroups', true)
        this.isEditing = Boolean(this.props.params.id)
    }

    async componentDidMount() {
        this.setTitle('Add Item Group | Glossary')
        this.setFormAction(true)

        this.dMloader(true)
        let data = {}
        if (this.props.params.id) {
            let data = await this.api.get(
                'groups',
                `?$filter=id eq ${this.props.params.id}`
            )

            if (data) {
                data[0].items = await this.api.get(
                    'groupitems',
                    `?$filter=groupname eq '${data[0].groupname}'`
                )

                this.setState({
                    dataIsLoaded: true,
                    data: data[0],
                    notesHtml: await mapRtfToDraft(data[0].notesrtf ?? ''),
                })

                this.setTitle('Edit Item Group | Glossary')
                data = data[0]
            }
        }

        setLocalStorage('glossaryItemGroups', data, true)
        setTimeout(() => {
            this.setState({
                showButtons: true,
            })
            this.dMloader(false)
        }, 250)
    }

    setIsLoading(state) {
        this.setState({
            isLoading: state,
        })
    }

    setDraft() {
        setLocalStorage('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
    }

    handleTextChange = (e) => {
        this.setDraft()
        this.formEvent.handleTextChange(e)
    }

    handleWysiwygChange = (name) => (e) => {
        this.setDraft()
        this.formEvent.handleWysiwygChange(name, e, `${name}rtf`)
    }

    handleClickSave = (e) => {
        this.setIsLoading(true)
        setLocalStorage('isDraft', 'false')
        window.onbeforeunload = null

        this.state.data.id ? this.handleUpdate(e) : this.handleCreate(e)
    }

    handleUpdate(e) {
        if (this.state.data && this.state.data.id) {
            const data = getLocalStorage('glossaryItemGroups', true) || `{}`

            const request = this.api.patch('groups', this.state.data.id, data)
            handleResponseAlert(request, (res) => {
                this.setIsLoading(false)

                if (res && !isNaN(res.id)) {
                    window.location.href = getRouteWithParam(
                        URI.glossary.list,
                        { path: 'item-groups' }
                    )
                }
            })
        }
    }

    handleCreate(e) {
        const data = getLocalStorage('glossaryItemGroups', true) || `{}`

        const request = this.api.postJson('groups', data)
        handleResponseAlert(request, (res) => {
            this.setIsLoading(false)

            if (res && !isNaN(res.id)) {
                window.location.href =
                    e.target.name === 'saveNew'
                        ? URI.glossary.add.itemGroups
                        : getRouteWithParam(URI.glossary.list, {
                              path: 'item-groups',
                          })
            }
        })
    }

    handleClick = (e) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            if (confirm('Leaving this screen will discard your changes.')) {
                setLocalStorage('isDraft', 'false')
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    renderHeader() {
        const { data, isLoading, isDraft } = this.state
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        onClick={this.handleClick}
                        to={URI.timeEntry.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{
                                to: getRouteWithParam(URI.glossary.list, {
                                    path: 'item-groups',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Item Groups
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: '#' }}
                            linkAs={Link}
                        >
                            {getBreadcrumbTitle(data, 'groupname', 'Group')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>
                        {getBreadcrumbTitle(data, 'groupname', 'Group')}
                    </HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <Button
                            className={
                                this.props.params.id
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </Button>

                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderActionBarVertical() {
        return (
            <ActionBarVertical>
                <ActionBarVertical.Links>
                    <li>
                        <NavLink
                            to="/"
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-add-box-fill mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/"
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-edit-line mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/"
                            className="d-flex align-items-center mb-2"
                        >
                            <i className="ri-close-line mx-0"></i>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/" className="d-flex align-items-center">
                            <i className="ri-restart-line mx-0"></i>
                        </NavLink>
                    </li>
                </ActionBarVertical.Links>
            </ActionBarVertical>
        )
    }

    renderContent() {
        const { data } = this.state
        return (
            <Container className="ms-0">
                <Row xs={1} lg={2} className="py-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Please enter"
                                    onChange={this.handleTextChange}
                                    name="groupname"
                                    defaultValue={data?.groupname}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Color/Finish
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            onChange={this.handleTextChange}
                                            name="category1"
                                            defaultValue={data?.category1}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Style/Size
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row xs={1} lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please enter"
                                            onChange={this.handleTextChange}
                                            name="category2"
                                            defaultValue={data?.category2}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Description
                                </Form.Label>
                            </Col>
                            <Col>
                                <WysiwygEditor
                                    onChange={this.handleWysiwygChange(
                                        'notes',
                                        this
                                    )}
                                >
                                    {this.state.notesHtml}
                                </WysiwygEditor>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} className="ps-lg-4 mb-4 mb-lg-0">
                        <MyDropzone></MyDropzone>
                    </Col>
                </Row>

                {/* ---------- section */}
                <div className="bg-secondary-grey roundedx-4">
                    <Row xs={1} className="py-3">
                        <Col>
                            <Row className="align-items-center px-4">
                                <Col className="mb-2 mb-lg-0 px-2">
                                    <h6 className="fw-bold mb-0">
                                        Group Items
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Row className="mt-4">
                    <Col
                        xs={12}
                        className="table-actions position-relative table-action-bar"
                    >
                        {this.renderActionBarVertical()}

                        <div className="table-gradient">
                            <Table
                                striped
                                responsive
                                className="a-table table-check"
                            >
                                <thead>
                                    <tr className="a-table-heading">
                                        <th className="w-180px ps-4">
                                            <span className="sort active">
                                                Type
                                            </span>
                                        </th>
                                        <th className="w-250px">
                                            <span className="sort">
                                                Stock/Catalog No.
                                            </span>
                                        </th>
                                        <th className="w-180px">
                                            <span className="sort">
                                                Quantity
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.items.map((item, i) => {
                                        if (i < 3) {
                                            return (
                                                <tr
                                                    key={i}
                                                    data-key={i}
                                                    className={
                                                        this.state.checks[i]
                                                            ? `active`
                                                            : ''
                                                    }
                                                >
                                                    <td className="ps-4">
                                                        {item.type}
                                                    </td>
                                                    <td>{item.stockcatno}</td>
                                                    <td>{item.quantity}</td>
                                                </tr>
                                            )
                                        }

                                        return ''
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>

                <FooterFormAction>
                    <Button
                        variant="primary"
                        onClick={this.handleClickSave}
                        name="save"
                        disabled={!isDraft || isLoading}
                        size="lg"
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(GlossaryAddItemGroups)
