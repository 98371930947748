import React from 'react'
import {
    Breadcrumb,
    Col,
    Container,
    Row,
    Table,
    Form,
    Button,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { compareStr } from '../../../../helpers/String'
import ClipMedia from '../../../components/ClipMedia'
import Header from '../../../components/Header'
import ListFilter from '../../../components/ListFilter'
import MiniZoomer from '../../../components/MiniZoomer'
import ThreadComponent from '../../ThreadComponent'
import { ApiService } from '../../../../lib/api/HttpService'
import { showLoading } from '../../../../helpers/Loading'

class CatalogList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            tmpData: [],
            viewType: 'table',
            gallerySize: 'md',
            disabledZoomer: true,
            dataIsLoaded: false,
            imagesLoaded: false,
        }

        this.api = new ApiService()
    }

    componentInit() {
        this.setTitle('Catalog').setActionBar(true)
    }

    async getCatalogItems() {
        const catalog = await this.api.getCatalogItems('')

        this.setState({
            tmpData: catalog,
        })

        return catalog
    }

    async componentDidMount() {
        const catalog = await this.getCatalogItems()

        this.renderData(catalog)
        this.enableSearchAndSortTable()

        await this.loadImages(catalog)
    }

    async loadImages(inventory) {
        if (inventory && inventory.length) {
            for (let item of inventory) {
                let file = item.primaryImageId
                    ? await this.api.getFile(item.primaryImageId + '/thumb')
                    : ''
                item.image = file
            }
            this.setState({
                tmpData: inventory,
                imagesLoaded: true,
            })
        }
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        this.setState((prev) => {
            const data = {}
            data.showTableSearch = !prev.showTableSearch
            return data
        })
    }

    handleSwitchView = (e) => {
        e.preventDefault()
        const view = e.target.getAttribute('data-view')
        // Set the new state.
        this.setState((prevState) => (prevState.viewType = view))
        this.setState(
            (prevState) => (prevState.disabledZoomer = view !== 'gallery')
        )
    }

    handleZoomChange = (zoom) => {
        let size = 'md'
        if (zoom === 0) {
            size = 'sm'
        }

        if (zoom === 2) {
            size = 'lg'
        }

        this.setState({ gallerySize: size })
    }

    handleChecks = (e) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked

            if (totalChecked > 0) {
                document.getElementById('inline-check-th-0').checked = true
            } else {
                document.getElementById('inline-check-th-0').checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e) => {
        // Get the target menu.
        const isChecked = e.target.checked

        this.setState((prevState) => {
            if (isChecked) {
                if (this.state.totalChecked < this.state.data.length) {
                    this.state.data.map((item, i) => {
                        prevState.checks[i] = true
                        document.getElementById(
                            'chk-projectview-items-' + i
                        ).checked = true
                        return null
                    })
                }
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e) => {
                        e.checked = false
                    })
                }
            }

            return prevState
        })
    }

    renderHeader() {
        return (
            <Header>
                <Header.LeftContent>
                    <Header.Breadcumbs>
                        <Breadcrumb className="breadcrumb-light">
                            <Breadcrumb.Item
                                linkProps={{ to: URI.item.catalog.base }}
                                linkAs={Link}
                            >
                                Items
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Catalog</Breadcrumb.Item>
                        </Breadcrumb>
                    </Header.Breadcumbs>
                    <Header.Title>Catalog</Header.Title>
                </Header.LeftContent>
                <Header.Actions list={true}>
                    <li>
                        <Button
                            as={Link}
                            to={URI.item.catalog.add}
                            variant="light"
                            size="lg"
                            className="btn-icon text-secondary-green"
                        >
                            <i className="ri-add-line ri-lg"></i> Add
                        </Button>
                    </li>
                </Header.Actions>
            </Header>
        )
    }

    renderFilter() {
        const { viewType } = this.state

        return (
            <>
                <ListFilter>
                    <ListFilter.Fields lg={9} xl={10}>
                        <ListFilter.Field className="flex-xl-column">
                            <div className="d-flex align-items-center mb-1">
                                <Button
                                    variant={`${compareStr(
                                        viewType,
                                        'table',
                                        'dark',
                                        'light'
                                    )}`}
                                    size="sm"
                                    className={`${compareStr(
                                        viewType,
                                        'table',
                                        'bg-primary-ash'
                                    )} square wrap-after py-0`}
                                    data-view="table"
                                    onClick={this.handleSwitchView}
                                >
                                    <i
                                        className="ri-list-unordered fsx-22 position-relative"
                                        style={{ top: '-2px' }}
                                    ></i>
                                </Button>
                                <Button
                                    variant={`${compareStr(
                                        viewType,
                                        'gallery',
                                        'dark',
                                        'light'
                                    )}`}
                                    size="sm"
                                    className={`${compareStr(
                                        viewType,
                                        'gallery',
                                        'bg-primary-ash'
                                    )} square wrap-after py-0 mx-2`}
                                    data-view="gallery"
                                    onClick={this.handleSwitchView}
                                >
                                    <i
                                        className="ri-microsoft-fill fsx-22 position-relative"
                                        style={{ top: '-2px' }}
                                    ></i>
                                </Button>
                                <MiniZoomer
                                    changeZoom={this.handleZoomChange}
                                    disabled={this.state.disabledZoomer}
                                ></MiniZoomer>
                            </div>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    <ListFilter.Actions lg={3} xl={2}>
                        <ListFilter.Action className="ms-auto">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button>
                            <Button
                                as={Link}
                                to=""
                                variant="ivory"
                                size="sm"
                                className="btn-icon btn-action ms-3"
                            >
                                <i className="ri-close-line"></i> Delete
                            </Button>
                        </ListFilter.Action>
                    </ListFilter.Actions>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderGalleryView() {
        return (
            <>
                <ClipMedia
                    data={this.state.data}
                    className="mt-5"
                    size={this.state.gallerySize}
                ></ClipMedia>
            </>
        )
    }

    renderTableView() {
        return (
            <Table striped responsive className="a-table">
                <thead>
                    <tr className="a-table-heading">
                        <th align="middle" className="mw-70px">
                            <div className="d-flex justify-content-center">
                                <Form.Check
                                    inline
                                    label=""
                                    name={`inline-check-th-0`}
                                    type="checkbox"
                                    data-id={`th-0`}
                                    id={`inline-check-th-0`}
                                    defaultChecked={
                                        this.state.totalChecked > 0
                                            ? true
                                            : false
                                    }
                                    className={`${
                                        this.state.totalChecked > 0 &&
                                        this.state.totalChecked <
                                            this.state.data.length
                                            ? 'line'
                                            : ''
                                    }`}
                                    onClick={this.handleSelectAllChecks}
                                />
                            </div>
                        </th>
                        <th>Image</th>
                        <th>
                            <span
                                className="sort active asc"
                                data-field="catno"
                            >
                                Catalog No.
                            </span>
                        </th>
                        <th>
                            <span className="sort" data-field="category1">
                                Color/Finish
                            </span>
                        </th>
                        <th>
                            <span className="sort" data-field="category2">
                                Style/Size
                            </span>
                        </th>
                        <th className="col-md-5">
                            <span className="sort" data-field="desc">
                                Description
                            </span>
                        </th>
                    </tr>
                    <tr
                        className={`a-table-search-fields ${
                            this.state.showTableSearch ? '' : 'd-none'
                        }`}
                    >
                        <th></th>
                        <th></th>
                        <th>
                            <Form.Control type="text" data-field="catno" />
                        </th>
                        <th>
                            <Form.Control type="text" data-field="category1" />
                        </th>
                        <th>
                            <Form.Control type="text" data-field="category2" />
                        </th>
                        <th>
                            <Form.Control type="text" data-field="desc" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (this.state.dataIsLoaded) {
                            return this.state.data.map((item, i) => (
                                <tr
                                    key={i}
                                    data-key={i}
                                    className={
                                        this.state.checks[i] ? `active` : ''
                                    }
                                >
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <Form.Check
                                                label=""
                                                name={`group-` + i}
                                                type="checkbox"
                                                data-id={i}
                                                id={
                                                    `chk-projectview-items-` + i
                                                }
                                                className="chk-projectview-items-item"
                                                defaultValue={i}
                                                onClick={this.handleChecks}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {item.image && (
                                            <Link
                                                to={URI.item.catalog.edit.replace(
                                                    ':id',
                                                    item.id
                                                )}
                                            >
                                                <img
                                                    src={URL.createObjectURL(
                                                        item.image
                                                    )}
                                                    alt={item.itemName}
                                                    className="img-sm"
                                                />
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        <Link
                                            to={URI.item.catalog.edit.replace(
                                                ':id',
                                                item.id
                                            )}
                                            className="text-charcoal hover-view-icon"
                                        >
                                            {item.catno}
                                        </Link>
                                    </td>
                                    <td>{item.category1}</td>
                                    <td>{item.category2}</td>
                                    <td>{item.desc}</td>
                                </tr>
                            ))
                        } else {
                            return showLoading()
                        }
                    })()}
                </tbody>
            </Table>
        )
    }

    renderContent() {
        if (this.state.viewType === 'table') return this.renderTableView()

        if (this.state.viewType === 'gallery') return this.renderGalleryView()
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                {/* Filter */}
                                {this.renderFilter()}

                                {this.renderContent()}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(CatalogList)
