import React from 'react'
import { Container, Col, Row, Form } from 'react-bootstrap'

class InputGroup extends React.Component {
    render() {
        return (
            <>
                <div className="bg-secondary-grey pt-5 pb-4">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="ui-title">Input Group</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="mt-4">
                    <Row>
                        <Col>
                            <div
                                className="border-1 border-sand px-4 py-4"
                                style={{ maxWidth: '400px' }}
                            >
                                <h6 className="fw-bold">
                                    Client Deposits/Retainers
                                </h6>
                                <div className="form-fields px-4 mt-4">
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Deposits Requested
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Retainers Received
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Deposits Received
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Total Received
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Dep./Ret. Applied (Or credit/refund)
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        >
                                            Available
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="4"
                                            className="fw-500 text-end px-0 fs-sm py-0"
                                        ></Form.Label>
                                        <Col sm="8">
                                            <Form.Check
                                                label="Include Signature from User Settings"
                                                name="inline-ig-radio-4"
                                                type="checkbox"
                                                id={`inline-ig-radio-4`}
                                                className="fw-sm"
                                            />
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default InputGroup
