import { addDomClass, hasClass, removeDomClass } from './DOM'

function tableResponsive(_table: any) {
    const table = _table.children ? _table.children[0] : null
    const tableParent = _table.parentNode
        ? _table.parentNode[0]
            ? _table.parentNode[0]
            : _table.parentNode
        : null

    if (
        tableParent &&
        tableParent.classList &&
        tableParent.classList.contains('table-gradient')
    ) {
        const totalScroll = _table.scrollLeft + _table.offsetWidth + 5

        if (_table.offsetWidth < table.clientWidth) {
            addDomClass('table-scrolled-gradient', tableParent)

            if (totalScroll >= table.clientWidth) {
                addDomClass('table-scrolled-end', tableParent)
            } else {
                removeDomClass('table-scrolled-end', tableParent)
            }
        }
    }
}

export default function tableEvent(callback?: any) {
    const tables = document.querySelectorAll('.table-responsive')

    // Add scroll events on tables.
    if (tables) {
        tables.forEach((etable) => {
            tableResponsive(etable)

            etable.addEventListener(
                'scroll',
                function (e) {
                    tableResponsive(e.target)
                },
                false
            )
        })
    }

    const tablesActionBar = document.querySelectorAll('.table-action-bar')

    // Add vartical action bar movement.
    if (tablesActionBar) {
        tablesActionBar.forEach((etable) => {
            function getElementIndex(e: any) {
                return Array.from(e.parentNode.parentNode.children).indexOf(
                    e.parentNode
                )
            }

            function getTd(e: any) {
                if (e.tagName !== 'TD') {
                    return e.parentNode.tagName !== 'TD'
                        ? e.parentNode.parentNode
                        : e.parentNode
                }

                return e
            }

            function setActionBarPostion(vActionBar: any, marginTop: any) {
                vActionBar.style.marginTop = marginTop + 'px'
            }

            const td = etable.querySelectorAll('tr td')
            const thead = etable.querySelector('thead') as HTMLElement
            const vActionBar = etable.querySelector(
                '.a-action-bar.vertical'
            ) as HTMLElement
            const theadSearch = etable.querySelector(
                '.a-table-search-fields'
            ) as HTMLElement
            let theadHeight = thead ? thead.offsetHeight : 0

            if (theadSearch && !hasClass('d-none', theadSearch)) {
                theadHeight += theadSearch?.offsetHeight
            }

            if (td && vActionBar) {
                setActionBarPostion(vActionBar, theadHeight)

                td.forEach((_td) => {
                    _td.addEventListener(
                        'mouseenter',
                        function (e: any) {
                            const realTd = getTd(e.target)

                            const indx = getElementIndex(realTd)

                            let theadHeight2 = theadHeight

                            const trHeight = realTd.parentNode.offsetTop || 0

                            const trEl = realTd.parentNode

                            if (
                                theadSearch &&
                                !hasClass('d-none', theadSearch)
                            ) {
                                theadHeight2 =
                                    theadHeight + theadSearch.offsetHeight
                            }

                            const marginTop = theadHeight2 + trEl.offsetTop

                            if (vActionBar) {
                                vActionBar.style.marginTop = marginTop + 'px'
                            }

                            if (callback) {
                                callback(trEl)
                            }
                        },
                        false
                    )
                })
            }
        })
    }
}
