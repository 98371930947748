import React from 'react'
import {
    Container,
    Row,
    Col,
    Form,
    FloatingLabel,
    Button,
    Alert,
} from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'

import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'
import {
    API_AUTH_ERROR_MESSAGES,
    ApiService,
} from '../../../lib/api/HttpService'
import { generatePath } from 'react-router-dom'
import {
    clearUserData,
    setCookie,
    isLoggedIn,
    parseJwt,
} from '../../../utilities/Auth'

import logo from '../../../assets/images/logo.svg'
import logoDark from '../../../assets/images/logo-dark.svg'

import briaHammel from '../../../assets/images/bria-hammel.png'
import thomFilicia from '../../../assets/images/thom-filicia.png'
import libbyLangdon from '../../../assets/images/libby-langdon.png'
import { AlertError } from '../../../app/components/bootstrap/BAlerts'
import { setLocalStorage } from '../../../utilities/LocalStorage'
import { PRO_CLOUD_LOGIN_PAGE } from 'app/context/security'
import './Login.css'

class Login extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            errorMessage: '',
            showErrorMessage: false,
            showLoginPage: false,
            isLoggingIn: false,
            instanceError: false,
        }

        this.api = new ApiService()
        this.loginData = {}
        this.redirectLocation = '/'
        this.cookieExpiry = 0
    }

    async componentDidMount() {
        const location = window.localStorage.getItem('location')
        this.redirectLocation = location ? location : '/'

        if (isLoggedIn()) {
            window.localStorage.removeItem('location')
            window.onbeforeunload = null
            setTimeout(() => {
                window.location.href = generatePath(this.redirectLocation)
            }, 1500)
        } else {
            clearUserData()
            this.setState({
                showLoginPage: true,
            })
        }
    }

    handleLoginClick = async (e) => {
        this.setState({
            isLoggingIn: true,
            showErrorMessage: false,
            instanceError: false,
        })
        let token = ''
        this.cookieExpiry = this.loginData.remember ? 7 : 0

        await this.api
            .getAuthToken(this.loginData)
            .then((resp) => {
                token = resp
            })
            .catch((err) => {
                this.setState({
                    showErrorMessage: true,
                    errorMessage: 'Incorrect username or password.',
                    isLoggingIn: false,
                })
            })

        if (token !== '') {
            setCookie('dmAuthToken', token.access_token, this.cookieExpiry)
            const parsedToken = parseJwt(token.access_token)
            const instances = JSON.parse(parsedToken.DmInstances)
            setLocalStorage('userInstances', instances, true)
            const isImpersonating = parsedToken?.ImpersonationMode ?? false
            const userName = isImpersonating
                ? parsedToken.name
                : this.loginData.username

            setCookie('dmInstanceId', instances[0].Id, this.cookieExpiry)

            await this.api
                .getUserData(
                    `?$filter=password eq '${encodeURIComponent(userName)}'`
                )
                .then((resp) => {
                    const [data] = resp
                    setCookie('dmUsercode', data.usercode, this.cookieExpiry)
                    setCookie('dmUsername', data.username, this.cookieExpiry)
                    setCookie(
                        'dmLoginUsername',
                        this.loginData.username,
                        this.cookieExpiry
                    )
                    setCookie('dmUserEmail', data.useremail, this.cookieExpiry)

                    window.localStorage.removeItem('location')
                    window.onbeforeunload = null

                    setTimeout(() => {
                        window.location.href = generatePath(
                            this.redirectLocation
                        )
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.response?.data?.UserError ===
                        API_AUTH_ERROR_MESSAGES.INVALID_INSTANCE
                    ) {
                        return this.setState({
                            showErrorMessage: true,
                            isLoggingIn: false,
                            instanceError: true,
                            errorMessage:
                                'Web DM is not available for your company at this time.  Please continue to Pro Cloud via',
                        })
                    }
                    this.setState({
                        isLoggingIn: false,
                    })
                })
        }
    }

    handleChange = (e) => {
        let key, value
        if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }
        this.loginData[key] = value
    }

    renderSpinner() {
        return (
            <div className="flex-grow-1 loginRight-form">
                <div>
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '10vh' }}
                    >
                        <div
                            className="spinner-border"
                            style={{ width: '3rem', height: '3rem' }}
                            role="status"
                        ></div>
                    </div>
                    <p className="text-secondary-ash fsx-16 mb-4 text-center">
                        Loading
                    </p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <form onSubmit={(e) => e.preventDefault()}>
                <Container fluid className="loginContainer d-lg-flex">
                    <div className="p-4 mb-5 d-lg-none">
                        <img
                            src={logoDark}
                            width="100%"
                            height="auto"
                            className="logo"
                            alt=""
                        />
                    </div>
                    <Row className="w-100 justify-content-center">
                        <Col
                            lg={4}
                            className="d-none d-lg-flex loginLeft flex-column"
                        >
                            <div className="p-4 flex-grow-1">
                                <img
                                    src={logo}
                                    width="100%"
                                    height="auto"
                                    className="logo"
                                    alt=""
                                />
                            </div>
                            <div className="loginLeft-footer">
                                <div className="mb-3">
                                    TRUSTED BY THOUSANDS OF INTERIOR DESIGNERS
                                </div>

                                <div className="logos px-4 pb-4">
                                    <img src={briaHammel} alt="" />

                                    <img src={thomFilicia} alt="" />

                                    <img src={libbyLangdon} alt="" />
                                </div>
                            </div>
                        </Col>

                        <Col lg={8} className="d-flex loginRight flex-column">
                            <div className="flex-grow-1 loginRight-form">
                                {this.state.showLoginPage ? (
                                    <div>
                                        <h1 className="fsx-40 mb-4">
                                            Sign into Design Manager
                                        </h1>

                                        <div className="d-grid gap-3">
                                            <FloatingLabel
                                                controlId="username"
                                                label="Username"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    size="lg"
                                                    name="username"
                                                    onChange={this.handleChange}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="password"
                                                label="Password"
                                            >
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password"
                                                    size="lg"
                                                    name="password"
                                                    onChange={this.handleChange}
                                                />
                                            </FloatingLabel>
                                            <Form.Check
                                                inline
                                                label="Remember me"
                                                name="remember"
                                                type="checkbox"
                                                id={`checkbox-remember`}
                                                onChange={this.handleChange}
                                            />
                                            {this.state.showErrorMessage && (
                                                <AlertError
                                                    isClose={false}
                                                    className="compact mb-1"
                                                    fw="fw-400"
                                                >
                                                    {`${this.state.errorMessage}  `}
                                                    {this.state
                                                        .instanceError && (
                                                        <a
                                                            className="link"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={
                                                                PRO_CLOUD_LOGIN_PAGE
                                                            }
                                                        >
                                                            {`  ${PRO_CLOUD_LOGIN_PAGE}`}
                                                        </a>
                                                    )}
                                                </AlertError>
                                            )}
                                            <Button
                                                variant="primary"
                                                size="lg"
                                                onClick={this.handleLoginClick}
                                                disabled={
                                                    this.state.isLoggingIn
                                                }
                                                type="submit"
                                            >
                                                {this.state.isLoggingIn
                                                    ? 'Signing in...'
                                                    : 'Sign in'}
                                            </Button>
                                            <div className="text-center">
                                                <Link to={URI.login.password}>
                                                    Forgot your password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    this.renderSpinner()
                                )}
                            </div>
                            <div className="loginRight-footer py-4">
                                By signing in to Design Manager, you agree to
                                our{' '}
                                <a href="https://www.designmanager.com/terms">
                                    Terms of Service
                                </a>{' '}
                                and{' '}
                                <a href="https://www.designmanager.com/privacy-policy">
                                    Privacy Policy
                                </a>
                                .
                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
        )
    }
}

export default Login
