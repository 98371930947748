import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import Select, { SingleValue, components, SingleValueProps } from 'react-select'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'

interface DropdownOption {
    value: object
    label: string
}

interface Props {
    message: string
    options: DropdownOption[]
    onCancel?: () => {}
    onConfirm: (type: object | undefined) => {}
    hasCancelButton?: boolean
    option?: (props: OptionProps<DropdownOption, false>) => JSX.Element
    singleValue?: (
        props: SingleValueProps<DropdownOption, false>
    ) => JSX.Element
    value?: Promise<DropdownOption>
}

interface State {
    value: DropdownOption | undefined
}

export class DropdownModal extends React.Component<Props, State> {
    hasCancelButton: boolean

    constructor(props: Props) {
        super(props)

        this.state = {
            value: props.options[0],
        }

        this.hasCancelButton = props.hasCancelButton ?? true
    }

    async componentDidMount() {
        const value = await this.props.value
        this.setState({ value: value })
    }

    handleChange = (e: SingleValue<DropdownOption>) => {
        if (e)
            this.setState({
                value: e,
            })
    }

    render() {
        return (
            <Modal
                size="sm"
                show={true}
                aria-labelledby="action-modal"
                className="a-modal mx-sm-5 mx-lg-0"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Container>
                        <Col>
                            <Row className="px-sm-0 px-lg-1">
                                <Col>
                                    <Row className="text-center mx-auto fw-bold">
                                        <p>{this.props.message}</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                    <Select
                                        components={{
                                            Option:
                                                this.props.option ??
                                                components.Option,
                                            SingleValue:
                                                this.props.singleValue ??
                                                components.SingleValue,
                                        }}
                                        options={this.props.options}
                                        value={
                                            this.state.value ??
                                            this.props.options.filter(
                                                (option) => {
                                                    return (
                                                        option.value ===
                                                        this.state.value
                                                    )
                                                }
                                            )[0]
                                        }
                                        name="manager"
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4">
                    <Col>
                        <Row className="mb-3">
                            <Col>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        this.props.onConfirm(
                                            this.state.value?.value
                                        )
                                    }}
                                    className="w-100"
                                >
                                    OK
                                </Button>
                            </Col>
                        </Row>
                        {this.hasCancelButton && (
                            <Row>
                                <Col>
                                    <Button
                                        variant="trans-light border-secondary-ash"
                                        onClick={this.props.onCancel}
                                        className="w-100"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Modal.Footer>
            </Modal>
        )
    }
}
