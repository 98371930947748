import React from 'react'
import { Col, Container, Row, Table, Form, Spinner } from 'react-bootstrap'
import { observer } from 'mobx-react'
import URI from '../../../defaults/RoutesDefault'
import ClientListViewModel from './ClientListViewModel'
import { Link } from 'react-router-dom'
import ClientListHeader from './components/ClientListHeader'
import ClientListFilter from './components/ClientListFilter'
import { displayAlert, displayAlertLoader } from '../../../utilities/Response'
import ClientAddEditViewModel from './ClientAddEditViewModel'
import { WithRouter, pathParam } from '../../../utilities/Router'
import DeleteModal from '../../components/modal/DeleteModal'
import HandleDeleteWorker from '../../../utilities/DeleteWorker'
import { Pagination } from '../../components/pagination/Pagination'
import { TableComponent, TableComponentState } from '../../TableComponent'

import noItems from '../../../assets/images/icons/client.svg'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import { isFiltered } from '../../../helpers/Util'
import MSG from '../../../defaults/Message'
import FilterSort from '../../../utilities/modules/FilterSort'

interface Props {
    clientModel: ClientListViewModel
    clientAddEditModel: ClientAddEditViewModel
}
interface State extends TableComponentState {
    checks: any
    totalChecked: number
    dataIsLoaded: boolean
    inactive: boolean
    showTableSearch: boolean
    disabledDeleteButton: boolean
    showModal: boolean
    deleteResponses: {}
    sortProperty: string
    pageSize: number
    page: number
    searchProperties: {}
}

class ClientListComponent extends TableComponent<Props, State> {
    readonly clientModel: ClientListViewModel
    readonly clientAddEditModel: ClientAddEditViewModel
    readonly fs: FilterSort
    deleteObjectParams: any

    constructor(props: Props) {
        super(props)

        this.clientModel = props.clientModel
        this.clientAddEditModel = props.clientAddEditModel
        this.fs = new FilterSort('contact_client_list')
        this.fs.setDefaultSort('client asc')
        this.state = {
            checks: {},
            totalChecked: 0,
            dataIsLoaded: false,
            inactive: this.fs.getFilter('inactive') ?? false,
            disabledDeleteButton: true,
            showModal: false,
            deleteResponses: {},
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'client asc',
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
        }

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objClient',
            objectCodeOrId: '',
        }
    }

    componentDidMount(): void {
        this.setTitle('Clients').hasActionBar(true).setBodyClass()

        this.clientModel.reset()
        this.clientModel.fetchClients(
            this.state.page,
            this.buildFilters(this.state.page)
        )
        this.clientAddEditModel.componentDidMount()
        super.componentDidMount()
    }

    getFS() {
        return this.fs
    }

    handleSwitch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState(
            {
                inactive: e.target.checked,
            },
            () => {
                this.handleChangePage(1)
            }
        )
        this.fs.setFilter('inactive', e.target.checked)
    }

    hideModal = (e: any) => {
        this.setState({
            showModal: false,
        })
    }

    handleShowTableSearch: any = (state: any) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e: any) => {
                e.value = ''
            })
        }

        this.setState({
            showTableSearch: !state.showTableSearch,
        })
        this.fs.setIsActiveSearch(!state.showTableSearch)

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                () => {
                    this.handleChangePage(1)
                }
            )
        }
    }

    handleChecks = (e: any) => {
        // Get the target menu.
        const indx = e.target.getAttribute('data-id')
        // Set the new state.
        this.setState((prevState: State) => {
            let totalChecked = 0
            prevState.checks[indx] = e.target.checked

            if (prevState.checks) {
                /* eslint-disable no-unused-vars */
                for (const [index, value] of Object.entries(prevState.checks)) {
                    if (value) {
                        totalChecked++
                    }
                }
                /* eslint-enable no-unused-vars */
            }

            prevState.totalChecked = totalChecked

            prevState.disabledDeleteButton = totalChecked <= 0

            const chkEl: any = document.getElementById('inline-check-th-0')

            if (totalChecked > 0) {
                chkEl.checked = true
            } else {
                chkEl.checked = false
            }

            return prevState
        })
    }

    handleSelectAllChecks = (e: any) => {
        // Get the target menu.
        const isChecked: boolean = e.target.checked

        this.setState((prevState: State) => {
            if (isChecked) {
                if (
                    this.state.totalChecked < this.clientModel.clientList.length
                ) {
                    this.clientModel.clientList.map((item, i) => {
                        const chkEl: any = document.getElementById(
                            'chk-projectview-items-' + i
                        )
                        prevState.checks[i] = true
                        if (chkEl) {
                            chkEl.checked = true
                        }

                        return null
                    })
                }
                prevState.totalChecked = this.clientModel.clientList.length
            } else {
                prevState.checks = {}
                prevState.totalChecked = 0

                const checks = document.querySelectorAll(
                    '.chk-projectview-items-item input'
                )
                if (checks) {
                    checks.forEach((e: any) => {
                        e.checked = false
                    })
                }
            }

            prevState.disabledDeleteButton = prevState.totalChecked <= 0

            return prevState
        })
    }

    handleTestDeleteObjects = (e: any) => {
        const self = this
        const workers: any = []

        displayAlertLoader(MSG.loading.delete.Client)

        for (const [index, value] of Object.entries(this.state.checks)) {
            if (value) {
                const params = {
                    deleteType: 'dmriTestOnly',
                    objectType: 'objClient',
                    objectCodeOrId: index,
                }
                workers.push(this.clientAddEditModel.deleteClient(params))
            }
        }

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: 'Client',
                    modulePlural: 'Clients',
                    reference: 'Projects',
                    objectType: self.deleteObjectParams.objectType,
                },
                false,
                (modalState: any) => {
                    this.setState(modalState)
                }
            )
        }
    }

    // Refresh data
    refreshDataAfterDelete = (e: any) => {
        const headingCheck: any = document.getElementById('inline-check-th-0')

        this.clientModel.componentDidMount()
        this.setState({
            checks: {},
            totalChecked: 0,
            showModal: false,
            deleteResponses: {},
        })
        headingCheck.checked = false
    }

    handleDelete = (e: any) => {
        const checks = document.querySelectorAll(
            '.chk-projectview-items-item input:checked'
        )
        if (checks.length) {
            if (confirm('Are you sure you want to delete?')) {
                const promises: any = []

                displayAlert('light', 'Deleting clients...')

                for (let i = 0; i < checks.length; i++) {
                    const element: any = checks[i]

                    promises.push(
                        this.clientAddEditModel.deleteClient({
                            deleteType: 'dmriTestOnly',
                            objectType: 'objClient',
                            objectCodeOrId: element.value,
                        })
                    )
                }

                Promise.all(promises).then((responses) => {
                    let success = 0
                    for (let i = 0; i < responses.length; i++) {
                        if (responses[i].objectCodeOrId) success++
                    }

                    const message =
                        success == checks.length
                            ? 'Successfully deleted clients.'
                            : 'Oops! Something went wrong.'
                    const variant =
                        success == checks.length ? 'success' : 'danger'

                    setTimeout(() => {
                        displayAlert(variant, message, 7000)
                    }, 3000)
                })
            }
        }
    }

    filterResults() {
        return this.clientModel.clientList.filter((item) => {
            return item.inactive === this.state.inactive
        })
    }

    buildFilters(currentPage: number) {
        const filters: string[] = []

        filters.push(`inactive eq ${this.state.inactive}`)

        Object.keys(this.state.searchProperties).forEach((key) => {
            // @ts-ignore
            const property = this.state.searchProperties[key]
            if (property.value) {
                if (property.type === 'number') {
                    filters.push(`${key} eq ${property.value}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        const filtersQuery =
            filters.length > 0 ? `&$filter=${filters.join(' and ')}` : ''
        let queryString = `?$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size: number) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page: number) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page: number) => {
        this.clientModel.fetchClients(page, this.buildFilters(page))

        this.setState({
            page: page,
        })
    }

    handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const key = e.target.attributes['data-field' as any].value
        const value = e.target.value
        const type = e.target.attributes['data-type' as any]
            ? e.target.attributes['data-type' as any].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.handleChangePage(1)
            }
        )
    }

    sortClass(name: string) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    render(): JSX.Element {
        let results: any = []
        if (this.clientModel.isLoaded) {
            results = this.filterResults()
        }
        return (
            <>
                {/* begin::Header */}
                <ClientListHeader />
                {/* begin::Header */}
                <div className="content-padding has-action-bar sticky-container">
                    <Container fluid className="px-0">
                        <Row>
                            <Col sm="12">
                                {/* begin::Filter */}
                                <ClientListFilter
                                    onShowSearch={this.handleShowTableSearch}
                                    showTableSearch={this.state.showTableSearch}
                                    disabledDelete={
                                        this.state.disabledDeleteButton
                                    }
                                    onDeleteClick={this.handleTestDeleteObjects}
                                    onSwitch={this.handleSwitch}
                                    inactive={this.state.inactive}
                                />
                                {/* begin::Filter */}

                                <Table striped responsive className={`a-table`}>
                                    <thead>
                                        <tr className="a-table-heading">
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'client'
                                                    )}
                                                    data-field="client"
                                                >
                                                    Client Code
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'sortName'
                                                    )}
                                                    data-field="sortName"
                                                >
                                                    Client Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'contact'
                                                    )}
                                                    data-field="contact"
                                                >
                                                    Contact Name
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'contactphone'
                                                    )}
                                                    data-field="contactphone"
                                                >
                                                    Contact Phone
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    className={this.sortClass(
                                                        'typedesc'
                                                    )}
                                                    data-field="typedesc"
                                                >
                                                    Type
                                                </span>
                                            </th>
                                        </tr>
                                        <tr
                                            className={`a-table-search-fields ${
                                                this.state.showTableSearch
                                                    ? ''
                                                    : 'd-none'
                                            }`}
                                        >
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="client"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'client'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="ClientName"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'ClientName'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="contact"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'contact'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="contactphone"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'contactphone'
                                                    )}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    type="text"
                                                    data-field="typedesc"
                                                    onChange={this.handleSearch}
                                                    defaultValue={this.fs.getValue(
                                                        'typedesc'
                                                    )}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.clientModel.isLoaded
                                            ? showLoading()
                                            : this.clientModel.clientList
                                                  .length === 0 &&
                                              isFiltered(
                                                  this.state.searchProperties
                                              )
                                            ? showEmpty()
                                            : this.clientModel.clientList
                                                  .slice(0, this.state.pageSize)
                                                  .map(
                                                      (
                                                          item: any,
                                                          i: number
                                                      ) => (
                                                          <tr
                                                              key={i}
                                                              data-key={i}
                                                          >
                                                              <td>
                                                                  <div className="d-flex flex-row align-content-center align-items-center">
                                                                      <Link
                                                                          to={pathParam(
                                                                              URI
                                                                                  .contact
                                                                                  .clientEdit,
                                                                              {
                                                                                  id: item.id,
                                                                                  tab: 'info',
                                                                              }
                                                                          )}
                                                                          className="text-charcoal hover-view-icon"
                                                                      >
                                                                          {
                                                                              item.client
                                                                          }
                                                                      </Link>

                                                                      {item.numAttachments >
                                                                          0 && (
                                                                          <Link
                                                                              to={pathParam(
                                                                                  URI
                                                                                      .contact
                                                                                      .clientEdit,
                                                                                  {
                                                                                      id: item.id,
                                                                                      tab: 'documents',
                                                                                  }
                                                                              )}
                                                                              style={{
                                                                                  textDecoration:
                                                                                      'none',
                                                                              }}
                                                                              className="text-charcoal border-0 fsx-16 ri-attachment-2 square ms-1"
                                                                          />
                                                                      )}
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  <Link
                                                                      to={pathParam(
                                                                          URI
                                                                              .contact
                                                                              .clientEdit,
                                                                          {
                                                                              id: item.id,
                                                                              tab: 'info',
                                                                          }
                                                                      )}
                                                                      className="text-charcoal hover-view-icon"
                                                                  >
                                                                      {item.sortName ??
                                                                          item.ClientName}
                                                                  </Link>
                                                              </td>
                                                              <td>
                                                                  {item.contact}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.contactPhone
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.typeDesc
                                                                  }
                                                              </td>
                                                          </tr>
                                                      )
                                                  )}
                                    </tbody>
                                </Table>
                                {this.clientModel.isLoaded &&
                                    !this.clientModel.clientList.length &&
                                    !isFiltered(
                                        this.state.searchProperties
                                    ) && (
                                        <div className="row justify-content-center text-center py-5">
                                            <div className="col-md-3">
                                                <img
                                                    src={noItems}
                                                    className="mw-100 mb-4"
                                                    alt=""
                                                />

                                                <h6>Add Your Clients</h6>
                                                <p>
                                                    This is where you will
                                                    manage the contact details
                                                    about your own clients.
                                                </p>

                                                <Link
                                                    to={pathParam(
                                                        URI.contact.clientAdd,
                                                        { tab: 'info' }
                                                    )}
                                                    className="btn btn-icon btn-primary mt-4"
                                                >
                                                    <i className="ri-add-line ri-lg"></i>{' '}
                                                    Add Client
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                    {Boolean(this.clientModel.clientList.length) && (
                        <Pagination
                            onPageSizeChanged={this.onPageSizeChanged}
                            onPageChanged={this.onPageChanged}
                            hasPreviousPage={this.state.page > 1}
                            hasNextPage={
                                this.clientModel.clientList.length >
                                this.state.pageSize
                            }
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                        />
                    )}
                </div>

                <DeleteModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    hideModal={this.hideModal}
                    responses={this.state.deleteResponses}
                />
            </>
        )
    }
}

export default WithRouter(observer(ClientListComponent))
