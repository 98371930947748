import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import { fakeReactSelectOptions } from '../../../factory/fakeFactory'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'

class PaymentFormModifyAmount extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
        }
    }

    componentInit() {
        this.setTitle('Modify Amount').setFormAction(true)
    }

    handleDateChange = (date) => {
        this.setState((prevState) => (prevState.startDate = date))
    }

    handleRadioChange = (e) => {
        this.setState(
            (prevState) =>
                (prevState.isDisabled = e.target.value === '1' ? true : false)
        )
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.accountsPayable.payment.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.accountsPayable.payment.base }}
                            linkAs={Link}
                        >
                            Accounts Payable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.accountsPayable.payment.base }}
                            linkAs={Link}
                        >
                            Payments
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Modify Amount</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>Modify Amount</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2} className="mb-5">
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Pay Amount
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="0.00"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Less Discount
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="0.00"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Check Amount
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="0.00"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-start mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0 mt-2 pt-1">
                                    Notes
                                </Form.Label>
                            </Col>
                            <Col>
                                <WysiwygEditor />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Pay With
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <Select
                                            options={fakeReactSelectOptions()}
                                            className="react-select"
                                            placeholder="Please select"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height">
                    <Container fluid className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(PaymentFormModifyAmount)
