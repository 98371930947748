import React from 'react'
import { makeid } from '../../../utilities/String'

interface Props {
    nolabel?: boolean
    off?: string
    on?: string
    checked?: boolean
    disabled?: boolean
    onChange?: any
}

/**
 * Display toggle switch.
 *
 * @param {string} off Off Text.
 * @param {string} on On Text.
 * @param {boolean} nolabel has Text.
 * @param {boolean} checked is On.
 * @param {boolean} disabled is Disabled input.
 * @returns
 */
export default class Switch extends React.Component<Props> {
    render() {
        const { nolabel, off, on, checked, disabled, onChange } = this.props
        const clssName = makeid(10)
        const offText = nolabel ? ' ' : off || 'Off'
        const onText = nolabel ? ' ' : on || 'On'

        return (
            <span className="a-switch">
                <style>
                    .chk-{clssName}::before{' '}
                    {"{ content: '" + offText + "' !important; }"}
                    .chk-{clssName}:checked::before{' '}
                    {"{ content: '" + onText + "' !important; }"}
                </style>
                <input
                    type="checkbox"
                    className={'chk-' + clssName}
                    defaultChecked={checked}
                    disabled={disabled}
                    onChange={onChange}
                />
            </span>
        )
    }
}
