import React from 'react'
import { Col, Row, Form, Table, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { ApiService } from '../../../../lib/api/HttpService'
import InputBar from '../../../components/InputBar'
import MyDropzone from '../../../ui-kit/MyDropzone'
import ThreadComponent from '../../ThreadComponent'
import { currencyFormat } from '../../../../helpers/Number'
import { generatePath } from 'react-router'
import DeleteModal from '../../../../app/components/modal/DeleteModal'
import HandleDeleteWorker from '../../../../utilities/DeleteWorker'
import HtmlToRtfBrowser from 'html-to-rtf-browser'
import { mapRtfToDraft } from '../../../../utilities/Rtf'
import { debounce } from 'lodash'
import FormCurrencyInput from '../../../../app/components/form/FormCurrencyInput'
import AsyncLocationsDropdown from '../../../../app/components/dropdowns/AsyncLocationsDropdown'
import AsyncSalesCategoriesDropdown from '../../../../app/components/dropdowns/AsyncSalesCategoriesDropdown'
import { truncateCharCount } from '../../../../helpers/String'
import { displayAlertError } from '../../../../utilities/Response'
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'
import SecureLink from '../../../../app/components/security/SecureLink'
import { Feature } from 'use-feature'
import { FeatureFlags } from 'app/enums/featureFlags/featureFlags'
import AddDropdown from '../../../../app/components/dropdowns/AddDropdown'
import { InventoryTypes } from '../../../../app/enums/inventoryTypes/inventoryTypes'

class ItemAddInfo extends ThreadComponent {
    constructor(props) {
        super(props)

        this.api = new ApiService()

        this.state = {
            locations: [],
            groups: [],
            uoms: [],
            salesCategories: [],
            dataIsLoaded: false,
            data: {},
            timebudget: false,
            modal: { show: false },
            disabledDeleteButton: false,
            checks: [],
            deleteResponses: [],
            descHtml: '',
            updatedData: props.updatedData,
            showLocationModal: false,
            clearedSelect: [],
            locAddName: '',
            locationsQry: `?$filter=(projectId eq null or projectId eq ${this.props.params.id})&$orderby=locn asc`,
            newLocation: false,
            componentIsLoaded: false,
        }

        this.deleteObjectParams = {
            deleteType: 'dmriTestOnly',
            objectType: 'objComponent',
            objectCodeOrId: '',
        }

        this.htmlToRtfConverter = new HtmlToRtfBrowser()
        this.recalculateHandler = debounce((id, changedId = null) => {
            this.recalculate(id, changedId)
        }, 500)

        this.setIsChildSpinner(true)
        this.projectId = this.props.params.id
    }

    getFormattedNumber(type) {
        return type
        // return this.state.dataIsLoaded ? currencyFormat(type) : type
    }

    async addItemComponentData(data) {
        data = await this.props.addItemComponentData(data)

        return data
    }

    getComponentLink = (item) => {
        if (item.txnum) {
            return generatePath(URI.timeEntry.edit, {
                id: item.txnum,
            })
        }

        return generatePath(URI.project.item.componentEdit, {
            id: this.props.params.id,
            itemId: this.props.params.itemId,
            compId: item.id,
        })
    }

    async componentDidMount() {
        if (Object.keys(this.props.data).length > 0) {
            let data = this.props.data || {}
            const updatedData = { ...this.state.updatedData }

            let unit = ''

            if (!data.quantity || !data.budget) {
                unit = ''
            } else {
                unit = data.budget / data.quantity
            }

            updatedData.unitbudget = isNaN(unit) ? '' : unit
            updatedData.cldeppct =
                data.cldeppct || this.props.project.clientdep || 0

            const descToUse =
                this.state.updatedData.descOnlyRtf ??
                data?.descOnlyRtf ??
                data?.descrtf ??
                data?.desc ??
                ''

            this.setState(
                {
                    updatedData: updatedData,
                    data: data,
                    dataIsLoaded: true,
                    descHtml: await mapRtfToDraft(descToUse),
                },
                () => {
                    this.setDefaultLocation()
                    this.setDefaultSalesCategory()
                }
            )
        }

        this.setState({
            groups: await this.getGroupName(),
            uoms: await this.getUnitsOfMeasures(),
        })

        this.setIsLoaded(true)
    }

    async componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            let data = this.props.data || {}

            const descToUse =
                this.state.updatedData.descOnlyRtf ??
                data?.descOnlyRtf ??
                data?.descrtf ??
                data?.desc ??
                ''

            this.setState(
                {
                    data: data,
                    descHtml: await mapRtfToDraft(descToUse),
                },
                () => {
                    this.setDefaultLocation()
                    this.setDefaultSalesCategory()
                }
            )
        }
    }

    refreshData = async (e) => {
        const data = await this.props.addItemComponentData(this.state.data)

        this.setState({
            data,
            componentIsLoaded: true,
        })
    }

    toggleDeleteButton(e) {
        this.setState({
            disabledDeleteButton: !this.state.disabledDeleteButton,
        })
    }

    async getGroupName() {
        let response = await this.api.get('Groups')
        let options = {}

        let list = []
        let keyedList = []
        for (let item of response) {
            if (!keyedList[item.groupname]) {
                const listItem = {
                    value: item.groupname,
                    label: item.groupname,
                }
                keyedList[item.groupname] = listItem
                list.push(listItem)
            }
        }

        options.list = list
        options.keyedList = keyedList

        return options
    }

    async getUnitsOfMeasures() {
        let response = await this.api.get('UnitsOfMeasures')
        let options = {}

        let list = []
        let keyedList = []
        for (let item of response) {
            if (!keyedList[item.value]) {
                const listItem = {
                    value: item.value,
                    label: item.value,
                }
                keyedList[item.value] = listItem
                list.push(listItem)
            }
        }

        options.list = list
        options.keyedList = keyedList

        return options
    }

    async recalculate(whatChanged, changedId) {
        const id = this.props.params.itemId
            ? this.props.params.itemId
            : this.props.newItemId

        let calcData = {
            whatChanged: whatChanged,
            quantity: Number(
                this.state.updatedData.quantity ?? this.props.data.quantity
            ),
            clDepPct: Number(
                this.state.updatedData.cldeppct ||
                    this.props.data.cldeppct ||
                    this.props.project.clientdep
            ),
            budget: Number(
                this.state.updatedData.budget ?? this.props.data.budget
            ),
            unitBudget: Number(this.state.updatedData.unitbudget ?? ''),
        }

        switch (whatChanged) {
            case 'iwcLocation':
                calcData.loc = this.state.updatedData?.loc
                break
            case 'iwcSalesCat':
                calcData.scat = this.state.updatedData?.scat
                break
        }

        await this.api.itemRecalculate(id, calcData).then((res) => {
            const recalculatedData = {}
            Object.keys(res).forEach((key) => {
                if (changedId !== key.toLowerCase()) {
                    recalculatedData[key.toLowerCase()] = res[key]
                }
            })

            this.setState(
                {
                    updatedData: {
                        ...this.state.updatedData,
                        ...recalculatedData,
                    },
                    hasUpdate: true,
                },
                () => {
                    this.props.onRecalculate(this.state.updatedData)
                }
            )
        })
    }

    handleChange = (e, meta = {}, callback = () => {}) => {
        let key, value
        if (e === null) {
            key = meta.name
            value = null
            // Since this is a select
            window.clearedSelect = window.clearedSelect ?? []
            if (window.clearedSelect.indexOf(key) < 0) {
                window.clearedSelect.push(key)
            }
        } else if (
            meta &&
            e.hasOwnProperty('value') &&
            e.hasOwnProperty('label')
        ) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        if (key === 'cldeppct' || key === 'estdep') {
            this.props.onDataChange({ key: `${key}Updated`, value: true })
        }

        this.setState(
            {
                updatedData: {
                    ...this.state.updatedData,
                    [key]: value,
                },
                hasUpdate: true,
            },
            () => callback()
        )

        this.props.onDataChange({ key: key, value: value })

        if (key === 'descOnly') {
            const valueRtf = this.htmlToRtfConverter
                .convertHtmlToRtf(e.target.html)
                .replaceAll('\\~', ' ')

            this.props.onDataChange({ key: `${key}Rtf`, value: valueRtf })
            this.props.onDataChange({
                key: `desc`,
                value: `${
                    this.state.updatedData.itemName ?? this.props.data.itemName
                } ${value}`,
            })
            this.props.onDataChange({
                key: `descrtf`,
                value: `${
                    this.state.updatedData.itemName ?? this.props.data.itemName
                } ${valueRtf}`,
            })
        }

        if (key === 'loc') {
            this.props.onDataChange({ key: 'defaultLocation', value: e })
        } else if (key === 'scat') {
            this.setState({
                defaultSalesCategory: e,
            })
            this.props.onDataChange({ key: 'defaultSalesCategory', value: e })
        }
    }

    formatQuantityInput = (event) => {
        let value = event.target.value.replace(/[^0-9.]/g, '')
        let [whole, decimal] = value.split('.')
        // If there's a decimal part, limit it to 2 characters
        if (decimal !== undefined) {
            decimal = decimal.slice(0, 2)
        }
        // Combine the whole number and decimal parts
        value = decimal !== undefined ? `${whole}.${decimal}` : whole

        if (value.length > 13) {
            displayAlertError('The input cannot exceed 13 characters')
            value = value.slice(0, 13)
        }
        event.target.value = value
    }

    // Use PATCH to write general properties back to the Item when the user saves.  For areas of the UI that need to update in  other fields  when user changes while they are editing, call recalculate.

    async setDefaultLocation() {
        const locationCode =
            this.state.updatedData?.loc ??
            (this.state.data?.loc && !this.isClearedSelect('loc')
                ? this.state.data?.loc
                : null) ??
            null

        if (locationCode && !this.state.updatedData?.defaultLocation) {
            const location = await this.api.getLocationByCode(
                locationCode,
                this.projectId,
                true
            )
            this.setState({
                defaultLocation: location,
            })
        }
    }

    async setDefaultSalesCategory() {
        let { defaultSalesCategory } = this.state.updatedData
        const scat = this.state.data?.scat
        if (!defaultSalesCategory && scat) {
            defaultSalesCategory = await this.api.getSalesCategorySummaryByCode(
                scat,
                true
            )
        }
        this.setState({
            defaultSalesCategory,
        })
    }

    defaultUnitOfMeasure() {
        if (!this.state.uoms.list || this.state.uoms.list.length === 0) {
            return { value: '', label: 'Please select' }
        }
        let existingUnit
        const selectedUnit = this.state.uoms.list.filter((unit) => {
            if (unit.value === this.state.updatedData.unitmeasure) {
                return true
            }

            if (
                this.state.data.unitmeasure === unit.value &&
                !this.isClearedSelect('unitmeasure')
            ) {
                existingUnit = unit
            }
        })[0]

        return (
            selectedUnit ??
            existingUnit ?? { value: '', label: 'Please select' }
        )
    }

    handleChangeAndRecalculate = (e, meta = {}) => {
        const id =
            e['recalculate-id'] ??
            e?.target.getAttribute?.('recalculate-id') ??
            e?.target['recalculate-id']
        const changedId =
            e?.id ?? e?.target?.getAttribute?.('id') ?? e?.target['id']

        this.handleChange(e, meta, () => {
            this.recalculateHandler(id, changedId)
        })
    }

    onAcceptedFiles = (file) => {
        this.props.onAcceptedFiles(file)
    }

    onRemoveFiles = () => {
        this.props.onRemoveFiles()
    }

    handleSelectAllChecks = () => {
        let checks = []
        if (
            this.state.checks.length !== this.props.data.itemComponents.length
        ) {
            checks = this.props.data.itemComponents.map((item) => {
                return item.id
            })
        }

        this.setState({
            checks: checks,
        })
    }

    handleCheck = (id) => {
        const checks = this.state.checks
        const index = checks.findIndex((_id) => _id === id)

        index > -1 ? checks.splice(index, 1) : checks.push(id)

        this.setState({
            checks: checks,
        })
    }

    refreshDataAfterDelete = (e) => {
        this.setState({
            checks: [],
            showModal: false,
            deleteResponses: [],
            componentIsLoaded: false,
        })
        this.refreshData()
    }

    handleDelete = async () => {
        if (!this.state.checks.length) {
            return
        }

        let workers = []

        for (const id of this.state.checks) {
            const params = {
                deleteType: 'dmriTestOnly',
                objectType: 'objComponent',
                objectCodeOrId: id,
            }
            workers.push(await this.api.postJson('deleteobjects', params))
        }

        if (workers) {
            HandleDeleteWorker(
                workers,
                {
                    moduleSingular: 'component',
                    modulePlural: 'components',
                    reference: 'items',
                    objectType: 'objComponent',
                },
                false,
                (modalState) => {
                    this.setState(modalState)
                }
            )
        }
    }

    handleAddCompClick = async (e, redirect = true) => {
        e.preventDefault()
        const isSaved = await this.props.editItem(false)
        if (isSaved && redirect === true) {
            location.href = generatePath(URI.project.item.componentAdd, {
                id: this.props.params.id,
                itemId: this.props.params.itemId
                    ? this.props.params.itemId
                    : this.props.newItemId,
            })
        }
    }

    renderExtendedBudget() {
        const quantity = Number(
            this.state.updatedData.quantity ?? this.props.data.quantity
        )
        const budget = Number(
            this.state.updatedData.budget ?? this.props.data.budget
        )

        if (!quantity || !budget) {
            return ''
        }

        const extendedBudget = quantity * budget

        return extendedBudget || extendedBudget === 0
            ? this.getFormattedNumber(extendedBudget)
            : ''
    }

    isClearedSelect(name) {
        return window.clearedSelect.includes(name)
    }

    handleClickEditComponent = async (e, url) => {
        e.preventDefault()
        if (this.state.hasUpdate === true) {
            await this.handleAddCompClick(e, false)
        }

        this.props.navigate(url)
    }

    handleSelectChange = (e, meta = {}) => {
        this.handleChangeAndRecalculate(
            {
                id: meta?.name,
                value: e?.value,
                'recalculate-id':
                    meta?.name === 'loc' ? 'iwcLocation' : 'iwcSalesCat',
                label: e?.label,
            },
            meta
        )
    }

    renderInputBar() {
        return (
            <InputBar className="medium">
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Client Deposit %
                        </Form.Label>
                        <div className="form-group-extra">
                            <Form.Control
                                type="number"
                                placeholder="0%"
                                value={
                                    this.state.updatedData.cldeppct ||
                                    this.props.data.cldeppct ||
                                    this.props.project.clientdep
                                }
                                id="cldeppct"
                                recalculate-id="iwcDepositPercent"
                                onChange={this.handleChangeAndRecalculate}
                                size="sm"
                            />
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Deposit Requested
                        </Form.Label>
                        <div className="form-group-extra">
                            <FormCurrencyInput
                                sizeClass={'sm'}
                                value={
                                    this.state.updatedData.estdep ??
                                    this.props.data?.estdep ??
                                    0
                                }
                                onValueChange={(value) => {
                                    const e = {
                                        target: {
                                            id: 'estdep',
                                            value: value,
                                        },
                                    }
                                    this.handleChange(e)
                                }}
                            />
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Est. Cost
                        </Form.Label>
                        <div className="form-group-extra">
                            <FormCurrencyInput
                                sizeClass={'sm'}
                                defaultValue={this.getFormattedNumber(
                                    this.state.updatedData.esttcost ??
                                        this.props.data.esttcost
                                )}
                                onValueChange={(value) => {
                                    const e = {
                                        target: {
                                            id: 'esttcost',
                                            value: value,
                                        },
                                    }
                                    this.handleChange(e)
                                }}
                                disabled={this.props.isEditing ?? false}
                            />
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Est. Price
                        </Form.Label>
                        <div className="form-group-extra">
                            <FormCurrencyInput
                                sizeClass={'sm'}
                                defaultValue={this.getFormattedNumber(
                                    this.state.updatedData.esttprice ??
                                        this.props.data.esttprice
                                )}
                                onValueChange={(value) => {
                                    const e = {
                                        target: {
                                            id: 'esttprice',
                                            value: value,
                                        },
                                    }
                                    this.handleChange(e)
                                }}
                                disabled={this.props.isEditing ?? false}
                            />
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    render() {
        return this.renderView(
            <>
                <Row xs={1}>
                    <Col lg={4} className="mb-3 mb-lg-0">
                        <MyDropzone
                            image={
                                this.props.uploadedImage ??
                                this.props.data.primaryImage
                            }
                            onAcceptedFiles={this.onAcceptedFiles}
                            onRemoveFiles={this.onRemoveFiles}
                        />
                    </Col>
                    <Col lg={8}>
                        <Form.Group>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Name
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        id="itemName"
                                        maxLength="45"
                                        value={
                                            this.state.updatedData.itemName ??
                                            this.props.data.itemName
                                        }
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0 pt-2">
                                        Description
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <WysiwygEditor
                                        id="descOnly"
                                        keepEmptySpaces
                                        onChange={this.handleChange}
                                    >
                                        {this.state.descHtml}
                                    </WysiwygEditor>
                                </Col>
                            </Row>

                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Location
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <AsyncLocationsDropdown
                                        defaultValue={
                                            this.state.updatedData
                                                ?.defaultLocation ??
                                            this.state.defaultLocation
                                        }
                                        name="loc"
                                        recalculate-id="iwcLocation"
                                        className="react-select"
                                        placeholder="Please select"
                                        isClearable={true}
                                        urlQuery={this.state.locationsQry}
                                        creatable={true}
                                        projectCode={this.props.project?.proj}
                                        onChange={this.handleSelectChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Quantity
                                    </Form.Label>
                                </Col>
                                <Col lg={3}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Please enter"
                                        id="quantity"
                                        recalculate-id="iwcQuantity"
                                        value={
                                            this.state.updatedData.quantity ??
                                            this.props.data.quantity
                                        }
                                        onKeyPress={(event) => {
                                            if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault()
                                            }
                                        }}
                                        onInput={this.formatQuantityInput}
                                        onChange={
                                            this.handleChangeAndRecalculate
                                        }
                                    />
                                </Col>

                                <Col
                                    lg={2}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Units of Measure
                                    </Form.Label>
                                </Col>
                                <Col lg={4}>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        defaultValue={this.defaultUnitOfMeasure()}
                                        options={this.state.uoms.list}
                                        name="unitmeasure"
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleChange}
                                        menuPortalTarget={document.body}
                                        isClearable={true}
                                        styles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 99999,
                                            }),
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Unit Budget
                                    </Form.Label>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group-extra">
                                        <FormCurrencyInput
                                            sizeClass={'lg'}
                                            value={
                                                this.state.updatedData
                                                    .unitbudget ?? ''
                                            }
                                            onValueChange={(value) => {
                                                const e = {
                                                    target: {
                                                        id: 'unitbudget',
                                                        value: value,
                                                        'recalculate-id':
                                                            'iwcUnitBudget',
                                                    },
                                                }
                                                this.handleChangeAndRecalculate(
                                                    e
                                                )
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    lg={2}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Extended Budget
                                    </Form.Label>
                                </Col>
                                <Col lg={4}>
                                    <div className="form-group-extra">
                                        <FormCurrencyInput
                                            sizeClass={'lg'}
                                            value={
                                                this.state.updatedData.budget ??
                                                this.props.data.budget ??
                                                this.renderExtendedBudget()
                                            }
                                            onValueChange={(value) => {
                                                const e = {
                                                    target: {
                                                        id: 'budget',
                                                        value: value,
                                                        'recalculate-id':
                                                            'iwcExtendedBudget',
                                                    },
                                                }
                                                this.handleChangeAndRecalculate(
                                                    e
                                                )
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Sales Category *
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <AsyncSalesCategoriesDropdown
                                        defaultValue={
                                            this.state.defaultSalesCategory
                                        }
                                        name="scat"
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleSelectChange}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 99999,
                                            }),
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="d-block my-5 pt-4">{this.renderInputBar()}</div>

                <div>
                    <div className="bg-secondary-grey px-4">
                        <Row
                            xs={'auto'}
                            className="py-4 justify-content-between align-items-center"
                        >
                            <Col>
                                <h6 className="fw-bold mb-0">
                                    Purchase Components Information
                                </h6>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <SecureBootstrapButton
                                            attributeNo={14}
                                            attributeType={
                                                SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                            }
                                            as={Link}
                                            to="#"
                                            variant="ivory"
                                            size="sm"
                                            className={`btn-icon btn-action ${
                                                this.state.checks.length === 0
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            disabled={
                                                this.state.checks.length === 0
                                            }
                                            onClick={this.handleDelete}
                                        >
                                            <i className="ri-close-line"></i>{' '}
                                            Delete
                                        </SecureBootstrapButton>
                                    </Col>
                                    <Feature
                                        name={
                                            FeatureFlags.AddInventoryItemComponent
                                        }
                                    >
                                        {this.props.params.itemId && (
                                            <Col>
                                                <AddDropdown
                                                    locationQuery={
                                                        this.state.locationsQry
                                                    }
                                                    projectId={
                                                        this.props.params.id
                                                    }
                                                    type={
                                                        InventoryTypes.Component
                                                    }
                                                    itemId={
                                                        this.props.params.itemId
                                                    }
                                                />
                                            </Col>
                                        )}
                                    </Feature>
                                    <Col>
                                        <SecureBootstrapButton
                                            attributeNo={14}
                                            attributeType={
                                                SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                            }
                                            variant="primary"
                                            size="sm"
                                            className={`btn-icon btn-action`}
                                            onClick={this.handleAddCompClick}
                                        >
                                            <i className="ri-add-line"></i> Add
                                        </SecureBootstrapButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col className="position-relative table-action-bar">
                            <div className="table-gradient">
                                <Table striped responsive className="a-table">
                                    <thead>
                                        <tr>
                                            <th
                                                align="middle"
                                                className="mw-70px"
                                            >
                                                <div className="d-flex justify-content-center">
                                                    <Form.Check
                                                        label=""
                                                        type="checkbox"
                                                        checked={
                                                            this.state.checks
                                                                .length ===
                                                            this.state.data
                                                                .itemComponents
                                                                ?.length
                                                        }
                                                        className={
                                                            this.state.checks
                                                                .length ===
                                                            this.state.data
                                                                .itemComponents
                                                                ?.length
                                                                ? 'line'
                                                                : ''
                                                        }
                                                        onChange={
                                                            this
                                                                .handleSelectAllChecks
                                                        }
                                                    />
                                                </div>
                                            </th>
                                            <th>Comp.#</th>
                                            <th>Description</th>
                                            <th>Vendor</th>
                                            <th>Type</th>
                                            <th>Est. Cost</th>
                                            <th>Est. Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(
                                            this.state.data.itemComponents || []
                                        ).map((item, i) => (
                                            <tr key={i} data-id={item.id}>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            label=""
                                                            type="checkbox"
                                                            checked={this.state.checks.includes(
                                                                item.id
                                                            )}
                                                            onChange={() => {
                                                                this.handleCheck(
                                                                    item.id
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-row align-content-center align-items-center">
                                                        <SecureLink
                                                            attributeNo={14}
                                                            attributeType={
                                                                SECURITY_ATTRIBUTE_TYPES.DenyEdit
                                                            }
                                                            to={this.getComponentLink(
                                                                item
                                                            )}
                                                            className="text-charcoal hover-view-icon"
                                                            onClick={(e) => {
                                                                this.handleClickEditComponent(
                                                                    e,
                                                                    this.getComponentLink(
                                                                        item
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            {item.comp}
                                                        </SecureLink>
                                                        {item.numAttachments >
                                                            0 && (
                                                            <Button
                                                                as={Link}
                                                                to={generatePath(
                                                                    URI.project
                                                                        .item
                                                                        .componentEditPage,
                                                                    {
                                                                        id: item.projectId,
                                                                        itemId: item.itemId,
                                                                        compId: item.id,
                                                                        page: 'documents',
                                                                    }
                                                                )}
                                                                variant="ivory"
                                                                size="sm"
                                                                className="border-0 fsx-16 ri-attachment-2 square ms-1"
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {truncateCharCount(
                                                        item.desc,
                                                        100
                                                    )}
                                                </td>
                                                <td>{item.vendorName}</td>
                                                <td>
                                                    {
                                                        this.props.data
                                                            .componentTypes[
                                                            `${item.comptype}`
                                                        ]
                                                    }
                                                </td>
                                                <td>
                                                    <div>
                                                        {currencyFormat(
                                                            item.usedisc === 1
                                                                ? item.feecost
                                                                : item.estcost,
                                                            '$'
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {currencyFormat(
                                                            item.estprice,
                                                            '$'
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>

                <DeleteModal
                    refreshData={this.refreshDataAfterDelete.bind(this)}
                    show={this.state.showModal}
                    hideModal={() =>
                        this.setState((prev) => (prev.showModal = false))
                    }
                    responses={this.state.deleteResponses}
                />
            </>
        )
    }
}

export default WithRouter(ItemAddInfo)
