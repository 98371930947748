import React, { useCallback, useEffect, useState } from 'react'
import { toggleDMPageLoader } from '../../../utilities/DOM'
import { ApiService } from '../../../lib/api/HttpService'
import { BudgetToolbar } from './BudgetToolbar'
import { BudgetTable } from './BudgetTable'
import BudgetAddEditModal from './BudgetAddEditModal'
import {
    displayAlert,
    displayAlertLoader,
    hideAlertLoader,
} from '../../../utilities/Response'
import MSG from '../../../defaults/Message'
import {
    fetchBudget,
    deleteBudget,
    createBudget,
    updateBudget,
    updateBudgetToolbar,
    fetchBudgetToolbarInfo,
} from './BudgetService'
import { debounce } from 'lodash'
import { Constants } from '../../../app/constants/Constants'
import ConfirmationModal from 'app/components/modal/ConfirmationModal'

function Budget({ proj, id, company, modalTitle }) {
    const [searchParams, setSearchParams] = useState({})
    const [isSearching, setSearching] = useState(false)
    const [editedBudget, setEditedBudget] = useState(undefined)
    const [selectedItems, setSelectedItems] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [pagination, setPagination] = useState({ size: 20, page: 1 })
    const [currentCompany, setCompany] = useState(company)
    const [isLoading, setLoading] = useState(false)
    const [isOverride, setOverride] = useState(false)
    const [budgetTotal, setBudgetTotal] = useState(0)
    const [overallWarning, setOverallWarning] = useState(0)
    const [overallTolerance, setOverallTolerance] = useState(0)

    const [data, setData] = useState({
        headers: headers,
        budgets: [],
    })

    useEffect(() => {
        toggleDMPageLoader({ destroy: true })
        const fetchInitialBudget = async () => {
            setLoading(true)
            await getBudgetToolbarInfo(id)
            await getBudget()
            setLoading(false)
        }

        fetchInitialBudget()
        getCompany()
    }, [])

    useEffect(() => {
        debouncedGetBudget()
    }, [searchParams])

    const getBudget = async (page = 1, size = 20) => {
        try {
            const budgets = await fetchBudget(buildFilter(page, size))
            setData({ ...data, budgets: budgets })
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        }
    }

    const getAllBudget = async () => {
        try {
            const budgets = await fetchBudget(`?$filter=proj eq '${proj}'`)
            const totalBudget = budgets.reduce(
                (acc, current) => acc + parseFloat(current.budgett),
                0
            )
            setBudgetTotal(totalBudget)
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        }
    }

    const debouncedGetBudget = useCallback(
        debounce(() => {
            getBudget()
        }, 500),
        [searchParams]
    )

    const getBudgetToolbarInfo = async (id) => {
        if (!id) {
            return
        }

        try {
            const [budgetsInfo] = await fetchBudgetToolbarInfo(
                `?$filter=id eq ${id}`
            )
            setData({
                ...data,
                budgett: budgetsInfo?.budgett || 0,
                budgettol: budgetsInfo?.budgettol || 0,
                budgetwarn: budgetsInfo?.budgetwarn || 0,
                budgettoverride: budgetsInfo?.budgettoverride || false,
            })
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        }
    }

    const getCompany = async () => {
        if (!company) {
            const company = await new ApiService().getCompany()
            setCompany(company)
        }
    }

    const handleSort = async (sortKey) => {
        let sortDirection = ''
        const newData = { ...data }
        newData.headers.forEach((header) => {
            if (header.sortKey === sortKey) {
                header.sortDirection =
                    header.sortDirection === 'asc' ? 'desc' : 'asc'
                sortDirection = header.sortDirection
            } else {
                header.sortDirection = 'asc'
            }

            header.isActive = header.sortKey === sortKey
        })

        setData({ ...newData })

        await getBudget()
    }

    const buildFilter = (page, size) => {
        const filters = []
        Object.keys(searchParams).forEach((key) => {
            const param = searchParams[key]
            if (param.type === 'number' && param.value.length) {
                filters.push(
                    `(contains(cast(${key}, 'Edm.String'),'${encodeURI(
                        param.value
                    )}'))`
                )
            } else if (param.type === 'range') {
                if (param.min) filters.push(`${key} ge ${param.min}`)
                if (param.max) filters.push(`${key} le ${param.max}`)
            } else {
                filters.push(`contains(${key}, '${searchParams[key].value}')`)
            }
        })
        let filter = `?$filter=proj eq '${proj}'`
        filter += filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        const activeSort = data.headers.find((header) => header.isActive)
        if (activeSort) {
            filter += `&$orderby=${activeSort.sortKey} ${activeSort.sortDirection}`
        }
        filter += `&$top=${size + 1}&$skip=${(page - 1) * size}`
        return filter
    }

    const handleToggleSearch = () => {
        const _isSearching = !isSearching
        setSearching(_isSearching)

        if (!_isSearching) {
            setSearchParams({})
        }
    }

    const handleSearch = (key, value, type) => {
        const newSearchParams = { ...searchParams }

        if (type === 'range') {
            const newKey = key.split('-')[0]
            let { min, max } = searchParams[newKey]
            if (key.includes('min')) {
                min = value
            } else {
                max = value
            }
            newSearchParams[newKey] = {
                value: undefined,
                type,
                min,
                max,
            }
        } else {
            newSearchParams[key] = { value, type }
        }
        setSearchParams(newSearchParams)
    }

    const handleOverrideChange = async (e) => {
        setOverride(e.target.checked)
        setBudgetTotal(budgetTotal)
        if (!e.target.checked) {
            getAllBudget()
        }
    }

    const handleOverallWarningChange = () => {
        setOverallWarning()
    }

    const handleOverallToleranceChange = (value) => {
        if (Number.isNaN(value)) {
            setOverallTolerance(0)
        } else {
            setOverallTolerance(value)
        }
    }

    const handleSave = async (data) => {
        displayAlertLoader(MSG.loading.update.msg)

        try {
            await updateBudgetToolbar(id, data)
            displayAlert('success', MSG.success.msg)
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        }
    }

    const handleBudget = (budget) => {
        setEditedBudget(budget)
    }

    const handleModalCancel = () => {
        setEditedBudget(undefined)
    }

    const handleShowAddModal = () => {
        setEditedBudget({})
    }

    const handleModalConfirm = async (isNew, data) => {
        try {
            if (isNew) {
                await createBudget({ ...data, proj: proj })
                displayAlert('success', 'Budget has been saved successfully')
            } else {
                const newData = { ...data }
                newData.updatedcode = newData.code
                newData.updatedtype = parseInt(newData.type)
                newData.code = editedBudget.code
                newData.type = parseInt(editedBudget.type)
                await updateBudget(newData)
                displayAlert('success', 'Budget has been updated successfully')
            }
            getBudget(pagination.page, pagination.size)
            setEditedBudget(undefined)
        } catch (error) {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                const regex =
                    /Violation of PRIMARY KEY constraint '[^']+'.*The duplicate key value is \(.+\)\./
                if (regex.test(error.response.data.userError)) {
                    displayAlert('danger', 'Budget already exists')
                } else {
                    displayAlert('danger', error.response.data.userError)
                }
            }
        }
    }
    const handleSelect = (items) => {
        setSelectedItems(items)
    }
    const handleDelete = async () => {
        try {
            setShowDeleteModal(false)
            displayAlertLoader(MSG.loading.delete.ProjectBudget)

            const failedItems = await deleteBudget(selectedItems)
            if (failedItems.length) {
                displayAlert(
                    'danger',
                    'Some of the selected items could not be deleted'
                )
            } else {
                hideAlertLoader()
            }

            displayAlert('success', 'Successfully deleted selected budget(s)')
            setSelectedItems(failedItems)
        } catch {
            if (error.response === undefined) {
                displayAlert('danger', error.message)
            } else {
                displayAlert('danger', error.response.data.userError)
            }
        } finally {
            getBudget(pagination.page, pagination.size)
        }
    }

    const handlePaginationChange = (pagination) => {
        setPagination(pagination)

        getBudget(pagination.page, pagination.size)
    }

    return (
        <>
            <BudgetToolbar
                budgetData={data}
                id={id}
                isSearching={isSearching}
                onSearch={handleToggleSearch}
                onAdd={handleShowAddModal}
                onDelete={() => setShowDeleteModal(true)}
                onOverrideChange={handleOverrideChange}
                onSave={handleSave}
                setOverride={setOverride}
                budgetTotal={budgetTotal}
                setBudgetTotal={setBudgetTotal}
                setOverallTolerance={setOverallTolerance}
                setOverallWarning={setOverallWarning}
                isOverride={isOverride}
                getAllBudget={getAllBudget}
                overallWarning={overallWarning}
                onOverallWarningChange={handleOverallWarningChange}
                overallTolerance={overallTolerance}
                onOverallToleranceChange={handleOverallToleranceChange}
                isDeleteAvailable={selectedItems.length > 0}
            />
            <BudgetTable
                data={data}
                selectedItems={selectedItems}
                isLoading={isLoading}
                isSearching={isSearching}
                onSort={handleSort}
                onSearch={handleSearch}
                onBudget={handleBudget}
                onPaginationChange={handlePaginationChange}
                onSelect={handleSelect}
            />
            {editedBudget && (
                <BudgetAddEditModal
                    title={modalTitle}
                    budget={editedBudget}
                    company={currentCompany}
                    proj={proj ?? Constants.DEFAULT_PROJECT_ID}
                    projId={id}
                    onCancel={handleModalCancel}
                    onConfirm={handleModalConfirm}
                />
            )}
            {showDeleteModal && (
                <ConfirmationModal
                    show={true}
                    title="Confirm Deletion"
                    message="Are you sure you want to delete selected
    entries? Once you delete, it's gone for good."
                    confirmAction={handleDelete}
                    toggleModal={() => setShowDeleteModal(false)}
                />
            )}
        </>
    )
}

const headers = [
    {
        label: 'Type',
        sortKey: 'typeName',
        isActive: false,
        sortDirection: 'asc',
    },
    {
        label: 'Code',
        sortKey: 'code',
        isActive: false,
        sortDirection: 'asc',
    },
    {
        label: 'Name',
        sortKey: 'codeName',
        isActive: false,
        sortDirection: 'asc',
    },
    {
        label: 'Note',
        sortKey: 'note',
        isActive: false,
        sortDirection: 'asc',
    },
    {
        label: 'Budget',
        sortKey: 'budgett',
        isActive: false,
        sortDirection: 'asc',
        type: 'number',
    },
    {
        label: 'Client Deposit Override',
        sortKey: undefined,
        isActive: false,
        sortDirection: undefined,
    },
    {
        label: 'Markup Override',
        sortKey: undefined,
        isActive: false,
        sortDirection: undefined,
    },
]

export default Budget
