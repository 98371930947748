import React from 'react'
import ThreadComponent from '../../ThreadComponent'
import { observer } from 'mobx-react'
import ClientAddEditHeader from './components/ClientAddEditHeader'
import { WithRouter, pathParam } from '../../../utilities/Router'
import { Container } from 'react-bootstrap'
import ClientAddEditInfo from './components/ClientAddEditInfo'
import ClientAddEditViewModel from './ClientAddEditViewModel'
import AddressAddEditViewModel from '../Address/AddressAddEditViewModel'
import ClientAddEditNotes from './components/ClientAddEditNotes'
import URI from '../../../defaults/RoutesDefault'
import { getLocalStorage } from '../../../utilities/LocalStorage'
import { displayAlert, handleResponseAlert } from '../../../utilities/Response'
import { ClientValidation } from './validations/ClientValidation'
import { validateInput } from '../../components/validators/Helpers'
import { delay, isEmpty } from 'lodash'
import { ErrorObjects } from '../../../defaults/Error'
import Documents from '../../../templates/modules/documents/Documents'

interface Props {
    clientAddEditModel: ClientAddEditViewModel
    addressAddEditModel: AddressAddEditViewModel
    params?: any
    navigate?: any
}

interface State {
    data: any
    isLoading: boolean
    isDraft: boolean
    numAttachments?: number
}

class ClientAddEditComponent extends ThreadComponent<Props, State> {
    readonly clientAddEditModel: ClientAddEditViewModel
    readonly addressAddEditModel: AddressAddEditViewModel

    constructor(props: Props) {
        super(props)

        this.clientAddEditModel = props.clientAddEditModel
        this.addressAddEditModel = props.addressAddEditModel

        this.state = {
            data: [],
            isLoading: false,
            isDraft: false,
            numAttachments: undefined,
        }

        this.setIsLoaded(true)
    }

    async componentDidMount() {
        this.setTitle('Add Clients').setBodyClass()

        this.clientAddEditModel.reset()
        this.dMloader(true)

        try {
            if (this.props.params.id !== undefined) {
                this.clientAddEditModel.setId(this.props.params.id)
                await this.clientAddEditModel.componentDidMount()
                this.addressAddEditModel.setClientCode(
                    this.clientAddEditModel.client.client
                )
                await this.addressAddEditModel.componentDidMount()

                if (isEmpty(this.clientAddEditModel.client)) {
                    throw ErrorObjects.Notfound
                }
            } else {
                await this.clientAddEditModel.componentDidMount()
                await this.addressAddEditModel.componentDidMount()
                this.resetLocalStorage()
            }

            this.retrieveDraft()
            window.localStorage.setItem('isDraft', 'false')
        } catch (error) {
            this.setIsFound(false).setError(error, true)
        }

        this.dMloader(false)
    }

    componentWillUnmount(): void {
        this.resetLocalStorage()
    }

    retrieveDraft() {
        const isDraft = getLocalStorage('isDraft')

        const clientData = isDraft
            ? getLocalStorage('clientData', true)
            : this.clientAddEditModel.clientData
        const addressData = isDraft
            ? getLocalStorage('addressData', true)
            : this.addressAddEditModel.addressData
        this.clientAddEditModel.setClientData('address', addressData)

        if (!isDraft) {
            window.localStorage.setItem(
                'addressData',
                JSON.stringify(addressData)
            )
        }

        this.setState({
            data: this.clientAddEditModel.clientData,
        })
    }

    setIsLoading(isLoading: boolean): void {
        this.setState({
            isLoading: isLoading,
        })
    }

    resetLocalStorage(): void {
        window.localStorage.removeItem('clientData')
        window.localStorage.removeItem('addressData')
        window.localStorage.setItem('isDraft', 'false')
        window.localStorage.removeItem('authToken')
    }

    switchTab = (e: any) => {
        this.retrieveDraft()
    }

    onDraft: any = (e: any) => {
        window.localStorage.setItem('isDraft', 'true')
        this.setState({
            isDraft: true,
        })
        this.retrieveDraft()
    }

    handleClickSave = async (e: any) => {
        const clientData: any = getLocalStorage('clientData', true)
        const addressData: any = getLocalStorage('addressData', true)
        const validated = await validateInput(
            ClientValidation,
            clientData.address
        )

        delete clientData.defaultSalesperson
        delete clientData.defaultTaxCode

        if (!validated.status) {
            const request = this.clientAddEditModel.modifyClient(
                this.clientAddEditModel.client.id,
                clientData
            )
            this.setIsLoading(true)
            handleResponseAlert(request, (res: any) => {
                this.setIsLoading(false)
                addressData.code = clientData.client
                addressData.addressTypeEnum = 'Client'
                addressData.addresstype = 4

                this.setIsLoading(true)
                this.addressAddEditModel.modifyAddress(
                    addressData.id,
                    addressData,
                    () => {
                        if (!res.code) {
                            this.redirectPage(e)
                        } else {
                            this.setIsLoading(false)
                            const client = document.getElementById(
                                'client'
                            ) as any
                            client.disabled = false
                            client.readOnly = false
                        }
                    }
                )
            })
        } else {
            displayAlert('danger', validated.message)
        }
    }

    redirectPage(e: any) {
        this.resetLocalStorage()
        delay(() => {
            if (e.target.name === 'saveNew') {
                window.location.href = pathParam(URI.contact.clientAdd, {
                    tab: 'info',
                })
            } else {
                this.props.navigate(URI.contact.client)
            }
        }, 500)
    }

    getActiveTabName() {
        return this.props.params && this.props.params.tab
            ? this.props.params.tab
            : 'info'
    }

    displayContent(): JSX.Element {
        const tab = this.getActiveTabName()
        const { data } = this.state

        if (tab === 'info' || tab === 'infonew')
            return (
                <ClientAddEditInfo
                    onDraft={this.onDraft}
                    data={data}
                    navigate={this.props.navigate}
                />
            )

        if (tab === 'notes')
            return <ClientAddEditNotes onDraft={this.onDraft} data={data} />

        if (tab === 'documents')
            return (
                <Documents
                    objectType="Client"
                    objectId={this.props.params.id}
                    onRefresh={(numAttachments: number) => {
                        this.setState({ numAttachments: numAttachments })
                    }}
                />
            )

        return <></>
    }

    render(): JSX.Element {
        const page = this.getActiveTabName()

        return this.renderView(
            <>
                <ClientAddEditHeader
                    activeMenu={page}
                    clientName={this.clientAddEditModel.client?.ClientName}
                    clientId={this.clientAddEditModel.client?.id}
                    onSave={this.handleClickSave}
                    onSwitchTab={this.switchTab}
                    isLoading={this.state.isLoading}
                    isDraft={this.state.isDraft}
                    numAttachments={
                        this.state.numAttachments ??
                        this.clientAddEditModel.client?.numAttachments ??
                        0
                    }
                    isSaveHidden={this.getActiveTabName() === 'documents'}
                />

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.displayContent()}</Container>
                </div>
            </>
        )
    }
}

export default WithRouter(observer(ClientAddEditComponent))
