import React from 'react'
import { Col, Row, Form, Breadcrumb, Container, Button } from 'react-bootstrap'
import { WithRouter } from '../../../helpers/Router'
import ThreadComponent from '../ThreadComponent'
import { HeaderLight } from '../../components/Header'
import { FooterFormAction } from '../../components/Form'
import { Link, NavLink } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'

class ProjectAddItemsStore extends ThreadComponent {
    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.project.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.base }}
                            linkAs={Link}
                        >
                            Projects
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.view }}
                            linkAs={Link}
                        >
                            JOH01
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.project.view }}
                            linkAs={Link}
                        >
                            Items
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Store Item in Catalog</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>Store Item in Catalog</HeaderLight.Title>

                    {/* Submit Button */}
                    <Button variant="primary">Save</Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    render() {
        return (
            <>
                {/* Header */}
                {this.renderHeader()}

                {/* Content */}
                <div className="content-padding min-height">
                    <Container fluid>
                        <Row xs={1} lg={2} className="mb-4">
                            <Col className="mb-3 mb-lg-0">
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            New Catalog No.
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Color/Finish
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col
                                        lg={3}
                                        className="text-lg-end mb-2 mb-lg-0"
                                    >
                                        <Form.Label className="mb-0">
                                            Style/Size
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please enter"
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ProjectAddItemsStore)
