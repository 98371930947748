import React from 'react'
import { Outlet } from 'react-router-dom'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'

class Project extends ThreadComponent {
    render() {
        return (
            <>
                <Outlet />
            </>
        )
    }
}

export default WithRouter(Project)
