import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiService } from 'lib/api/HttpService'
import BaseDropdown from './BaseDropdown'

const api = new ApiService()

const getSalesCategories = async () => await api.getSalesCategoriesSummaries()

const SalesCategoriesDropdown = (props) => {
    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'salesCategories'],
        queryFn: getSalesCategories,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const options =
        data?.map((option) => ({
            label: `${option.value ?? ''} ${
                option.key ? `[${option.key}]` : ''
            }`.trim(),
            value: option.key,
        })) || []

    return <BaseDropdown {...props} options={options} isLoading={isLoading} />
}

export default SalesCategoriesDropdown
