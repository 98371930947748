import React, { useEffect, useState } from 'react'
import SelectCreatableAsyncPaginate from './SelectCreatableAsyncPaginate'
import getAsyncReactSelectOptions, {
    getOptionLabel,
    getOptionValue,
} from './utils/AsyncDropdownOptions'
import { AsyncPaginate } from 'react-select-async-paginate'
import AsyncDropdownLoadingIndicator from './utils/AsyncDropdownLoadingIndicator'
import {
    getServiceFn,
    overrideQueryProperty,
} from './utils/AsyncDropdownFunctions'
import ClientQuickAddModal from '../modal/ClientQuickAddModal'
import { isEmpty } from 'lodash'

/**
 * AsyncClientsDropdown
 *
 * @param props                             - the `react-select` properties
 * @param string     `props.urlQuery`       - set the default query parameters
 * @param boolean    `props.creatable`      - set true to enable create option
 * @param string     `props.fieldKey`       - used for search and sort if sort is not added to urlQuery
 * @param object     `props.value`          - default value with `label` and `value` properties
 * @param object     `props.defaultValue`   - similar with value with `label` and `value` as properties
 * @param string     `props.valueKey`       - set the property name/key to be used as the select value: 'id', 'value', 'key'
 * @param any        `(...rest)`            - the rest of the `react-select` properties
 *
 * @returns `React.Component`
 */
export default function AsyncClientsDropdown(props: any) {
    const type = 'client'
    const valueKey = props.valueKey ?? 'key'
    const [value, setValue] = useState(
        (props.value ?? props.defaultValue) as any
    )

    const { loadOptions } = getAsyncReactSelectOptions({
        props: overrideQueryProperty(type, props),
        getDataFn: getServiceFn(type),
        field: props.fieldKey ?? 'ClientName',
        searchKeys: ['client'],
        valueKey,
    })

    /** Creation Properties */
    const [newName, setNewName] = useState('')
    const [isModal, setIsModal] = useState(false)
    const handleChange = (e: any, meta: any) => {
        setValue(e)
        props.onChange && props.onChange(e, meta)
    }
    const handleSuccessCreate = (res: any) => {
        if (!isEmpty(res)) {
            const newValue = {
                value: getOptionValue(
                    res.id,
                    res.client,
                    res.ClientName,
                    valueKey,
                    props
                ),
                label: getOptionLabel(
                    res.id,
                    res.client,
                    res.ClientName,
                    props
                ),
                name: res.ClientName,
                id: res.id,
            }

            setValue(newValue)
            props.onChange &&
                props.onChange(newValue, {
                    action: 'select-option',
                    name: props.name,
                    option: undefined,
                })
            setIsModal(false)
        }
    }
    /** !END Creation Properties */

    /* *****************
     * DID MOUNT/UPDATE
     * ***************** */
    useEffect(() => {
        setValue(props.value ?? props.defaultValue)
    }, [props.defaultValue, props.value])

    const Component =
        props.creatable === true ? SelectCreatableAsyncPaginate : AsyncPaginate

    return (
        <>
            <Component
                {...props}
                value={value}
                loadOptions={loadOptions}
                additional={{
                    page: 1,
                }}
                cacheOptions
                components={{ LoadingIndicator: AsyncDropdownLoadingIndicator }}
                onCreateOption={(value) => {
                    setNewName(value)
                    setIsModal(true)
                    props.onCreateOption && props.onCreateOption(value)
                }}
                onChange={handleChange}
            />

            {/* Create Modal when creatable is set to `true` */}
            {props.creatable === true && isModal && (
                <ClientQuickAddModal
                    hideModal={() => setIsModal(false)}
                    handleSuccess={handleSuccessCreate}
                    clientNameNew={newName}
                />
            )}
        </>
    )
}

AsyncClientsDropdown.displayName = 'AsyncClientsDropdown'
