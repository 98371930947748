import { useNavigate } from 'react-router-dom'

const usePageReload = () => {
    const navigate = useNavigate()

    const reloadPage = () => {
        navigate(0)
    }

    return reloadPage
}

export default usePageReload
