import React from 'react'
import { Dropdown, Col, Row, Table, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import ListFilter from '../../components/ListFilter'
import URI from '../../../defaults/RoutesDefault'
import { ApiService } from '../../../lib/api/HttpService'
import { currencyFormat } from '../../../helpers/Number'
import Select from 'react-select'
import { FooterFormAction } from '../../components/Form'
import { displayAlert } from '../../../utilities/Response'
import { fakeReactSelectOptions } from '../../factory/fakeFactory'

class AccountSettingsAccounts extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            checks: {},
            totalChecked: 0,
            data: [],
            data2: [],
            selectData: [],
            filteredSelectData: [],
        }

        this.api = new ApiService()
        this.updatedData = {}
        this.updatedData2 = {}
    }

    componentInit() {
        this.setTitle('Accounts | Account Settings')
    }

    async componentDidMount() {
        this.dMloader(true)
        const companyRes = await this.api.getCompany()
        const company2Res = await this.api.getCompany2()

        await this.renderOptions()

        this.renderData(companyRes, company2Res)
        this.dMloader(false)
    }

    renderData(data, data2) {
        this.setState({
            dataIsLoaded: true,
            data: data,
            data2: data2,
        })
    }

    async renderOptions() {
        const getGLAccounts = await this.api.getGLAccounts('')
        getGLAccounts.map((account) => {
            this.state.selectData.push({
                value: parseInt(account.account),
                label: account.accountn + ' (' + account.account + ')',
            })
        })

        const filteredGLAAccounts = await this.api.getGLAccounts(
            '?$filter=specacct ne 1 and specacct ne 2'
        )
        filteredGLAAccounts.map((account) => {
            this.state.filteredSelectData.push({
                value: parseInt(account.account),
                label: account.accountn + ' (' + account.account + ')',
            })
        })
    }

    handleChange = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }

            let state = this.state
            state[key] = value
            this.setState(state)
        }
        this.updatedData[key] = value
    }

    handleChange2 = (e, meta = {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }

            let state = this.state
            state[key] = value
            this.setState(state)
        }
        this.updatedData2[key] = value
    }

    handleClickSave = async (e) => {
        e.preventDefault()
        try {
            await this.api.patchCompany(this.updatedData).then((res) => {
                displayAlert('success', 'Successfully saved changes.')
            })
            await this.api.patchCompany2(this.updatedData2).then((res) => {
                displayAlert('success', 'Successfully saved changes.')
            })
        } catch (error) {
            const errorMessage =
                error.response?.data?.userError ||
                'No two accounts can be the same on this tab except for inventory and inventory freight'
            displayAlert('danger', errorMessage)
        }
    }

    render() {
        const { data, data2 } = this.state

        return (
            <>
                <Form.Group>
                    <Row xs={1} lg={2} className="mb-5">
                        <Col>
                            <Row xs={1} lg={1}>
                                <Col className="mb-2 mb-lg-0">
                                    <Row className="mb-lg-3">
                                        <Col lg={12} className="text-start">
                                            <Form.Label className="mb-0">
                                                <strong>Sales Accounts</strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewm}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="samercacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.samercacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewd}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="sadesignacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.sadesignacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewf}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="safreightacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.safreightacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewi}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="sainstallacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.sainstallacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewl}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="salaboracct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.salaboracct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewo}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="saotheracct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.saotheracct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row xs={1} lg={1}>
                                <Col className="mb-2 mb-lg-0">
                                    <Row className="mb-lg-3">
                                        <Col lg={12} className="text-start">
                                            <Form.Label className="mb-0">
                                                <strong>COGS Accounts</strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewm}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="comercacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.comercacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewd}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="codesignacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.codesignacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewf}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="cofreightacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.cofreightacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewi}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="coinstallacct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.coinstallacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewl}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="colaboracct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.colaboracct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        {data.compviewo}
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="cootheracct"
                                                options={
                                                    this.state
                                                        .filteredSelectData
                                                }
                                                defaultValue={this.state.filteredSelectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.cootheracct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-5">
                        <Col>
                            <Row xs={1} lg={1}>
                                <Col className="mb-2 mb-lg-0">
                                    <Row className="mb-lg-3">
                                        <Col lg={12} className="text-start">
                                            <Form.Label className="mb-0">
                                                <strong>
                                                    Payable Accounts
                                                </strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Vendor Deposits
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="vdepositacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.vdepositacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Vendor Discounts
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="discountacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.discountacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Accounts Payable
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="apacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.apacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Payment Processing Fees
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="feeExpenseAccount"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data2.feeExpenseAccount
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row xs={1} lg={1}>
                                <Col className="mb-2 mb-lg-0">
                                    <Row className="mb-lg-3">
                                        <Col lg={12} className="text-start">
                                            <Form.Label className="mb-0">
                                                <strong>
                                                    Receivable Accounts
                                                </strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Accounts Receivable
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="aracct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.aracct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Client Deposit
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="cdepositacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.cdepositacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Un-deposited Funds
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="undepacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.undepacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-5">
                        <Col>
                            <Row xs={1} lg={1}>
                                <Col className="mb-2 mb-lg-0">
                                    <Row className="mb-lg-3">
                                        <Col lg={12} className="text-start">
                                            <Form.Label className="mb-0">
                                                <strong>
                                                    General Ledger Accounts
                                                </strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Retained Earnings
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="retearnacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.retearnacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Suspense
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="suspenseacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.suspenseacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Work in Process
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row lg={1}>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 10000
                                                )}-min`}
                                                name="wipacct"
                                                options={this.state.selectData}
                                                defaultValue={this.state.selectData.filter(
                                                    (account) => {
                                                        return (
                                                            account.value ==
                                                            data.wipacct
                                                        )
                                                    }
                                                )}
                                                className="react-select"
                                                placeholder="Please select"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form.Group>

                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleClickSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(AccountSettingsAccounts)
