import { CurrencyType, LocaleType } from 'app/enums/utiles/CurrencyTypes'

export const formatChartCurrency = (
    amount: number,
    locale = LocaleType.EN_US,
    currency = CurrencyType.USD
): string => {
    const absAmount = Math.abs(amount)
    let formattedAmount: string

    if (absAmount >= 1000) {
        const units = ['K', 'M', 'B', 'T']
        let unitIndex = -1
        let num = absAmount

        while (num >= 1000 && unitIndex < units.length - 1) {
            num /= 1000
            unitIndex++
        }

        formattedAmount =
            new Intl.NumberFormat(locale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }).format(num) + units[unitIndex]
    } else {
        formattedAmount = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: absAmount % 1 === 0 ? 0 : 2,
            maximumFractionDigits: absAmount % 1 === 0 ? 0 : 2,
        }).format(absAmount)
    }

    const formattedChartCurrency =
        amount < 0 ? `-${formattedAmount}` : formattedAmount

    return formattedChartCurrency
}
