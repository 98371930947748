export type TPaymentProcessorResponse = {
    id: number
    instanceId: string
    onboardingStatus: `${OnboardingStatus}`
    processor: string
    processorAccountId: string
    onboardingUrl: string
    detailsSubmitted: boolean
    stripePublicKey: string
    allowsCreditCard: boolean
    allowsAch: boolean
    plaidEnvironment: string
    plaidLinkToken: string
    passProcessorFeesToClient: boolean
    taxTransactionFee: boolean
    passACHFeesToClient: boolean
    passCreditCardFeesToClient: boolean
    isOk: boolean
    resourceNotAuhtorized: boolean
    resourceNotFound: boolean
    resourceInvalidPayload: boolean
    resourceForbidden: boolean
    userError: string
    internalError: string
    errorCode: string
}

export enum OnboardingStatus {
    Failed = 'Failed',
    Initiated = 'Initiated',
    DetailsSubmitted = 'DetailsSubmitted',
    Verified = 'Verified',
}

export enum RainForestOnboardingStatus {
    Completed = 'COMPLETED',
    NeedsInformation = 'NEEDS_INFORMATION',
    InReview = 'IN_REVIEW',
    Declined = 'DECLINED',
}
