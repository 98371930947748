import React from 'react'
import { Col, Row, Form, Button, Container } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../lib/api/HttpService'
import { FooterFormAction } from '../../components/Form'
import { isEmpty } from 'lodash'
import InputMasked from '../../../app/components/form/MaskedInput'
import {
    displayAlertError,
    displayAlertSuccess,
} from '../../../utilities/Response'

class ProjectViewInfo extends ThreadComponent {
    constructor(props) {
        super(props)
        this.state = {
            startDate: '',
            minDate: new Date(),
            isEnabled1: false,
            isEnabled2: false,
            dataLoaded: false,
            data: { addressBill: {}, addressSite: {} },
            clients: [],
            sitesameasbill: false,
            sortType: { value: 2, label: 'Manual' },
            project: {},
            showSalesTaxWarningModal: false,
            continueSaving: false,
            closedt: this.props.project.closeddt,
        }
        this.updatedData = {}
        this.updatedBillingAddress = {}
        this.updatedSiteAddress = {}
        this.api = new ApiService()
        this.clientData = []
        this.sortTypes = [
            { value: 0, label: 'Name' },
            { value: 1, label: 'Company' },
            { value: 2, label: 'Manual' },
        ]
        this.setIsChildSpinner(true)
        this.setIsLoaded(true)
    }

    async componentDidMount() {
        if (!isEmpty(this.props.project?.proj)) {
            this.setIsLoaded(false)
            this.setState(
                {
                    project: this.props.project,
                },
                this.loadData
            )
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        let project = {}
        if (previousProps.project !== this.props.project) {
            project = this.props.project || {}

            this.setIsLoaded(false)
            this.setState(
                {
                    project,
                },
                this.loadData
            )
        }
    }

    async handleUpdate(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    async handleChangeProjectStatus(e) {
        this.dMloader(true)
        const params = {
            closeddt: null,
        }

        try {
            const res = await this.api.editProject(
                this.props.project.id,
                params
            )

            if (res.hasOwnProperty('closeddt') && res.closeddt === null) {
                displayAlertSuccess('Successfully open a project.')
                setTimeout(function () {
                    window.location.reload()
                }, 2000)
            }
        } catch (error) {
            displayAlertError(error)
        }

        this.dMloader(false)
    }

    async loadData() {
        if (isEmpty(this.state.project?.proj)) {
            return
        }

        const projects = await this.api.getProjects(
            `?$filter=proj eq '${this.state.project?.proj}'`
        )
        const project = projects[0]
        const addresses = await this.api.getAddresses(
            '?$filter=addresstype eq 1 and inactive eq false'
        )
        const billingAddresses = await this.api.getAddresses(
            `?$filter=code eq '${this.state.project?.proj}' and addresstype eq 0`
        )
        this.clientData = await this.api.getClients(
            '?$filter=inactive eq false'
        )

        let clients = []

        for (let client of this.clientData) {
            clients.push({ value: client.id, label: client.ClientName })
        }

        const employeeData = await this.api.getClientEmployees(
            '?$filter=inactive eq false'
        )
        let employees = []
        for (let emp of employeeData) {
            employees.push({ value: emp.vendor, label: emp.vendorn })
            if (emp.vendor === project?.manager) project.managern = emp.vendorn
        }

        project.addressSite = {}
        project.addressShip = {}
        project.addressBill = {}
        project.addressShipOptions = []

        for (let address of addresses) {
            if (
                address.addressTypeEnum === 'ProjectSite' &&
                address.code === this.state.project?.proj
            ) {
                project.addressSite = address
            } else if (address.addressTypeEnum === 'ProjectShipTo') {
                project.addressShip = addresses
                let options = {
                    value: address.code,
                    label: `${address.attention} [${address.code}]`,
                }
                project.addressShipOptions.push(options)
                if (address.code === project.shipto)
                    project.addressShipSelected = options
            }
        }

        if (billingAddresses[0]) {
            project.addressBill = billingAddresses[0]
        }

        this.setState({
            data: project,
            clients: clients,
            employees: employees,
            sitesameasbill: project.sitesameasbill,
            isEnabled2: project.proj === project.shipto,
        })
        this.setIsLoaded(true)
    }

    render() {
        const { data } = this.state

        return this.renderView(
            <>
                <Container className="ms-0">
                    <Row xs={1} lg={2} className="mb-0 mb-lg-5">
                        <Col className="mb-3">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Client*
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="mb-2 mb-lg-0">
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 1000
                                                )}-min`}
                                                options={this.state.clients}
                                                defaultValue={{
                                                    value: data.clientId,
                                                    label: data.clientName,
                                                }}
                                                className="react-select"
                                                placeholder="Select"
                                                name="clientId"
                                                isDisabled={
                                                    this.state.isEnabled2
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="">
                                    <Form.Check
                                        inline
                                        label="Inactive"
                                        name="checkbox-group-inactive"
                                        type="checkbox"
                                        data-field="inactive"
                                        defaultChecked={
                                            this.props.project.closeddt
                                        }
                                        onChange={this.handleChangeProjectStatus.bind(
                                            this
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-0 mb-lg-5">
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Project code*
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        defaultValue={data.proj}
                                        id="proj"
                                        disabled
                                        readOnly
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Name*
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        name="clientName"
                                        type="text"
                                        disabled
                                        defaultValue={data.projn}
                                        id="projn"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mb-2 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Opened*
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <DatePicker
                                        minDate={this.state.minDate}
                                        className="form-control"
                                        selected={
                                            data.dateopened
                                                ? new Date(
                                                      data.dateopened.replace(
                                                          'T',
                                                          ' '
                                                      )
                                                  )
                                                : ''
                                        }
                                        id="dateopened"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Client Order #
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Client Order #"
                                        defaultValue={data.clientorder}
                                        id="clientorder"
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2}>
                        <Col className="mb-2 mb-lg-0">
                            <Row className="mb-lg-3">
                                <Col lg={3} className="text-start">
                                    <Form.Label className="mb-0">
                                        <strong>Billing Address</strong>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-0 mb-lg-5">
                        <Col className="">
                            <Row className="align-items-first mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0 mt-1"
                                >
                                    <Form.Label className="mb-0">
                                        Address
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        defaultValue={data.addressBill.addr}
                                        id="addr"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        City
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={data.addressBill.city}
                                        id="city"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        State
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row xs={1} lg={2}>
                                        <Col>
                                            <Form.Control
                                                maxLength={2}
                                                type="text"
                                                defaultValue={
                                                    data.addressBill.state
                                                }
                                                id="state"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Zip
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row xs={1} lg={2}>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                disabled
                                                defaultValue={
                                                    data.addressBill.zip
                                                }
                                                id="zip"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2}>
                        <Col>
                            <Row className="mb-3">
                                <Col lg={4} className="text-start">
                                    <Form.Label className="mb-0">
                                        <strong>Contact Information</strong>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-1 mb-lg-5">
                        <Col>
                            <Row className="align-items-first mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Contact
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={data.addressBill.contact}
                                        id="contact"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Phone
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <InputMasked
                                        type="text"
                                        disabled
                                        value={
                                            data.addressBill.contactphone || ''
                                        }
                                        id="contactphone"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Fax
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <InputMasked
                                        type="text"
                                        disabled
                                        value={data.addressBill.fax || ''}
                                        id="fax"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Email
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={data.addressBill.email}
                                        id="email"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Website
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Website"
                                        defaultValue={data.addressBill.website}
                                        id="website"
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mb-3 mb-lg-0">
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Phone 2
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row xs={1} lg={2}>
                                        <Col className="mb-2 mb-lg-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone 2 Description"
                                                defaultValue={
                                                    data.addressBill.phonedesc1
                                                }
                                                id="phonedesc1"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <InputMasked
                                                type="text"
                                                placeholder="Phone 2 Number"
                                                value={
                                                    data.addressBill.phone1 ||
                                                    ''
                                                }
                                                id="phone1"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Phone 3
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Row xs={1} lg={2}>
                                        <Col className="mb-2 mb-lg-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone 3 Description"
                                                defaultValue={
                                                    data.addressBill.phonedesc2
                                                }
                                                id="phonedesc2"
                                                disabled
                                            />
                                        </Col>
                                        <Col>
                                            <InputMasked
                                                type="text"
                                                placeholder="Phone 3 Number"
                                                value={
                                                    data.addressBill.phone2 ||
                                                    ''
                                                }
                                                id="phone2"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Sales Tax Code*
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.props.salesTaxCodes}
                                        defaultValue={
                                            !isEmpty(this.props.salesTaxCodes)
                                                ? this.props.salesTaxCodes.filter(
                                                      (code) => {
                                                          return (
                                                              code.value ===
                                                              (this.updatedData
                                                                  .taxc ??
                                                                  data.taxc)
                                                          )
                                                      }
                                                  )
                                                : null
                                        }
                                        className="react-select"
                                        placeholder="Select Sales Tax Code"
                                        name="taxc"
                                        isDisabled={this.state.isEnabled2}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Salesperson / Manager
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        options={this.state.employees}
                                        defaultValue={{
                                            value: data?.manager,
                                            label: data?.managern,
                                        }}
                                        className="react-select"
                                        placeholder="Salesperson / Manager"
                                        name="manager"
                                        isDisabled={this.state.isEnabled2}
                                        isClearable={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2}>
                        <Col>
                            <Row className="mb-0 mb-lg-3">
                                <Col lg={4} className="text-start">
                                    <Form.Label className="mb-0">
                                        <strong>Site Address</strong>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row
                        xs={1}
                        lg={2}
                        className={`mb-0 ${
                            this.state.data.sitesameasbill ? '' : 'mb-lg-5'
                        }`}
                    >
                        <Col>
                            <Row className="align-items-first mb-3">
                                <Col lg={3} className="text-lg-end mb-lg-0">
                                    <Form.Label className="mb-0"></Form.Label>
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Use billing address as site address"
                                        name="sitesameasbill"
                                        type="checkbox"
                                        id={`inline-radio-2`}
                                        checked={
                                            this.state.data.sitesameasbill ??
                                            false
                                        }
                                        disabled
                                    />
                                </Col>
                            </Row>
                            {!this.state.data.sitesameasbill && (
                                <>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Attention
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Attention"
                                                defaultValue={
                                                    data.addressSite.attention
                                                }
                                                id="attention"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-4 mb-lg-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Sort
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Please enter"
                                                defaultValue={
                                                    data.addressSite.sortname
                                                }
                                                id="sortname"
                                                disabled
                                                name="sortname"
                                                readOnly={
                                                    this.state.sortType
                                                        .value === 0 ||
                                                    this.state.sortType
                                                        .value === 1
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                key={`${Math.floor(
                                                    Math.random() * 1000
                                                )}-min`}
                                                options={this.sortTypes}
                                                defaultValue={
                                                    this.state.sortType
                                                }
                                                className="react-select"
                                                placeholder="Please select"
                                                disabled
                                                name="parsetype"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-first mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0 mt-1"
                                        >
                                            <Form.Label className="mb-0">
                                                Address
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Address"
                                                rows={3}
                                                defaultValue={
                                                    data.addressSite.addr
                                                }
                                                id="addr"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                City
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                defaultValue={
                                                    data.addressSite.city
                                                }
                                                id="city"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                State
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Row xs={1} lg={2}>
                                                <Col>
                                                    <Form.Control
                                                        maxLength={2}
                                                        type="text"
                                                        placeholder="State"
                                                        defaultValue={
                                                            data.addressSite
                                                                .state
                                                        }
                                                        id="state"
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-0 mb-lg-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Zip
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Row xs={1} lg={2}>
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Zip"
                                                        defaultValue={
                                                            data.addressSite.zip
                                                        }
                                                        id="zip"
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                        {!this.state.data.sitesameasbill && (
                            <>
                                <Col className="mb-3 mb-lg-0">
                                    <Row className="align-items-first mb-0 mb-lg-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0"></Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                <strong>
                                                    Contact Information
                                                </strong>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Contact
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Contact"
                                                defaultValue={
                                                    data.addressSite.contact
                                                }
                                                id="contact"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Phone
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <InputMasked
                                                type="text"
                                                placeholder="Phone"
                                                value={
                                                    data.addressSite
                                                        .contactphone || ''
                                                }
                                                id="contactphone"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Fax
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <InputMasked
                                                type="text"
                                                placeholder="Fax"
                                                value={
                                                    data.addressSite.fax || ''
                                                }
                                                id="fax"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Email
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder="Email"
                                                defaultValue={
                                                    data.addressSite.email
                                                }
                                                id="email"
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Phone 2
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Row xs={1} lg={2}>
                                                <Col className="mb-2 mb-lg-0">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Phone 2 Number"
                                                        defaultValue={
                                                            data.addressSite
                                                                .phone1
                                                        }
                                                        id="phone1"
                                                        disabled
                                                    />
                                                </Col>
                                                <Col>
                                                    <InputMasked
                                                        type="text"
                                                        placeholder="Phone 2 Description"
                                                        defaultValue={
                                                            data.addressSite
                                                                .phonedesc1 ||
                                                            ''
                                                        }
                                                        id="phonedesc1"
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-3">
                                        <Col
                                            lg={3}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Phone 3
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            <Row xs={1} lg={2}>
                                                <Col className="mb-2 mb-lg-0">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Phone 3 Number"
                                                        defaultValue={
                                                            data.addressSite
                                                                .phone2
                                                        }
                                                        id="phone2"
                                                        disabled
                                                    />
                                                </Col>
                                                <Col>
                                                    <InputMasked
                                                        type="text"
                                                        placeholder="Phone 3 Description"
                                                        defaultValue={
                                                            data.addressSite
                                                                .phonedesc2 ||
                                                            ''
                                                        }
                                                        id="phonedesc2"
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                    </Row>

                    <Row xs={1} lg={2}>
                        <Col>
                            <Row className="mb-3">
                                <Col lg={4} className="text-start">
                                    <Form.Label className="mb-0">
                                        <strong>Shipping</strong>
                                    </Form.Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row xs={1} lg={2} className="mb-1 mb-lg-5">
                        <Col>
                            <Row className="align-items-first mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Ship to
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Check
                                        inline
                                        label="Use site"
                                        name="group4"
                                        type="checkbox"
                                        defaultChecked={this.state.isEnabled2}
                                        disabled
                                        data-state="isEnabled2"
                                        id={`inline-radio-4`}
                                    />
                                </Col>
                            </Row>

                            <Row className="align-items-first mb-3">
                                <Col
                                    lg={3}
                                    className="text-lg-end mb-2 mb-lg-0 d-none d-lg-block"
                                ></Col>
                                <Col>
                                    <Select
                                        key={`${Math.floor(
                                            Math.random() * 1000
                                        )}-min`}
                                        name="shipto"
                                        options={data.addressShipOptions}
                                        defaultValue={
                                            data.addressShipOptions?.filter(
                                                (option) => {
                                                    return (
                                                        option.value ===
                                                        this.updatedData.shipto
                                                    )
                                                }
                                            )[0] ?? data.addressShipSelected
                                        }
                                        isDisabled={this.state.isEnabled2}
                                        className="react-select"
                                        placeholder="Select Shipping"
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <FooterFormAction>
                    <Button
                        onClick={this.handleUpdate}
                        variant="primary"
                        size="lg"
                        // disabled
                        id="handleSaveProject"
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ProjectViewInfo)
