import { HeaderLight } from 'templates/components/Header'

import Breadcumbs from './Breadcrumbs'

import URI from 'defaults/RoutesDefault'

export default function Header() {
    return (
        <HeaderLight>
            <Breadcumbs
                trail={[
                    {
                        text: 'Accounts Payable',
                        to: URI.accountsPayable.creditCard.list,
                    },
                    {
                        text: 'Credit Card',
                        to: URI.accountsPayable.creditCard.list,
                    },
                    {
                        text: 'Reconcile History',
                    },
                ]}
            />
            <HeaderLight.Content>
                <HeaderLight.Title>
                    Reconcile Credit Card History
                </HeaderLight.Title>
            </HeaderLight.Content>
        </HeaderLight>
    )
}
