import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ThreadComponent from '../ThreadComponent'

class Colours extends ThreadComponent {
    render() {
        return (
            <Container className="px-0" fluid>
                <div className="bg-light pt-5 pb-4">
                    <Container>
                        <Row>
                            <Col>
                                <Breadcrumb className="mt-2">
                                    <Breadcrumb.Item href="/">
                                        UI Kit
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="/icons">
                                        Colours
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <h2 className="fw-normal ff-type-medium">
                                    Colours
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="mt-5 ui-colours-wrapper">
                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-primary-green"></div>
                            <span className="float-start">Primary Green</span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-primary-green"></div>
                            <span className="float-start">Secondary Green</span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-tertiary-green"></div>
                            <span className="float-start">Tertiary Green</span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-primary-violet"></div>
                            <span className="float-start">Primary Violet</span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-secondary-violet"></div>
                            <span className="float-start">
                                Secondary Violet
                            </span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-tertiary-violet"></div>
                            <span className="float-start">Tertiary Violet</span>
                            <span className="float-end">#008B6D</span>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-charcoal"></div>
                            <span className="float-start">Charcoal</span>
                            <span className="float-end">#211F21</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-primary-ash"></div>
                            <span className="float-start">Ash</span>
                            <span className="float-end">#484747</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-secondary-ash"></div>
                            <span className="float-start">Ash</span>
                            <span className="float-end">#7C7C7C</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-sand"></div>
                            <span className="float-start">Sand</span>
                            <span className="float-end">#DEDCD7</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-beige"></div>
                            <span className="float-start">Beige</span>
                            <span className="float-end">#EEEDE9</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-ivory"></div>
                            <span className="float-start">Ivory</span>
                            <span className="float-end">#FAF9F7</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-white"></div>
                            <span className="float-start">White</span>
                            <span className="float-end">#FFFFFF</span>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-primary-yellow"></div>
                            <span className="float-start">Primary Yellow</span>
                            <span className="float-end">#FFD43E</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-secondary-yellow"></div>
                            <span className="float-start">
                                Secondary Yellow
                            </span>
                            <span className="float-end">#CCAA32</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-tertiary-yellow"></div>
                            <span className="float-start">Tertiary Yellow</span>
                            <span className="float-end">#FFF6D8</span>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-primary-red"></div>
                            <span className="float-start">Primary Red</span>
                            <span className="float-end">#F25438</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-primary-red"></div>
                            <span className="float-start">Secondary Red</span>
                            <span className="float-end">#C2432D</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-tertiary-red"></div>
                            <span className="float-start">Tertiary Red</span>
                            <span className="float-end">#FFF1EE</span>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <div className="bg-item bg-primary-blue"></div>
                            <span className="float-start">Primary Blue</span>
                            <span className="float-end">#176DE5</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-primary-blue"></div>
                            <span className="float-start">Secondary Blue</span>
                            <span className="float-end">#104CA0</span>
                        </Col>
                        <Col>
                            <div className="bg-item bg-tertiary-blue"></div>
                            <span className="float-start">Tertiary Blue</span>
                            <span className="float-end">#E8F0FC</span>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}

export default Colours
