import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import { WithRouter } from '../../../helpers/Router'
import ThreadComponent from '../ThreadComponent'
import { ApiService } from '../../../lib/api/HttpService'
import _, { uniq } from 'lodash'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../utilities/Response'
import { plural } from '../../../utilities/String'
import MSG from '../../../defaults/Message'
import { isEmail } from '../../../utilities/Validation'
class ClientInvoiceModal extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            amount: 0,
            hasFile: false,
            to: [],
            cc: [],
            bcc: [],
            subject: '',
            message: '',
            company: {},
            includeSignature: false,
            invoiceFrom: [],
            attachment: [],
            selectedProjects: [],
            clientID: null,
        }
        this.api = new ApiService()
    }

    async componentDidMount() {
        this.setState({
            selectedProjects: this.props.selectedProjects,
        })
        const company = await this.api.get('company')
        await this.getAddressDetails(company)

        const listOfClients = await this.api.getClients()
        const clientName = this.props.clientName

        const clientWithMatchingName = listOfClients.find(
            (x) => x.client === clientName
        )
        const idValue = clientWithMatchingName
            ? clientWithMatchingName.id
            : null
        this.setState({
            clientID: idValue,
        })
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedProjects !== this.props.selectedProjects) {
            this.setState({
                selectedProjects: this.props.selectedProjects,
            })
        }
    }

    async getAddressDetails(company) {
        let invoiceTxt = ''
        for (const project of this.state.selectedProjects) {
            const { due, invnum, desc } = project

            const amtDue = due < 0 ? `(${due})` : due
            const texts = [`Invoice ${invnum}`, desc, `Payment Due ${amtDue}`]
                .filter((t) => t)
                .join(', ')

            invoiceTxt += `\n${texts}`
        }

        // Get unique projects.
        const projects = uniq(this.state.selectedProjects.map((p) => p.proj))

        for (const proj of projects) {
            const address = await this.api.get(
                'addresses',
                `?$filter=code eq '${proj}' and addresstype eq 0`
            )

            this.setState((prevState) => {
                const isEmailToExist = _.some(
                    prevState.to,
                    (email) => email === address[0].email ?? ''
                )
                if (!isEmailToExist && address[0].email) {
                    prevState.to.push(address[0].email)
                }

                const isEmailCCExist = _.some(
                    prevState.cc,
                    (email) => email === address[0].emailcc ?? ''
                )
                if (!isEmailCCExist && address[0].emailcc) {
                    prevState.cc.push(address[0].emailcc)
                }

                const isEmailBCCExist = _.some(
                    prevState.bcc,
                    (email) => email === address[0].emailbcc ?? ''
                )

                if (!isEmailBCCExist && address[0].emailbcc) {
                    prevState.bcc.push(address[0].emailbcc)
                }

                const isInvoiceFromExist = _.some(
                    prevState.invoiceFrom,
                    (from) => from === proj
                )
                if (!isInvoiceFromExist) {
                    prevState.invoiceFrom.push(proj)
                }

                prevState.subject = `Invoices from ${company.name}`
                if (prevState.message.length === 0) {
                    prevState.message = plural(
                        `${company.name} would like to inform you that the following new Invoice have been added to your account:${invoiceTxt}`,
                        'new Invoice',
                        this.state.selectedProjects?.length
                    )
                }

                return prevState
            })
        }

        this.setIsLoaded(true)
    }

    handleChange = (e) => {
        const field = e.target.name
        let value = e.target.value
        if (field === 'to' || field === 'cc' || field === 'bcc') {
            value = e.target.value.split(',')
        }

        this.setState((prevState) => ({
            ...prevState,
            [e.target.name]: value,
        }))
    }

    onFileChange = (e) => {
        this.setState((prev) => (prev.hasFile = e.target.value ? true : false))
        this.setState((prevState) => ({
            ...prevState,
            attachment: e.target.files,
        }))
    }

    validate() {
        const { to, cc, bcc } = this.state

        const invalidTo = to.filter((em) => isEmail(em) === false)
        const invalidCc = cc.filter((em) => isEmail(em) === false)
        const invalidBcc = bcc.filter((em) => isEmail(em) === false)

        if (!to.length || invalidTo.length) {
            displayAlertError(
                `Please enter a valid email address for [to]: {${invalidTo.join(
                    ', '
                )}}.`
            )
            return false
        }

        if (cc.length && invalidCc.length) {
            displayAlertError(
                `Please enter a valid email address for [cc]: {${invalidCc.join(
                    ', '
                )}}.`
            )
            return false
        }

        if (bcc.length && invalidBcc.length) {
            displayAlertError(
                `Please enter a valid email address for [bcc]: {${invalidBcc.join(
                    ', '
                )}}.`
            )
            return false
        }

        return true
    }

    onSend = async () => {
        const totalInvoice = this.state.selectedProjects?.length
        let publicMappingObj = {
            publicMappingObjects: [],
        }
        displayAlertLoader(
            plural(
                MSG.loading.email.ARClientInv,
                'client invoice',
                totalInvoice
            )
        )

        if (!this.validate()) {
            return
        }

        for (const item of this.state.selectedProjects) {
            publicMappingObj.publicMappingObjects.push({
                objectId: parseInt(item.id),
                objectType: 'invoice',
            })
        }

        let token = null
        await this.api
            .createPublicMappingBatches(publicMappingObj)
            .then((response) => {
                if (response.isOk) {
                    token = response.token
                }
            })
            .catch((error) => {
                displayAlert('danger', error.response.data.userError)
            })

        if (token) {
            let files = []
            if (this.state.attachment.length) {
                let uploadedFile = await this.api.saveFile({
                    file: this.state.attachment[0],
                    FileType: 'other',
                })

                files.push(uploadedFile.id)
            }

            const notificationData = {
                notificationTypeId: 1,
                emailData: {
                    to: this.state.to,
                    cc: this.state.cc,
                    bcc: this.state.bcc,
                    subject: this.state.subject,
                    message: this.state.message,
                },
                publicMappingBatchToken: token,
                includeUserSignature: this.state.includeSignature,
                ...(files.length && { files }),
            }
            await this.api
                .postNotification(notificationData)
                .then(async (response) => {
                    if (response.isOk) {
                        displayAlertSuccess(
                            plural(
                                MSG.success.email.ARClientInv,
                                'invoice',
                                totalInvoice
                            )
                        )
                        setTimeout(() => {
                            this.props.hideModal(true)
                        }, 500)
                    }
                })
                .catch((error) => {
                    displayAlertError(getErrorMessage(error))
                })
        }
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={() => this.hide()}
                aria-labelledby="example-modal-sizes-title-lg"
                className="right a-modal fixed-footer"
                backdrop="static"
            >
                {this.renderView(
                    <>
                        <Modal.Body>
                            <div
                                className="p-5 mt-5 mx-auto"
                                style={{ maxWidth: '600px' }}
                            >
                                <h4 className="ff-type-medium">
                                    Send Invoice Email
                                </h4>
                                <Row className="form-fields px-4 mt-5">
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            To:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                name="to"
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.to}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            Cc:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                name="cc"
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.cc}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-4 pb-2"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            Bcc:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                name="bcc"
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.bcc}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            Subject:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                name="subject"
                                                type="text"
                                                placeholder=""
                                                size="sm"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                    this.state.subject
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        ></Form.Label>
                                        <Col sm="10">
                                            <a
                                                href={
                                                    this.props.clientInvoiceData
                                                        .link
                                                }
                                                className="fw-700"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Invoice
                                            </a>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            Attachment:
                                        </Form.Label>
                                        <Col sm="10">
                                            <div className="form-group-extra">
                                                <Form.Control
                                                    type="file"
                                                    placeholder=""
                                                    title=""
                                                    size="sm"
                                                    nam="attachment"
                                                    className={`file-upload-white ${
                                                        this.state.hasFile
                                                            ? 'has-file'
                                                            : ''
                                                    }`}
                                                    onChange={this.onFileChange}
                                                />
                                                <span className="text-secondary-ash ri-attachment-line"></span>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        >
                                            Message:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                name="message"
                                                as="textarea"
                                                rows={10}
                                                placeholder=""
                                                size="sm"
                                                onChange={this.handleChange}
                                                value={this.state.message}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formPlaintextEmail"
                                    >
                                        <Form.Label
                                            column
                                            sm="2"
                                            className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
                                        ></Form.Label>
                                        <Col sm="10">
                                            <Form.Check
                                                label="Include Signature from User Settings"
                                                name="includeSignature"
                                                type="checkbox"
                                                id={`inline-md-radio-4`}
                                                onChange={() => {
                                                    this.setState({
                                                        includeSignature:
                                                            !this.state
                                                                .includeSignature,
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="bg-ivory py-4 px-5">
                            <Row className="w-100">
                                <Col lg={{ span: 10, offset: 1 }}>
                                    <Button
                                        variant="primary"
                                        onClick={this.onSend}
                                        className="w-100 mb-2"
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        variant="trans-light border-secondary-ash mt-1"
                                        onClick={this.props.hideModal.bind(
                                            null
                                        )}
                                        className="w-100"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        )
    }
}

export default WithRouter(ClientInvoiceModal)
