import { makeAutoObservable } from 'mobx'
import { AddressListResponse, AddressService } from './api/AddressService'
import { getLocalStorage } from '../../../utilities/LocalStorage'

export default class AddressAddEditViewModel {
    private addressService: AddressService
    private _address: any = {}
    private _addressData: any = {}
    private _isLoaded = false
    public _clientCode: any = ''
    private _addressType = 0

    get address(): AddressListResponse {
        return this._address
    }

    get addressData(): AddressListResponse {
        return this._addressData
    }

    get isLoaded(): boolean {
        return this._isLoaded
    }

    get addressType(): number {
        return this._addressType
    }

    reset(): any {
        this._address = {}
        this._addressData = {}
        this._clientCode = ''
        return this
    }

    setAddress(address: any): AddressAddEditViewModel {
        this._address = address
        this._addressData = address
        window.localStorage.setItem('addressData', JSON.stringify(address))
        return this
    }

    setAddressData(key: any, value: any): AddressAddEditViewModel {
        const addressData: any = getLocalStorage('addressData', true)
        addressData[key] = value

        window.localStorage.setItem('addressData', JSON.stringify(addressData))

        this._addressData = addressData

        return this
    }

    setIsLoaded(isLoaded: boolean): AddressAddEditViewModel {
        this._isLoaded = isLoaded
        return this
    }

    setAddressType(addressType: number): AddressAddEditViewModel {
        this._addressType = addressType
        return this
    }

    constructor(addressService: AddressService) {
        this.addressService = addressService

        makeAutoObservable(this)
    }

    async componentDidMount() {
        await this.fetchAddress()
    }

    setClientCode(code: any): AddressAddEditViewModel {
        this._clientCode = code
        return this
    }

    async fetchAddress() {
        // Set loading state
        this.setIsLoaded(false)

        if (!this._clientCode) {
            const data: any = {}
            this.setAddress(data)
            return
        }

        return this.addressService
            .getAddressByClientCode(this._clientCode, this._addressType)
            .then((response: any) => {
                this.setIsLoaded(true)

                if (response && response[0]) {
                    this.setAddress(response[0])
                }
            })
            .catch((e: any) => {
                this.setIsLoaded(true)
            })
    }

    modifyAddress(id?: number, params?: any, callback?: any) {
        this.setIsLoaded(false)
        if (!id) {
            this.addressService
                .getAddressByClientCode(this._clientCode, this._addressType)
                .then((response: any) => {
                    this.setIsLoaded(true)

                    if (response && response[0]) {
                        this.updateAddress(response[0].id, params, callback)
                    } else {
                        this.insertAddress(params, callback)
                    }
                })
                .catch((e: any) => {
                    this.setIsLoaded(true)
                })
        } else {
            delete params.addresstype
            delete params.addressTypeEnum
            this.updateAddress(id, params, callback)
        }
    }

    insertAddress(params?: any, callback?: any) {
        this.addressService
            .insert(params)
            .then((response: any) => {
                if (callback) {
                    callback(response)
                }
            })
            .catch((e) => {
                if (callback) {
                    callback(e)
                }
            })
    }

    updateAddress(id: number, params?: any, callback?: any) {
        this.addressService
            .update(id, params)
            .then((response: any) => {
                if (callback) {
                    callback(response)
                }
            })
            .catch((e: any) => {
                if (callback) {
                    callback(e)
                }
            })
    }
}
