import React from 'react'
import { Link } from 'react-router-dom'
import {
    Breadcrumb,
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
} from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import Header from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../../lib/api/HttpService'
import { DropdownModal } from '../../../../app/components/modal/DropdownModal'
import { getCookie } from '../../../../utilities/Auth'
import { BasicModal } from '../../../../app/components/modal/BasicModal'
import dayjs from 'dayjs'
import {
    displayAlert,
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
} from '../../../../utilities/Response'
import { setDraft } from '../../../../utilities/FormEvent'
import { Event as FormEvent } from '../../../../utilities/DOM'
import { showLoading } from '../../../../helpers/Loading'
import { currencyFormat } from '../../../../helpers/Number'
import { getCheckAllClass } from '../../../../utilities/ModuleHelper'
import { debounce, delay, find, toLower } from 'lodash'
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting'
import { tableSearch } from '../../../../utilities/modules/TableSearch'
import { SearchExpressionButton } from '../../uikit/SearchExpressionButton'
import CashReceiptsModal from '../../modal/CashReceiptsModal'
import EditRefundModal from './EditRefundModal'
import MSG from '../../../../defaults/Message'
import { setLocalStorage } from '../../../../utilities/LocalStorage'
import { navigateOnSuccess } from '../../../../utilities/Router'
import { Pagination } from '../../../../app/components/pagination/Pagination'
import AsyncProjectsDropdown from '../../../../app/components/dropdowns/AsyncProjectsDropdown'
import AsyncEmployeesDropdown from '../../../../app/components/dropdowns/AsyncEmployeesDropdown'

class Step {
    static default = new Step('default')
    static confirming = new Step('confirming')
    static payment = new Step('payment')
    static succeeded = new Step('succeeded')

    constructor(name) {
        this.name = name
    }

    toString() {
        return `${this.name}`
    }
}

class ReturnsAndCreditsList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            step: Step.default,
            checkedItems: [],
            totalItems: 0,
            dataIsLoaded: false,
            editRefund: {
                isLoaded: false,
                show: false,
                item: {},
                grid: {},
                project: {},
            },
            managers: [],
            items: [],
            itemsCopy: [],
            updatedData: {
                projectCode: '',
                fiscalMonth: new Date(),
                refundType: '',
                isRetainer: false,
                manager: '',
                depositDate: new Date(),
                transactionDescription: 'Credit Memo',
                refundAmount: 0,
            },
            payment: undefined,
            configuration: undefined,
            searchProperties: {},
            page: 1,
            pageSize: 20,
            selectedProject: null,
        }

        this.api = new ApiService()
        this.refundTypes = [
            { value: 'Check', label: 'Write Check to Client' },
            { value: 'CC', label: "On Client's Credit Card/ACH" },
            { value: 'Account', label: 'On Account (Open A/R)' },
            { value: 'Retainer', label: "Increase Client's Retainer" },
        ]

        this.searchHandler = debounce(this.handleDoSearch.bind(this), 200)
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    componentInit() {
        this.setTitle('Client Returns & Credits')
    }

    async componentDidMount() {
        this.dMloader(true)
        const fiscalMonth = await this.fetchFiscalMonth()
        await this.api.getClientInvoiceItems()

        this.dMloader(false)

        this.setState({
            dataIsLoaded: true,
            updatedData: {
                ...this.state.updatedData,
                fiscalMonth: fiscalMonth,
            },
        })
    }

    async fetchFiscalMonth() {
        const company = await this.api.getCompany()
        const company2 = await this.api.getCompany2()

        let fiscalMonth = new Date()
        if (company2.autodeffmo) {
            fiscalMonth = new Date(
                fiscalMonth.setMonth(
                    fiscalMonth.getMonth() + company.fiscaloffset
                )
            )
        } else {
            fiscalMonth = new Date(company2.closedarfmo)
            fiscalMonth.setMonth(fiscalMonth.getMonth() + 1)
        }

        return fiscalMonth
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.setState({
            page: page,
        })

        await this.fetchData(
            {
                proj: this.state.updatedData.projectCode,
                client: this.state.clientName,
            },
            page
        )
    }

    handleFilter = (name) => (e) => {
        this.setState(
            {
                [name]: e,
                page: 1,
            },
            this.changePageHandler
        )
    }

    renderData(data) {
        this.setState({
            dataIsLoaded: true,
            data: data,
        })
    }

    handleShowTableSearch = (e) => {
        if (!this.state.itemsCopy.length) {
            return
        }

        this.setState(
            {
                showTableSearch: !this.state.showTableSearch,
            },
            () => {
                if (!this.state.showTableSearch) {
                    FormEvent('.a-table-search-fields .form-control').value('')
                    this.setState({
                        items: this.state.itemsCopy,
                    })
                }
            }
        )
    }

    handleFiscalMonthChange = async (date) => {
        this.setState({
            updatedData: {
                ...this.state.updatedData,
                fiscalMonth: date
                    ? new Date(date)
                    : await this.fetchFiscalMonth(),
            },
        })
    }

    handleDateChange = (date) => {
        this.setState({
            updatedData: {
                ...this.state.updatedData,
                depositDate: date ? new Date(date) : '',
            },
        })
    }

    handleRadioChange = (e) => {
        this.setState(
            {
                updatedData: {
                    ...this.state.updatedData,
                    isRetainer: e.target.value === '1',
                    refundAmount:
                        e.target.value === '1'
                            ? 0
                            : this.state.updatedData.refundAmount,
                },
            },
            e.target.value === '0' ? this.recalculateAmount : null
        )
    }

    handleProjectChange = async (e, meta = {}) => {
        setDraft(true)
        FormEvent('[data-field="proposalNumber"]').value('')
        FormEvent('[data-field="invoiceNumber"]').value('')
        const project = await this.api.getProjectByCode(e?.value)
        this.setState(
            {
                selectedProject: e,
                proposalNumber: null,
                invoiceNumber: null,
                updatedData: {
                    ...this.state.updatedData,
                    projectCode: e.value,
                },
                clientName: project?.client
                    ? `${project?.clientName} [${project?.client}]`
                    : '',
                checkedItems: [],
            },
            async () => {
                this.dMloader(true)
                this.setState({
                    dataIsLoaded: false,
                })
                await this.createGrid()
                await this.handleChangePage(1)
            }
        )
    }

    async fetchData(data, currentPage) {
        let availableRetainer = 0
        let queryString = `?$top=${parseInt(this.state.pageSize) + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }`

        const items = await this.api.getReturnItems(
            `${queryString}&$filter=usercode eq ${getCookie(
                'dmUsercode'
            )} and proj eq '${data?.proj}'&$orderby=loc asc, item asc`
        )

        if (data?.proj) {
            const project = await this.api.getProjectByCode(data?.proj, true)
            const projId = project?.id
            availableRetainer = await this.api.getProjectAvailableRetainer(
                projId
            )
            availableRetainer = availableRetainer?.amount ?? 0
        }

        const _totalItems = items.length
        const _items =
            items.length > this.state.pageSize ? items.splice(1) : items
        this.setState(
            {
                items: _items,
                itemsCopy: _items,
                totalItems: _totalItems,
                availableRetainer,
                clientName: data?.client,
                dataIsLoaded: true,
                updatedData: {
                    ...this.state.updatedData,
                    refundAmount: 0,
                },
            },
            () => {
                this.enableSortTable()
                this.recalculateAmount()
            }
        )
        this.dMloader(false)
    }

    handleProposalChange = async (e, meta = {}) => {
        setDraft(true)
        this.setState(
            {
                proposalNumber: e.target.value,
                dataIsLoaded: false,
            },
            async () => {
                await this.createGrid()
                await this.handleChangePage(1)
            }
        )
    }

    handleInvoiceChange = async (e, meta = {}) => {
        setDraft(true)
        this.setState(
            {
                invoiceNumber: e.target.value,
                dataIsLoaded: false,
            },
            async () => {
                await this.createGrid()
                await this.handleChangePage(1)
            }
        )
    }

    handleChange = (e, meta = {}, callback = () => {}) => {
        let key, value
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.attributes['data-field'].value
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        if (key === 'manager') {
            this.setState({
                selectedManager: e,
            })
        }

        setDraft(true)
        this.setState(
            {
                updatedData: {
                    ...this.state.updatedData,
                    [key]: value,
                },
            },
            () => callback()
        )
    }

    handleSelectAllChecks = () => {
        let checkedItems = this.state.checkedItems
        const checkedItemIds = checkedItems.map((item) => item.item)
        const itemIds = this.state.items.map((item) => item.item)
        const some = this.state.items.some((item) =>
            checkedItemIds.includes(item.item)
        )

        if (checkedItemIds.includes(itemIds) || some) {
            checkedItems = checkedItems.filter(
                (item) => !itemIds.includes(item.item)
            )
        } else {
            checkedItems = [...checkedItems, ...this.state.items]
        }

        this.setState(
            {
                checkedItems: checkedItems,
            },
            () => {
                this.recalculateAmount()
            }
        )
    }

    handleCheck = (item) => {
        const checkedItems = this.state.checkedItems
        const index = checkedItems.findIndex(
            (_item) => _item.item === item.item
        )

        index > -1 ? checkedItems.splice(index, 1) : checkedItems.push(item)

        this.setState(
            {
                checkedItems: checkedItems,
            },
            () => {
                this.recalculateAmount()
            }
        )
    }

    createGrid = async () => {
        const params = {}
        if (this.state.updatedData.projectCode) {
            params.projectCode = this.state.updatedData.projectCode
        }
        if (this.state.proposalNumber) {
            params.proposalNumber = this.state.proposalNumber
        }
        if (this.state.invoiceNumber) {
            params.invoiceNumber = this.state.invoiceNumber
        }
        await this.api.createReturnsGrid(params)
    }

    recalculateAmount() {
        const amount = this.state.checkedItems.reduce(
            (total, item) => total + item.invamount,
            0
        )

        this.setState({
            updatedData: {
                ...this.state.updatedData,
                refundAmount: amount.toFixed(2),
            },
        })
    }

    reselectItems() {
        let checkedItems = this.state.checkedItems
        const checkedItemIds = checkedItems.map((item) => item.item)
        let newCheckedItems = []

        this.state.items.map((item) => {
            if (checkedItemIds.includes(item.item)) {
                newCheckedItems.push(item)
            }
        })

        this.setState(
            {
                checkedItems: newCheckedItems,
            },
            () => {
                this.recalculateAmount()
            }
        )
    }

    getFormattedFiscalMonth() {
        let month = this.state.updatedData.fiscalMonth.getMonth() + 1
        month = month < 10 ? `0${month}` : month
        return `${month}/${this.state.updatedData.fiscalMonth.getFullYear()}`
    }

    getFormattedDepositDate() {
        return dayjs(this.state.updatedData.depositDate).format('MM/DD/YYYY')
    }

    buildConfiguration() {
        let configuration
        if (this.state.updatedData.refundType === 'Check') {
            configuration = {
                confirmationMessage: `Design Manager is ready to process this Credit/Return and post it to fiscal ${this.getFormattedFiscalMonth()}. Press OK to continue, or Cancel to abort.`,
                successMessage:
                    'The Refund/Credit has been completed successfully. Would you like to go to open A/P entry so that you can write a check for it?',
                redirectURL: URI.accountsPayable.payment.list,
            }
        } else if (this.state.updatedData.refundType === 'Account') {
            if (!this.state.updatedData.isRetainer) {
                configuration = {
                    confirmationMessage: `Design Manager is ready to process this Credit/Return and post it to fiscal ${this.getFormattedFiscalMonth()}. Press OK to continue, or Cancel to abort.`,
                    successMessage:
                        'The Refund/Credit has been completed successfully. Would you like to go to the credit invoice so that you can view or print it?',
                    redirectURL:
                        URI.accountsReceivable.clientInvoices.listExisting,
                }
            }
        } else if (this.state.updatedData.refundType === 'Retainer') {
            if (!this.state.updatedData.isRetainer) {
                configuration = {
                    confirmationMessage: `Design Manager is ready to process this Credit/Return and post it to fiscal ${this.getFormattedFiscalMonth()}. Press OK to continue, or Cancel to abort.`,
                    successMessage:
                        'The Refund/Credit has been completed successfully. Would you like to go to the entry which represents the new retainer?',
                    redirectURL:
                        URI.accountsReceivable.cashReceipt.listExisting,
                }
            }
        } else if (this.state.updatedData.refundType === 'CC') {
            let successMessage = ''
            if (this.payment?.stripeId) {
                successMessage = `The Refund/Void has been processed successfully through DM. Would you like DM to send this refund of $${this.state.updatedData.refundAmount} to the client through Stripe against ${this.payment.cardType}?`
            } else {
                successMessage =
                    'The Refund/Credit has been completed successfully. Would you like to go to the entry which represents the new retainer?'
            }
            configuration = {
                confirmationMessage: `Design Manager is ready to process this Credit/Return and post it to fiscal ${this.getFormattedFiscalMonth()}. Press OK to continue, or Cancel to abort.`,
                successMessage: successMessage,
                redirectURL: URI.accountsReceivable.cashReceipt.listExisting,
            }
        }
        return configuration
    }

    handleOk = async () => {
        this.configuration = this.buildConfiguration()

        if (!this.state.updatedData.projectCode) {
            displayAlertError('Please select a project.')
            return
        }

        if (!this.state.updatedData.depositDate) {
            displayAlertError(`Please enter a valid date.`)
            return
        }

        if (this.state.updatedData.isRetainer) {
            const ra = parseFloat(this.state.updatedData.refundAmount)
            const ar = this.state.availableRetainer

            if (ra > ar) {
                displayAlertError(
                    'The refund retainer amount cannot be greater than the available retainer.'
                )
                return
            }
        }

        if (this.state.updatedData.refundType === 'Check') {
            this.setState({
                step: Step.confirming,
            })
        } else if (this.state.updatedData.refundType === 'Account') {
            if (!this.state.updatedData.isRetainer) {
                this.setState({
                    step: Step.confirming,
                })
            }
        } else if (this.state.updatedData.refundType === 'Retainer') {
            if (!this.state.updatedData.isRetainer) {
                this.setState({
                    step: Step.confirming,
                })
            }
        } else if (this.state.updatedData.refundType === 'CC') {
            this.setState({
                step: Step.confirming,
            })
        } else {
            displayAlertError('Please select refund type.')
        }
    }

    tagItems() {
        const promises = []

        for (const item of this.state.checkedItems) {
            promises.push(
                this.api.editClientInvoicesItems({
                    projectCode: this.state.selectedProject?.value,
                    userCode: getCookie('dmUsercode'),
                    item: item.item,
                    tagged: true,
                })
            )
        }

        return Promise.all(promises)
    }

    createReturn() {
        const params = {}
        params.projectCode = this.state.updatedData.projectCode
        params.fiscalMonth = this.getFormattedFiscalMonth()
        params.refundType = this.state.updatedData.refundType
        if (this.state.updatedData.isRetainer) {
            params.isRetainer = true
        } else {
            params.manager = this.state.updatedData.manager
        }
        params.depositDate = this.getFormattedDepositDate()
        params.transactionDescription =
            this.state.updatedData.transactionDescription
        params.refundAmount = this.state.updatedData.refundAmount

        if (this.payment?.stripeId) {
            params.paymentType = 'CC'
            params.usedStripe = true
            params.cardNum = '**4242'
        } else if (this.payment?.type) {
            params.paymentType = this.payment.type
        }

        return this.api.createReturn(params)
    }

    mapCCTypes() {
        return this.state.ccTypes.map((type) => {
            return { value: type, label: type.cardType ?? type.type }
        })
    }

    handleConfirmPayment = (value) => {
        this.payment = value

        this.processReturn()
    }

    handleConfirmation = async () => {
        if (this.state.updatedData.refundType === 'CC') {
            const stripeCCTypes = await this.api.getReturnsCCTypes()
            const otherCCTypes = await this.api.getPaymentTypes()

            this.setState({
                step: Step.payment,
                ccTypes: [...stripeCCTypes, ...otherCCTypes],
            })
        } else {
            this.processReturn()
        }
    }

    async processReturn() {
        try {
            await this.tagItems()
            await this.createReturn()

            this.setState({
                step: Step.succeeded,
            })
        } catch (error) {
            displayAlert('danger', error.response.data.userError)

            this.setState({
                step: Step.default,
            })
        }
    }

    handleSuccessConfirmation = () => {
        setDraft(false)
        if (this.configuration.redirectURL) {
            window.location.href = this.configuration.redirectURL
        } else {
            this.handleFormReset()
        }
    }

    handleFormReset = async () => {
        setDraft(false)
        this.configuration = undefined
        this.payment = undefined
        this.setState({
            step: Step.default,
            checkedItems: [],
            items: [],
            clientName: '',
            proposalNumber: '',
            invoiceNumber: '',
            selectedProject: '',
            selectedManager: '',
            updatedData: {
                projectCode: '',
                fiscalMonth: await this.fetchFiscalMonth(),
                refundType: '',
                isRetainer: false,
                manager: '',
                depositDate: new Date(),
                transactionDescription: 'Credit Memo',
                refundAmount: 0,
                paymentType: '',
                cardNum: '',
            },
        })
    }

    isOkDisabled() {
        return (
            ['Account', 'Retainer'].includes(
                this.state.updatedData.refundType
            ) && this.state.updatedData.isRetainer
        )
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'
        const exp = e.target.attributes['placeholder']?.value ?? '>='

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value, type, exp },
                },
            },
            this.searchHandler
        )
    }

    handleDoSearch = () =>
        tableSearch({
            data: this.state.itemsCopy,
            searchProps: this.state.searchProperties,
            callback: (data) =>
                this.setState(
                    {
                        items: data,
                    },
                    this.enableSortTable
                ),
        })

    enableSortTable = () =>
        tableSortingEnableSort({
            data: this.state.items,
            stateKey: 'items',
            classRef: this,
            targetTable: '.a-table-heading',
        })

    resetSearchField(name) {
        FormEvent(`.a-table-search-fields [data-field="${name}"]`).value('')
        const field = document.querySelector(
            `.a-table-search-fields [data-field="${name}"]`
        )
        field.addEventListener(
            'clearExp',
            (event) => this.handleSearch(event),
            false
        )
        field.dispatchEvent(new Event('clearExp'))
    }

    getReturnTypeDefault() {
        const type = this.refundTypes.filter((type) => {
            return type.value === this.state.updatedData.refundType
        })[0]

        return type ? type : null
    }

    handleCancel = () => {
        this.handleFormReset()
    }

    openEditRefundModal = async (item) => {
        const projectItem = await this.api.getProjectItems(
            `?$filter=item eq '${item.item}' and proj eq '${item.proj}'`
        )
        const project = await this.api.getProjects(
            `?$filter=proj eq '${item.proj}'`
        )

        this.setState({
            editRefund: {
                ...this.state.editRefund,
                isLoaded: true,
                item: projectItem[0] ?? {},
                project: project[0] ?? {},
                grid: item,
                show: true,
            },
        })
    }

    hideEditRefundModal = () => {
        this.setState({
            editRefund: {
                isLoaded: false,
                show: false,
                item: {},
                grid: {},
                project: {},
            },
        })
    }

    handleSaveEditRefund = async (data) => {
        this.setState({
            editRefund: {
                ...this.state.editRefund,
                isLoaded: false,
            },
        })

        let payload = {
            PricesToInvoice: [
                data.pricetoinvm == '' ? 0 : parseFloat(data.pricetoinvm),
                data.pricetoinvd == '' ? 0 : parseFloat(data.pricetoinvd),
                data.pricetoinvf == '' ? 0 : parseFloat(data.pricetoinvf),
                data.pricetoinvi == '' ? 0 : parseFloat(data.pricetoinvi),
                data.pricetoinvl == '' ? 0 : parseFloat(data.pricetoinvl),
                data.pricetoinvo == '' ? 0 : parseFloat(data.pricetoinvo),
            ],
            TaxableAmounts: [
                data.taxableamountm == '' ? 0 : parseFloat(data.taxableamountm),
                data.taxableamountd == '' ? 0 : parseFloat(data.taxableamountd),
                data.taxableamountf == '' ? 0 : parseFloat(data.taxableamountf),
                data.taxableamounti == '' ? 0 : parseFloat(data.taxableamounti),
                data.taxableamountl == '' ? 0 : parseFloat(data.taxableamountl),
                data.taxableamounto == '' ? 0 : parseFloat(data.taxableamounto),
            ],
            IsOverride: data.isOverride,
            ItemNumber: data.itemNumber,
            ProjectCode: data.projectCode,
            QtyToInvoice: data.gridtoinv,
            DepositToInvoice: data.deposittorefund,
        }

        try {
            await this.api.overrideRefund(payload)
            this.hideEditRefundModal()

            this.setState({
                dataIsLoaded: false,
            })

            await this.fetchData(
                {
                    proj: this.state.updatedData.projectCode,
                    client: this.state.clientName,
                },
                1
            )

            delay(() => {
                this.reselectItems()
            }, 500)
        } catch (error) {
            displayAlertError(getErrorMessage(error))
            this.setState({
                editRefund: {
                    ...this.state.editRefund,
                    isLoaded: true,
                },
            })
        }
    }

    renderHeader() {
        return (
            <Header>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{
                                        to: URI.accountsReceivable
                                            .returnsAndCredit.base,
                                    }}
                                    linkAs={Link}
                                >
                                    Accounts Receivable
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    Client Returns & Credits
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>Client Returns & Credits</Header.Title>
                    </Header.LeftContent>
                </Header.TitleContent>

                <Header.Actions list={true}>
                    <li>
                        <Button
                            variant="ivory-light"
                            className="mt-4 w-100 btn-lg btn-block"
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button>
                    </li>
                    <li>
                        <Button
                            variant="primary"
                            className="mt-4 w-100 btn-lg btn-block"
                            onClick={this.handleOk}
                            disabled={this.isOkDisabled()}
                        >
                            Save
                        </Button>
                    </li>
                </Header.Actions>
            </Header>
        )
    }

    renderInputBar() {
        return (
            <InputBar className="full multiple">
                <InputBar.Links className="auto-width zi-400">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Date
                        </Form.Label>
                        <div className="react-select-header">
                            <DatePicker
                                selected={this.state.startDate}
                                minDate={this.state.minDate}
                                onChange={this.handleDateChange}
                                showMonthDropdown="true"
                                showYearDropdown="true"
                                className="form-control form-control-sm"
                                wrapperClassName="no-icon"
                                placeholderText="7/8/2022"
                            />
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Fiscal Month
                        </Form.Label>
                        <Form.Select size="sm">
                            <option>07/2022</option>
                        </Form.Select>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Trans. Cesc.
                        </Form.Label>
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Credit Memo"
                        />
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Mgr/SP
                        </Form.Label>
                        <Form.Select size="sm">
                            <option>Select</option>
                        </Form.Select>
                    </InputBar.Link>
                </InputBar.Links>
                <InputBar.Links className="auto-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Total Refund
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                type="text"
                                placeholder="0.00"
                                size="sm"
                            />
                            <span>$</span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderContent() {
        const { items, checkedItems } = this.state
        const { isRetainer } = this.state.updatedData
        const filteredRefundTypes = isRetainer
            ? this.refundTypes.slice(0, 2)
            : this.refundTypes

        return (
            <Form.Group>
                <Row
                    xs={1}
                    lg={2}
                    style={{ position: 'relative', zIndex: 300 }}
                >
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Project
                                </Form.Label>
                            </Col>
                            <Col>
                                <AsyncProjectsDropdown
                                    name={'projectCode'}
                                    className="react-select"
                                    placeholder="Please select"
                                    value={this.state.selectedProject}
                                    onChange={this.handleProjectChange}
                                />
                            </Col>
                            <Col className="mb-3 mb-lg-0">
                                <Form.Control
                                    type="text"
                                    disabled={true}
                                    readOnly={true}
                                    value={this.state.clientName}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    xs={1}
                    lg={2}
                    style={{ position: 'relative', zIndex: 200 }}
                >
                    <Col>
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Refund For
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    inline
                                    checked={isRetainer === false}
                                    label="Item Deposit or Invoice"
                                    type="radio"
                                    className="vtop me-4"
                                    onChange={this.handleRadioChange}
                                    value={0}
                                />
                                <Form.Check
                                    inline
                                    checked={isRetainer === true}
                                    label="Retainer"
                                    type="radio"
                                    className="vtop"
                                    onChange={this.handleRadioChange}
                                    value={1}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    xs={1}
                    lg={2}
                    style={{ position: 'relative', zIndex: 100 }}
                >
                    <Col>
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Refund Type
                                </Form.Label>
                            </Col>
                            <Col>
                                <Select
                                    options={filteredRefundTypes}
                                    value={this.getReturnTypeDefault()}
                                    className="react-select"
                                    placeholder="Please select"
                                    name="refundType"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row xs={1} lg={2} className="mb-0 mb-lg-4">
                    <Col>
                        {!this.state.updatedData.isRetainer && (
                            <Row className="align-items-center mb-4">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Proposal No. (Optional)
                                    </Form.Label>
                                </Col>
                                <Col sm={3} className="mb-3 mb-lg-0">
                                    <Form.Control
                                        type="number"
                                        className="no-spinner"
                                        disabled={
                                            this.state.updatedData
                                                .projectCode === ''
                                        }
                                        data-field="proposalNumber"
                                        onChange={this.handleProposalChange}
                                        value={this.state.proposalNumber}
                                    />
                                </Col>
                            </Row>
                        )}
                        {!this.state.updatedData.isRetainer && (
                            <Row className="align-items-center mb-4">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Invoice No. (Optional)
                                    </Form.Label>
                                </Col>
                                <Col sm={3} className="mb-3 mb-lg-0">
                                    <Form.Control
                                        type="number"
                                        className="no-spinner"
                                        disabled={
                                            this.state.updatedData
                                                .projectCode === ''
                                        }
                                        data-field="invoiceNumber"
                                        onChange={this.handleInvoiceChange}
                                        value={this.state.invoiceNumber}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Date</Form.Label>
                            </Col>
                            <Col lg={'auto'}>
                                <DatePicker
                                    selected={
                                        this.state.updatedData.depositDate
                                    }
                                    showMonthDropdown="true"
                                    showYearDropdown="true"
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    onChange={this.handleDateChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Fiscal Month
                                </Form.Label>
                            </Col>
                            <Col lg={'auto'}>
                                <DatePicker
                                    selected={
                                        this.state.updatedData.fiscalMonth
                                    }
                                    showMonthYearPicker={true}
                                    className="form-control"
                                    dateFormat="MM/yyyy"
                                    onChange={this.handleFiscalMonthChange}
                                />
                            </Col>
                        </Row>
                        {!this.state.updatedData.isRetainer && (
                            <Row className="align-items-center mb-4">
                                <Col
                                    lg={4}
                                    className="text-lg-end mb-2 mb-lg-0"
                                >
                                    <Form.Label className="mb-0">
                                        Manager/Salesperson
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <AsyncEmployeesDropdown
                                        defaultValue={
                                            this.state.selectedManager
                                        }
                                        name="manager"
                                        className="react-select"
                                        placeholder="Please select"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Transaction Description
                                </Form.Label>
                            </Col>
                            <Col className="mb-3 mb-lg-0">
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={
                                        this.state.updatedData
                                            .transactionDescription
                                    }
                                    data-field="transactionDescription"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-4">
                            <Col lg={4} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label
                                    className="mb-0"
                                    data-field="transactionDescription"
                                >
                                    Total Refund
                                </Form.Label>
                            </Col>
                            <Col lg={3} className="mb-3 mb-lg-0">
                                <Form.Control
                                    type="text"
                                    className="no-spinner"
                                    placeholder=""
                                    value={`$${this.state.updatedData.refundAmount}`}
                                    data-field="refundAmount"
                                    disabled={true}
                                    readOnly={true}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {this.state.updatedData.isRetainer ? (
                    <div>
                        <div className="bg-secondary-grey mb-4 roundedx-4">
                            <Row xs={1} className="py-3">
                                <Col>
                                    <Row className="px-3">
                                        <Col
                                            style={{ height: 32 }}
                                            className="px-3"
                                        >
                                            <h6 className="fw-bold">
                                                Retainer
                                            </h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Form.Group>
                            <Row xs={1} lg={2} className="mb-4 pb-2">
                                <Col>
                                    <Row className="align-items-center mb-4">
                                        <Col
                                            lg={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Available Retainer
                                            </Form.Label>
                                        </Col>
                                        <Col sm={3} className="mb-3 mb-lg-0">
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                readOnly={true}
                                                value={currencyFormat(
                                                    this.state
                                                        .availableRetainer ?? 0
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-4">
                                        <Col
                                            lg={4}
                                            className="text-lg-end mb-2 mb-lg-0"
                                        >
                                            <Form.Label className="mb-0">
                                                Refund Amount
                                            </Form.Label>
                                        </Col>
                                        <Col sm={3} className="mb-3 mb-lg-0">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                data-field="refundAmount"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                ) : (
                    <div className="sticky-container">
                        <div className="bg-secondary-grey mb-4 roundedx-4">
                            <Row xs={1} className="py-3">
                                <Col>
                                    <Row className="align-items-center px-3">
                                        <Col className="px-3">
                                            <h6 className="fw-bold mb-0">
                                                Choose Item(s)
                                            </h6>
                                        </Col>
                                        <Col className="px-3 justify-content-end d-flex">
                                            <Button
                                                as={Link}
                                                to="#"
                                                variant="ivory"
                                                size="sm"
                                                className={`btn-icon btn-action ${
                                                    this.state.showTableSearch
                                                        ? 'bg-primary-ash text-white'
                                                        : ''
                                                }`}
                                                onClick={
                                                    this.handleShowTableSearch
                                                }
                                            >
                                                <i className="ri-search-line"></i>{' '}
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row xs={1} className="mb-4 pb-2">
                            <Col>
                                <div className="table-gradient">
                                    <Table
                                        striped
                                        responsive
                                        className="a-table"
                                    >
                                        <thead>
                                            <tr className="a-table-heading item">
                                                <th
                                                    align="middle"
                                                    className="mw-70px"
                                                >
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check
                                                            label=""
                                                            type="checkbox"
                                                            checked={
                                                                checkedItems.length >
                                                                0
                                                            }
                                                            className={getCheckAllClass(
                                                                checkedItems.length,
                                                                items
                                                            )}
                                                            onChange={
                                                                this
                                                                    .handleSelectAllChecks
                                                            }
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="loc"
                                                    >
                                                        Location
                                                    </span>
                                                </th>
                                                {/* <th>
                                                    <span
                                                        className="sort"
                                                        data-field="groupname"
                                                    >
                                                        Group
                                                    </span>
                                                </th> */}
                                                <th className="mw-90px">
                                                    <span
                                                        className="sort"
                                                        data-field="item"
                                                    >
                                                        Ref. No.
                                                    </span>
                                                </th>
                                                <th>
                                                    <span
                                                        className="sort"
                                                        data-field="payee"
                                                    >
                                                        Last Proposal
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="previnfo"
                                                    >
                                                        Last Invoice
                                                    </span>
                                                </th>
                                                <th className="mw-140px">
                                                    <span
                                                        className="sort"
                                                        data-field="shortdesc"
                                                    >
                                                        Description
                                                    </span>
                                                </th>
                                                <th className="mw-100px">
                                                    <span
                                                        className="sort"
                                                        data-field="quantity"
                                                    >
                                                        Qty
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="esttprice"
                                                    >
                                                        Estimated Price
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="esttstax"
                                                    >
                                                        Estimated Sales Tax
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="esttotal"
                                                    >
                                                        Total Estimated
                                                    </span>
                                                </th>
                                                <th className="mw-100px">
                                                    <span
                                                        className="sort"
                                                        data-field="invbal"
                                                    >
                                                        Available Deposit
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="tdinvqty"
                                                    >
                                                        Invoiced Qty.
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="invqty"
                                                    >
                                                        Invoiced Price
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="invstax"
                                                    >
                                                        Invoiced Sales Tax
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="invtotal"
                                                    >
                                                        Total Invoiced
                                                    </span>
                                                </th>
                                                <th className="mw-120px">
                                                    <span
                                                        className="sort"
                                                        data-field="invamount"
                                                    >
                                                        Amount to Refund
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr
                                                className={`a-table-search-fields ${
                                                    this.state.showTableSearch
                                                        ? ''
                                                        : 'd-none'
                                                }`}
                                            >
                                                <th></th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="loc"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th>
                                                {/* <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="groupname"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th> */}
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="item"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="payee"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="previnfo"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th>
                                                <th>
                                                    <Form.Control
                                                        type="text"
                                                        data-field="shortdesc"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="quantity"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expquantity ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expquantity
                                                        }
                                                        callback={(
                                                            expquantity
                                                        ) =>
                                                            this.setState({
                                                                expquantity,
                                                            })
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="esttprice"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expesttprice ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expesttprice
                                                        }
                                                        callback={(
                                                            expesttprice
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expesttprice,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'esttprice'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="esttstax"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expesttstax ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expesttstax
                                                        }
                                                        callback={(
                                                            expesttstax
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expesttstax,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'esttstax'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="esttotal"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expesttotal ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expesttotal
                                                        }
                                                        callback={(
                                                            expesttotal
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expesttotal,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'esttotal'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="tdinvqty"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .exptdinvqty ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .exptdinvqty
                                                        }
                                                        callback={(
                                                            exptdinvqty
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    exptdinvqty,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'tdinvqty'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="invqty"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expinvqty ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state.expinvqty
                                                        }
                                                        callback={(expinvqty) =>
                                                            this.setState(
                                                                {
                                                                    expinvqty,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'invqty'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="invbal"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expinvbal ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state.expinvbal
                                                        }
                                                        callback={(expinvbal) =>
                                                            this.setState(
                                                                {
                                                                    expinvbal,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'invbal'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="invstax"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expinvstax ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expinvstax
                                                        }
                                                        callback={(
                                                            expinvstax
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expinvstax,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'invstax'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="invtotal"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expinvtotal ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expinvtotal
                                                        }
                                                        callback={(
                                                            expinvtotal
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expinvtotal,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'invtotal'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th className="position-relative">
                                                    <Form.Control
                                                        type="number"
                                                        data-type="number"
                                                        data-field="invamount"
                                                        onChange={
                                                            this.handleSearch
                                                        }
                                                        placeholder={
                                                            this.state
                                                                .expinvamount ||
                                                            '>='
                                                        }
                                                    />
                                                    <SearchExpressionButton
                                                        operator={
                                                            this.state
                                                                .expinvamount
                                                        }
                                                        callback={(
                                                            expinvamount
                                                        ) =>
                                                            this.setState(
                                                                {
                                                                    expinvamount,
                                                                },
                                                                this.resetSearchField.bind(
                                                                    this,
                                                                    'invamount'
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.dataIsLoaded
                                                ? this.state.items.map(
                                                      (item, i) => (
                                                          <tr
                                                              key={i}
                                                              data-key={i}
                                                              className={
                                                                  checkedItems
                                                                      .map(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.item
                                                                      )
                                                                      .includes(
                                                                          item.item
                                                                      )
                                                                      ? `active`
                                                                      : ''
                                                              }
                                                          >
                                                              <td>
                                                                  <div className="d-flex justify-content-center">
                                                                      <Form.Check
                                                                          label=""
                                                                          type="checkbox"
                                                                          checked={checkedItems
                                                                              .map(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item.item
                                                                              )
                                                                              .includes(
                                                                                  item.item
                                                                              )}
                                                                          onChange={() => {
                                                                              this.handleCheck(
                                                                                  item
                                                                              )
                                                                          }}
                                                                      />
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  {item.loc}
                                                              </td>
                                                              {/* <td>
                                                                  {
                                                                      item.groupname
                                                                  }
                                                              </td> */}
                                                              <td>
                                                                  {item.item}
                                                              </td>
                                                              <td>
                                                                  {item.payee}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.previnfo
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.shortdesc
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.quantity
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.esttprice,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.esttstax,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.esttotal,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.invdep,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      item.tdinvqty
                                                                  }
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.invbal,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      item.invstax,
                                                                      '$'
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {currencyFormat(
                                                                      Number(
                                                                          item.invbal
                                                                      ) +
                                                                          Number(
                                                                              item.invstax
                                                                          )
                                                                  )}
                                                              </td>
                                                              <td>
                                                                  {item.override && (
                                                                      <strong
                                                                          className={`dot-primary-red`}
                                                                      />
                                                                  )}
                                                                  <Link
                                                                      className="text-charcoal hover-view-icon"
                                                                      onClick={() => {
                                                                          this.openEditRefundModal(
                                                                              item
                                                                          )
                                                                      }}
                                                                  >
                                                                      {currencyFormat(
                                                                          item.invamount,
                                                                          '$'
                                                                      )}
                                                                  </Link>
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : showLoading()}
                                        </tbody>
                                    </Table>
                                    {this.state.items.length > 0 && (
                                        <Pagination
                                            onPageSizeChanged={
                                                this.onPageSizeChanged
                                            }
                                            onPageChanged={this.onPageChanged}
                                            hasPreviousPage={
                                                this.state.page > 1
                                            }
                                            hasNextPage={
                                                this.state.items.length >=
                                                this.state.pageSize
                                            }
                                            page={this.state.page}
                                            pageSize={this.state.pageSize}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleOk}
                        disabled={this.isOkDisabled()}
                    >
                        OK
                    </Button>
                </FooterFormAction>

                {this.state.editRefund.show && (
                    <EditRefundModal
                        isLoaded={this.state.editRefund.isLoaded}
                        hideModal={this.hideEditRefundModal}
                        handleSave={this.handleSaveEditRefund}
                        item={this.state.editRefund.item}
                        grid={this.state.editRefund.grid}
                        project={this.state.editRefund.project}
                    />
                )}

                {this.state.step === Step.confirming && (
                    <BasicModal
                        message={this.configuration?.confirmationMessage}
                        onPrimary={this.handleConfirmation}
                        onSecondary={() => {
                            this.setState({ step: Step.default })
                        }}
                    />
                )}
                {this.state.step === Step.payment && (
                    <DropdownModal
                        options={this.mapCCTypes(this.state.ccTypes)}
                        message={
                            'Choose the Credit Card for the refund or the Credit Card Type for the refund'
                        }
                        onCancel={() => {
                            this.setState({ step: Step.default })
                        }}
                        onConfirm={this.handleConfirmPayment}
                    />
                )}
                {this.state.step === Step.succeeded && (
                    <BasicModal
                        message={this.configuration?.successMessage}
                        primaryButtonTitle={'Yes'}
                        onPrimary={this.handleSuccessConfirmation}
                        secondaryButtonTitle={'No'}
                        onSecondary={this.handleFormReset}
                    />
                )}
            </>
        )
    }
}

export default WithRouter(ReturnsAndCreditsList)
