import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { DeleteResponse } from '../types/DeleteResponse'
import { DeletePayload } from '../types/DeletePayload'

const api = new ApiService()

const useDeleteObject = (
    options?: UseMutationOptions<
        DeleteResponse,
        unknown,
        DeletePayload,
        unknown
    >
) => {
    return useMutation({
        ...(options || {}),
        mutationFn: (deletePayload: DeletePayload) => {
            const deletedObject = api.deleteObjects(
                deletePayload
            ) as Promise<DeleteResponse>
            return deletedObject
        },
    })
}
export default useDeleteObject
