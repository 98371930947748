import ThreadComponent from '../ThreadComponent'
import { WithRouter } from '../../../helpers/Router'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import {
    displayAlertError,
    displayAlertLoader,
    displayAlertSuccess,
    getErrorMessage,
    hideAlertLoader,
} from '../../../utilities/Response'
import { getCookie } from '../../../utilities/Auth'
import { loadingWindowHTML } from '../../../utilities/DOM'
import MSG from 'defaults/Message'

class BatchCreateConfirmModal extends ThreadComponent {
    async handleAction() {
        let reportParams = [
            {
                parameterId: 341,
                value: 'multi-po',
            },
        ]

        for (let i in this.props.items) {
            reportParams.push({
                parameterId: 342,
                value: this.props.items[i].poid,
            })
        }

        let params = {
            instanceId: getCookie('dmInstanceId'),
            userId: getCookie('dmUsercode'),
            reportId: 1020,
            reportFormatId: 1251,
            reportObjectType: 'Report',
            runReportParameters: reportParams,
        }

        this.props.hideModal()

        try {
            displayAlertLoader(
                'Generating report, please wait<span class="dot-load"></span>'
            )

            const saveRequest = await this.api.postJson('runreports', params)

            if (saveRequest?.isOk === false) {
                displayAlertError(
                    saveRequest?.internalError ?? saveRequest?.userError
                )
            } else if (!saveRequest?.id) {
                displayAlertError(
                    saveRequest?.internalError ??
                        saveRequest?.userError ??
                        'Failed to run report. Please contact the administrator.'
                )
            } else {
                const windowReport = window.open('', '_blank')
                windowReport.document.write(loadingWindowHTML())
                displayAlertLoader(
                    'Please wait for your report to be generated<span class="dot-load"></span>'
                )
                const maxRequest = 10
                let requestCount = 0
                let interval = window.setInterval(async () => {
                    try {
                        const reportId = await this.api.get(
                            'runreports',
                            `?$filter=id eq ${saveRequest?.id}`
                        )
                        const fileId = reportId
                            ? reportId[0].completedFileId
                            : null
                        if (fileId) {
                            clearInterval(interval)
                            const label = 'Batch Purchase Orders'
                            const { link } = await this.api.getPublicMappings(
                                fileId,
                                label
                            )
                            windowReport.location.href = link
                            displayAlertSuccess(MSG.success.report.msg)
                        } else if (requestCount === maxRequest) {
                            clearInterval(interval)
                            displayAlertError(
                                'Report is on pending status. Try again later.'
                            )
                        }
                    } catch (error) {
                        displayAlertError(getErrorMessage(error))
                        windowReport.close()
                    }
                    requestCount++
                }, 5000)
            }
        } catch (error) {
            windowReport.close()
            displayAlertError(getErrorMessage(error))
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                aria-labelledby="action-modal"
                className="a-modal"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Row className="mb-3">
                        <Col className="px-5">
                            <h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
                                Batch Create Confirm
                            </h5>
                            <div className="text-center mx-auto fw-bold pt-4">
                                <h6>
                                    This will produce a combined document from
                                    the
                                    {` ${this.props.items.length}`} documents
                                    that you have selected. Would you like to
                                    continue?
                                </h6>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-3 px-5">
                    <Row className="w-100">
                        <Col className="d-flex" lg={{ span: 10, offset: 1 }}>
                            <Button
                                variant="danger"
                                onClick={this.handleAction.bind(this)}
                                className="w-100 m-2"
                            >
                                OK
                            </Button>
                            <Button
                                variant="trans-light border-secondary-ash"
                                onClick={this.props.hideModal.bind(this)}
                                className={`w-100 m-2`}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default WithRouter(BatchCreateConfirmModal)
