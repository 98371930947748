import ThreadComponent from '../../ThreadComponent'
import { WithRouter } from '../../../../helpers/Router'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import React from 'react'
import { FooterFormAction } from '../../../components/Form'
import { ApiService } from '../../../../lib/api/HttpService'
import { displayAlert } from '../../../../utilities/Response'
import { reload } from '../../../../helpers/Util'

export class WorkflowSettingsSchedule extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            updatedData: {},
        }
        this.data = this.props.data.company2
        this.api = new ApiService()
    }

    componentDidMount() {
        this.dMloader(false, true)
    }

    handleChange = (e) => {
        this.setState({
            updatedData: {
                ...this.state.updatedData,
                [e.target.id]: e.target.value,
            },
        })
    }

    handleSave = async () => {
        try {
            await this.api.patchCompany2(this.state.updatedData)
            displayAlert('success', 'Successfully saved changes.')

            reload()
        } catch (error) {
            displayAlert('danger', error.response.data.userError)
        }
    }

    renderData() {
        const rows = []
        for (let index = 1; index <= 10; index++) {
            const _index = index < 10 ? `0${index}` : index
            const phaseId = `sphase${_index}`
            const durationId = `sduration${_index}`
            rows.push(
                <Row key={index} className="mb-2">
                    <Col xs={3} className="text-lg-end mb-2 mb-lg-0">
                        <Form.Label className="mb-0 mt-2 pt-1">{`${index}.`}</Form.Label>
                    </Col>
                    <Col xs={4}>
                        <Form.Control
                            type="text"
                            id={phaseId}
                            value={
                                this.state.updatedData[phaseId] ??
                                this.data[phaseId]
                            }
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col xs={4}>
                        <Form.Control
                            type="text"
                            id={durationId}
                            value={
                                this.state.updatedData[durationId] ??
                                (this.data[durationId] === 0
                                    ? ''
                                    : this.data[durationId])
                            }
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
            )
        }
        return <>{rows}</>
    }

    render() {
        return (
            <>
                <div className="content-padding min-height">
                    <Container className="mb-4">
                        <Col>
                            <Row className={'mb-2'}>
                                <Col xs={11} lg={6}>
                                    <Row className={'mb-2'}>
                                        <Col xs={3}></Col>
                                        <Col xs={4}>Phase</Col>
                                        <Col xs={4}>Duration (Days)</Col>
                                    </Row>
                                    {this.renderData()}
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </div>
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(WorkflowSettingsSchedule)
