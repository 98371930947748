import * as React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import { Resource } from '../../../resources/Resources'
import logo from '../../../assets/images/logo-dark.svg'
import emoji from '../../../resources/assets/images/sad.svg'
import '../../../assets/styles/components/error-pages.scss'
import { Link } from 'react-router-dom'
import URI from '../../../defaults/RoutesDefault'

interface Props {
    code?: number
    message?: string
}

export function ErrorContent(props: Props) {
    return (
        <div className="d-flex justify-content-center flex-wrap align-items-center text-center">
            <div className="w-100 page-error">
                <div>
                    <Image
                        src={logo}
                        width="150"
                        height="auto"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </div>
                <h1 className="page-error__heading mb-0 mt-2">
                    {props.code || '500'}
                </h1>
                <div className="text-center">
                    <Image
                        src={Resource.Image.Util.ErrorSeparator}
                        className="mx-auto"
                        style={{
                            maxWidth: '100%',
                            height: '85px',
                            marginTop: '-15px',
                        }}
                    ></Image>
                </div>
                <h4 className="mb-4">
                    {props.message || 'Oops! Something went wrong.'}{' '}
                    <Image
                        src={emoji}
                        className="mx-auto"
                        style={{
                            maxWidth: '100%',
                            width: '40px',
                            marginTop: '0px',
                        }}
                    ></Image>
                </h4>
                <Link
                    to={URI.home}
                    className="text-decoration-none d-flex justify-content-center"
                >
                    <Button
                        variant="success btn-icon"
                        name="cancel"
                        className="mx-3 fs-lg"
                        size="lg"
                    >
                        <i className="ri-home-2-fill ri-lg me-2"></i>
                        Go Back to Home
                    </Button>
                </Link>
            </div>
        </div>
    )
}

class PageComponentError extends React.Component<Props> {
    readonly code: number
    readonly message: string

    constructor(props: Props) {
        super(props)

        this.code = props.code ?? 500
        this.message = props.message ?? 'Oops! Something went wrong.'
    }

    render(): JSX.Element {
        return (
            <div className="content-padding error-layout">
                <Container fluid>
                    <Row>
                        <Col
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{ minHeight: 'calc(100vh - 200px)' }}
                        >
                            <ErrorContent
                                code={this.code}
                                message={this.message}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PageComponentError
