import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import Select from 'react-select'

interface Props {
    onPageSizeChanged: (size: number) => void
    onPageChanged: (page: number) => void
    hasPreviousPage: boolean
    hasNextPage: boolean
    page: number
    pageSize: number
}

export class Pagination extends Component<Props, any> {
    availablePageSize = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 75, label: '75' },
        { value: 100, label: '100' },
    ]

    render() {
        return (
            <div className={'d-flex flex-wrap justify-content-center'}>
                <div
                    className={'d-flex flex-row align-items-center pagination'}
                >
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={!this.props.hasPreviousPage}
                        onClick={() =>
                            this.props.onPageChanged(this.props.page - 1)
                        }
                    >
                        <i className="ri-arrow-left-s-line"></i> Previous
                    </Button>
                    <span className={'pagination-span'}>{this.props.page}</span>
                    <Button
                        variant="ivory"
                        size="sm"
                        className={'btn-icon pagination-btn'}
                        disabled={!this.props.hasNextPage}
                        onClick={() =>
                            this.props.onPageChanged(this.props.page + 1)
                        }
                    >
                        Next <i className="ri-arrow-right-s-line ms-1"></i>
                    </Button>
                </div>
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                        e && this.props.onPageSizeChanged(e.value)
                    }}
                    key={`${Math.floor(Math.random() * 1000)}-min`}
                    options={this.availablePageSize}
                    defaultValue={{
                        value: this.props.pageSize,
                        label: `${this.props.pageSize}`,
                    }}
                    className="react-select pagination-select mx-3 z-"
                    placeholder="Please select"
                />
            </div>
        )
    }
}
