import React from 'react'
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { getRouteWithParam, WithRouter } from '../../../helpers/Router'

import URI from '../../../defaults/RoutesDefault'
import { Link, NavLink } from 'react-router-dom'
import { HeaderLight } from '../../components/Header'
import { ApiService } from '../../../../src/lib/api/HttpService'
import { displayAlert } from '../../../utilities/Response'
import { pathParam } from '../../../utilities/Router'
import { getLocalStorage } from '../../../utilities/LocalStorage'
import { generatePath } from 'react-router'

class GlossaryAddSalesTaxCalculation extends ThreadComponent {
    constructor(props) {
        super(props)
        this.api = new ApiService()
        this.state = {
            data: {
                taxc: '',
                // "seqnum": 0,
                tpm: 0,
                tpd: 0,
                tpf: 0,
                tpi: 0,
                tpl: 0,
                tpo: 0,
                lowamt: 0,
                highamt: 0,
                ontotal: true,
                onbelow: false,
                onbetween: false,
                onabove: false,
                onunit: false,
                rulen: '',
                invprintsep: false,
                ssmaTimeStamp: 'AAAAAAAtZUA=',
            },
            amountOption: 'ontotal',
            company: {},
            updatedValue: '',
        }

        this.isEditing = Boolean(this.props.params.taxc)
        this.tpdInputRef = React.createRef()
    }

    handleOnFormChange = (e) => {
        if (e.target.type === 'checkbox') {
            this.setState({
                data: { ...this.state.data, [e.target.name]: e.target.checked },
            })
        } else if (e.target.type === 'radio') {
            switch (e.target.id) {
                case 'ontotal':
                    this.setState({
                        data: {
                            ...this.state.data,
                            ontotal: true,
                            onbelow: false,
                            onbetween: false,
                            onabove: false,
                        },
                    })
                    break
                case 'onbelow':
                    this.setState({
                        data: {
                            ...this.state.data,
                            ontotal: false,
                            onbelow: true,
                            onbetween: false,
                            onabove: false,
                        },
                    })
                    break
                case 'onbetween':
                    this.setState({
                        data: {
                            ...this.state.data,
                            ontotal: false,
                            onbelow: false,
                            onbetween: true,
                            onabove: false,
                        },
                    })
                    break
                case 'onabove':
                    this.setState({
                        data: {
                            ...this.state.data,
                            ontotal: false,
                            onbelow: false,
                            onbetween: false,
                            onabove: true,
                        },
                    })
                    break
                default:
                    this.setState({
                        data: {
                            ...this.state.data,
                            ontotal: false,
                            onbelow: false,
                            onbetween: false,
                            onabove: false,
                        },
                    })
            }
            this.setState({ amountOption: e.target.id })
        } else {
            this.setState({
                data: { ...this.state.data, [e.target.name]: e.target.value },
            })
        }
    }

    handleValueChange = (e) => {
        const targetValue = e.target.value

        this.setState((prevState) => {
            const updatedState = {}
            const tpValuesToUpdate = ['tpm', 'tpd', 'tpf', 'tpi', 'tpl', 'tpo']
            tpValuesToUpdate.forEach((key) => {
                updatedState[key] = targetValue
            })

            return {
                data: {
                    ...prevState.data,
                    ...updatedState,
                },
                updatedValue: targetValue,
            }
        })
    }

    handleTPMChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpm: e.target.value,
            },
        }))
    }

    handleTPDChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpd: e.target.value,
            },
        }))
    }
    handleTPFChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpf: e.target.value,
            },
        }))
    }
    handleTPIChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpi: e.target.value,
            },
        }))
    }
    handleTPLChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpl: e.target.value,
            },
        }))
    }
    handleTPOChange = (e) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                tpo: e.target.value,
            },
        }))
    }

    handleSaveAndNewClick = async (e) => {
        e.preventDefault(),
            await this.api
                .addSalesTaxCodeCalculation(this.state.data)
                .then((res) => {
                    displayAlert('success', 'Successfully saved changes!')
                    window.onbeforeunload = null
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                })
    }

    handleSaveClick = async (e) => {
        e.preventDefault()
        let { data } = this.state
        if (!data.taxc) {
            data.taxc = this.props.params.taxc
        }
        await this.api
            .addSalesTaxCodeCalculation(data)
            .then((res) => {
                displayAlert('success', 'Successfully saved changes!')
                // Redirect to list page
                window.onbeforeunload = null
                setTimeout(() => {
                    if (this.isEditing) {
                        window.location.href = getRouteWithParam(
                            URI.glossary.salesTaxCodes,
                            {
                                taxc: this.props.params.taxc,
                            }
                        )
                    } else {
                        this.props.navigate(URI.glossary.add.salesTaxCodes, {
                            state: { taxc: this.props.params.taxc },
                        })
                    }
                }, 2000)
            })
            .catch((err) => {
                const errorMessage =
                    err.response?.data?.userError ||
                    'Sales tax code calculation could not be saved'
                displayAlert('danger', errorMessage)
            })
    }

    componentInit() {
        this.setTitle('Sales Tax Calculation | Glossary')
        this.setFormAction(true)
    }

    async componentDidMount() {
        this.dMloader(true)
        const company = await this.api.get('company', '')
        this.setState({
            company: company,
        })
        if (this.props.params.taxc) {
            this.setState({
                data: { ...this.state.data, taxc: this.props.params.taxc },
            })
        }

        this.isEditing = JSON.parse(getLocalStorage('isEditing'))
        this.dMloader(false)
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={URI.timeEntry.base}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb className="ms-4">
                        <Breadcrumb.Item
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Settings
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{ to: URI.glossary.base }}
                            linkAs={Link}
                        >
                            Glossary
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: getRouteWithParam(URI.glossary.list, {
                                    path: 'sales-tax-codes',
                                }),
                            }}
                            linkAs={Link}
                        >
                            Sales Tax Codes
                        </Breadcrumb.Item>
                        {this.props.params?.taxc && this.isEditing ? (
                            <Breadcrumb.Item
                                linkProps={{
                                    to: pathParam(
                                        URI.glossary.edit.salesTaxCodes,
                                        { code: this.props.params.taxc }
                                    ),
                                }}
                                linkAs={Link}
                            >
                                Edit Sales Tax Code {this.props.params.taxc}
                            </Breadcrumb.Item>
                        ) : (
                            <Breadcrumb.Item
                                linkProps={{
                                    to: URI.glossary.add.salesTaxCodes,
                                }}
                                linkAs={Link}
                            >
                                Add Sales Tax Code
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item linkProps={{ to: '#' }} linkAs={Link}>
                            Sales Tax Calculation
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content>
                    <HeaderLight.Title>Sales Tax Calculation</HeaderLight.Title>

                    <div>
                        {/* Submit Button */}
                        <Button
                            onClick={this.handleSaveAndNewClick}
                            variant="primary me-2"
                        >
                            Save & New
                        </Button>

                        <Button
                            onClick={this.handleSaveClick}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </div>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderOptions() {
        const { data, amountOption } = this.state
        return (
            <>
                {amountOption === 'onbelow' || amountOption === 'onbetween' ? (
                    <Col className="mb-3 mb-md-0">
                        <Form.Label className="mb-2 mb-md-1">
                            <strong>Below $</strong>
                        </Form.Label>
                        <Form.Control
                            onChange={this.handleOnFormChange}
                            type="text"
                            placeholder="Please enter"
                            name="lowamt"
                            defaultValue={data.lowamt}
                        />
                    </Col>
                ) : null}
                {amountOption === 'onabove' || amountOption === 'onbetween' ? (
                    <Col className="mb-3 mb-md-0">
                        <Form.Label className="mb-2 mb-md-1">
                            <strong>Above $</strong>
                        </Form.Label>
                        <Form.Control
                            onChange={this.handleOnFormChange}
                            type="text"
                            placeholder="Please enter"
                            name="highamt"
                            defaultValue={data.highamt}
                        />
                    </Col>
                ) : null}
            </>
        )
    }

    renderContent() {
        const { data } = this.state

        return (
            <Container className="ms-0">
                <Row xs={1} lg={2} className="pt-4 pb-2">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Authority Name/ Description
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    onChange={this.handleOnFormChange}
                                    type="text"
                                    maxLength={30}
                                    placeholder="Please enter"
                                    name="rulen"
                                    defaultValue={data.rulen}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-4">
                    <Col className="mb-3 mb-lg-0">
                        <Row className="align-items-start align-items-md-end mb-3">
                            <Col
                                lg={1}
                                className="text-lg-end mb-2 mb-lg-0"
                                style={{ width: '12.5%' }}
                            >
                                <Form.Label className="mb-2">Tax %</Form.Label>
                            </Col>
                            <Col>
                                <Row
                                    xs={1}
                                    md={6}
                                    className="align-items-center"
                                >
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewm}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleValueChange(e)
                                                this.handleTPMChange(e)
                                            }}
                                            type="text"
                                            name="tpm"
                                            id="tpm"
                                            placeholder={data.tpm}
                                            value={this.state.data.tpm}
                                        />
                                    </Col>
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewd}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleTPDChange(e)
                                            }}
                                            type="text"
                                            name="tpd"
                                            placeholder={data.tpd}
                                            value={this.state.data.tpd}
                                        />
                                    </Col>
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewf}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleTPFChange(e)
                                            }}
                                            type="text"
                                            name="tpf"
                                            id="tpf"
                                            placeholder={data.tpf}
                                            value={this.state.data.tpf}
                                        />
                                    </Col>
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewi}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleTPIChange(e)
                                            }}
                                            type="text"
                                            name="tpi"
                                            placeholder={data.tpi}
                                            value={this.state.data.tpi}
                                        />
                                    </Col>
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewl}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleTPLChange(e)
                                            }}
                                            type="text"
                                            name="tpl"
                                            placeholder={data.tpl}
                                            value={this.state.data.tpl}
                                        />
                                    </Col>
                                    <Col className="mb-3 mb-md-0">
                                        <Form.Label className="mb-2 mb-md-3">
                                            <strong>
                                                {this.state.company.compviewo}
                                            </strong>
                                        </Form.Label>
                                        <Form.Control
                                            onChange={(e) => {
                                                this.handleOnFormChange(e)
                                                this.handleTPOChange(e)
                                            }}
                                            type="text"
                                            name="tpo"
                                            placeholder={data.tpo}
                                            value={this.state.data.tpo}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-end mb-3">
                            <Col
                                lg={1}
                                className="text-lg-end mb-2 mb-lg-0"
                                style={{ width: '12.5%' }}
                            ></Col>
                            <Col>
                                <Row className="align-items-center">
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Total"
                                            name="select-amount"
                                            type="radio"
                                            id="ontotal"
                                            defaultChecked={data.ontotal}
                                            className="me-3"
                                            onChange={this.handleOnFormChange}
                                        />
                                        <Form.Check
                                            inline
                                            label="Below"
                                            name="select-amount"
                                            type="radio"
                                            id="onbelow"
                                            defaultChecked={data.onbelow}
                                            className="me-3"
                                            onChange={this.handleOnFormChange}
                                        />
                                        <Form.Check
                                            inline
                                            label="Between"
                                            name="select-amount"
                                            type="radio"
                                            id="onbetween"
                                            defaultChecked={data.onbetween}
                                            className="me-3"
                                            onChange={this.handleOnFormChange}
                                        />
                                        <Form.Check
                                            inline
                                            label="Above"
                                            name="select-amount"
                                            type="radio"
                                            id="onabove"
                                            defaultChecked={data.onabove}
                                            className="me-3"
                                            onChange={this.handleOnFormChange}
                                        />
                                    </Col>
                                    {this.renderOptions()}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="align-items-end">
                            <Col
                                lg={1}
                                className="text-lg-end mb-2 mb-lg-0"
                                style={{ width: '12.5%' }}
                            ></Col>
                            <Col>
                                <Row className="align-items-center">
                                    <Col>
                                        <Form.Check
                                            onChange={this.handleOnFormChange}
                                            inline
                                            label="Calculate on Unit Price"
                                            name="onunit"
                                            type="checkbox"
                                            id={`checkbox-group-1`}
                                            className="me-3"
                                            defaultChecked={data.onunit}
                                        />
                                        <Form.Check
                                            onChange={this.handleOnFormChange}
                                            inline
                                            label="Print Separate on Invoice"
                                            name="invprintsep"
                                            type="checkbox"
                                            id={`checkbox-group-2`}
                                            className="me-3"
                                            defaultChecked={data.invprintsep}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const { isLoading, isDraft } = this.state

        return (
            <>
                {this.renderHeader()}
                <div className="content-padding min-height has-action-bar">
                    <Container fluid className="px-0">
                        {this.renderContent()}
                    </Container>
                </div>
            </>
        )
    }
}

export default WithRouter(GlossaryAddSalesTaxCalculation)
