import React from 'react'
import { Button, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import URI from '../../../../defaults/RoutesDefault'
import { pathParam } from '../../../../utilities/Router'
import { compareStr } from '../../../../utilities/String'
import { HeaderLight } from '../../../components/header/HeaderComponent'
import {
    getLocalStorage,
    setLocalStorage,
} from '../../../../utilities/LocalStorage'
import SecureButton from '../../../components/security/SecureButton'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security'

interface Props {
    clientName?: string
    activeMenu?: string
    onSave?: any
    clientId?: any
    isLoading?: boolean
    onSwitchTab?: any
    isDraft?: boolean
    numAttachments: number
    isSaveHidden: boolean
}
interface State {
    clientName?: string
    isLoading?: boolean
    showButtons?: boolean
}

interface HeaderLink {
    info: string
    notes: string
    documents: string
}

export default class ClientAddEditHeader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            clientName: 'Add Client',
            isLoading: false,
            showButtons: false,
        }
    }

    componentDidMount(): void {
        if (!this.props.clientName && this.props.clientId) {
            this.setState({
                clientName: 'Edit Client',
            })
        }
        if (this.props.clientName) {
            const { clientName, isLoading } = this.props

            this.setState({
                clientName: clientName,
                isLoading: isLoading,
            })
        }
        window.localStorage.removeItem('isAdd')
        if (!this.props.clientId) {
            setLocalStorage('isAdd', true)
        }

        setTimeout(() => {
            this.setState({
                showButtons: true,
            })
        }, 250)
    }

    componentDidUpdate(previousProps: Props, previousState: State): void {
        if (
            previousProps.clientName !== this.props.clientName ||
            previousProps.isLoading !== this.props.isLoading
        ) {
            const { clientName, isLoading } = this.props

            this.setState({
                clientName: clientName || 'Add Client',
                isLoading: isLoading,
            })
        }
    }

    handleClickSave: any = (e: any) => {
        window.localStorage.setItem('isDraft', 'false')
        window.onbeforeunload = null
        if (this.props.onSave) {
            this.props.onSave(e)
        }
    }

    onSwitchTab: any = (e: any) => {
        if (this.props.onSwitchTab) {
            this.props.onSwitchTab(e)
        }
    }

    handleClick = (e: any) => {
        const isDraft = getLocalStorage('isDraft')

        if (isDraft === 'true') {
            e.preventDefault()

            if (confirm('Leaving this screen will discard your changes.')) {
                window.localStorage.setItem('isDraft', 'false')
                if (e.currentTarget.href !== undefined) {
                    location.href = e.currentTarget.href
                } else if (e.target.href !== undefined) {
                    location.href = e.target.href
                } else {
                    location.href = e.currentTarget.firstChild.href
                }
            }
        }
    }

    render(): JSX.Element {
        const { clientName, isLoading } = this.state
        const { activeMenu, clientId, isDraft } = this.props

        const link: HeaderLink = {
            info: pathParam(
                clientId ? URI.contact.clientEdit : URI.contact.clientAdd,
                { id: this.props.clientId, tab: 'info' }
            ),
            notes: pathParam(
                clientId ? URI.contact.clientEdit : URI.contact.clientAdd,
                { id: this.props.clientId, tab: 'notes' }
            ),
            documents: pathParam(
                clientId ? URI.contact.clientEdit : URI.contact.clientEdit,
                { id: this.props.clientId, tab: 'documents' }
            ),
        }

        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClickCapture={this.handleClick}
                            linkProps={{ to: URI.contact.client }}
                            linkAs={Link}
                        >
                            Clients
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{clientName}</Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>{clientName}</HeaderLight.Title>

                    <div
                        className={
                            this.state.showButtons ? 'd-block' : 'd-none'
                        }
                    >
                        {/* Submit Button */}
                        <SecureButton
                            attributeNo={39}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            className={
                                this.props.clientId
                                    ? 'd-none'
                                    : 'd-inline-block'
                            }
                            variant="primary me-2"
                            onClick={this.handleClickSave}
                            name="saveNew"
                            disabled={!isDraft || isLoading}
                        >
                            Save & New
                        </SecureButton>

                        <SecureButton
                            attributeNo={39}
                            attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
                            variant="primary"
                            onClick={this.handleClickSave}
                            name="save"
                            disabled={!isDraft || isLoading}
                        >
                            Save
                        </SecureButton>
                    </div>
                </HeaderLight.Content>
                <HeaderLight.Actions>
                    <li>
                        <Link
                            onClick={this.onSwitchTab}
                            to={link.info}
                            data-menu="info"
                            className={
                                `btn text-charcoal ` +
                                compareStr(
                                    activeMenu || '',
                                    'info',
                                    'active',
                                    ''
                                )
                            }
                        >
                            Info
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={this.onSwitchTab}
                            to={link.notes}
                            data-menu="notes"
                            className={
                                `btn text-charcoal ` +
                                compareStr(
                                    activeMenu || '',
                                    'notes',
                                    'active',
                                    ''
                                )
                            }
                        >
                            Notes
                        </Link>
                    </li>
                    {clientId && (
                        <li>
                            <Link
                                onClick={this.onSwitchTab}
                                to={link.documents}
                                data-menu="documents"
                                className={
                                    `btn text-charcoal ` +
                                    compareStr(
                                        activeMenu || '',
                                        'documents',
                                        'active',
                                        ''
                                    )
                                }
                            >
                                Documents{' '}
                                {this.props.numAttachments > 0 && (
                                    <i className="ri-attachment-2 ms-1"></i>
                                )}
                            </Link>
                        </li>
                    )}
                </HeaderLight.Actions>
            </HeaderLight>
        )
    }
}
