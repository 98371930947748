import React, { FC, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { showEmpty, showLoading } from 'helpers/Loading'
import { Link } from 'react-router-dom'
import { routeParam } from 'helpers/Router'
import URI from 'defaults/RoutesDefault'
import { Resource } from 'resources/Resources'
import { currencyFormat } from 'helpers/Number'
import { TStockItem } from 'lib/api/types/StockItem'

interface IStockItemsListTableProps {
    data: TStockItem[]
    thumbnails: Map<number, any>
    sort: { key: string; direction: string }
    selectedIds: number[]
    isSearching: boolean
    isLoading: boolean
    onCheck: (id: number) => void
    onCheckAll: () => void
    onSearch: (key: string, value: string, type?: string) => void
    onSort: (sort: { key: string; direction: string }) => void
}
export const StockItemsListTable: FC<IStockItemsListTableProps> = ({
    data,
    thumbnails,
    sort,
    selectedIds,
    isSearching,
    isLoading,
    onCheck,
    onCheckAll,
    onSearch,
    onSort,
}) => {
    const [headers, setHeaders] = useState([
        {
            title: 'Image',
            key: undefined,
            direction: 'asc',
        },
        {
            title: 'Stock No.',
            key: 'stockno',
            direction: 'asc',
        },
        {
            title: 'Style/Size',
            key: 'category1',
            direction: 'asc',
        },
        {
            title: 'Color/Finish',
            key: 'category2',
            direction: 'asc',
        },
        {
            title: 'Sales Cat.',
            key: 'scat',
            direction: 'asc',
        },
        {
            title: 'Description',
            key: 'shortdesc',
            direction: 'asc',
        },
        {
            title: 'Storage Loc.',
            key: 'locbin',
            direction: 'asc',
        },
        {
            title: 'Vendor',
            key: 'supplier',
            direction: 'asc',
        },
        {
            title: 'Mfg No.',
            key: 'mfgcat',
            direction: 'asc',
        },
        { title: 'Units', key: 'unitofmeasure', direction: 'asc' },
        { title: 'On Hand', key: 'qtypaidfor', direction: 'asc' },
        { title: 'Committed', key: 'committed', direction: 'asc' },
        { title: 'On Order', key: 'onorder', direction: 'asc' },
        { title: 'Unit Price', key: 'unitprice', direction: 'asc' },
        { title: 'Unit Cost', key: 'unitestcost', direction: 'asc' },
    ])

    const handleSort = (key: string) => {
        let direction = ''
        const newHeaders = headers
        newHeaders.forEach((header) => {
            if (header.key === key) {
                header.direction = header.direction === 'asc' ? 'desc' : 'asc'
                direction = header.direction
            } else {
                header.direction = 'asc'
            }
        })
        setHeaders(newHeaders)

        onSort({ key: key, direction: direction })
    }

    return (
        <div className="table-gradient sticky-container">
            <Table
                striped
                responsive
                className="a-table"
                style={{ tableLayout: 'fixed' }}
            >
                <thead>
                    <tr key="0" className="a-table-heading">
                        <th
                            align="center"
                            style={{
                                width: '50px',
                            }}
                        >
                            <div className="d-flex justify-content-center">
                                <Form.Check
                                    label=""
                                    type="checkbox"
                                    checked={selectedIds.length > 0}
                                    className={
                                        selectedIds.length > 0 ? 'line' : ''
                                    }
                                    onChange={() => {
                                        onCheckAll()
                                    }}
                                />
                            </div>
                        </th>
                        {headers.map((header, index) => {
                            return (
                                <th
                                    key={index}
                                    style={{
                                        width: '180px',
                                    }}
                                >
                                    <span
                                        className={`
                                        ${header.key ? 'sort' : ''}
                                        ${
                                            header.direction
                                                ? header.direction
                                                : ''
                                        }
                                        ${
                                            sort.key == header.key
                                                ? 'active'
                                                : ''
                                        }
                                        `}
                                        id={header.key}
                                        onClick={() => {
                                            if (!header.key) {
                                                return
                                            }
                                            handleSort(header.key)
                                        }}
                                    >
                                        {header.title}
                                    </span>
                                </th>
                            )
                        })}
                    </tr>
                    {isSearching && (
                        <tr className="a-table-search-fields">
                            <th></th>
                            <th></th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="stockno"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="category1"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="category2"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="scat"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="shortdesc"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="locbin"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="supplier"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="mfgcat"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <Form.Control
                                    type="text"
                                    id="unitofmeasure"
                                    onChange={(e) => {
                                        onSearch(e.target.id, e.target.value)
                                    }}
                                />
                            </th>
                            <th className="mw-180px">
                                <div className="d-flex flex-row">
                                    <NumberField
                                        placeholder="From"
                                        id="qtypaidfor-min"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                    <NumberField
                                        placeholder="To"
                                        className="ms-2"
                                        id="qtypaidfor-max"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="mw-180px">
                                <div className="d-flex flex-row">
                                    <NumberField
                                        placeholder="From"
                                        id="committed-min"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                    <NumberField
                                        placeholder="To"
                                        className="ms-2"
                                        id="committed-max"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="mw-180px">
                                <div className="d-flex flex-row">
                                    <NumberField
                                        placeholder="From"
                                        id="onorder-min"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                    <NumberField
                                        placeholder="To"
                                        className="ms-2"
                                        id="onorder-max"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="mw-180px">
                                <div className="d-flex flex-row">
                                    <NumberField
                                        placeholder="From"
                                        id="unitprice-min"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                    <NumberField
                                        placeholder="To"
                                        className="ms-2"
                                        id="unitprice-max"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                </div>
                            </th>
                            <th className="mw-180px">
                                <div className="d-flex flex-row">
                                    <NumberField
                                        placeholder="From"
                                        id="unitestcost-min"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                    <NumberField
                                        placeholder="To"
                                        className="ms-2"
                                        id="unitestcost-max"
                                        onChange={(e) => {
                                            onSearch(
                                                e.target.id,
                                                e.target.value,
                                                'range'
                                            )
                                        }}
                                    />
                                </div>
                            </th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {isLoading
                        ? showLoading()
                        : data.length
                        ? data.map((item: TStockItem, i) => (
                              <tr
                                  key={i}
                                  className={
                                      selectedIds.includes(item.id)
                                          ? `active`
                                          : ''
                                  }
                              >
                                  <td>
                                      <div className="d-flex justify-content-center">
                                          <Form.Check
                                              label=""
                                              type="checkbox"
                                              checked={selectedIds.includes(
                                                  item.id
                                              )}
                                              onChange={() => {
                                                  onCheck(item.id)
                                              }}
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      {thumbnails.has(item.id) ? (
                                          <Link
                                              to={routeParam(
                                                  URI.stockItem.edit,
                                                  { id: item.id }
                                              )}
                                          >
                                              <img
                                                  className="img-sm object-fit-contain"
                                                  style={{ aspectRatio: 1 }}
                                                  src={URL.createObjectURL(
                                                      thumbnails.get(item.id)
                                                  )}
                                                  alt={item.itemName}
                                              />
                                          </Link>
                                      ) : (
                                          <img
                                              className="img-sm"
                                              src={
                                                  Resource.Image.ClipperFallback
                                                      .default
                                              }
                                              alt={item.itemName}
                                          />
                                      )}
                                  </td>
                                  <td>
                                      <Link
                                          to={routeParam(URI.stockItem.edit, {
                                              id: item.id,
                                          })}
                                          className="text-charcoal hover-view-icon"
                                      >
                                          {item.stockno}
                                      </Link>
                                  </td>
                                  <td>{item.category1}</td>
                                  <td>{item.category2}</td>
                                  <td>{item.scat}</td>
                                  <td>{item.shortdesc}</td>
                                  <td>{item.locbin}</td>
                                  <td>{item.supplier}</td>
                                  <td>{item.mfgcat}</td>
                                  <td>{item.unitofmeasure}</td>
                                  <td>{item.qtypaidfor}</td>
                                  <td>{item.committed}</td>
                                  <td>{item.onorder}</td>
                                  <td>{currencyFormat(item.unitprice)}</td>
                                  <td>{currencyFormat(item.unitestcost)}</td>
                              </tr>
                          ))
                        : showEmpty()}
                </tbody>
            </Table>
        </div>
    )
}
StockItemsListTable.displayName = 'StockItemsListTable'

interface INumberFieldProps {
    id: string
    placeholder: string
    className?: string
    onChange: (e: any) => void
}
const NumberField: FC<INumberFieldProps> = ({
    id,
    placeholder,
    className,
    onChange,
}) => {
    return (
        <Form.Control
            placeholder={placeholder}
            type="number"
            className={className}
            id={id}
            onChange={(e) => {
                onChange(e)
            }}
            onWheel={(e) => {
                // eslint-disable-next-line
                (e.target as HTMLElement).blur()
            }}
        />
    )
}
NumberField.displayName = 'NumberField'
