import React from 'react'
import { PRO_CLOUD_LOGIN_PAGE, useUserQuery } from 'app/context/security'
import AppLayout from 'app/layouts/AppLayout'
import Spinner from 'app/components/help/Spinner'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { API_AUTH_ERROR_MESSAGES } from 'lib/api/HttpService'
import './InvalidInstance.css'

const InvalidInstanceComponent = () => {
    const userQuery = useUserQuery(['SecurityProvider', 'invalidInstance'])
    const navigate = useNavigate()
    const { data, isLoading, error } = userQuery

    const shouldRedirect =
        data ||
        (error as AxiosError<{ UserError: string }>)?.response?.data
            ?.UserError !== API_AUTH_ERROR_MESSAGES.INVALID_INSTANCE

    if (isLoading) {
        return <Spinner />
    }

    if (shouldRedirect) {
        navigate('/')
    }
    return (
        <>
            <AppLayout />
            <div className="invalid-instance-container">
                <p className="invalid-instance-text">
                    Web DM is not available for your company at this time.
                </p>
                <p className="invalid-instance-text invalid-instance-link-container">
                    Please continue to Pro Cloud via
                    <a
                        className="invalid-instance-link"
                        target="_blank"
                        rel="noreferrer"
                        href={PRO_CLOUD_LOGIN_PAGE}
                    >
                        {PRO_CLOUD_LOGIN_PAGE}
                    </a>
                </p>
            </div>
        </>
    )
}

InvalidInstanceComponent.displayName = 'InvalidInstanceComponent'

export default InvalidInstanceComponent
