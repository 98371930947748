import { Component } from 'lib/api/types/Component'
import { Summary } from 'lib/api/types/Summary'
import { mapRtfToDraft } from 'utilities/Rtf'
import { TFormValues } from '../types/TFormValues'

const getPreloadValues = async (component: Component, address: Summary[]) => {
    console.log('getPreloadValues()')
    const {
        quantity,
        unitmeasure,
        itemName,
        supplier,
        useterms,
        bterm1,
        unitlist,
        supdep,
        feecost,
        estmarkup,
        bterm2,
        comptype,
        list,
        bterm3,
        feeunitcost,
        usedisc,
        markup,
        estcost,
        estdisc,
        estprice,
        catalog,
        estunitcost,
        descOnly,
        descrtf,
        descOnlyRtf,
        taxable,
    } = component

    const addressByCode = address[0]

    const description = await mapRtfToDraft(descOnlyRtf || descrtf || descOnly)

    const newValues = {
        itemName,
        comptype,
        estdisc: Number(estdisc),
        supdep: Number(supdep),
        quantity: Number(quantity),
        bterm1: Number(bterm1),
        bterm2: Number(bterm2),
        bterm3: Number(bterm3),
        estunitcost: Number(estunitcost),
        usedisc,
        markup: Number(markup),
        catalog: catalog || '',
        taxable,
        shipto: address && addressByCode ? addressByCode?.key : null,
        estcost: Number(estcost),
        estmarkup: Number(estmarkup),
        estprice: Number(estprice),
        unitmeasure: unitmeasure || 'each',
        feeunitcost: Number(feeunitcost),
        useterms,
        supplier,
        unitlist: Number(unitlist),
        list: Number(list),
        feecost: Number(feecost),
        description: {
            value: descOnly,
            html: description,
        } as TFormValues['description'],
    }

    return newValues
}
export default getPreloadValues
