import React from 'react'
import { Container, Button, Row, Col, Table } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { WithRouter, routeParam } from '../../../../helpers/Router'
import { fakeData } from '../../../factory/fakeFactory'
import { HeaderLight } from '../../../../templates/components/Header'
import ListFilter from '../../../components/ListFilter'
import ComboBox from '../../../components/ComboBox'
import { delay, assign, map } from 'lodash'
import { ApiService } from '../../../../lib/api/HttpService'
import { showEmpty, showLoading } from '../../../../helpers/Loading'
import Select from 'react-select'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM'
import { encodeURI } from '../../../../utilities/String'
import MultiSearchComponents from '../../../../app/components/multi-search/MultiSearchComponents'
import MultiSearchComponentsText from '../../../../app/components/multi-search/MultiSearchComponentsText'
import MultiSearchComponentsSwitch from '../../../../app/components/multi-search/MultiSearchComponentsSwitch'
import MultiSearchComponentsDateRange from '../../../../app/components/multi-search/MultiSearchComponentsDateRange'
import MultiSearchComponentsCombobox from '../../../../app/components/multi-search/MultiSearchComponentsCombobox'
import MultiSearchComponentsReactSelect from '../../../../app/components/multi-search/MultiSearchComponentsReactSelect'
import {
    displaySortableTableData,
    displaySortableTableHeader,
} from '../../../../app/components/table/TableDisplay'
import MultiSearchComponentsNumberRange from '../../../../app/components/multi-search/MultiSearchComponentsNumberRange'
import loadItemImages from '../../../../utilities/Images'
import { ItemSearchFields, getItemSearchTableHeaders } from './ItemSearch'
import FilterSort from '../../../../utilities/modules/FilterSort'
import URI from '../../../../defaults/RoutesDefault'

var timeout

class ItemSearchList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.api = new ApiService()

        this.fs = new FilterSort('item_search_list')
        this.state = {
            checks: {},
            totalChecked: 0,
            startDate: '',
            minDate: new Date(),
            isDisabled: true,
            data: fakeData(4),
            dataIsLoaded: false,
            additionalFilters: this.fs.getSearch('selected_filters') ?? [],
            dateValues: {
                entry: [],
                keyedate: [],
            },
            isLoaded: false,
            clear: false,
            page: 1,
            pageSize: { value: 20, label: '20' },
            propertyFilter: this.fs.getSearch('active_filters') ?? {},
            sortProperty: this.fs.getSort() ?? 'item asc',
            wildcardstatus1: '',
            wildcardstatus2: '',
            ItemSearchFields: ItemSearchFields,
            filterOptions: ItemSearchFields.filter(
                (f) => !this.isFixed(f.isFixed)
            ).map((f) => ({
                key: f.id,
                value: f.label,
            })),
            fieldValues: this.fs.getSearches(),
            imagesLoaded: false,
            imageData: {},
            statusNames: {},
            tableHeaders: getItemSearchTableHeaders(this),
        }

        this.availablePageSize = [
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '75', label: '75' },
            { value: '100', label: '100' },
        ]

        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    async componentDidMount() {
        window.isMounted = true
        this.enableSortTable()

        const statuses = await this.api.getStatusCodes()
        const statusNames = {}
        statuses?.map((s) => {
            statusNames[s.statusNumber] = s.statusName
        })

        const company = await this.api.getCompany()
        const wildcardstatus1 = company?.wildcardstatus1 ?? ''
        const wildcardstatus2 = company?.wildcardstatus2 ?? ''

        const ItemSearchFields = this.state.ItemSearchFields

        ItemSearchFields.map((f) => {
            if (f.id === 'user-date-1' && wildcardstatus1) {
                f.label = wildcardstatus1
            }
            if (f.id === 'user-date-2' && wildcardstatus2) {
                f.label = wildcardstatus2
            }
            if (f.id === 'component-type-dropdown' && company) {
                const fields = [
                    'compviewm',
                    'compviewd',
                    'compviewf',
                    'compviewi',
                    'compviewl',
                    'compviewo',
                ]
                const componentTypes = []
                fields.map((ct, i) => {
                    componentTypes.push({
                        key: i,
                        value: company[ct],
                    })
                })
                f.options = componentTypes
            }
        })

        this.setState(
            {
                statusNames,
                statuses,
                ItemSearchFields,
                filterOptions: ItemSearchFields.filter(
                    (f) => !this.isFixed(f.isFixed)
                ).map((f) => ({
                    key: f.id,
                    value: f.label,
                })),
            },
            () => {
                this.loadData()
            }
        )
    }

    componentWillUnmount() {
        window.isMounted = false
    }

    async loadData() {
        await this.fetchData()
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        let sortProperty = ''
                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                            sortProperty = `${_e.attributes['data-field'].value} asc`
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                            sortProperty = `${_e.attributes['data-field'].value} desc`
                        } else {
                            addDomClass('desc', _e)
                            sortProperty = `${_e.attributes['data-field'].value} desc`
                        }

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty: sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    fetchData = async (page) => {
        let query = ''
        let filters = []
        let halt = false
        _.forEach(this.state.propertyFilter, (filter, k) => {
            if (typeof filter == 'object') {
                let range = ''
                if (filter.from !== undefined || filter.to !== undefined) {
                    range = filter.from ? filter.from : ''
                    range =
                        range.length && filter.to
                            ? `${range} and ${filter.to}`
                            : filter.to ?? range

                    range.length && filters.push(`(${range})`)
                }
            } else {
                filters.push(filter)
            }
        })

        if (halt) {
            return
        }

        this.setState({
            isLoaded: false,
            imagesLoaded: false,
        })

        if (filters.length > 0) {
            query += `$filter=${filters.join(' and ')}&`
        }

        query += `$top=${parseInt(this.state.pageSize.value) + 1}&$skip=${
            ((page ?? this.state.page) - 1) * this.state.pageSize.value
        }`

        if (this.state.sortProperty !== '') {
            query += `&$orderby=${this.state.sortProperty}`
        }

        const data = await this.api.getItemSearch(`?${query}`)

        const uniqueItemsAndProj = Array.from(
            new Set(
                data.map((item) =>
                    JSON.stringify({ item: item.item, proj: item.proj })
                )
            )
        ).map((str) => JSON.parse(str))

        const refUrls = []
        for (const obj of uniqueItemsAndProj) {
            const itemData = await this.api.getProjectItems(
                `?$filter=item eq '${obj.item}' and proj eq '${encodeURI(
                    obj.proj
                )}'`
            )

            if (itemData) {
                const route = routeParam(URI.project.itemEdit, {
                    id: itemData[0].projectId,
                    itemId: itemData[0].id,
                })

                for (const i in data) {
                    if (
                        data[i].item === obj.item &&
                        data[i].proj === obj.proj
                    ) {
                        data[i]['refUrl'] = route
                    }
                }
            }
        }

        this.setState(
            {
                data: data,
                isLoaded: true,
            },
            () => {
                loadItemImages({
                    data,
                    property: 'primaryImageId',
                    callback: (imageData) => {
                        this.setState({
                            imagesLoaded: true,
                            imageData,
                        })
                    },
                })
            }
        )
    }

    handleChangePage = async (page) => {
        this.fetchData(page)
        this.setState({
            page: page,
        })
    }

    handleFilter = (name) => (e) => {
        this.setState(
            {
                [name]: e,
                page: 1,
            },
            this.changePageHandler
        )
    }

    reloadDataAndSetFilter() {
        this.fs.setSearch('active_filters', this.state.propertyFilter)
        clearTimeout(timeout)
        timeout = delay(() => {
            this.loadData()
        }, 200)
    }

    handleReactSelectChange = (select, props) => {
        const { id, apifield } = props
        const propertyFilter = this.state.propertyFilter
        let fieldValues = this.state.fieldValues

        fieldValues[id] = select

        if (select && select.value) {
            propertyFilter[id] = select.value
        } else {
            delete propertyFilter[id]
        }

        this.setState(
            {
                propertyFilter,
                isLoaded: false,
                clear: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, select)
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleComboSelect = (selected, modtype, props) => {
        const { isNumber, apifield, id } = props
        const field = apifield
        let filters = this.state.propertyFilter
        let fieldValues = this.state.fieldValues

        fieldValues[id] = selected

        if (modtype == 'item-location') {
            filters[id] = `(contains(${field}, '${selected.join(
                `') or contains(${field}, '`
            )}'))`
        } else {
            if (selected == '') {
                delete filters[id]
            } else {
                filters[id] = isNumber
                    ? `(${field} in (${selected.join(',')}))`
                    : `(${field} in ('${selected.join("','")}'))`
            }
        }

        this.setState(
            {
                propertyFilter: filters,
                isLoaded: false,
                clear: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, selected)
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleDateRangeSelect = (dates, props) => {
        const { type, apifield, id } = props
        const field = apifield
        let filters = this.state.propertyFilter
        let dateValues = this.state.dateValues
        let fieldValues = this.state.fieldValues

        fieldValues[id] = dates

        if (dates == '' || dates == null) {
            delete filters[id]
            delete dateValues[type]
        } else {
            if (dates.length == undefined) {
                const dayStart = dayjs(dates)
                    .startOf('month')
                    .format('YYYY-MM-DD')
                const dayEnd = dayjs(dates).endOf('month').format('YYYY-MM-DD')
                dates = [dayStart, dayEnd]
            }

            filters[id] = `(${field} ge ${dayjs(dates[0]).format(
                'YYYY-MM-DD'
            )} and ${field} le ${dayjs(dates[1]).format('YYYY-MM-DD')})`

            dateValues[type] = dates
        }

        this.setState(
            {
                propertyFilter: filters,
                dateValues: dateValues,
                isLoaded: false,
                clear: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, dates)
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleChangeField = (value, props) => {
        const { isNumber, operator, apifield, id } = props
        const field = apifield
        let filters = this.state.propertyFilter
        let fieldValues = this.state.fieldValues

        fieldValues[id] = value

        if (value == '') {
            delete filters[id]
        } else {
            if (field.split('|').length > 1) {
                const fd = field.split('|')
                filters[id] = `(${fd
                    .map((f) => {
                        return isNumber !== true
                            ? operator
                                ? `${f} ${operator} '${encodeURI(value)}'`
                                : `contains(${f}, '${encodeURI(value)}')`
                            : `${f} ${operator ?? 'eq'} ${value}`
                    })
                    .join(' or ')})`
            } else {
                if (isNumber == true) {
                    filters[id] = `${field} ${operator ?? 'eq'} ${value}`
                } else if (operator) {
                    filters[id] = `${field} ${operator} '${encodeURI(value)}'`
                } else {
                    filters[id] = `contains(${field}, '${encodeURI(value)}')`
                }
            }
        }

        this.setState(
            {
                propertyFilter: filters,
                isLoaded: false,
                clear: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, value)
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleNumRangeChangeField = (value, pos, props) => {
        const { apifield, id } = props
        let filters = this.state.propertyFilter
        let fieldValues = this.state.fieldValues
        let ranges = filters[id] ?? {}

        if (value == '') {
            delete ranges[pos]
        } else {
            ranges[pos] = `${apifield} ${pos === 'from' ? 'ge' : 'le'} ${value}`
        }

        fieldValues[id] = fieldValues[id] ?? {}
        fieldValues[id][pos] = value

        filters[id] = ranges
        this.setState(
            {
                propertyFilter: filters,
                isLoaded: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, fieldValues[id])
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleSwitchChange = (e, props) => {
        const { apifield } = props
        const { id, checked } = e.target
        let filters = this.state.propertyFilter
        let fieldValues = this.state.fieldValues

        fieldValues[id] = checked

        switch (id) {
            case 'item-completed':
                filters[props.id] = `completed eq ${checked}`
                break
            case 'item-active':
                filters[props.id] = `(completed eq ${!checked} ${
                    checked ? 'and' : 'or'
                } inactive eq ${!checked})`
                break

            default:
                filters[props.id] = `${apifield} eq ${checked}`
                break
        }

        this.setState(
            {
                propertyFilter: filters,
                isLoaded: false,
                clear: false,
                fieldValues,
            },
            () => {
                this.fs.setSearch(id, checked)
                this.reloadDataAndSetFilter()
            }
        )
    }

    handleSelectMoreFilters = (selected) => {
        const activeFilters = this.state.additionalFilters

        const [removeFilter] =
            activeFilters?.filter((f) => selected.indexOf(f) < 0) ?? []

        this.reloadDataFilterRemove(removeFilter, selected)
        this.fs.setSearch('selected_filters', selected)
        this.fs.setSearch(removeFilter, null)
    }

    handleDetach = (filter) => {
        let selected = this.state.additionalFilters.filter(
            (item) => item !== filter
        )

        this.reloadDataFilterRemove(filter, selected)
        this.fs.setSearch('selected_filters', selected)
        this.fs.setSearch(filter, null)
    }

    reloadDataFilterRemove(filterId, selected) {
        const dfilter = this.state.propertyFilter[filterId]
        let fieldValues = this.state.fieldValues
        let filters = this.state.propertyFilter

        if (filterId) {
            delete filters[filterId]
            delete fieldValues[filterId]
        }

        this.setState(
            {
                propertyFilter: filters,
                isLoaded: dfilter?.length ? false : true,
                fieldValues,
            },
            () => {
                this.setState({
                    additionalFilters: selected,
                })
                if (dfilter?.length) {
                    this.reloadDataAndSetFilter()
                }
            }
        )
    }

    clearFilters() {
        this.setState(
            {
                additionalFilters: [],
                propertyFilter: {},
                clear: true,
                isLoaded: false,
                dateValues: {
                    entry: [],
                    keyedate: [],
                },
                fieldValues: {},
            },
            () => {
                this.fs.setSearches({})
                this.reloadDataAndSetFilter()
            }
        )
    }

    isFixed(isFixed) {
        return isFixed === true || isFixed === undefined
    }

    addFieldProps(field, i) {
        const { type, id } = field

        switch (type) {
            case 'combobox':
                field.handleChangeField = this.handleComboSelect
                break
            case 'switch':
                field.handleChangeField = this.handleSwitchChange
                break
            case 'date-range':
                field.handleChangeField = this.handleDateRangeSelect
                break
            case 'react-select':
                field.handleChangeField = this.handleReactSelectChange
                break
            case 'number-range':
                field.handleChangeField = this.handleNumRangeChangeField
                break
            default:
                field.handleChangeField = this.handleChangeField
                break
        }

        field.isClear = this.state.clear
        field.handleDetach = this.handleDetach
        field.zIndex = i
        field.defaultValue = this.state.fieldValues[id]

        return field
    }

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Content actions={true}>
                    <HeaderLight.Title>Item Search</HeaderLight.Title>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderFieldFilters(isFixed) {
        return this.state.ItemSearchFields.filter(
            (f) =>
                (isFixed && this.isFixed(f.isFixed)) ||
                (!isFixed &&
                    !this.isFixed(f.isFixed) &&
                    this.state.additionalFilters.indexOf(f.id) > -1)
        ).map((field, i) => {
            field = this.addFieldProps(field, isFixed ? i : i + 200)

            switch (field.type) {
                case 'text':
                case 'number':
                    return (
                        <MultiSearchComponentsText
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                case 'switch':
                    return (
                        <MultiSearchComponentsSwitch
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                case 'date-range':
                    return (
                        <MultiSearchComponentsDateRange
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                case 'number-range':
                    return (
                        <MultiSearchComponentsNumberRange
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                case 'combobox':
                    return (
                        <MultiSearchComponentsCombobox
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                case 'react-select':
                    return (
                        <MultiSearchComponentsReactSelect
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
                default:
                    return (
                        <MultiSearchComponents
                            {...field}
                            key={field.id}
                            index={field.id}
                        />
                    )
            }
        })
    }

    renderFilters() {
        return (
            <>
                <ListFilter className="project-list-filter" breakpoint={1030}>
                    <div className="d-flex flex-row justify-content-between">
                        <ListFilter.Fields
                            md={12}
                            lg={9}
                            xl={9}
                            className="width-30"
                        >
                            <ListFilter.Field className="flex-wrap w-100">
                                {/* Load fixed filters */}
                                {this.renderFieldFilters(true)}
                                {/* Load optional filters */}
                                {this.renderFieldFilters(false)}
                            </ListFilter.Field>
                        </ListFilter.Fields>
                        <ListFilter.Actions
                            md={12}
                            lg={3}
                            xl={3}
                            className="justify-content-center"
                        >
                            <ListFilter.Action className="">
                                <Button
                                    href="#"
                                    to="#"
                                    variant="white"
                                    size="sm"
                                    className="fw-bold btn-icon btn-action me-3 mb-2"
                                    onClick={this.clearFilters.bind(this)}
                                >
                                    Clear
                                </Button>
                                <ComboBox
                                    id="more-options-dropdown"
                                    title={'Add More Search Criteria '}
                                    label={'More +'}
                                    type={'more-options'}
                                    baseVariant="button"
                                    position="right"
                                    searchPlaceholder="Search"
                                    setSuffix={false}
                                    onSelect={this.handleSelectMoreFilters}
                                    options={this.state.filterOptions}
                                    selected={this.state.additionalFilters}
                                    className="me-2 mw-100px"
                                />
                            </ListFilter.Action>
                        </ListFilter.Actions>
                    </div>
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderPagination() {
        if (this.state.data.length) {
            return (
                <div className={'d-flex flex-wrap justify-content-center'}>
                    <div
                        className={
                            'd-flex flex-row align-items-center pagination'
                        }
                    >
                        <Button
                            variant="ivory"
                            size="sm"
                            className={'btn-icon pagination-btn'}
                            disabled={this.state.page === 1}
                            onClick={() =>
                                this.handleChangePage(this.state.page - 1)
                            }
                        >
                            <i className="ri-arrow-left-s-line"></i> Previous
                        </Button>
                        <span className={'pagination-span'}>
                            {this.state.page}
                        </span>
                        <Button
                            variant="ivory"
                            size="sm"
                            className={'btn-icon pagination-btn'}
                            disabled={
                                this.state.data.length <=
                                parseInt(this.state.pageSize.value)
                            }
                            onClick={() =>
                                this.handleChangePage(this.state.page + 1)
                            }
                        >
                            Next <i className="ri-arrow-right-s-line ms-1"></i>
                        </Button>
                    </div>
                    <Select
                        onChange={this.handleFilter('pageSize')}
                        key={`${Math.floor(Math.random() * 1000)}-min`}
                        options={this.availablePageSize}
                        defaultValue={this.state.pageSize}
                        className="react-select pagination-select mx-3"
                        placeholder="Please select"
                    />
                </div>
            )
        }
    }

    renderContent() {
        const { data, imagesLoaded } = this.state

        return (
            <Row>
                <Col sm="12">{this.renderFilters()}</Col>

                <div className="table-gradient sticky-container">
                    <Table striped responsive className="a-table">
                        <thead>
                            <tr className="a-table-heading">
                                {displaySortableTableHeader(
                                    this.state.tableHeaders,
                                    'item',
                                    'asc'
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.isLoaded
                                ? showLoading()
                                : data.length > 0
                                ? displaySortableTableData(
                                      this.state.tableHeaders,
                                      data.slice(0, this.state.pageSize.value),
                                      imagesLoaded
                                  )
                                : showEmpty()}
                        </tbody>
                    </Table>
                    {this.renderPagination()}
                </div>
            </Row>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="ff-transaction-search content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>
            </>
        )
    }
}

export default WithRouter(ItemSearchList)
