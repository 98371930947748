import React from 'react'
import SecureContent from '../../app/components/security/SecureContent'

export function renderSecureContent(Component: any) {
    return (attrNo: any, attrType: any) => {
        if (attrNo && attrType) {
            return (
                <SecureContent attributeNo={attrNo} attributeType={attrType}>
                    <SecureContent.HasAccess>
                        {Component}
                    </SecureContent.HasAccess>
                    <SecureContent.NoAccess>
                        <SecureContent.GenericNoAccess></SecureContent.GenericNoAccess>
                    </SecureContent.NoAccess>
                </SecureContent>
            )
        }

        return Component
    }
}

export function renderSecureHideContent(Component: any) {
    return (attrNo: any, attrType: any) => {
        if (attrNo && attrType) {
            return (
                <SecureContent attributeNo={attrNo} attributeType={attrType}>
                    <SecureContent.HasAccess>
                        {Component}
                    </SecureContent.HasAccess>
                </SecureContent>
            )
        }

        return Component
    }
}
