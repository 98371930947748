import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import { HeaderLight } from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import Select from 'react-select'
import { fakeReactSelectOptions } from '../../../factory/fakeFactory'
import { ApiService } from '../../../../lib/api/HttpService'
import DatePicker from 'react-datepicker'
import {
    displayAlertError,
    displayAlertLoader,
    getErrorMessage,
} from '../../../../utilities/Response'
import { getSaveButtonName } from '../../../../utilities/String'
import MSG from '../../../../defaults/Message'
import { currencyFormat } from '../../../../helpers/Number'

class ClientInvoiceExistingReverseCreditInvoice extends ThreadComponent {
    constructor(props) {
        super(props)

        // API
        this.api = new ApiService()
        this.state = {
            invoices: {},
            data: {
                invNum: this.props.params.invnum,
                reversalDt: new Date(),
                txDesc: '',
                hideInvs: false,
                reportFormatId: 0,
                fiscalMo: null,
            },
        }
    }

    async componentDidMount() {
        // Request Invoice Data
        const filter = `?$filter=invnum eq ${this.props.params.invnum}`
        const invoices = await this.api.getInvoices(filter)
        const company = await this.api.getCompany()
        const company2 = await this.api.getCompany2()
        if (invoices.length > 0) {
            let fiscalDate = new Date()
            if (company2.autodeffmo) {
                fiscalDate = new Date(
                    fiscalDate.setMonth(
                        fiscalDate.getMonth() + company.fiscaloffset
                    )
                )
            } else {
                fiscalDate = new Date(company2.closedarfmo)
                fiscalDate.setMonth(fiscalDate.getMonth() + 1)
            }

            this.setState({
                invoices: invoices[0],
                data: {
                    ...this.state.data,
                    fiscalMo: fiscalDate,
                },
            })
        }
    }

    componentInit() {
        this.setTitle('Reverse/Credit Invoice').setFormAction(true)
    }

    handleFiscalMonthChange = (date) => {
        this.setState({ data: { ...this.state.data, fiscalMo: date } })
    }

    handleDateChange = (date) => {
        this.setState({ data: { ...this.state.data, reversalDt: date } })
    }

    handleTransactionDescChange = (event) => {
        this.setState({
            data: { ...this.state.data, txDesc: event.target.value },
        })
    }

    handleHideOnReportsChange = (event) => {
        const { checked } = event.target
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                hideInvs: checked,
            },
        }))
    }

    handleSaveClick = async (e) => {
        e.preventDefault()
        try {
            if (!this.state.data.fiscalMo || !this.state.data.reversalDt) {
                displayAlertError('Please enter valid dates.')
                return
            }

            displayAlertLoader(MSG.loading.update.msg)
            this.setLoading(true)
            const data = {
                ...this.state.data,
                fiscalMo: this.getFormattedFiscalMonth(),
                reversalDt: this.getFormattedReversalDt(),
            }
            await this.api.addClientInvoiceCredit(data)
            window.location.href = getRouteWithParam(
                URI.accountsReceivable.clientInvoices.listExisting
            )
            this.setLoading(false)
        } catch (error) {
            this.setLoading(false)
            displayAlertError(getErrorMessage(error))
        }
    }

    getFormattedFiscalMonth() {
        let month = this.state.data.fiscalMo.getMonth() + 1
        month = month < 10 ? `0${month}` : month
        return `${month}/${new Date().getFullYear()}`
    }

    getFormattedReversalDt() {
        return this.state.data.reversalDt.toISOString()
    }

    setLoading = (isSaving) =>
        this.setState({
            isSaving,
        })

    renderHeader() {
        return (
            <HeaderLight>
                <HeaderLight.Breadcumbs>
                    <NavLink
                        to={getRouteWithParam(
                            URI.accountsReceivable.clientInvoices.list,
                            { page: 'existing' }
                        )}
                        className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
                    >
                        <i className="ri-arrow-left-s-line"></i> Back
                    </NavLink>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Accounts Receivable
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: URI.accountsReceivable.clientInvoices
                                    .listNew,
                            }}
                            linkAs={Link}
                        >
                            Client Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkProps={{
                                to: getRouteWithParam(
                                    URI.accountsReceivable.clientInvoices.list,
                                    { page: 'existing' }
                                ),
                            }}
                            linkAs={Link}
                        >
                            Existing Invoices
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Reverse/Credit Invoice
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </HeaderLight.Breadcumbs>
                <HeaderLight.Content actions={false}>
                    <HeaderLight.Title>
                        Reverse/Credit Invoice
                    </HeaderLight.Title>

                    {/* Submit Button */}
                    <Button onClick={this.handleSaveClick} variant="primary">
                        Save
                    </Button>
                </HeaderLight.Content>
            </HeaderLight>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2} className="mb-4">
                    <Col>
                        The highlighted{' '}
                        <strong>Invoice #{this.state.invoices.invnum}</strong>{' '}
                        for the amount of{' '}
                        <strong>
                            {currencyFormat(this.state.invoices.invoicePrice)}
                        </strong>{' '}
                        will be credited.
                    </Col>
                </Row>

                <Row xs={1} lg={2}>
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Fiscal Month
                                </Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <DatePicker
                                            selected={this.state.data.fiscalMo}
                                            onChange={
                                                this.handleFiscalMonthChange
                                            }
                                            className="form-control"
                                            placeholderText="Please select"
                                            showMonthYearPicker="true"
                                            dateFormat="MM/yyyy"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Date</Form.Label>
                            </Col>
                            <Col>
                                <Row lg={2}>
                                    <Col>
                                        <DatePicker
                                            selected={
                                                this.state.data.reversalDt
                                            }
                                            onChange={this.handleDateChange}
                                            className="form-control"
                                            placeholderText="Please select"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Transaction Desc.
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    onChange={this.handleTransactionDescChange}
                                    placeholder="Please enter"
                                />
                            </Col>
                        </Row>

                        {/* <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Reversed</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    value={this.state.invoices.hasreversal ? "Yes" : "No"}
                                    disabled={true}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Reverse of Invoice No.</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type="text" defaultValue={this.state.invoices.reverseofinvnum} disabled={true}/>
                            </Col>
                        </Row> */}

                        <Row className="align-items-start mb-3">
                            <Col
                                lg={3}
                                className="text-lg-end d-none d-lg-block"
                            ></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    label="Hide on Reports"
                                    name="check-group-hide-reports"
                                    type="checkbox"
                                    id={`check-group-hide-reports`}
                                    className="vtop"
                                    checked={this.state.data.hideInvs}
                                    onChange={this.handleHideOnReportsChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        const { isSaving } = this.state
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height mh-270">
                    <Container fluid className="ms-0">
                        {this.renderContent()}
                    </Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        onClick={this.handleSaveClick}
                        variant="primary"
                        size="lg"
                        disabled={isSaving}
                    >
                        {getSaveButtonName(isSaving, 'Save')}
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(ClientInvoiceExistingReverseCreditInvoice)
