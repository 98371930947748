import React from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import ThreadComponent from '../ThreadComponent'
import { WithRouter, getRouteWithParam } from '../../../helpers/Router'
import Switch from '../../components/Switch'
import ListFilter from '../../components/ListFilter'
import InputBar from '../../components/InputBar'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../../helpers/Number'

import { ApiService } from '../../../lib/api/HttpService'
import debounce from 'lodash/debounce'
import { Pagination } from '../../../app/components/pagination/Pagination'
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM'
import { formatDate, formatFilterDate } from '../../../helpers/Date'
import { DateRangePicker } from 'rsuite'
import {
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    getMonth,
    getYear,
    lastDayOfMonth,
} from 'date-fns'
import noItems from '../../../assets/images/icons/addpayment.svg'
import { isFiltered } from '../../../helpers/Util'
import { showEmpty, showLoading } from '../../../helpers/Loading'
import URI from '../../../defaults/RoutesDefault'
import { isEmpty } from 'lodash'
import FilterSort from '../../../utilities/modules/FilterSort'
import { encodeURI } from '../../../utilities/String'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security'
import SecureA from '../../../app/components/security/SecureA'

class ProjectViewInvoice extends ThreadComponent {
    constructor(props) {
        super(props)

        this.projectId = this.props.params.id
        this.fs = new FilterSort('project_invoice_list_' + this.projectId)
        this.fs.setDefaultSort('invdt asc')
        this.state = {
            checks: { 0: true },
            data: [],
            proposals: [],
            dataIsLoaded: false,
            available: this.fs.getFilter('available') ?? true,
            gross_sum: 0,
            depret_sum: 0,
            balance_sum: 0,
            payment_sum: 0,
            arbalance_sum: 0,
            pageSize: 20,
            page: 1,
            sortProperty: this.fs.getSort() ?? 'invdt desc',
            showTableSearch: this.fs.isSearchActive(),
            searchProperties: this.fs.getSearches(),
            project: {},
        }

        this.api = new ApiService()
        this.changePageHandler = debounce(
            this.handleChangePage.bind(this, 1),
            200
        )
    }

    componentInit() {
        this.setFormAction(true)
    }

    async componentDidMount() {
        window.setTimeout(() => {
            this.setState({ hideAlert: true })
        }, 5000)

        if (isEmpty(this.props.project?.proj)) {
            return
        }

        this.setState(
            {
                project: this.props.project,
                dataIsLoaded: false,
            },
            this.loadData
        )
    }

    async componentDidUpdate(previousProps, previousState) {
        let project = {}
        if (previousProps.project !== this.props.project) {
            project = this.props.project || {}

            this.setState(
                {
                    project,
                    dataIsLoaded: false,
                },
                this.loadData
            )
        }
    }

    async loadData() {
        await this.fetchItems()
        await this.fetchInvoiceTotal()
        this.enableSortTable()
    }

    async fetchInvoiceTotal() {
        const totals = await this.api.getProjectInvoiceTotal(
            this.state.project?.proj,
            this.state.available
        )

        this.setState({
            gross_sum: totals.grossInvoices,
            depret_sum: totals.depositRetainerApplied,
            balance_sum: totals.balance,
            payment_sum: totals.paymentsAdjustments,
            arbalance_sum: totals.ar,
            dataIsLoaded: true,
        })
    }

    async fetchItems(page, setLoading) {
        if (setLoading) this.setState({ dataIsLoaded: false })
        let data
        try {
            data = await this.api.get(
                'projectinvoices',
                `${this.state.available ? 'open' : ''}?${this.buildFilters(
                    page
                )}`
            )
            const proposals = await this.api.get(
                'proposals',
                `?&$filter=proj eq '${encodeURIComponent(
                    this.state.project?.proj
                )}'`
            )
            this.setState({
                proposals,
                dataIsLoaded: true,
            })

            this.renderData(data)
        } catch {
            data = []
        } finally {
            this.setState({
                dataIsLoaded: true,
            })

            this.renderData(data)
        }
    }

    handleSwitch = (e) => {
        this.setState(
            {
                available: e.target.checked,
                gross_sum: 0,
                depret_sum: 0,
                balance_sum: 0,
                payment_sum: 0,
                arbalance_sum: 0,
            },
            () => {
                this.handleChangePage(1)
                this.fetchInvoiceTotal()
            }
        )
        this.fs.setFilter('available', e.target.checked)
    }

    renderData(data) {
        this.setState({
            data: data,
        })
    }

    computeSum(arrayOfNum) {
        return arrayOfNum.reduce((a, b) => a + b)
    }

    handleShowTableSearch = (e) => {
        const filters = document.querySelectorAll(
            '.a-table-search-fields input'
        )
        if (filters) {
            filters.forEach((e) => {
                e.value = ''
            })
        }

        let newTableSearch = !this.state.showTableSearch
        this.fs.setIsActiveSearch(newTableSearch)
        this.setState({
            showTableSearch: newTableSearch,
        })

        if (JSON.stringify(this.state.searchProperties) !== '{}') {
            this.fs.setSearches({})
            this.setState(
                {
                    searchProperties: {},
                },
                this.changePageHandler
            )
        }
    }

    async handleDocument(e, id) {
        e.preventDefault()

        let document = await this.api.postJson('publicmappingbatches', {
            publicMappingObjects: [
                {
                    objectId: id,
                    objectType: 'Invoice',
                },
            ],
        })
        window.open(document.link, '_blank').focus()
    }

    enableSortTable() {
        const sort = document.querySelectorAll('.a-table-heading .sort')
        const self = this

        // Add change event
        if (sort) {
            sort.forEach((_e) => {
                _e.addEventListener(
                    'click',
                    function (e) {
                        sort.forEach((_e2) => {
                            if (_e !== _e2) {
                                removeDomClass('desc', _e2)
                                removeDomClass('asc', _e2)
                                removeDomClass('active', _e2)
                            }
                        })

                        addDomClass('active', _e)

                        if (hasClass('desc', _e)) {
                            removeDomClass('desc', _e)
                            addDomClass('asc', _e)
                        } else if (hasClass('asc', _e)) {
                            removeDomClass('asc', _e)
                            addDomClass('desc', _e)
                        } else {
                            addDomClass('desc', _e)
                        }

                        const sortProperty = `${
                            _e.attributes['data-field'].value
                        } ${hasClass('desc', _e) ? 'asc' : 'desc'}`

                        // Save sortProperty to localStorage
                        self.fs.setSort(sortProperty)

                        self.setState(
                            {
                                sortProperty,
                            },
                            self.changePageHandler
                        )
                    },
                    false
                )
            })
        }
    }

    async handleProposalClick(id) {
        const document = await this.api.postJson('publicmappingbatches', {
            publicMappingObjects: [
                {
                    objectId: id,
                    objectType: 'Proposal',
                },
            ],
        })
        window.open(document.link, '_blank').focus()
    }

    buildFilters(currentPage) {
        let filtersQuery = `&$filter=proj eq '${this.state.project?.proj}'`
        let filters = []

        Object.keys(this.state.searchProperties).forEach((key) => {
            const property = this.state.searchProperties[key]
            if (property.value || property.min || property.ma) {
                if (property.type === 'number') {
                    filters.push(
                        `(contains(cast(${key}, 'Edm.String'),'${encodeURI(
                            property.value
                        )}'))`
                    )
                } else if (property.type === 'date') {
                    if (property.min) filters.push(`${key} ge ${property.min}`)
                    if (property.max) filters.push(`${key} le ${property.max}`)
                } else {
                    filters.push(`contains(${key}, '${property.value}')`)
                }
            }
        })

        filtersQuery +=
            filters.length > 0 ? ` and ${filters.join(' and ')}` : ''

        let queryString = `$top=${this.state.pageSize + 1}&$skip=${
            ((currentPage ?? this.state.page) - 1) * this.state.pageSize
        }${filtersQuery}`

        if (this.state.sortProperty !== '') {
            queryString += `&$orderby=${this.state.sortProperty}`
        }

        return queryString
    }

    onPageSizeChanged = (size) => {
        this.setState(
            {
                pageSize: size,
                page: 1,
            },
            () => {
                this.handleChangePage(1)
            }
        )
    }

    onPageChanged = (page) => {
        this.handleChangePage(page)
    }

    handleChangePage = async (page) => {
        this.fetchItems(page, true)

        this.setState({
            page: page,
        })
    }

    handleSearch = (e) => {
        const key = e.target.attributes['data-field'].value
        const value = e.target.value
        const type = e.target.attributes['data-type']
            ? e.target.attributes['data-type'].value
            : 'string'

        this.setState(
            {
                searchProperties: {
                    ...this.state.searchProperties,
                    [key]: { value: value, type: type },
                },
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    handleDateChange = (key, e) => {
        let tmp = this.state.searchProperties
        if (e !== null) {
            tmp[key] = {
                min: formatFilterDate(e[0]),
                max: formatFilterDate(e[1]),
                type: 'date',
            }
        } else {
            delete tmp[key]
        }
        this.setState(
            {
                searchProperties: tmp,
                dataIsLoaded: false,
            },
            () => {
                this.fs.setSearches(this.state.searchProperties)
                this.changePageHandler()
            }
        )
    }

    sortClass(name) {
        return `sort ${this.fs.isActiveSort(name)}`
    }

    isDisplaySearch() {
        return (
            (this.state.data && this.state.data.length > 0) ||
            isFiltered(this.state.searchProperties) ||
            !this.state.dataIsLoaded
        )
    }

    renderListFilter() {
        return (
            <>
                <ListFilter expandable={0}>
                    <ListFilter.Fields sm={12} md={9}>
                        <ListFilter.Field className="w-100">
                            <Form.Label className="text-end">
                                <strong>Show Open/Available Only</strong>
                            </Form.Label>
                            <span>
                                <Switch
                                    onChange={this.handleSwitch}
                                    checked={this.state.available}
                                ></Switch>
                            </span>
                        </ListFilter.Field>
                    </ListFilter.Fields>
                    {this.isDisplaySearch() && (
                        <ListFilter.Actions
                            sm={12}
                            md={3}
                            replaceclassmatch="justify-content-xl-end"
                            replaceclassmatchwith="justify-content-lg-end"
                        >
                            <ListFilter.Action>
                                <Button
                                    as={Link}
                                    to="#"
                                    variant="ivory"
                                    size="sm"
                                    className={`btn-icon btn-action fw-bold ${
                                        this.state.showTableSearch
                                            ? 'bg-primary-ash text-white'
                                            : ''
                                    }`}
                                    onClick={this.handleShowTableSearch}
                                >
                                    <i className="ri-search-line"></i> Search
                                </Button>
                            </ListFilter.Action>
                        </ListFilter.Actions>
                    )}
                </ListFilter>
                <ListFilter.Spacer />
            </>
        )
    }

    renderInputBar() {
        return (
            <InputBar className="large">
                <InputBar.Links className="full-width">
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Gross Invoices
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                readOnly
                                disabled
                                type="text"
                                size="sm"
                            />
                            <span>{currencyFormat(this.state.gross_sum)}</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Dep./Ret. Applied
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                readOnly
                                disabled
                                type="text"
                                size="sm"
                            />
                            <span>{currencyFormat(this.state.depret_sum)}</span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            Balance
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                readOnly
                                disabled
                                type="text"
                                size="sm"
                            />
                            <span>
                                {currencyFormat(this.state.balance_sum)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            PMTS./ Adj.
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                readOnly
                                disabled
                                type="text"
                                size="sm"
                            />
                            <span>
                                {currencyFormat(this.state.payment_sum)}
                            </span>
                        </div>
                    </InputBar.Link>
                    <InputBar.Link>
                        <Form.Label htmlFor="inputPassword5" className="ilabel">
                            A/R
                        </Form.Label>
                        <div className="form-group-extra reversed">
                            <Form.Control
                                readOnly
                                disabled
                                type="text"
                                size="sm"
                            />
                            <span>
                                {currencyFormat(this.state.arbalance_sum)}
                            </span>
                        </div>
                    </InputBar.Link>
                </InputBar.Links>
            </InputBar>
        )
    }

    renderContent() {
        return (
            <>
                <>
                    <div className="table-gradient sticky-container">
                        <Table striped responsive className={`a-table`}>
                            <thead>
                                <tr key="0" className="a-table-heading">
                                    <th>
                                        <span
                                            className={this.sortClass('invdt')}
                                            data-field="invdt"
                                        >
                                            Date
                                        </span>
                                    </th>
                                    <th>
                                        <span data-field="document">
                                            Document
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass('invnum')}
                                            data-field="invnum"
                                        >
                                            Inv. #
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'propnum'
                                            )}
                                            data-field="propnum"
                                        >
                                            Prop. #
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass('gross')}
                                            data-field="gross"
                                        >
                                            Gross
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'totaldepositapplied'
                                            )}
                                            data-field="totaldepositapplied"
                                        >
                                            Dep./Ret.
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'invoiceprice'
                                            )}
                                            data-field="invoiceprice"
                                        >
                                            Balance
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'totalpayment'
                                            )}
                                            data-field="totalpayment"
                                        >
                                            Payment
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'lastclientchecknumber'
                                            )}
                                            data-field="lastclientchecknumber"
                                        >
                                            CK.#
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'amountdue'
                                            )}
                                            data-field="amountdue"
                                        >
                                            A/R Balance
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass(
                                                'previnfo'
                                            )}
                                            data-field="previnfo"
                                        >
                                            Reverse/Credit
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            className={this.sortClass('txdesc')}
                                            data-field="txdesc"
                                        >
                                            Transaction
                                            <br />
                                            Description
                                        </span>
                                    </th>
                                </tr>
                                <tr
                                    className={`a-table-search-fields ${
                                        this.state.showTableSearch
                                            ? ''
                                            : 'd-none'
                                    }`}
                                >
                                    <th>
                                        <DateRangePicker
                                            style={{
                                                minWidth: '200px',
                                            }}
                                            placement="auto"
                                            placeholder="Select date"
                                            format="MM/dd/yyyy"
                                            defaultValue={this.fs.getValue(
                                                'invdt'
                                            )}
                                            onChange={this.handleDateChange.bind(
                                                this,
                                                'invdt'
                                            )}
                                            onClean={this.handleDateChange.bind(
                                                this,
                                                'invdt'
                                            )}
                                            ranges={[
                                                {
                                                    label: 'today',
                                                    value: [
                                                        startOfDay(new Date()),
                                                        endOfDay(new Date()),
                                                    ],
                                                },
                                                {
                                                    label: 'yesterday',
                                                    value: [
                                                        startOfDay(
                                                            addDays(
                                                                new Date(),
                                                                -1
                                                            )
                                                        ),
                                                        endOfDay(
                                                            addDays(
                                                                new Date(),
                                                                -1
                                                            )
                                                        ),
                                                    ],
                                                },
                                                {
                                                    label: 'last7Days',
                                                    value: [
                                                        startOfDay(
                                                            subDays(
                                                                new Date(),
                                                                6
                                                            )
                                                        ),
                                                        endOfDay(new Date()),
                                                    ],
                                                },
                                                {
                                                    label: 'Last 30 Days',
                                                    value: [
                                                        startOfDay(
                                                            subDays(
                                                                new Date(),
                                                                30
                                                            )
                                                        ),
                                                        endOfDay(new Date()),
                                                    ],
                                                },
                                                {
                                                    label: 'This month',
                                                    value: [
                                                        startOfDay(
                                                            new Date(
                                                                getYear(
                                                                    new Date()
                                                                ),
                                                                getMonth(
                                                                    new Date()
                                                                ),
                                                                1
                                                            )
                                                        ),
                                                        endOfDay(
                                                            lastDayOfMonth(
                                                                new Date()
                                                            )
                                                        ),
                                                    ],
                                                },
                                                {
                                                    label: 'Last month',
                                                    value: [
                                                        startOfDay(
                                                            new Date(
                                                                getYear(
                                                                    new Date()
                                                                ),
                                                                getMonth(
                                                                    new Date()
                                                                ) - 1,
                                                                1
                                                            )
                                                        ),
                                                        endOfDay(
                                                            lastDayOfMonth(
                                                                new Date(
                                                                    getYear(
                                                                        new Date()
                                                                    ),
                                                                    getMonth(
                                                                        new Date()
                                                                    ) - 1,
                                                                    1
                                                                )
                                                            )
                                                        ),
                                                    ],
                                                },
                                            ]}
                                        />
                                    </th>
                                    <th></th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="invnum"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'invnum'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="propnum"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'propnum'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="gross"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'gross'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="totaldepositapplied"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'totaldepositapplied'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="invoiceprice"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'invoiceprice'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="totalpayment"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'totalpayment'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="lastclientchecknumber"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'lastclientchecknumber'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="amountdue"
                                            data-type="number"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'amountdue'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="previnfo"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'previnfo'
                                            )}
                                        />
                                    </th>
                                    <th>
                                        <Form.Control
                                            type="text"
                                            data-field="txdesc"
                                            onChange={this.handleSearch}
                                            defaultValue={this.fs.getValue(
                                                'txdesc'
                                            )}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!this.state.dataIsLoaded
                                    ? showLoading()
                                    : this.state.data.length === 0 &&
                                      isFiltered(this.state.searchProperties)
                                    ? showEmpty()
                                    : this.state.data
                                          .slice(0, this.state.pageSize)
                                          .map((item, i) => (
                                              <tr
                                                  key={i}
                                                  className={
                                                      this.state.checks[i]
                                                          ? `active`
                                                          : ''
                                                  }
                                              >
                                                  <td>
                                                      {formatDate(item.invdt)}
                                                  </td>
                                                  <td>
                                                      <SecureA
                                                          href=""
                                                          attributeNo={52}
                                                          attributeType={
                                                              SECURITY_ATTRIBUTE_TYPES.DenyAccess
                                                          }
                                                          onClick={(e) => {
                                                              this.handleDocument(
                                                                  e,
                                                                  item.id
                                                              )
                                                          }}
                                                      >
                                                          PDF
                                                      </SecureA>
                                                  </td>
                                                  <td>
                                                      <Link
                                                          to={getRouteWithParam(
                                                              URI
                                                                  .accountsReceivable
                                                                  .clientInvoices
                                                                  .existingInvoiceView,
                                                              {
                                                                  invnum: item.invnum,
                                                              }
                                                          )}
                                                          className="text-charcoal hover-view-icon"
                                                      >
                                                          {item.invnum}
                                                      </Link>
                                                  </td>
                                                  <td>
                                                      <Link
                                                          onClick={(e) => {
                                                              e.preventDefault()
                                                              const proposal =
                                                                  this.state.proposals.find(
                                                                      (prop) =>
                                                                          prop.propnum ===
                                                                          item.propnum
                                                                  )

                                                              if (proposal) {
                                                                  this.handleProposalClick(
                                                                      proposal.id
                                                                  )
                                                              }
                                                          }}
                                                          className="text-charcoal hover-view-icon"
                                                      >
                                                          {item.propnum}
                                                      </Link>
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.gross,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.totalDepositApplied,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.invoicePrice,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.totalPayment,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>
                                                      {
                                                          item.lastClientCheckNumber
                                                      }
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.amountDue,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>
                                                      {currencyFormat(
                                                          item.prevInfo,
                                                          '$'
                                                      )}
                                                  </td>
                                                  <td>{item.txdesc}</td>
                                              </tr>
                                          ))}
                            </tbody>
                        </Table>
                    </div>

                    {this.state.data.length > 0 && (
                        <Pagination
                            onPageSizeChanged={this.onPageSizeChanged}
                            onPageChanged={this.onPageChanged}
                            hasPreviousPage={this.state.page > 1}
                            hasNextPage={
                                this.state.data.length > this.state.pageSize
                            }
                            page={this.state.page}
                            pageSize={this.state.pageSize}
                        />
                    )}
                </>
                {!isFiltered(this.state.searchProperties) &&
                    !this.state.data.length &&
                    this.state.dataIsLoaded && (
                        <div className="row justify-content-center text-center py-5">
                            <div className="col-md-3">
                                <img
                                    src={noItems}
                                    className="mw-100 mb-4"
                                    alt=""
                                />

                                <h6>View Client Invoices</h6>
                                <p>
                                    This is where you can view the status of
                                    your project invoices for client billing. To
                                    create an invoice, go to{' '}
                                    <Link
                                        to={
                                            URI.accountsReceivable
                                                .clientInvoices.listNew
                                        }
                                        className="text-black"
                                    >
                                        Accounts Receivable - Client Invoices
                                    </Link>
                                </p>
                            </div>
                        </div>
                    )}
            </>
        )
    }

    render() {
        return (
            <>
                <div>
                    {/* Filter */}
                    {this.renderListFilter()}

                    {/* Content */}
                    {this.renderContent()}
                </div>

                <div className="mt-5">{this.renderInputBar()}</div>
            </>
        )
    }
}

export default WithRouter(ProjectViewInvoice)
