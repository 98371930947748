import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ApiService } from '../HttpService'
import { Item } from '../types/Item'

const api = new ApiService()

const useEditItem = (
    options?: UseMutationOptions<
        Item,
        Partial<Item> & { id: number },
        Partial<Item> & { id: number },
        unknown
    >
) => {
    return useMutation({
        mutationFn: (editedItem: Partial<Item> & { id: number }) => {
            const newlyEditedItem = api.editProjectItem(
                editedItem.id,
                editedItem
            ) as Promise<Item>
            return newlyEditedItem
        },
        ...(options || {}),
    })
}
export default useEditItem
