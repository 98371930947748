import React from 'react'
import { Button } from 'react-bootstrap'
import { getClassNames, removeDomClass } from '../../helpers/DOM'
import { Link } from 'react-router-dom'
import { routeParam, WithRouter } from '../../helpers/Router'
import URI from '../../defaults/RoutesDefault'
import { isEmpty } from 'lodash'

class TreeView extends React.Component {
    constructor(props) {
        super(props)
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = (e) => {
        // Toggle active node class.
        e.target.parentNode.classList.toggle('active-node')
    }

    handleClickOnEmpty = (e, item) => {
        const btns = document.querySelectorAll('.a-tree-parent .active-btn')
        if (btns) {
            btns.forEach((btn) => {
                removeDomClass('active-btn', btn)
            })
        }
        // Toggle active node class.
        e.target.classList.toggle('active-btn')

        if (item.isItem) {
            const icon = e.target.querySelector('span.ri-server-fill')
            icon.classList.remove('ri-server-fill', 'me-1')
            icon.classList.add(
                'spinner-border',
                'spinner-border-sm',
                'alert-icon',
                'me-1'
            )
        }

        if (item.onClick) {
            item.onClick(e.target, item.isItem)
            e.target.parentNode.parentNode.childNodes.forEach((node) => {
                node.classList.remove('active-node')
            })
            e.target.parentNode.classList.toggle('active-node')
        }
    }

    renderIcon(icon) {
        if (icon) return <span className={`${icon || ''} me-1`}></span>

        return ''
    }

    render() {
        const { data } = this.props
        const { isroot } = this.props
        const { variant } = this.props
        const { btnemptyclass } = this.props

        if (!data) return null

        return (
            <ul
                {...this.props}
                data={''}
                className={getClassNames(
                    this.props,
                    `${
                        isroot || isroot !== 0 ? 'a-tree-parent' : 'a-tree-sub'
                    } ${variant || ''}`
                )}
            >
                {data.map((item, i) => {
                    let title = item.name
                    if (
                        !isEmpty(item.children) &&
                        item.children[0]?.locationName
                    ) {
                        title += ` ${item.children[0].locationName}`
                    }

                    return (
                        <li
                            key={i}
                            className={item.isActive ? 'active-node ' : ''}
                        >
                            {(() => {
                                if (
                                    (item.children && item.children.length) >
                                        0 ||
                                    item.reports
                                ) {
                                    // If has children
                                    return (
                                        <>
                                            <Button
                                                variant="link"
                                                onClick={this.handleClick}
                                                className="wrap-after"
                                            >
                                                <i></i>{' '}
                                                {this.renderIcon(item.icon)}{' '}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: title,
                                                    }}
                                                ></span>
                                            </Button>
                                            <Link
                                                to={routeParam(
                                                    URI.project.itemEdit,
                                                    {
                                                        id: item.project,
                                                        itemId: item.id,
                                                    }
                                                )}
                                                className="text-charcoal editLink"
                                            >
                                                Edit{' '}
                                                <i className="ri-edit-fill"></i>
                                            </Link>
                                        </>
                                    )
                                }

                                return (
                                    <>
                                        <Button
                                            variant="link"
                                            className={`empty-node wrap-after ${
                                                btnemptyclass
                                                    ? btnemptyclass
                                                    : ''
                                            }`}
                                            onClick={(e) => {
                                                this.handleClickOnEmpty(e, item)
                                            }}
                                        >
                                            <i></i> {this.renderIcon(item.icon)}{' '}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: item.name,
                                                }}
                                            ></span>
                                        </Button>
                                        {item.component ? (
                                            <Link
                                                to={routeParam(
                                                    URI.project.item
                                                        .componentEdit,
                                                    {
                                                        id: item.project,
                                                        itemId: item.itemId,
                                                        compId: item.compId,
                                                    }
                                                )}
                                                className="text-charcoal editLink"
                                            >
                                                Edit{' '}
                                                <i className="ri-edit-fill"></i>
                                            </Link>
                                        ) : (
                                            <Link
                                                to={routeParam(
                                                    URI.project.itemEdit,
                                                    {
                                                        id: item.project,
                                                        itemId: item.id,
                                                    }
                                                )}
                                                className="text-charcoal editLink"
                                            >
                                                Edit{' '}
                                                <i className="ri-edit-fill"></i>
                                            </Link>
                                        )}
                                    </>
                                )
                            })()}
                            <TreeView
                                data={item.children}
                                isroot={0}
                                btnemptyclass={btnemptyclass}
                            />
                            <TreeView
                                data={item.reports}
                                isroot={0}
                                btnemptyclass={btnemptyclass}
                            />
                        </li>
                    )
                })}
            </ul>
        )
    }
}

export default TreeView
