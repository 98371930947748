import React from 'react'
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown'
import { CompNumericPercentType } from 'lib/api/types/CompPercentType'

const CompPercentTypeDropdown = (props: TBaseDropdownProps) => {
    const options = [
        {
            label: 'Markup',
            value: CompNumericPercentType.Markup,
        },
        {
            label: 'Fee',
            value: CompNumericPercentType.Fee,
        },
        {
            label: 'Discount',
            value: CompNumericPercentType.Discount,
        },
    ]
    return <BaseDropdown {...props} options={options} />
}

CompPercentTypeDropdown.displayName = 'CompPercentTypeDropdown'

export default CompPercentTypeDropdown
