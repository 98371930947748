import React from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'

interface Props {
    message: string
    show: boolean
    size: any
    placeholder: string
    onCancel: () => {}
    onConfirm: (input: string) => {}
}

interface State {
    input: string
}

export class InputModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            input: '',
        }
    }

    render() {
        return (
            <Modal
                size={this.props.size ? this.props.size : 'sm'}
                show={this.props.show}
                aria-labelledby="action-modal"
                className="a-modal mx-sm-5 mx-lg-0"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Container>
                        <Col>
                            <Row className="px-sm-0 px-lg-1">
                                <Col>
                                    <Row className="text-center mx-auto fw-bold">
                                        <p>{this.props.message}</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                    <Row className="text-center">
                                        <Form.Control
                                            type="text"
                                            placeholder={this.props.placeholder}
                                            onChange={(e) => {
                                                this.setState({
                                                    input: e.target.value,
                                                })
                                            }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="bg-ivory py-4">
                    <Col>
                        <Row className="mb-3">
                            <Col>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        this.props.onConfirm(this.state.input)
                                    }}
                                    className="w-100"
                                >
                                    OK
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="trans-light border-secondary-ash"
                                    onClick={this.props.onCancel}
                                    className="w-100"
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Footer>
            </Modal>
        )
    }
}
