import { Helmet } from 'react-helmet'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { displayAlert } from 'utilities/Response'
import { useRunReport } from 'hooks'

import {
    getCreditCardReconciliations,
    voidCreditCardReconciliation,
} from '../api'
import ConfirmModal from '../ConfirmModal'
import ConfirmationMessage from './ConfirmationMessage'
import Grid from './Grid'
import Header from './Header'
import SecureContent from 'app/components/security/SecureContent'
import { SECURITY_ATTRIBUTE_TYPES, useHasAccess } from 'app/context/security'
import { useAccounts } from '../hooks'

export default function ReconcileHistory() {
    const [isConfirmationShowing, setIsConfirmationShowing] = useState(false)
    const [voidItem, setVoidItem] = useState(null)

    const runReport = useRunReport()
    const queryClient = useQueryClient()
    const hasAccess = useHasAccess(49, SECURITY_ATTRIBUTE_TYPES.DenyAccess)

    const accountsQuery = useAccounts()

    const reconciliations = useQuery({
        enabled: hasAccess,
        queryKey: ['accounts-payable', 'credit-card', 'reconcile', 'history'],
        queryFn: getCreditCardReconciliations,
    })

    const voidMutation = useMutation(voidCreditCardReconciliation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['accounts-payable', 'credit-card'])
        },
        onError: (error) => {
            const message =
                error?.response?.data?.userError ||
                'Only the most recent reconciliation can be deleted.'
            displayAlert('danger', message)
        },
    })

    const onConfirmationCancel = () => {
        setIsConfirmationShowing(false)
    }

    const onConfirmationConfirm = () => {
        const { ccaccount, statementdate } = voidItem

        voidMutation.mutate({
            CreditCardAccount: ccaccount,
            StatementDate: statementdate,
        })
        setIsConfirmationShowing(false)
    }

    const onDownload = ({ ccaccount, statementdate }) => {
        runReport({
            label: 'Credit Card Reconciliation',
            reportId: 1176,
            reportFormatId: 1352,
            runReportParameters: [
                {
                    parameterId: 735,
                    value: `${ccaccount},${statementdate}`,
                },
            ],
        })
    }

    const onVoid = ({ ccaccount, statementdate }) => {
        setVoidItem({ ccaccount, statementdate })
        setIsConfirmationShowing(true)
    }

    return (
        <>
            <Helmet>
                <title>Reconcile Credit Card History | Design Manager</title>
            </Helmet>
            <Header />
            <SecureContent
                attributeNo={61}
                attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
            >
                <SecureContent.NoAccess>
                    <div className="content-padding min-height has-action-bar mt-2">
                        <SecureContent.GenericNoAccess />
                    </div>
                </SecureContent.NoAccess>
                <SecureContent.HasAccess>
                    {accountsQuery.data && (
                        <div className="content-padding min-height has-action-bar mt-2">
                            <Grid
                                accountsQuery={accountsQuery}
                                onDownload={onDownload}
                                onVoid={onVoid}
                                query={reconciliations}
                            />
                        </div>
                    )}
                    <ConfirmModal
                        isShowing={isConfirmationShowing}
                        onCancel={onConfirmationCancel}
                        onConfirm={onConfirmationConfirm}
                    >
                        <ConfirmationMessage />
                    </ConfirmModal>
                </SecureContent.HasAccess>
            </SecureContent>
        </>
    )
}
