import React from 'react'
import { DocumentsAssets } from './Assets'

const defaultIcon = DocumentsAssets.Image.unknown

const DefaultFiletypeIcon = ({ filetype }) => {
    return (
        <div className="documents-icon">
            <img className="w-100" src={defaultIcon} alt="" />
            <strong className="text-white position-absolute">
                {filetype ? '.' : ''}
                {filetype?.toUpperCase()}
            </strong>
        </div>
    )
}

export const FiletypeIcon = ({ filetype, thumbnail }) => {
    var icon = defaultIcon
    switch (filetype) {
        case 'xls':
        case 'xlsx':
            icon = DocumentsAssets.Image.excel
            break
        case 'doc':
        case 'docx':
            icon = DocumentsAssets.Image.word
            break
        case 'pdf':
            icon = DocumentsAssets.Image.pdf
            break
        case 'url':
            icon = DocumentsAssets.Image.url
            break
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
        case 'webp':
            return thumbnail ? (
                <img src={thumbnail} alt="" />
            ) : (
                <DefaultFiletypeIcon filetype={filetype} />
            )
        default:
            return <DefaultFiletypeIcon filetype={filetype} />
    }
    return <img className="documents-icon" src={icon} alt="" />
}
