import { createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { API_AUTH_ERROR_MESSAGES, ApiService } from '../../lib/api/HttpService'
import { clearUserData, getCookie } from '../../utilities/Auth'
import URI from 'defaults/RoutesDefault'

const api = new ApiService()

const SecurityContext = createContext()

export const PRO_CLOUD_LOGIN_PAGE = 'https://login.designmanager.com'

export const SECURITY_ATTRIBUTE_TYPES = {
    DenyAccess: 1,
    DenyAdd: 2,
    DenyEdit: 4,
    DenyDel: 8,
    DenySpec1: 16,
    DenySpec2: 32,
    DenySpec3: 64,
    DenySpec4: 128,
    DenySpec5: 256,
    DenySpec6: 1024,
    DenySpec7: 2048,
    DenySpec8: 4096,
    DenySpec9: 8192,
    DenySpec10: 16384,
}

export const useUserQuery = (queryKey) => {
    const navigate = useNavigate()

    const userCode = getCookie('dmUsercode')

    const getUserData = async () =>
        await api.getUserData(`?$filter=usercode eq ${userCode}`)

    const userDataQuery = useQuery({
        queryKey,
        queryFn: () => getUserData(),
        retry: (_, error) => {
            return !isErrorInStatus(error?.response?.status)
        },
        refetchOnWindowFocus: (query) => {
            return !isErrorInStatus(query.state?.error?.response?.status)
        },
        onError: (err) => {
            if (err?.response?.status === 401) {
                clearUserData()
                navigate('/')
            } else if (
                err?.response?.data?.UserError ===
                API_AUTH_ERROR_MESSAGES.INVALID_INSTANCE
            ) {
                navigate(URI.invalidInstance)
            }
        },
    })
    return userDataQuery
}

export const isErrorInStatus = (errorCode) => {
    const errorStatus = [401, 403]
    return errorStatus.includes(errorCode)
}

function SecurityProvider({ children }) {
    const userDataQuery = useUserQuery(['SecurityProvider', 'userInfo'])

    if (userDataQuery.isLoading) return null

    const { data } = userDataQuery
    const userData = data?.[0]
    const value = {
        admin: userData?.admin,
        attributes: userData?.attributes,
        authorizationTemplateType: userData?.authorizationTemplateType,
        disabled: userData?.disabled,
    }

    return (
        <SecurityContext.Provider value={value}>
            {children}
        </SecurityContext.Provider>
    )
}

export function useSecurity() {
    const context = useContext(SecurityContext)
    if (context === undefined) {
        throw new Error('useSecurity must be used within a SecurityProvider')
    }
    return context
}

function AuthorizationDenyAccessForAttributes(
    attributes,
    attributeNumber,
    flag
) {
    if (!attributes || attributes.trim() === '')
        // if empty deny all
        return false

    var list = []
    for (var i = 0; i < attributes.length; i += 5) {
        list.push(attributes.substring(i, i + 5))
    }

    var attributeFlags = parseInt(list[attributeNumber - 1])
    return (attributeFlags & flag) === flag
}

export function useHasAccess(attributeNo, AttributeType) {
    const { attributes } = useSecurity()

    return !AuthorizationDenyAccessForAttributes(
        attributes,
        attributeNo,
        AttributeType
    )
}

export function useCurrentRole() {
    const { authorizationTemplateType } = useSecurity()
    // there is a bug in the api where it returns 0 instead of null
    if (authorizationTemplateType === 0) return null

    return authorizationTemplateType
}

export default SecurityProvider
