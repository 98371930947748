import { useEffect, useState } from 'react'

function MiniZoomer(props) {
    const [size, setSize] = useState(props.size || 1)
    const callback = props.changeZoom || null
    const isDisabled = props.disabled || null

    function _addSize() {
        if (size < 2) {
            setSize(size + 1)
        }
    }

    function _subSize() {
        if (size > 0) {
            setSize(size - 1)
        }
    }

    useEffect(() => {
        if (callback) {
            callback(size)
        }
    }, [size, callback])

    return (
        <span
            className={`a-mini-zoomer zoom-${size} ${
                isDisabled ? 'zoom-disabled' : ''
            }`}
        >
            <span
                className="ri-zoom-out-fill"
                onClick={isDisabled ? null : _subSize}
            ></span>
            <span className="zoom-slide">
                <span></span>
            </span>
            <span
                className="ri-zoom-in-fill"
                onClick={isDisabled ? null : _addSize}
            ></span>
        </span>
    )
}

export default MiniZoomer
