import React, { memo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiService } from 'lib/api/HttpService'
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown'
import { Company } from 'lib/api/types/Company'
import getCompanyTypeMap from 'utilities/getComponentTypeMap'

const api = new ApiService()

const getCompTypes = async () => {
    const company = (await api.getCompany()) as Company
    return Array.from(Array(5).keys()).map((type) => ({
        label: getCompanyTypeMap(company)[type],
        value: type,
    }))
}

const arePropsEqual = (
    prevProps: TBaseDropdownProps,
    nextProps: TBaseDropdownProps
) => {
    return (
        prevProps.blurInputOnSelect === nextProps.blurInputOnSelect &&
        prevProps.isDisabled === nextProps.isDisabled &&
        JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)
    )
}

const CompTypeDropdown = memo((props: TBaseDropdownProps) => {
    const { data, isLoading } = useQuery({
        queryKey: ['dropdowns', 'compTypes'],
        queryFn: getCompTypes,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    return <BaseDropdown {...props} isLoading={isLoading} options={data} />
}, arePropsEqual)

CompTypeDropdown.displayName = 'CompTypeDropdown'

export default CompTypeDropdown
