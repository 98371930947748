import * as React from 'react'
import AppNavigation from './app/AppNavigation'
import { Event, initDOMEvents, toggleDMPageLoader } from './utilities/DOM'
import { isDraft, promptDraft } from './utilities/ModuleHelper'
import { setDraft } from './utilities/FormEvent'
import { delay } from 'lodash'
import { AppWrapper } from 'AppWrapper'

interface State {
    assetsLoaded: boolean
}
interface Props {}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            assetsLoaded: false,
        }
    }

    componentDidMount() {
        this.setState({ assetsLoaded: true })

        window.onpopstate = (e) => {
            this.onPopStateChange(e)
        }
    }

    componentDidUpdate() {
        initDOMEvents()
    }

    onPopStateChange = (e: any) => {
        // Let's remove some loaders.
        toggleDMPageLoader({ destroy: true })

        if (isDraft()) {
            setDraft(false)
            if (!confirm('Leaving this screen will discard your changes.')) {
                history.go(1)

                // Let's remove some loaders.
                delay(() => toggleDMPageLoader({ destroy: true }), 70)
            }
        }
    }

    render() {
        if (this.state.assetsLoaded) {
            return (
                <AppWrapper>
                    <AppNavigation />
                </AppWrapper>
            )
        } else {
            return <></>
        }
    }
}

export default App
