import React from 'react'
import ImagesFanFrame from '../ImagesFanFrame/ImagesFanFrame'
import { formatDate } from 'utilities/formatDate'
import { formatCurrency } from 'utilities/formatCurrency'
import Icon from '../Icon/Icon'
import CreateItemIcon from '../../../assets/images/icons/create_item_icon.svg'
import CreateTimeEntryIcon from '../../../assets/images/icons/Create_Time_Entry-Icon.svg'
import { updateTimeEntryFilter } from 'utilities/updateTimeEntryFilter'
import { useNavigate } from 'react-router-dom'

interface IProjectCardProps {
    date: string
    budget: number
    projectName: string
    clientName: string
    amountReceived: number
    totalAmount: number
    primaryImages?: []
    projectId?: number
    projectCode: string
}

const RecentProjectCard = ({
    date,
    budget,
    projectName,
    clientName,
    amountReceived,
    totalAmount,
    primaryImages = [],
    projectId,
    projectCode,
}: IProjectCardProps) => {
    const navigate = useNavigate()
    const receivedPercentage = (amountReceived / totalAmount) * 100

    const handleButtonClick = (type: string) => {
        if (type === 'timeEntry' && projectId) {
            updateTimeEntryFilter(projectName, projectCode, projectId)
            navigate('/time-entry')
        } else {
            navigate(`/projects/${projectId}/item/add`)
        }
    }

    return (
        <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-[200px] tw-min-w-[200px] md:tw-min-w-[25%] md:tw-w-[25%] tw-mr-4 tw-h-[400px] tw-overflow-hidden">
            <div className="tw-flex tw-justify-center tw-overflow-hidden tw-h-[140px]">
                {projectId && (
                    <div className="tw-absolute tw-z-10	tw-top-[10px] tw-right-[4px]">
                        <Icon
                            path={CreateItemIcon}
                            alt="creaate-item-icon"
                            iconClass="tw-mr-[4px] tw-rounded-full tw-bg-black tw-p-1.5 tw-h-[24px] tw-w-[24px] tw-cursor-pointer"
                            title="Create Item"
                            onClickIcon={() => handleButtonClick('item')}
                        />
                        <Icon
                            path={CreateTimeEntryIcon}
                            alt="creaate-timeentry-icon"
                            iconClass="tw-rounded-full tw-bg-black tw-p-1.5 tw-h-[24px] tw-w-[24px] tw-cursor-pointer"
                            title="Create Time Entry"
                            onClickIcon={() => handleButtonClick('timeEntry')}
                        />
                    </div>
                )}
                <ImagesFanFrame images={primaryImages} />
            </div>
            <div className="tw-p-4 tw-flex tw-flex-col tw-justify-around tw-h-[250px]">
                <div className="tw-flex tw-justify-between tw-text-gray-500 tw-text-sm">
                    <span className="tw-mr-3">{formatDate(date)}</span>
                    <span className="tw-text-right">
                        {formatCurrency(budget)} Budget
                    </span>
                </div>
                <h3 className="tw-font-bold tw-text-base tw-mt-2">
                    {projectName}
                </h3>
                <p className="tw-text-gray-500">{clientName}</p>
                <div className="tw-flex tw-flex-col tw-mt-4">
                    <span className="tw-font-bold">
                        {formatCurrency(amountReceived)} Received
                    </span>
                    <div className="tw-bg-gray-200 tw-rounded-full tw-h-2.5 tw-w-full tw-ml-2">
                        <div
                            className="tw-bg-green-500 tw-h-2.5 tw-rounded-full"
                            style={{ width: `${receivedPercentage}%` }}
                        ></div>
                    </div>
                </div>
                <div className="tw-text-right tw-text-gray-500 tw-text-sm tw-mt-2">
                    {formatCurrency(totalAmount)} Total
                </div>
            </div>
        </div>
    )
}

RecentProjectCard.displayName = 'RecentProjectCard'

export default RecentProjectCard
