import React from 'react'
import { BarType } from 'app/enums/dashboard/barType'
import { formatChartCurrency } from 'utilities/formatChartCurrency'

interface ICashflowBar {
    cashAmount: number
    total: number
    remainingCash: number
    barColor: string
    progressBarColor: string
    cashAmountLabel: string
    barType: BarType
    placeholderWidth?: string
}

const CashflowBar = ({
    total,
    cashAmount,
    remainingCash,
    barColor,
    progressBarColor,
    cashAmountLabel,
    barType,
    placeholderWidth,
}: ICashflowBar) => {
    const noAvailableCash = remainingCash === 0
    const defaultWidth =
        noAvailableCash && barType === BarType.MoneyIn ? '0' : '100%'
    const barWidth = noAvailableCash
        ? defaultWidth
        : `${(cashAmount / total) * 100}%`
    const isEmpty = barType === BarType.Empty

    return (
        <div className="tw-mb-4">
            <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-between">
                    <div className="tw-text-base tw-leading-none tw-font-bold tw-text-gray-900">
                        {formatChartCurrency(cashAmount)}
                    </div>
                    <div className="tw-text-xs tw-text-gray-500 tw-text-nowrap">
                        {cashAmountLabel}
                    </div>
                </div>
                <div
                    className={`tw-flex tw-items-center tw-w-full ${barColor} tw-rounded tw-h-6 tw-ml-4`}
                >
                    <div
                        className={`${progressBarColor} tw-h-6 tw-rounded`}
                        style={{
                            width: `${isEmpty ? placeholderWidth : barWidth}`,
                        }}
                    />
                </div>
            </div>
            <div className="tw-text-sm tw-text-gray-500 tw-text-right">
                {formatChartCurrency(remainingCash)} Remaining
            </div>
        </div>
    )
}

CashflowBar.displayName = 'CashflowBar'

export default CashflowBar
