import UnitsOfMeasureDropdown from 'app/components/dropdowns/UnitsOfMeasuresDropdown'
import FormCurrencyInput from 'app/components/form/FormCurrencyInput'
import Label from 'app/components/label/Label'
import { TWhatChangedComponent } from 'lib/api/types/WhatChangedComponent'
import React from 'react'
import { Form, FormControl } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'

const MarkupInputs = ({
    disableInputs,
    handleRecalculate,
}: {
    disableInputs: boolean
    handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void
}) => {
    const methods = useFormContext()

    return (
        <div className="tw-mt-8 tw-flex tw-gap-4">
            <div>
                <Label label="Unit Cost" className="!tw-text-left tw-mb-2" />
                <div className="tw-flex tw-gap-4 tw-pr-8">
                    <div className="tw-max-w-36">
                        <Controller
                            control={methods.control}
                            name="estunitcost"
                            render={({ field }) => (
                                <FormCurrencyInput
                                    id="Unit Cost"
                                    disabled={disableInputs}
                                    allowNegativeValue
                                    value={field.value}
                                    maxLength={15}
                                    onBlur={() =>
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcEstimatedUnitCost
                                        )
                                    }
                                    onValueChange={field.onChange}
                                />
                            )}
                        />
                    </div>
                    <span className="tw-self-center">X</span>
                    <div className="tw-relative">
                        <Label
                            label="Qty"
                            className="tw-absolute tw-bottom-0 tw-right-2 tw-top-2 tw-text-[#84878a]"
                        />
                        <Controller
                            name="quantity"
                            render={({ field: { value, onChange } }) => (
                                <FormControl
                                    id="Qty"
                                    onChange={onChange}
                                    size="sm"
                                    disabled={disableInputs}
                                    value={value}
                                    maxLength={15}
                                    onBlur={(e) => {
                                        const isNotAValidNumber = isNaN(
                                            Number(e.target.value)
                                        )
                                        if (
                                            !e.target.value ||
                                            isNotAValidNumber ||
                                            e.target.value === '-0.'
                                        ) {
                                            methods.setValue('quantity', 0)
                                        } else {
                                            methods.setValue(
                                                'quantity',
                                                Number(e.target.value)
                                            )
                                        }
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcQuantity
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Label label="Units" className="tw-mb-2" />
                <div className="tw-flex tw-items-center tw-gap-4">
                    <Controller
                        control={methods.control}
                        name="unitmeasure"
                        render={({ field }) => (
                            <div>
                                <UnitsOfMeasureDropdown
                                    inputId="Units"
                                    height={32}
                                    value={{
                                        label: '',
                                        value: field.value,
                                    }}
                                    onChange={(value) =>
                                        field.onChange(value.value)
                                    }
                                />
                            </div>
                        )}
                    />
                    <span className="tw-justify-self-center tw-text-2xl">
                        =
                    </span>
                </div>
            </div>
            <div>
                <Label
                    label="Extended Cost"
                    className="!tw-text-left tw-mb-2"
                />
                <div className="tw-flex tw-gap-4">
                    <div className="tw-max-w-36">
                        <Controller
                            control={methods.control}
                            name="estcost"
                            render={({ field }) => (
                                <FormCurrencyInput
                                    allowNegativeValue
                                    disabled={disableInputs}
                                    id="Extended Cost"
                                    value={field.value}
                                    maxLength={15}
                                    onValueChange={field.onChange}
                                    onBlur={() =>
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcEstimatedCost
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                    <span className="tw-self-center">+</span>
                </div>
            </div>
            <div className="tw-relative tw-self-end">
                <Label
                    label="Markup"
                    className="tw-absolute tw-bottom-0 tw-right-2 tw-top-2 tw-text-[#84878a]"
                />
                <Controller
                    name="markup"
                    render={() => (
                        <Controller
                            control={methods.control}
                            name="estmarkup"
                            render={({ field }) => (
                                <FormCurrencyInput
                                    allowNegativeValue
                                    disabled={disableInputs}
                                    id="Markup"
                                    value={field.value}
                                    maxLength={15}
                                    onValueChange={field.onChange}
                                    onBlur={() =>
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcEstimatedMarkup
                                        )
                                    }
                                />
                            )}
                        />
                    )}
                />
            </div>
            <span className="tw-self-end tw-text-2xl tw-pl-8">=</span>
            <div>
                <Label label="Sell Price" className="!tw-text-left tw-mb-2" />
                <div className="tw-flex tw-gap-4">
                    <div className="tw-max-w-36">
                        <Controller
                            control={methods.control}
                            name="estprice"
                            render={({ field }) => (
                                <FormCurrencyInput
                                    allowNegativeValue
                                    id="Sell Price"
                                    disabled={disableInputs}
                                    value={field.value}
                                    maxLength={15}
                                    onValueChange={field.onChange}
                                    onBlur={() =>
                                        handleRecalculate(
                                            TWhatChangedComponent.cwcEstimatedPrice
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className="tw-flex tw-items-center">
                        <i
                            className="ri-lock-fill px-2"
                            onClick={() => {
                                methods.setValue('usedisc', 'dmDiscount')
                                methods.setValue('markup', 0)
                                handleRecalculate(
                                    TWhatChangedComponent.cwcPercentType
                                )
                            }}
                        />
                        <div className="tw-max-w-36">
                            <Controller
                                control={methods.control}
                                name="taxable"
                                render={({ field }) => (
                                    <Form.Check
                                        size={30}
                                        type="checkbox"
                                        disabled={disableInputs}
                                        label="Taxable"
                                        className="tw-bg-color-[]"
                                        checked={field.value}
                                        maxLength={15}
                                        onChange={field.onChange}
                                        onBlur={() =>
                                            handleRecalculate(
                                                TWhatChangedComponent.cwcTaxable
                                            )
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
MarkupInputs.displayName = ' MarkupInputs'
export default MarkupInputs
