import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap'
import ThreadComponent from '../../ThreadComponent'
import Header from '../../../components/Header'
import URI from '../../../../defaults/RoutesDefault'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../components/Form'
import WysiwygEditor from '../../../ui-kit/WysiwygEditor'
import { ApiService } from '../../../../lib/api/HttpService'
import { displayAlert } from '../../../../utilities/Response'
import { getCookie } from '../../../../utilities/Auth'
import { UserEditValidation } from '../user-management/validations/UserEditValidation'
import { validateInput } from '../../../../app/components/validators/Helpers'
import SecureInput from '../../../../app/components/security/SecureInput'
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security'

class UserSettingsList extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            dataIsLoaded: false,
            showTableSearch: false,
            userId: null,
        }
        this.api = new ApiService()

        this.updatedData = {}
        this.userCode = getCookie('dmUsercode')
    }

    componentInit() {
        this.setTitle('User Settings').setFormAction(true)
    }

    async componentDidMount() {
        this.dMloader(true)
        let user = await this.api.getUserData(
            `?$filter=usercode eq ${this.userCode}`
        )
        user = user[0]
        const { password } = user
        const users = await this.api.get('manageaccount')
        const accountEmail = users.users.find(
            (u) => u.username.toLowerCase() === password.toLowerCase()
        )
        user.accountEmail = accountEmail.email

        this.setState({
            data: user,
            dataIsLoaded: true,
            userId: accountEmail.userId,
        })
        this.updatedData = user
        this.dMloader(false)
    }

    handleChange = (e, meta = {}) => {
        let key, value
        let { data } = this.state
        if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
            // Select
            key = meta.name
            value = e.value
        } else if (e.hasOwnProperty('target')) {
            // Form
            key = e.target.name ?? e.target.id
            if (e.target.hasOwnProperty('value')) {
                value = e.target.value
            } else if (e.target.hasOwnProperty('checked')) {
                value = e.target.checked
            }
        }

        this.updatedData[key] = value
        data[key] = value
        this.setState({
            data,
            dataIsLoaded: true,
        })
    }

    handleWysiwygChange = (name) => (e) => {
        this.updatedData[name] = e.target.value
    }

    handleSave = async (e) => {
        const validated = await validateInput(
            UserEditValidation,
            this.updatedData
        )
        const manageAccountData = { email: this.updatedData.accountEmail }
        if (!validated.status) {
            await this.api
                .editManageAccount(this.state.userId, manageAccountData)
                .catch((err) => {
                    const errorMessage =
                        err.response?.data?.userError ||
                        'User account management email could not be updated'
                    displayAlert('danger', errorMessage)
                })

            await this.api
                .editUserData(this.updatedData.usercode, this.updatedData)
                .then((resp) => {
                    displayAlert(
                        'success',
                        'User has been updated successfully'
                    )
                    setTimeout(() => {
                        window.onbeforeunload = null
                        window.location.reload()
                    }, 1500)
                })
                .catch((err) => {
                    displayAlert('danger', 'User could not be updated')
                })
        } else {
            displayAlert('danger', 'Please enter a valid email')
        }
    }

    renderHeader() {
        return (
            <Header>
                <Header.TitleContent>
                    <Header.LeftContent>
                        <Header.Breadcumbs>
                            <NavLink
                                to={URI.settings.list}
                                className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
                            >
                                <i className="ri-arrow-left-s-line"></i> Back
                            </NavLink>
                            <Breadcrumb className="breadcrumb-light">
                                <Breadcrumb.Item
                                    linkProps={{ to: URI.settings.list }}
                                    linkAs={Link}
                                >
                                    Settings
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>User Settings</Breadcrumb.Item>
                            </Breadcrumb>
                        </Header.Breadcumbs>
                        <Header.Title>User Settings</Header.Title>
                    </Header.LeftContent>
                </Header.TitleContent>

                <Header.Actions list={true}>
                    <li>
                        <Button variant="light" onClick={this.handleSave}>
                            Save
                        </Button>
                    </li>
                </Header.Actions>
            </Header>
        )
    }

    renderContent() {
        return (
            <Form.Group>
                <Row xs={1} lg={2} className="mb-4">
                    <Col>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Name</Form.Label>
                            </Col>
                            <Col>
                                <SecureInput
                                    attributeNo={192}
                                    attributeType={
                                        SECURITY_ATTRIBUTE_TYPES.DenySpec1
                                    }
                                    type="text"
                                    placeholder="Enter name"
                                    name="username"
                                    defaultValue={this.state.data.username}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">Title</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter title"
                                    name="usertitle"
                                    defaultValue={this.state.data.usertitle}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Correspondence Email
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter correspondence email"
                                    name="useremail"
                                    data-field="useremail"
                                    defaultValue={this.state.data.useremail}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    Account Management Email
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter account management email"
                                    name="accountEmail"
                                    data-field="accountEmail"
                                    defaultValue={this.state.data.accountEmail}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row className="align-items-start mb-3">
                            <Col
                                lg={3}
                                className="text-lg-end mb-2 mb-lg-0 mt-0 mt-lg-3"
                            >
                                <Form.Label className="mb-0">
                                    Signature / Default Email Message Text
                                </Form.Label>
                            </Col>
                            <Col>
                                <WysiwygEditor
                                    toolbarHidden
                                    onChange={this.handleWysiwygChange(
                                        'defemailtext',
                                        this
                                    )}
                                    name="defemailtext"
                                >
                                    {this.state.data.defemailtext}
                                </WysiwygEditor>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}

                <div className="content-padding min-height has-action-bar">
                    <Container fluid>{this.renderContent()}</Container>
                </div>

                {/* Submit Button */}
                <FooterFormAction>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </FooterFormAction>
            </>
        )
    }
}

export default WithRouter(UserSettingsList)
