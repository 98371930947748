import React from 'react'
import ThreadComponent from '../../ThreadComponent'
import { Container, Button, Form, Row, Col, Modal } from 'react-bootstrap'
import { WithRouter } from '../../../../helpers/Router'
import { FooterFormAction } from '../../../../templates/components/Form'
import DatePicker from 'react-datepicker'
import { ApiService } from '../../../../lib/api/HttpService'
import { displayAlert } from '../../../../utilities/Response'
import { localeDate } from '../../../../helpers/Date'
import { formatDate } from '../../../../helpers/Date'

class ModifyStatusDialog extends ThreadComponent {
    constructor(props) {
        super(props)

        this.state = {
            shipDate: this.props.selectedData.expdt
                ? new Date(this.props.selectedData.expdt)
                : '',
            cfaDate: this.props.selectedData.cfadt
                ? new Date(this.props.selectedData.cfadt)
                : '',
            receiveDate: this.props.selectedData.recdt
                ? new Date(this.props.selectedData.recdt)
                : '',
            qtyReceive: this.props.selectedData.recquan || 0,
            installDate: this.props.selectedData.wc1dt
                ? new Date(this.props.selectedData.wc1dt)
                : '',
            statusStorage: this.props.selectedData.statusnote || '',
            userDate: this.props.selectedData.wc2dt
                ? new Date(this.props.selectedData.wc2dt)
                : '',
            qtyBackOrder: this.props.selectedData.boquan || 0,
            isReceiveAllItems: false,
            clearNulls: false,
        }

        this.api = new ApiService()
    }

    handleChange = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    handleDateChange = (dateName, date) => {
        this.setState((prevState) => ({
            ...prevState,
            [dateName]: date,
        }))
    }

    handleSubmit = async (e) => {
        const modalFrom = e.target.getAttribute('data-from')

        let postData = {
            compNo: this.props.selectedData.comp,
            itemNo: this.props.selectedData.item,
            usercode: this.props.usercode,
            poId: this.props.poId,
            changeAll: false,
            expDt: this.state.shipDate ? localeDate(this.state.shipDate) : '',
            cfaDt: this.state.cfaDate ? localeDate(this.state.cfaDate) : '',
            recDt: this.state.receiveDate
                ? localeDate(this.state.receiveDate)
                : '',
            recQuan: this.state.qtyReceive,
            userDt1: this.state.installDate
                ? localeDate(this.state.installDate)
                : '',
            statusNote: this.state.statusStorage,
            userDt2: this.state.userDate ? localeDate(this.state.userDate) : '',
            boQuan: this.state.qtyBackOrder,
        }

        if (modalFrom === 'updateAll') {
            delete postData.compNo
            delete postData.itemNo
            postData.changeAll = true
            postData.clearNulls = this.state.clearNulls
        }

        await this.api
            .updatePurchaseOrderStatus(postData)
            .then((response) => {
                displayAlert('success', 'Successfully update status')
                this.props.onUpdate()
            })
            .catch((err) => {
                displayAlert('danger', err.response.data.userError)
            })
    }

    handleModalClose = () => {
        this.props.onCancel()
    }

    updateAllContent() {
        return (
            <Form.Group className="m-4">
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Expected Ship Date
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.shipDate}
                            onChange={(date) => {
                                this.handleDateChange('shipDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">CFA Date</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.cfaDate}
                            onChange={(date) => {
                                this.handleDateChange('cfaDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">Receive Date</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.receiveDate}
                            onChange={(date) => {
                                this.handleDateChange('receiveDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Receive all Items"
                                    name={'receive-all-items'}
                                    id={'receive-all-items-radio'}
                                    type="checkbox"
                                    onChange={() => {
                                        this.setState({
                                            isReceiveAllItems:
                                                !this.state.isReceiveAllItems,
                                        })
                                    }}
                                    defaultChecked={
                                        this.state.isReceiveAllItems
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <br />
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            {this.props.company?.wildcardstatus1 ??
                                'Install Date1'}
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.installDate}
                            onChange={(date) => {
                                this.handleDateChange('installDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={1} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Status / Storage
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="statusStorage"
                            onChange={this.handleChange}
                            defaultValue={this.state.statusStorage}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            {this.props.company?.wildcardstatus2 ??
                                'User Date 21'}
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.userDate}
                            onChange={(date) => {
                                this.handleDateChange('userDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col>
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Clear Blank Fields"
                                    name={'clear-nulls'}
                                    id={'clear-nulls-radio'}
                                    type="checkbox"
                                    onChange={() => {
                                        this.setState({
                                            clearNulls: !this.state.clearNulls,
                                        })
                                    }}
                                    defaultChecked={this.state.clearNulls}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <FooterFormAction>
                            <a
                                className="btn btn-primary btn-icon btn-action me-0 me-lg-2 align-items-center"
                                data-from="updateAll"
                                name="ok"
                                onClick={this.handleSubmit}
                            >
                                <i className="ri-check-line ri-lg"></i>OK
                            </a>
                            <a
                                onClick={this.handleModalClose}
                                className="btn btn-trans-light btn-icon btn-action mb-2 mb-lg-0 align-items-center"
                            >
                                <i className="ri-close-line ri-lg text-primary-red"></i>
                                Cancel
                            </a>
                        </FooterFormAction>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    content() {
        return (
            <Form.Group className="m-4">
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Vendor Invoice Date
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            readOnly
                            disabled
                            defaultValue={
                                this.props.selectedData.latestsindt
                                    ? formatDate(
                                          this.props.selectedData.latestsindt
                                      )
                                    : ''
                            }
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Vendor Invoice Number
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            readOnly
                            disabled
                            defaultValue={this.props.selectedData.latestsinno}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">Qty. Paid For</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <Form.Control
                            type="text"
                            readOnly
                            disabled
                            defaultValue={this.props.selectedData.totpaidqty}
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">Actual Cost</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <Form.Control
                            type="text"
                            readOnly
                            disabled
                            defaultValue={
                                this.props.selectedDataIndex !== null
                                    ? this.props.components[
                                          this.props.selectedDataIndex
                                      ]?.actcost
                                    : null
                            }
                        />
                    </Col>
                </Row>
                <hr />
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Expected Ship Date
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.shipDate}
                            onChange={(date) => {
                                this.handleDateChange('shipDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">CFA Date</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.cfaDate}
                            onChange={(date) => {
                                this.handleDateChange('cfaDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">Received Date</Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.receiveDate}
                            onChange={(date) => {
                                this.handleDateChange('receiveDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">Qty. Receive</Form.Label>
                    </Col>
                    <Col lg={2}>
                        <Form.Control
                            type="text"
                            name="qtyReceive"
                            defaultValue={this.state.qtyReceive}
                            onChange={this.handleChange}
                            onFocus={(e) => (e.target.value = '')}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            {this.props.company?.wildcardstatus1 ??
                                'Install Date1'}
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.installDate}
                            onChange={(date) => {
                                this.handleDateChange('installDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Status / Storage
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <Form.Control
                            type="text"
                            name="statusStorage"
                            onChange={this.handleChange}
                            defaultValue={this.state.statusStorage}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-3">
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            {this.props.company?.wildcardstatus2 ??
                                'User Date 21'}
                        </Form.Label>
                    </Col>
                    <Col lg={4}>
                        <DatePicker
                            selected={this.state.userDate}
                            onChange={(date) => {
                                this.handleDateChange('userDate', date)
                            }}
                            showMonthDropdown="true"
                            showYearDropdown="true"
                            className="form-control"
                            placeholderText="Select Entry Date"
                        />
                    </Col>
                    <Col lg={2} className="mb-2 mb-lg-0">
                        <Form.Label className="mb-0">
                            Qty. Back Ordered
                        </Form.Label>
                    </Col>
                    <Col lg={2}>
                        <Form.Control
                            type="text"
                            name="qtyBackOrder"
                            onChange={this.handleChange}
                            onFocus={(e) => (e.target.value = '')}
                            defaultValue={this.state.qtyBackOrder}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <FooterFormAction>
                            <a
                                className="btn btn-primary btn-icon btn-action me-0 me-lg-2 align-items-center"
                                name="ok"
                                onClick={this.handleSubmit}
                            >
                                <i className="ri-check-line ri-lg"></i>OK
                            </a>
                            <a
                                onClick={this.handleModalClose}
                                className="btn btn-trans-light btn-icon btn-action mb-2 mb-lg-0 align-items-center"
                            >
                                <i className="ri-close-line ri-lg text-primary-red"></i>
                                Cancel
                            </a>
                        </FooterFormAction>
                    </Col>
                </Row>
            </Form.Group>
        )
    }

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    show={this.props.isShowing}
                    aria-labelledby="action-modal"
                    className="a-modal"
                    backdrop="static"
                    centered
                >
                    <div className="content-padding min-height">
                        <Container fluid className="mt-4">
                            {Object.values(this.props.selectedData).length
                                ? this.content()
                                : this.updateAllContent()}
                        </Container>
                    </div>
                </Modal>
            </>
        )
    }
}

export default WithRouter(ModifyStatusDialog)
